<div class="visa-faqs">
  <div class="container">
    <ngx-skeleton-loader *ngIf="!faqs"
                         count="1"
                         [theme]="{height:'100px', 'vertical-align':'bottom', width:'100%', 'margin-bottom':'0px'}">
    </ngx-skeleton-loader>

    <div class="section" *ngFor="let faq of faqs">
      <div class="container">
        <ngx-skeleton-loader *ngIf="!faqs"
                             count="1"
                             [theme]="{height:'100px', 'vertical-align':'bottom', width:'100%', 'margin-bottom':'0px'}">
        </ngx-skeleton-loader>
        <h1 class="question">{{faq.question}}</h1>
        <p class="answer" [innerHTML]="faq.answer"></p>
      </div>
    </div>
    <div class="ngb-pagination">
      <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize"
                      [collectionSize]="totalSize" (pageChange)="changePage(page)">
      </ngb-pagination>
    </div>
  </div>
</div>
