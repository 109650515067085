<ng-container *ngIf="itineraries && itineraries.length > 0">
  <div class="review-trip-section mb-3">
    <div class="traveller-details">
      <div class="traveller-detail">
        <div class="title d-flex justify-content-between">
          <div>
            <h5>Book now before tickets run out!</h5>
          </div>
        </div>
        <!-- <div class="row form mx-0">
          <div class="timer p-3">
            <div class="row align-items-end">
              <div class="col-6">
                <div id="countDown">
                  <h1>{{transform(counter)}}</h1>
                </div>
              </div>
              <div class="col-6 py-1 px-3 text-right">
                <img alt="timer" title="clock" src="./assets/images/timer.svg"/>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="review-trip-section">
    <div class="traveller-details">
      <div class="traveller-detail">
        <div class="title d-flex justify-content-between">
          <div>
            <h5>Baggage Information</h5>
          </div>
        </div>
        <div class="row form mx-0">
          <div class=" py-3 baggage-container">
            <ng-container *ngFor="let flights of itineraries[0]['flights']">
              <div class="flight-baggage baggage-info">
                <div class="d-flex align-items-center mb-3">
                  <div>
                    <img alt="operatingAirline" title="flight-image"
                         src="https://c.fareportal.com/n/common/air/3x/{{flights[0].OperatingAirline}}.png"
                         class="operatingAirline"/>
                  </div>
                  <div>
                    <span>FLIGHT {{flights[0].FlightNumber}}</span>
                    <span>{{flights[0].DepartureAirport}} - {{flights[0].ArrivalAirport}}</span>
                    <div class="mb-2">
                      <span>{{flights[0].allow?.Pieces}} Free Bag(s)</span>
                      <span *ngIf="flights[0].allow?.Weight">
                          {{flights[0].allow?.Weight}}
                        {{flights[0].allow?.Unit}}
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="flights[0].charge.length > 0">
                  <p class="extra-baggage">Extra Baggage</p>
                  <div class="mb-2" *ngFor="let chargedBag of flights[0].charge; let iii = index">
                    <small *ngIf="iii === 0">1 <sup>st</sup> Piece</small>
                    <small *ngIf="iii === 1">2 <sup>nd</sup> Piece</small>
                    <span class="semi-bold"> {{ chargedBag[0]?.EquivalentAmount | currency}}</span>
                    <small class="d-block">{{chargedBag[0]?.Description1}}</small>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" mt-3">
    <div class="review-trip-section">
      <div class="traveller-details">
        <div class="traveller-detail">
          <div class="title d-flex justify-content-between">
            <div>
              <h5 class="font-size-15">Safe Shopping Guarantee</h5>
            </div>
          </div>
          <div class="row form mx-0">
            <p class="font-size-14-line-height-24">
              We value your trust and take steps to protect your personal information within our organization. We
              understand that customer service is essential to maintaining customer trust, so we use reasonable
              organizational, technical, and administrative measures to protect customer information. However, we also
              understand that no data transmission or storage system can be guaranteed to be 100% secure. If you have
              reason to believe that your interaction with us is no longer secure (for example, if you feel that the
              security of your account has been compromised), please immediately notify customer service at
              <a href="tel:+1-888-666-8545" target="_blank"> +1-888-666-8545</a>
              or feedback at
              <a href="mailto:info@flyAllOver.com" target="_blank"> info@flyAllOver.com</a> so that we have an
              opportunity to try to address your concerns. We take customer trust seriously and will make every effort
              to mitigate any risks to your personal information. Thank you for entrusting us with your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
