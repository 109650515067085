import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-check-out-input',
  templateUrl: './check-out-input.component.html',
  styleUrls: ['./check-out-input.component.css']
})
export class CheckOutInputComponent {
  @Input() returnDate;
  @Output() openDesktopCalenderEvent = new EventEmitter();
  isFocused = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-US');
  }

  openDesktopCalender() {
    this.openDesktopCalenderEvent.emit('return');
  }

  onFocus(): void {
    if (this.returnDate && this.returnDate.length > 0) {
      this.isFocused = true;
    } else {
      this.isFocused = !this.isFocused;
    }
  }
}
