import {Component, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-search-results',
  templateUrl: './help-search-results.component.html',
  styleUrls: ['./help-search-results.component.css']
})
export class HelpSearchResultsComponent implements OnInit, OnChanges {

  // pagination variables
  page: number;
  pageSize: number;
  collectionSize: number;
  // searchResultsFound = true;
  searchText = '';
  topicId = '';
  searchResults = []
  nextPage;
  prevPage;
  loading: boolean = true;
  backendError: boolean = false;

  constructor(
    private apis: HelpCenterServices, private activatedRoute: ActivatedRoute, private router: Router, private seoService: SEOService) {
    this.seoUpdate();
  }


  ngOnInit(): void {
    this.searchText = this.activatedRoute.snapshot.queryParams.text;
    this.topicId = this.activatedRoute.snapshot.queryParams.id;
    if (this.searchText) {
      this.onSearch();
    }
  }

  seoUpdate() {

    this.seoService.updateTitle('Help Search Results | FlyAllOver');
    this.seoService.updateDescription('Help Search Results | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnChanges() {
    this.navigateOnSamePage();
  }

  onSearch() {
    this.loading = true;
    this.navigateOnSamePage()
    if (this.searchText !== '') {
      this.apis.searchForTopic(this.searchText).subscribe(
        (res: any) => {
          this.searchResults = res.data
          this.page = res.meta.current_page;
          this.collectionSize = res.meta.total;
          this.pageSize = res.meta.per_page
          this.nextPage = res.links.next;
          this.prevPage = res.links.prev;
          this.loading = false
        },
        (err) => {
          this.loading = false;
          this.backendError = true;
          // this.router.navigate(['/no-result'])
        }
      )
    }

  }

  navigateOnSamePage() {
    if (this.searchText) {
      this.router.navigate(['search-results'], {
        queryParams: {
          text: this.searchText
        }
      })
    }
    if (this.topicId) {
      this.router.navigate(['search-results'], {
        queryParams: {
          id: this.topicId
        }
      })
    }
  }

  changePage(page) {
    this.loading = true;
    this.apis.getPagingData(page).subscribe(
      (res) => {
        this.searchResults = res['data']
        this.page = res['meta'].current_page;
        this.collectionSize = res['meta'].total;
        this.pageSize = res['meta'].per_page;
        this.nextPage = res['links'].next;
        this.prevPage = res['links'].prev;
        this.loading = false
      },
      (err) => {
        this.loading = false;
        this.backendError = true;
      }
    )
  }

  getTopic(id) {
    if (this.topicId !== '') {
      this.loading = true;
      this.apis.getTopic(id).subscribe(
        (res: any) => {
          this.searchResults = res.data
          this.page = res.meta.current_page;
          this.collectionSize = res.meta.total;
          this.pageSize = res.meta.per_page
          this.nextPage = res.links.next;
          this.prevPage = res.links.prev;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.backendError = true
        }
      )
    }
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
