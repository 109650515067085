import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
@Component({
  selector: 'app-travellers-classes-hotels-dialog',
  templateUrl: './travellers-hotels-desktop-dialog.component.html',
  styleUrls: ['./travellers-hotels-desktop-dialog.component.css']
})

export class TravellersHotelsDesktopDialogComponent implements OnInit {

  className: any = 'Economy';
  errorMax = false;
  errorMin = false;
  errorInfants = false;
  childrenAges: string[] = [];
  travellers: any = [
    {
      id: 'adults',
      name: 'Adults',
      ageFrom: 16,
      ageTo: 64,
      initialValue: 1,
      currentValue: 1,
      minValue: 0,
      maxValue: 12,
    },

    {
      id: 'children',
      name: 'Children',
      ageFrom: 2,
      ageTo: 12,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
  ];
  rooms: any = [
    {
      id: 'rooms',
      name: 'Rooms',
      initialValue: 1,
      currentValue: 1,
      minValue: 1,
      maxValue: 20,
    },
  ];
  totalTravellers = 1;
  hotelsData: any;

  constructor(
              private activatedRoute: ActivatedRoute,
              private hotelsService: HotelsApisService,
              private router: Router,

  ) {
  }

  ngOnInit(): void {

  }

  inc(traveller): void {
    if (this.totalTravellers < 9) {
      traveller.currentValue++;
      this.totalTravellers++;
      if (traveller.id == 'children') {
        this.childrenAges.push('1');
      }
      if (this.totalTravellers >= 1) {
        this.errorMin = false;
      }
      this.errorMax = false;
    }
    if (this.totalTravellers >= 9) {
      this.errorMax = true;
    }
  }

  dec(traveller): void {
    if (this.totalTravellers >= 1) {
      traveller.currentValue--;
      this.totalTravellers--;
      if (traveller.id == 'children') {
        this.childrenAges.pop();
      }
      if (this.totalTravellers <= 9) {
        this.errorMax = false;
      }
      this.errorMin = false;
    }
    if (this.totalTravellers < 1) {
      this.errorMin = true;
    }
  }

  incRooms(room): void {
      room.currentValue++;
  }

  decRooms(room): void {
    if (room.currentValue <= 1) {
      this.errorMin = true;
    }else {
      room.currentValue--;
    }
  }
 

}
