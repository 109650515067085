import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { DatePipe, isPlatformBrowser, ViewportScroller } from "@angular/common";
import { LocalStorageService } from "src/app/services/webApis/local-storage.service";
import { ThanksModalComponent } from "../../components/review-flight/thanks-modal/thanks-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { SEOService } from "../../services/seo.service";
import {map, publish, startWith} from 'rxjs/operators';
import { AppComponent } from "../../app.component";
import { Subscription } from "rxjs";
import {DataService} from '../../services/data.service';
import { PaymentDialogComponent } from "./payment-dialog/payment-dialog.component";
import { LoadingModalComponent } from "src/app/components/loading-modal/loading-modal.component";

@Component({
  selector: "app-review-flight",
  templateUrl: "./review-flight.component.html",
  styleUrls: ["./review-flight.component.css"],
})
export class ReviewFlightComponent implements OnInit {
  step = 1;
  itineraries: any[] = [];
  sabre: any;
  selectedTrip;
  error;
  tagId;
  data: any;
  tripType;
  searchRequest: any;
  selectedTravellers: any = [];
  paymentData: any;
  dialogRef: any;
  insurance: any;
  updatePaymentDataVar: any;
  errorMessage: any;
  res: any;
  fareData;
  countries: any;
  token: any;
  accountInfo: any;
  OwnerInfo: any;
  familyMembers: any=[];
  familyrelation;
  InsuranceInfo;
  seats;
  flights: any;
  destination: any;
  origin: any;
  setTraveller: any;
  itinerary: any;
  metaTitle: string;
  metaImage: any;
  offers: any;
  payLater: string;
  MultiTicket: string;
  secondTagId: string;
  firstIndex: string;
  secondIndex: string;
  flightResultsPage: boolean;
  subscriptionsList: Subscription[] = [];
  ErrorMsg: any;
  selectedFlightObject: any;
  selectedFlightIndex:any;
  fareRulesData: any [] = [];
  fareRules
  airlines: string[] = [];
  randomMin: any;
  interval: any;
  airlineImg: any = [];
  baggingFree
  loadingFlight= true
  errorSearchData= false
  myIpAddress: any;
  loadingModal: any;
  pnrData: any;
  constructor(
    private apis: ApisService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private viewportScroller: ViewportScroller,
    private localStorage: LocalStorageService,
    private matDialog: MatDialog,
    private cookie: CookieService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _ActivatedRoute: ActivatedRoute,
    private dataFake: DataService,
  ) {
    this.tagId = this._ActivatedRoute.snapshot.paramMap.get('tagId')
    this.tripType = this._ActivatedRoute.snapshot.paramMap.get('tripType')
    this.seoUpdate(
      this.metaTitle,
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up",
      this.metaImage
    );
  }
  getArrayValues(index) {
    this.interval = setInterval(() => {
      if (index == this.dataFake.airlinesLog.length) {
        this.airlineImg = [];
        index = 0;
        return;
      }
      this.airlineImg.push(this.dataFake.airlinesLog[index]);
      index++;
    }, 500);
  }

  ngOnInit() {
    this.getTripById();
    this.getArrayValues(0);
    this.getIpAddress()
    this.flightResultsPage = true;
    this.subscriptionsList = this.subscriptions();
    this.getCountries();
    this.token = this.localStorage.getItem("flyallover_token");
    if(this.token){
      this.getFamilyMembers();
    }
    const selectedTravellers = JSON.parse(this.localStorage.getItem("selectedTravellers"));
    if (selectedTravellers) {
      this.selectedTravellers = selectedTravellers;
    }
  }
  getFareRules(fareRules) {
    const senFareRulesData = {
      destination_location: fareRules[0]?.flights[0][(fareRules[0]?.flights[0].length) - 1].ArrivalAirport,
      // destination_location: e.flights[0][(e.flights[0].length) - 1].ArrivalAirport,
      // origin_location: e.flights[0][0].DepartureAirport,
      origin_location: fareRules[0]?.flights[0][0].DepartureAirport,
      marketing_carrier: fareRules[0]?.flights[0][0].MarketingAirline,
      fare_basis: fareRules[0]?.FareBasisCode,
      // departure_date_time: departureDate
      departure_date_time: this.datePipe.transform(
      fareRules[0]?.flights[0][0].DepartureDateTime,
        "MM-dd"
      )
      /*//////////////////////////////////////////////////////////////////////
      destination_location: fareRules.flights[0][(this.fareRules.flights[0].length) - 1].ArrivalAirport,
      origin_location: fareRules.flights[0][0].DepartureAirport,
      marketing_carrier:fareRules.flights[0][0].MarketingAirline,
      fare_basis: this.fareRules.FareBasisCode*/
    }
    this.apis.fareRules(senFareRulesData).subscribe(
      (res: any) => {
        this.res = res;
        this.fareRulesData = res.data.Paragraph;

      },
      (err) => {
        this.res = null;
      }
    );
  }
  callSearchAPi(request = this.searchRequest) {
    this.apis.search(request).subscribe(
      (data: any) => {
        this.flights = data.data.flight;
        this.destination = data.data.destination;
      }
    );
  }
  rulesByAirline() {
    this.fareRules = this.itineraries;
    /*this.fareRules.forEach((el, i) => {
      if (this.airlines.indexOf(el.OperatingAirlineCode) === -1) {
        const fareData = {
          destination_location: el.flights[0][(el.flights[0].length) - 1].ArrivalAirport,
          origin_location: el.flights[0][0].DepartureAirport,
          marketing_carrier: el.flights[0][0].MarketingAirline,
          fare_basis: this.itineraries[0].FareBasisCode
        };
      }
    });*/
        this.getData('fareData')

  }

  getData(e) {
    this.fareData = e;
    // this.getFareRules(e);
  }

  getCountries() {
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    });
  }

  getAccountInfo() {
    this.getProfile()
  }
  getProfile() {
    this.apis.getAccountInfo(this.token).subscribe(
      (data: any) => {
        this.accountInfo = data;
        this.OwnerInfo = data.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  private continueToPaymentSub(): Subscription{
    return this.apis.continueToPaymentStep.subscribe(list=>
      this.step=list



    )

  }
  private subscriptions(): Subscription[]{
    this.subscriptionsList.push(this.continueToPaymentSub());

    return  this.subscriptionsList;
  }

  getFamilyMembers() {
    this.apis.familyMembers(this.token).subscribe(
      (data: any) => {
        this.familyMembers = data.data;

      },
      (err) => {
        console.log(err);
      }
    );
  }



  getFamilyRelations() {
    this.apis.familyRelations(this.token).subscribe(
      (data: any) => {
        this.familyrelation = data.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initInsuranceRequest() {
    const insuranceData: any = {};
    insuranceData.TypeID = "WTP14";
    insuranceData.PlanID = "0";
    insuranceData.tagId = this.tagId;
    insuranceData.start_date = this.datePipe.transform(
      this.itineraries[0].flights[0][0].DepartureDateTime,
      "yyyy-MM-dd"
    );
    insuranceData.end_date = this.datePipe.transform(
      this.itineraries[0].flights[this.itineraries[0].flights?.length - 1][0]
        .DepartureDateTime,
      "yyyy-MM-dd"
    );
    insuranceData.trip_type = this.tripType;
    const fName = {};
    const lName = {};
    const dob = {};
    const PassengerType = {};
    fName[0] = "p1";
    lName[0] = "p1";
    dob[0] = "1995-12-04";
    PassengerType[0] = "ADT";
    insuranceData[`f_name`] = fName;
    insuranceData[`l_name`] = lName;
    insuranceData[`dob`] = dob;
    insuranceData[`PassengerType`] = PassengerType;
    return insuranceData;
  }
  getSeats() {
    this.apis.getSeats(this.tagId).subscribe(
      (response: any) => {
        this.seats = response.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getFreeBags() {
    this.apis.getFreeBags(this.tagId).subscribe(
      (data: any) => {
        // this.data = data['0'].ns26AncillaryDefinition.ns26AncillaryDefinition;
        this.baggingFree = data['0'].ns26GetAncillaryOffersRS.ns26AncillaryDefinition;
      }


    );
  }
  getMultiTicket(){
    this.apis.getMultiTicketTrib(this.tagId,this.firstIndex,this.secondTagId,this.secondIndex).subscribe(
      (data:any)=>{
        this.itineraries = data.data.Itineraries;

      }
    )
  }
  setAllFkightData(flightData) {
    return {
      fullOrigin : flightData.fullOrigin,
      fullDestination : flightData.fullDestination,
      trip_type : flightData.trip_type,
      departure_date : flightData.departure_date,
      return_date : flightData.return_date,
      origin : flightData.origin,
      destination : flightData.destination,
      class : flightData.class,
      travellers : flightData.travellers,
      adult : flightData.adult,
      child : flightData.child,
      lap :  flightData.lap,
      seat : flightData.seat,
      stops : flightData.stops,
      airlines : flightData.airlines,
      offers : "true",
      payLater : "true",
    };
  }
  getTripById() {

    var SelectedTagId = this.tagId;

    if(this.tripType == "Return"){
      SelectedTagId = this.tagId;
    }
    this.apis.getTripById(SelectedTagId, this.tripType).subscribe(
      (data: any) => {
        this.itineraries = data.data.Itineraries;
        console.log('this.itineraries',this.itineraries);

        const allFlightData = data.data.flight_data;
        this.setAllFkightData(allFlightData)
        localStorage.setItem('searchRequestOffers', JSON.stringify(this.setAllFkightData(allFlightData)));
        this.searchRequest = JSON.parse(this.localStorage.getItem("searchRequestOffers"));
        origin
        this.destination = allFlightData.destination;
        this.getAirlineForSearch()
        this.tripType = allFlightData.tripType;
        this.offers = allFlightData.offers;
        this.loadingFlight ==false
        this.rulesByAirline();
        this.getFreeBags();
        this.getFareRules(this.itineraries);
        // this.flights.push(this.selectedFlightObject);
        this.sabre = data.data.sabre_json;
        this.metaImage =
          'https://c.fareportal.com/n/common/air/3x/' +
          this.itineraries[0].flights[0][0].OperatingAirline +
          '.png';
        this.metaTitle =
          'Deal of the day \n' +
          'from ' +
          this.itineraries[0].flights[0][0].ArrivalAirportName +
          ' To ' +
          this.itineraries[0].flights[
          this.itineraries[0].flights?.length - 1
            ][0].ArrivalAirportName +
          ' At ' +
          this.datePipe.transform(
            this.itineraries[0].flights[0][0].DepartureDateTime,
            'yyyy/MM/dd'
          ) +
          ' To ' +
          this.datePipe.transform(
            this.itineraries[0].flights[
            this.itineraries[0].flights?.length - 1
              ][0].DepartureDateTime,
            'yyyy/MM/dd'
          ) /*+ ' Price $' + (this.itineraries[0].BaseFare.Amount + this.itineraries[0].taxfees.Amount)*/ +
          ' By ' +
          this.itineraries[0].flights[0][0].OperatingAirlineName;
          this.errorSearchData = false
      },
      (err) => {
        this.error = true;
        console.log(err);
        this.errorSearchData = true
      }
    );
  }
  getAirlineForSearch(){
    const airlneRequestId = {
      request_id:  this.tagId.split('-')[0]
    };
    this.apis.getAirlineForSearch(airlneRequestId).subscribe(
      (airline: any) => {
        this.flights = airline?.data;
      },
      err => {
        console.error('airlineForSearchData errors', err)
      }
    );
  }

  nextStep() {
    if (this.step < 6) {
      this.apis.continueToPaymentStep.next(++this.step);
      if (isPlatformBrowser(this.platformId)) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    }
  }
  previousStep() {
    if (this.step > 1) {
      this.step--;
      if (isPlatformBrowser(this.platformId)) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    }
    this.updatePaymentDataVar = this.paymentData;
  }

  setProtection(e) {
    this.insurance = e;
  }

  setSelectedTravellers(e) {
    this.selectedTravellers = e;
    this.localStorage.setItem(
      "selectedTravellers",
      JSON.stringify(this.selectedTravellers)
    );
  }
  noAuthSelectedTraveller(traveller:any){
    this.setTraveller = traveller
    this.localStorage.setItem(
      "noAuthTravellers",
      JSON.stringify(this.setTraveller)
    );
    this.localStorage.setItem(
      "selectedTravellers",
      JSON.stringify(this.setTraveller)
    );
  }
  updatePaymentData(e) {
    this.paymentData = e;
  }
  initRequestOffers(){
    const object: any = {};
    object.tagId = this.tagId;
    object.pnr = localStorage.getItem('order_pnr');
    object.order_id = localStorage.getItem('order_id');
    object.CreditCardCity = this.paymentData.CreditCardCity;
    object.CreditCardstreetAddress = this.paymentData.CreditCardstreetAddress;
    object.cardHolderName = this.paymentData.cardHolderFullName;
    object.CreditCardCountry = this.paymentData.CreditCardCountry;
    object.CreditCardZip = this.paymentData.CreditCardZip;
    object.CreditCardVendorCode = this.paymentData.CreditCardVendorCode;
    object.CreditCardExpireDate = this.paymentData.CreditCardExpireDate;
    object.CreditCardNumber = this.paymentData.CreditCardNumber;
    object.state = this.paymentData.State;
    object.creditCardRegion = this.paymentData.creditCardRegion;
    object.cvc = this.paymentData.cvc;
    object.contact_email = this.paymentData.contact_email;
    object.contact_phone = this.paymentData.contact_phone;
    object.payment_intent = "pi_1Iv1ssEzVRHi1jXem8lfgH7k";
    object.customer_ip=this.myIpAddress;
    return object;
  }
  dataPnr_Output(data){
this.pnrData=data

  }
  setIssueTicket(){
    this.apis.issueTicketWithPnr( this.initRequestOffers()).subscribe({
      next:(res:any) =>{
        this.loadingModal.close();
          this.data = res.data;
          console.log(res);
          console.log('res data',res.data);

          this.localStorage.removeItem('order_pnr')
          this.localStorage.removeItem('order_id')
          const order= res.data?.order
          this.router.navigate(['/payment-details/'+order.order_status+'/'+order.id]);
      },
      error:(err:any) =>{
          this.errorMessage = err.error.error;
        this.openErorPaymentModal();
        this.loadingModal.close();

      }
    });
  }
  getIpAddress() {
    this.apis.getIpAddress().subscribe((data: any) => {
      this.myIpAddress = data.ip;
    });
  }
  openLoadingModal(): void {
    this.loadingModal = this.matDialog.open(LoadingModalComponent, {
      panelClass: "alternativeDateDialog",
      autoFocus: false,
      maxHeight: "95vh",
      maxWidth: "95vw",
      backdropClass: "background-transparent",

    })

  }
  orderOffers(){
this.openLoadingModal()
this.setIssueTicket()
    // this.apis.checkCreditAuthorize( this.initRequestOffers()).subscribe({
    //   next:(res:any) =>{
    //     if (res.status === true){
    //       console.log('thischecked == true')
    //     }else
    //     {
    //       console.log('error check');
    //       this.errorMessage = res?.error  ;

    //       this.openErorPaymentModal();
    //       }
    //   },
    //   error:(err:any) =>{
    //       this.errorMessage = err.error.error;
    //     this.openErorThanksModal();
    //   }
    // });
  }
  seoUpdate(
    title = null,
    description = null,
    image = null,
    url = "https://api.flyallover.com/storage/"
  ) {
    if (title != null) {
      this.seoService.updateTitle(title);
    }

    if (description != null) {
      this.seoService.updateDescription(description);
    }

    this.seoService.updateUrl();

    if (image != null) {
      this.seoService.updateImage(image);
    }
  }
  initRequest() {
    const object: any = {};
    // travellers data

    const selectedTravellers = JSON.parse(
      this.localStorage.getItem("selectedTravellers")
    );
    if (selectedTravellers) {
      this.selectedTravellers = selectedTravellers;
    }
    for (let i = 0; i < this.selectedTravellers.length; i++) {
      this.selectedTravellers[i]["country-code"] =
        this.selectedTravellers[i]["country_code"];
      object[i + 1] = {};
      object[i + 1] = this.selectedTravellers[i];
    }
    object.tagId = this.tagId;
    // payment data
    object.CreditCardCity = this.paymentData.CreditCardCity;
    object.CreditCardstreetAddress = this.paymentData.CreditCardstreetAddress;
    object.cardHolderName = this.paymentData.cardHolderFullName;
    object.CreditCardCountry = this.paymentData.CreditCardCountry.code;
    object.CreditCardZip = this.paymentData.CreditCardZip;
    object.CreditCardVendorCode = this.paymentData.CreditCardVendorCode;
    object.CreditCardExpireDate = this.paymentData.CreditCardExpireDate;
    object.CreditCardNumber = this.paymentData.CreditCardNumber;
    object.cvc = this.paymentData.cvc;
    object.email = this.paymentData.email;
    object.phone = this.paymentData.phone;
    // flight data
    object.origin = this.searchRequest.origin;
    object.destination = this.searchRequest.destination;
    object.departure_date = this.searchRequest.departure_date;
    object.return_date = this.searchRequest.return_date;
    object.passengerscount = this.searchRequest.travellers.toString();
    object.trip_type = this.searchRequest.trip_type;
    // object.payment_intent = 'pi_1HBgBSEzVRHi1jXeUSw9MJQP';
    object.payment_intent = "pi_1Iv1ssEzVRHi1jXem8lfgH7k";
    object.origin_country = this.getOriginCountry();
    object.destination_country = this.getDestinationCountry();
    // insurance data
    if (this.insurance) {
      object.PlanCost = this.insurance?.PlanCost?.Amount;
      object.BasePremium = this.insurance?.PlanCost?.BasePremium?.Amount;
      object.TypeID = this.insurance?.attributes?.TypeID;
      object.PlanID = "0";
    }
    return object;
  }

  getOriginCountry() {
    const arr = this.searchRequest.origin.split(",");
    return arr[arr.length - 1];
  }

  getDestinationCountry() {
    const arr = this.searchRequest.destination.split(",");
    return arr[arr.length - 1];
  }

  order() {
    // this.nextStep();
    this.apis.createPnrV4(this.initRequest(),this.token).subscribe(
      (res: any) => {
        if (res.data) {
          this.data = res.data;
          if (this.localStorage.getItem("flyallover_token") != null) {
            this.openThanksModal();
          } else {
            this.openThanksModal();
            this.localStorage.setItem("flyallover_token",res.data.access_token);
          }
        }
      },
      (err: any) => {
        this.errorMessage = err.error.error;
        this.openErorThanksModal();
      }
    );
  }

  openThanksModal(): void {
    this.dialogRef = this.matDialog.open(ThanksModalComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      disableClose: true,
      maxHeight: "95vh",
      width: '440px',
      maxWidth:"98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: this.data,
        error: this.errorMessage,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {

    });
  }
  openErorThanksModal(): void {
    this.dialogRef = this.matDialog.open(ThanksModalComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      maxHeight: "95vh",
      width: '440px',
      maxWidth:"98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: null,
        error: this.errorMessage,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }

  openErorPaymentModal(): void {
    this.dialogRef = this.matDialog.open(PaymentDialogComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      disableClose: true,
      width: '840px',
      maxWidth:"98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: null,
        error: this.errorMessage,
        order_id : localStorage.getItem('order_id')
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }

  splitString(str:any){
    let f = str.slice(0,5);
    let l = str.substring(str.indexOf(",") + 1);
    return f + l
  }
  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
  getInsuranceInfo() {
    this.apis
      .getTravelProtection(this.initInsuranceRequest())
      .subscribe((res: any) => {
        this.InsuranceInfo = res;
      });
  }


}
