<form *ngIf="traveller" [formGroup]="formGroupAddTraveller" (ngSubmit)="createMember()">
    <ng-container formArrayName="formArrayAddTraveller">
        <ng-container *ngFor="let f of memberTraveller.controls; let i = index ;">
            <ng-container [formGroupName]="i">
                <div class="details-traveler">
                    <div class="formTitle">
                        <div class="d-flex justify-content-between">
                            <span>Personal Information <strong style="color: #FE521A;">({{1 + i}})</strong></span>
                            <span style="color: red;font-weight: 800;cursor: pointer;" (click)="deleteTraveller(i)"> X
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>First Name</mat-label>
                                    <input matInput formControlName="first_name" type="text" name="firstName" />
                                </mat-form-field>
                                <!-- <mat-error>
                                    Please Enter First Name
                                </mat-error> -->
                            </div>
                            <div class="col-4 remove-astrick">
                                <mat-form-field appearance="fill">
                                    <mat-label>Middle Name</mat-label>
                                    <input matInput type="text" formControlName="middle_name" name="MiddleName" />
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput formControlName="last_name" type="text" name="LastName" />
                                </mat-form-field>
                                <!-- <mat-error >
                                    Please Enter Last Name
                                </mat-error> -->
                            </div>
                            <div class="col-4 remove-astrick">
                                <mat-form-field appearance="fill">
                                    <mat-label>Email</mat-label>
                                    <input matInput type="email" formControlName="email" name="email" />
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>phone</mat-label>
                                    <input matInput formControlName="phone" type="text" name="phone" />
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Gender</mat-label>
                                    <input type="text" matInput formControlName="gender" name="gender"
                                        placeholder="Select your gender" [matAutocomplete]="gender" />
                                    <mat-autocomplete #gender="matAutocomplete">
                                        <mat-option *ngFor="let option of genders" [value]="option.name">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <!-- <mat-error> Gender is <strong>required</strong></mat-error> -->
                            </div>
                            <ng-container formGroupName="passport">
                                <div class="col-4 calendar mb-4" formGroupName="travellerDob">
                                    <div class="date-form d-flex">
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label>Date of Birth</mat-label>
                                                <mat-select formControlName="traveller_dob_month"
                                                    (ngModelChange)="checkDate(i,'travellerDob')">
                                                    <mat-option *ngFor="let month of months"
                                                        [value]="month.num">{{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input formControlName="traveller_dob_day"
                                                    (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                    placeholder="DD" class="example-right-align" maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input formControlName="traveller_dob_year"
                                                    (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                    placeholder="YYYY" maxlength="4" minlength="4"
                                                    class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <mat-error>
                                        Age Must Be More Than 18 Years
                                    </mat-error> -->
                                </div>
                            </ng-container>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label>Passenger Type</mat-label>
                                    <input type="text" matInput formControlName="PassengerType" name="travellerCountry"
                                        placeholder="Select your Passenger Type" [matAutocomplete]="Relation_id" />
                                    <mat-autocomplete #Relation_id="matAutocomplete">
                                        <mat-option *ngFor="let option of relations" [value]="option.name">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <!-- <mat-error class="mat-error ">
                                    Passenger Type Is Required 
                                </mat-error> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-traveler">
                    <div class="formTitle">
                        <span>Passport Information <strong style="color: #FE521A;">({{1 +
                                i}})</strong></span>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <ng-container formGroupName="passport">
                                <div class="col-4">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Passport Number</mat-label>
                                        <input matInput formControlName="passport_number" placeholder="000000000"
                                            type="text" name="passportNumber" />
                                    </mat-form-field>
                                    <!-- <mat-error>
                                    Passport Number is Required and should be numbers
                                </mat-error> -->
                                </div>
                                <div class="col-4 calendar mb-4" formGroupName="issueDate">
                                    <div class="date-form d-flex">
                                        <input matInput required>
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label>Issued Date</mat-label>
                                                <mat-select (ngModelChange)="checkDate(i,'issueDate')"
                                                    formControlName="issue_date_month">
                                                    <mat-option *ngFor="let month of months" [value]="month.num">
                                                        {{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'issueDate')"
                                                    formControlName="issue_date_day" placeholder="DD"
                                                    class="example-right-align" maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'issueDate')"
                                                    formControlName="issue_date_year" placeholder="YYYY" maxlength="4"
                                                    minlength="4" class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <mat-error>
                                    Issue Date must be less than today
                                </mat-error> -->
                                </div>
                                <div class="col-4 calendar mb-4" formGroupName="expireDate">
                                    <div class="date-form d-flex">
                                        <input matInput required>
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label>Expire Date</mat-label>
                                                <mat-select (ngModelChange)="checkDate(i,'expireDate')"
                                                    formControlName="expiry_date_month">
                                                    <mat-option *ngFor="let month of months" [value]="month.num">
                                                        {{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'expireDate')"
                                                    formControlName="expiry_date_day" placeholder="DD"
                                                    class="example-right-align" maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'expireDate')"
                                                    formControlName="expiry_date_year" placeholder="YYYY" maxlength="4"
                                                    minlength="4" class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <mat-error>
                                    Expire Must Be After {{formData.return_date | date}}
                                </mat-error> -->
                                </div>
                                <div class="col-4">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Issued Country</mat-label>
                                        <input type="text" formControlName="issued_country" name="passportIssuedCountry"
                                            placeholder="Select your Issued Country" matInput
                                            [matAutocomplete]="issuedCountry" (focus)="getfilterCountry(i)"
                                            (blur)="countryPatchValue(i)" (ngModelChange)="countryPatchValue(i)" />
                                        <mat-autocomplete #issuedCountry="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredCountry | async"
                                                [value]="option.name">
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <!-- <mat-error>
                                        passport Issued Country Is Required 
                                    </mat-error> -->
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <div class="py-4" *ngIf="formGroupAddTraveller.controls.formArrayAddTraveller.value.length > 0">
        <button class="btn-add" [style]="!formGroupAddTraveller.valid ? 'opacity:.5':''" [disabled]="!formGroupAddTraveller.valid" type="submit">add member</button>
    </div>
</form>