import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpCenterServices } from 'src/app/services/help-center.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { SEOService } from '../../services/seo.service';
import { AppComponent } from "../../app.component";
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { DOCUMENT, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-covid-testing-locations',
  templateUrl: './covid-testing-locations.component.html',
  styleUrls: ['./covid-testing-locations.component.css'],
})
export class CovidTestingLocationsComponent implements OnInit, OnDestroy {

  states: any = [];
  loading: boolean = true;
  statesOrCitiesNotFound: boolean = false;
  baseRoute: string;
  paramsSub: Subscription;
  country: string;

  constructor(
    private centerApis: HelpCenterServices,
    private router: Router,
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  seoUpdate(country) {
    this.seoService.updateTitle(`Locations to perform COVID-19 PCR TESTS in ${country} | flyallover`);
    this.seoService.updateDescription(`Find a testing center near you, learn how to get tested, and find key information about Locations to perform COVID-19 PCR TESTS in ${country}.`);
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/pcr/pcr_meta.jpg');
  }

  ngOnInit(): void {
    this.baseRoute = this.activatedRoute.snapshot.url[0].path;
    this.paramsSub = this.activatedRoute.paramMap.subscribe(params => {
      this.loading = true;
      const countryName = params.get('country');
      this.country = countryName || 'United States';
      // this.country = this.country.replace(/-/g, ' ').toLowerCase();
      this.getAllCountryLocations(this.country);
      this.seoUpdate(this.country);
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  getAllCountryLocations(country) {
    this.states = [];
    this.centerApis.getAllCovidCountryLocations(country).subscribe(
      (res: any) => {
        this.states = res?.data?.states_or_cities;
        if (this.states?.length === 0) {
          this.statesOrCitiesNotFound = true;
        } else {
          this.statesOrCitiesNotFound = false;
        }
        this.loading = false;
      },
      (err) => {
        this.statesOrCitiesNotFound = true;
        this.loading = false;
      }
    );
  }

  navigateToState(state, location2) {
    this.router.navigate([`pcr-time-slider-for-travel/${this.country}/${state}`], {
      fragment: location2,
    });
    let x = `pcr-test-for-travel/${this.country}/${state}`;
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
