import {AfterViewInit, Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../services/apis.service';
import {CheckRequirmentComponent} from '../../components/check-requirment/check-requirment.component';
import {CovidTestingLocationsComponent} from 'src/app/pages/covid-testing-locations/covid-testing-locations.component';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-flight-details-single-flight',
  templateUrl: './flight-details-single-flight.component.html',
  styleUrls: ['./flight-details-single-flight.component.css','./details-single.scss']
})

export class FlightDetailsSingleFlightComponent implements OnInit {
[x: string]: any;
  bagging: any;
  @Input() itinerary;
  @Input() origin;
  @Input() destination;
  @Input() flight;
  @Input() flightType;
  @Input() counter;
  originCountry: any;
  convertedElapsedTime;

  constructor(
    private matDialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.initElapsedTime();
    this.bagging = this.itinerary.BrandFeatures.BG;
  }

  initElapsedTime(): void {
    this.convertedElapsedTime = this.convertTime(this.flight.ElapsedTime);
  }

  toggleAirCraftDetails() {
    this.document.querySelector('.aircraftDetails.FlightNumber' + this.flight.FlightNumber).classList.toggle('show');
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}h`;
    }
    if (Math.floor(minutes % 60) > 0) {
      convertedMinutes = `${minutes % 60}m`;
    }
    return convertedHours + ' ' + convertedMinutes;
  }
  date(depatureDate , returnDate){
    let departD = new Date(depatureDate);
    let returnD = new Date(returnDate)
    return this.datepipe.transform(departD, 'yyyy-MM-dd') != this.datepipe.transform(returnD, 'yyyy-MM-dd')
  }
  calcTotalFlightTime(flights) {
    let totalTime = 0;
    flights.forEach(function(flight) {
      if (Array.isArray(flight)) {
        flight.forEach(function(stop) {
          totalTime += stop.ElapsedTime;
          totalTime += stop.FlightLayoverTime;
        });
      } else {
        totalTime += flight.ElapsedTime;
        totalTime += flight.FlightLayoverTime;
      }
    });
    return (`${Math.floor(totalTime / 60)}h ${totalTime % 60}m`);
  }

  openDialog() {
    this.dialog.open(CheckRequirmentComponent, {
      panelClass: 'custom-dialog',
      data: {origin: this.origin},
    });
  }

  convertDateToMillisecond(date: any) {
    const fullDate = new Date(date);
    const millisecondDate = fullDate.getDay();
    return millisecondDate;
  }

}
