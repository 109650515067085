<app-navbar></app-navbar>

<div class="results-page">
  <div class="container small-screen-container">
    <app-hotels-engine [loadingSpinner]="loadingSpinner"></app-hotels-engine>
    <div class="row" style="margin-top: 3vh;" [appDisable]="enable">
      <div class="col-lg sidebar">
        <!-- <div class="row"></div>
        <div class="modify benefit row">
          <h1>Modify Your Search</h1>
          <app-hotel-search
            [roundSpinner]="loading"
            (searchDataReady)="hotelSearchAPI($event)"
          >
          </app-hotel-search>
        </div> -->

        <!-- <app-filters></app-filters> -->
        <div class="filters">
          <div class="stars">
            <h5>Filter by :</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  5 stars
                </label>
              </div>
              <span class="text-muted cost"> $414.88 </span>
            </div>
            <hr />
          </div>

          <div class="Your-Budget">
            <h5>Your Budget (per night)</h5>
            <div class="form-check form-switch py-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                style="width: 32px; font-weight: 700; height: 16px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Set your own budget</label
              >
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  EGP 1,000 – EGP 2,000
                </label>
              </div>
              <span class="text-muted cost1"> 3 </span>
            </div>
            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  EGP 2,000 – EGP 3,000</label
                >
              </div>
              <span class="text-muted cost1"> 9 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  EGP 3,000 – EGP 4,000
                </label>
              </div>
              <span class="text-muted cost1"> 26</span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  EGP 4,000 + EGP 4,000
                </label>
              </div>
              <span class="text-muted cost1"> 173</span>
            </div>
            <hr />
          </div>

          <div class="Popular-Filters">
            <h5>Popular Filters</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hotels</label
                >
              </div>
              <span class="text-muted"> 819 </span>
            </div>
            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  5 stars</label
                >
              </div>
              <span class="text-muted"> 179 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Breakfast Included
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Good: 7+
                </label>
              </div>
              <span class="text-muted"> 44 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Less than 3 km
                </label>
              </div>
              <span class="text-muted"> 58 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hyde Park
                </label>
              </div>
              <span class="text-muted"> 466 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Central London
                </label>
              </div>
              <span class="text-muted"> 466 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Apartments
                </label>
              </div>
              <span class="text-muted"> 533 </span>
            </div>
            <hr />
          </div>

          <div class="Health&safety">
            <h5>Health & safety</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Properties that take health & safety measures</label
                >
              </div>
              <span class="text-muted cost3"> 1167 </span>
            </div>
            <hr />
          </div>

          <div class="Sustainability">
            <h5>Sustainability</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Travel Sustainable properties</label
                >
              </div>
              <span class="text-muted cost3"> 150</span>
            </div>
            <hr />
          </div>

          <div class="Star-Rating">
            <h5>Star Rating</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  1 star
                </label>
              </div>
              <span class="text-muted"> 4 </span>
            </div>

            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  2 stars
                </label>
              </div>
              <span class="text-muted"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  3 stars
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Good: 7+
                </label>
              </div>
              <span class="text-muted"> 469</span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  4 stars
                </label>
              </div>
              <span class="text-muted"> 602 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  5 stars
                </label>
              </div>
              <span class="text-muted"> 183 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Unrated
                </label>
              </div>
              <span class="text-muted"> 422 </span>
            </div>
            <hr />
          </div>

          <div class="Distance">
            <h5>Distance from center of London</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Less than 1 km
                </label>
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Less than 3 km
                </label>
              </div>
              <span class="text-muted cost1"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Less than 5 km
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Fun-Things">
            <h5>Fun Things To Do</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Fitness center</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>
            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Fitness center
                </label>
              </div>
              <span class="text-muted"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Sauna
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Massage
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Library
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Deals">
            <h5>Deals</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >All Deals</label
                >
              </div>
              <span class="text-muted cost3"> 1167 </span>
            </div>
            <hr />
          </div>
          <div class="Cancellation-Policy">
            <h5>Cancellation Policy</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Free cancellation</label
                >
              </div>
              <span class="text-muted cost3"> 1167 </span>
            </div>
            <hr />
          </div>

          <div class="Meals">
            <h5>Meals</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Kitchen facilities</label
                >
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check py-2 space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Breakfast Included
                </label>
              </div>
              <span class="text-muted cost1"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  All-inclusive
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Massage
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Breakfast & dinner included
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Entire-places">
            <h5>Entire places</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Entire homes & apartments</label
                >
              </div>
              <span class="text-muted cost3"> 116 </span>
            </div>
            <hr />
          </div>
          <div class="Property-Type">
            <h5>Property Type</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Hotels</label
                >
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Apartments</label
                >
              </div>
              <span class="text-muted cost1"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Guesthouses
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hostels
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Bed and Breakfasts
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <hr />
          </div>
          <div class="Landmarks">
            <h5>Landmarks</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Tower of London</label
                >
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >London Eye</label
                >
              </div>
              <span class="text-muted cost1"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  London Zoo
                </label>
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Bed Preference">
            <h5>Bed Preference</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Twin beds</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Double bed</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Queen bed
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Queen bed
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Review Score">
            <h5>Review Score</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Wonderful: 9+</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Very Good: 8+</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Good: 7+</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Pleasant: 6+
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Fair: 5+</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Facilities">
            <h5>Facilities</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Parking</label
                >
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Restaurant</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Pet friendly
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Room service
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  24-hour front desk
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Non-smoking rooms
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Airport shuttle</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>
          <div class="Private-bathrooms">
            <h5>Private bathrooms</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >1+ bathrooms</label
                >
              </div>
              <span class="text-muted cost3"> 1167 </span>
            </div>
            <hr />
          </div>
          <div class="Bed Preference">
            <h5>Bed Preference</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Kitchen/Kitchenette</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Private bathroom</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Air conditioning
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Laptop-friendly workspace</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Neighborhood">
            <h5>Neighborhood</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Kitchen/Kitchenette</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Guests' Favorite Area</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Westminster Borough
                </label>
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Kensington and Chelsea</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>
          <div class="Brands">
            <h5>Brands</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >London Premier</label
                >
              </div>
              <span class="text-muted cost1"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >OYO Rooms</label
                >
              </div>
              <span class="text-muted cost1"> 117 </span>
            </div>

            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Hilton Hotels & Resorts</label
                >
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Doubletree by Hilton</label
                >
              </div>
              <span class="text-muted cost1"> 24 </span>
            </div>
            <hr />
          </div>
          <div class="Property-Accessibility">
            <h5>Property Accessibility</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Wheelchair accessible</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Toilet with grab rails</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Raised toilet</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Lowered sink</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <hr />
          </div>

          <div class="Room Accessibility">
            <h5>Room Accessibility</h5>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Entire unit located on ground floor</label
                >
              </div>
              <span class="text-muted"> 114 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Upper floors accessible by elevator</label
                >
              </div>
              <span class="text-muted"> 117 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Entire unit wheelchair accessible</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
            <div class="form-check space">
              <div class="">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Toilet with grab rails</label
                >
              </div>
              <span class="text-muted"> 24 </span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!hotels || loadingSpinner" class="col-lg results">
        <div class="result" *ngFor="let item of skeleton">
          <div class="row bg-white pt-3 pb-3 rounded">
            <div class="col col-carousel">
              <div class="carousel">
                <div class="item text-center">
                  <ngx-skeleton-loader
                    count=" 1"
                    [theme]="{ height: '200px', width: '200px' }"
                  >
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="col col-description">
              <div class="description">
                <div>
                  <div class="title">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '20px',
                        width: '55%',
                        'margin-right': '30px'
                      }"
                    >
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader
                      appearance="circle"
                      count="5"
                      [theme]="{
                        height: '10px',
                        width: '10px',
                        'vertical-align': 'top',
                        'margin-right': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                  <div class="zone">
                    <div class="d-block">
                      <ngx-skeleton-loader
                        appearance="circle"
                        count="1"
                        [theme]="{
                          height: '10px',
                          width: '10px',
                          'vertical-align': 'super',
                          'margin-right': '0px'
                        }"
                      >
                      </ngx-skeleton-loader>
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{
                          height: '10px',
                          width: '30%',
                          'margin-left': '10px'
                        }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="address">
                    <ngx-skeleton-loader
                      count="4"
                      [theme]="{
                        height: '10px',
                        width: '100%',
                        'margin-bottom': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                </div>
                <div>
                  <div class="tip">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '60%',
                        'margin-bottom': '0px',
                        display: 'block'
                      }"
                    >
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '30%',
                        'margin-bottom': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-amount">
              <div class="amount">
                <div class="address">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '10px', width: '60%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div class="parking">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '10px', width: '100%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div class="price text-center">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '30px', width: '80%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div class="select">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '50px', width: '100%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="hotels && !loadingSpinner" class="col-lg results">
        <div *ngFor="let hotel of hotels; let i = index" class="result">
          <span *ngIf="i == 0 && page === 1" class="execlusive">exclusive</span>
          <div class="row bg-white pt-3 pb-3 rounded">
            <div class="col col-carousel">
              <div class="carousel">
                <div class="item">
                  <img
                    alt="Hotel image"
                    src="{{ hotel.basis_info.HotelImageInfo?.Url }}"
                    class="fluid-img"
                    title=" Hotel image"
                  />
                </div>
              </div>
            </div>
            <div class="col col-description">
              <div class="description">
                <div>
                  <div class="title">
                    <h4>
                      {{ hotel.basis_info.HotelName }}
                      <!-- <div class="rate rate{{ rates[i] }}">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div> -->
                    </h4>
                  </div>
                  <!-- <div class="zone">
                    <a
                      class="d-block"
                      href="http://maps.google.com/maps?q={{
                        hotel.basis_info.LocationInfo.Latitude
                      }},{{ hotel.basis_info.LocationInfo.Longitude }}"
                      target="_blank"
                    >
                      <i class="fas fa-map-marker-alt"></i>
                      {{ hotel.basis_info.Address.CityName }} City - Show Map
                    </a>
                  </div> -->
                  <div style="margin-top: 3vh;min-height: 9vh;">
                  <div class="address">
                    <span
                      *ngIf="hotel.basis_info.RoomsInfo[0]?.Text[0]?.length > 0"
                      class="text"
                    >
                      {{
                        hotel.basis_info.RoomsInfo[0]?.Text[0]?.slice(0, 100)
                      }}</span
                    >
                    <!-- class="text-hidden d-none" -->
                    <span >{{
                      hotel.basis_info.RoomsInfo[0]?.Text[0]?.slice(
                        100,
                        hotel.basis_info.RoomsInfo[0]?.Text[0]?.length
                      )
                    }}</span>
                    <a
                      *ngIf="
                        hotel.basis_info.RoomsInfo[0]?.Text[0]?.length > 100
                      "
                      class="show-more more-btn"
                      (click)="toggleTextView($event)"
                      >. . . Show More</a
                    >
                    <a
                      *ngIf="
                        hotel.basis_info.RoomsInfo[0]?.Text[0]?.length > 100
                      "
                      class="show-more less-btn d-none"
                      (click)="toggleTextView($event)"
                    >
                      Show less</a
                    >
                  </div>
                  <div class="parking">
                    <p class="mb-1" *ngIf="amenities[i]?.length > 0">
                      <i class="fas fa-check"></i>
                      <span
                        *ngFor="
                          let amenity of amenities[i] | slice: 0:5;
                          let isLast = last
                        "
                      >
                        <ng-container *ngIf="!isLast">
                          {{ amenity }} |
                        </ng-container>
                        <ng-container *ngIf="isLast">{{
                          amenity
                        }}</ng-container>
                      </span>
                      <a
                        *ngIf="amenities[i]?.length > 5"
                        class="show-more more-btn"
                        (click)="toggleAmenitiesView($event)"
                        >. . . Show More</a
                      >
                      <a
                        *ngIf="amenities[i]?.length > 5"
                        class="show-more less-btn d-none"
                        (click)="toggleAmenitiesView($event)"
                      >
                        Show less</a
                      >
                    </p>
                    <div
                      *ngIf="amenities[i]?.length > 5"
                      class="mb-2 more-amenities d-none"
                    >
                      <span
                        *ngFor="
                          let amenity of amenities[i] | slice: 5;
                          let isLast = last
                        "
                      >
                        <ng-container *ngIf="!isLast">
                          {{ amenity }} |
                        </ng-container>
                        <ng-container *ngIf="isLast"
                          >{{ amenity }}
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M13 3H6v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8H10V7h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><path d="M18.5,3H6C4.9,3,4,3.9,4,5v5.71c0,3.83,2.95,7.18,6.78,7.29c3.96,0.12,7.22-3.06,7.22-7v-1h0.5c1.93,0,3.5-1.57,3.5-3.5 S20.43,3,18.5,3z M16,5v3H6V5H16z M18.5,8H18V5h0.5C19.33,5,20,5.67,20,6.5S19.33,8,18.5,8z M4,19h16v2H4V19z"/></g></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 5V3H3v2l8 9v5H6v2h12v-2h-5v-5l8-9zM7.43 7L5.66 5h12.69l-1.78 2H7.43z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.57 14.86L22 13.43 20.57 12 17 15.57 8.43 7 12 3.43 10.57 2 9.14 3.43 7.71 2 5.57 4.14 4.14 2.71 2.71 4.14l1.43 1.43L2 7.71l1.43 1.43L2 10.57 3.43 12 7 8.43 15.57 17 12 20.57 13.43 22l1.43-1.43L16.29 22l2.14-2.14 1.43 1.43 1.43-1.43-1.43-1.43L22 16.29z"/></svg>
                </div> -->
                </div>

                <div>
                  <!-- <div>
                    <p style="color: red;" *ngIf="priceInfo[i][0]?.AdditionalFeesInclusive">
                      Additional charges may apply
                    </p>
                  </div> -->
                  <div class="tip">

                    <p *ngIf="cancelPenalties[i]?.Refundable">
                      <span> Risk Free </span>
                      You can cancel
                      <ng-container *ngIf="cancelPenalties[i]?.Deadline">
                        <ng-container
                          *ngIf="cancelPenalties[i]?.Deadline.AbsoluteDeadline"
                        >
                          before
                          {{
                            cancelPenalties[i]?.Deadline.AbsoluteDeadline
                              | date: "MMM, d yyyy"
                          }}
                        </ng-container>
                        <ng-container
                          *ngIf="cancelPenalties[i]?.Deadline.OffsetDropTime"
                        >
                          {{
                            cancelPenalties[i]?.Deadline.OffsetUnitMultiplier
                          }}
                          {{ cancelPenalties[i]?.Deadline.OffsetTimeUnit }}
                          <ng-container
                            *ngIf="
                              cancelPenalties[i]?.Deadline
                                .OffsetUnitMultiplier > 1
                            "
                          >
                            s
                          </ng-container>
                          {{ cancelPenalties[i]?.Deadline.OffsetDropTime }}
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!cancelPenalties[i]?.Deadline">
                        for free!
                      </ng-container>
                    </p>
                    <p
                      *ngIf="cancelPenalties[i]?.PenaltyDescription"
                      class="penalty"
                    >
                      {{ cancelPenalties[i]?.PenaltyDescription.Text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-amount">
              <div class="amount">
                <div class="price text-end">
                  {{ priceInfo[i][0]?.AmountAfterTax | currency }}
                </div>
                <div class="address">
                  <p style="text-align: end;">
                    <span *ngIf="priceInfo[i][0]?.Nights === 1">
                      {{ priceInfo[i][0]?.Nights }}
                      night,
                    </span>
                    <span *ngIf="priceInfo[i][0]?.Nights !== 1">
                      {{ priceInfo[i][0]?.Nights }}
                      nights,
                    </span>
                    <span *ngIf="searchData.adults === 1">
                      {{ searchData.adults }} adult
                    </span>
                    <span *ngIf="searchData.adults !== 1">
                      {{ searchData.adults }} adults
                    </span>
                  </p>

                </div>
                <div class="parking"></div>
                <div style="margin-top: 6rem;">


                <div class="select">
                  <a [routerLink]="['/hotel', hotel.basis_info.HotelCode]"
                    >SELECT</a
                  >
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row ngb-pagination">
          <ngb-pagination
            *ngIf="collectionSize > 0"
            class="d-flex justify-content-center"
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="collectionSize"
            (pageChange)="getNewPage()"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
