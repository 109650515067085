<div class="details-traveler" *ngIf="travellers && travellers.length > 0">
  <div class="section-details">
    <div class="form-details">
      <div class="header-content">
        <img title="Travellers" alt="Additional-Info" src="./assets/images/dashboard/Additional-Info.svg">
        <div class="item-header">
          <h4>Travellers</h4>
          <p>When you book your trip you can Automatically select your co-traveller And book faster. </p>
        </div>
      </div>
      <hr class="hr">
      <div class="travellers" *ngIf="travellers">
        <div *ngFor="let traveller of travellers; let i = index" class="members mb-2">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-10">
                <div class="row align-items-center name">
                  <img class="mr-2 w-auto" alt="Traveller name" title="Travellers-Image" src="./assets/images/dashboard/Additional-Info.svg">
                  <span class="w-auto">{{traveller.GivenName.toUpperCase()}}</span>
                  <span class="w-auto" *ngIf="traveller.MiddleName">{{traveller.MiddleName.toUpperCase()}}</span>
                  <span class="w-auto">{{traveller.Surname.toUpperCase()}}</span>
                </div>
              </div>
              <div class="col-2">
                <div class="row">
                  <mat-checkbox class="col-6" [checked]="true" (change)="toggleCheck($event ,traveller)"></mat-checkbox>
                  <i class="fa fa-times ml-3 col-6" (click)="delete(traveller)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
