<div class="container-fluid position-relative">
  <div class="col-12">
    <div class="row justify-content-between">
      <div
        class="video-section"
        style="
          height: 375px;
          background-image: url('./assets/webp/EgyptAirBanner-1.webp');
          background-size: contain;
          background-repeat: no-repeat;

          @media screen and (max-width: 1399px) and (min-width: 1024px) {
            height: 375px;
          }
        "
      >
        <!--        <ng-container *ngIf="autoPlay">-->
        <!--          <video style="height: 97%" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"-->
        <!--            class="img-fluid" title="EGYPT" controls-->
        <!--            src="https://player.vimeo.com/external/590636003.hd.mp4?s=c3df8f46b6465c63f82fcc546ec565f27ac05d46&profile_id=175">-->
        <!--          </video>-->
        <!--        </ng-container>-->
        <!--        <ng-container *ngIf="!autoPlay">-->
        <!--          <video style="height: 555px" muted="" playsinline="" loop="" poster="./assets/webp/EgyptAirBanner-1.webp"-->
        <!--            onloadedmetadata="this.muted = true" class="img-fluid" title="EGYPT"-->
        <!--            src="https://player.vimeo.com/external/590636003.hd.mp4?s=c3df8f46b6465c63f82fcc546ec565f27ac05d46&profile_id=175">-->
        <!--          </video>-->
        <!--        </ng-container>-->
        <ng-container *ngIf="showEngine">
          <app-engine-static
            [origin]="origin"
            [destination]="destination"
            [tripType]="tripType"
            [stops]="stops"
            [airline]="airline"
          >
          </app-engine-static>
        </ng-container>
        <button
          class="btn"
          *ngIf="!showEngine"
          (click)="showEngine = !showEngine"
        >
          BOOK NOW
        </button>
      </div>
      <div class="details-section">
        <!--
        <h1>SPECIAL OFFER</h1>
        <p>
          Fly From New York To Cairo
          <br>
          and save up to 25%
          <br>
          Tickets must be issued <br> on/before 10 DEC 2021
          <br>

          -->
        <img
          title="SPECIAL OFFER"
          style="width: 300px; height: 240px"
          alt="SPECIAL OFFER"
          src="./assets/webp/egyptairlogo.webp"
        />
        <!--
          <br class="show">
          Valid for Travel <br>
          <span> commencing on/after<br> 16AUG2021 - 30NOV2021</span>
        </p>
        -->
      </div>
    </div>
  </div>
</div>
