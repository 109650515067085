import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data: any = [
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T01:10:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T02:25:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 692,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 692,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 475,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T18:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 1,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 1,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 490,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 817.95,
        "AdultPrice": 817.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 706,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 817.95,
          "Tax": 111.95,
          "BaseFare": 706
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "UV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-0"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T17:10:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T18:30:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 303,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 303,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T16:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T19:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 304,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 304,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 645,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-2"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T01:20:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-10T21:55:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 120,
            "ElapsedTime": 755,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "8",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "AA",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 9000,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "American Airlines",
            "OperatingAirlineCode": "AA",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T10:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 1303,
            "ElapsedTime": 225,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1303,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 600,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T17:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 1302,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1302,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:30:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T16:10:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 701,
            "ElapsedTime": 880,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "8",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 701,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 740,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 859.95,
        "AdultPrice": 859.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 682,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 859.95,
          "Tax": 177.95,
          "BaseFare": 682
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "OLUSP1RX",
      "GoverningCarrier": "QR",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "50 PCT QMILES ACCUMULATION",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAG 1 PIECE 7 KG",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "3": [
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-21"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T19:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 389,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 389,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 585,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T21:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T01:00:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 388,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 388,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 315,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-3"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T01:10:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T02:25:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 692,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 692,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 475,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T19:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T22:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 11,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 11,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 710,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 817.95,
        "AdultPrice": 817.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 706,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 817.95,
          "Tax": 111.95,
          "BaseFare": 706
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "UV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-1"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T19:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 389,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 389,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 585,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:55:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T03:05:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 386,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 386,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 190,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-4"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T01:20:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-10T21:55:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 120,
            "ElapsedTime": 755,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "8",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "AA",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 9000,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "American Airlines",
            "OperatingAirlineCode": "AA",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T10:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 1303,
            "ElapsedTime": 225,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1303,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 600,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:40:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T03:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 1306,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1306,
            "AircraftName": "Boeing 777-200LR / Boeing 777F",
            "AircraftCode": "77L",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T14:45:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T21:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 705,
            "ElapsedTime": 880,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "8",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 705,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 660,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 856.35,
        "AdultPrice": 856.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 174.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 682,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 856.35,
          "Tax": 174.35,
          "BaseFare": 682
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "OLUSP1RX",
      "GoverningCarrier": "QR",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "50 PCT QMILES ACCUMULATION",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAG 1 PIECE 7 KG",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "3": [
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-19"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T09:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 690,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 690,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 880,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T18:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 1,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 1,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 490,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 886.95,
        "AdultPrice": 886.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 775,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 886.95,
          "Tax": 111.95,
          "BaseFare": 775
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "PV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-23"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T19:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 389,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 389,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 585,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T16:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T19:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 304,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 304,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 645,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-5"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T13:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T16:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 14,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 14,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 945,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 896.15,
        "AdultPrice": 896.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 896.15,
          "Tax": 135.15,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-24"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T19:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 389,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 389,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 585,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T19:45:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T22:55:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 302,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 302,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 440,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-6"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 411,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 917.25,
        "AdultPrice": 917.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 917.25,
          "Tax": 135.75,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-31"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T21:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T22:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 387,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 387,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 710,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T21:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T01:00:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 388,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 388,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 315,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-7"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T13:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T16:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 14,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 14,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 945,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 919.45,
        "AdultPrice": 919.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 137.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 919.45,
          "Tax": 137.95,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-32"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 922.25,
        "AdultPrice": 922.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 161.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 922.25,
          "Tax": 161.25,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-34"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T14:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 20,
            "ElapsedTime": 690,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 20,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T21:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T22:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 387,
            "ElapsedTime": 140,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 387,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 710,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:55:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T03:05:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 386,
            "ElapsedTime": 130,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JED",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 386,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "King Abdulaziz International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T06:15:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 21,
            "ElapsedTime": 815,
            "DepartureAirport": "JED",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SV",
            "MarketingAirline": "SV",
            "MarketingFlightNumber": 21,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Saudi Arabian Airlines",
            "OperatingAirlineCode": "SV",
            "DepartureAirportName": "King Abdulaziz International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 190,
            "allow": {
              "id": 4,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 853.87,
        "AdultPrice": 853.87,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 139.87,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 714,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 853.87,
          "Tax": 139.87,
          "BaseFare": 714
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "NLRTUS",
      "GoverningCarrier": "SV",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "4": [
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 4,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 107,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 267,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-8"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:20:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T22:40:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 387,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 387,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 710,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T16:20:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T19:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 304,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 304,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 645,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-9"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:20:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T22:40:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 387,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 387,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 710,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T19:45:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T22:55:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 302,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 302,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 440,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-10"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T13:45:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T15:05:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 305,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 305,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 255,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T21:50:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-21T01:00:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 388,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 388,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 315,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-11"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T13:45:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T15:05:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 305,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 305,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 255,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T23:55:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-21T03:05:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 386,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 386,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 190,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-12"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T13:45:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T15:05:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 305,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 305,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 255,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T16:20:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T19:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 304,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 304,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 645,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-13"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T13:45:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T15:05:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 305,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 305,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 255,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T19:45:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T22:55:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 302,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 302,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 440,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-14"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T17:10:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T18:30:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 303,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 303,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T21:50:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-21T01:00:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 388,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 388,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 315,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-15"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T17:10:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T18:30:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 303,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 303,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T23:55:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-21T03:05:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 386,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 386,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 190,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-16"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T14:00:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 20,
    //         "ElapsedTime": 690,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 20,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T17:10:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T18:30:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 303,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 303,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T19:45:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T22:55:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 302,
    //         "ElapsedTime": 130,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JED",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 302,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "King Abdulaziz International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T06:15:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-21T11:50:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 21,
    //         "ElapsedTime": 815,
    //         "DepartureAirport": "JED",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "SV",
    //         "MarketingAirline": "SV",
    //         "MarketingFlightNumber": 21,
    //         "AircraftName": "BOEING 777",
    //         "AircraftCode": "777",
    //         "OperatingAirlineName": "Saudi Arabian Airlines",
    //         "OperatingAirlineCode": "SV",
    //         "DepartureAirportName": "King Abdulaziz International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "N"
    //         ],
    //         "FlightLayoverTime": 440,
    //         "allow": {
    //           "id": 4,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 853.87,
    //     "AdultPrice": 853.87,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 139.87,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 714,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 853.87,
    //       "Tax": 139.87,
    //       "BaseFare": 714
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": true,
    //   "FareBasisCode": "NLRTUS",
    //   "GoverningCarrier": "SV",
    //   "BrandFeatures": [],
    //   "Fees": 0.5,
    //   "allow": {
    //     "4": [
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 4,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 107,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Refundable": true,
    //         "Cat16Info": null,
    //         "Amount": 267,
    //         "CurrencyCode": "USD"
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-17"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T01:20:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-10T21:55:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 120,
            "ElapsedTime": 755,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "8",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "AA",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 9000,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "American Airlines",
            "OperatingAirlineCode": "AA",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T10:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 1303,
            "ElapsedTime": 225,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1303,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 600,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:40:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T03:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 1306,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1306,
            "AircraftName": "Boeing 777-200LR / Boeing 777F",
            "AircraftCode": "77L",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:30:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T16:10:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 701,
            "ElapsedTime": 880,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "8",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 701,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 345,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 856.35,
        "AdultPrice": 856.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 174.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 682,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 856.35,
          "Tax": 174.35,
          "BaseFare": 682
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "OLUSP1RX",
      "GoverningCarrier": "QR",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "50 PCT QMILES ACCUMULATION",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAG 1 PIECE 7 KG",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "3": [
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-18"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T01:20:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-10T21:55:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 120,
            "ElapsedTime": 755,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "8",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "AA",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 9000,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "American Airlines",
            "OperatingAirlineCode": "AA",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:30:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 1301,
            "ElapsedTime": 225,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1301,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 995,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:40:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T03:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 1306,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1306,
            "AircraftName": "Boeing 777-200LR / Boeing 777F",
            "AircraftCode": "77L",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:30:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T16:10:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 701,
            "ElapsedTime": 880,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "8",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 701,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "O"
            ],
            "FlightLayoverTime": 345,
            "allow": {
              "id": 3,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PCT QMILES ACCUMULATION",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAG 1 PIECE 7 KG",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 859.95,
        "AdultPrice": 859.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 682,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 859.95,
          "Tax": 177.95,
          "BaseFare": 682
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "OLUSP1RX",
      "GoverningCarrier": "QR",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "50 PCT QMILES ACCUMULATION",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2 PCS 23 KG EACH",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAG 1 PIECE 7 KG",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "3": [
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 3,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 405,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 605,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-20"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T13:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:40:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 4,
            "ElapsedTime": 550,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 4,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T01:10:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T02:25:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 692,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 692,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 1110,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T18:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 1,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 1,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 490,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 886.95,
        "AdultPrice": 886.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 775,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 886.95,
          "Tax": 111.95,
          "BaseFare": 775
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "PV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-22"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T09:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 690,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 690,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 880,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T18:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 1,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 1,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 490,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 886.95,
        "AdultPrice": 886.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 775,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 886.95,
          "Tax": 111.95,
          "BaseFare": 775
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "PV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-23"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 896.15,
        "AdultPrice": 896.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 896.15,
          "Tax": 135.15,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-25"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 896.75,
        "AdultPrice": 896.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 896.75,
          "Tax": 135.75,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-26"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 915.35,
        "AdultPrice": 915.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 154.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 915.35,
          "Tax": 154.35,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-27"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 916.65,
        "AdultPrice": 916.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 916.65,
          "Tax": 135.15,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-28"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T13:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T16:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 14,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 14,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 945,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 916.65,
        "AdultPrice": 916.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 916.65,
          "Tax": 135.15,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-29"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 916.65,
        "AdultPrice": 916.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 135.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 916.65,
          "Tax": 135.15,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-30"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 919.45,
        "AdultPrice": 919.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 137.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 919.45,
          "Tax": 137.95,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-33"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 922.25,
        "AdultPrice": 922.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 161.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 922.25,
          "Tax": 161.25,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-35"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T21:05:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T10:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 15,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "UA",
    //         "MarketingFlightNumber": 9715,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 100,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 922.25,
    //     "AdultPrice": 922.25,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 761,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 922.25,
    //       "Tax": 161.25,
    //       "BaseFare": 761
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "UA",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PERSONAL ITEM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM QUALIFYING CREDIT",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM SEAT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "FIRST EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "SECOND EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "CARRY ON BAGGAGE",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "TS": [
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY BOARDING",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "11": [
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-36"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 285,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 926.15,
    //     "AdultPrice": 926.15,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 144.65,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 926.15,
    //       "Tax": 144.65,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-37"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 938.55,
        "AdultPrice": 938.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 157.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 938.55,
          "Tax": 157.05,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-38"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T12:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2285,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2285,
    //         "AircraftName": "Canadair Regional Jet 900",
    //         "AircraftCode": "CR9",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 185,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 565,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 938.55,
    //     "AdultPrice": 938.55,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 157.05,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 938.55,
    //       "Tax": 157.05,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-39"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T13:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2287,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2287,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 290,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 938.55,
    //     "AdultPrice": 938.55,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 157.05,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 938.55,
    //       "Tax": 157.05,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-40"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T16:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T18:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2289,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2289,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 545,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 205,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 938.55,
    //     "AdultPrice": 938.55,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 157.05,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 938.55,
    //       "Tax": 157.05,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-41"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 941.45,
        "AdultPrice": 941.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 180.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 941.45,
          "Tax": 180.45,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-42"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T19:05:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:30:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 694,
            "ElapsedTime": 145,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 694,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 110,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T19:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T22:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 11,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 11,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 710,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 941.95,
        "AdultPrice": 941.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 830,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 941.95,
          "Tax": 111.95,
          "BaseFare": 830
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "VLV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-44"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 720,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 95,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 941.45,
        "AdultPrice": 941.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 180.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 941.45,
          "Tax": 180.45,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-43"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T19:05:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T20:30:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 694,
            "ElapsedTime": 145,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 694,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 110,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T18:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 1,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 1,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 490,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 941.95,
        "AdultPrice": 941.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 830,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 941.95,
          "Tax": 111.95,
          "BaseFare": 830
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "VLV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-45"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 240,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 942.75,
        "AdultPrice": 942.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 161.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 942.75,
          "Tax": 161.25,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-46"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 340,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-47"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 370,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-48"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 370,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-49"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T11:10:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2807,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2807,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 55,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T13:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 16,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 16,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 755,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-50"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T21:05:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T10:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 15,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 15,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 100,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-51"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T21:05:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T10:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 15,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 15,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 100,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 942.75,
    //     "AdultPrice": 942.75,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 161.25,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 942.75,
    //       "Tax": 161.25,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-52"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 945.55,
        "AdultPrice": 945.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 164.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 945.55,
          "Tax": 164.05,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-53"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:10:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 88,
    //         "ElapsedTime": 495,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "VIE",
    //         "OperatingAirline": "OS",
    //         "MarketingAirline": "OS",
    //         "MarketingFlightNumber": 88,
    //         "AircraftName": "BOEING 767-300",
    //         "AircraftCode": "76W",
    //         "OperatingAirlineName": "Austrian Airlines",
    //         "OperatingAirlineCode": "OS",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Vienna International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 16,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 6,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T11:10:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T15:35:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 863,
    //         "ElapsedTime": 205,
    //         "DepartureAirport": "VIE",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "OS",
    //         "MarketingAirline": "OS",
    //         "MarketingFlightNumber": 863,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Austrian Airlines",
    //         "OperatingAirlineCode": "OS",
    //         "DepartureAirportName": "Vienna International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 165,
    //         "allow": {
    //           "id": 16,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 6,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 16,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 6,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 16,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 6,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 16,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 6,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 945.55,
    //     "AdultPrice": 945.55,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 164.05,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 945.55,
    //       "Tax": 164.05,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "OS",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "16": [
    //       {
    //         "id": 16,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 16,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 16,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 16,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 16,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 6,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 6,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 6,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 6,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 6,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-54"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 948.35,
        "AdultPrice": 948.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 187.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 761,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 948.35,
          "Tax": 187.35,
          "BaseFare": 761
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-55"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "UA",
    //         "MarketingFlightNumber": 9719,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T11:10:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2807,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2807,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 55,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 948.35,
    //     "AdultPrice": 948.35,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 761,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 948.35,
    //       "Tax": 187.35,
    //       "BaseFare": 761
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "UA",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PERSONAL ITEM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM QUALIFYING CREDIT",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM SEAT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "FIRST EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "SECOND EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "CARRY ON BAGGAGE",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "TS": [
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY BOARDING",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "11": [
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-56"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 285,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 952.25,
    //     "AdultPrice": 952.25,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 170.75,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 952.25,
    //       "Tax": 170.75,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-57"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 285,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 952.25,
    //     "AdultPrice": 952.25,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 170.75,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 952.25,
    //       "Tax": 170.75,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-58"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 262,
            "ElapsedTime": 450,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 262,
            "AircraftName": "Airbus A330-900",
            "AircraftCode": "339",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 655,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 895,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 614,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 3451,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 958.45,
        "AdultPrice": 958.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 800,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 958.45,
          "Tax": 158.45,
          "BaseFare": 800
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-59"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T12:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 440,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8709,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 895,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 614,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 3451,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 958.45,
        "AdultPrice": 958.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 800,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 958.45,
          "Tax": 158.45,
          "BaseFare": 800
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-60"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8742,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 895,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 614,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 3451,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 958.45,
        "AdultPrice": 958.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 800,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 958.45,
          "Tax": 158.45,
          "BaseFare": 800
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-61"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 964.65,
        "AdultPrice": 964.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 183.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 781.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 964.65,
          "Tax": 183.15,
          "BaseFare": 781.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-62"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T09:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T10:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2283,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2283,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 85,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 665,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-63"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T12:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2285,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2285,
    //         "AircraftName": "Canadair Regional Jet 900",
    //         "AircraftCode": "CR9",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 185,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 565,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-64"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T12:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2285,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2285,
    //         "AircraftName": "Canadair Regional Jet 900",
    //         "AircraftCode": "CR9",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 185,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 565,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-65"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T13:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2287,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2287,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 290,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-66"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T13:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2287,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "CL",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2287,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa CityLine",
    //         "OperatingAirlineCode": "CL",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 290,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 460,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-67"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T16:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T18:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2289,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2289,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 545,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 205,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-68"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T18:25:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:40:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 502,
    //         "ElapsedTime": 435,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "BRU",
    //         "OperatingAirline": "SN",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 5621,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Brussels Airlines",
    //         "OperatingAirlineCode": "SN",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Brussels National Arpt",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T16:45:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T18:00:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2289,
    //         "ElapsedTime": 75,
    //         "DepartureAirport": "BRU",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 2289,
    //         "AircraftName": "Airbus A319",
    //         "AircraftCode": "319",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Brussels National Arpt",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 545,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 205,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 2,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 4,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 964.65,
    //     "AdultPrice": 964.65,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 183.15,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 964.65,
    //       "Tax": 183.15,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LH",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE FLTS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "2": [
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 2,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 4,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-69"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T21:05:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T10:45:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 15,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "UA",
    //         "MarketingFlightNumber": 9715,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 100,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T03:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 587,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 587,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "S"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-20T15:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 410,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 410,
    //         "AircraftName": "Airbus A340-600",
    //         "AircraftCode": "346",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "S"
    //         ],
    //         "FlightLayoverTime": 370,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 966.45,
    //     "AdultPrice": 966.45,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 144.45,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 822,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 966.45,
    //       "Tax": 144.45,
    //       "BaseFare": 822
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "UA",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PERSONAL ITEM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM QUALIFYING CREDIT",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM SEAT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "FIRST EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "SECOND EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "CARRY ON BAGGAGE",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "TS": [
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY BOARDING",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "11": [
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-70"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T17:30:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T07:10:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 411,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "UA",
    //         "MarketingFlightNumber": 8870,
    //         "AircraftName": "Airbus A340-600",
    //         "AircraftCode": "346",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T21:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T02:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 586,
    //         "ElapsedTime": 225,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 586,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 855,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T03:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 587,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "MUC",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 587,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Munich International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "S"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PERSONAL ITEM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM QUALIFYING CREDIT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREMIUM SEAT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND EXCESS BAG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON BAGGAGE",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-20T15:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 410,
    //         "ElapsedTime": 555,
    //         "DepartureAirport": "MUC",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LH",
    //         "MarketingAirline": "LH",
    //         "MarketingFlightNumber": 410,
    //         "AircraftName": "Airbus A340-600",
    //         "AircraftCode": "346",
    //         "OperatingAirlineName": "Lufthansa",
    //         "OperatingAirlineCode": "LH",
    //         "DepartureAirportName": "Munich International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "S"
    //         ],
    //         "FlightLayoverTime": 370,
    //         "allow": {
    //           "id": 11,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 5,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE FLTS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 967.05,
    //     "AdultPrice": 967.05,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 145.05,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 822,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 967.05,
    //       "Tax": 145.05,
    //       "BaseFare": 822
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "UA",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PERSONAL ITEM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM QUALIFYING CREDIT",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREMIUM SEAT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "FIRST EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "SECOND EXCESS BAG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "CARRY ON BAGGAGE",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "TS": [
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY BOARDING",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "11": [
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 11,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 5,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": true,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-71"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 180,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 240,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-72"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 180,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 240,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-73"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 340,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-74"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T16:35:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 460,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 17,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T14:05:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T19:00:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 772,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "MS",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 4190,
    //         "AircraftName": "BOEING 737 800",
    //         "AircraftCode": "73H",
    //         "OperatingAirlineName": "Egyptair",
    //         "OperatingAirlineCode": "MS",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 340,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-75"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T11:10:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2807,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2807,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 55,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T07:30:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T08:25:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2802,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2802,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 615,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 195,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-76"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T19:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T09:20:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 23,
    //         "ElapsedTime": 455,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 23,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T10:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T11:10:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2807,
    //         "ElapsedTime": 55,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2807,
    //         "AircraftName": "Airbus A320",
    //         "AircraftCode": "320",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 55,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T12:25:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-11T17:20:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 238,
    //         "ElapsedTime": 235,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 238,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T18:10:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T21:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 239,
    //         "ElapsedTime": 245,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "ZRH",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 239,
    //         "AircraftName": "AIRBUS A321NEO",
    //         "AircraftCode": "32Q",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Zurich Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T09:15:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T10:05:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2804,
    //         "ElapsedTime": 50,
    //         "DepartureAirport": "ZRH",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "GVA",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 2804,
    //         "AircraftName": "AIRBUS A220-300",
    //         "AircraftCode": "223",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Zurich Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Geneva International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 720,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "PREFERRED SEAT RESERVATION",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE BEFORE DEPARTURE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGE AFTER DEPARTURE",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CHECKED BAG UP TO 23KG",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 CABIN BAG UP TO 8 KG",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "WIFI",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "IN FLIGHT ENTERTAINMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "LG": [
    //             {
    //               "serviceGroup": "LG",
    //               "commercialName": "LOUNGE ACCESS",
    //               "application": "C"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "CATERING ON INTERCONT FLTS",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-21T11:40:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-21T14:40:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 22,
    //         "ElapsedTime": 540,
    //         "DepartureAirport": "GVA",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "LX",
    //         "MarketingAirline": "LX",
    //         "MarketingFlightNumber": 22,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Swiss International Air Lines",
    //         "OperatingAirlineCode": "LX",
    //         "DepartureAirportName": "Geneva International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "T"
    //         ],
    //         "FlightLayoverTime": 95,
    //         "allow": {
    //           "id": 8,
    //           "Pieces": 1,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": {
    //           "id": 3,
    //           "Pieces": null,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //           "EquivalentAmount": 100,
    //           "EquivalentCurrency": "USD",
    //           "FirstPiece": 1,
    //           "LastPiece": 1
    //         },
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 968.85,
    //     "AdultPrice": 968.85,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 187.35,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 781.5,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 968.85,
    //       "Tax": 187.35,
    //       "BaseFare": 781.5
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "TLA76NCS",
    //   "GoverningCarrier": "LX",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "PREFERRED SEAT RESERVATION",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE BEFORE DEPARTURE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGE AFTER DEPARTURE",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CHECKED BAG UP TO 23KG",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 CABIN BAG UP TO 8 KG",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "WIFI",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "IN FLIGHT ENTERTAINMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "LG": [
    //       {
    //         "serviceGroup": "LG",
    //         "commercialName": "LOUNGE ACCESS",
    //         "application": "C"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON EUROPE AND CAI FLT",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "CATERING ON INTERCONT FLTS",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "8": [
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 8,
    //         "Pieces": 1,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ],
    //     [
    //       {
    //         "id": 3,
    //         "Pieces": null,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
    //         "EquivalentAmount": 100,
    //         "EquivalentCurrency": "USD",
    //         "FirstPiece": 1,
    //         "LastPiece": 1
    //       }
    //     ]
    //   ],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-77"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 9,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 570,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 895,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 614,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "AZ",
            "MarketingFlightNumber": 3451,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 975.45,
        "AdultPrice": 975.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 817,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 975.45,
          "Tax": 158.45,
          "BaseFare": 817
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "QJ3L86M6",
      "GoverningCarrier": "AF",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHOICE OF STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD MILES ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "17": [
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-78"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T13:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:40:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 4,
            "ElapsedTime": 550,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 4,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T09:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 690,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 690,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T19:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T22:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 11,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 11,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 710,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 976.95,
        "AdultPrice": 976.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 865,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 976.95,
          "Tax": 111.95,
          "BaseFare": 865
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LLV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-79"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T13:30:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T06:40:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 4,
    //         "ElapsedTime": 550,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "IST",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 4,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Istanbul Ataturk Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T07:55:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-11T09:10:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 690,
    //         "ElapsedTime": 135,
    //         "DepartureAirport": "IST",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 690,
    //         "AircraftName": "Airbus A321",
    //         "AircraftCode": "321",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Istanbul Ataturk Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 75,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T03:50:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T07:10:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 693,
    //         "ElapsedTime": 140,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "IST",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 693,
    //         "AircraftName": "Airbus A330-200",
    //         "AircraftCode": "332",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Istanbul Ataturk Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "U"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T15:20:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-20T18:25:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 1,
    //         "ElapsedTime": 665,
    //         "DepartureAirport": "IST",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 1,
    //         "AircraftName": "Airbus A350-900",
    //         "AircraftCode": "359",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Istanbul Ataturk Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "U"
    //         ],
    //         "FlightLayoverTime": 490,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 976.95,
    //     "AdultPrice": 976.95,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 111.95,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 865,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 976.95,
    //       "Tax": 111.95,
    //       "BaseFare": 865
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "LLV3XPC",
    //   "GoverningCarrier": "TK",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "REFUNDABLE TICKET",
    //         "application": "D"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "BAG INCLUDED",
    //         "application": "F"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "MEAL SERVICE",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "7": [
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 135,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 135,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-80"
    // },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T23:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T17:15:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 12,
    //         "ElapsedTime": 570,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "IST",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 12,
    //         "AircraftName": "Airbus A350-900",
    //         "AircraftCode": "359",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Istanbul Ataturk Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "U"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-12T01:10:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-12T02:25:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 692,
    //         "ElapsedTime": 135,
    //         "DepartureAirport": "IST",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 692,
    //         "AircraftName": "Airbus A330-200",
    //         "AircraftCode": "332",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Istanbul Ataturk Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "U"
    //         ],
    //         "FlightLayoverTime": 475,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T10:00:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T13:30:00",
    //         "ArrivalTimezone": "+03:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 691,
    //         "ElapsedTime": 150,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": null,
    //         "ArrivalAirport": "IST",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 691,
    //         "AircraftName": "Airbus A321",
    //         "AircraftCode": "321",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Istanbul Ataturk Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T19:00:00",
    //         "DepartureTimezone": "+03:00",
    //         "ArrivalDateTime": "2022-11-20T22:05:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 11,
    //         "ElapsedTime": 665,
    //         "DepartureAirport": "IST",
    //         "DepartureAirportTerminal": null,
    //         "ArrivalAirportTerminal": "1",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "TK",
    //         "MarketingAirline": "TK",
    //         "MarketingFlightNumber": 11,
    //         "AircraftName": "Airbus A330-300",
    //         "AircraftCode": "333",
    //         "OperatingAirlineName": "Turkish Airlines",
    //         "OperatingAirlineCode": "TK",
    //         "DepartureAirportName": "Istanbul Ataturk Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 330,
    //         "allow": {
    //           "id": 7,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "BAG INCLUDED",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "MEAL SERVICE",
    //               "application": "F"
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 976.95,
    //     "AdultPrice": 976.95,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 111.95,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 865,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 976.95,
    //       "Tax": 111.95,
    //       "BaseFare": 865
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "UV3XPC",
    //   "GoverningCarrier": "TK",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "REFUNDABLE TICKET",
    //         "application": "D"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "BAG INCLUDED",
    //         "application": "F"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "MEAL SERVICE",
    //         "application": "F"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "7": [
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 7,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 135,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 135,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-81"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 262,
            "ElapsedTime": 450,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 262,
            "AircraftName": "Airbus A330-900",
            "AircraftCode": "339",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 655,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 979.45,
        "AdultPrice": 979.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 821,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 979.45,
          "Tax": 158.45,
          "BaseFare": 821
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-82"
    },
    // {
    //   "flights": [
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-10T23:30:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-11T12:50:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 17,
    //         "ElapsedTime": 440,
    //         "DepartureAirport": "JFK",
    //         "DepartureAirportTerminal": "1",
    //         "ArrivalAirportTerminal": "2E",
    //         "ArrivalAirport": "CDG",
    //         "OperatingAirline": "AF",
    //         "MarketingAirline": "DL",
    //         "MarketingFlightNumber": 8709,
    //         "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
    //         "AircraftCode": "772",
    //         "OperatingAirlineName": "Air France",
    //         "OperatingAirlineCode": "AF",
    //         "DepartureAirportName": "John F. Kennedy International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Charles de Gaulle Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 12,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD BOARDING",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
    //               "application": "F"
    //             }
    //           ],
    //           "FF": [
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "STATUS ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INTERNET ACCESS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INFLIGHT ENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "ALCOHOLIC DRINK",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "SNACK",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "SB": [
    //             {
    //               "serviceGroup": "SB",
    //               "commercialName": "STANDBY",
    //               "application": "D"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY CHECK IN",
    //               "application": "D"
    //             }
    //           ],
    //           "UN": [
    //             {
    //               "serviceGroup": "UN",
    //               "commercialName": "UNACCOMPANIED MINOR",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-11T18:55:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-12T00:15:00",
    //         "ArrivalTimezone": "+02:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 570,
    //         "ElapsedTime": 260,
    //         "DepartureAirport": "CDG",
    //         "DepartureAirportTerminal": "2E",
    //         "ArrivalAirportTerminal": "2",
    //         "ArrivalAirport": "CAI",
    //         "OperatingAirline": "AF",
    //         "MarketingAirline": "DL",
    //         "MarketingFlightNumber": 8622,
    //         "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
    //         "AircraftCode": "772",
    //         "OperatingAirlineName": "Air France",
    //         "OperatingAirlineCode": "AF",
    //         "DepartureAirportName": "Charles de Gaulle Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Cairo International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "L"
    //         ],
    //         "FlightLayoverTime": 365,
    //         "allow": {
    //           "id": 12,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD BOARDING",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
    //               "application": "F"
    //             }
    //           ],
    //           "FF": [
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "STATUS ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INTERNET ACCESS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INFLIGHT ENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "ALCOHOLIC DRINK",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "SNACK",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "SB": [
    //             {
    //               "serviceGroup": "SB",
    //               "commercialName": "STANDBY",
    //               "application": "D"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY CHECK IN",
    //               "application": "D"
    //             }
    //           ],
    //           "UN": [
    //             {
    //               "serviceGroup": "UN",
    //               "commercialName": "UNACCOMPANIED MINOR",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       }
    //     ],
    //     [
    //       {
    //         "DepartureDateTime": "2022-11-20T03:35:00",
    //         "DepartureTimezone": "+02:00",
    //         "ArrivalDateTime": "2022-11-20T06:15:00",
    //         "ArrivalTimezone": "+01:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 895,
    //         "ElapsedTime": 220,
    //         "DepartureAirport": "CAI",
    //         "DepartureAirportTerminal": "2",
    //         "ArrivalAirportTerminal": "3",
    //         "ArrivalAirport": "FCO",
    //         "OperatingAirline": "AZ",
    //         "MarketingAirline": "DL",
    //         "MarketingFlightNumber": 6700,
    //         "AircraftName": "AIRBUS INDUSTRIE A321",
    //         "AircraftCode": "32S",
    //         "OperatingAirlineName": "Alitalia",
    //         "OperatingAirlineCode": "AZ",
    //         "DepartureAirportName": "Cairo International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "Leonardo da Vinci International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "X"
    //         ],
    //         "FlightLayoverTime": 0,
    //         "allow": {
    //           "id": 12,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD BOARDING",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
    //               "application": "F"
    //             }
    //           ],
    //           "FF": [
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "STATUS ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INTERNET ACCESS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INFLIGHT ENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "ALCOHOLIC DRINK",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "SNACK",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "SB": [
    //             {
    //               "serviceGroup": "SB",
    //               "commercialName": "STANDBY",
    //               "application": "D"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY CHECK IN",
    //               "application": "D"
    //             }
    //           ],
    //           "UN": [
    //             {
    //               "serviceGroup": "UN",
    //               "commercialName": "UNACCOMPANIED MINOR",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T09:50:00",
    //         "DepartureTimezone": "+01:00",
    //         "ArrivalDateTime": "2022-11-20T13:25:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 614,
    //         "ElapsedTime": 575,
    //         "DepartureAirport": "FCO",
    //         "DepartureAirportTerminal": "3",
    //         "ArrivalAirportTerminal": "E",
    //         "ArrivalAirport": "BOS",
    //         "OperatingAirline": "AZ",
    //         "MarketingAirline": "DL",
    //         "MarketingFlightNumber": 6588,
    //         "AircraftName": "Airbus A330",
    //         "AircraftCode": "330",
    //         "OperatingAirlineName": "Alitalia",
    //         "OperatingAirlineCode": "AZ",
    //         "DepartureAirportName": "Leonardo da Vinci International Airport",
    //         "DepartureCityName": "",
    //         "ArrivalAirportName": "Logan International Airport",
    //         "ArrivalCitytName": "true",
    //         "FareBookingCode": [
    //           "X"
    //         ],
    //         "FlightLayoverTime": 215,
    //         "allow": {
    //           "id": 12,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": {
    //           "BF": [
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "CHANGEABLE TICKET",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "REFUNDABLE TICKET",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "UPGRADE ELIGIBILITY",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD SEAT",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BF",
    //               "commercialName": "STANDARD BOARDING",
    //               "application": "F"
    //             }
    //           ],
    //           "BG": [
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "BG",
    //               "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
    //               "application": "F"
    //             }
    //           ],
    //           "FF": [
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "MILEAGE ACCRUAL",
    //               "application": "F"
    //             },
    //             {
    //               "serviceGroup": "FF",
    //               "commercialName": "STATUS ACCRUAL",
    //               "application": "F"
    //             }
    //           ],
    //           "IE": [
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INTERNET ACCESS",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "IE",
    //               "commercialName": "INFLIGHT ENT",
    //               "application": "F"
    //             }
    //           ],
    //           "ML": [
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "ALCOHOLIC DRINK",
    //               "application": "C"
    //             },
    //             {
    //               "serviceGroup": "ML",
    //               "commercialName": "SNACK",
    //               "application": "F"
    //             }
    //           ],
    //           "SA": [
    //             {
    //               "serviceGroup": "SA",
    //               "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //               "application": "F"
    //             }
    //           ],
    //           "SB": [
    //             {
    //               "serviceGroup": "SB",
    //               "commercialName": "STANDBY",
    //               "application": "D"
    //             }
    //           ],
    //           "TS": [
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY BOARDING",
    //               "application": "D"
    //             },
    //             {
    //               "serviceGroup": "TS",
    //               "commercialName": "PRIORITY CHECK IN",
    //               "application": "D"
    //             }
    //           ],
    //           "UN": [
    //             {
    //               "serviceGroup": "UN",
    //               "commercialName": "UNACCOMPANIED MINOR",
    //               "application": "C"
    //             }
    //           ]
    //         }
    //       },
    //       {
    //         "DepartureDateTime": "2022-11-20T15:45:00",
    //         "DepartureTimezone": "-05:00",
    //         "ArrivalDateTime": "2022-11-20T17:11:00",
    //         "ArrivalTimezone": "-05:00",
    //         "StopQuantity": 0,
    //         "FlightNumber": 2468,
    //         "ElapsedTime": 86,
    //         "DepartureAirport": "BOS",
    //         "DepartureAirportTerminal": "A",
    //         "ArrivalAirportTerminal": "4",
    //         "ArrivalAirport": "JFK",
    //         "OperatingAirline": "DL",
    //         "MarketingAirline": "DL",
    //         "MarketingFlightNumber": 2468,
    //         "AircraftName": "221",
    //         "AircraftCode": "221",
    //         "OperatingAirlineName": "Delta Air Lines",
    //         "OperatingAirlineCode": "DL",
    //         "DepartureAirportName": "Logan International Airport",
    //         "DepartureCityName": "true",
    //         "ArrivalAirportName": "John F. Kennedy International Airport",
    //         "ArrivalCitytName": "",
    //         "FareBookingCode": [
    //           "X"
    //         ],
    //         "FlightLayoverTime": 140,
    //         "allow": {
    //           "id": 12,
    //           "Pieces": 2,
    //           "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //           "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //         },
    //         "charge": [],
    //         "BrandFeatures": []
    //       }
    //     ]
    //   ],
    //   "prices": {
    //     "ItinTotalFare": 979.45,
    //     "AdultPrice": 979.45,
    //     "AdultPriceWithOffer": 0,
    //     "FareClassName": "Economy"
    //   },
    //   "taxfees": {
    //     "Amount": 158.45,
    //     "CurrencyCode": "USD"
    //   },
    //   "BaseFare": {
    //     "Amount": 821,
    //     "CurrencyCode": "USD"
    //   },
    //   "Cabin": "Y",
    //   "passengerDetails": [
    //     {
    //       "PassengerType": "Adult",
    //       "PassengerTypeCode": "ADT",
    //       "Quantity": 1,
    //       "Price": 979.45,
    //       "Tax": 158.45,
    //       "BaseFare": 821
    //     },
    //     {
    //       "PassengerType": "Child",
    //       "PassengerTypeCode": "CNN",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Lap",
    //       "PassengerTypeCode": "INF",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     },
    //     {
    //       "PassengerType": "Infant Seat",
    //       "PassengerTypeCode": "INS",
    //       "Quantity": 0,
    //       "Price": 0,
    //       "Tax": 0,
    //       "BaseFare": 0
    //     }
    //   ],
    //   "refundable": false,
    //   "FareBasisCode": "LJ3L86M6",
    //   "GoverningCarrier": "DL",
    //   "BrandFeatures": {
    //     "BF": [
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "CHANGEABLE TICKET",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "REFUNDABLE TICKET",
    //         "application": "D"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "UPGRADE ELIGIBILITY",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD SEAT",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BF",
    //         "commercialName": "STANDARD BOARDING",
    //         "application": "F"
    //       }
    //     ],
    //     "BG": [
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "BG",
    //         "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
    //         "application": "F"
    //       }
    //     ],
    //     "FF": [
    //       {
    //         "serviceGroup": "FF",
    //         "commercialName": "MILEAGE ACCRUAL",
    //         "application": "F"
    //       },
    //       {
    //         "serviceGroup": "FF",
    //         "commercialName": "STATUS ACCRUAL",
    //         "application": "F"
    //       }
    //     ],
    //     "IE": [
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "INTERNET ACCESS",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "IE",
    //         "commercialName": "INFLIGHT ENT",
    //         "application": "F"
    //       }
    //     ],
    //     "ML": [
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "ALCOHOLIC DRINK",
    //         "application": "C"
    //       },
    //       {
    //         "serviceGroup": "ML",
    //         "commercialName": "SNACK",
    //         "application": "F"
    //       }
    //     ],
    //     "SA": [
    //       {
    //         "serviceGroup": "SA",
    //         "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
    //         "application": "F"
    //       }
    //     ],
    //     "SB": [
    //       {
    //         "serviceGroup": "SB",
    //         "commercialName": "STANDBY",
    //         "application": "D"
    //       }
    //     ],
    //     "TS": [
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY BOARDING",
    //         "application": "D"
    //       },
    //       {
    //         "serviceGroup": "TS",
    //         "commercialName": "PRIORITY CHECK IN",
    //         "application": "D"
    //       }
    //     ],
    //     "UN": [
    //       {
    //         "serviceGroup": "UN",
    //         "commercialName": "UNACCOMPANIED MINOR",
    //         "application": "C"
    //       }
    //     ]
    //   },
    //   "Fees": 0.5,
    //   "allow": {
    //     "12": [
    //       {
    //         "id": 12,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 12,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 12,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 12,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       },
    //       {
    //         "id": 12,
    //         "Pieces": 2,
    //         "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
    //         "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
    //       }
    //     ]
    //   },
    //   "charge": [],
    //   "Penalties": {
    //     "Exchange": {
    //       "Before": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       },
    //       "After": {
    //         "Changeable": true,
    //         "ConditionsApply": null,
    //         "Amount": 0,
    //         "CurrencyCode": "USD"
    //       }
    //     },
    //     "Refund": {
    //       "Before": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       },
    //       "After": {
    //         "Refundable": false,
    //         "Cat16Info": null,
    //         "Amount": null,
    //         "CurrencyCode": null
    //       }
    //     }
    //   },
    //   "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-83"
    // },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8742,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 979.45,
        "AdultPrice": 979.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 821,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 979.45,
          "Tax": 158.45,
          "BaseFare": 821
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-84"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 798,
            "ElapsedTime": 200,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 7139,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 980.45,
        "AdultPrice": 980.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 137.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 980.45,
          "Tax": 137.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-85"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 985.65,
        "AdultPrice": 985.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 163.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 985.65,
          "Tax": 163.65,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-86"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 986.95,
        "AdultPrice": 986.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 144.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 986.95,
          "Tax": 144.45,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-87"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 986.95,
        "AdultPrice": 986.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 144.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 986.95,
          "Tax": 144.45,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-88"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 411,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 987.55,
        "AdultPrice": 987.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 145.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 987.55,
          "Tax": 145.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-89"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 989.75,
        "AdultPrice": 989.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 147.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 989.75,
          "Tax": 147.25,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-90"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 992.55,
        "AdultPrice": 992.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 170.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 992.55,
          "Tax": 170.55,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-91"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 285,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 996.45,
        "AdultPrice": 996.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 153.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 996.45,
          "Tax": 153.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-92"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 9,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 570,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 996.45,
        "AdultPrice": 996.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 838,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 996.45,
          "Tax": 158.45,
          "BaseFare": 838
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "QJ3L86M6",
      "GoverningCarrier": "AF",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHOICE OF STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD MILES ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "17": [
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-93"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 998.05,
        "AdultPrice": 998.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 998.05,
          "Tax": 176.05,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-94"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 998.05,
        "AdultPrice": 998.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 998.05,
          "Tax": 176.05,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-95"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 998.05,
        "AdultPrice": 998.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 998.05,
          "Tax": 176.05,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-96"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 5990,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:50:00",
            "DepartureTimezone": "50:00Z",
            "ArrivalDateTime": "2022-11-11T17:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 1781,
            "ElapsedTime": 80,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8721,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 235,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 998.75,
        "AdultPrice": 998.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 821,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 998.75,
          "Tax": 177.75,
          "BaseFare": 821
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-97"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 798,
            "ElapsedTime": 200,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 7139,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 615,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1006.55,
        "AdultPrice": 1006.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 164.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1006.55,
          "Tax": 164.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-98"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 798,
            "ElapsedTime": 200,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 7139,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 720,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T11:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 540,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 95,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1006.55,
        "AdultPrice": 1006.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 164.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1006.55,
          "Tax": 164.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-99"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1008.85,
        "AdultPrice": 1008.85,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 166.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1008.85,
          "Tax": 166.35,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-100"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2289,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2289,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 545,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1008.85,
        "AdultPrice": 1008.85,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 166.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1008.85,
          "Tax": 166.35,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-101"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T12:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2285,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2285,
            "AircraftName": "Canadair Regional Jet 900",
            "AircraftCode": "CR9",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 565,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1008.85,
        "AdultPrice": 1008.85,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 166.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1008.85,
          "Tax": 166.35,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-102"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T13:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2287,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2287,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 290,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1008.85,
        "AdultPrice": 1008.85,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 166.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1008.85,
          "Tax": 166.35,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-103"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 108,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 108,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 145,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 375,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1010.65,
        "AdultPrice": 1010.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1010.65,
          "Tax": 168.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-104"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T15:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T16:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 110,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 110,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 315,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1010.65,
        "AdultPrice": 1010.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1010.65,
          "Tax": 168.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-105"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 112,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 255,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1010.65,
        "AdultPrice": 1010.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1010.65,
          "Tax": 168.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-106"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T17:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 114,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 114,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1010.65,
        "AdultPrice": 1010.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1010.65,
          "Tax": 168.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-107"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T13:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T14:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 106,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 106,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 435,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T18:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 239,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 239,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 16,
            "ElapsedTime": 555,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 16,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 755,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1010.65,
        "AdultPrice": 1010.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1010.65,
          "Tax": 168.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-108"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T01:10:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T02:25:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 692,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 692,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 475,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:50:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T07:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 693,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 693,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:50:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T11:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 3,
            "ElapsedTime": 660,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 3,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1011.95,
        "AdultPrice": 1011.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 900,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1011.95,
          "Tax": 111.95,
          "BaseFare": 900
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "UV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-109"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 340,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1013.05,
        "AdultPrice": 1013.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 170.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1013.05,
          "Tax": 170.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-110"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 240,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1013.05,
        "AdultPrice": 1013.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 170.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1013.05,
          "Tax": 170.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-111"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1013.05,
        "AdultPrice": 1013.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 170.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1013.05,
          "Tax": 170.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-112"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1017.25,
        "AdultPrice": 1017.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 195.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1017.25,
          "Tax": 195.25,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-113"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1017.25,
        "AdultPrice": 1017.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 195.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1017.25,
          "Tax": 195.25,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-114"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1017.25,
        "AdultPrice": 1017.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 195.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1017.25,
          "Tax": 195.25,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-115"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-116"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q", 
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-117"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-118"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-119"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-120"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 15,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1018.55,
        "AdultPrice": 1018.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1018.55,
          "Tax": 176.05,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-121"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 411,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1019.15,
        "AdultPrice": 1019.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1019.15,
          "Tax": 176.65,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-122"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 411,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1019.15,
        "AdultPrice": 1019.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1019.15,
          "Tax": 176.65,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-123"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 411,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1019.15,
        "AdultPrice": 1019.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 176.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1019.15,
          "Tax": 176.65,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-124"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1021.35,
        "AdultPrice": 1021.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 178.85,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1021.35,
          "Tax": 178.85,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-125"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1021.35,
        "AdultPrice": 1021.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 178.85,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1021.35,
          "Tax": 178.85,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-126"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 88,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 16,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 6,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1021.35,
        "AdultPrice": 1021.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 178.85,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1021.35,
          "Tax": 178.85,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "OS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "16": [
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 16,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 6,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-127"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1024.15,
        "AdultPrice": 1024.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1024.15,
          "Tax": 202.15,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-128"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1024.15,
        "AdultPrice": 1024.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1024.15,
          "Tax": 202.15,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-129"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1024.15,
        "AdultPrice": 1024.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 822,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1024.15,
          "Tax": 202.15,
          "BaseFare": 822
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-130"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 285,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1028.05,
        "AdultPrice": 1028.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 185.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1028.05,
          "Tax": 185.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-131"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 285,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1028.05,
        "AdultPrice": 1028.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 185.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1028.05,
          "Tax": 185.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-132"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 285,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1028.05,
        "AdultPrice": 1028.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 185.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1028.05,
          "Tax": 185.55,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-133"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 262,
            "ElapsedTime": 450,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 262,
            "AircraftName": "Airbus A330-900",
            "AircraftCode": "339",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 655,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1029.35,
        "AdultPrice": 1029.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 881,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1029.35,
          "Tax": 148.35,
          "BaseFare": 881
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-134"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T12:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 440,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8709,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1029.35,
        "AdultPrice": 1029.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 881,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1029.35,
          "Tax": 148.35,
          "BaseFare": 881
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-135"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8742,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1029.35,
        "AdultPrice": 1029.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 881,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1029.35,
          "Tax": 148.35,
          "BaseFare": 881
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-136"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T12:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2285,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2285,
            "AircraftName": "Canadair Regional Jet 900",
            "AircraftCode": "CR9",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 565,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-137"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2289,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2289,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 545,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-138"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2289,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2289,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 545,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-139"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2289,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2289,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 545,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-140"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T13:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2287,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2287,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 290,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-141"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T13:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2287,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2287,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 290,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-142"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T13:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2287,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2287,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 290,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-143"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T12:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2285,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2285,
            "AircraftName": "Canadair Regional Jet 900",
            "AircraftCode": "CR9",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 565,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-144"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:45:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T12:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2285,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2285,
            "AircraftName": "Canadair Regional Jet 900",
            "AircraftCode": "CR9",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 565,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-145"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-146"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-147"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 5621,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2283,
            "ElapsedTime": 75,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "CL",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 2283,
            "AircraftName": "Airbus A319",
            "AircraftCode": "319",
            "OperatingAirlineName": "Lufthansa CityLine",
            "OperatingAirlineCode": "CL",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1040.45,
        "AdultPrice": 1040.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 197.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1040.45,
          "Tax": 197.95,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-148"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 240,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-149"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 240,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-150"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 340,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-151"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 340,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-152"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T09:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2804,
            "ElapsedTime": 50,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2804,
            "AircraftName": "AIRBUS A220-300",
            "AircraftCode": "223",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 240,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-153"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:35:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 17,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2802,
            "ElapsedTime": 55,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2802,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T19:00:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 772,
            "ElapsedTime": 235,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 4190,
            "AircraftName": "BOEING 737 800",
            "AircraftCode": "73H",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 340,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-154"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-155"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-156"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 23,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8 KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE AND CAI FLT",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 8,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 3,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1044.65,
        "AdultPrice": 1044.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 202.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 842.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1044.65,
          "Tax": 202.15,
          "BaseFare": 842.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "LX",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8 KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE AND CAI FLT",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "8": [
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 8,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 3,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-157"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 12,
            "ElapsedTime": 570,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 12,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T09:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 690,
            "ElapsedTime": 135,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 690,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "P"
            ],
            "FlightLayoverTime": 880,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T10:00:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T13:30:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 691,
            "ElapsedTime": 150,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "IST",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 691,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Istanbul Ataturk Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T19:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T22:05:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 11,
            "ElapsedTime": 665,
            "DepartureAirport": "IST",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "TK",
            "MarketingAirline": "TK",
            "MarketingFlightNumber": 11,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Turkish Airlines",
            "OperatingAirlineCode": "TK",
            "DepartureAirportName": "Istanbul Ataturk Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 330,
            "allow": {
              "id": 7,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "BAG INCLUDED",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL SERVICE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1045.95,
        "AdultPrice": 1045.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 111.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 934,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1045.95,
          "Tax": 111.95,
          "BaseFare": 934
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "PV3XPC",
      "GoverningCarrier": "TK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 PIECE X 8 KG CABIN BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "BAG INCLUDED",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL SERVICE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "7": [
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 7,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 135,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-158"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 5990,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:50:00",
            "DepartureTimezone": "50:00Z",
            "ArrivalDateTime": "2022-11-11T17:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 1781,
            "ElapsedTime": 80,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8721,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 235,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1048.65,
        "AdultPrice": 1048.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 167.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 881,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1048.65,
          "Tax": 167.65,
          "BaseFare": 881
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-159"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T05:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8740,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 790,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1068.45,
        "AdultPrice": 1068.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 900,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1068.45,
          "Tax": 168.45,
          "BaseFare": 900
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "KJ3L06M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-160"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8748,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 735,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 614,
            "ElapsedTime": 575,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "E",
            "ArrivalAirport": "BOS",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6588,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Logan International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T15:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-20T17:11:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 2468,
            "ElapsedTime": 86,
            "DepartureAirport": "BOS",
            "DepartureAirportTerminal": "A",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 2468,
            "AircraftName": "221",
            "AircraftCode": "221",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "Logan International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1068.45,
        "AdultPrice": 1068.45,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 168.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 900,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1068.45,
          "Tax": 168.45,
          "BaseFare": 900
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "KJ3L06M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-161"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T16:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 112,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 255,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1079.95,
        "AdultPrice": 1079.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1079.95,
          "Tax": 177.45,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-162"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T17:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 114,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 114,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1079.95,
        "AdultPrice": 1079.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1079.95,
          "Tax": 177.45,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-163"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T15:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T16:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 110,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 110,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 205,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 315,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1079.95,
        "AdultPrice": 1079.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1079.95,
          "Tax": 177.45,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-164"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 108,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 108,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 145,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 375,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1079.95,
        "AdultPrice": 1079.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1079.95,
          "Tax": 177.45,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-165"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T13:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T14:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 106,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 106,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 435,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 410,
            "ElapsedTime": 555,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 410,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 370,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1079.95,
        "AdultPrice": 1079.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.45,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1079.95,
          "Tax": 177.45,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-166"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:00:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 262,
            "ElapsedTime": 450,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "DL",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 262,
            "AircraftName": "Airbus A330-900",
            "AircraftCode": "339",
            "OperatingAirlineName": "Delta Air Lines",
            "OperatingAirlineCode": "DL",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 655,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T10:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T14:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 608,
            "ElapsedTime": 585,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6590,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1105.35,
        "AdultPrice": 1105.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 957,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1105.35,
          "Tax": 148.35,
          "BaseFare": 957
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-167"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T12:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 17,
            "ElapsedTime": 440,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8709,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 365,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T10:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T14:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 608,
            "ElapsedTime": 585,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6590,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1105.35,
        "AdultPrice": 1105.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 957,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1105.35,
          "Tax": 148.35,
          "BaseFare": 957
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-168"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8742,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T10:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T14:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 608,
            "ElapsedTime": 585,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6590,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1105.35,
        "AdultPrice": 1105.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 957,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1105.35,
          "Tax": 148.35,
          "BaseFare": 957
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-169"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T13:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T14:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 106,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 106,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 435,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1111.55,
        "AdultPrice": 1111.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 209.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1111.55,
          "Tax": 209.05,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-170"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T13:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T14:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 106,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 106,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 85,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 435,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T09:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 95,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 95,
            "AircraftName": "AIRBUS INDUSTRIE A320 SHARKLETS",
            "AircraftCode": "32A",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1111.55,
        "AdultPrice": 1111.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 209.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1111.55,
          "Tax": 209.05,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-171"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 108,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 108,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 145,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 375,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T07:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T08:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 93,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 93,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 45,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 180,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1111.55,
        "AdultPrice": 1111.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 209.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1111.55,
          "Tax": 209.05,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-172"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 108,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 108,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 145,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 375,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1111.55,
        "AdultPrice": 1111.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 209.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1111.55,
          "Tax": 209.05,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-173"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:50:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 405,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 405,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T17:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 114,
            "ElapsedTime": 55,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 114,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 195,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:10:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 587,
            "ElapsedTime": 245,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 587,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:00:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 99,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 99,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 400,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "S"
            ],
            "FlightLayoverTime": 60,
            "allow": {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 4,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1111.55,
        "AdultPrice": 1111.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 209.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 902.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1111.55,
          "Tax": 209.05,
          "BaseFare": 902.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "SL106RCE",
      "GoverningCarrier": "LH",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "1 CHECKED BAG UP TO 23KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "2": [
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 2,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 4,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-174"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T05:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8740,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 790,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1118.35,
        "AdultPrice": 1118.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 960,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1118.35,
          "Tax": 158.35,
          "BaseFare": 960
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "KJ3L06M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-175"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8748,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 735,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T18:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 610,
            "ElapsedTime": 605,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6595,
            "AircraftName": "Airbus A330",
            "AircraftCode": "330",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 515,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1118.35,
        "AdultPrice": 1118.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 158.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 960,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1118.35,
          "Tax": 158.35,
          "BaseFare": 960
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "KJ3L06M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-176"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 5990,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:50:00",
            "DepartureTimezone": "50:00Z",
            "ArrivalDateTime": "2022-11-11T17:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 1781,
            "ElapsedTime": 80,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8721,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 235,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 8622,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T03:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 895,
            "ElapsedTime": 220,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "FCO",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6700,
            "AircraftName": "AIRBUS INDUSTRIE A321",
            "AircraftCode": "32S",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Leonardo da Vinci International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T10:40:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T14:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 608,
            "ElapsedTime": 585,
            "DepartureAirport": "FCO",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AZ",
            "MarketingAirline": "DL",
            "MarketingFlightNumber": 6590,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Alitalia",
            "OperatingAirlineCode": "AZ",
            "DepartureAirportName": "Leonardo da Vinci International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 265,
            "allow": {
              "id": 12,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
                  "application": "F"
                }
              ],
              "FF": [
                {
                  "serviceGroup": "FF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "FF",
                  "commercialName": "STATUS ACCRUAL",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENT",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "ALCOHOLIC DRINK",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "STANDBY",
                  "application": "D"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECK IN",
                  "application": "D"
                }
              ],
              "UN": [
                {
                  "serviceGroup": "UN",
                  "commercialName": "UNACCOMPANIED MINOR",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1124.65,
        "AdultPrice": 1124.65,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 167.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 957,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1124.65,
          "Tax": 167.65,
          "BaseFare": 957
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L86M6",
      "GoverningCarrier": "DL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST BAG 23K 50LB 62LI 158LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND BAG 23K50LB 62LI 158LCM",
            "application": "F"
          }
        ],
        "FF": [
          {
            "serviceGroup": "FF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "FF",
            "commercialName": "STATUS ACCRUAL",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENT",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "ALCOHOLIC DRINK",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "STANDBY",
            "application": "D"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "D"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECK IN",
            "application": "D"
          }
        ],
        "UN": [
          {
            "serviceGroup": "UN",
            "commercialName": "UNACCOMPANIED MINOR",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "12": [
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 12,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-177"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T13:05:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 9,
            "ElapsedTime": 430,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 9,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 570,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 350,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 567,
            "ElapsedTime": 290,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 567,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T08:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 22,
            "ElapsedTime": 500,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AF",
            "MarketingAirline": "AF",
            "MarketingFlightNumber": 22,
            "AircraftName": "Airbus A350-900",
            "AircraftCode": "359",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 140,
            "allow": {
              "id": 17,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1143.35,
        "AdultPrice": 1143.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 152.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 991,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1143.35,
          "Tax": 152.35,
          "BaseFare": 991
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "QJ3L86M6",
      "GoverningCarrier": "AF",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHOICE OF STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD MILES ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "17": [
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 17,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-178"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1171.95,
        "AdultPrice": 1171.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 150.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1021,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1171.95,
          "Tax": 150.95,
          "BaseFare": 1021
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-179"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T21:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T02:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 586,
            "ElapsedTime": 225,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "LH",
            "MarketingFlightNumber": 586,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 855,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1172.55,
        "AdultPrice": 1172.55,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 151.55,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1021,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1172.55,
          "Tax": 151.55,
          "BaseFare": 1021
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-180"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 411,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "MUC",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8870,
            "AircraftName": "Airbus A340-600",
            "AircraftCode": "346",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Munich International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T08:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T09:55:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 112,
            "ElapsedTime": 65,
            "DepartureAirport": "MUC",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 112,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Munich International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1191.15,
        "AdultPrice": 1191.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 170.15,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1021,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1191.15,
          "Tax": 170.15,
          "BaseFare": 1021
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-181"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 118,
            "ElapsedTime": 700,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 118,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T17:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 545,
            "ElapsedTime": 210,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 545,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:55:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:25:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 544,
            "ElapsedTime": 150,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 544,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T13:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 117,
            "ElapsedTime": 815,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 117,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 95,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1192.75,
        "AdultPrice": 1192.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1044,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1192.75,
          "Tax": 148.75,
          "BaseFare": 1044
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "BLRTUS2",
      "GoverningCarrier": "KU",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "1": [
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-182"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T19:45:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T09:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 23,
            "ElapsedTime": 455,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "GVA",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9719,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Geneva International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T10:15:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T11:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 2807,
            "ElapsedTime": 55,
            "DepartureAirport": "GVA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 2807,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Geneva International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 55,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 75,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1198.05,
        "AdultPrice": 1198.05,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 177.05,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1021,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1198.05,
          "Tax": 177.05,
          "BaseFare": 1021
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-183"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 118,
            "ElapsedTime": 700,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 118,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T17:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 545,
            "ElapsedTime": 210,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 545,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T13:55:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T17:40:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 542,
            "ElapsedTime": 165,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 542,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T08:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T13:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 117,
            "ElapsedTime": 815,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 117,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 860,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1236.75,
        "AdultPrice": 1236.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1088,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1236.75,
          "Tax": 148.75,
          "BaseFare": 1088
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "BLRTUS2",
      "GoverningCarrier": "KU",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "1": [
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-184"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 118,
            "ElapsedTime": 700,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 118,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T15:20:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T17:50:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 545,
            "ElapsedTime": 210,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 545,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 215,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T19:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T22:40:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 546,
            "ElapsedTime": 140,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 546,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-21T08:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T13:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 117,
            "ElapsedTime": 815,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 117,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 560,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1236.75,
        "AdultPrice": 1236.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1088,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1236.75,
          "Tax": 148.75,
          "BaseFare": 1088
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "BLRTUS2",
      "GoverningCarrier": "KU",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "1": [
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-185"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T17:15:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 986,
            "ElapsedTime": 630,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 986,
            "AircraftName": "Boeing 777-300",
            "AircraftCode": "773",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "E"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 10,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T10:00:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T14:40:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 985,
            "ElapsedTime": 700,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 985,
            "AircraftName": "Boeing 777-300",
            "AircraftCode": "773",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 10,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1253.95,
        "AdultPrice": 1253.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 101.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1152,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1253.95,
          "Tax": 101.95,
          "BaseFare": 1152
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "ELRIMS",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "10": [
          {
            "id": 10,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": null
          },
          {
            "id": 10,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": null
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 250,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 250,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 250,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 250,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-186"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:30:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:35:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 644,
            "ElapsedTime": 425,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "AMS",
            "OperatingAirline": "KL",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 644,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "KLM Royal Dutch Airlines",
            "OperatingAirlineCode": "KL",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Amsterdam Airport Schiphol",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:50:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T16:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 1741,
            "ElapsedTime": 80,
            "DepartureAirport": "AMS",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2F",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 2013,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Amsterdam Airport Schiphol",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 255,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T18:55:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-12T00:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 570,
            "ElapsedTime": 260,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2E",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "AF",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 2360,
            "AircraftName": "Boeing 777-200 / Boeing 777-200ER",
            "AircraftCode": "772",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:10:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 567,
            "ElapsedTime": 290,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "2E",
            "ArrivalAirport": "CDG",
            "OperatingAirline": "AF",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 2361,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Air France",
            "OperatingAirlineCode": "AF",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Charles de Gaulle Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T09:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T10:30:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 1224,
            "ElapsedTime": 85,
            "DepartureAirport": "CDG",
            "DepartureAirportTerminal": "2F",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "AMS",
            "OperatingAirline": "KL",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 1224,
            "AircraftName": "BOEING 737-700",
            "AircraftCode": "73W",
            "OperatingAirlineName": "KLM Royal Dutch Airlines",
            "OperatingAirlineCode": "KL",
            "DepartureAirportName": "Charles de Gaulle Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Amsterdam Airport Schiphol",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 175,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHOICE OF STANDARD SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD MILES ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
                  "application": "F"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T13:35:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T15:50:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 641,
            "ElapsedTime": 495,
            "DepartureAirport": "AMS",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "KL",
            "MarketingAirline": "KL",
            "MarketingFlightNumber": 641,
            "AircraftName": "BOEING DREAMLINER SERIES 10",
            "AircraftCode": "781",
            "OperatingAirlineName": "KLM Royal Dutch Airlines",
            "OperatingAirlineCode": "KL",
            "DepartureAirportName": "Amsterdam Airport Schiphol",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 14,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1265.25,
        "AdultPrice": 1265.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 175.25,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1090,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1265.25,
          "Tax": 175.25,
          "BaseFare": 1090
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "LJ3L06M6",
      "GoverningCarrier": "KL",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHOICE OF STANDARD SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "D"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD MILES ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAG 2PC OF 23KGS 158CM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CABIN BAGGAGE 12KG 1PC 115CM",
            "application": "F"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "14": [
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 14,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-187"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T16:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T11:45:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 118,
            "ElapsedTime": 700,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 118,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-11T22:50:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-12T00:55:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 543,
            "ElapsedTime": 185,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 543,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "B"
            ],
            "FlightLayoverTime": 665,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:55:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T06:25:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 544,
            "ElapsedTime": 150,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "KWI",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 544,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Kuwait International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          },
          {
            "DepartureDateTime": "2022-11-20T08:00:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-20T13:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 117,
            "ElapsedTime": 815,
            "DepartureAirport": "KWI",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "KU",
            "MarketingAirline": "KU",
            "MarketingFlightNumber": 117,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Kuwait Airways",
            "OperatingAirlineCode": "KU",
            "DepartureAirportName": "Kuwait International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "L"
            ],
            "FlightLayoverTime": 95,
            "allow": {
              "id": 1,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": []
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1317.75,
        "AdultPrice": 1317.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 148.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1169,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1317.75,
          "Tax": 148.75,
          "BaseFare": 1169
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "BLRTUS2",
      "GoverningCarrier": "KU",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
        "1": [
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 1,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 175,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": true,
            "Amount": 500,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-188"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:10:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:25:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 88,
            "ElapsedTime": 495,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "VIE",
            "OperatingAirline": "OS",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9820,
            "AircraftName": "BOEING 767-300",
            "AircraftCode": "76W",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Vienna International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T11:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T15:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 863,
            "ElapsedTime": 205,
            "DepartureAirport": "VIE",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "OS",
            "MarketingAirline": "OS",
            "MarketingFlightNumber": 863,
            "AircraftName": "Airbus A320",
            "AircraftCode": "320",
            "OperatingAirlineName": "Austrian Airlines",
            "OperatingAirlineCode": "OS",
            "DepartureAirportName": "Vienna International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 165,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1334.75,
        "AdultPrice": 1334.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 153.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1181,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1334.75,
          "Tax": 153.75,
          "BaseFare": 1181
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "WL106RCE",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-189"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T15:55:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T05:15:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 401,
            "ElapsedTime": 440,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8840,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T13:30:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T18:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 580,
            "ElapsedTime": 250,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8910,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 495,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T11:05:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T13:55:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 400,
            "ElapsedTime": 530,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8841,
            "AircraftName": "Boeing 747-400",
            "AircraftCode": "744",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 325,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1347.75,
        "AdultPrice": 1347.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 166.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1181,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1347.75,
          "Tax": 166.75,
          "BaseFare": 1181
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": true,
      "FareBasisCode": "WL106RCE",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 300,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-190"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T10:40:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T08:10:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 204,
            "ElapsedTime": 750,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "DXB",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 204,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Dubai International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T15:00:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-11T17:15:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 923,
            "ElapsedTime": 255,
            "DepartureAirport": "DXB",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 923,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Dubai International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 410,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T19:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T00:50:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 924,
            "ElapsedTime": 210,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "DXB",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 924,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Dubai International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T02:35:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-21T08:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 203,
            "ElapsedTime": 880,
            "DepartureAirport": "DXB",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 203,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Dubai International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1392.75,
        "AdultPrice": 1392.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 123.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1269,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1392.75,
          "Tax": 123.75,
          "BaseFare": 1269
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "QLEIPUS1",
      "GoverningCarrier": "EK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "30 PERCENT MILES EARNED",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAGGAGE",
            "application": "F"
          }
        ],
        "GT": [
          {
            "serviceGroup": "GT",
            "commercialName": "CHAUFFEUR DRIVE",
            "application": "D"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "ENTERTAINMENT",
            "application": "F"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE",
            "application": "D"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "5": [
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 200,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 200,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 600,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 496,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-191"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T20:25:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 202,
            "ElapsedTime": 745,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "DXB",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 202,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Dubai International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T08:10:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-12T10:25:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 927,
            "ElapsedTime": 255,
            "DepartureAirport": "DXB",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 927,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Dubai International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 705,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T19:20:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T00:50:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 924,
            "ElapsedTime": 210,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "DXB",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 924,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Dubai International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T02:35:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-21T08:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 203,
            "ElapsedTime": 880,
            "DepartureAirport": "DXB",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "EK",
            "MarketingAirline": "EK",
            "MarketingFlightNumber": 203,
            "AircraftName": "AIRBUS INDUSTRIE A380-800",
            "AircraftCode": "388",
            "OperatingAirlineName": "Emirates",
            "OperatingAirlineCode": "EK",
            "DepartureAirportName": "Dubai International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 105,
            "allow": {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "30 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CHECKED BAGGAGE",
                  "application": "F"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "INTERNET ACCESS",
                  "application": "C"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE",
                  "application": "D"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEAL",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1392.75,
        "AdultPrice": 1392.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 123.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1269,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1392.75,
          "Tax": 123.75,
          "BaseFare": 1269
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "QLEIPUS1",
      "GoverningCarrier": "EK",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "30 PERCENT MILES EARNED",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY7KG 15LB UPTO45LI 115LCM",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CHECKED BAGGAGE",
            "application": "F"
          }
        ],
        "GT": [
          {
            "serviceGroup": "GT",
            "commercialName": "CHAUFFEUR DRIVE",
            "application": "D"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "ENTERTAINMENT",
            "application": "F"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "INTERNET ACCESS",
            "application": "C"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE",
            "application": "D"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "BEVERAGE",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "MEAL",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "5": [
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          },
          {
            "id": 5,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "MAX LENGTH UP TO 59 INCHES/150 CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 200,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 200,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 600,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 496,
            "CurrencyCode": "USD"
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-192"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T21:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:45:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 15,
            "ElapsedTime": 460,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "ZRH",
            "OperatingAirline": "LX",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9715,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Zurich Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T12:25:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T17:20:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 238,
            "ElapsedTime": 235,
            "DepartureAirport": "ZRH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "LX",
            "MarketingAirline": "LX",
            "MarketingFlightNumber": 238,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Swiss International Air Lines",
            "OperatingAirlineCode": "LX",
            "DepartureAirportName": "Zurich Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "T"
            ],
            "FlightLayoverTime": 100,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T02:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T05:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 581,
            "ElapsedTime": 275,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "FRA",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 9025,
            "AircraftName": "AIRBUS A321NEO",
            "AircraftCode": "32Q",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Frankfurt Intl",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "H"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T17:10:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-20T20:25:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 404,
            "ElapsedTime": 555,
            "DepartureAirport": "FRA",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "LH",
            "MarketingAirline": "UA",
            "MarketingFlightNumber": 8843,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Lufthansa",
            "OperatingAirlineCode": "LH",
            "DepartureAirportName": "Frankfurt Intl",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "H"
            ],
            "FlightLayoverTime": 690,
            "allow": {
              "id": 11,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 5,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PERSONAL ITEM",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM QUALIFYING CREDIT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREMIUM SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON BAGGAGE",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1396.95,
        "AdultPrice": 1396.95,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 150.95,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1246,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1396.95,
          "Tax": 150.95,
          "BaseFare": 1246
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "TLA76NCS",
      "GoverningCarrier": "UA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PERSONAL ITEM",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM QUALIFYING CREDIT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "PREMIUM SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON BAGGAGE",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEAT ASSIGNMENT",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "11": [
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 11,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 5,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-193"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 10,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:00:00",
            "DepartureTimezone": "00:00Z",
            "ArrivalDateTime": "2022-11-11T20:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 778,
            "ElapsedTime": 280,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 778,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T17:40:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:00:00",
            "ArrivalTimezone": "00:00Z",
            "StopQuantity": 0,
            "FlightNumber": 779,
            "ElapsedTime": 320,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 779,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "E"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T08:55:00",
            "DepartureTimezone": "55:00Z",
            "ArrivalDateTime": "2022-11-21T12:15:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 3,
            "ElapsedTime": 500,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 3,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 715,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1422.25,
        "AdultPrice": 1422.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 198.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1223.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1422.25,
          "Tax": 198.75,
          "BaseFare": 1223.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "XLRF7SMN",
      "GoverningCarrier": "VS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT SELECTION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EARN MILES",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "HAND BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1ST BAG MAX 23KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEALS AND DRINKS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "SEAT SELECTION AT ANYTIME",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "18": [
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 600,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-194"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 10,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T22:30:00",
            "DepartureTimezone": "30:00Z",
            "ArrivalDateTime": "2022-11-12T05:10:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 780,
            "ElapsedTime": 280,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 780,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 695,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T09:15:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T12:35:00",
            "ArrivalTimezone": "35:00Z",
            "StopQuantity": 0,
            "FlightNumber": 777,
            "ElapsedTime": 320,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 777,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "E"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:35:00",
            "DepartureTimezone": "35:00Z",
            "ArrivalDateTime": "2022-11-20T17:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 137,
            "ElapsedTime": 480,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 137,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1422.25,
        "AdultPrice": 1422.25,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 198.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1223.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1422.25,
          "Tax": 198.75,
          "BaseFare": 1223.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "XLRF7SMN",
      "GoverningCarrier": "VS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT SELECTION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EARN MILES",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "HAND BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1ST BAG MAX 23KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEALS AND DRINKS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "SEAT SELECTION AT ANYTIME",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "18": [
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 600,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-195"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T23:05:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T10:55:00",
            "ArrivalTimezone": "55:00Z",
            "StopQuantity": 0,
            "FlightNumber": 10,
            "ElapsedTime": 410,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 10,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "X"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T14:00:00",
            "DepartureTimezone": "00:00Z",
            "ArrivalDateTime": "2022-11-11T20:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 778,
            "ElapsedTime": 280,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 778,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "W"
            ],
            "FlightLayoverTime": 185,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T09:15:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T12:35:00",
            "ArrivalTimezone": "35:00Z",
            "StopQuantity": 0,
            "FlightNumber": 777,
            "ElapsedTime": 320,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "LHR",
            "OperatingAirline": "MS",
            "MarketingAirline": "MS",
            "MarketingFlightNumber": 777,
            "AircraftName": "Boeing 787-9[3]",
            "AircraftCode": "789",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Heathrow Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "E"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-20T14:35:00",
            "DepartureTimezone": "35:00Z",
            "ArrivalDateTime": "2022-11-20T17:35:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 137,
            "ElapsedTime": 480,
            "DepartureAirport": "LHR",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "VS",
            "MarketingAirline": "VS",
            "MarketingFlightNumber": 137,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Virgin Atlantic Airways",
            "OperatingAirlineCode": "VS",
            "DepartureAirportName": "Heathrow Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "Q"
            ],
            "FlightLayoverTime": 120,
            "allow": {
              "id": 18,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 7,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGES",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EARN MILES",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAGGAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1ST BAG MAX 23KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WIFI",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "MEALS AND DRINKS",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "SEAT SELECTION AT ANYTIME",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1422.75,
        "AdultPrice": 1422.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 198.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1224,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1422.75,
          "Tax": 198.75,
          "BaseFare": 1224
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "XLRF7SMN",
      "GoverningCarrier": "VS",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "PREFERRED SEAT SELECTION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGES",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EARN MILES",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "HAND BAGGAGE",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1ST BAG MAX 23KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WIFI",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "MEALS AND DRINKS",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "SEAT SELECTION AT ANYTIME",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "18": [
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          },
          {
            "id": 18,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 7,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 81 LINEAR INCHES/208 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 275,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 600,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-196"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T10:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T06:35:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 704,
            "ElapsedTime": 730,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "8",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "AA",
            "MarketingFlightNumber": 8118,
            "AircraftName": "BOEING 777",
            "AircraftCode": "777",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 6,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "SAME DAY CHANGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "AADVANTAGE MILES",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BASIC SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEG ROOM",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "THIRD CHECKED BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "PERSONAL ITEM UP TO 40 LI",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WI FI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "STREAMING VIDEO",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "ADMIRALS CLUB",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "PREMIUM BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "MAIN CABIN EXTRA",
                  "application": "C"
                },
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEATS",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "SAME DAY STANDBY",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECKIN",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T07:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-11T10:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 1303,
            "ElapsedTime": 225,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1303,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 80,
            "allow": {
              "id": 6,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "SAME DAY CHANGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "AADVANTAGE MILES",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BASIC SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEG ROOM",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "THIRD CHECKED BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "PERSONAL ITEM UP TO 40 LI",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WI FI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "STREAMING VIDEO",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "ADMIRALS CLUB",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "PREMIUM BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "MAIN CABIN EXTRA",
                  "application": "C"
                },
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEATS",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "SAME DAY STANDBY",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECKIN",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T17:05:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T21:10:00",
            "ArrivalTimezone": "+03:00",
            "StopQuantity": 0,
            "FlightNumber": 1302,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "DOH",
            "OperatingAirline": "QR",
            "MarketingAirline": "QR",
            "MarketingFlightNumber": 1302,
            "AircraftName": "Airbus A330-200",
            "AircraftCode": "332",
            "OperatingAirlineName": "Qatar Airways",
            "OperatingAirlineCode": "QR",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Hamad International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 6,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "SAME DAY CHANGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "AADVANTAGE MILES",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BASIC SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEG ROOM",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "THIRD CHECKED BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "PERSONAL ITEM UP TO 40 LI",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WI FI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "STREAMING VIDEO",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "ADMIRALS CLUB",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "PREMIUM BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "MAIN CABIN EXTRA",
                  "application": "C"
                },
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEATS",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "SAME DAY STANDBY",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECKIN",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T01:55:00",
            "DepartureTimezone": "+03:00",
            "ArrivalDateTime": "2022-11-21T09:00:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 121,
            "ElapsedTime": 905,
            "DepartureAirport": "DOH",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "8",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "AA",
            "MarketingAirline": "AA",
            "MarketingFlightNumber": 121,
            "AircraftName": "Boeing 777-300ER",
            "AircraftCode": "77W",
            "OperatingAirlineName": "American Airlines",
            "OperatingAirlineCode": "AA",
            "DepartureAirportName": "Hamad International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 285,
            "allow": {
              "id": 6,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
              "EquivalentAmount": 100,
              "EquivalentCurrency": "USD",
              "FirstPiece": 1,
              "LastPiece": 1
            },
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "SAME DAY CHANGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "AADVANTAGE MILES",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BASIC SEAT",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEG ROOM",
                  "application": "C"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "FIRST EXCESS BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "SECOND EXCESS BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "THIRD CHECKED BAG",
                  "application": "C"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "PERSONAL ITEM UP TO 40 LI",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "WI FI",
                  "application": "C"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "IN FLIGHT ENTERTAINMENT",
                  "application": "F"
                },
                {
                  "serviceGroup": "IE",
                  "commercialName": "STREAMING VIDEO",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "ADMIRALS CLUB",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "PREMIUM BEVERAGE",
                  "application": "F"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "SNACK",
                  "application": "F"
                }
              ],
              "SA": [
                {
                  "serviceGroup": "SA",
                  "commercialName": "MAIN CABIN EXTRA",
                  "application": "C"
                },
                {
                  "serviceGroup": "SA",
                  "commercialName": "PRE RESERVED SEATS",
                  "application": "F"
                }
              ],
              "SB": [
                {
                  "serviceGroup": "SB",
                  "commercialName": "SAME DAY STANDBY",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY CHECKIN",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "STANDARD BOARDING",
                  "application": "F"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY BOARDING",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1727.35,
        "AdultPrice": 1727.35,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 174.35,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1553,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1727.35,
          "Tax": 174.35,
          "BaseFare": 1553
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "NLN0CQN7",
      "GoverningCarrier": "AA",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "SAME DAY CHANGE",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "AADVANTAGE MILES",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "BASIC SEAT",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEG ROOM",
            "application": "C"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "FIRST EXCESS BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "SECOND EXCESS BAG",
            "application": "C"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "THIRD CHECKED BAG",
            "application": "C"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "PERSONAL ITEM UP TO 40 LI",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "CARRY ON UP TO 45 LI 115 LCM",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "WI FI",
            "application": "C"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "IN FLIGHT ENTERTAINMENT",
            "application": "F"
          },
          {
            "serviceGroup": "IE",
            "commercialName": "STREAMING VIDEO",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "ADMIRALS CLUB",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "PREMIUM BEVERAGE",
            "application": "F"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "SNACK",
            "application": "F"
          }
        ],
        "SA": [
          {
            "serviceGroup": "SA",
            "commercialName": "MAIN CABIN EXTRA",
            "application": "C"
          },
          {
            "serviceGroup": "SA",
            "commercialName": "PRE RESERVED SEATS",
            "application": "F"
          }
        ],
        "SB": [
          {
            "serviceGroup": "SB",
            "commercialName": "SAME DAY STANDBY",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY CHECKIN",
            "application": "C"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "STANDARD BOARDING",
            "application": "F"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY BOARDING",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "6": [
          {
            "id": 6,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 6,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 6,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 6,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [
        [
          {
            "id": 1,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 1,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 1,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        [
          {
            "id": 1,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      ],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": true,
            "Cat16Info": null,
            "Amount": 305,
            "CurrencyCode": "USD"
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-197"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T22:00:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T19:30:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 100,
            "ElapsedTime": 750,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "4",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "AUH",
            "OperatingAirline": "EY",
            "MarketingAirline": "EY",
            "MarketingFlightNumber": 100,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Etihad Airways",
            "OperatingAirlineCode": "EY",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalCitytName": "AUH",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 15,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "NEIGHBOUR FREE SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BID TO UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "75 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FREE CHECKED BAG UPTO 2P",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "GOLF EQUIPMENT UP TO 15 KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "EXCESS WEIGHT SPECIAL CHARGE",
                  "application": "C"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH BUSINESS CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH FIRST CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH ARRIVAL LOUNGE",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "FOOD AND BEVERAGE",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "NO SHOW FEE",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY ACCESS",
                  "application": "C"
                }
              ],
              "UP": [
                {
                  "serviceGroup": "UP",
                  "commercialName": "INSTANT UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "UP",
                  "commercialName": "PUSH UPGRADE 48H",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-12T02:30:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-12T04:40:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 657,
            "ElapsedTime": 250,
            "DepartureAirport": "AUH",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "2",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "EY",
            "MarketingAirline": "EY",
            "MarketingFlightNumber": 657,
            "AircraftName": "Airbus A321",
            "AircraftCode": "321",
            "OperatingAirlineName": "Etihad Airways",
            "OperatingAirlineCode": "EY",
            "DepartureCityName": "AUH",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "K"
            ],
            "FlightLayoverTime": 420,
            "allow": {
              "id": 15,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "NEIGHBOUR FREE SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BID TO UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FREE CHECKED BAG UPTO 2P",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "GOLF EQUIPMENT UP TO 15 KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "EXCESS WEIGHT SPECIAL CHARGE",
                  "application": "C"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH BUSINESS CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH FIRST CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH ARRIVAL LOUNGE",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "FOOD AND BEVERAGE",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "NO SHOW FEE",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY ACCESS",
                  "application": "C"
                }
              ],
              "UP": [
                {
                  "serviceGroup": "UP",
                  "commercialName": "INSTANT UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "UP",
                  "commercialName": "PUSH UPGRADE 48H",
                  "application": "C"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T23:00:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-21T04:05:00",
            "ArrivalTimezone": "+04:00",
            "StopQuantity": 0,
            "FlightNumber": 916,
            "ElapsedTime": 185,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "AUH",
            "OperatingAirline": "MS",
            "MarketingAirline": "EY",
            "MarketingFlightNumber": 4904,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalCitytName": "AUH",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 15,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "NEIGHBOUR FREE SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BID TO UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "75 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FREE CHECKED BAG UPTO 2P",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "GOLF EQUIPMENT UP TO 15 KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "EXCESS WEIGHT SPECIAL CHARGE",
                  "application": "C"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH BUSINESS CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH FIRST CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH ARRIVAL LOUNGE",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "FOOD AND BEVERAGE",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "NO SHOW FEE",
                  "application": "C"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY ACCESS",
                  "application": "C"
                }
              ],
              "UP": [
                {
                  "serviceGroup": "UP",
                  "commercialName": "INSTANT UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "UP",
                  "commercialName": "PUSH UPGRADE 48H",
                  "application": "C"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T10:25:00",
            "DepartureTimezone": "+04:00",
            "ArrivalDateTime": "2022-11-21T16:10:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 101,
            "ElapsedTime": 885,
            "DepartureAirport": "AUH",
            "DepartureAirportTerminal": "3",
            "ArrivalAirportTerminal": "4",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "EY",
            "MarketingAirline": "EY",
            "MarketingFlightNumber": 101,
            "AircraftName": "Airbus A350-1000[2]",
            "AircraftCode": "351",
            "OperatingAirlineName": "Etihad Airways",
            "OperatingAirlineCode": "EY",
            "DepartureCityName": "AUH",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "U"
            ],
            "FlightLayoverTime": 380,
            "allow": {
              "id": 15,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGEABLE TICKET",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "REFUNDABLE TICKET",
                  "application": "D"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT SELECTION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "NEIGHBOUR FREE SEAT",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "BID TO UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "50 PERCENT MILES EARNED",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "HAND BAG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "FREE CHECKED BAG UPTO 2P",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "GOLF EQUIPMENT UP TO 15 KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "EXCESS WEIGHT SPECIAL CHARGE",
                  "application": "C"
                }
              ],
              "GT": [
                {
                  "serviceGroup": "GT",
                  "commercialName": "CHAUFFEUR DRIVE",
                  "application": "D"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH BUSINESS CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH FIRST CLASS LOUNGE",
                  "application": "C"
                },
                {
                  "serviceGroup": "LG",
                  "commercialName": "AUH ARRIVAL LOUNGE",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "FOOD AND BEVERAGE",
                  "application": "F"
                }
              ],
              "TS": [
                {
                  "serviceGroup": "TS",
                  "commercialName": "NO SHOW FEE",
                  "application": "D"
                },
                {
                  "serviceGroup": "TS",
                  "commercialName": "PRIORITY ACCESS",
                  "application": "C"
                }
              ],
              "UP": [
                {
                  "serviceGroup": "UP",
                  "commercialName": "INSTANT UPGRADE",
                  "application": "C"
                },
                {
                  "serviceGroup": "UP",
                  "commercialName": "PUSH UPGRADE 48H",
                  "application": "C"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1736.75,
        "AdultPrice": 1736.75,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
      },
      "taxfees": {
        "Amount": 123.75,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1613,
        "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1736.75,
          "Tax": 123.75,
          "BaseFare": 1613
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "KLC2US",
      "GoverningCarrier": "EY",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGEABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "REFUNDABLE TICKET",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT SELECTION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "EXTRA LEGROOM SEAT SELECTION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "NEIGHBOUR FREE SEAT",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "BID TO UPGRADE",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "75 PERCENT MILES EARNED",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "HAND BAG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "FREE CHECKED BAG UPTO 2P",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "GOLF EQUIPMENT UP TO 15 KG",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "EXCESS WEIGHT SPECIAL CHARGE",
            "application": "C"
          }
        ],
        "GT": [
          {
            "serviceGroup": "GT",
            "commercialName": "CHAUFFEUR DRIVE",
            "application": "D"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "AUH BUSINESS CLASS LOUNGE",
            "application": "C"
          },
          {
            "serviceGroup": "LG",
            "commercialName": "AUH FIRST CLASS LOUNGE",
            "application": "C"
          },
          {
            "serviceGroup": "LG",
            "commercialName": "AUH ARRIVAL LOUNGE",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "FOOD AND BEVERAGE",
            "application": "F"
          }
        ],
        "TS": [
          {
            "serviceGroup": "TS",
            "commercialName": "NO SHOW FEE",
            "application": "C"
          },
          {
            "serviceGroup": "TS",
            "commercialName": "PRIORITY ACCESS",
            "application": "C"
          }
        ],
        "UP": [
          {
            "serviceGroup": "UP",
            "commercialName": "INSTANT UPGRADE",
            "application": "C"
          },
          {
            "serviceGroup": "UP",
            "commercialName": "PUSH UPGRADE 48H",
            "application": "C"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "15": [
          {
            "id": 15,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 15,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 15,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 15,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": [],
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 820,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": null,
            "Amount": 820,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-198"
    },
    {
      "flights": [
        [
          {
            "DepartureDateTime": "2022-11-10T18:25:00",
            "DepartureTimezone": "-05:00",
            "ArrivalDateTime": "2022-11-11T07:40:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 502,
            "ElapsedTime": 435,
            "DepartureAirport": "JFK",
            "DepartureAirportTerminal": "1",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "SN",
            "MarketingAirline": "SN",
            "MarketingFlightNumber": 502,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "John F. Kennedy International Airport",
            "DepartureCityName": "",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "N"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 9,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "2 CHECKED BAGS UP TO 23KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-11T15:20:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-11T20:35:00",
            "ArrivalTimezone": "+02:00",
            "StopQuantity": 0,
            "FlightNumber": 726,
            "ElapsedTime": 255,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "3",
            "ArrivalAirport": "CAI",
            "OperatingAirline": "MS",
            "MarketingAirline": "SN",
            "MarketingFlightNumber": 5601,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Cairo International Airport",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "Y"
            ],
            "FlightLayoverTime": 460,
            "allow": {
              "id": 9,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ],
        [
          {
            "DepartureDateTime": "2022-11-20T10:35:00",
            "DepartureTimezone": "+02:00",
            "ArrivalDateTime": "2022-11-20T14:20:00",
            "ArrivalTimezone": "+01:00",
            "StopQuantity": 0,
            "FlightNumber": 725,
            "ElapsedTime": 285,
            "DepartureAirport": "CAI",
            "DepartureAirportTerminal": "2",
            "ArrivalAirportTerminal": null,
            "ArrivalAirport": "BRU",
            "OperatingAirline": "MS",
            "MarketingAirline": "SN",
            "MarketingFlightNumber": 5602,
            "AircraftName": "AIRBUS A320NEO",
            "AircraftCode": "32N",
            "OperatingAirlineName": "Egyptair",
            "OperatingAirlineCode": "MS",
            "DepartureAirportName": "Cairo International Airport",
            "DepartureCityName": "true",
            "ArrivalAirportName": "Brussels National Arpt",
            "ArrivalCitytName": "true",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 0,
            "allow": {
              "id": 9,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "2 CHECKED BAGS UP TO 23KG EACH",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          },
          {
            "DepartureDateTime": "2022-11-21T10:35:00",
            "DepartureTimezone": "+01:00",
            "ArrivalDateTime": "2022-11-21T13:20:00",
            "ArrivalTimezone": "-05:00",
            "StopQuantity": 0,
            "FlightNumber": 501,
            "ElapsedTime": 525,
            "DepartureAirport": "BRU",
            "DepartureAirportTerminal": null,
            "ArrivalAirportTerminal": "1",
            "ArrivalAirport": "JFK",
            "OperatingAirline": "SN",
            "MarketingAirline": "SN",
            "MarketingFlightNumber": 501,
            "AircraftName": "Airbus A330-300",
            "AircraftCode": "333",
            "OperatingAirlineName": "Brussels Airlines",
            "OperatingAirlineCode": "SN",
            "DepartureAirportName": "Brussels National Arpt",
            "DepartureCityName": "true",
            "ArrivalAirportName": "John F. Kennedy International Airport",
            "ArrivalCitytName": "",
            "FareBookingCode": [
              "V"
            ],
            "FlightLayoverTime": 1215,
            "allow": {
              "id": 9,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
            },
            "charge": [],
            "BrandFeatures": {
              "BF": [
                {
                  "serviceGroup": "BF",
                  "commercialName": "UPGRADE ELIGIBILITY",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "STANDARD SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "PREFERRED SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "EXTRA LEGROOM SEAT RESERVATION",
                  "application": "C"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "MILEAGE ACCRUAL",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE BEFORE DEPARTURE",
                  "application": "F"
                },
                {
                  "serviceGroup": "BF",
                  "commercialName": "CHANGE AFTER DEPARTURE",
                  "application": "F"
                }
              ],
              "BG": [
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CHECKED BAG UP TO 23KG",
                  "application": "F"
                },
                {
                  "serviceGroup": "BG",
                  "commercialName": "1 CABIN BAG UP TO 8KG",
                  "application": "F"
                }
              ],
              "IE": [
                {
                  "serviceGroup": "IE",
                  "commercialName": "INFLIGHT ENTERTAINMENT",
                  "application": "F"
                }
              ],
              "LG": [
                {
                  "serviceGroup": "LG",
                  "commercialName": "LOUNGE ACCESS",
                  "application": "C"
                }
              ],
              "ML": [
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON EUROPE FLTS",
                  "application": "C"
                },
                {
                  "serviceGroup": "ML",
                  "commercialName": "CATERING ON INTERCONT FLTS",
                  "application": "F"
                }
              ]
            }
          }
        ]
      ],
      "prices": {
        "ItinTotalFare": 1797.15,
        "AdultPrice": 1797.15,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Premium economy"
      },
      "taxfees": {
        "Amount": 155.65,
        "CurrencyCode": "USD"
      },
      "BaseFare": {
        "Amount": 1641.5,
        "CurrencyCode": "USD"
      },
      "Cabin": "S",
      "passengerDetails": [
        {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1797.15,
          "Tax": 155.65,
          "BaseFare": 1641.5
        },
        {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        },
        {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
        }
      ],
      "refundable": false,
      "FareBasisCode": "NLA76ENC",
      "GoverningCarrier": "SN",
      "BrandFeatures": {
        "BF": [
          {
            "serviceGroup": "BF",
            "commercialName": "UPGRADE ELIGIBILITY",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "STANDARD SEAT RESERVATION",
            "application": "C"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "MILEAGE ACCRUAL",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE BEFORE DEPARTURE",
            "application": "F"
          },
          {
            "serviceGroup": "BF",
            "commercialName": "CHANGE AFTER DEPARTURE",
            "application": "F"
          }
        ],
        "BG": [
          {
            "serviceGroup": "BG",
            "commercialName": "2 CHECKED BAGS UP TO 23KG EACH",
            "application": "F"
          },
          {
            "serviceGroup": "BG",
            "commercialName": "1 CABIN BAG UP TO 8KG",
            "application": "F"
          }
        ],
        "IE": [
          {
            "serviceGroup": "IE",
            "commercialName": "INFLIGHT ENTERTAINMENT",
            "application": "F"
          }
        ],
        "LG": [
          {
            "serviceGroup": "LG",
            "commercialName": "LOUNGE ACCESS",
            "application": "C"
          }
        ],
        "ML": [
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON EUROPE FLTS",
            "application": "C"
          },
          {
            "serviceGroup": "ML",
            "commercialName": "CATERING ON INTERCONT FLTS",
            "application": "F"
          }
        ]
      },
      "Fees": 0.5,
      "allow": {
        "9": [
          {
            "id": 9,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 9,
            "Pieces": 2,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ],
        "13": [
          {
            "id": 13,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          },
          {
            "id": 13,
            "Pieces": 1,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS"
          }
        ]
      },
      "charge": {
        "2": [
          {
            "id": 2,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ],
        "3": [
          {
            "id": 2,
            "Pieces": null,
            "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
            "Description2": "UP TO 62 LINEAR INCHES/158 LINEAR CENTIMETERS",
            "EquivalentAmount": 100,
            "EquivalentCurrency": "USD",
            "FirstPiece": 1,
            "LastPiece": 1
          }
        ]
      },
      "Penalties": {
        "Exchange": {
          "Before": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          },
          "After": {
            "Changeable": true,
            "ConditionsApply": true,
            "Amount": 0,
            "CurrencyCode": "USD"
          }
        },
        "Refund": {
          "Before": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          },
          "After": {
            "Refundable": false,
            "Cat16Info": null,
            "Amount": null,
            "CurrencyCode": null
          }
        }
      },
      "TagID": "BargainFinderMaxRQ~a570959f052bd08b812943cefe90697a-199"
    }
  ];
  dataFakeEgyptair:any = [
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },
    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-10-31T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-10-31T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-11-02T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-11-02T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-10-31T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-10-31T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-11-02T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-11-02T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-10-31T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-10-31T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
    "flights": [
        [
            {
                "DepartureDateTime": "2022-12-29T17:15:00",
                "DepartureTimezone": "-05:00",
                "ArrivalDateTime": "2022-12-30T10:30:00",
                "ArrivalTimezone": "+02:00",
                "StopQuantity": 0,
                "FlightNumber": 986,
                "ElapsedTime": 615,
                "DepartureAirport": "JFK",
                "DepartureAirportTerminal": "1",
                "ArrivalAirportTerminal": "3",
                "ArrivalAirport": "CAI",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 986,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "John F. Kennedy International Airport",
                "DepartureCityName": "",
                "ArrivalAirportName": "Cairo International Airport",
                "ArrivalCitytName": "true",
                "FareBookingCode": [
                    "K"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ],
        [
            {
                "DepartureDateTime": "2023-11-02T09:00:00",
                "DepartureTimezone": "+02:00",
                "ArrivalDateTime": "2023-11-02T14:40:00",
                "ArrivalTimezone": "-04:00",
                "StopQuantity": 0,
                "FlightNumber": 985,
                "ElapsedTime": 700,
                "DepartureAirport": "CAI",
                "DepartureAirportTerminal": "3",
                "ArrivalAirportTerminal": "1",
                "ArrivalAirport": "JFK",
                "OperatingAirline": "MS",
                "MarketingAirline": "MS",
                "MarketingFlightNumber": 985,
                "AircraftName": "Boeing 777-300",
                "AircraftCode": "773",
                "OperatingAirlineName": "Egyptair",
                "OperatingAirlineCode": "MS",
                "DepartureAirportName": "Cairo International Airport",
                "DepartureCityName": "true",
                "ArrivalAirportName": "John F. Kennedy International Airport",
                "ArrivalCitytName": "",
                "FareBookingCode": [
                    "T"
                ],
                "FlightLayoverTime": 0,
                "allow": {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                },
                "charge": [],
                "BrandFeatures": []
            }
        ]
    ],
    "prices": {
        "ItinTotalFare": 1216.56,
        "AdultPrice": 1216.56,
        "AdultPriceWithOffer": 0,
        "FareClassName": "Economy"
    },
    "taxfees": {
        "Amount": 101.56,
        "CurrencyCode": "USD"
    },
    "BaseFare": {
        "Amount": 1077.08,
        "CurrencyCode": "USD"
    },
    "Cabin": "Y",
    "passengerDetails": [
        {
            "PassengerType": "Adult",
            "PassengerTypeCode": "ADT",
            "Quantity": 1,
            "Price": 1216.56,
            "Tax": 101.56,
            "BaseFare": 1115
        },
        {
            "PassengerType": "Child",
            "PassengerTypeCode": "CNN",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Lap",
            "PassengerTypeCode": "INF",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        },
        {
            "PassengerType": "Infant Seat",
            "PassengerTypeCode": "INS",
            "Quantity": 0,
            "Price": 0,
            "Tax": 0,
            "BaseFare": 0
        }
    ],
    "refundable": true,
    "FareBasisCode": "KLREMS/PV",
    "GoverningCarrier": "MS",
    "BrandFeatures": [],
    "Fees": 0.5,
    "allow": {
        "2": [
            {
                "id": 2,
                "Pieces": 1,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ],
        "5": [
            {
                "id": 5,
                "Pieces": 2,
                "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                "Description2": null
            }
        ]
    },
    "charge": {
        "1": [
            {
                "id": 1,
                "Pieces": null,
                "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                "Description2": null,
                "EquivalentAmount": 60,
                "EquivalentCurrency": "USD",
                "FirstPiece": null,
                "LastPiece": null
            }
        ]
    },
    "Penalties": {
        "Exchange": {
            "Before": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Changeable": true,
                "ConditionsApply": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        },
        "Refund": {
            "Before": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            },
            "After": {
                "Refundable": true,
                "Cat16Info": null,
                "Amount": 250,
                "CurrencyCode": "USD"
            }
        }
    },
    "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-10-31T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-10-31T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
},
{
  "flights": [
      [
          {
              "DepartureDateTime": "2022-12-29T17:15:00",
              "DepartureTimezone": "-05:00",
              "ArrivalDateTime": "2022-12-30T10:30:00",
              "ArrivalTimezone": "+02:00",
              "StopQuantity": 0,
              "FlightNumber": 986,
              "ElapsedTime": 615,
              "DepartureAirport": "JFK",
              "DepartureAirportTerminal": "1",
              "ArrivalAirportTerminal": "3",
              "ArrivalAirport": "CAI",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 986,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "John F. Kennedy International Airport",
              "DepartureCityName": "",
              "ArrivalAirportName": "Cairo International Airport",
              "ArrivalCitytName": "true",
              "FareBookingCode": [
                  "K"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ],
      [
          {
              "DepartureDateTime": "2023-11-02T09:00:00",
              "DepartureTimezone": "+02:00",
              "ArrivalDateTime": "2023-11-02T14:40:00",
              "ArrivalTimezone": "-04:00",
              "StopQuantity": 0,
              "FlightNumber": 985,
              "ElapsedTime": 700,
              "DepartureAirport": "CAI",
              "DepartureAirportTerminal": "3",
              "ArrivalAirportTerminal": "1",
              "ArrivalAirport": "JFK",
              "OperatingAirline": "MS",
              "MarketingAirline": "MS",
              "MarketingFlightNumber": 985,
              "AircraftName": "Boeing 777-300",
              "AircraftCode": "773",
              "OperatingAirlineName": "Egyptair",
              "OperatingAirlineCode": "MS",
              "DepartureAirportName": "Cairo International Airport",
              "DepartureCityName": "true",
              "ArrivalAirportName": "John F. Kennedy International Airport",
              "ArrivalCitytName": "",
              "FareBookingCode": [
                  "T"
              ],
              "FlightLayoverTime": 0,
              "allow": {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              },
              "charge": [],
              "BrandFeatures": []
          }
      ]
  ],
  "prices": {
      "ItinTotalFare": 1216.56,
      "AdultPrice": 1216.56,
      "AdultPriceWithOffer": 0,
      "FareClassName": "Economy"
  },
  "taxfees": {
      "Amount": 101.56,
      "CurrencyCode": "USD"
  },
  "BaseFare": {
      "Amount": 1077.08,
      "CurrencyCode": "USD"
  },
  "Cabin": "Y",
  "passengerDetails": [
      {
          "PassengerType": "Adult",
          "PassengerTypeCode": "ADT",
          "Quantity": 1,
          "Price": 1216.56,
          "Tax": 101.56,
          "BaseFare": 1115
      },
      {
          "PassengerType": "Child",
          "PassengerTypeCode": "CNN",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Lap",
          "PassengerTypeCode": "INF",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      },
      {
          "PassengerType": "Infant Seat",
          "PassengerTypeCode": "INS",
          "Quantity": 0,
          "Price": 0,
          "Tax": 0,
          "BaseFare": 0
      }
  ],
  "refundable": true,
  "FareBasisCode": "KLREMS/PV",
  "GoverningCarrier": "MS",
  "BrandFeatures": [],
  "Fees": 0.5,
  "allow": {
      "2": [
          {
              "id": 2,
              "Pieces": 1,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ],
      "5": [
          {
              "id": 5,
              "Pieces": 2,
              "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
              "Description2": null
          }
      ]
  },
  "charge": {
      "1": [
          {
              "id": 1,
              "Pieces": null,
              "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
              "Description2": null,
              "EquivalentAmount": 60,
              "EquivalentCurrency": "USD",
              "FirstPiece": null,
              "LastPiece": null
          }
      ]
  },
  "Penalties": {
      "Exchange": {
          "Before": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Changeable": true,
              "ConditionsApply": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      },
      "Refund": {
          "Before": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          },
          "After": {
              "Refundable": true,
              "Cat16Info": null,
              "Amount": 250,
              "CurrencyCode": "USD"
          }
      }
  },
  "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
},    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-10-31T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-10-31T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
  },
  {
      "flights": [
          [
              {
                  "DepartureDateTime": "2022-12-29T17:15:00",
                  "DepartureTimezone": "-05:00",
                  "ArrivalDateTime": "2022-12-30T10:30:00",
                  "ArrivalTimezone": "+02:00",
                  "StopQuantity": 0,
                  "FlightNumber": 986,
                  "ElapsedTime": 615,
                  "DepartureAirport": "JFK",
                  "DepartureAirportTerminal": "1",
                  "ArrivalAirportTerminal": "3",
                  "ArrivalAirport": "CAI",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 986,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "John F. Kennedy International Airport",
                  "DepartureCityName": "",
                  "ArrivalAirportName": "Cairo International Airport",
                  "ArrivalCitytName": "true",
                  "FareBookingCode": [
                      "K"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ],
          [
              {
                  "DepartureDateTime": "2023-11-02T09:00:00",
                  "DepartureTimezone": "+02:00",
                  "ArrivalDateTime": "2023-11-02T14:40:00",
                  "ArrivalTimezone": "-04:00",
                  "StopQuantity": 0,
                  "FlightNumber": 985,
                  "ElapsedTime": 700,
                  "DepartureAirport": "CAI",
                  "DepartureAirportTerminal": "3",
                  "ArrivalAirportTerminal": "1",
                  "ArrivalAirport": "JFK",
                  "OperatingAirline": "MS",
                  "MarketingAirline": "MS",
                  "MarketingFlightNumber": 985,
                  "AircraftName": "Boeing 777-300",
                  "AircraftCode": "773",
                  "OperatingAirlineName": "Egyptair",
                  "OperatingAirlineCode": "MS",
                  "DepartureAirportName": "Cairo International Airport",
                  "DepartureCityName": "true",
                  "ArrivalAirportName": "John F. Kennedy International Airport",
                  "ArrivalCitytName": "",
                  "FareBookingCode": [
                      "T"
                  ],
                  "FlightLayoverTime": 0,
                  "allow": {
                      "id": 5,
                      "Pieces": 2,
                      "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                      "Description2": null
                  },
                  "charge": [],
                  "BrandFeatures": []
              }
          ]
      ],
      "prices": {
          "ItinTotalFare": 1216.56,
          "AdultPrice": 1216.56,
          "AdultPriceWithOffer": 0,
          "FareClassName": "Economy"
      },
      "taxfees": {
          "Amount": 101.56,
          "CurrencyCode": "USD"
      },
      "BaseFare": {
          "Amount": 1077.08,
          "CurrencyCode": "USD"
      },
      "Cabin": "Y",
      "passengerDetails": [
          {
              "PassengerType": "Adult",
              "PassengerTypeCode": "ADT",
              "Quantity": 1,
              "Price": 1216.56,
              "Tax": 101.56,
              "BaseFare": 1115
          },
          {
              "PassengerType": "Child",
              "PassengerTypeCode": "CNN",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Lap",
              "PassengerTypeCode": "INF",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          },
          {
              "PassengerType": "Infant Seat",
              "PassengerTypeCode": "INS",
              "Quantity": 0,
              "Price": 0,
              "Tax": 0,
              "BaseFare": 0
          }
      ],
      "refundable": true,
      "FareBasisCode": "KLREMS/PV",
      "GoverningCarrier": "MS",
      "BrandFeatures": [],
      "Fees": 0.5,
      "allow": {
          "2": [
              {
                  "id": 2,
                  "Pieces": 1,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ],
          "5": [
              {
                  "id": 5,
                  "Pieces": 2,
                  "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                  "Description2": null
              }
          ]
      },
      "charge": {
          "1": [
              {
                  "id": 1,
                  "Pieces": null,
                  "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                  "Description2": null,
                  "EquivalentAmount": 60,
                  "EquivalentCurrency": "USD",
                  "FirstPiece": null,
                  "LastPiece": null
              }
          ]
      },
      "Penalties": {
          "Exchange": {
              "Before": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Changeable": true,
                  "ConditionsApply": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          },
          "Refund": {
              "Before": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              },
              "After": {
                  "Refundable": true,
                  "Cat16Info": null,
                  "Amount": 250,
                  "CurrencyCode": "USD"
              }
          }
      },
      "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
  },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-10-31T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-10-31T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-10"
    },
    {
        "flights": [
            [
                {
                    "DepartureDateTime": "2022-12-29T17:15:00",
                    "DepartureTimezone": "-05:00",
                    "ArrivalDateTime": "2022-12-30T10:30:00",
                    "ArrivalTimezone": "+02:00",
                    "StopQuantity": 0,
                    "FlightNumber": 986,
                    "ElapsedTime": 615,
                    "DepartureAirport": "JFK",
                    "DepartureAirportTerminal": "1",
                    "ArrivalAirportTerminal": "3",
                    "ArrivalAirport": "CAI",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 986,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "John F. Kennedy International Airport",
                    "DepartureCityName": "",
                    "ArrivalAirportName": "Cairo International Airport",
                    "ArrivalCitytName": "true",
                    "FareBookingCode": [
                        "K"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ],
            [
                {
                    "DepartureDateTime": "2023-11-02T09:00:00",
                    "DepartureTimezone": "+02:00",
                    "ArrivalDateTime": "2023-11-02T14:40:00",
                    "ArrivalTimezone": "-04:00",
                    "StopQuantity": 0,
                    "FlightNumber": 985,
                    "ElapsedTime": 700,
                    "DepartureAirport": "CAI",
                    "DepartureAirportTerminal": "3",
                    "ArrivalAirportTerminal": "1",
                    "ArrivalAirport": "JFK",
                    "OperatingAirline": "MS",
                    "MarketingAirline": "MS",
                    "MarketingFlightNumber": 985,
                    "AircraftName": "Boeing 777-300",
                    "AircraftCode": "773",
                    "OperatingAirlineName": "Egyptair",
                    "OperatingAirlineCode": "MS",
                    "DepartureAirportName": "Cairo International Airport",
                    "DepartureCityName": "true",
                    "ArrivalAirportName": "John F. Kennedy International Airport",
                    "ArrivalCitytName": "",
                    "FareBookingCode": [
                        "T"
                    ],
                    "FlightLayoverTime": 0,
                    "allow": {
                        "id": 5,
                        "Pieces": 2,
                        "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                        "Description2": null
                    },
                    "charge": [],
                    "BrandFeatures": []
                }
            ]
        ],
        "prices": {
            "ItinTotalFare": 1216.56,
            "AdultPrice": 1216.56,
            "AdultPriceWithOffer": 0,
            "FareClassName": "Economy"
        },
        "taxfees": {
            "Amount": 101.56,
            "CurrencyCode": "USD"
        },
        "BaseFare": {
            "Amount": 1077.08,
            "CurrencyCode": "USD"
        },
        "Cabin": "Y",
        "passengerDetails": [
            {
                "PassengerType": "Adult",
                "PassengerTypeCode": "ADT",
                "Quantity": 1,
                "Price": 1216.56,
                "Tax": 101.56,
                "BaseFare": 1115
            },
            {
                "PassengerType": "Child",
                "PassengerTypeCode": "CNN",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Lap",
                "PassengerTypeCode": "INF",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            },
            {
                "PassengerType": "Infant Seat",
                "PassengerTypeCode": "INS",
                "Quantity": 0,
                "Price": 0,
                "Tax": 0,
                "BaseFare": 0
            }
        ],
        "refundable": true,
        "FareBasisCode": "KLREMS/PV",
        "GoverningCarrier": "MS",
        "BrandFeatures": [],
        "Fees": 0.5,
        "allow": {
            "2": [
                {
                    "id": 2,
                    "Pieces": 1,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ],
            "5": [
                {
                    "id": 5,
                    "Pieces": 2,
                    "Description1": "UP TO 50 POUNDS/23 KILOGRAMS",
                    "Description2": null
                }
            ]
        },
        "charge": {
            "1": [
                {
                    "id": 1,
                    "Pieces": null,
                    "Description1": "UP TO 22 POUNDS/10 KILOGRAMS",
                    "Description2": null,
                    "EquivalentAmount": 60,
                    "EquivalentCurrency": "USD",
                    "FirstPiece": null,
                    "LastPiece": null
                }
            ]
        },
        "Penalties": {
            "Exchange": {
                "Before": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Changeable": true,
                    "ConditionsApply": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            },
            "Refund": {
                "Before": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                },
                "After": {
                    "Refundable": true,
                    "Cat16Info": null,
                    "Amount": 250,
                    "CurrencyCode": "USD"
                }
            }
        },
        "TagID": "BargainFinderMaxRQ~0a16181fcccc80e8872be2c185b65104-12"
    },
]
  airlinesLog: any = [
    {url: 'https://c.fareportal.com/n/common/air/3x/QR.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/SV.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/AF.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/KL.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/DL.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/NW.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/KA.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/PR.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/QF.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/NZ.png'},
    {url: 'https://c.fareportal.com/n/common/air/3x/IR.png'},
  ];

  constructor() {
  }

}
