import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {SEOService} from "../../services/seo.service";
import {AppComponent} from "../../app.component";
import {Clipboard} from "@angular/cdk/clipboard";
import * as events from "events";
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-airline-bagging-information',
  templateUrl: './airline-bagging-information.component.html',
  styleUrls: ['./airline-bagging-information.component.css']
})
export class AirlineBaggingInformationComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    autoplayTimeout: 7000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },

  };

  data: any = [];
  airlineName: any;
  airlineInfo: any;
  about: any;
  images: any = [];

  airlineId: number;
  reviewData: any = [];
  showRev = false;
  total_review: number;
  star5: number;
  star4: number;
  star3: number;
  star2: number;
  star1: number;
  averageReview: number;
  metaTitle: any;
  metaDescription: string;
  metaImage: any;
  display = true;
  copied=false;

  constructor(private apis: ApisService,
    public activatedRoute: ActivatedRoute, 
    location: Location, private router: Router,
    private seoService: SEOService ,
    private clipboard: Clipboard
      ) {

      this.airlineName = this.activatedRoute.snapshot.paramMap.get('name');
      this.seoUpdate();
      this.getAirlineInfo();


  }

  ngOnInit(): void {
    // this.airlineName = this.activatedRoute.snapshot.paramMap.get('name');
    this.getAirLines();


  }

  getAirLines() {
    this.apis.getHomeAirLines().subscribe((data: any) => {
        this.data = data.data;
        // console.log('data for air lines baggage',this.data);

        // this.metaTitle = this.data[0].name;
        // this.metaDescription = this.data[0].about;
        // this.metaImage = this.data[0].main_image;
        // this.getAirlineInfo();
        // this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);
        // console.log('name', this.metaTitle, 'd', this.metaDescription, 'img', this.metaImage);
      },
      err => {
        console.log(err);
      });
  }
  setReview(event:any){
    this.apis.addAirlineReviews(event).subscribe((data: any) => {
          this.data = data.data;
        },
        err => {
          console.log(err);
        });
    // console.log('xxxxxxxxx =>' , event)
  }
  // getAirlineInfo() {
  //   this.images = [];
  //   for (let i = 0; i < this.data.length; i++) {
  //     // tslint:disable-next-line:triple-equals
  //     if (this.airlineName == this.data[i].name) {
  //       this.airlineInfo = this.data[i];
  //       this.airlineId=this.airlineInfo.id;
  //       this.getReviews()
  //       this.about = this.airlineInfo.baggage_information_descrption;
  //       // tslint:disable-next-line:prefer-for-of no-shadowed-variable
  //       for (let i = 0; i < this.airlineInfo.images.length; i++) {
  //         this.images.push(this.airlineInfo.images[i].full_url);
  //       }
  //       window.scrollTo(0, 0);
  //     }
  //   }
  // }
  getAirlineInfo() {
    this.activatedRoute.params
        .pipe(map((prams) => prams.name))
        .subscribe((name) => {
            // console.log(name, "xxxxxxxxxxxxxxxxxxxxxx");
            this.apis.getAirLineByCallsign(name).subscribe(
                (data: any) => {
                    this.about = data.data.baggage_information_descrption;
                    this.airlineInfo = data.data;
                    this.airlineId = this.airlineInfo.id;
                    for (let i = 0; i < this.airlineInfo.images.length; i++) {
                        this.images.push(this.airlineInfo.images[i].full_url);
                    }

                    this.metaTitle = this.airlineInfo.baggage_information_tag;
                    this.metaDescription = this.airlineInfo.meta_baggage_information_description
                    this.metaImage = this.airlineInfo.main_image;
                    this.getReviews();
                    this.seoUpdate(
                        this.metaTitle,
                        this.metaDescription,
                        this.metaImage
                    );
                    // console.log(this.airlineId);
                },
                (err) => {
                    console.log(err);
                }
            );
        });
}

  getReviews() {
    this.apis.getAirlineReviews(this.airlineId).subscribe((data: any) => {
        this.reviewData = data.data;
      },
      err => {
        console.log(err);
      });
  }

  showReviews() {
    this.showRev = !this.showRev;
  }

  seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {

    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }


  }

  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied=!this.copied;
    setTimeout(()=>{
      this.copied = false;
    },1500)
  }

  fb() {
    let url = "www.google.com";

    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "flyallover.com" +
        this.router.url,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

}
