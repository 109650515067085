<!-- start ingredients -->
<div class="ingredients">
    <div class="ingredient">
        <h2>My Profile</h2>
        <div class="ingress">

            <!-- start my-profile -->
            <div class="my-profile">
                <h1>My Account Information</h1>
                <h4 class="successfully-updated">{{ SuccessfullyUpdated }}</h4>

                <!-- start login-information -->
                <div class="login-information">
                    <div>
                        <div class="float-left pl-2 pb-2">
                            <p>
                                <i class="fas fa-envelope"></i>
                                <input type="email" class="inpt" [(ngModel)]="email" autocomplete="off" required/>
                                <span (click)="editEmail()">EDIT</span>
                            </p>
                        </div>
                        <div class="float-left pl-2 pb-2">
                            <p>
                                <i class="fas fa-key"></i>
                                <input type="password" class="inpt" autocomplete="off" />
                                <span (click)="open_change_password = true">Change</span>
                            </p>

                            <div class="change-password-section" *ngIf="open_change_password">
                                <div class="overlay" (click)="open_change_password = false"></div>
                                <div class="inner">
                                    <div class="close">
                                        <i class="fas fa-times" (click)="open_change_password = false"></i>
                                    </div>
                                    <div class="title text-center">
                                        <h2>Change password</h2>
                                        <span class="success">{{succsess_change_password}}</span>
                                        <span class="error">{{error_change_password}}</span>
                                    </div>
                                    <ul class="list-unstyled">
                                        <li>
                                            <label for="old_password">Old Password*:</label>
                                            <input id="old_password" type="password" [(ngModel)]="old_password" autocomplete="off" required/>
                                        </li>
                                        <li>
                                            <label for="new_password">New Password*:</label>
                                            <input id="new_password" type="password" [(ngModel)]="new_password" autocomplete="off" required minlength="6"/>
                                        </li>
                                        <li>
                                            <label for="confirm_password">Confirm Password*:</label>
                                            <input id="confirm_password" type="password" [(ngModel)]="confirm_password" autocomplete="off" required minlength="6"/>
                                        </li>
                                    </ul>
                                    <div class="row text-center btns">
                                        <div class="col">
                                            <button (click)="open_change_password = false">Cancel</button>
                                        </div>
                                        <div class="col">
                                            <button (click)="changePassword()">Confirm</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <!-- end login-information -->

                <!-- start person-information -->
                <div class="person-information">

                    <div class="edit float-right">
                        <span (click)="startEditing()"><i class="far fa-edit"></i> Edit</span>
                    </div>
                    <div class="clearfix"></div>

                    <div class="succsess_edit_profile">{{succsess_edit_profile}}</div>
                    <div class="error_edit_profile">{{error_edit_profile}}</div>

                    <div class="row">
                        <div class="col">
                            <p>
                                <img title="Profile" alt="my-profile-icon-identificatio"  src="./assets/images/profile/my-profile-icon-identification.svg" />
                                <label>
                                    <input type="text" class="first_name" [(ngModel)]="first_name" [disabled]="!editing" required/>
                                    | <input class="last_name" type="text" [(ngModel)]="last_name" [disabled]="!editing" required/>
                                </label>
                            </p>
                        </div>
                        <div class="col">
                            <p>
                                <img title="Profile" alt="/my-profile-icon-birthday"  src="./assets/images/profile/my-profile-icon-birthday.svg" />
                                <label>Date of Birth:
                                    <input type="text" class="date_of_birth" id="date_of_birth" [(ngModel)]="date_of_birth" readonly [disabled]="!editing" required/>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>
                                <img title="Profile" alt="my-profile-icon-love"  src="./assets/images/profile/my-profile-icon-love.svg" />
                                <label class="gender_container">Gender:
                                    <select type="text" class="gender" [(ngModel)]="gender" [disabled]="!editing" required>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </label>
                            </p>
                        </div>
                        <div class="col">
                            <p>
                                <img title="Profile" alt="my-profile-icon-flag"  src="./assets/images/profile/my-profile-icon-flag.svg" />
                                <label class="country_container">Country:
                                    <select [(ngModel)]="country_code" class="country_code" [disabled]="!editing" required>
                                        <option [value]="country.Code" *ngFor="let country of countries">
                                            {{country.Name}}</option>
                                    </select>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>
                                <img title="Profile" alt="my-profile-icon-smartphone"  src="../../../assets/images/profile/my-profile-icon-smartphone.svg" />
                                <label>Phone:
                                    <input class="num" type="text" [(ngModel)]="phone" [disabled]="!editing" required minlength="5"/>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="editing" class="py-4">
<!--                        <button (click)="editProfile()" class="edit float-right p-2 mb-2">Submit Edits</button>-->
                    </div>

                </div>
                <!-- end person-information -->

            </div>
            <!-- end my-profile -->

        </div>
    </div>
    <div class="clearfix"></div>

</div>
<!-- end ingredients -->
