<input
  class="form-control d-flex pointer"
  readonly
  [value]="departureDate | date: 'MMM d'"
  (click)="openDesktopCalender()"
  (focus)="onFocus()"
  (blur)="onFocus()"
/>
<ng-container>
  <div class="placeHolder element pointer" (click)="openDesktopCalender()">
    <div *ngIf="!isFocused && !departureDate">
      <span class="star">*</span>
      <span>Check-In</span>
    </div>
    <div>
      <img
        title="Check-In"
        style="width: 24px; height: 24px"
        alt="Check-In"
        src="/assets/images/icons/calendar.svg"
      />
    </div>
  </div>
</ng-container>
