<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!--                <div class="wrapper">-->
        <!--                    <div class="image loader">-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="gif-image d-flex justify-content-center">
          <img
            src="../../../assets/images/icons_flights/flyLoading.gif"
            alt="flight"
            title="flight"
          />
        </div>
        <p>Searching for {{data.message}} …</p>
        <span>
          You told us when and where...<br />
          now let us take you there with <br />
          our great fares on over 600 airlines...!
        </span>
      </div>
    </div>
  </div>
</section>
