<app-navbar></app-navbar>
<div class="vaction-header ">
  <h1 class="title"> Villas </h1>
</div>

<div class="container">
  <div class="row">
    <section id="vacations" class="section-with-bg my-5 ">
      <div class="container">
        <div class="row mx-16 "  >
          <div class="col-4 col-md-4 col-lg-4 col-sm-4 my-3"   >
            <div class="post-item position-relative h-100" >
              <div class="post-img position-relative overflow-hidden" >
                <img  src="assets/images/hotels/3.jpg "class="w-100" alt="" style="height: 250px !important;">
              </div>

              <div class="post-content d-flex flex-column">
                <div style="height: 13vh;">
                  <div class="handleNAme">
                    <h6 class="post-name"> name </h6>
                  </div>
                  <div class="rating">
                    <!-- <div style="width: 90px"> -->
                    <span [ngClass]="getRating(2,1)" class="fa fa-star "></span>
                    <span [ngClass]="getRating(5,2)" class="fa fa-star "></span>
                    <span [ngClass]="getRating(3,3)" class="fa fa-star"></span>
                    <span [ngClass]="getRating(2,4)" class="fa fa-star "></span>
                    <span [ngClass]="getRating(1,5)" class="fa fa-star "></span>
                    <!-- </div> -->
                  </div>
                </div>
                <hr>
                <div class="handelTitle">
                  <h3 class="post-title"> title </h3>
                </div>

                <div class="nights">
                  <h6 class="price">${{' '+ 55 }}</h6>
                </div>
                <div class="nights">
                  <h6 class="nights-num">{{8 +' '}}nights</h6>
                </div>
                <hr>
                <a  [routerLink]="['/villas-details']" class="readmore stretched-link"><span >More Details</span>
                  <!--            <i class="bi bi-arrow-right"></i>-->
                </a>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!--   -----------------------  new  -------------------->
    <!--    <section class="section">-->
    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          <div class="gallery gallery&#45;&#45;home">-->
    <!--            <figure class="gallery__img-box gallery__img-box&#45;&#45;home">-->
    <!--              <img src="./assets/images/hotels/img/gallery1.jpg" class = "gallery__img" alt="Gallery Image 1">-->
    <!--              <a href="#" class="btn btn&#45;&#45;transparent">Book Room</a>-->
    <!--              <h2 class="heading&#45;&#45;2">Standard Room</h2>-->
    <!--              <h3 class="heading&#45;&#45;3"><span>NGN 20,000</span> / Night</h3>-->
    <!--            </figure>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="col-12">-->
    <!--          <div class="gallery gallery&#45;&#45;home">-->
    <!--            <figure class="gallery__img-box gallery__img-box&#45;&#45;home">-->
    <!--              <img src="./assets/images/hotels/img/gallery1.jpg" class = "gallery__img" alt="Gallery Image 1">-->
    <!--              <a href="#" class="btn btn&#45;&#45;transparent">Book Room</a>-->
    <!--              <h2 class="heading&#45;&#45;2">Standard Room</h2>-->
    <!--              <h3 class="heading&#45;&#45;3"><span>NGN 20,000</span> / Night</h3>-->
    <!--            </figure>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </section>-->

  </div>
</div>


<app-footer></app-footer>


