<app-navbar></app-navbar>
<!-- start help-center-page -->
<div class="help-center-page">
    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family">Help Center</h1>
        </div>
    </div>
</div>

 <!-- ======= Help Section ======= -->
 <section id="help" class="help section-bg"> 
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Popular Topics</h2>
        <p>Fly All Over provides a comprehensive help center for all your travel needs. Check your booking status, view refund/credit details, receive check-in assistance, make new bookings, get guidance on trip cancellations, and sort out payment issues, all in one place.</p>
      </div>

      <div class="row">
        <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon-box">
            <h4><a href="">Check Booking Status ?</a></h4>
            <p>View your trip status online and receive confirmation .</p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <h4><a href="">Check Refund/Airline Credit status ?</a></h4>
              <p>View your refund/credit details and check status online .</p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <h4><a href="">Flight Checkin and Boarding Pass ?</a></h4>
              <p>Get assistance on flight Checkin and Boarding Pass .</p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <h4><a href="">Help with New Bookings ? </a></h4>
              <p>Get assistance to make a new booking .</p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <h4><a href="">Want to Cancel your trip ? </a></h4>
              <p>Get assistance to make a new booking .</p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <h4><a href="">Payment Issues ? </a></h4>
              <p>Receive Payment Information and assistance on other billing queries .</p>
            </div>
          </div>



      </div>

    </div>
  </section><!-- End Help Section -->









<app-footer></app-footer>