<div class="ingredient">
    <h2>My Documents</h2>
    <form [formGroup]="docsForm" class="ingress" (submit)="submitDocs()">

        <!-- start my-documents -->
        <div class="my-documents row">
            <div class="col-12 wrapper">
                <label>Bank Statement</label>
                <div class="input-wrapper">
                    <input formControlName="bank_statement" 
                    [withMeta]="true" [maxHeight]="1000" [maxWidth]="3000"
                    [minWidth]="1000" [minHeight]="200"
                    type="file" accept="image/*" 
                    class="file-input" multiple
                    (click)="docsForm.controls.bank_statement.markAsTouched()">
                    <!-- click event is used to set touched property to true because input[type="file"] does not set touched to true when clicking on it natively-->
                    <button class="btn">Choose File</button>
                </div>
                <app-print-error [control]="docsForm.controls.bank_statement"></app-print-error>
                
            </div>
            <div class="col-12 wrapper">
                <label>national ID</label>
                <div class="input-wrapper">
                    <input formControlName="ID" type="file" 
                    [withMeta]="true" [maxHeight]="1000" [maxWidth]="3000"
                    [minWidth]="1000" [minHeight]="200"
                    accept="image/*" class="file-input"
                    (click)="docsForm.controls.ID.markAsTouched()"> 
                    <button class="btn">Choose File</button>
                </div>
                <app-print-error [control]="docsForm.controls.ID"></app-print-error>
            </div>
            <div class="col-12 wrapper">
                <label>passport</label>
                <div class="input-wrapper">
                    <input formControlName="passport" type="file" 
                    [withMeta]="true" [maxHeight]="1000" [maxWidth]="3000"
                    [minWidth]="1000" [minHeight]="200"
                    accept="image/*" class="file-input"
                    (click)="docsForm.controls.passport.markAsTouched()">
                    <button class="btn">Choose File</button>
                </div>
                <app-print-error [control]="docsForm.controls.passport"></app-print-error>
            </div>
        </div>
        <p class="error">
            <span *ngFor="let message of messages" class="d-block">{{message}} </span>
        </p>
        <button type="submit" class="btn upload">Upload</button>
    </form>
</div>