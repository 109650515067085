import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-footer-about-dialog',
  templateUrl: './footer-about-dialog.component.html',
  styleUrls: ['./footer-about-dialog.component.css']
})
export class FooterAboutDialogComponent  {

  constructor(public dialogRef: MatDialogRef<FooterAboutDialogComponent>,) { }


  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
}
