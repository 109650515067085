import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FareRulesComponent } from 'src/app/components/fare-rules/fare-rules.component';
import { LoadingModalComponent } from 'src/app/components/loading-modal/loading-modal.component';
import { ApisService } from 'src/app/services/apis.service';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  @Input() orderId: any;
  @Input() filghtDetails: any;
  itinerary: any;
  card: any;
  paymentStep = 1;
  vendorCode;
  error = false;
  token = localStorage.getItem('flyallover_token')
  fareRules: any;
  errorMessageYear: any;
  errorMessageMonth: string;
  paymentForm: FormGroup;
  userAddress: any;
  userLatitude: any;
  userLongitude: any;
  addresscomponents: any;
  countryCode: any;
  country: any;
  city: any;
  state: any;
  creditRegion: any;
  ZipCode: any;
  form: FormGroup;
  myIpAddress: any;
  checkPoliceyBox = 0;
  setCreditCardExpireDate: string;
  checkbox1 = new FormControl('');
  checkbox2 = new FormControl('');
  checkbox3 = new FormControl('');
  checkbox4 = new FormControl('');
  checkbox5 = new FormControl('');
  loadingModal: any;
  submitError: boolean;
  dialogRef: any;
  user: any;
  userContact: any;
  navbar = true
  @Input() fareRulesData: any;

  constructor(
    private apis: ApisService, private matDialog: MatDialog, private formBuilder: FormBuilder, private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document, private router: Router, private _ActivatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    if (this._ActivatedRoute.snapshot.paramMap.get('orderId')) {
      this.orderId = this._ActivatedRoute.snapshot.paramMap.get('orderId');
    } else {
      this.orderId = localStorage.getItem('order_id') ;
      this.navbar = false

    }
    this.flightDetails()
    this.form = this.initForm();
    this.getContactInfo()
  }

  flightDetails() {
    this.apis.flightDetails(this.token, this.orderId).subscribe((data: any) => {
      this.filghtDetails = data.data;
      this.user = this.filghtDetails?.family_members[0]?.family_member
      this.itinerary = JSON.parse(this.filghtDetails?.itinerary_details)
    },
      err => {
        console.log(err);
      });
  }
  handelName(name) {
    var nameUpdata = name != null ? name : ` `;
    return nameUpdata;
  }

  openFareRulesDialog(itinerary: any) {
    this.fareRules = this.matDialog.open(FareRulesComponent, {
      panelClass: 'FareRules',
      maxHeight: '90vh',
      width: '50%',
    });
    this.fareRules.componentInstance.itinerary = itinerary;
  }
  initForm(): FormGroup {
    return new FormGroup({
      CreditCardNumber: new FormControl("", []),
      CreditCardVendorCode: new FormControl("", []),
      CreditCardExpireDate: new FormControl("", []),
      cardHolderFullName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$"),
      ]),
      card: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{16}"),
      ]),
      cvc: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{3}"),
      ]),
      CreditCardExpireDateMonth: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,}"),
      ]),
      CreditCardExpireDateYear: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,}$"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      State: new FormControl("", [Validators.required]),
      creditCardRegion: new FormControl("", [Validators.required]),
      contactEmail: new FormControl(""),
      contactPhone: new FormControl("")
    });
  }
  get f() {
    return this.form.controls;
  }
  checkDate() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const valueMonth = this.form.value.CreditCardExpireDateMonth;
    const valueYear = this.form.value.CreditCardExpireDateYear;
    if (valueYear == null) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Is Required!";
    }
    if (valueYear > year) {
      this.errorMessageYear = null;
      this.errorMessageMonth = null;
    }
    if (valueYear < year) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Should Be More Than " + year;
    }
    if (valueYear == year) {
      this.errorMessageYear = null;
      if (month > valueMonth) {
        this.errorMessageYear = null;
        this.errorMessageMonth = "Month should Be More Than " + month;
      }
      if (month <= valueMonth) {
        this.errorMessageMonth = null;
      }
    }
  }
  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.addresscomponents = address.address_components;
    for (let i = 0; i < address.address_components.length; i++) {

      for (let ii = 0; ii < address.address_components[i].types.length; ii++) {
        if (address.address_components[i].types[ii] == "postal_code") {
          this.ZipCode = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "administrative_area_level_1") {
          this.state = address.address_components[i].short_name;
          this.creditRegion = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "locality") {
          this.city = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.country = address.address_components[i].long_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.countryCode = address.address_components[i].short_name;
        }
      }

    }
  }
  validationForm(FormControlName: any) {
    this.form.get(FormControlName).valueChanges.subscribe((value) => {
      if (this.f[FormControlName].dirty) {
        let element = this.document.getElementById(FormControlName);
        if (FormControlName == 'email') {
          return
        } else {
          if (!this.f[FormControlName]?.errors?.required) {
            element.classList.remove('is-invalid')
            element.classList.add('is-valid')
            if (!this.f[FormControlName]?.errors?.pattern) {
              element.classList.remove('is-invalid')
              element.classList.add('is-valid')
            } else {
              element.classList.remove('is-valid')
              element.classList.add('is-invalid')
            }
          } else {
            element.classList.remove('is-valid')
            element.classList.add('is-invalid')
          }
        }
      }
    })
  }
  getIpAddress() {
    this.apis.getIpAddress().subscribe((data: any) => {
      this.myIpAddress = data.ip;
    });
  }
  checkCreditCard(cardnumber, cardname) {
    let ccErrorNo = 0;
    const ccErrors = [];
    const cards = [];
    ccErrors[0] = "Unknown card type";
    ccErrors[1] = "No card number provided";
    ccErrors[2] = "Credit card number is in invalid format";
    ccErrors[3] = "Credit card number is invalid";
    ccErrors[4] = "Credit card number has an inappropriate number of digits";
    ccErrors[5] =
      "Warning! This credit card number is associated with a scam attempt";
    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      checkdigit: true,
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      checkdigit: true,
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      checkdigit: true,
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305",
      checkdigit: true,
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      checkdigit: true,
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      checkdigit: true,
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35",
      checkdigit: true,
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149",
      checkdigit: true,
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767",
      checkdigit: true,
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
      checkdigit: true,
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      checkdigit: true,
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      checkdigit: true,
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      checkdigit: true,
    };

    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {
      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType == -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length == 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, "");

    // Check that the number is numeric
    let cardNo = cardnumber;
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 != 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo == "5490997771092064") {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(",");

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp("^" + prefix[i]);
      if (exp.test(cardNo)) {
        PrefixValid = true;
      }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(",");
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length == lengths[j]) {
        LengthValid = true;
      }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    }

    // The credit card is in the required format.
    return true;
  }
  testCreditCard() {
    this.card = this.form.value.card;
    const vendorCode = {
      AX: "American Express",
      CB: "Carte Blanche",
      DC: "Diners Club",
      DS: "Discover",
      CA: "MasterCard",
      VI: "Visa",
      JC: "JCB",
    };
    if (this.checkCreditCard(this.form.value.card, vendorCode["AX"])) {
      this.vendorCode = "AX";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CB"])) {
      this.vendorCode = "CB";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DC"])) {
      this.vendorCode = "DC";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DS"])) {
      this.vendorCode = "DS";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CA"])) {
      this.vendorCode = "CA";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["VI"])) {
      this.vendorCode = "VI";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["JC"])) {
      this.vendorCode = "JC";
      this.error = false;
    } else {
      this.vendorCode = 'VI'
      this.error = true;
    }
    const object = {
      card: this.form.value.card,
      error: this.error,
      vendorCode: this.vendorCode,
    };
    this.setPaymentCardEvent(object);
  }
  setPaymentCardEvent(e) {
    this.form.patchValue({
      CreditCardNumber: e.card,
      CreditCardVendorCode: e.vendorCode,
    });
  }
  validateCheckboxes(ob: any) {
    console.log(ob.target.checked);

    if (ob.target.checked == true) {
      this.checkPoliceyBox++;
      console.log(this.checkPoliceyBox);
    } else {
      this.checkPoliceyBox--;
    }
  }
  submit() {
    this.initRequestOffers()
    if (this.checkPoliceyBox == 5 && this.form.status == "VALID") {
      this.openLoadingModal()
      this.setIssueTicket()
    }
  }
  openLoadingModal(): void {
    this.loadingModal = this.matDialog.open(LoadingModalComponent, {
      panelClass: "alternativeDateDialog",
      autoFocus: false,
      maxHeight: "95vh",
      maxWidth: "95vw",
      backdropClass: "background-transparent",

    })

  }
  setIssueTicket() {
    this.apis.issueTicketWithPnr(this.initRequestOffers()).subscribe({
      next: (res: any) => {
        this.loadingModal.close();
        if (res.data) {
          this.loadingModal.close()
          const order = res.data?.order
          this.router.navigate(['/payment-details/' + order.order_status + '/' + order.id]);
        }
      },
      error: (err: any) => {
        this.loadingModal.close()
        this.router.navigate(['/update-payment/zelle/' + this.orderId]);
      }
    });
  }
  initRequestOffers() {
    if (this.form.status != "VALID") {
      this.submitError = true;
    } else {
      const orderData = this.form.value;
      // data.CreditCardCountry = this.getCountryCode(data.CreditCardCountry);
      orderData.CreditCardCountry = this.countryCode;
      const object: any = {};
      object.order_id = this.orderId;
      object.CreditCardCity = orderData.CreditCardCity;
      object.CreditCardstreetAddress = orderData.CreditCardstreetAddress;
      object.cardHolderName = orderData.cardHolderFullName;
      object.CreditCardCountry = orderData.CreditCardCountry;
      object.CreditCardZip = orderData.CreditCardZip;
      object.CreditCardVendorCode = orderData.CreditCardVendorCode;
      object.CreditCardExpireDate = orderData.CreditCardExpireDate;
      object.CreditCardNumber = orderData.CreditCardNumber;
      object.state = orderData.State;
      object.creditCardRegion = orderData.creditCardRegion;
      object.contact_email = orderData.contactEmail;
      object.contact_phone = orderData.contactPhone;
      object.cvc = orderData.cvc;
      object.payment_intent = "pi_1Iv1ssEzVRHi1jXem8lfgH7k",
        object.customer_ip = this.myIpAddress;
      return object;
    }


  }
  openErorPaymentModal(): void {
    this.dialogRef = this.matDialog.open(PaymentDialogComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      disableClose: true,
      width: '840px',
      maxWidth: "98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: null,
        order_id: this.orderId
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }
  updateExpireDate() {
    const date =
      this.form.value.CreditCardExpireDateYear +
      "-" +
      this.form.value.CreditCardExpireDateMonth.padStart(2, '0');
    this.form.patchValue({
      CreditCardExpireDate: date,
    });
    this.setCreditCardExpireDate = date
  }
  getContactInfo() {

    this.apis.getAccountInfo(this.token).subscribe({
      next: (res: any) => {
        this.userContact = res.data;
        this.form.patchValue({
          contactEmail: this.userContact.email,
          contactPhone: this.userContact.phone
        })
      }
    })
  }
}

