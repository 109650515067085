import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-seats-navigation-buttons',
  templateUrl: './review-flight-seats-navigation-buttons.component.html',
  styleUrls: ['./review-flight-seats-navigation-buttons.component.css']
})

export class ReviewFlightSeatsNavigationButtonsComponent implements  OnInit{
  @Input() flights: any;
  @Output() setIndexEvent = new EventEmitter();
  @Input() index: any = 0;
constructor(
  private seoService: SEOService) {
}

  ngOnInit() {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  nextFlight() {
    this.index++;
    this.setIndexEvent.emit(this.index);
  }

  previousFlight() {
    this.index--;
    this.setIndexEvent.emit(this.index);
  }
}
