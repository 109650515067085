<div class="nav-manu">
  <nav class="navbar navbar-light justify-content-center">
    <div class="navdasbar-1">
      <div>
        <a routerLink="/" class="navbar-brand sticky-pop d-flex flex-column align-items-center">
          <div class="d-flex align-items-center">
            <img
              style="width: 35px; height: 35px ;padding-right: 7px; "
              src="/assets/images/home/coparess/logo4.png"
              class="d-inline-block   align-top"
              alt="FlyAllOver"
              title="FlyAllOver"
            />
            <h1 class="siteName ">Fly All Over</h1>
          </div>
        </a>

        <div class="toggle-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

    </div>
  </nav>
  <nav
    class="navbar navbar-expand-lg nav-show navbar-light white-nav justify-content-between nav-hidden mobileFixPadding col-12 p-0"
  >
    <div class="nav-width">
      <div class="navbar-1 col-12 p-0">
        <div class="navbar-collapse justify-content-center" id="navbarText">
          <ul class="navbar-nav col-12 p-0">
            <li class="nav-item active Font-Family">
              <a class="nav-link" href="https://flyallover.com/" routerLink="/"
              >Home <span class="sr-only"></span
              ></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/blog">Blogs</a>
            </li>

            <!-- <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                href="http://www.travelinsured.com/agency?agency=53835"
              >
                Travel Insurance
              </a>
            </li> -->
            <li class="nav-item">
              <a
                class="nav-link visaLink"
                routerLink="/visa"
                href="https://flyallover.com/visa"
              >
                <span style="margin-right: 5px">Visa</span>
                <span class="newBadge">New</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/vacation">Vacation</a>
            </li>
            <li class="nav-item">
              <a  target="_blank" class="nav-link" href="https://book.mylimobiz.com/v4/flyallover" data-ores-widget="quickres"
                 data-ores-alias="flyallover" data-redirect-url="https://flyallover.com/">WorldWide Limo Any Where</a>


            </li>

            <!-- <div class="drop-menu" >
              <li class="nav-item" (click)="openOffersMenu()" >
                <a class="nav-link">Offers <span class="arrow-v"><svg class="arrow-v" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></span></a>
              </li>
              <span class="arrow-up"></span>
              <div class="open-menu" [ngClass]="{'menu-show': menuView}" (mouseleave)="openOffersMenu()">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/offers">Offers</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/Egyptair-Offers"> Egyptair Offers<img alt="Egyptair offers" src="../../../assets/icons/icons8-favorites.svg" width="45" height="30"></a>
                </li>
              </div></div> -->
            <li class="nav-item" *ngIf="!token">
              <a class="nav-link" routerLink="/sign-in"> Login </a>
            </li>
            <li class="nav-item">
              <a *ngIf="token" class="nav-link" routerLink="/profile/dashboard">
                Profile
              </a>
            </li>
            <!-- <li class="ml-5"> <img src="../../../assets/images/arrow/phone.png" width="30px" class="mx-2"><a class="Font-Family text-white mt-1">+1 212-381-1167</a> </li> -->
            <li>
              <div class="d-flex">
                <div>
                  <img src="../../../assets/images/arrow/phone.png" width="40px" class="mx-2">
                </div>
                <div style="line-height: 1.2; margin-top: 4px;">
                  <span class="Font-Family text-white">+1 212-381-1167</span> <br>
                  <p class="Font-Family text-white" style="font-size: 12px;">For issues or complaints</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
