import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApisService } from "../../../services/apis.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { validators } from "sitemap";
import { SEOService } from "../../../services/seo.service";
import { DOCUMENT } from "@angular/common";
import { LocalStorageService } from "src/app/services/webApis/local-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { MatDialog } from "@angular/material/dialog";
import { ThanksModalComponent } from "../thanks-modal/thanks-modal.component";
// import {} from '@types/googlemaps';
import { Title } from '@angular/platform-browser';

import PlaceResult = google.maps.places.PlaceResult;
@Component({
  selector: "app-review-flight-payment",
  templateUrl: "./review-flight-payment.component.html",
  styleUrls: ["./review-flight-payment.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewFlightPaymentComponent implements OnInit {
  @Output() nextStepEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();
  @Output() updatePaymentDataEvent = new EventEmitter();
  @Input() paymentData: any;
  card: any;
  vendorCode;
  error = false;
  submitError = false;
  filteredCountries: Observable<string[]>;
  countries;
  form: FormGroup;
  vendorSrc: any;
  checkEmail: boolean;
  messageCheckEmail: string;
  errorMessageYear;
  errorMessageMonth;
  token: any;
  selectedTravellers: any;
  dataPnr: any ;
  loading: boolean = false;
  messageError: string;
  tagId: string;
  @Input() Countries2;
  @Input() payLater;
  @Output() countries2 = new EventEmitter();
  @Input() offers;
  dialogRef: any;

  @Input() adressType: string;
  @Input() familyMembers: any;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;
  showDetails = false;
  @Input() addressType: string;
  place!: object;

  addressForm!: FormGroup;
  // googleAddress!: GoogleAddress;
  phone: string;
  title = 'google-places-autocomplete';
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''


  autocompleteInput: string;
  queryWait: boolean;
  ZipCode: void;
  city: any;
  addresscomponents: any[] = [];
  state: any;
  contactUserEmail: any;
  contactUserPhone: any;
  contactUserData: any;
  country: any;
  countryCode: any;
  itinerary: any[];
  checkName: boolean;
  paymentType: string= 'Credit';
  changePaymentKay: any;
  constructor(private apis: ApisService, private seoService: SEOService, @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalStorageService, private _ActivatedRoute: ActivatedRoute, private cookie: CookieService,
    private _Router: Router, private matDialog: MatDialog,
    private titleService: Title,
  ) {
    this.tagId = this._ActivatedRoute.snapshot.queryParams['tagId'];
    this.selectedTravellers = JSON.parse(this.localStorage.getItem("selectedTravellers"));
    this.form = this.initForm();
    this.seoUpdate();
    if (this.localStorage.getItem("flyallover_token") !== "undefined") {
      this.token = this.localStorage.getItem('flyallover_token');
      this.getUserContact()
    }
  }

  ngOnInit() {


    if (this.offers == 'false') {
      this.getOfferPnr();
    }
    this.getCountries();
    this.countries2.emit();
    if (this.paymentData) {
      this.setValue();
    }
    // check email
  }
  getUserContact() {
    this.apis.showUser(this.token).subscribe({
      next: (user: any) => {
        this.contactUserData = user.data
        this.contactUserPhone = this.contactUserData.phone;
        this.contactUserEmail = this.contactUserData.email;
      }, error: error => {
        console.error(error);

      }
    })
  }
  validationcheckEmail(): any {
    let element = this.document.getElementById('email');
    this.form.get("email").valueChanges.subscribe(email => {
      if (this.token == undefined) {
        console.log(this.messageCheckEmail);
        this.apis.checkMail(email).subscribe(
          (data: any) => {
            if (data.status) {
              this.checkEmail = true
              this.messageCheckEmail = undefined;
              element.classList.remove('is-invalid')
              element.classList.add('is-valid')
            }
          },
          (err) => {
            this.checkEmail = false
            this.messageCheckEmail = err.error.error.email[0];
            element.classList.remove('is-valid')
            element.classList.add('is-invalid')
          }
        );
      } else {
        if (!this.f['email']?.errors?.email && !this.f['email']?.errors?.required) {
          element.classList.remove('is-invalid')
          element.classList.add('is-valid')
        } else {
          element.classList.remove('is-valid')
          element.classList.add('is-invalid')
        }
      }
    })
  }
  validationCountry(FormControlName: any) {
    let element = this.document.getElementById(FormControlName);
    if (!this.f[FormControlName]?.errors?.required) {
      element.classList.remove('is-invalid')
      element.classList.add('is-valid')
      if (!this.f[FormControlName]?.errors?.someProp) {
        element.classList.remove('is-invalid')
        element.classList.add('is-valid')
      } else {
        element.classList.remove('is-valid')
        element.classList.add('is-invalid')
      }
    } else {
      element.classList.remove('is-valid')
      element.classList.add('is-invalid')
    }
  }
  validationForm(FormControlName: any) {
    this.form.get(FormControlName).valueChanges.subscribe((value) => {
      if (this.f[FormControlName].dirty) {
        let element = this.document.getElementById(FormControlName);
        if (FormControlName == 'email') {
          return
        } else {
          if (!this.f[FormControlName]?.errors?.required) {
            element.classList.remove('is-invalid')
            element.classList.add('is-valid')
            if (!this.f[FormControlName]?.errors?.pattern) {
              element.classList.remove('is-invalid')
              element.classList.add('is-valid')
            } else {
              element.classList.remove('is-valid')
              element.classList.add('is-invalid')
            }
          } else {
            element.classList.remove('is-valid')
            element.classList.add('is-invalid')
          }
        }
      }
    })
  }
  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.addresscomponents = address.address_components;

    //   address.address_components.length.forEach(function (value) {
    //     console.log(value);
    // });
    for (let i = 0; i < address.address_components.length; i++) {

      for (let ii = 0; ii < address.address_components[i].types.length; ii++) {
        console.log('address.address_components[i].types[ii]', address.address_components[i].types[ii])
        if (address.address_components[i].types[ii] == "postal_code") {
          this.ZipCode = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "administrative_area_level_1") {
          this.state = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "locality") {
          this.city = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.country = address.address_components[i].long_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.countryCode = address.address_components[i].short_name;
        }
      }

    }
    // this.ZipCode=address.address_components[address.address_components.length-1].short_name;
    // this.city = address.address_components[2].long_name;
    // this.form.markAsDirty();
    console.log(address)
    console.log('ZipCode', this.ZipCode)
    console.log('ZipCode', this.ZipCode)
    console.log('this.state', this.state)
  }
  onChangeZip(e) {
    this.ZipCode = e;
  }
  onChangeCity(e) {
    this.city = e;
  }
  onChangeCountry(e) {
    this.country = e;
  }
  onChangeState(e) {
    this.state = e;
  }
  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  getCountries() {
    if (this.Countries2) {
      this.countries = this.Countries2;
      this.filteredCountries =
        this.form.controls.CreditCardCountry.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterCountries(value))
        );
    }
  }

  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  setPaymentCardEvent(e) {
    this.form.patchValue({
      CreditCardNumber: e.card,
      CreditCardVendorCode: e.vendorCode,
    });
  }

  updateHolderName() {
    this.form.patchValue({
      cardHolderName: this.form.value.cardHolderFullName,
    });
  }

  updateExpireDate() {
    const date =
      this.form.value.CreditCardExpireDateYear +
      "-" +
      this.form.value.CreditCardExpireDateMonth;
    this.form.patchValue({
      CreditCardExpireDate: date,
    });
  }

  checkDate() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const valueMonth = this.form.value.CreditCardExpireDateMonth;
    const valueYear = this.form.value.CreditCardExpireDateYear;
    if (valueYear == null) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Is Required!";
    }
    if (valueYear > year) {
      this.errorMessageYear = null;
      this.errorMessageMonth = null;
    }
    if (valueYear < year) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Should Be More Than " + year;
    }
    if (valueYear == year) {
      this.errorMessageYear = null;
      if (month > valueMonth) {
        this.errorMessageYear = null;
        this.errorMessageMonth = "Month should Be More Than " + month;
      }
      if (month <= valueMonth) {
        this.errorMessageMonth = null;
      }
    }
  }

  initForm(): FormGroup {
    return new FormGroup({
      CreditCardNumber: new FormControl("", []),
      CreditCardVendorCode: new FormControl("", []),
      // cardHolderName: new FormControl("", []),
      CreditCardExpireDate: new FormControl("", []),
      cardHolderFullName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$"),
      ]),
      card: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{16}"),
      ]),
      cvc: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{3}"),
      ]),
      CreditCardExpireDateMonth: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{2,}"),
      ]),
      CreditCardExpireDateYear: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
      // addressLine2: new FormControl(""),
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,}$"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      State: new FormControl("", [Validators.required]),
      creditCardRegion: new FormControl(""),
      contact_phone: new FormControl("", [Validators.required]),
      contact_email: new FormControl("", [Validators.required]),
      // stateOrProvince: new FormControl("", [Validators.pattern("[a-zA-Z ]+")]),
      // email: new FormControl("", [Validators.required, Validators.email]),
      // phone: new FormControl("", [Validators.required, Validators.pattern("[0-9]{1,}"),])
    });
  }
  get f() {
    return this.form.controls;
  }
  getOfferPnr() {
    this.loading = true;
    this.form.patchValue({
      CreditCardVendorCode: this.selectedTravellers.CreditCardVendorCode,
      CreditCardZip: this.selectedTravellers.CreditCardZip,
      CreditCardstreetAddress: this.selectedTravellers.CreditCardstreetAddress,
      CreditCardCity: this.selectedTravellers.CreditCardCity,
      cardHolderName: this.selectedTravellers.cardHolderName,
      CreditCardCountry: this.selectedTravellers.CreditCardCountry
    })
    this.apis.createOnlyPnr(this.selectedTravellers).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.dataPnr = res.data;
        if (this.dataPnr.access_token != "") {
          this.localStorage.setItem('flyallover_token', this.dataPnr.access_token)
          this.cookie.set('flyallover_token', this.dataPnr.access_token);
        }
        this.form.removeControl('email');
        this.form.removeControl('phone');
        this.form.removeControl('State');
        this.form.addControl('pnr', new FormControl(this.dataPnr.order.pnr, [Validators.required]));
        this.form.addControl('order_id', new FormControl(this.dataPnr.order.id, [Validators.required]));
        this.form.addControl('tagId', new FormControl(this.tagId, [Validators.required]));
        localStorage.setItem('user_id', res.data.user.id);
        localStorage.setItem('email_log', res.data.user.email);
      },
      error: (err: any) => {
        this.loading = false;
        this.messageError = err.error.error;

      }
    })
  }
  createPnr() {
    this.apis.createOnlyPnr(this.selectedTravellers).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.dataPnr = res.data;
        if (this.dataPnr.access_token != "") {
          this.localStorage.setItem('flyallover_token', this.dataPnr.access_token)
          this.cookie.set('flyallover_token', this.dataPnr.access_token);
        }
        this.form.removeControl('email');
        this.form.removeControl('phone');
        this.form.removeControl('State');
        this.form.addControl('pnr', new FormControl(this.dataPnr.order.pnr, [Validators.required]));
        this.form.addControl('order_id', new FormControl(this.dataPnr.order.id, [Validators.required]));
        this.form.addControl('tagId', new FormControl(this.tagId, [Validators.required]));
        localStorage.setItem('user_id', res.data.user.id);
        localStorage.setItem('email_log', res.data.user.email);
        this.openThanksModal();
      },
      error: (err: any) => {
        this.loading = false;
        this.messageError = err.error.error;
        this.openThanksModal();
      }
    })
  }
  openThanksModal(): void {
    this.dialogRef = this.matDialog.open(ThanksModalComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      maxHeight: "95vh",
      width: '440px',
      backdropClass: "background-transparent",
      data: {
        dataKey: this.dataPnr,
        error: this.messageError,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      // this._Router.navigate(["/"]);
    });
  }
  submit(): any {
    if (this.form.status != "VALID") {
      this.submitError = true;
    } else {
      const data = this.form.value;
      // data.CreditCardCountry = this.getCountryCode(data.CreditCardCountry);
      data.CreditCardCountry = this.countryCode;
      this.updatePaymentDataEvent.emit(data);
      this.nextStepEvent.emit();
    }
  }

  getCountryCode(country): any {
    const index = this.countries.findIndex((obj) => obj.name === country);
    if (index > -1) {
      return this.countries[index];
    }
  }

  testCreditCard() {
    this.card = this.form.value.card;
    const vendorCode = {
      AX: "American Express",
      CB: "Carte Blanche",
      DC: "Diners Club",
      DS: "Discover",
      CA: "MasterCard",
      VI: "Visa",
      JC: "JCB",
    };
    if (this.checkCreditCard(this.form.value.card, vendorCode["AX"])) {
      this.vendorCode = "AX";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CB"])) {
      this.vendorCode = "CB";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DC"])) {
      this.vendorCode = "DC";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DS"])) {
      this.vendorCode = "DS";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CA"])) {
      this.vendorCode = "CA";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["VI"])) {
      this.vendorCode = "VI";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["JC"])) {
      this.vendorCode = "JC";
      this.error = false;
    } else {
      this.vendorCode = 'VI'
      this.error = true;
    }
    const object = {
      card: this.form.value.card,
      error: this.error,
      vendorCode: this.vendorCode,
    };
    this.setPaymentCardEvent(object);
  }

  setValue() {
    this.form.patchValue({
      CreditCardNumber: this.paymentData.card,
      CreditCardVendorCode: this.paymentData.CreditCardVendorCode,
      cardHolderName: this.paymentData.cardHolderName,
      CreditCardExpireDate: this.paymentData.CreditCardExpireDate,
      cardHolderFullName: this.paymentData.cardHolderFullName,
      //   cardHolderFirstName: this.paymentData.cardHolderFirstName,
      //   cardHolderMiddleName: this.paymentData.cardHolderMiddleName,
      //   cardHolderLastName: this.paymentData.cardHolderLastName,
      CreditCardExpireDateMonth: this.paymentData.CreditCardExpireDateMonth,
      CreditCardExpireDateYear: this.paymentData.CreditCardExpireDateYear,
      CreditCardCountry: this.paymentData.CreditCardCountry,
      CreditCardstreetAddress: this.paymentData.CreditCardstreetAddress,
      addressLine2: this.paymentData.addressLine2,
      CreditCardCity: this.paymentData.CreditCardCity,
      State: this.paymentData.State,
      creditCardRegion: this.paymentData.creditCardRegion,
      stateOrProvince: this.paymentData.stateOrProvince,
      CreditCardZip: this.paymentData.CreditCardZip,
      cvc: this.paymentData.cvc,
      card: this.paymentData.card,
      contact_email: this.paymentData.contact_email,
      contact_phone: this.paymentData.contact_phone
    });
  }

  checkCreditCard(cardnumber, cardname) {
    let ccErrorNo = 0;
    const ccErrors = [];
    const cards = [];
    ccErrors[0] = "Unknown card type";
    ccErrors[1] = "No card number provided";
    ccErrors[2] = "Credit card number is in invalid format";
    ccErrors[3] = "Credit card number is invalid";
    ccErrors[4] = "Credit card number has an inappropriate number of digits";
    ccErrors[5] =
      "Warning! This credit card number is associated with a scam attempt";

    // Define the cards we support. You may add addtional card types as follows.
    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit

    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      checkdigit: true,
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      checkdigit: true,
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      checkdigit: true,
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305",
      checkdigit: true,
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      checkdigit: true,
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      checkdigit: true,
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35",
      checkdigit: true,
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149",
      checkdigit: true,
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767",
      checkdigit: true,
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
      checkdigit: true,
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      checkdigit: true,
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      checkdigit: true,
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      checkdigit: true,
    };

    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {
      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType == -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length == 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, "");

    // Check that the number is numeric
    let cardNo = cardnumber;
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 != 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo == "5490997771092064") {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(",");

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp("^" + prefix[i]);
      if (exp.test(cardNo)) {
        PrefixValid = true;
      }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(",");
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length == lengths[j]) {
        LengthValid = true;
      }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    }

    // The credit card is in the required format.
    return true;
  }
  format(e) {

    e.target.value = this.padLeft(e.target.value, "0", 2);
    console.log(e.target.value)
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);

  }
  checkCardName() {
    this.checkName = false
    const formName = this.form.value.cardHolderFullName;
    var cardName = formName?.split(' ')[0] + ' ' + formName?.split(' ')[formName.split(' ').length - 1]
    let travelerName = this.familyMembers?.family_members.find(element => this.checkTravelerName(element.family_member) === cardName.toLocaleLowerCase())
    if (travelerName) {

      return true
    } else {
      return false
    }

  }
  checkTravelerName(travelerName) {
    let setName = travelerName?.first_name + ' ' + travelerName?.last_name;
    return setName.toLocaleLowerCase()
  }
  paymentTypeAction(typePayment: string) {
    this.paymentType = typePayment
 }
 checkedValue(checked){
    this.changePaymentKay =checked.value
  }
}
