import {Router, ActivatedRoute} from '@angular/router';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {CookieService} from 'ngx-cookie-service';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  trip_type: string;
  itinerary: any;
  createPnr: any;
  totalPrices: string;
  DocumentNumber: string;
  chargebaggageAllowance;
  code: string;
  countRequest: number = 0; // not used
  freeBaggageAllowance;
  operatingAirlines = [];
  supportMessage: boolean = false;
  intent: string = undefined;
  loading: boolean = true;
  invalidCard: boolean;
  flightsAirlines = [];
  name: string;
  isBrowser: boolean;

  constructor(
    private seoService: SEOService,
    private router: Router,
    private route: ActivatedRoute,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private metaService: MetaTagsService,
    private viewportScroller: ViewportScroller,
    private cookie: CookieService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.seoUpdate();
  }

  ngOnInit() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.intent = this.route.snapshot.queryParams.payment_intent;
    this.createPnr = JSON.parse(this.localStorage.getItem('bookData'));
    if (this.intent?.length > 1) {
      this.setCreatePnr();
    } else if (this.intent == '1') {
      this.loading = false;
      this.supportMessage = true;
      this.invalidCard = false;
    } else if (this.intent == '0') {
      this.loading = false;
      this.supportMessage = true;
      this.invalidCard = true;
    }

  }

  seoUpdate() {

    this.seoService.updateTitle('Confirmation | Flyallover');
    this.seoService.updateDescription('Confirmation | Flyallover');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  setCreatePnr() {
    if (this.createPnr) {
      this.invalidCard = false;
      this.trip_type = this.createPnr.staticResult[0].trip_type;
      this.itinerary = this.createPnr.staticResult[1].itinerary;
      this.itinerary.flights.forEach(el => {
        el.forEach(element => {
          this.flightsAirlines.push({
            'ArrivalAirport': element.ArrivalAirport,
            'DepartureAirport': element.DepartureAirport,
            'FlightNumber': element.FlightNumber,
            'OperatingAirline': element.OperatingAirline
          });
        });
      });
      const token = this.localStorage.getItem('completedBookings');
      this.createPnr.createPnr['payment_intent'] = this.intent;
      this.apis.createPnr(this.createPnr.createPnr, token).subscribe((data: any) => {

        // if message = Manual review is required
        if (data.data == 'Manual review is required') {
          // no success create PNR
          this.supportMessage = true;
        } else {
          // response create PNR
          this.DocumentNumber = data.data[0].DocumentNumber;
          this.code = data.data[0].Reservation.content;
          this.totalPrices = +data.data[0].TotalAmount.content + this.itinerary?.Fees;
          this.name = data.data[0].FirstName + ' ' + data.data[0].LastName
          this.supportMessage = false;
          if (data.status) {
            setTimeout(() => {
              // success create PNR
              this.loading = false;
            }, 400);
          }
        }
        this.localStorage.removeItem('bookData')
      }, err => {
        console.log(err);

        // error response create PNR from api server
        this.loading = false;
        this.supportMessage = true;
        this.localStorage.removeItem('bookData')
      });

      for (let i = 0; i < this.itinerary.flights.length; i++) {
        for (let ii = 0; ii < this.itinerary.flights[i].length; ii++) {
          this.operatingAirlines.push({photo: this.itinerary.flights[i][ii].OperatingAirline});
        }
      }
      this.freeBaggageAllowance = this.itinerary.allow;
      this.chargebaggageAllowance = this.itinerary.charge;
    } else {
      if (this.isBrowser) {

        this.intent = undefined;
      }
    }
  }

  getDateString(date) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return (`${days[new Date(date).getDay()].substring(0, 3)} , ${months[new Date(date).getMonth()].substring(0, 3)} ${new Date(date).getDate()} , ${new Date(date).getFullYear()}`);
  }

  getTimeString(date) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return (`
    ${(new Date(date).getHours()) > 12 ? (new Date(date).getHours()) - 12 : (new Date(date).getHours())
    }:${this.zero(new Date(date).getMinutes()) + new Date(date).getMinutes().toString()
    } ${(new Date(date).getHours()) >= 12 ? 'PM' : 'AM'
    }
    `);
  }

  getDurationString(minutes) {
    return (`Duration ${Math.floor(minutes / 60)}h ${minutes % 60}m`);
  }

  layoverTime(num) {
    if (num > 0) {
      let h = Math.floor(num / 60);
      let m = num % 60;
      return h + 'hrs ' + m + ' mins';
    } else {
      return num;
    }
  }

  zero(num) {
    return (num < 10 ? '0' : '');
  }

  modifyMetaTag() {
    this.metaService.modifyMetaTags({robots: 'noindex'})
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
