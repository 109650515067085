import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SEOService} from '../../services/seo.service';
import {CookieService} from "ngx-cookie-service";
import {LocalStorageService} from "../../services/webApis/local-storage.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Clipboard} from "@angular/cdk/clipboard";
import {WindowService} from 'src/app/services/webApis/window.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from "@angular/common";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.css']
})
export class TripDetailsComponent implements OnInit {
  word = '';
  showResultSearch: boolean = false;
  searchInput: string = '';
  page: any = 0;
  pageSize: number = 9;
  data: any = [];
  about: any;
  collectionSize: number;
  dataGnrl: any;
  tripId: any;
  title: any;
  formData: FormGroup;
  count: any;
  datashare: any;
  copied: boolean;
  ShareData: any;
  isVisible = true;
  checkValue: any;
  checkEmail: boolean;
  form: FormGroup;
  isLoading = false;
  messageCheckEmail: any;
  token: string;
  ZipCode: any;
  city: any;
  country: any;
  state: any;
  countryCode: any;
  userAddress: any;
  userLatitude: any;
  userLongitude: any;
  addresscomponents: any;
  Countries2: any;
  countries: any;
  filteredCountries: any;
  contactUserEmail: any;
  contactUserPhone: any;
  name: any;
  countries2: any;
  loading: boolean = false;
  messageError: string;
  card: any;
  vendorCode: string;
  error: boolean;
  errorMessageYear;
  errorMessageMonth;
  submitError: boolean;
  trip_id: any;
  selectedCountryId: string;
  priceSelect: any[] = [60, 100];
  select: number;
  VideoName = 'Angular';
  @ViewChild('videoPlayer', {static: false}) videoplayer: ElementRef;
  isPlay = false;
  formSubmitted = false;
  msg: any;

  constructor(
    private apis: ApisService,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute,
    private cookie: CookieService,
    private _Router: Router,
    private modalService: NgbModal,
    private router: Router,
    private clipboard: Clipboard,
    private _Window: WindowService,
    private formBuilder: FormBuilder,
    // public dialogRef: MatDialogRef<TripDetailsComponent>,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.tripId = this._ActivatedRoute.snapshot.paramMap.get('slug')
    this.token = this.localStorage.getItem('flyallover_token');
    this.getMetaData();
    this.form = this.initForm();
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      agent_name: ['', []],
      contact_agent_name: ['', []],
      agent_email: ['', []],
      agent_phone: ['', []],

    });


  }


  ngOnInit(): void {
    this.getTripsInfo();
    this.getCountries();
    this.countries2?.emit();
    this.toggleVideo()
    // this.countries2.emit();
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  restart() {
    const video: any = document.getElementById('my_video_1');
    video.currentTime = 0;
  }

  getCountries() {
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    }, err => {
    });
  }

  seoUpdate(meta_title, meta_description, image) {
    this.seoService.updateTitle(meta_title);
    this.seoService.updateDescription(meta_description);
    this.seoService.updateUrl();
    this.seoService.updateImage(image);
  }

  handleSelectChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedCountryId = target.value;
    console.log('Selected value:', this.selectedCountryId);
    // يمكنك تنفيذ الإجراء الذي ترغب فيه باستخدام القيمة المحددة
  }

  // seoUpdate( ) {
  //   this.seoService.updateTitle('Discover Cairo Hidden Gems');
  //   this.seoService.updateDescription('For just $148 per person in a TRP Room, you enjoy: Accommodation for 1 night in a comfortable Standard Room');
  //   this.seoService.updateUrl();
  //   this.seoService.updateImage('https://www.flyallover.com/assets/images/blog/post3.png');
  // }

  getTripsInfo() {
    this.isLoading = true;
    this.apis.getTripsById(this.tripId).pipe(finalize(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000)
    })).subscribe((data: any) => {
        // this.data = data.reverse();
        // this.title = data.data.title ;
        this.datashare = data?.data;
        this.data = data?.data?.trip_includes;
        // console.log(this.datashare, 'this.data details')
        // this.seoUpdate(this.datashare.meta_title , this.datashare.meta_description , this.datashare.image);
      },
      err => {
        console.log(err);
      });

  }

  updateExpireDate() {
    const date =
      this.form.value.CreditCardExpireDateYear +
      "-" +
      this.form.value.CreditCardExpireDateMonth;
    this.form.patchValue({
      CreditCardExpireDate: date,
    });
  }

  format(e) {

    e.target.value = this.padLeft(e.target.value, "0", 2);
    console.log(e.target.value)
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);

  }

  checkDate() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const valueMonth = this.form.value.CreditCardExpireDateMonth;
    const valueYear = this.form.value.CreditCardExpireDateYear;
    if (valueYear == null) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Is Required!";
    }
    if (valueYear > year) {
      this.errorMessageYear = null;
      this.errorMessageMonth = null;
    }
    if (valueYear < year) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Should Be More Than " + year;
    }
    if (valueYear == year) {
      this.errorMessageYear = null;
      if (month > valueMonth) {
        this.errorMessageYear = null;
        this.errorMessageMonth = "Month should Be More Than " + month;
      }
      if (month <= valueMonth) {
        this.errorMessageMonth = null;
      }
    }
  }

  initForm(): FormGroup {
    return new FormGroup({
      CreditCardNumber: new FormControl("", []),
      CreditCardVendorCode: new FormControl("", []),
      // cardHolderName: new FormControl("", []),
      CreditCardExpireDate: new FormControl("", []),
      cardHolderName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$"),
      ]),
      card: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{16}"),
      ]),
      cvc: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{3||4}"),
      ]),
      CreditCardExpireDateMonth: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,}"),
      ]),
      CreditCardExpireDateYear: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
      // addressLine2: new FormControl(""),
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,}$"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      State: new FormControl("", [Validators.required]),
      // creditCardRegion: new FormControl(""),
      // phone: new FormControl("", [Validators.required]),
      // email: new FormControl("", [Validators.required]),
      // stateOrProvince: new FormControl("", [Validators.pattern("[a-zA-Z ]+")]),
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [Validators.required, Validators.pattern("[0-9]{1,}"),]),
      trip_id: new FormControl("",),
      country_id: new FormControl("",),
      amount: new FormControl("",),
    });
  }

  getMetaData() {
    this.apis.getTripsMetaById(this.tripId).subscribe((data: any) => {
        this.ShareData = data.data;
        this.trip_id = this.ShareData.id;
        console.log(this.ShareData, 'this.data ShareData')
        console.log(this.trip_id, 'this.trip_id')
        // this.seoUpdate(this.ShareData.meta_title , this.ShareData.meta_description , this.ShareData.image);
        // this.seoUpdate();

      },
    );
  }


  // seoUpdate(meta_title , meta_description , image ) {
  //   this.seoService.updateTitle(meta_title);
  //   this.seoService.updateDescription(meta_description);
  //   this.seoService.updateUrl();
  //   this.seoService.updateImage(image);
  // }

  onSubmit(): void {

    const formData = new FormData();
    formData.append('name', this.formData.value.name);
    formData.append('email', this.formData.value.email);
    formData.append('phone', this.formData.value.phone);
    formData.append('contact_agent_name', this.formData.value.contact_agent_name);
    formData.append('agent_name', this.formData.value.agent_name);
    formData.append('agent_email', this.formData.value.agent_email);
    formData.append('agent_phone', this.formData.value.agent_phone);
    formData.append('slug', this.tripId);

    // @ts-ignore
    this.apis.getTripContact(formData).subscribe({
      next: (res: any) => {
        console.log(res);
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    this.formData.reset();

  }

  check(value) {
    this.checkValue = value;
    console.log(this.checkValue, '  this.check4Value')
  }

  cop() {
    this.clipboard.copy('https://flyallover.com' + this.router.url)
    this.copied = !this.copied
    setTimeout(() => {
      this.copied = false;
    }, 1500)
  }

  fb() {
    let url = 'www.google.com';
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + 'https://www.flyallover.com' + this.router.url,
      'facebook-popUp',
      'width=600px,height=350px,'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  linked() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }


  get f() {
    return this.form.controls;
  }

  // validationcheckEmail():any {
  //   let element = this.document.getElementById('email');
  //   this.form.get("email").valueChanges.subscribe(email => {
  //     if (this.token == undefined) {
  //       console.log(this.messageCheckEmail);
  //       this.apis.checkMail(email).subscribe(
  //         (data: any) => {
  //           if (data.status) {
  //             this.checkEmail = true
  //             this.messageCheckEmail = undefined;
  //             element.classList.remove('is-invalid')
  //             element.classList.add('is-valid')
  //           }
  //         },
  //         (err) => {
  //           console.log(err);
  //           this.checkEmail = false
  //           this.messageCheckEmail = err.error.error.email[0];
  //           console.log(this.messageCheckEmail);
  //           element.classList.remove('is-valid')
  //           element.classList.add('is-invalid')
  //         }
  //       );
  //     }else{
  //       if (!this.f['email']?.errors?.email && !this.f['email']?.errors?.required) {
  //         element.classList.remove('is-invalid')
  //         element.classList.add('is-valid')
  //       } else {
  //         element.classList.remove('is-valid')
  //         element.classList.add('is-invalid')
  //       }
  //     }
  //   })
  // }
  // validationCountry(FormControlName:any){
  //   let element = this.document.getElementById(FormControlName);
  //   if (!this.f[FormControlName]?.errors?.required) {
  //     element.classList.remove('is-invalid')
  //     element.classList.add('is-valid')
  //     if (!this.f[FormControlName]?.errors?.someProp) {
  //       element.classList.remove('is-invalid')
  //       element.classList.add('is-valid')
  //     } else {
  //       element.classList.remove('is-valid')
  //       element.classList.add('is-invalid')
  //     }
  //   } else {
  //     element.classList.remove('is-valid')
  //     element.classList.add('is-invalid')
  //   }
  // }
  // validationForm(FormControlName: any) {
  //   this.form.get(FormControlName).valueChanges.subscribe((value) => {
  //     if (this.f[FormControlName].dirty) {
  //       let element = this.document.getElementById(FormControlName);
  //       if (FormControlName == 'email') {
  //         return
  //       } else {
  //         if (!this.f[FormControlName]?.errors?.required) {
  //           element.classList.remove('is-invalid')
  //           element.classList.add('is-valid')
  //           if (!this.f[FormControlName]?.errors?.pattern) {
  //             element.classList.remove('is-invalid')
  //             element.classList.add('is-valid')
  //           } else {
  //             element.classList.remove('is-valid')
  //             element.classList.add('is-invalid')
  //           }
  //         } else {
  //           element.classList.remove('is-valid')
  //           element.classList.add('is-invalid')
  //         }
  //       }
  //     }
  //   })
  // }
  validationForm(FormControlName: any) {
    this.form.get(FormControlName).valueChanges.subscribe((value) => {
      if (this.f[FormControlName].dirty) {
        let element = this.document.getElementById(FormControlName);
        if (FormControlName == 'email') {
          return
        } else {
          if (!this.f[FormControlName]?.errors?.required) {
            element.classList.remove('is-invalid')
            element.classList.add('is-valid')
            if (!this.f[FormControlName]?.errors?.pattern) {
              element.classList.remove('is-invalid')
              element.classList.add('is-valid')
            } else {
              element.classList.remove('is-valid')
              element.classList.add('is-invalid')
            }
          } else {
            element.classList.remove('is-valid')
            element.classList.add('is-invalid')
          }
        }
      }
    })
  }

  checkCreditCard(cardnumber, cardname) {
    let ccErrorNo = 0;
    const ccErrors = [];
    const cards = [];
    ccErrors[0] = "Unknown card type";
    ccErrors[1] = "No card number provided";
    ccErrors[2] = "Credit card number is in invalid format";
    ccErrors[3] = "Credit card number is invalid";
    ccErrors[4] = "Credit card number has an inappropriate number of digits";
    ccErrors[5] =
      "Warning! This credit card number is associated with a scam attempt";

    // Define the cards we support. You may add addtional card types as follows.
    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit

    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      checkdigit: true,
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      checkdigit: true,
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      checkdigit: true,
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305",
      checkdigit: true,
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      checkdigit: true,
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      checkdigit: true,
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35",
      checkdigit: true,
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149",
      checkdigit: true,
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767",
      checkdigit: true,
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
      checkdigit: true,
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      checkdigit: true,
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      checkdigit: true,
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      checkdigit: true,
    };

    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {
      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType == -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length == 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, "");

    // Check that the number is numeric
    let cardNo = cardnumber;
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 != 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo == "5490997771092064") {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(",");

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp("^" + prefix[i]);
      if (exp.test(cardNo)) {
        PrefixValid = true;
      }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(",");
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length == lengths[j]) {
        LengthValid = true;
      }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    }

    // The credit card is in the required format.
    return true;
  }

  testCreditCard() {
    this.card = this.form.value.card;
    const vendorCode = {
      AX: "American Express",
      CB: "Carte Blanche",
      DC: "Diners Club",
      DS: "Discover",
      CA: "MasterCard",
      VI: "Visa",
      JC: "JCB",
    };
    if (this.checkCreditCard(this.form.value.card, vendorCode["AX"])) {
      this.vendorCode = "AX";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CB"])) {
      this.vendorCode = "CB";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DC"])) {
      this.vendorCode = "DC";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DS"])) {
      this.vendorCode = "DS";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CA"])) {
      this.vendorCode = "CA";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["VI"])) {
      this.vendorCode = "VI";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["JC"])) {
      this.vendorCode = "JC";
      this.error = false;
    } else {
      this.vendorCode = 'VI'
      this.error = true;
    }
    const object = {
      card: this.form.value.card,
      error: this.error,
      vendorCode: this.vendorCode,
    };
    this.setPaymentCardEvent(object);
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.addresscomponents = address.address_components;

    console.log(address, 'addressTotal');
    // });
    for (let i = 0; i < address.address_components.length; i++) {

      for (let ii = 0; ii < address.address_components[i].types.length; ii++) {
        console.log('address.address_components[i].types[ii]', address.address_components[i].types[ii])
        if (address.address_components[i].types[ii] == "postal_code") {
          this.ZipCode = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "administrative_area_level_1") {
          this.state = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "locality") {
          this.city = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.country = address.address_components[i].long_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.countryCode = address.address_components[i].short_name;
        }
      }

    }
    console.log(address)
    console.log('ZipCode', this.ZipCode)
    console.log('country', this.country)
    console.log('this.state', this.state)
  }

  onChangeZip(e) {
    this.ZipCode = e;
  }

  onChangeCity(e) {
    this.city = e;
  }

  onChangeCountry(e) {
    this.country = e;
  }

  onChangeState(e) {
    this.state = e;
  }


  setPaymentCardEvent(e) {
    this.form.patchValue({
      CreditCardNumber: e.card,
      CreditCardVendorCode: e.vendorCode,
    });
  }

  // setValue() {
  //   this.form.patchValue({
  //     CreditCardNumber: this.form.value.card,
  //     CreditCardVendorCode: this.form.value.CreditCardVendorCode,
  //     cardHolderName: this.form.value.cardHolderName,
  //     CreditCardExpireDate: this.form.value.CreditCardExpireDate,
  //     CreditCardExpireDateMonth: this.form.value.CreditCardExpireDateMonth,
  //     CreditCardExpireDateYear: this.form.value.CreditCardExpireDateYear,
  //     CreditCardCountry: this.form.value.CreditCardCountry,
  //     CreditCardstreetAddress: this.form.value.CreditCardstreetAddress,
  //     addressLine2: this.form.value.addressLine2,
  //     CreditCardCity: this.form.value.CreditCardCity,
  //     State: this.form.value.State,
  //     creditCardRegion: this.form.value.creditCardRegion,
  //     stateOrProvince: this.form.value.stateOrProvince,
  //     CreditCardZip: this.form.value.CreditCardZip,
  //     cvc: this.form.value.cvc,
  //     card: this.form.value.card,
  //     contact_email: this.form.value.contact_email,
  //     contact_phone: this.form.value.contact_phone
  //   });
  // }
  checkedFee(fee) {
    if (fee) {
      this.form.controls['checked_fee']
      this.form.patchValue({
        checked_fee: 1
      })
    } else {
      this.form.patchValue({
        checked_fee: ''
      })
    }
  }

  selectPrice(select: number) {
    this.select = select
    this.form.patchValue({
      amount: select
    })
  }


  submit(): any {
    if (this.form.status != "VALID") {
      this.submitError = true;
    } else {
      // const data = this.form.value;
      // data.CreditCardCountry = this.getCountryCode(data.CreditCardCountry);
      // data.CreditCardCountry = this.countryCode;
      // this.updatePaymentDataEvent.emit(data);
      // this.nextStepEvent.emit();
      this.form.patchValue({
        CreditCardNumber: this.form.value.card,
        CreditCardVendorCode: this.form.value.CreditCardVendorCode,
        cardHolderName: this.form.value.cardHolderName,
        CreditCardExpireDate: this.form.value.CreditCardExpireDate,
        // CreditCardExpireDateMonth: this.form.value.CreditCardExpireDateMonth,
        // CreditCardExpireDateYear: this.form.value.CreditCardExpireDateYear,
        CreditCardCountry: this.form.value.CreditCardCountry,
        CreditCardstreetAddress: this.form.value.CreditCardstreetAddress,
        addressLine2: this.form.value.addressLine2,
        CreditCardCity: this.form.value.CreditCardCity,
        State: this.form.value.State,
        // creditCardRegion: this.form.value.creditCardRegion,
        stateOrProvince: this.form.value.stateOrProvince,
        CreditCardZip: this.form.value.CreditCardZip,
        cvc: this.form.value.cvc,
        card: this.form.value.card,
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
        trip_id: this.trip_id,
        country_id: this.selectedCountryId,
      });
      this.formSubmitted = true;
      this.apis.TripPayment(this.form.value).subscribe({
        next: (res: any) => {
          this.msg = res;
          console.log(res);
        },
        error: (err: any) => {
          this.msg = err;
          console.log(err);
        }
      });
      this.formData.reset();

      console.log(this.form.value, ' form value from payment')

    }
  }

  close(): void {
    this.formSubmitted = false;

  }


}
