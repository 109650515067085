<section class="thanks-modal">
  <div >
    <div class="booking-error">
      <h1 class="Font-Family">Dear valued Fly All Over customer,</h1>
      <p class="note-des">

        We hope this message finds you well. We wanted to take a moment to express our sincere appreciation for your business and continued support. Your trust in our services means a lot to us.

        However, we regret to inform you that we were unable to verify your identity at this time. As a result, we kindly request that you use an alternative method of payment to proceed with your booking. We understand that this may be inconvenient, and we apologize for any inconvenience caused.

        Please rest assured that we take the security of our customers' information and transactions very seriously. To facilitate a secure payment, we offer the following alternative methods:<br>

        1. Zelle: You can use the Zelle payment platform to send a secure payment directly from your bank account. Please follow the instructions provided by your bank to initiate the payment using Zelle.<br>

        2. Wise  Transfer: You can initiate a wire transfer from your bank to our designated bank account. Please contact our customer support team, and they will provide you with the necessary details to complete the transfer.<br>

        3. Plaid Payment: We also offer the option to make a secure payment using Plaid. Plaid is a trusted payment platform that allows you to connect your bank account securely and make payments with ease. Please visit our website or contact our customer support team for further instructions on using Plaid for your payment.<br>

        Should you require any assistance or have any questions regarding these alternative payment methods, our dedicated customer support team is available to help you navigate through this process.

        Once again, we deeply appreciate your understanding and cooperation in this matter. Thank you for choosing Fly All Over, and we look forward to serving you in the future.

        Best regards,


        Fly All Over Customer Support Team</p>
      <mat-radio-group >
        <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="zelle"> <img height="65" width="150" src="./assets/icons/pament-icons/Zelle.svg" alt=""></mat-radio-button>
        <!-- <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="plaid"> <img height="65" width="150" src="./assets/icons/pament-icons/Plaid.svg" alt=""></mat-radio-button> -->
        <!-- <mat-radio-button class="example-margin col-12" (change)="checkedValue($event)" value="wisetransfer"> <img height="65" width="200" src="./assets/icons/pament-icons/TransferWise.svg" alt=""></mat-radio-button> -->
      </mat-radio-group>      <p></p>
      <div *ngFor="let item of errorData">
        <ul class="error" *ngIf="errorData">
          <li class="Font-Family">{{item}}</li>
        </ul>
        <p class="error Font-Family" *ngIf="!errorData">There is a problem with your visa number</p>

      </div>
      <div class="btn-port" >
        <!-- <button class="fao_mobile_btn_small-verify" type="button" mat-dialog-close="" (click)="openPlaidLink()">Verify Document</button> -->
        <button class="fao_mobile_btn_small_verify col-6 me-2"  type="button" mat-dialog-close="" (click)="goToHome()"> home</button>
        <button class="fao_mobile_btn_small col-6" type="button"(click)="changepayment()">Confirm</button>
      </div>
    </div>
  </div>
</section>
