import {DOCUMENT, ViewportScroller, isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, ViewChildren, AfterViewInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {SEOService} from '../../services/seo.service';
import {distinctUntilChanged, map, switchMap, catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {WindowService} from 'src/app/services/webApis/window.service';
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-centre',
  templateUrl: './centre.component.html',
  styleUrls: ['./centre.component.css']
})
export class CentreComponent implements OnInit, AfterViewInit {

  @ViewChildren('categoriesList') categoriesList;
  @ViewChildren('categoriesListMobile') categoriesListMobile;

  currentApi = 'all Articles';
  searchInput = '';
  categorySlug: any;
  categories;
  categoriesBreadCrumb = [];
  posts = [];
  articleSuggestions = {};
  // booleans
  isMenuCollapsed = true;
  mobile;
  loading = false;
  showResultSearch = false;
  // pagination variables
  page = 1;
  pageSize = 11;
  collectionSize;
  $categoriesListSubscription;
  $categoriesListMobileSubscription;

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private viewportScroller: ViewportScroller,
    private windowService: WindowService,
  ) {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle('Support | Fllyallover');
    this.seoService.updateDescription('Frequently Asked Questions on all Flyallover travel services. Answers to most FAQs on flights, hotels, cruises, vacations and travel insurance and all you need in Booking');
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');
  }

  ngOnInit(): void {
    this.getCategories();
    this.route.paramMap.subscribe((paramMap: any) => {
      const parametrs = paramMap.params;
      if (parametrs.category) {
        this.categorySlug = paramMap.get('category');
        this.searchInput = '';
        this.getArticlesForCategory(this.categorySlug);
      } else if (parametrs.query) {
        // this if condition to check if query string has value
        // if it does call search api, if it is empty call allArticles api
        this.searchInput = paramMap.get('query');
        this.categorySlug = undefined;
        this.search();
      } else {
        this.getAllArticles();
      }
    });
    if (this.windowService.nativeWindow.screen?.width < 992) {
      this.mobile = true;
    }
  }

  ngAfterViewInit() {
    // detecting the end of ngFor loops that loop on categories array
    this.$categoriesListSubscription = this.categoriesList.changes.subscribe(t => {
      // this method is called in case there is a categorySlug, it will update the view of the category tab
      this.categoriesUI();
    })
    this.$categoriesListMobileSubscription = this.categoriesListMobile.changes.subscribe(t => {
      // this method is called in case there is a categorySlug, it will update the view of the category tab
      this.categoriesUI();
    });
  }

  getAllArticles(page = 1) {
    this.currentApi = 'all Articles';
    this.showResultSearch = false;
    this.categoriesBreadCrumb = [];
    this.loading = true;
    const navItems = this.document.querySelectorAll('.navbar-centre .active');
    // navItems.forEach(navItem => navItem.classList.remove('active'))
    for (let i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('active')
    }
    const firstNavItem = this.document.getElementById('first-nav-item')
    if (firstNavItem) firstNavItem.classList.add('active');
    const params = {
      page: page,
      paginate: this.pageSize
    }
    this.apis.getAllArticles(params).subscribe((res: any) => {
      this.posts = res.data;
      this.assignPaginationVars(res?.meta?.current_page, res?.meta.total, res?.meta?.per_page);
      this.loading = false;
    }, err => {
      this.posts = [];
      this.loading = false;
    })
  }

  triggerSearch() {
    // persist search query in url,such that in case the page reloads get the query from url
    // when this method is called it changes  parameters, and in  params subscription search support will be called
    if (this.searchInput) {
      this.router.navigate(['/support/search', this.searchInput]);
    } else {
      this.router.navigate(['/support'])
    }
    this.getAllArticles()
  }

  search(page = 1) {
    this.loading = true;
    this.currentApi = 'search';
    this.categoriesBreadCrumb = [];
    const navItems = this.document.querySelectorAll('.active');
    // navItems.forEach(navItem => navItem.classList.remove('active'))
    for (let i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('active')
    }
    // if (this.searchInput.length > 0) {
    const params = {
      text: this.searchInput,
      page: page,
      paginate: this.pageSize
    }
    this.apis.searchArticles(params).subscribe((res: any) => {
      this.showResultSearch = true;
      this.posts = res.data;
      this.assignPaginationVars(res?.meta.current_page, res?.meta.total, res?.meta.per_page);
      this.loading = false;
      const navItems = this.document.querySelectorAll('.active');
      // navItems.forEach(navItem => navItem.classList.remove('active'))
      for (let i = 0; i < navItems.length; i++) {
        navItems[i].classList.remove('active')
      }
    }, err => {
      this.showResultSearch = true;
      this.posts = [];
      this.loading = false;
    })
  }

  searchSuggestions = (text$: Observable<string>) => {
    return text$.pipe(
      // distinctUntilChanged(),
      switchMap(term => {
        const params = {
          text: term
        }
        return this.apis.searchArticles(params).pipe(
          map((res: any) => {
            return res.data.map(el => {
              this.articleSuggestions[`${el.title_content}_slug`] = el.post_slug;
              this.articleSuggestions[`${el.title_content}_category`] = el.category_slug;
              return el.title_content
            })
          }),
          catchError(() => {
            return of([]);
          })
        )
      })
    )
  }

  getArticlesForCategory(categorySlug: string, page = 1) {
    const params = {
      page: page,
      paginate: this.pageSize
    }
    //  control pagination (changePage method will call which api)
    this.currentApi = 'categories';
    this.showResultSearch = false;
    this.loading = true;
    this.apis.getArticlesOfCategory(categorySlug, params).subscribe((res: any) => {
      // this.setMetaTags(res?.category_meta?.data)
      this.posts = res.data;
      this.categoriesBreadCrumb = this.posts[0]?.bread_crumb;
      this.assignPaginationVars(res?.meta?.current_page, res?.meta?.total, res?.meta?.per_page);
      this.loading = false;
    }, err => {
      this.posts = [];
      this.categoriesBreadCrumb = [];
      this.loading = false;
    })
  }

  getCategories() {
    this.apis.getCategories().subscribe((res: any) => {
      const allcats = res.data;
      this.categories = allcats.filter(cat => cat.parent_id == null);
    }, err => {
      this.categories = undefined
    })
  }

  categoriesUI(event = undefined) {
    // this condition is triggered by changing categorySlug in URl, when triggered active class is added to active tab
    if (this.categorySlug && !event) {
      const activeCategory = this.document.getElementById(`${this.categorySlug}`);
      if (activeCategory) {
        const navItems = this.document.querySelectorAll('.active');
        // navItems.forEach(navItem => navItem.classList.remove('active'))
        for (let i = 0; i < navItems.length; i++) {
          navItems[i].classList.remove('active')
        }
        activeCategory.classList.add('active');
        this.$categoriesListSubscription.unsubscribe();
        this.$categoriesListMobileSubscription.unsubscribe();
      }
    } else if (event) {
      if (event.target.id !== 'restOfCats' && event.target.id) {
        this.categorySlug = event.target.id
        // control active tab styling
        const navItems = this.document.querySelectorAll('.active');
        // navItems.forEach(navItem => navItem.classList.remove('active'))
        for (let i = 0; i < navItems.length; i++) {
          navItems[i].classList.remove('active')
        }
        event.target.classList.add('active');
        // this.getArticlesForCategory(this.categorySlug);
        this.router.navigate(['/support', this.categorySlug])
      }
    }
  }

  changePage(page) {
    // this.windowService.nativeWindow.scrollTo(0, 0);
    this.viewportScroller.scrollToPosition([0, 0])
    switch (true) {
      case this.currentApi === 'all Articles':
        this.getAllArticles(page);
        break;
      case this.currentApi === 'search':
        this.search(page);
        break;
      case this.currentApi === 'categories':
        this.getArticlesForCategory(this.categorySlug, page);
        break;
      default:
        this.getAllArticles(page);
        break;
    }
  }

  assignPaginationVars(currentPage, total, pageSize) {
    // pagination variable assignment
    this.page = currentPage;
    this.collectionSize = total;
    this.pageSize = pageSize;
  }

  goToArticle(event) {
    // get the title of the article form the event (the articles titles are keys in this.articleSuggestions object)
    const articleSelected = event.item;
    // get the value (the slug) of the key articleSelected
    const aticleSlug = this.articleSuggestions[`${articleSelected}_slug`];
    const aticleCategory = this.articleSuggestions[`${articleSelected}_category`];
    this.router.navigate(['/support', aticleCategory, aticleSlug])
  }

  createRange(number) {
    var items
    [] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
