import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'offer'
})
export class OfferService implements PipeTransform {
  transform(offer: any, word: string) {
    return offer.filter(offer => {
      return offer.name.toLowerCase().includes(word.toLowerCase()) ||
        offer.offer_description.toLowerCase().includes(word.toLowerCase());
    });
  }
}
