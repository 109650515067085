import {Component, OnInit, HostListener, Inject, ChangeDetectionStrategy} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {DOCUMENT} from '@angular/common';
import {SEOService} from '../../services/seo.service';
import {Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  constructor(
    private seoService: SEOService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.seoUpdate();
  }

  linkShare = 'flyallover.comholiday-inn-bar-harbor/';
  copy_status = false;
  close_status = true;

  results = ['$212.85', '$356.81', '$356.81'];
  page = 1;
  pageSize = 8;
  minDate = new Date(1990, 0, 1);
  maxDate = new Date(2020, 0, 1);
  public innerWidth: any;
  public contentHeight;

  items: GalleryItem[];
  imageData = data;

  degrees = [
    {name: 'Cleanlines', degree: '7.2'},
    {name: 'Comfort', degree: '5.5'},
    {name: 'Facilities', degree: '8.1'},
    {name: 'Location', degree: '3.9'},
    {name: 'Stuff', degree: '9.2'},
    {name: 'Wifi', degree: '6.2'},
  ];

  carouselOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    items: 1
  };

  minValue = 200;
  maxValue = 900;

  seoUpdate() {

    this.seoService.updateTitle('Offer | FlyAllOver');
    this.seoService.updateDescription('Offer | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.contentHeight = document.getElementById('hotelsPage').clientHeight + document.getElementById('benefit').clientHeight;

    // gallery
    this.items = this.imageData.map(item => new ImageItem({src: item.srcUrl, thumb: item.previewUrl}));
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Bottom
    });
    lightboxRef.load(this.items);
  }

  openGallery(e, i) {
    if (i == 4) {
      e.srcElement.childNodes[0].click();
    }
  }

  getCheckbox(event) {
  }

  copyMessage(val: string, input) {
    const selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    input.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);
    this.copy_status = true;
    setTimeout(() => {
      this.copy_status = false;
    }, 1000);
  }

}

const data = [
  {
    srcUrl: './assets/images/offer/hotel-photo-1.png',
    previewUrl: './assets/images/offer/hotel-photo-1.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-2.png',
    previewUrl: './assets/images/offer/hotel-photo-2.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-3.png',
    previewUrl: './assets/images/offer/hotel-photo-3.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-4.png',
    previewUrl: './assets/images/offer/hotel-photo-4.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-5.png',
    previewUrl: './assets/images/offer/hotel-photo-5.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-1.png',
    previewUrl: './assets/images/offer/hotel-photo-1.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-2.png',
    previewUrl: './assets/images/offer/hotel-photo-2.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-3.png',
    previewUrl: './assets/images/offer/hotel-photo-3.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-4.png',
    previewUrl: './assets/images/offer/hotel-photo-4.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-5.png',
    previewUrl: './assets/images/offer/hotel-photo-5.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-1.png',
    previewUrl: './assets/images/offer/hotel-photo-1.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-2.png',
    previewUrl: './assets/images/offer/hotel-photo-2.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-3.png',
    previewUrl: './assets/images/offer/hotel-photo-3.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-4.png',
    previewUrl: './assets/images/offer/hotel-photo-4.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-5.png',
    previewUrl: './assets/images/offer/hotel-photo-5.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-1.png',
    previewUrl: './assets/images/offer/hotel-photo-1.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-2.png',
    previewUrl: './assets/images/offer/hotel-photo-2.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-3.png',
    previewUrl: './assets/images/offer/hotel-photo-3.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-4.png',
    previewUrl: './assets/images/offer/hotel-photo-4.png'
  },
  {
    srcUrl: './assets/images/offer/hotel-photo-5.png',
    previewUrl: './assets/images/offer/hotel-photo-5.png'
  }
];
