<div class="container-fluid">
  <div class="row g-0">
    <!-- /////////////////////////////////////////////////////////////// -->
    <div class="post1-container col-lg-8 col-md-12 col-sm-12 col-xs-12">
      <a href="/hotel/100141305" class="post1">
        <div class="post1-photo hotelImg">
          <img
            title=" The St. Regis Almasa Hotel, Cairo"
            alt=" The St. Regis Almasa Hotel, Cairo"
            src="../../../assets/images/hotels/hotels/10.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 night, 2 adults</small>
            <h4>$135.00</h4>
          </div>
        </div>
        <div>
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              The St. Regis Almasa Hotel, Cairo
            </h6>
            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel">
            Non-smoking rooms (generic) | Rollaway adult | Crib charge | Extra
            person | Tennis court | Conference facilities | Health club | Spa
          </p>
          <div class="hotelLocation1">
            <a
              target="blank"
              href="https://www.google.com/maps/place/30%C2%B004'46.2%22N+31%C2%B026'07.2%22E/@30.0795,31.437522,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xee51f0577b34e365!8m2!3d30.07951!4d31.43533"
              ><label
                ><i class="fas fa-map-marker-alt"></i>&nbsp;Cairo City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- /////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100003453" class="post">
        <div class="post-photo hotelImg">
          <img
            title="Hilton Dubai Jumeirah"
            alt="Hilton Dubai Jumeirah"
            src="../../../assets/images/hotels/hotels/5.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 night, 2 adults</small>
            <h4>$265.41</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              Hilton Dubai Jumeirah
            </h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Lounges/bars | Non-smoking rooms (generic) | Smoking rooms available
            | Smoke-free property
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100003453" class="post">
        <div class="post-photo hotelImg">
          <img
            title="Avenue Hotel"
            alt="Avenue Hotel"
            src="../../../assets/images/hotels/hotels/15.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 night, 2 adults</small>
            <h4>$90.64</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">Avenue Hotel</h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Car rental desk | Meal plan available | Lounges/bars |
            Transportation services - local area
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/102175321" class="post">
        <div class="post-photo hotelImg">
          <img
            title="Staybridge Suites Dubai"
            alt="Staybridge Suites Dubai"
            src="../../../assets/images/hotels/hotels/16.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 nights, 2 adults</small>
            <h4>$100.99</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              Staybridge Suites Dubai
            </h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Lounges/bars | Non-smoking rooms (generic) | Smoke-free property
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100003453" class="post">
        <div class="post-photo hotelImg">
          <img
            title="Grand Hyatt Dubai"
            alt="Grand Hyatt Dubai"
            src="../../../assets/images/hotels/hotels/17.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 nights, 2 adults</small>
            <h4>$255.88</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">Grand Hyatt Dubai</h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Car rental desk | Meal plan available | Lounges/bars | Non-smoking
            rooms (generic)
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100420376" class="post">
        <div class="post-photo hotelImg">
          <img
            title=" Four Points By Sheraton"
            alt=" Four Points By Sheraton"
            src="../../../assets/images/hotels/hotels/18.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 nights, 2 adults</small>
            <h4>$81.66</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              Four Points By Sheraton
            </h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Non-smoking rooms (generic) | Rollaway adult | Crib charge | Extra
            person
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100003012" class="post">
        <div class="post-photo hotelImg">
          <img
            title=" Chelsea Gardens Hotel Apts"
            alt=" Chelsea Gardens Hotel Apts"
            src="../../../assets/images/hotels/hotels/20.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 nights, 2 adults</small>
            <h4>$85.74</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              Chelsea Gardens Hotel Apts
            </h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Lounges/bars | Non-smoking rooms (generic) | Smoking rooms available
            | Family Room
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
    <div class="post-container col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <a href="/hotel/100408340" class="post">
        <div class="post-photo hotelImg">
          <img
            title=" Marriott Executive Apartments"
            alt=" Marriott Executive Apartments"
            src="../../../assets/images/hotels/hotels/21.jpg"
            class="img-fluid"
          />
          <div class="hotelPrice">
            <small class="nightNum">1 nights, 2 adults</small>
            <h4>$149.72</h4>
          </div>
        </div>
        <div class="post-description">
          <div class="hotelRating">
            <h6 title="Hotel America" class="hotelName">
              Marriott Executive Apartments
            </h6>

            <div class="offer_description">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <p class="aboutHotel2">
            Non-smoking rooms (generic) | Rollaway adult | Crib charge | Extra
            person | Tennis court.
          </p>
          <div class="hotelLocation">
            <a
              target="blank"
              href="https://www.google.com/maps/place/25%C2%B001'46.5%22N+55%C2%B017'02.5%22E/@25.02957,55.2862287,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfb870482b139a688!8m2!3d25.02957!4d55.28404"
              ><label
                ><i class="fas fa-map-marker-alt"></i> Dubai City - Show
                Map</label
              ></a
            >
          </div>
        </div>
      </a>
    </div>
    <!-- //////////////////////////////////////////////////////////////////////// -->
  </div>
</div>
