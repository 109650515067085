import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-visa-engine',
  templateUrl: './visa-engine.component.html',
  styleUrls: ['./visa-engine.component.css']
})
export class VisaEngineComponent implements OnInit {
  filteredDestinations: Observable<string[]>;
  filteredNationalities: Observable<string[]>;
  filteredResidency: Observable<string[]>;
  filteredPurpose: Observable<string[]>;
  destinationError = false;
  nationalityError = false;
  residencyError = false;
  purposeError = false;
  destinations: any;
  nationalities: any;
  residency: any;
  request = {
    input: {
      destination: '',
      nationality: '',
      residency: '',
      purpose: '',
    },
    value: {
      destination: '',
      nationality: '',
      residency: '',
      purpose: '',
    }
  };
  form: FormGroup;
  visaData: any;
  error: string;
  oldVisaFormData: any;
  purposes: any = [
    {
      name: 'Tourism',
      value: 'T'
    },
    {
      name: 'Business',
      value: 'B'
    },
    {
      name: 'Education',
      value: 'TR'
    },
  ];

  private _filterDestinations(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.destinations.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.nationalities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterResidency(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.residency.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterPurposes(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.purposes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
    private router: Router,
  ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
    // this.initOldData();
    this.getCountries();
  }

  initForm(): FormGroup {
    return new FormGroup({
      destination: new FormControl('', Validators.required),
      nationality: new FormControl('', Validators.required),
      residency: new FormControl('', Validators.required),
      purpose: new FormControl('', Validators.required),
    });
  }

  getCountries(): void {
    this.apis.getCountries().subscribe(response => {
      this.destinations = response;
      this.nationalities = response;
      this.residency = response;
      this.filteredDestinations = this.form.controls.destination.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterDestinations(value)),
        );
      this.filteredNationalities = this.form.controls.nationality.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterNationalities(value))
        );
      this.filteredResidency = this.form.controls.residency.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterResidency(value))
        );
      this.filteredPurpose = this.form.controls.purpose.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPurposes(value))
        );
    }, err => {
      console.log(err);
    });
  }

  submit() {
    this.destinationError = false;
    this.nationalityError = false;
    this.residencyError = false;
    this.purposeError = false;
    if (this.getSelectedDestination() && this.getSelectedNationality() &&
      this.getSelectedResidency() && this.getSelectedPurpose() &&
      this.form.status === 'VALID') {
      const params = {
        destination: this.request.value.destination,
        nationality: this.request.value.nationality,
        residency: this.request.value.residency,
        purpose: this.request.value.purpose,
      };
      this.router.navigate(['visa/' + this.request.input.destination], { queryParams: params });
    }
  }

  getSelectedDestination() {
    const index = this.destinations.findIndex(x => x.name === this.form.value.destination);
    if (index < 0) {
      this.destinationError = true;
      this.error = 'please select valid destination';
      return false;
    }
    this.request.input.destination = this.destinations[index].name;
    this.request.value.destination = this.destinations[index].iso_code;
    return true;
  }

  getSelectedNationality() {
    const index = this.nationalities.findIndex(x => x.name === this.form.value.nationality);
    if (index < 0) {
      this.nationalityError = true;
      this.error = 'please select valid nationality';
      return false;
    }
    this.request.input.nationality = this.nationalities[index].name;
    this.request.value.nationality = this.nationalities[index].iso_code;
    this.error = 'please select valid destination';
    return true;
  }

  getSelectedResidency() {
    const index = this.residency.findIndex(x => x.name === this.form.value.residency);
    if (index < 0) {
      this.residencyError = true;
      this.error = 'please select valid residency';
      return false;
    }
    this.request.input.residency = this.residency[index].name;
    this.request.value.residency = this.residency[index].iso_code;
    return true;
  }

  getSelectedPurpose() {
    const index = this.purposes.findIndex(x => x.name === this.form.value.purpose);
    if (index < 0) {
      this.purposeError = true;
      this.error = 'please select valid purpose';
      return false;
    }
    this.request.input.purpose = this.purposes[index].name;
    this.request.value.purpose = this.purposes[index].value;
    return true;
  }


  // submit(): void {
  //   this.visaData = this.form;
  //   if (this.visaData.status == 'VALID') {
  //     this.submitError = false;
  //
  //   } else {
  //     this.submitError = true;
  //     this.error = 'Destination or Nationality, Residency, and Purpose are required';
  //   }
  // }

  initOldData(): void {
    this.request = {
      input: {
        destination: '',
        nationality: '',
        residency: '',
        purpose: '',
      },
      value: {
        destination: '',
        nationality: '',
        residency: '',
        purpose: '',
      }
    };
    this.oldVisaFormData = JSON.parse(localStorage.getItem('visaFormData'));
    if (this.oldVisaFormData) {
      this.form.patchValue(this.oldVisaFormData.input);
      this.form.updateValueAndValidity();
    }
  }
}
