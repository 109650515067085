import { Component, OnInit,Inject } from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/webApis/local-storage.service';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/overlay-directives';

@Component({
  selector: 'app-check-requirment',
  templateUrl: './check-requirment.component.html',
  styleUrls: ['./check-requirment.component.css']
})
export class CheckRequirmentComponent implements OnInit {
  countries: any;
  countriesR:any;
  searchRequest: any;
  VisaFrom: any;
  visaDestinationCountryName: any;
  visaDestination: string;
  visaPurpose: any;
  visaData: any;
  visaDataR:any;
  visaRequired: any = true;
  isHealthRequirementsOpened = false;
  isHealthRequirementsOpenedR=false;
  readMoreEntryHealthRequirements = true;
  readMoreCountryExperience = true;
  readMoreTransitRestrictions = true;
  readMoreEntryRequirements = true;
  countryName:number;



  constructor(
    public dialogRef: MatDialogRef<CheckRequirmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: ApisService,
    private matDialog: MatDialog,
    private router: Router,
    private localStorage: LocalStorageService,

  ) { }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.getCountries();
      this.callVisaApi();

      // this.returnFunction();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  getCountries(): void {
    this.apis.getLocalCountries().subscribe(response => {
      this.countries = response;
      this.setCountryName();
    }, err => {
      console.log(err);
    });
  }

  // getCountriesReturn(): void {
  //   this.countriesR = this.data.origin.country_name;
  // }

  search(nameKey: string, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].code.trim().toLowerCase() === nameKey.trim().toLowerCase()) {
        return myArray[i];
      }
    }
  }

  setCountryName(): void {
    this.visaDestinationCountryName = this.search(this.visaDestination, this.countries);
    this.countryName = this.visaDestinationCountryName.name;
  }
  
  callVisaApi(): void {
    this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequest'));
    if (this.searchRequest) {
      const lastOriginIndex = this.searchRequest?.fullOrigin.split(',').length - 1;
      const lastDestinationIndex = this.searchRequest?.fullDestination.split(',').length - 1;
      this.VisaFrom = this.searchRequest?.fullOrigin.split(',')[lastOriginIndex].toLowerCase();
      this.visaDestination = this.searchRequest?.fullDestination.split(',')[lastDestinationIndex].toLowerCase();
      this.visaPurpose = 'T';
      this.apis.homeVisa(this.VisaFrom, this.visaDestination, this.visaPurpose).subscribe((response: any) => {
        this.visaData = response.data;
        this.checkIfVisaNotRequired();
        });

      }
      this.apis.homeVisa(this.visaDestination, this.VisaFrom , this.visaPurpose).subscribe((response: any) => {
        this.visaDataR = response.data;
        });

    }


  checkIfVisaNotRequired(): void {
    if (this.visaData.VisaMessage) {
      const flagArray = this.visaData.VisaMessage.split(',');
      if (flagArray && flagArray.length > 0) {
        if (flagArray[0] == 'No') {
          this.visaRequired = false;
        }
      }
    }
  }

  returnFunction(){
  }

  getFreshScore(index: number) {
    if(index == 0){
      this.countryName = this.visaDestinationCountryName.name;
    }else{
      this.countryName=this.data.origin.country_name;
    }
  }



}
