<!-- .............................................. more things to do ....................................... -->

<app-navbar></app-navbar>

<div>
    <ng-container>
        <div class="mt-5 col-10 mx-auto">
            <app-engine></app-engine>
        </div>
    </ng-container>
</div>
<br/>
<div class="container">
    <div class="mt-3">
        <div class="search-box">
            <input type="text" [(ngModel)]="name" placeholder="Search here..."/>
            <!-- <label class="my-2">Cou :</label>
             <mat-autocomplete >
               <mat-option *ngFor="let option of countries" [value]="option.name">
                 {{ option.name }}
               </mat-option>
             </mat-autocomplete>-->
            <div class="select-box">
                <mat-form-field appearance="none" style="margin-bottom: -27px;" >
                    <mat-label  >Select Country</mat-label>
                    <mat-select  [(value)]="country_id">
                        <mat-option (click)="selectCountries()" *ngFor="let item of countries" [value]="item.id">
                            {{item?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="search" (click)="search_thinks()" type="submit"><i class="fa fa-search"></i></button>
            <!--  <button>
              <img
                title="Search about trip"
                alt="search"
                src="./assets/images/blog/search.svg"
                width="30"
              />
            </button>-->
        </div>
       <!-- <div class="col-1"></div>
        <div>
                <div class="select-box">
                    <mat-form-field class="" appearance="fill">
                        <mat-label>Select Country</mat-label>
                        <mat-select [(value)]="name">
                            <mat-option *ngFor="let item of countries" [value]="item.name">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>-->
    </div>
</div>
<!--
    <mat-form-field appearance="fill">
      <mat-label>Country</mat-label>
      <input type="text" name="passportIssuedCountry" placeholder="Select Country"
             matInput required (click)="$event.target.select()" [matAutocomplete]="issuedCountry"
             (input)="filterPassportIssuedCountry($event)"/>
      <mat-autocomplete #issuedCountry="matAutocomplete">
        <mat-option *ngFor="let option of countries" [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  -->
<div class="container">
    <div class="row">
        <div class="col-12 mb-5">
            <h1 class="text-center mb-3  fao_mobile_title_large">THINGS TO DO</h1>
            <h1 class="h1Title ">Cheap Flight Tickets</h1>
        </div>
        <!-- ...............................................................  Cards things to do ............................................. -->
        <ng-container *ngFor="let item of data ; let i = index">
            <div class="col col-12 col-md-12 col-lg-4 p-16px">


                <div class="things card-body">
                    <div class="things-img">
                        <a [routerLink]="['../things-to-do/', item.city?.name, item.slug]">
                            <img
                                    src="{{ item.images[0].full_url }}"
                                    alt="{{ item.meta_description }}"
                                    class="rounded w-100 p-3"
                                    height="250px"
                            />
                            <div class="name">
                                <h4 class="fao_mobile_title_med text-center ">
                                    {{ item?.name }}
                                </h4>
                            </div>
                        </a>
                    </div>
                    <div
                            style="
                background-color: #ddd4d4;
                height: 1px;
                width: 95%;
                margin: auto;
              "
                    ></div>
                    <div>
                        <p class="fao_mobile_prgraph">
                            {{ item.about | slice:0:155 }} ....
                        </p>

                        <div class="center-class mb-4">
                            <button class="fao_des_btn_med "
                                    [routerLink]="['../things-to-do/', item.city?.name,item.slug]">
                                MORE DETAILS
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>
        <!-- ...............................................................  pagination ............................................. -->
        <ngb-pagination class="d-flex justify-content-center my-5 py-5" [collectionSize]="collectionSize"
                        (pageChange)="getThingsToDo($event)" [(page)]="page" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>
        </div>
        <div *ngIf="!data" class="col-3">
            <ng-container>
                <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '257px', margin: '0', height: '330px' }"
                ></ngx-skeleton-loader>
            </ng-container>

        </div>
    </div>
</div>

<app-footer></app-footer>
