<ng-container *ngIf="novbar">
  <app-navbar></app-navbar>
</ng-container>
<div class="page404">
    <div class="container text-center">
        <img title="image404" alt="no-result"  class="image404" src="./assets/images/help-center/no-result.png" />

        <h1>Sorry, We couldn't find what you're looking for!</h1>
        <h4>We can't find any data that match your search. Update your choices so we can try again or contact us.
        </h4>
        <div class="button-container">
            <button class="button-home" [routerLink]="['/']">Home</button>
            <button class="button-contact" [routerLink]="['/contact-us']">Contact Us</button>
        </div>

    </div>
</div>

<ng-container *ngIf="novbar">
  <app-footer></app-footer>
</ng-container>
