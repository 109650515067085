import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "src/app/services/webApis/local-storage.service";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApisService {
  // domain
  private domain = environment.domain;
  lat: any;
  lng: any;
  token: string;
  continueToPaymentStep: Subject<number> = new Subject<number>();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    this.token = localStorage.getItem("flyallover_token");
  }

  getAirports(code: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/airport-details?airport_code=" + code,
      { headers }
    );
  }
  searchBFM(tag_id, index) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/search-BFM",
      { tag_id, index },
      { headers }
    );
  }

  countryAirports(id: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/airports-at-country?country_id=" + id,
      { headers }
    );
  }

  relatedAirPorts(countryId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/airports-at-country`,
      { country_id: countryId },
      { headers }
    );
  }

  searchGeoHotels(term) {
    return this.http.get(this.domain + "/api/geo-location?code=" + term).pipe(
      map((response: any) => {
        if (response.data.length > 0) {
          return response.data.map((el) => {
            return ` ${el.name}, ${el.country}`;
          });
        } else {
          return [" "];
        }
      })
    );
  }

  searchHotelsName(term) {
    return this.http
      .get(this.domain + "/api/hotels/search-for-hotel?name=" + term)
      .pipe(
        map((response: any) => {
          if (response.data.length > 0) {
            return response.data.map((el) => {
              console.log(response.data);
              return ` ${el.name}, ${el.country_code}`;
            });
          } else {
            return [" "];
          }
        })
      );
  }

  searchHotelsNameV2(term) {
    return this.http
      .get(`${this.domain}/api/hotels/search-for-hotel?name=${term}`)
      .pipe(
        map((response: any) => {
          if (response.data.length > 0) {
            return response.data.map((el: any) => {
              return {
                name: `${el.name}, ${el.country_code}`,
                description: el.model_type, // or any other pro
                icon: this.getIconForType(el.model_type), // Assuming you are using country_code to determine the icon
              };
            });
          } else {
            return [
              {
                name: "No results found",
                icon: "path/to/no-results-icon.png",
              },
            ];
          }
        })
      );
  }

  // Function to get icon based on type or country_code
  getIconForType(type: string): string {
    const typeToIcon = {
      City: "assets/icons/city_icon.svg",
      hotel: "assets/icons/hotel_icon.svg",
      location: "assets/icons/location_icon.svg",
      airport: "assets/icons/airport_icon.svg",
      Place: "assets/icons/location_icon.svg",
    };
    return typeToIcon[type] || "assets/icons/info_icon.svg";
  }

  // Geo Autocomplete
  oldGeoAutocomplete(code: string, category: string) {
    return this.http
      .get(
        this.domain + "/api/geo-location?code=" + code + "&category=" + category
      )
      .pipe(
        map((response: any) => {
          if (category === "AIR") {
            if (response.data.length > 0) {
              return response.data.map((el) => {
                return `${el.id} - ${el.name}, ${el.city}, ${el.country}`;
              });
            } else {
              return [" "];
            }
          } else if (category === "CITY") {
            if (response.data.length > 0) {
              const arr = [];
              response.data.map((el) => {
                arr.push(`${el.name}, ${el.city}, ${el.country}`);
              });

              const data = {
                arr,
                res: response.data,
              };
              return { data };
            } else {
              return [" "];
            }
          }
        })
      );
  }

  geoAutocomplete_v2(
    code: string,
    category: string,
    latitude: string = null,
    longitude: string = null
  ) {
    /*
     * to convert response {data: Array(5), code: 200, status: true, error: null} to an array
     * so that it can be displayed using ngFor (something internal to ngbTypeAhead it only accepts arrays)
     * the returned array is in form of ['LHR - London Heathrow, England, London, GB', ...]
     */
    // ?code=' + code + '&category=' + category + '&lat=' + latitude + '&long=' + longitude
    let param = "?code=" + code;
    param += "&category=" + category;
    if (latitude) {
      param += "&lat=" + latitude;
    }
    if (longitude) {
      param += "&long=" + longitude;
    }
    return this.http.get(this.domain + "/api/geo-location-v2" + param).pipe(
      map((response: any) => {
        if (category === "AIR") {
          if (response.data.length > 0) {
            const data = response.data;
            const sorter1 = (a: any) =>
              a.code.toLowerCase() === code.toLowerCase() ? 1 : -1;
            let sortedArray = data.sort(sorter1);
            sortedArray = sortedArray.reverse();
            return sortedArray.map((el) => {
              return `${el.code} - ${el.name}, ${el.city_name}, ${el.country_code}`;
            });
          } else {
            return [" "];
          }
        } else if (category === "CITY") {
          if (response.data.length > 0) {
            return response.data.map((el) => {
              return ` ${el.name}, ${el.country}`;
            });
          } else {
            return [" "];
          }
        }
      })
    );
  }

  geoAutocomplete(
    code: string,
    category: string,
    latitude: string = null,
    longitude: string = null
  ) {
    /*
     * to convert response {data: Array(5), code: 200, status: true, error: null} to an array
     * so that it can be displayed using ngFor (something internal to ngbTypeAhead it only accepts arrays)
     * the returned array is in form of ['LHR - London Heathrow, England, London, GB', ...]
     */
    // ?code=' + code + '&category=' + category + '&lat=' + latitude + '&long=' + longitude
    let param = "?code=" + code;
    param += "&category=" + category;
    if (latitude) {
      param += "&lat=" + latitude;
    }
    if (longitude) {
      param += "&long=" + longitude;
    }
    return this.http.get(this.domain + "/api/geo-location" + param).pipe(
      map((response: any) => {
        if (category === "AIR") {
          if (response.data.length > 0) {
            return response.data.map((el) => {
              return `${el.id} - ${el.name}, ${el.city}, ${el.country}`;
            });
          } else {
            return [" "];
          }
        } else if (category === "CITY") {
          if (response.data.length > 0) {
            return response.data.map((el) => {
              return ` ${el.name}, ${el.country}`;
            });
          } else {
            return [" "];
          }
        }
      })
    );
  }

  // airlines in advanced search
  carriers() {
    return this.http.get(`${this.domain}/api/airlines`);
  }

  // Lead Price Calendar
  leadPriceCalendar(
    origin: string,
    destination: string,
    tripType: string = "OneWay",
    departureDate: string = null
  ) {
    let query = "?";
    if (origin) {
      query += "&origin=" + origin;
    }
    if (destination) {
      query += "&destination=" + destination;
    }
    if (tripType) {
      query += "&trip_type=" + tripType;
    }
    if (departureDate) {
      query += "&departure_date=" + departureDate;
    }
    return this.http.get(this.domain + "/api/calendar-prices" + query);
  }

  search(data: any): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    // if (data.trip_type == 'Return') {
    return this.http.post(this.domain + "/api/search_v4", data, { headers });
    // }
    // return this.http.post(this.domain + '/api/search', data, { headers });
  }

  newFilters(data) {
    return this.http.get(this.domain + "/api/flight/filter", { params: data });
  }

  checkMail(email) {
    return this.http.post(this.domain + "/api/check_email", {
      email,
    });
  }
  getArticlesDetails(id) {
    return this.http.get(this.domain + "/api/related-articles/" + id);
  }

  getHomeAirLines() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/home-airlines`);
  }

  createOnlyPnr(form) {
    console.log(form)
    const token = this.localStorage.getItem("flyallover_token");
    if (token) {
      const headers = new HttpHeaders()
        .set("Authorization", "Bearer " + token)
        .set("Accept", "application/json");
      // console.log("token createOnlyPnr = >{", token);

      return this.http.post(`${this.domain}/api/create-only-pnr-v1`, form, {
        headers,
      });
    } else {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return this.http.post(`${this.domain}/api/create-only-pnr-v1`, form, {
        headers,
      });
    }
  }

  issueTicketWithPnr(data) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    //const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + token);
    return this.http.post(`${this.domain}/api/issue-ticket-with-pnr`, data, {
      headers,
    });
  }
  checkCreditAuthorize(data) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/check-credit-card-with-authorize`,
      data,
      { headers }
    );
  }
  getIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  getAirLineByCallsign(callSign) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/get-airline-by-callsign`,
      { call_sign: callSign },
      { headers }
    );
  }

  getbHomeAirports() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/easy-access-airports`,
      {},
      { headers }
    );
  }

  getbHomePopularDestinations(countryId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations-at-country`,
      { country_id: countryId },
      { headers }
    );
  }

  getbHomeThingsToDo() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/access-things-to-do`,
      {},
      { headers }
    );
  }

  searchAlternativeDate(data: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/search-alternarive-date-v2",
      data,
      { headers }
    );
  }

  // city guides
  getCitiesGuide() {
    // return this.http.get('https://flyallover.com/offers/wp-json/wp/v2/posts');
    return this.http.get("./assets/data/temporary-response.json");
  }

  // get Air lines (to view in filters panel)
  getAirLines(RequestID) {
    return this.http.get(this.domain + "/api/search/get-airlines/" + RequestID);
  }

  // get page content depending on the page number (used in flight-results page)
  pagination(
    requestId: string,
    pageNo: number,
    tripType: string,
    is_roundtrip_selected: string
  ) {
    return this.http.get(
      this.domain +
        "/api/paginate-results?requestID=" +
        requestId +
        "&pageNo=" +
        pageNo +
        "&trip_type=" +
        tripType +
        "&is_roundtrip_selected=" +
        is_roundtrip_selected
    );
  }

  // offer
  getOffer(offerParams) {
    return this.http.get(this.domain + "/api/search", { params: offerParams });
  }

  getOfferDetails(offerSlug) {
    return this.http.get(`${this.domain}/api/offer-data/${offerSlug}`);
  }

  getTripById(tagId, tripType) {
    return this.http.get(
      this.domain + "/api/flights/" + tagId + "/" + tripType
    );
  }
  getFlightDataShare(tagId, tripType) {
    return this.http.get(
      this.domain + "/api/get-flight-info/" + tagId + "/" + tripType
    );
  }
  getPrivacyDetails(PrivacyId) {
    return this.http.get(
      this.domain + "/api/content-management-details/" + PrivacyId
    );
  }

  //   getMultiTicketTrib(first_tag_id,first_index_id,second_tag_id,second_index_id ) {
  //     return this.http.get(this.domain + '/api/revalidate-tags/' + first_tag_id + '/' + first_index_id+ '/' +second_tag_id + '/' + second_index_id);
  // }
  getMultiTicketTrib(
    first_tag_id,
    first_index_id,
    second_tag_id,
    second_index_id
  ) {
    return this.http.post(this.domain + "/api/revalidate-tags", {
      first_tag_id: first_tag_id,
      first_index_id: first_index_id,
      second_tag_id: second_tag_id,
      second_index_id: second_index_id,
    });
  }

  // countries from local file
  getLocalCountries() {
    return this.http.get("./assets/data/countries.json");
  }

  getCountries() {
    return this.http.get(`${this.domain}/api/countries`);
  }
  getCities(country_id) {
    return this.http.get(`${this.domain}/api/cities/` + country_id);
  }

  getAccountInfo(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(`${this.domain}/api/user`, { headers });
  }

  addFeedback(data, token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/add-user-feedback`,
      {
        email: data.email,
        phoneNumber: data.phoneNumber,
        feedback: data.feedback,
      },
      { headers }
    );
  }

  changeUserPassword(token, old_password, new_password) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/user/password`,
      { old_password, new_password },
      { headers }
    );
  }

  // getting the summary panel in flight-results page (cheapest-fastest)
  // getBestFlightResults(searchData) {
  //   return this.http.post(`${this.domain}/api/cheapest-fastest`, searchData);
  // }
  getBestFlights(searchData) {
    return this.http.get(
      `${this.domain}/api/search/get-filter-values` +
        "?request_id=" +
        searchData
    );
  }

  getFilterValues(requestId) {
    return this.http.get(
      `${this.domain}/api/search/get-filter-values` + "?request_id=" + requestId
    );
  }

  getSortValues(requestId) {
    return this.http.get(
      `${this.domain}/api/search/get-sort-values` + "?request_id=" + requestId
    );
  }

  createPnr(data, token: string = undefined) {
    if (token) {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token);
      return this.http.post(`${this.domain}/api/create-pnr`, data, { headers });
    } else {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return this.http.post(`${this.domain}/api/create-pnr`, data, { headers });
    }
  }
  createMultiTicketPnr(data, token: string = undefined) {
    if (token) {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token);
      return this.http.post(`${this.domain}/api/create-only-pnr-v4`, data, {
        headers,
      });
    } else {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return this.http.post(`${this.domain}/api/create-only-pnr-v4`, data, {
        headers,
      });
    }
  }

  createPnrV2(data) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/create-pnr-v2`, data, {
      headers,
    });
  }

  createPnrV4(data, token: string = undefined) {
    if (token) {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token);
      return this.http.post(`${this.domain}/api/create-pnr-v6`, data, {
        headers,
      });
    } else {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return this.http.post(`${this.domain}/api/create-pnr-v6`, data, {
        headers,
      });
    }
  }
  issueTicket(data, token: string = undefined) {
    if (token) {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token);
      return this.http.post(`${this.domain}/api/issue-ticket-with-pnr`, data, {
        headers,
      });
    } else {
      const headers = new HttpHeaders().set("Content-Type", "application/json");
      return this.http.post(`${this.domain}/api/issue-ticket-with-pnr`, data, {
        headers,
      });
    }
  }

  createHotelsPnr(data) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/hotels/create-pnr`, data, {
      headers,
    });
  }

  // logout
  logout(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(this.domain + "/api/logout", {}, { headers });
  }

  // show user
  showUser(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(this.domain + "/api/user", { headers });
  }

  // edit email
  editEmail(token, email) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    // return this.http.post(this.domain + '/api/user/email', { 'email': email }, { headers });
    return this.http.put(
      this.domain + "/api/user/email",
      { email },
      { headers }
    );
  }

  // email subscription
  subscribeByEmail(email, captchaKey) {
    return this.http.post(`${this.domain}/api/newsletter?email=${email}`, {
      "g-recaptcha-response": captchaKey,
    });
  }

  // edit profile
  editProfile(
    token,
    first_name,
    last_name,
    middle_name,
    country_id,
    gender,
    phone,
    date_of_birth,
    email,
    nationality,
    address
  ) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    // return this.http.post(this.domain + '/api/user', { 'first_name': first_name, 'last_name': last_name, 'country_code': country_code, 'gender': gender, 'phone': phone, 'date_of_birth': date_of_birth }, { headers });
    return this.http.post(
      this.domain + "/api/user",
      {
        first_name,
        last_name,
        middle_name,
        country_id,
        gender,
        phone,
        date_of_birth,
        email,
        nationality_id: nationality,
        address,
      },
      { headers }
    );
  }

  recommendedPlaces() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(this.domain + "/api/offers/category/1", { headers });
  }

  // change password
  changePassword(token, old_password, new_password, confirm_password) {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
    return this.http.post(
      this.domain + "/api/user/password",
      {
        old_password,
        new_password,
        confirm_new_password: confirm_password,
      },
      { headers }
    );
  }

  // upcoming Booking
  upcomingBooking(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(this.domain + "/api/orders/upcoming", { headers });
  }

  // completed Booking
  completedBooking(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(this.domain + "/api/orders/completed", { headers });
  }

  flightDetails(token, id) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(
      this.domain + "/api/orders/flight" + "?order_id=" + id,
      { headers }
    );
  }

  familyMembers(token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(this.domain + "/api/user/family-members", { headers });
  }

  deleteFamilyMember(id, token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(this.domain + "/api/user/family-members/" + id, "", {
      headers,
    });
  }

  familyRelations(token = null) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.get(this.domain + "/api/family-relations", { headers });
  }

  createFamilyMember(
    first,
    middle,
    last,
    country,
    nationality,
    phone,
    gender,
    relation,
    image,
    dob,
    email,
    token
  ) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user/family-members/create",
      {
        first_name: first,
        middle_name: middle,
        last_name: last,
        country_id: country,
        nationality_id: nationality,
        phone,
        gender,
        family_relation_id: relation,
        image,
        date_of_birth: dob,
        email,
      },
      { headers }
    );
  }

  addNewMember(token, data) {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.post(
      this.domain + "/api/user/family-members/create",
      data,
      { headers }
    );
  }

  editFamilyMember(data, id, token) {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.post(
      this.domain + "/api/user/family-members/update/" + id,
      data,
      { headers }
    );
  }

  familyMemberPassport(id, token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user/family-member-passport",
      id,
      { headers }
    );
  }

  createFamilyMemberPassport(
    id,
    passportNum,
    issueDate,
    expiryDate,
    country,
    nationality,
    issuedCountry,
    token
  ) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user/family-members-passport/create",
      {
        family_member_id: id,
        passport_number: passportNum,
        issue_date: issueDate,
        expire_date: expiryDate,
        country_id: country,
        nationality_id: nationality,
        issued_country_id: issuedCountry,
      },
      { headers }
    );
  }

  editFamilyMemberPassport(
    id,
    passportNum,
    issueDate,
    expiryDate,
    country,
    nationality,
    issuedCountry,
    passportId,
    token
  ) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user/family-members-passport/update/" + passportId,
      {
        family_member_id: id,
        passport_number: passportNum,
        issue_date: issueDate,
        expire_date: expiryDate,
        country_id: country,
        nationality_id: nationality,
        issued_country_id: issuedCountry,
      },
      { headers }
    );
  }
  editFamilyMemberPassportUpdate(passportId, passportData) {
    this.token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    if (passportId) {
      return this.http.post(
        this.domain + "/api/user/family-members-passport/update/" + passportId,
        passportData,
        { headers }
      );
    } else {
      return this.http.post(
        this.domain + "/api/user/family-members-passport/create",
        passportData,
        { headers }
      );
    }
  }

  deleteFamilyMemberPassport(id, token) {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user/family-members-passport/" + id,
      "",
      { headers }
    );
  }

  // sign in
  signIn(email, password) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/login",
      { email, password },
      { headers }
    );
  }

  // create account
  createAccount(
    email,
    password,
    confirm_password,
    first_name,
    last_name,
    country_code
  ) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      this.domain + "/api/signup",
      {
        email,
        password,
        confirm_password,
        first_name,
        last_name,
        country_code,
      },
      { headers }
    );
  }

  //  submitting user documents in profile page
  userDocuments(documentsObj, token) {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token);
    return this.http.post(`${this.domain}/api/user/identity`, documentsObj, {
      headers,
    });
  }

  // sending some billing info to check the validity of the credit card before proceeding to book the ticket
  createPaymentMethod(paymentMethodData) {
    return this.http.post(
      `${this.domain}/api/createPaymentMethod`,
      paymentMethodData
    );
  }

  fareRules(data) {
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/air-fare-rules`, data, {
      headers,
    });
  }

  getSeats(tagId) {
    return this.http.get(`${this.domain}/api/get-seats?tagId=` + tagId);
  }

  // getting insurance data
  getTravelProtection(insuranceData) {
    return this.http.post(
      `${this.domain}/api/travel-protection-quote`,
      insuranceData
    );
  }

  // getting visa faq
  getVisaFAQ(page) {
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http.get(`${this.domain}/api/visa-faq?page=` + page, {
      headers,
    });
  }

  checkVisa(data) {
    const headers = new HttpHeaders().set("Accept", "application/json");
    const param =
      "?nationality=" +
      data.nationality +
      "&residency=" +
      data.residency +
      "&destination=" +
      data.destination +
      "&purpose=" +
      data.purpose;
    return this.http.get(`${this.domain}/api/visa/check-visa-direct` + param, {
      headers,
    });
  }

  homeVisa(from, destination, purpose) {
    const headers = new HttpHeaders().set("Accept", "application/json");
    const param =
      "?from=" + from + "&destination=" + destination + "&purpose=" + purpose;
    return this.http.get(`${this.domain}/api/visa/check-visa-home` + param, {
      headers,
    });
  }

  localFilters(data) {
    const headers = new HttpHeaders().set("Accept", "application/json");
    const param = "?requestID=" + data.requestId;
    return this.http.get(`${this.domain}/api/visa/check-visa-home` + param, {
      headers,
    });
  }

  firstChatMessage() {
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/first-chat-message`, { headers });
  }

  getPopularDest() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations`,
      {},
      { headers }
    );
  }

  popularDestPagination(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations?page=${pageNumber}`,
      {},
      { headers }
    );
  }
  PaginationPopularDest(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations?page=${pageNumber}`,
      {},
      { headers }
    );
  }

  popularDestDetails(slug) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations-details`,
      { slug },
      { headers }
    );
  }

  relatedPopularDest(countryId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destinations-at-country`,
      { country_id: countryId },
      { headers }
    );
  }

  thingsToDoDetails(slug) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/things-to-do-details`,
      { slug },
      { headers }
    );
  }

  paginationThingsToDo(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/things-to-do?page=${pageNumber}`,
      {},
      { headers }
    );
  }

  getThingsToDo() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/things-to-do`, {}, { headers });
  }

  relatedThingsToDo(countryId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/things-to-do-at-country`,
      { country_id: countryId },
      { headers }
    );
  }

  getOffers() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/flight-offers`, {}, { headers });
  }

  offersDetails(slug) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/flight-offers-details`,
      { slug },
      { headers }
    );
  }

  getOffersPagination(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/flight-offers?page=${pageNumber}`,
      {},
      { headers }
    );
  }

  updateOffers(data) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/check-offer-price`,
      {
        offer_id: data.offer_id,
        request_id: data.request_id,
        iata: data.iata,
        new_price: data.new_price,
      },
      { headers }
    );
  }

  paginationOffers(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/flight-offers?page=${pageNumber}`,
      {},
      { headers }
    );
  }

  getblogs() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/blogs`, {}, { headers });
  }

  getBlogDetails(slug) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/blogs-details`,
      { slug },
      { headers }
    );
  }

  paginationblogs(pageNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/blogs?page=${pageNumber}`, {
      headers,
    });
  }

  thingsRviews() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/things-to-do-reviews`, {
      headers,
    });
  }

  AirportRviews() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/airport-reviews`, { headers });
  }

  addThingsRviews(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/user/add-things-to-do-review`,
      obj,
      { headers }
    );
  }

  getThingsReviews(id) {
    const token = this.localStorage.getItem("flyallover_token");
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/things-to-do-reviews?city_things_to_do_id=` + id,
      {},
      { headers }
    );
  }

  addAirportRviews(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/user/add-airport-review`, obj, {
      headers,
    });
  }

  getAirportReviews(airport_id) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/airport-reviews?airport_id=` + airport_id,
      {},
      { headers }
    );
  }

  addPopularDestinationReview(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/user/add-popular-destination-review`,
      obj,
      { headers }
    );
  }

  getPopularReview(id) {
    const token = this.localStorage.getItem("flyallover_token");
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/popular-destination-reviews?popular_destinations_id=` +
        id,
      {},
      { headers }
    );
  }

  FooterInfo() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/countries-with-cities`, {
      headers,
    });
  }

  getAirlineReviews(id) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/airline-reviews?airline_id=` + id,
      {},
      { headers }
    );
  }

  addAirlineReviews(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/user/add-airline-review`, obj, {
      headers,
    });
  }

  getBlogReviews(id) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/blog-reviews?blog_id=` + id,
      {},
      { headers }
    );
  }

  addBlogRviews(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/user/add-blog-review`, obj, {
      headers,
    });
  }

  getOfferReviews(id) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/flight-offer-reviews?flight_offer_id=` + id,
      {},
      { headers }
    );
  }

  addOfferRviews(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      `${this.domain}/api/user/add-flight-offer-review`,
      obj,
      { headers }
    );
  }

  hotelsData() {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer" + token)
      .set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/search-fao-hotel`, { headers });
  }
  AddHotelForm(obj) {
    const token = this.localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(`${this.domain}/api/create-fao-hotel`, obj, {
      headers,
    });
  }

  searchThings(name, id) {
    return this.http.post(`${this.domain}/api/search-things-to-do`, {
      search_text: name,
      country_id: id,
    });
  }
  searchPopularDestinations(id, name) {
    return this.http.post(`${this.domain}/api/popular-destinations`, {
      search_key: name,
      country_id: id,
    });
  }
  getFreeBags(tagId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.domain}/api/free-bags-by-tagid`, tagId);
  }
  getAirlineForSearch(requestId) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(
      `${this.domain}/api/get-all-airline-by-callsign`,
      requestId
    );
  }
  UserDashoardCreated() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/pnr-created",
      {},
      { headers }
    );
  }
  UserDashoardCanceld() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/pnr-canceld",
      {},
      { headers }
    );
  }

  UserDashoardPaymentFail() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/payment-fail",
      {},
      { headers }
    );
  }
  UserDashoardHighRiskScore() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/high-risk-score",
      {},
      { headers }
    );
  }
  UserDashoardError() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/pnr-error",
      {},
      { headers }
    );
  }
  UserDashoardManualReview() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/manual-review",
      {},
      { headers }
    );
  }
  UserDashoardIssueTticketError() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/issue-ticket-error",
      {},
      { headers }
    );
  }
  UserDashoardSuccess() {
    const token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/user-dashboard/success",
      {},
      { headers }
    );
  }
  plaidCreateToken() {
    this.token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/plaid-create-token",
      {},
      { headers }
    );
  }
  plaidIdentityVerification(orderId) {
    this.token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/plaid-identity-verification/" + orderId,
      {},
      { headers }
    );
  }
  getPlaidIdentityVerification(orderId) {
    this.token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/plaid-identity-verification-get",
      { identity_verification_id: orderId },
      { headers }
    );
  }
  changePayment(orderId, paymetKay) {
    this.token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/change-payment/" + orderId,
      { payment_status: paymetKay },
      { headers }
    );
  }
  plaidTransferChangeStatus(pladToken) {
    this.token = localStorage.getItem("flyallover_token");
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + this.token)
      .set("Accept", "application/json");
    return this.http.post(
      this.domain + "/api/plaid-transfer-change-status",
      { link_token: pladToken },
      { headers }
    );
  }

  getTrip(id?) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/trips?page=${id}`, { headers });
  }
  // getPageTrip(){
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.get(`${this.domain}/api/trips`, { headers });
  // }
  getTripsById(id) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/trips/` + id, { headers });
  }
  getTripsMetaById(id) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.domain}/api/trips-meta/` + id, { headers });
  }

  getTripContact(formData) {
    return this.http.post(`${this.domain}/api/trip-contact`, formData);
  }
  TripPayment(formData) {
    return this.http.post(`${this.domain}/api/trip-payment`, formData);
  }
  getVacationTitle(searchData) {
    return this.http.get(`${this.domain}/api/trips` + "?title=" + searchData);
  }
  getVacationPrice(searchData) {
    return this.http.get(`${this.domain}/api/trips` + "?price=" + searchData);
  }
}
