import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-home-image-engine',
  templateUrl: './home-videos-cities.component.html',
  styleUrls: ['./home-videos-cities.component.css']
})
export class HomeVideosCitiesComponent implements OnInit , AfterViewChecked  {
  @Input() autoPlay;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();
  showEngine: any = 0;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';
  shortOrigin: any = 'New York';
  shortDestination: any = 'Cairo';
  fullOrigin: any = 'JFK -  J.F. Kennedy, NYC, New York, US';
  fullDestination: any = 'CAI - Cairo, Cairo, EG';

  constructor(
    private viewportScroller: ViewportScroller,
  ) {
  }

  ngOnInit(): void {
  }
  ngAfterViewChecked() {
    // you could also do this after a service call of some sort
    this.finishedLoading.emit(true);
  }
  scrollToEngine() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
  prevent(e): void {
    e.preventDefault();
  }
  displayEngine(e) {
    this.showEngine = e;
    if (e === 1) {
      this.origin = 'JFK';
      this.destination = 'CAI';
      this.stops = '0';
      this.tripType = 'Return';
      this.shortOrigin = 'Cairo';
      this.shortDestination = 'New York';
      this.fullOrigin = 'CAI - Cairo, Cairo, EG';
      this.fullDestination = 'JFK -  J.F. Kennedy, NYC, New York, US';
    }
    if (e === 2) {
      this.destination = 'LHR';
      this.origin = 'JFK';
      this.destination = 'CAI';
      this.stops = '0';
      this.tripType = 'Return';
      this.shortOrigin = 'Cairo';
      this.shortDestination = 'London';
      this.fullOrigin = 'CAI - Cairo, Cairo, EG';
      this.fullDestination = 'LHR - London Heathrow, England, London, GB';
    }
    if (e === 3) {
      this.destination = 'IEV';
      this.origin = 'JFK';
      this.destination = 'CAI';
      this.stops = '0';
      this.tripType = 'Return';
      this.shortOrigin = 'Cairo';
      this.shortDestination = 'Zhulhany';
      this.fullOrigin = 'CAI - Cairo, Cairo, EG';
      this.fullDestination = 'IEV - Zhulhany Apt, Kiev, Kiev, UA';
    }
    if (e === 4) {
      this.destination = 'DPS';
      this.origin = 'JFK';
      this.destination = 'CAI';
      this.stops = '0';
      this.tripType = 'Return';
      this.shortOrigin = 'Cairo';
      this.shortDestination = 'Denpasar';
      this.fullOrigin = 'CAI - Cairo, Cairo, EG';
      this.fullDestination = 'DPS - Denpasar, Denpasar-Bali, ID';
    }
  }
}
