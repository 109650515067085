<app-navbar></app-navbar>
<div class="hotelSearch">
  <mat-tab-group class="mainTabGroup">
    <mat-tab class="hotel-tab-search">
      <ng-template mat-tab-label>
        <div class="mainTab hotelsTab">
          <img
            alt="hotel"
            title="hotel"
            src="../../../assets/images/icons/hotel2.png"
          />
          <span style="color: rgb(255, 255, 255)">Hotels</span>
        </div>
      </ng-template>
      <app-hotels-form></app-hotels-form>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="featured_hotels col-12">
  <h1>Featured hotels</h1>
  <h2>Cheap Flight Tickets</h2>
  <div class="row">
    <div class="col-12">
      <app-featured-hotels></app-featured-hotels>
    </div>
  </div>
</div>
<app-footer></app-footer>
