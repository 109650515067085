import {Component, OnInit, OnChanges} from '@angular/core';
import {HelpCenterServices} from '../../services/help-center.service'
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {SEOService} from "../../services/seo.service";
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.css']
})
export class HelpCenterComponent implements OnInit, OnChanges {

  accordion = '';
  oldAccordion = '';

  faqs = [];
  faqsExist: boolean = false;
  topics = [];
  topicsExist: boolean = false;
  searchResults = true;
  metaData = {
    title: "Support | Fllyallover",
    meta_data: {
      "og:title": "Support | Fllyallover",
      "twitter:title": "Support | Fllyallover",
      "title": "Support | Fllyallover",
    }
  }

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
  ) {
    this.seoUpdate();
  }

  ngOnChanges() {

  }

  ngOnInit(): void {
    this.apis.getFAQs().subscribe(
      (res: any) => {
        this.faqs = res.data;
        this.faqsExist = true;
      },
      (err) => {
        this.router.navigate(['/no-result'])
      }
    );

    this.apis.getTopics().subscribe(
      (res) => {
        this.topics = res['data'];
        this.topicsExist = true;

      },
      (err) => {
        this.router.navigate(['/no-result'])
      }
    );
  }

  seoUpdate() {

    this.seoService.updateTitle('Help Center | flyallover ');
    this.seoService.updateDescription('Help center on all Flyallover travel services. Answers to most Question on flights, hotels, cruises, vacations and travel insurance and all you need in Booking        ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  runAccordion(answer) {
    if (this.oldAccordion === answer) {
      this.accordion = '';
      this.oldAccordion = '';
    } else {
      this.accordion = answer;
      this.oldAccordion = answer;
    }
  }

  onSearch(value) {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: value
      }
    });
  }

  searchTopic(title, id) {
    this.router.navigate([`/topic/${title}/${id}`])
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
