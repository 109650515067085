import {Component, Inject, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {Router, NavigationExtras} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {DOCUMENT} from '@angular/common';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  beforeUpdateEmail: string;
  countries: any;
  token: string;
  email: string;
  password: string;
  SuccessfullyUpdated: string;

  open_change_password: boolean = false;
  old_password: string;
  new_password: string;
  confirm_password: string;
  succsess_change_password: string;
  error_change_password: string;

  editing: boolean = false;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  country_code: string;
  phone: string;
  succsess_edit_profile: string;
  error_edit_profile: string;

  constructor(
    private apis: ApisService,
    private router: Router,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');

    // countries name and code
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    }, err => {
    });
  }

  ngOnInit() {
    if (this.token) {
      this.apis.showUser(this.token).subscribe((data: any) => {
        this.email = data.data.email;
        this.beforeUpdateEmail = data.data.email;
        this.password = data.data.password;
        this.first_name = data.data.first_name
        this.last_name = data.data.last_name;
        this.country_code = data.data.country_code;
        this.gender = data.data.gender;
        this.phone = data.data.phone;
        this.date_of_birth = data.data.date_of_birth?.replace('00:00:00', '');

      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.router.navigate(['/sign-in']);
    }
  }

  editEmail() {
    if (this.email != this.beforeUpdateEmail) {
      if (this.token) {
        this.apis.editEmail(this.token, this.email).subscribe((data: any) => {
          if (data.status == true) {
            this.SuccessfullyUpdated = 'email ' + data.data.message;
            this.beforeUpdateEmail = data.data.user.email;
          }
        }, (err: any) => {
          this.SuccessfullyUpdated = '';
        });
      } else {
        this.router.navigate(['/sign-in']);
      }
    } else if (this.beforeUpdateEmail == this.email) {
      this.SuccessfullyUpdated = '';
    }
  }

  changePassword() {
    if (this.old_password && this.new_password && this.confirm_password) {
      if (this.new_password.length >= 6 && this.confirm_password.length >= 6) {
        this.apis.changePassword(this.token, this.old_password, this.new_password, this.confirm_password).subscribe((data: any) => {
          if (data.status == true) {
            this.succsess_change_password = 'Success change password!';
            this.error_change_password = '';
          }
        }, (err: any) => {
          this.error_change_password = err.error.error.error;
          this.succsess_change_password = '';
        });
      } else {
        this.error_change_password = 'Password should be 6 characters or more';
        this.succsess_change_password = '';
      }
    } else {
      this.error_change_password = 'All Fields are Required!';
      this.succsess_change_password = '';
    }
  }

  // editProfile() {
  //   this.date_of_birth = (this.document.getElementById('date_of_birth') as HTMLInputElement).value;
  //   if (this.first_name && this.last_name && this.country_code && this.gender && this.phone && this.date_of_birth) {
  //     this.apis.editProfile(this.token, this.first_name, this.last_name, this.country_code, this.gender, this.phone, this.date_of_birth).subscribe((data: any) => {
  //       if (data.status == true) {
  //         this.succsess_edit_profile = data.data.message;
  //         this.error_edit_profile = '';
  //         setTimeout(() => {
  //           this.succsess_edit_profile = '';
  //         }, 5000);
  //       }
  //     }, (err: any) => {
  //       this.error_edit_profile = err.error.error.error;
  //       this.succsess_edit_profile = '';
  //     });
  //   } else {
  //     this.error_edit_profile = 'All fields are required';
  //     this.succsess_change_password = '';
  //   }
  // }

  startEditing() {
    this.editing = true;
  }

}

