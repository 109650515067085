import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { ApisService } from 'src/app/services/apis.service';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";


@Component({
  selector: 'app-hotel-confirmation',
  templateUrl: './hotel-confirmation.component.html',
  styleUrls: ['./hotel-confirmation.component.css']
})
export class HotelConfirmationComponent implements OnInit {
  pnrResponse;
  loading: boolean = true;
  reservationNum: string;
  formsData = {};
  hotelSearchData;
  // when supportMessage is true this means that reservation failed (card is invalid)
  supportMessage: boolean = false;
  intent: string;
  invalidCard: boolean = false;
  // this is for checking if the host is server or browser
  isBrowser: boolean;

  constructor(
    private seoService: SEOService,
    private route: ActivatedRoute,
    private hotelApis: HotelsApisService,
    private apis: ApisService,
    private router: Router,
    private localStorage: LocalStorageService,
    private metaService: MetaTagsService,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.seoUpdate();
  }

  ngOnInit() {
    // this.windowService.nativeWindow.scrollTo(0, 0);
    this.viewportScroller.scrollToPosition([0, 0])
    this.intent = this.route.snapshot.queryParams.payment_intent;
    // checking tha state of the card
    if (this.intent != '0' && this.intent != '1') {
      this.supportMessage = false;
      this.invalidCard = false;
      this.hotelSearchData = JSON.parse(this.localStorage.getItem('hotelSearchData'));
      this.formsData = JSON.parse(this.localStorage.getItem('FormsData'))

      if (this.formsData) {
        this.formsData["payment_intent"] = this.intent;
        this.formsData["start_date"] = this.hotelSearchData?.starte_date;
        this.formsData["end_date"] = this.hotelSearchData?.end_date;

        this.callPnr();
      } else {
        if (this.isBrowser) {
          this.loading = false;
          this.intent = undefined
        }

      }
    } else if (this.intent == '1') {
      this.loading = false;
      this.supportMessage = true;
      this.invalidCard = false;
    } else if (this.intent == '0') {
      this.loading = false;
      this.supportMessage = true;
      this.invalidCard = true;
    }

  }

  seoUpdate() {

    this.seoService.updateTitle('Hotel Confirmation | FlyAllOver');
    this.seoService.updateDescription('Hotel Confirmation | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  callPnr() {
    this.apis.createHotelsPnr(this.formsData).subscribe((data: any) => {
      this.localStorage.removeItem('FormsData');
      if (data.data == 'booking Failed') {
        this.supportMessage = true;
      } else {
        this.pnrResponse = data.data;
        this.supportMessage = false;
      }
      this.loading = false;
    }, err => {
      this.localStorage.removeItem('FormsData');
      this.loading = false;
      this.supportMessage = true;
    })
  }
  modifyMetaTag() {
    this.metaService.modifyMetaTags({robots: 'noindex'});
  }
  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
