<div class="details-traveler">
  <div class="section-details">
    <div class="form-details">
      <div class="header-content">
        <img alt="Additional-Info" title="Members" src="./assets/images/dashboard/Additional-Info.svg">
        <div class="item-header">
          <h4>Passengers</h4>
          <p>When you book your trip you can Automatically select your co-traveller And book faster. </p>
        </div>
      </div>
      <hr class="hr">
      <div class="travellers">
        <div class="col-12">
          <ngx-skeleton-loader *ngIf="!familyMembers" [theme]="{height: '300px'}"></ngx-skeleton-loader>
        </div>
        <div *ngFor="let member of familyMembers; let i = index" class="members">
          <div class="col-12">
            <div class="row">
              <ng-container *ngIf="noPassport && noPassport[member.id]">
                <span>passport is required</span>
              </ng-container>
            </div>
          </div>
          <div class="col-12">
            <div class="row align-items-center">
              <ng-container *ngIf="noPassport && noPassport[member.id]">
                <span>passport is required</span>
              </ng-container>
              <div class="col-5 d-flex align-items-center">
                <span class="col-2">
                  <img *ngIf="member.image" alt="member-image" title="Members" style=" border-radius: 50%;"
                    src="{{member.image}}">
                  <img *ngIf="!member.image" alt="" style=" border-radius: 50%;"
                    src="../../../../assets/newIcon/user-profile.svg" />
                </span>
                <span class="info info-name col-10">{{member.first_name.toUpperCase() + ' ' +
                  member.middle_name.toUpperCase() + ' ' + member.last_name.toUpperCase()}}</span>
              </div>
              <div class="col-3">
                <div class="info relation-info">{{member.family_relation.name}}</div>
              </div>
              <div class="col-2">
                <span *ngIf="member.passport" class="info col-2"
                  style="font-size: 18px;">{{member.passport.passport_number}}</span>
                <span *ngIf="!member.passport" class="info col-2">----- -----</span>
              </div>
              <div class="col-2">
                <div class="row">
                  <button class="btn save-btn" *ngIf="member.passport" (click)="getPassportInfo(member.id, member.first_name.toUpperCase() + ' ' + member.middle_name.toUpperCase() + ' ' + member.last_name.toUpperCase(), member.family_relation.name );
                  passportInfo = !passportInfo">
                    <img alt="edit" style="height: 35px" src="./assets/images/dashboard/edit.svg" title="Edit image">
                  </button>
                  <button class="btn save-btn" *ngIf="!member.passport"
                    (click)="addPassport = !addPassport ; newPassport(member.id)">
                    <img style="height: 35px" alt="add" src="./assets/images/dashboard/add%20(2).svg" title="Add Image">
                  </button>
                  <!-- <button class="btn save-btn"
                          style="margin-right: 10px" (click)="info(member.id); showTraveler = !showTraveler">
                    <img alt="information" title="Imformation Image" style="height: 35px"
                         src="./assets/images/dashboard/information.svg">
                  </button> -->
                  <button *ngIf="!selectable" class="btn save-btn" (click)="openDialog(member.id)">
                    <img alt="Icon-material-delete-forever" title="Delete"
                      src="./assets/images/dashboard/Icon%20material-delete-forever.svg">
                  </button>
                  <ng-container *ngIf="selectable">
                    <mat-checkbox [disableRipple]="true" [disabled]="member.passport == null"
                      (change)="toggleCheck($event ,member)"></mat-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-add-member *ngIf="addTraveler"></app-add-member>
    <app-edit-member *ngIf="showTraveler" [familyMembers]="familyMembers" [id]="memberId" (closeForm)="closeMember()">
    </app-edit-member>
    <app-add-passport *ngIf="addPassport" [id]="memberId" (closeForm)="closeAddPassport()"
      (reloadFamilyMember)="this.familyMember2.emit();"></app-add-passport>
    <app-edit-passport *ngIf="passportInfo" [id]="memberId" [memberName]="memberName" [memberRelation]="memberRelation"
      [familyMembers]="familyMembers" (closeForm)="closePassport()" (reloadFamilyMember)="this.familyMember2.emit();">
    </app-edit-passport>
  </div>
  <div class="Add-traveller-btn">
    <a (click)="opedAddMemberDialog()">
      <img alt="Group" src="./assets/images/dashboard/Group%2015504.svg" title="Add Member">
      <span>ADD MEMBER</span>
    </a>
  </div>
</div>