<app-navbar></app-navbar>

<div class="hotels-page" id="hotelsPage">
  <div class="container">
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/hotel-results']">Search</a>
        </li>
        <li class="breadcrumb-item active">Reserve Your Room</li>
      </ol>
    </nav>
  </div>
  <div class="container-fluid">
    <app-hotels-engine [loadingSpinner]="loading"></app-hotels-engine>
    <div class="row">
      <!-- <div class="col-3">
        <div class="modify benefit row">
           <app-hotel-search
            [roundSpinner]="loading"
            (searchDataReady)="hotelSearchAPI($event)"
          >
          </app-hotel-search>

        </div>
      </div>-->
      <div class="col-lg-12 results" *ngIf="!resultsArrived && loading">
        <div class="hotel">
          <div class="hotel-header">
            <div class="row">
              <div class="col">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    height: '20px',
                    width: '40%',
                    'margin-right': '30px'
                  }"
                >
                </ngx-skeleton-loader>

                <ngx-skeleton-loader
                  class="skeleton-items"
                  appearance="circle"
                  count="5"
                  [theme]="{
                    height: '10px',
                    width: '10px',
                    'vertical-align': 'top'
                  }"
                >
                </ngx-skeleton-loader>
                <div>
                  <ngx-skeleton-loader
                    appearance="circle"
                    count="1"
                    [theme]="{ height: '10px', width: '10px' }"
                  >
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      height: '10px',
                      width: '20%',
                      'vertical-align': 'sub'
                    }"
                  >
                  </ngx-skeleton-loader>
                </div>
              </div>
              <div class="col col-icons text-right">
                <div
                  class="fb-share-button"
                  data-layout="button"
                  data-size="small"
                >
                  <ngx-skeleton-loader count="1" [theme]="{ height: '15px' }">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
          <div class="hotel-details">
            <div class="description">
              <div class="row">
                <div class="col">
                  <h3 class="mt-2">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ height: '20px', width: '20%' }"
                    >
                    </ngx-skeleton-loader>
                  </h3>
                  <p>
                    <ngx-skeleton-loader
                      count="2"
                      [theme]="{
                        height: '10px',
                        width: '70%',
                        'margin-bottom': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '40%',
                        'margin-bottom': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '15%',
                        'margin-bottom': '0px',
                        display: 'block'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </p>
                </div>
              </div>
            </div>
            <div class="property-facilities" style="margin-top: 10px">
              <h3>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '20px', width: '20%' }"
                >
                </ngx-skeleton-loader>
              </h3>
              <div *ngFor="let item of createRange(2)">
                <span
                  class="skeleton-items"
                  *ngFor="let item of createRange(3)"
                >
                  <ngx-skeleton-loader
                    appearance="circle"
                    count="1"
                    [theme]="{ height: '10px', width: '10px' }"
                  >
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      height: '10px',
                      width: '20%',
                      'margin-bottom': '0px',
                      'vertical-align': 'super',
                      'margin-right': '10%'
                    }"
                  >
                  </ngx-skeleton-loader>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="booknow">
          <div class="book">
            <h3>
              <ngx-skeleton-loader
                count="1"
                [theme]="{ height: '20px', width: '20%' }"
              >
              </ngx-skeleton-loader>
            </h3>
            <div class="result">
              <div class="row bg-white pt-3 pb-3 rounded">
                <div class="col col-carousel">
                  <ngx-skeleton-loader
                    count=" 1"
                    [theme]="{ height: '200px', width: '200px' }"
                  >
                  </ngx-skeleton-loader>
                </div>

                <div class="col col-description">
                  <div class="description">
                    <div>
                      <h1 class="title">
                        <ngx-skeleton-loader
                          count="1"
                          [theme]="{
                            height: '20px',
                            width: '55%',
                            'margin-right': '30px'
                          }"
                        >
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader
                          appearance="circle"
                          count="5"
                          [theme]="{
                            height: '10px',
                            width: '10px',
                            'vertical-align': 'top',
                            'margin-right': '0px'
                          }"
                        >
                        </ngx-skeleton-loader>
                      </h1>
                      <h2 class="zone">
                        <div class="d-block">
                          <ngx-skeleton-loader
                            appearance="circle"
                            count="1"
                            [theme]="{
                              height: '10px',
                              width: '10px',
                              'vertical-align': 'super',
                              'margin-right': '0px'
                            }"
                          >
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            count="1"
                            [theme]="{
                              height: '10px',
                              width: '30%',
                              'margin-left': '10px'
                            }"
                          >
                          </ngx-skeleton-loader>
                        </div>
                      </h2>

                      <h3 class="address">
                        <ngx-skeleton-loader
                          count="4"
                          [theme]="{
                            height: '10px',
                            width: '100%',
                            'margin-bottom': '0px'
                          }"
                        >
                        </ngx-skeleton-loader>
                      </h3>
                    </div>
                    <div>
                      <h5 class="tip">
                        <ngx-skeleton-loader
                          count="1"
                          [theme]="{
                            height: '10px',
                            width: '60%',
                            'margin-bottom': '0px',
                            display: 'block'
                          }"
                        >
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                          count="1"
                          [theme]="{
                            height: '10px',
                            width: '30%',
                            'margin-bottom': '0px'
                          }"
                        >
                        </ngx-skeleton-loader>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col col-amount">
                  <div class="amount">
                    <div class="address">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '10px', width: '60%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                    <div class="parking">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '10px', width: '100%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                    <div class="price text-center">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '30px', width: '80%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                    <div class="select">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '50px', width: '100%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rules">
          <h2></h2>
          <div class="rule">
            <h3>
              <ngx-skeleton-loader
              appearance="circle"
              count="1"
              [theme]="{ height: '10px', width: '10px' }"
            >
              </ngx-skeleton-loader>
            </h3>
            <div class="row" *ngFor="let item of createRange(5)">
              <div class="col">
                <ngx-skeleton-loader
                  appearance="circle"
                  count="1"
                  [theme]="{ height: '10px', width: '10px' }"
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    height: '10px',
                    width: '40%',
                    'vertical-align': 'sub'
                  }"
                >
                </ngx-skeleton-loader>
              </div>
              <div class="col">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '10px', width: '80%' }"
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '10px', width: '50%' }"
                >
                </ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 results" *ngIf="resultsArrived && !loading">
        <div class="hotel">
          <div class="hotel-header">
            <div class="row">
              <div class="d-flex col-8">
                <div style="width: 30%;">
                <h1 class="hotelName">
                  {{ hotelDetails.HotelName }}</h1>
                </div>
                <div style="bottom: 1vh;width: 100%;margin-top: 1.5vh;">
                  <span class="rate rate{{ rate }}"
                    ><i class="fas fa-star"></i><i class="fas fa-star"></i
                    ><i class="fas fa-star"></i><i class="fas fa-star"></i
                    ><i class="fas fa-star"></i></span>
                  </div>
                <!-- <p>
                  <a
                    href="http://maps.google.com/maps?q={{
                      hotelDetails.LocationInfo.Latitude
                    }},{{ hotelDetails.LocationInfo.Longitude }}"
                    target="_blank"
                  >
                    <i class="fas fa-map-marker-alt"></i>
                    {{ hotelDetails.Address.CityName }} City - Show Map
                  </a>
                </p> -->
              </div>
              <!-- <div class="col-4 col-icons text-right share">
                <span (click)="close_status = false"
                  ><i class="fas fa-share"></i> Share
                </span>
              </div> -->

                <div class="col-4  ">
                  <div class="sticky-content">
                    <div class="social-icons social-icons-colored social-icons-vertical" style="margin-left: 8.8rem;" >
                      <div class="icon-share" style="margin-top: 0; ">
                        <span class="social-label ">SHARE:</span>
                        <p class="social-icon image-item" (click)="cop()"
                           target="_blank">
                          <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                               src="./assets/newIcon/copy-blue-icon.svg"/>
                          <img *ngIf="copied" title="copied" class="copied" alt="copied"
                               src="./assets/newIcon/copied.png"/>
                        </p>
                        <!-- <p (click)="fb()" class="social-icon social-facebook" title="Facebook"><i
                          class="icon-facebook-f"></i></p>
                        <p (click)="tw()" class="social-icon social-twitter" title="Twitter"><i
                          class="icon-twitter"></i></p>
                        <p (click)="in()" class="social-icon social-linkedin" title="Linkedin"><i
                          class="icon-linkedin"></i></p> -->
                          <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                            <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                          <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                            <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                          <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                            <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
            <!-- <app-share-popup
              [linkedinTitle]="hotelDetails.Address.CityName"
              [close_status]="close_status"
              [viewSocialLinks]="true"
              [linkToBeShared]="linkToBeShared"
              (close)="close_status = true"
            >
            </app-share-popup> -->
          </div>

          <div *ngIf="hotelImages" class="hotel-photos">
            <div class="photos" gallerize>
              <div
                class="photo"
                (click)="openGallery($event, i)"
                *ngFor="let img of hotelImages; let i = index"
                [ngClass]="{ 'count': i == 7 }"
                title="+{{
                  hotelImages?.length > 8 ? hotelImages?.length - 8 : ''
                }}"
              >
                <img
                  title="Hotel Image"
                  alt="Hotel"
                  src="{{ img[0]?.Url }}"
                  width="60%"
                />
              </div>
            </div>
          </div>

          <div class="hotel-details">
            <div class="description">
              <div class="row">
                <div *ngIf="description" class="col-12">
                  <h3 class="descriptionTitle">Description</h3>
                  <p >
                    <span class="description-body" *ngFor="let sentence of description">
                      {{ sentence }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="facilities" class="property-facilities">
              <h3>Property Facilities</h3>
              <div class="row">
                <ng-container *ngFor="let facility of facilities">
                  <div *ngIf="facility !== ''" class="col-6">
                    <p class="facilityDescription">
                      <img
                        alt="flyallover"
                        title="flyallover"
                        src="./assets/images/hotels/tick.svg"
                      />
                      {{ facility.replace(" -", "") }}
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
         <div class="col-12">
          <app-google-map [longitude]="hotelDetails.LocationInfo.Longitude" [Latitude]="hotelDetails.LocationInfo.Latitude" id="map"></app-google-map>
         </div>
          <div class="booknow">
            <h2>Book Your Room</h2>
            <div class="book">
              <h3>Room Options</h3>
              <div
                *ngFor="
                  let room of hotelDetails.RoomsInfo | slice: 0:3;
                  let i = index
                "
                class="result"
              >
                <div class="row bg-white pt-3 pb-3 rounded">
                  <div class="col col-carousel">
                    <div *ngIf="hotelImages?.length > 1" class="item">
                      <img
                        alt="hotel image"
                        title="Room Options"
                        [src]="
                          hotelImages[i][0]?.Url ||
                          '../../../assets/images/hotel-img.png'
                        "
                        class="fluid-img w-100"
                      />
                    </div>
                    <div *ngIf="hotelImages?.length == 1" class="item">
                      <img
                        alt="hotel image"
                        title="Room Options"
                        [src]="
                          hotelImages[0][0]?.Url ||
                          '../../../assets/images/hotel-img.png'
                        "
                        class="fluid-img w-100"
                      />
                    </div>
                    <div *ngIf="!hotelImages" class="item">
                      <img
                        title="Room Options "
                        alt="hotel image"
                        src="../../../assets/images/hotel-img.png"
                        class="fluid-img w-100"
                      />
                    </div>
                  </div>

                  <div class="col col-description">
                    <div class="description">
                      <div>
                        <div class="title">
                          <h4>{{ room.Name }}</h4>
                        </div>

                        <div class="address">
                          <p>{{ room.Text }}</p>
                        </div>

                        <div class="parking">
                          <p *ngIf="room.Amenities">
                            <ng-container
                              *ngFor="
                                let amenity of room.Amenities.Amenity;
                                let isFirst = first;
                                let isLast = last
                              "
                            >
                              <i *ngIf="isFirst" class="fas fa-check mr-2"></i>

                              <span *ngIf="!isLast">
                                {{ amenity.Description }} -</span
                              >
                              <span *ngIf="isLast">
                                {{ amenity.Description }}</span
                              >
                            </ng-container>
                          </p>
                        </div>
                      </div>
                      <div class="tip">
                        <p
                          *ngIf="
                            room.RatePlans.RatePlan.CancelPenalties
                              .CancelPenalty[0]?.Refundable
                          "
                        >
                          <span> Risk Free </span>

                          <ng-container
                            *ngIf="
                              room.RatePlans.RatePlan.CancelPenalties
                                .CancelPenalty[0]?.Deadline
                            "
                          >
                            You can cancel

                            <ng-container
                              *ngIf="
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline.AbsoluteDeadline
                              "
                            >
                              before
                              {{
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline.AbsoluteDeadline
                                  | date: "MMM, d yyyy"
                              }}
                            </ng-container>

                            <ng-container
                              *ngIf="
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline.OffsetDropTime
                              "
                            >
                              {{
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline
                                  .OffsetUnitMultiplier
                              }}

                              {{
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline.OffsetTimeUnit
                              }}
                              <ng-container
                                *ngIf="
                                  room.RatePlans.RatePlan.CancelPenalties
                                    .CancelPenalty[0]?.Deadline
                                    .OffsetUnitMultiplier > 1
                                "
                              >
                                s
                              </ng-container>

                              {{
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Deadline.OffsetDropTime
                              }}
                            </ng-container>
                          </ng-container>
                        </p>

                        <p
                          *ngIf="
                            room.RatePlans.RatePlan.CancelPenalties
                              .CancelPenalty[0]?.PenaltyDescription
                          "
                          class="penalty"
                        >
                          {{
                            room.RatePlans.RatePlan.CancelPenalties
                              .CancelPenalty[0]?.PenaltyDescription.Text
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col col-amount">
                    <div class="amount"></div>

                    <div class="col-lg sidebar">
                      <div class="benefit" id="benefit">
                        <div class="items">
                          <div class="item departure_return">
                            <label class="small-title" for="date1">Date</label>
                            <div class="row">
                              <div class="col">
                                <div class="date">
                                  <input
                                    [(ngModel)]="
                                      searchData
                                        ? searchData.start_date
                                        : today
                                    "
                                    [matDatepicker]="checkIn"
                                    matInput
                                  />
                                  <mat-datepicker #checkIn></mat-datepicker>
                                </div>
                              </div>

                              <div class="col">
                                <div class="date">
                                  <input
                                    [(ngModel)]="
                                      searchData
                                        ? searchData.end_date
                                        : defaultDate
                                    "
                                    [matDatepicker]="checkOut"
                                  />
                                  <mat-datepicker #checkOut></mat-datepicker>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="item">
                            <label class="small-title" for="Guests"
                              >Guests</label
                            >
                            <input
                              type="text"
                              id="Guests"
                              [(ngModel)]="totalGuests"
                            />
                          </div>

                          <div class="address">
                            <p>
                              {{ pricesInfo[i]?.Nights }} nights,
                              {{ adults }} adults
                            </p>
                            <p *ngIf="pricesInfo[i]?.AdditionalFeesInclusive">
                              Additional charges may apply
                            </p>
                          </div>
                          <div class="parking">
                            <!-- <p>!! Breakfast included  </p> -->
                            <p
                              *ngIf="
                                room.RatePlans.RatePlan.CancelPenalties
                                  .CancelPenalty[0]?.Refundable
                              "
                            >
                              FREE cancellation
                            </p>
                            <!-- <p>!!No prepayment needed</p> -->
                            <ng-container
                              *ngIf="room?.AdditionalDetails?.AdditionalDetail"
                            >
                              <p
                                *ngFor="
                                  let text of room?.AdditionalDetails
                                    ?.AdditionalDetail?.Text
                                "
                              >
                                {{ text }}
                              </p>
                            </ng-container>
                          </div>

                          <div class="price text-center">
                            {{ pricesInfo[i]?.AmountAfterTax | currency }}
                          </div>

                          <div class="select">
                            <button (click)="selectRoom($event, i)">
                              SELECT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rules">
                <h2>Check Property Rules</h2>
                <div class="rule">
                  <h3>Hotel Rules</h3>
                  <div class="row">
                    <div class="col">
                      <img
                        alt="clock"
                        title="check In Time"
                        src="./assets/images/hotels/clock.svg"
                      />
                      Check In:
                    </div>
                    <div class="col">
                      From {{ checkInTime }} Local Time of the Hotel
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <img
                        alt="clock"
                        title="check Out Time"
                        src="./assets/images/hotels/clock.svg"
                      />
                      Check Out:
                    </div>
                    <div class="col">
                      From {{ checkOutTime }} Local Time of the Hotel
                    </div>
                  </div>
                  <div *ngIf="services" class="row">
                    <div class="col">
                      <img
                        alt="Socks"
                        title="Services Image
"
                        src="./assets/images/hotels/socks.svg"
                      />
                      Services:
                    </div>
                    <div class="col">
                      <ng-container
                        *ngFor="let sentence of services; let isLast = last"
                      >
                        <span
                          *ngIf="!isLast && sentence"
                          [innerHTML]="sentence.replace('-', '') + ' - '"
                        >
                        </span>
                        <span
                          *ngIf="isLast && sentence"
                          [innerHTML]="sentence"
                        ></span>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="petsState" class="row">
                    <div class="col">
                      <img
                        alt="dog"
                        title="Pets State"
                        src="./assets/images/hotels/dog.svg"
                      />Pets:
                    </div>
                    <div class="col">
                      {{ petsState }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <img
                        alt="credit-card"
                        title="Accepted Cards"
                        src="./assets/images/hotels/credit-card.svg"
                      />
                      Accepted Cards:
                    </div>
                    <div class="col">
                      <ng-container
                        *ngFor="
                          let card of hotelDetails.RoomsInfo[0]?.RatePlans
                            .RatePlan.Guarantee.GuaranteesAccepted
                            .GuaranteeAccepted.PaymentCard.PaymentCards
                        "
                      >
                        <img
                          alt="{{ card.cardCode }}"
                          title="{{ card.value }}"
                          src="./assets/images/hotels/{{ card.CardCode }}.png"
                        />
                      </ng-container>
                    </div>
                  </div>
                  <div class="row" *ngIf="cancellationPolicy">
                    <div class="col">
                      <img
                        alt="clock"
                        title="Cancelation"
                        src="./assets/images/hotels/clock.svg"
                      />
                      Cancelation :
                    </div>
                    <div class="col">
                      <ng-container *ngFor="let sentence of cancellationPolicy">
                        {{ sentence }}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
