<div class="ticket-body-view">
  <div class="d-flex">
  <div class="p-0">
    <img src="../../../../assets/images/arrow/logo_sign_shadow.png" alt="logo" width="25px"
      class="logo-ticket-pnr">
  </div>

    <div class=" ticket-header-view ">

      <p class="Font-Family fontHandel "><span class="mr-1"
          *ngIf="item?.departure_date">{{item?.departure_date| date:"dd MMM yyy"}}</span> <img
          *ngIf="item?.return_date" src="assets/images/arrow/Polygon.png" class="mx-2"> <span class="mx-1"
          *ngIf="item?.return_date">{{item?.return_date| date:"dd MMM yyy"}}</span>
      </p>
      <p class=" Font-Family trip-part " *ngIf="item?.origin_city_object"><small
          class=" font-ticket-lv-3 col-4 p-0">TRIP FROM</small> <span
          class="text2Handel col-8 p-0">{{item?.origin_city_object.city_name}}
          , {{item?.origin_city_object.country_code}} </span>
      </p>
      <p class=" Font-Family trip-part" *ngIf="item?.destination_city_object"><small
          class=" font-ticket-lv-3 col-4 p-0">TRIP TO</small> <span
          class="text2Handel col-8 p-0">{{item?.destination_city_object.city_name}}
          , {{item?.destination_city_object.country_code}}</span>
      </p>
      <div class="hr-part"></div>
      <div class="">
        <p class="font-ticket-lv-3 Font-Family"><small> RESERVATION CODE</small></p>
        <span class="font-ticket-lv-1 Font-Family-bold" *ngIf="item?.pnr">{{item?.pnr}}</span>
        <div class="">
          <p class="font-ticket-lv-3 " *ngIf="item?.trip_class"> Cabin <small
              class="text2Handel">{{item?.trip_class}} </small></p>
          <p class="font-ticket-lv-3  d-none" *ngIf="item?.status"> Status<small class="text2Handel">{{
              item?.status}} </small></p>

        </div>
        <div class="min-part">

          <p class=" Font-Family trip-part" *ngIf="item?.id"><small
            class=" font-ticket-lv-3 col-4 p-0 pe-2">FLIGHT NO</small> <span
            class="text2Handel  p-0">{{item?.id}} </span>
          </p> <p class=" Font-Family trip-part" *ngIf="item?.fare_class"><small
            class=" font-ticket-lv-3 col-4 p-0 pe-2">CLASS</small> <span
            class="text2Handel  p-0">{{item?.fare_class}} </span>
          </p>
        </div>
        <div class="min-part">

          <p class=" Font-Family trip-part" *ngIf="item?.trip_type"><small
            class=" font-ticket-lv-3 col-4 p-0 pe-2">TRIP TYPE </small> <span
            class="text2Handel  p-0">{{item?.trip_type}}</span>
          </p> <p class=" Font-Family trip-part" *ngIf="item?.departure_date"><small
            class=" font-ticket-lv-3 col-4 p-0 pe-2">TIME</small> <span
            class="text2Handel  p-0">{{item?.departure_date|date :'HH:mm a'}} </span>
          </p>
        </div>
        <div class="hr-part"></div>

      </div>
    </div>
  </div>
      <div class="up-title">PREPARED FOR</div>
      <div class="down-title pb-0">

        <span *ngIf="item.user?.gender=='M'">MR</span>
        <span *ngIf="item.user?.gender=='F'">MRS</span></div>
        {{handelName(item.user?.last_name) + ' / ' + handelName(item.user?.first_name) + ' ' + handelName(item.user?.middle_name) + ' '}}

</div>
