<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center">Sign up</h1>
    </div>
  </div>

  <div class="help-center-body">
    <!-- start contact-us -->
    <div class="container">
      <div class="contact-us">
        <div class="inside">
          <h6 class="text-center">Welcome to FlyAllOver</h6>
          <p class="text-center">
            Sign up now to get our all the updates and be the first one to know
            hear from us.
          </p>
          <div class="field">
            <div class="row">
              <div class="col">
                <label for="firstName">*first Name</label>
                <input id="firstName" type="text" />
              </div>
              <div class="col">
                <label for="lastName">*last Name</label>
                <input id="lastName" type="text" />
              </div>
            </div>
          </div>
          <div class="field">
            <label for="email">*Email Address</label>
            <input id="email" type="email" />
          </div>
          <div class="field">
            <label for="password">*Create Password</label>
            <input id="password" type="password" />
          </div>
          <div class="field">
            <div>
              <input type="checkbox" id="checkbox1" checked />
              <label for="checkbox1">I agree on all terms and conditions</label>
            </div>
            <div>
              <input type="checkbox" id="checkbox2" checked />
              <label for="checkbox2">I accept to send me newsletter</label>
            </div>
          </div>
          <div class="submit">
            <button>SIGN UP</button>
          </div>
          <div class="field text-center">
            <h4><span>Or sign up with</span></h4>
            <div class="img">
              <span class="facebock"><i class="fab fa-facebook-f"></i></span>
              <span class="google"
                ><img
                  title="Sign Up  With"
                  alt="google"
                  src="./assets/images/help-center/google-logo.png"
                  width="20"
              /></span>
            </div>
            <div>
              <label>Already has an account? <a href="#">Login</a></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
