<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center">Login</h1>
        </div>
    </div>

    <div class="help-center-body">
        <!-- start contact-us -->
        <div class="container">
            <div class="contact-us">
                <div class="inside">
                    <h4 class="text-center">Welcome back to FlyAllOver</h4>
                    <div class="field">
                        <label for="email">*Email Address</label>
                        <input id="email" type="email" />
                    </div>
                    <div class="field">
                        <label for="password">*Password</label>
                        <input id="password" type="password" />
                    </div>
                    <div class="field">
                        <div>
                            <input type="checkbox" id="checkbox1" checked>
                            <label for="checkbox1">Remember me</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkbox2" checked>
                            <label for="checkbox2">I accept to send me newsletter</label>
                        </div>
                    </div>
                    <div class="submit">
                        <button>lOGIN</button>
                    </div>
                    <div class="field text-center">
                        <h6><span>Or Login with</span></h6>
                        <div class="img">
                            <span class="facebock"><i class="fab fa-facebook-f"></i></span>
                            <span class="google"><img title="Login With" alt="google-logo"
                                                      src="./assets/images/help-center/google-logo.png" width="20" /></span>
                        </div>
                        <div>
                            <label>Don't have an account? <a href="#">Sign up</a></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end contact-us -->
    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
