<app-navbar></app-navbar>

<div *ngIf="!dataShare">
  <div class=" all-card col-12 p-0">
    <div class="vt-placeholder-loading " *ngFor="let Loading of loadingsShare" [style]="{height: Loading.width}">
      <div class="vt-animated-background-loading" [style]="{height: Loading.width}"></div>
    </div>
  </div>

</div>

<div class="container" *ngIf="dataShare">
  <div class="row mt-5">
    <h2 class="fao_mobile_title_large mb-4 font-weight-bold Font-Family-bold">Get the Best Flight Deals</h2>
    <div class="col-7">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper m-auto">
            <img src="{{dataShare.origin.main_image}}" width="100%" height="400" style="object-fit: cover;"
              class="rounded" alt="Random second slide" alt="{{ dataShare.origin.city_name }}"
              title="{{ dataShare.origin.city_name }}" />

          </div>
          <div class="carousel-caption"></div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img src="{{dataShare.destination.main_image}}" width="100%" height="400" style="object-fit: cover;"
              class="rounded" alt="Random second slide" alt="{{ dataShare.destination.city_name }}"
              title="{{ dataShare.destination.city_name }}" />
          </div>
          <div class="carousel-caption"></div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="col-5">
      
      <div class="col-12">
        <p class="font_p_1 Font-Family mt-3"> <span class="font-weight-bold ">From </span> <span class="color_Sp"
            *ngIf="flightDataShare.origin"> {{origin}}</span> <span class="font-weight-bold ml-1"> To </span> <span
            class="color_Sp mx-2"> {{destination}}</span>   </p>
            <p style="font-size: 25px; color: #161D4C;"><span class="font-weight-bold" >with Fly All Over</span></p>
        <p class="font_p_1 Font-Family" *ngIf="flightDataShare.trip_type"> Book your <strong>
            {{trip_type_Sh}}</strong> from <span *ngIf="flightDataShare.destination"> <strong>
              {{flightDataShare.origin}} </strong> </span>to <span *ngIf="flightDataShare.destination"> <strong>
              {{flightDataShare.destination}}</strong></span></p>
        <p class="font-span_1 Font-Family " *ngIf="totalPrice">For only <span class="font-Price">$ {{totalPrice}}
          </span></p>
          <button class="fao_mobile_btn_small mt-3"(click)="callFlightAPi()" >More Details</button>
      </div>
    </div>

  </div>
</div>

<div class="my-5">
</div>
<div class="container" *ngIf="data">
  <div class="row my-5">
    <app-itinerary  [loadCount]="1" [toggleDetails]="false" [reviewFlight]="true" [skeletons]="1" [origin]="data.origin"
      [destination]="data.destination" [flights]="flights" [loading]="loading" [shareValue]="1"
      [itineraries]="itineraries" [page]="null" [review]="true">
    </app-itinerary>
    <div class="my-1">
      <!-- <p class="fao_mobile_prgraph " style="color:#c91b1b !important; font-size: 21px !important;">Fare prices above
        were last found On <span>10/24/2023 At 6:02 PM</span> Travel Dates Range <span>04/08/2024 To 05/07/2024</span>
        Fares are round trip and include all fuel surcharges - Tickets are limited</p> -->
    </div>
    <div class="row w-100 px-2 my-5">
      <div class="col-6 px-2">
          <div class="item follow Font-Family">
            
            <ul class=" p-4 my-2 font-icon">
              <li><h6 class="font-span_1 ">Share Now : </h6></li>
              <li>
                <a class="social-icon image-item" (click)="cop()" target="_blank">
                  <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                    src="./assets/newIcon/copy-blue-icon.svg" />
                  <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                    src="./assets/newIcon/copied.png" />
                </a>          
              </li>
              <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                    class="fab fa-facebook-f"></i></a></li>
              <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                    class="fab fa-twitter"></i></a></li>
              <!-- <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><i
                    class="fab fa-youtube"></i></a></li>
              <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><i
                    class="fab fa-instagram"></i></a></li> -->
              <li><a (click)="linked()" target="_blank" rel="nofollow"
                  href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>
      </div>
      <div class="col-6    text-end mt-3 ">
        <!-- <span (click)="openTransfer(ShareIcon)" class="mb-2"><img src="../../../assets/images/arrow/share.png"
            width="50px"> </span> -->
        <button class="fao_mobile_btn_small mt-3" (click)="SelectFlightDetails()"> Continue</button>
      </div>

    </div>
  </div>

</div>





<ng-container>
  <ng-template ng-template #ShareIcon let-modal>
    <div class="container">
      <div class="row share_box">
        <div class="col-12 my-2">
          <div class="row">
            <span class="custom_close" (click)="closeModal()"><img class="mt-2"
                src="../../../assets/images/arrow/close.png" width="20px" style=""></span>
          </div>
          <div class="row px-2">
            <div class="col container-follow">
              <div class="item follow Font-Family">
                <h6 class="font-span_1 mt-2">Share Now</h6>
                <ul class=" p-4 my-2 font-icon">
                  <li>
                    <a class="social-icon image-item" (click)="cop()" target="_blank">
                      <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                        src="./assets/newIcon/copy-blue-icon.svg" />
                      <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                        src="./assets/newIcon/copied.png" />
                    </a>
                  </li>
                  <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                        class="fab fa-facebook-f"></i></a></li>
                  <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                        class="fab fa-twitter"></i></a></li>
                  <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><i
                        class="fab fa-youtube"></i></a></li>
                  <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><i
                        class="fab fa-instagram"></i></a></li>
                  <li><a (click)="linked()" target="_blank" rel="nofollow"
                      href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </ng-template>
</ng-container>

<app-footer></app-footer>
