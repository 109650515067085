import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.css']
})
export class SharePopupComponent implements OnInit {

  @Input() close_status: boolean;
  @Input() viewSocialLinks: boolean;
  @Input() linkToBeShared: string;
  @Output() close = new EventEmitter();
  @Input() linkedinTitle: string;

  copy_status: boolean = false;
  url: string;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.url = this.router.url;
  }

  copyMessage(val: string, input) {
    const selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    input.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);
    this.copy_status = true;
    setTimeout(() => { this.copy_status = false; }, 1000);
  }
}
