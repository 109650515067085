import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { DatePipe } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-new-hotels-details',
  templateUrl: './new-hotels-details.component.html',
  styleUrls: ['./new-hotels-details.component.scss'],

})
export class NewHotelsDetailsComponent implements OnInit {


  clipboard: any;
  hotelCode: any;
  searchData: any;
  copied: boolean;
  lat = 30.0676;
  long = 31.22236;
  rateStart = 10 * 10 / 2;
  hotelDetails: any;
  carouselOptions: OwlOptions = {
    navText: ["<i class='arrow arrowNext fa fa-chevron-left'></i>", "<i class='arrow arrowPrev fa fa-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 }
    },
    items: 2,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true,
    autoplayHoverPause: true,
    smartSpeed: 2500,
    pullDrag: true,

  };
  hotelsImages: any;
  imageGategory: any;
  Facilities: any;
  services: any;
  attractions: any;
  HotelCancellationPolicy: any;
  Policies: any;
  SafetyInfo: any;
  TransportationInfo: any;
  currentPage = 1;
  itemSilderImages: number = 6;
  imagesSilder: any;
  categoryActicert = 'all';
  placholderLodaing = [
    { width: '150px' },
    { width: '250px' },
    { width: '100px' },
    { width: '75px' },
    { width: '100px' },
    { width: '100px' },
    { width: '120px' },
    { width: '150px' },
    { width: '100px' },
  ]
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private hotelApis: HotelsApisService,
    private datePipe: DatePipe,
  ) {
    this.hotelCode = this.activatedRoute.snapshot.paramMap.get('HotelCode')
  }

  ngOnInit(): void {
    this.getHotelDetails()

  }
  getHotelDetails() {
    var hotelQuery = {
      adults: Number(this.searchData?.adults) || 1,
      children: Number(this.searchData?.children) || 0,
      start_date: this.searchData?.start_date || '2024-07-05',
      end_date: this.searchData?.end_date || '2024-07-08',
      hotel_code: this.hotelCode,
    };
    this.hotelApis.hotelDetails(hotelQuery).subscribe({
      next: (response: any) => {
        this.hotelDetails = response.data;
        this.long = this.hotelDetails.LocationInfo?.Longitude
        this.lat = this.hotelDetails.LocationInfo?.Latitude

        this.getImages('all')
        const fac = ['Facilities'];
        // this.Facilities = fac.concat(response.data.basis_info.Descriptions.Description[1].value);
        // this.services = response.data.basis_info.Descriptions.Description[2].value;
        // this.attractions = response.data.basis_info.Descriptions.Description[3].value;
        // this.HotelCancellationPolicy = response.data.basis_info.Descriptions.Description[4].value;
        // this.Policies = response.data.basis_info.Descriptions.Description[5].value;
        // this.SafetyInfo = response.data.basis_info.Descriptions.Description[6].value;
        // this.TransportationInfo = response.data.basis_info.Descriptions.Description[7].value;

      }, error: err => {
        console.error('error get hotel details=> ', err)
      }
    })

  }
  getCategories() {
    const categories = [];
    let currentCategory = null;

    this.Facilities.forEach(item => {
      if (item.startsWith('-')) {
        if (currentCategory) {
          currentCategory.items.push(item);
        }
      } else {
        currentCategory = { title: item, items: [] };
        categories.push(currentCategory);
      }
    });

    return categories;
  }
  getServices() {
    const Services = [];
    let currentServices = null;

    this.services.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }
  getAttractions() {
    const Services = [];
    let currentServices = null;

    this.attractions.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }

  getCancellationPolicy() {
    const Services = [];
    let currentServices = null;

    this.HotelCancellationPolicy.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }
  getPolicies() {
    const Services = [];
    let currentServices = null;

    this.Policies.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }

  getSafetyInfo() {
    const Services = [];
    let currentServices = null;

    this.SafetyInfo.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }
  getTransportationInfo() {
    const Services = [];
    let currentServices = null;

    this.TransportationInfo.forEach(item => {
      if (item.startsWith('-')) {
        if (currentServices) {
          currentServices.items.push(item);
        }
      } else {
        currentServices = { title: item, items: [] };
        Services.push(currentServices);
      }
    });

    return Services;
  }



  // getItemsWithHyphen() {
  //   return this.Facilities.filter(item => item.startsWith('-'));

  // }

  // getItemsWithoutHyphen() {
  //   var cat = ['Facilities'];
  //   const total =  cat.concat(this.Facilities.filter(item => item && !item.startsWith('-')));
  //   console.log(total);
  //   return total ;
  //   //  this.Facilities.filter(item => item && !item.startsWith('-'));


  // }
  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied = !this.copied
    setTimeout(() => {
      this.copied = false;
    }, 1500)
  }

  fb() {
    let url = 'www.google.com';
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + 'https://www.flyallover.com' + this.router.url,
      'facebook-popUp',
      'width=600px,height=350px,'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  linked() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  transform(value: string): string {
    // Ensure the input is a string and has exactly 4 characters
    if (!value || value.length !== 4) {
      return value;
    }

    // Extract hours and minutes
    let hours = parseInt(value.substring(0, 2), 10);
    const minutes = value.substring(2);

    // Determine AM/PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Format the hours to have a leading zero if necessary
    const formattedHours = ('0' + hours).slice(-2);

    return `${formattedHours}:${minutes} ${ampm}`;
  }

  getImages(category: any) {
    this.categoryActicert = category
    if (this.categoryActicert != 'all') {
      this.hotelsImages = this.hotelDetails.HotelMediaInfo[`${category}`]
      this.getSildersImages()
    } else {
      this.hotelsImages = this.hotelDetails.AllHotelMediaInfo
      this.getSildersImages()
    }
    this.currentPage = 1
  }
  getSildersImages() {
    var imagesLenght = this.hotelsImages.length
    const startItem = (this.currentPage - 1) * this.itemSilderImages
    const endItem = Math.min(startItem + this.itemSilderImages, imagesLenght)
    this.imagesSilder = this.hotelsImages.slice(startItem, endItem)
  }
  pageChanged(event: any) {
    this.currentPage = +event
    this.getSildersImages()
  }
  checkService(service, text) {
    const texts = service.toString().toLowerCase()
    const Charge = texts
      ?.split('charge')[1].split(',')
    const noCharge = texts
      ?.split('no charge')[1].toString().split('charge')[0].split(',')
    const services = texts
      ?.split('no charge')[0].split(',')
    console.log(' const Charge = texts', texts);

    switch (text) {
      case 'Charge':
        return Charge
      case 'noCharge':
        return noCharge
      case 'services':
        return services
      default:
        console.log('noCharge', noCharge);
        console.log('Charge', Charge);
    }

  }

  getRooms(numBads) {

  }
  changeRoomName(romeName) {
    // if()
    const newName = romeName.toString().replace(' - Booking.com rate', '');
    const roomArrayName = newName.split('- ')
    return roomArrayName;
  }
}
