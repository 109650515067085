<div class="post-container">
  <a [routerLink]="['/offers', offer.slug ]" class="post">
    <div class="post-photo">
      <img title="{{offer?.image_alt || 'flyallover'}}" [src]="offer?.image" alt="{{offer?.image_alt || 'flyallover'}}" class="img-fluid">
    </div>
    <div class="post-description">
      <div class="offer_name">
        <h4 title="{{offer.offer_name}}">
          {{offer?.offer_name.slice(0, 40)}}
          <span *ngIf="offer?.offer_name?.length > 50">...</span>
        </h4>
      </div>
      <div class="offer_description">
        <p class=" d-sm-block">
          {{offer?.short_description?.slice(0, 120)}}
          <a *ngIf="offer?.short_description?.length  > 120" [routerLink]="['/offers', offer.slug ]">Read More ...</a>
        </p>
      </div>
      <label><i class="far fa-clock"></i> From {{offer.start_date | date:'d MMM'}} to
        {{offer.expire_date | date:'d MMM'}}</label>
    </div>
  </a>
</div>
