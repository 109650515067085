<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start page body -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family ">Sign up</h1>

        </div>
    </div>
    <div class="help-center-body">
        <!-- start contact-us -->
        <div class="container">
            <div class="contact-us">
                <div class="logo text-center">
                    <img title="Sign Up" alt="Sign Up"  src="/assets/images/home/coparess/logo-sign.png">
                </div>
                <form [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUpForm(signUpForm.value)">
                    <div class="inside">
                        <h2 class="text-center Font-Family">Welcome to FlyAllOver</h2>
                        <p class="text-center Font-Family">What are you waiting for! join our family to able to have our amazing offers </p>
                        <div class="field" *ngIf="backendError">
                            <div class="text-danger text-center">{{backednErrorMsg}}</div>
                        </div>
                        <!-- First & Last name -->
                        <div class="field row">
                            <div class="col-6 input">
                                <label for="firstName"
                                class="Font-Family"
                                    [ngClass]="signUpForm.get('firstName').invalid && signUpForm.get('firstName').touched ?'text-danger':''">First
                                    Name</label>
                                <input id="firstName" type="text" formControlName="firstName" required maxlength="255"
                                    [ngClass]="signUpForm.get('firstName').invalid && signUpForm.get('firstName').touched ? 'has-error':'no-error'" />
                                <span *ngFor=" let validation of validationMessages.firstName">
                                    <small class="text-danger"
                                        *ngIf="signUpForm.get('firstName').hasError(validation.type) && (signUpForm.get('firstName').dirty || signUpForm.get('firstName').touched)">
                                        {{validation.message}}</small>
                                </span>
                            </div>
                            <div class="col-6 input">
                                <label for="lastName"
                                class="Font-Family"
                                    [ngClass]="signUpForm.get('lastName').invalid && signUpForm.get('lastName').touched ?'text-danger':''">Last
                                    Name</label>
                                <input id="LastName" required type="text" formControlName="lastName" maxlength="255"
                                    [ngClass]="signUpForm.get('lastName').invalid && signUpForm.get('lastName').touched ? 'has-error':'no-error'"
                                    class="no-error" />
                                <span *ngFor=" let validation of validationMessages.LastName">
                                    <small class="text-danger"
                                        *ngIf="signUpForm.get('lastName').hasError(validation.type) && (signUpForm.get('lastName').dirty || signUpForm.get('lastName').touched)">
                                        {{validation.message}}</small>
                                </span>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="field">
                            <label for="email"
                            class="Font-Family"
                                [ngClass]="signUpForm.get('email').invalid && signUpForm.get('email').touched ?'text-danger':''">Email
                                Address</label>
                            <input id="email" type="email" formControlName="email" required
                                [ngClass]="signUpForm.get('email').invalid && signUpForm.get('email').touched ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.email">
                                <small class="text-danger"
                                    *ngIf="signUpForm.get('email').hasError(validation.type) && (signUpForm.get('email').dirty || signUpForm.get('email').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- Phone -->
                        <div class="field">
                            <label for="phone"
                            class="Font-Family"
                                [ngClass]="signUpForm.get('phone').invalid && signUpForm.get('phone').touched ?'text-danger':''">Phone number</label>
                            <input id="phone" type="text" formControlName="phone" required
                                [ngClass]="signUpForm.get('phone').invalid && signUpForm.get('phone').touched ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.phone">
                                <small class="text-danger"
                                    *ngIf="signUpForm.get('phone').hasError(validation.type) && (signUpForm.get('phone').dirty || signUpForm.get('phone').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- Password -->
                        <div class="field">
                            <label for="password"
                            class="Font-Family"
                                [ngClass]="signUpForm.get('password').invalid && signUpForm.get('password').touched || passwordMissMatch ?'text-danger':''">Create
                                Password</label>
                            <input (keyup)="resetError()" id="password" type="password" required
                                formControlName="password" minlength="6"
                                [ngClass]="signUpForm.get('password').invalid && signUpForm.get('password').touched || passwordMissMatch ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.password">
                                <small class="text-danger"
                                    *ngIf="signUpForm.get('password').hasError(validation.type) && (signUpForm.get('password').dirty || signUpForm.get('password').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- confirm password -->
                        <div class="field">
                            <label for="mobile"
                            class="Font-Family"
                                [ngClass]="signUpForm.get('confirmPassword').invalid && signUpForm.get('confirmPassword').touched || passwordMissMatch ?'text-danger':''">Confirm
                                Password</label>
                            <input (keyup)="resetError()" type="password" required formControlName="confirmPassword"
                                minlength="6"
                                [ngClass]="signUpForm.get('confirmPassword').invalid && signUpForm.get('confirmPassword').touched || passwordMissMatch ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.confirmPassword">
                                <small class="text-danger"
                                    *ngIf="signUpForm.get('confirmPassword').hasError(validation.type) && (signUpForm.get('confirmPassword').dirty || signUpForm.get('confirmPassword').touched)">
                                    {{validation.message}}</small>
                            </span>
                            <small class="text-danger Font-Family" *ngIf="passwordMissMatch">Password and confirm password must match</small>

                        </div>
                        <div class="box-accept">

                            <div class="field row checkBox">
                                <div class="checkbox-field d-flex align-items-center">
                                  <input type="checkbox" id="checkbox1" style="margin-right: 10px"
                                        formControlName="checkbox1" required>
                                  <label class="Font-Family" style="margin-bottom: 0" for="checkbox1">I agree on all <span style="text-decoration: underline; color: blue;" [routerLink]="['/terms']"> terms and conditions </span> </label>
                                </div>
                            </div>
                        </div>
                        <div class="recaptcha">
                            <re-captcha required (resolved)="resolved($event)"  [siteKey]="siteKey">
                            </re-captcha>
                        </div>
                        <div class="submit">
                            <button [ngClass]="signUpForm.valid && captchaKey? 'button active':'button disabled'"
                                [disabled]="!signUpForm.valid && !captchaKey" type="submit">SIGN UP
                                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i></button>
                        </div>
                        <span class="Font-Family ">Already has an account? </span>
                        <div class="submit">
                        <button class="btn btn-login" [routerLink]="['/sign-in']"> Login
<!--                          <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>-->
                        </button>
                      </div>
<!--                        <div class="social-login">-->
<!--                            <div class="redirect row"> <span>Already has an account? </span> -->
<!--                              <span class="link" [routerLink]="['/sign-in']"> Login</span>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<!-- end footer -->
