import { Component, ElementRef, OnInit } from '@angular/core';
import { json } from 'express';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-vacations-result',
  templateUrl: './vacations-result.component.html',
  styleUrls: ['./vacations-result.component.css']
})
export class VacationsResultComponent implements OnInit {
  hotelsVacations: any;
  starWidth: number = 0;
  constructor(private seoService: SEOService,
              private hotelsService:HotelsApisService,
              private element :ElementRef) { }

  ngOnInit(): void {
    this.seoUpdate();
    this.getAllVacations();
  }
  seoUpdate() {

    this.seoService.updateTitle('Vacations Offers | FlyAllOver');
    this.seoService.updateDescription('Vacations Offers | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  getAllVacations(){
    this.hotelsService.allVacations().subscribe( (res :any)=> {
      this.hotelsVacations= res.data;
      for (let i = 0; i < this.hotelsVacations.length; i++) {
        this.rateProduct(this.hotelsVacations[i].hotel.hotel_rate)

      }

    })
  }

rateProduct(rateValue: number) {
  // console.log(rateValue);

  this.starWidth =rateValue* 90 / 5;
}
getRating(rateValue: number,e){


  var classList='';

  if (rateValue>=e){
    classList='checked'
  }

  return classList;
}
}
