<ng-template #rt let-r="result" let-t="term">
  <img
    alt="location"
    title="location"
    src="/assets/icons/flights.svg"
    style="width: 24px; height: 24px; margin-right: 8px"
  />
  <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
</ng-template>
<input
  type="text"
  [resultTemplate]="rt"
  class="form-control mt-2"
  #input
  (click)="input.select()"
  [ngbTypeahead]="flightsSearch"
  [(ngModel)]="inputValue"
  placeHolder="To Where ?"
/>
<ng-container *ngIf="!isFocused && !inputValue">
  <div class="placeHolder" (click)="input.select()">
    <!-- <span>To Where ?</span> -->
    <div>
      <img
        style="width: 17px; height: 24px"
        alt="location-pin"
        title="To Where ?"
        src="/assets/images/icons/location-pin.svg"
      />
    </div>
  </div>
</ng-container>
