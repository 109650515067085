<app-navbar></app-navbar>
<div class="container">
  <div class="row">
    <!-- ////////////////////Start Of flight-results-visa //////////////////////  -->

    <!-- <app-point-slider-offers></app-point-slider-offers>  -->
    <!-- ////////////////////End Of flight-results-visa //////////////////////  -->
    <!-- ////////////////////Start Of flight-results-search //////////////////////  -->
    <div class="row">
      <div class="col-12">
        <div class="visaComponent">
          <app-flight-results-visa></app-flight-results-visa>
        </div>
        <!-- <div class="searchInfo">
          <app-flight-results-search-data (modifiedSearchRequestEvent)="applyModifiedSearch($event)">
          </app-flight-results-search-data>
        </div> -->
      </div>
      <div class="searchInfo col-12">
        <app-flight-results-search
          (modifiedSearchRequestEvent)="applyModifiedSearch($event)"
        ></app-flight-results-search>
      </div>
      <ng-container *ngIf="!viewAlternativeDates&& !errorSearch">
      <div class="col-12 searchInfo">
        <app-flight-results-sorts
          (filterAlternativeDatesEvent)="filterAlternativeDates($event)"
          (setOpenDetailsEvent)="setOpenSortDetails()"
          (emitData)="setCheapest($event)"
          [openDetails]="openSortDetails"
          [tripType]="tripType"
          [requestId]="requestId"
          [response]="response"
          (selectTagId)="select($event)"
          (selectedObject)="selectedObject($event)"
        >
        </app-flight-results-sorts>
      </div>
    </ng-container>
      <!-- ////////////////////End Of flight-results-search //////////////////////  -->
      <!-- ////////////////////Start Of filters && Offers //////////////////////  -->
      <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 mb-4 mt-1 filter" >
        <app-filters *ngIf="!errorSearch && !viewAlternativeDates"
          (click)="closeFlightSort()"
          (applyFiltersEvent)="applyFiltersEvent($event)"
          [requestId]="requestId"
          [searchResults]="searchResults"
        >
        </app-filters>
      </div>
      <!-- ////////////////////End Of filters && Offers //////////////////////  -->
      <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <!-- *ngIf="!noResult" -->
        <ng-container *ngIf="errorSearch">
<div class="card-error-search d-none">
  <div class="title-error-search">
    Sorry there is not any result for selected airline
  </div>
</div>
<app-no-result [novbar]="false"></app-no-result>
        </ng-container>
        <ng-container class="result">
          <!--            *ngIf="alternativeDatesResponse"-->
           <!-- ----------------------------------old_loading---------------------------------- -->
            <ng-container *ngIf="loading">
              <div class="w-100 filters mb-3" >
                <div class="w-100 d-flex overflow-hidden">
                  <ng-container *ngFor="let item of airlineImg; let i = index">
                    <div class="animated-css bounceInRight" *ngIf="randomMin<i<airlineImg.length">
                      <img class="" src="{{item['url']}}">

                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row" >
                <app-loading-fake-flight></app-loading-fake-flight>
                <!--<ng-container *ngIf="(!itineraries && !destination) || (tripType == 'OpenJaw' && !itineraries)||!itineraries[0]">
                  <section class="w-100">
                    <div class="skeleton-container">
                      <ngx-skeleton-loader
                        [theme]="{ height: '300px' }"
                      ></ngx-skeleton-loader>
                    </div>
                  </section>
                </ng-container>-->
              </div>
            </ng-container>


          <!-- ----------------------------------old_loading---------------------------------- -->
          <!-- <div>
            <button class="btn alternative mb-4" *ngIf="tripType!='OpenJaw'" type="button" (click)="alternative('1')">Alternative Dates</button>
            <app-alternative-dates  (selectTagId)="select($event)" (selectedObject)="selectedObject($event)" (selectedItinerary)="selectedItinerary($event)"  *ngIf="alternativeSend =='1'" ></app-alternative-dates>
          </div> -->
          <ng-container *ngIf="!errorSearch">
          <div class="header ">
            <div class="w-100" style="padding: 8px 15px; ">
              <div class="price d-flex p-2" (click)="ShowMenu('viewAlternativeDates')">
                <div class="col-10 p-0  d-flex" *ngIf="!loading && !noResult">

                    <button class=" btn-open mb-2" *ngIf="tripType!='OpenJaw'" type="button" >Alternative Dates  <i class="fas fa-chevron-up openDetailsBtn  ng-star-inserted"
                      [ngClass]="{'rotate90deg': viewAlternativeDates }"></i></button>

                  </div>

                <div>

                </div>
              </div>
              <div  *ngIf="viewAlternativeDates ">
                <div class="alternative-part">
                  <app-alternative-dates (selectTagId)="select($event)" (selectedObject)="selectedObject($event)" (selectedItinerary)="selectedItinerary($event)"  ></app-alternative-dates>
                </div>
                </div>

              </div>
          </div>
          <div class="alternative-no-result my-3" *ngIf="!loading && noResult">
            <h3>NOTE !</h3>
            <p>Regrettably, we couldn't find any matches for the date you selected. Here are some alternative results that might be of interest to you.</p>

          </div>
          </ng-container>
          <app-itinerary class="mt-3" *ngIf="selected_Itinerary" [skeletons]="3" [altrnative_bg]="Bg_color"
            [flights]="select_Flights" [itineraries]="selected_Itinerary"  ></app-itinerary>
            <ng-container *ngIf="!viewAlternativeDates">
              <!-- *ngIf="!loading && !selectRound" -->
              <app-itinerary
             *ngIf="!loading && !selectRound"
              [toggleDetails]="true"
              [skeletons]="3"
              [alternativeDatesResponse]="alternativeDatesResponse"
              [resultsArrived]="resultsArrived"
              [loading]="loading"
              [itineraries]="itineraries"
              [flights]="flights"
              [origin]="origin"
              [destination]="destination"
              [searchRequest_Trip]="searchRequest"
              [page]="page"
              [cheapestFlights]="lowestPrice"
              (selectDepartReturn)="getSelecteditinerary($event)"
              (select)="select($event)"
              [airlineForSearchData]="airlineForSearchData"

            ></app-itinerary></ng-container>

            <ng-container *ngIf="!viewAlternativeDates">
          <app-itinerary-select-round
            *ngIf="selectRound"
            [selectedIndex]="selectedIndex"
            (selectedTicket)="select($event)"
            (selectRound)="BactToIti()"
            (collectionTotalItemsCount)="changeCollectionNumberOfPages($event)"
            [selectedItineraryTagId]="selectedItineraryTagId"
            [toggleDetails]="true"
            [skeletons]="3"
            [alternativeDatesResponse]="alternativeDatesResponse"
            [resultsArrived]="resultsArrived"
            [loading]="loading"
            [itineraries]="itineraries"
            [flights]="flights"
            [origin]="origin"
            [destination]="destination"
            [page]="page"
            (select)="select($event)"
            [cheapestFlights]="lowestPrice"
          ></app-itinerary-select-round>
        </ng-container>
          <!-- <ngb-pagination
            class="d-flex justify-content-center"
            *ngIf="itineraries && !loading"
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="collectionSize"
            (pageChange)="getNewPage()"
          >
            <ng-template ngbPaginationPrevious></ng-template>
            <ng-template ngbPaginationNext>></ng-template>
          </ngb-pagination> -->
          <ng-container *ngIf="!viewAlternativeDates">
              <ngb-pagination *ngIf="!loading" class="d-flex justify-content-center" [rotate]="true" [maxSize]="6"
                  [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="getNewPage()">
                  <ng-template ngbPaginationPrevious></ng-template>
                  <ng-template ngbPaginationNext>></ng-template>
                </ngb-pagination>
              </ng-container>
         </ng-container>
        <!-- <ng-container *ngIf="noResult ">
          <div class="mx-auto text-center body-notfound">
            <img class="image404" src="./assets/images/help-center/no-result.png" title="404 image" alt="no resulit"/>
            <h1 class="notFound">
              Sorry, We couldn't find what you're looking for!
            </h1>
            <p>
              We can't find any data that match your search. Update your
              choices so we can try again or contact us.
            </p>
          </div>
        </ng-container> -->
      </div>
      <div class="col-2 p-0">
        <app-flights-slider-offers></app-flights-slider-offers>
      </div>
    </div>
  </div>
</div>
<!-- <app-important-links></app-important-links> -->
<app-footer></app-footer>
