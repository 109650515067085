<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="image-banner" style="width: 1200px;height: 480px;background-image: url('./assets/webp/Brazil-new-banner.webp');background-size: contain">
<!--        <img style="display: block;margin-left: auto;margin-right: auto;"-->
<!--          title="Brazil-new-banner" alt="Brazil-new-banner" src="">-->
        <button class="btn" (click)="scrollToEngine()">BOOK NOW</button>
      </div>
    </div>
  </div>
</div>
