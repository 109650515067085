<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->
<div class="container-fluid p-5">
  <div class="row">
    <!-- start need -->
    <div class="col-lg-3">
      <div class="need">
        <div class="assistance d-flex text-white p-3">
          <i class="fa-solid fa-ticket "></i>
          <div class="assistance-detail">
            <h5>Need Assistance</h5>
            <span>Get the help you need online now</span>
          </div>
        </div>
        <div class="accordion accordion-flush bg-white" id="accordionFlushExample">
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingOne">
              <a class="accordion-button collapsed  " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                Book a New Flight
              </a>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingOne"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Help with New Bookings</a></li>
                  <li><a href="#">Book Flight with Airline Credit</a></li>
                  <li><a href="#">Group Bookings</a></li>
                  <li><a href="#">Vactions Bookins</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingtwo">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                Existing Flight Reservations
              </a>
            </h2>
            <div id="flush-collapsetwo" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingtwo"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Email Itinerary</a></li>
                  <li><a href="#">Check Booking Status</a></li>
                  <li><a href="#">Change or Cancel Booking</a></li>
                  <li><a href="#">Seats, Baggages &amp; Special Requests</a></li>
                  <li><a href="#">Flight Checkin and Boarding Pass</a></li>
                  <li><a href="#">Trip Insurance</a></li>
                  <li><a href="#">Received Notice of Schedule Change</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingthree">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                Refund, Credit and Payment Queries
              </a>
            </h2>
            <div id="flush-collapsethree" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingthree"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Check Refund/Airline Credit status</a></li>
                  <li><a href="#">Get Invoice</a></li>
                  <li><a href="#">Price Match Promise</a></li>
                  <li><a href="#">Payment Issues</a></li>
                  <li><a href="#">Help with Multiple Charges</a></li>
                  <li><a href="#">International Transaction Fee</a></li>
                  <li><a href="#">Currency Conversion</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingfour">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                Hotel and Cars
              </a>
            </h2>
            <div id="flush-collapsefour" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingfour"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Book a Hotel or Car</a></li>
                  <li><a href="#">Help with Existing Hotel Booking</a></li>
                  <li><a href="#">Help with Existing Car Booking</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingfive">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                Promotion, Rewards &amp; Gift Cards
              </a>
            </h2>
            <div id="flush-collapsefive" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingfive"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Earn or Redeem Reward Points</a></li>
                  <li><a href="#">Issue with Redeem Rewards</a></li>
                  <li><a href="#">Using Giftcards</a></li>
                  <li><a href="#">Buy Gift Cards</a></li>
                  <li><a href="#">Apply Promo Codes</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingsix">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                Add-ons
              </a>
            </h2>
            <div id="flush-collapsesix" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingsix"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#"> Drop Monitor</a></li>
                  <li><a href="#">Price Lock</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingseven">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                Account and Login
              </a>
            </h2>
            <div id="flush-collapseseven" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingseven"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Forgot Password</a></li>
                  <li><a href="#">Update Email Address/ Phone Number</a></li>
                  <li><a href="#">Delete My Account</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingeight">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                Covid-19 &amp; Travel Restrictions
              </a>
            </h2>
            <div id="flush-collapseeight" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingeight"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Visa and Passport Information</a></li>
                  <li><a href="#">Travel Restrictions &amp; Covid-19 Info</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header" id="flush-headingnine">
              <a class="accordion-button collapsed " type="button" data-bs-toggle="collapse"
                 data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                Special Assistance
              </a>
            </h2>
            <div id="flush-collapsenine" class="accordion-collapse-1 collapse " aria-labelledby="flush-headingnine"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body p-0 ">
                <ul class="book">
                  <li class="primary"><a href="#">Assistance</a></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- end need -->

    <!-- start Popular-card -->
    <div class="col-lg-6">
<!--      <h3 class="pb-2">Popular Topics</h3>-->
<!--      <div class="row row-cols-1 row-cols-md-3 g-3">-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card h-100">-->
<!--              <img src="./assets/images/contact/Booking-Status.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Check Booking Status?</h5>-->
<!--                <p class="card-text">View your trip status online and receive confirmation.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card h-100">-->
<!--              <img src="./assets/images/contact/Refund.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Check Refund and Credit details?</h5>-->
<!--                <p class="card-text">View your refund/credit details and check status online.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card h-100">-->
<!--              <img src="./assets/images/contact/Cancel.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Want to Check-in?</h5>-->
<!--                <p class="card-text"> Get assistance on flight Checkin and Boarding Pass.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card h-100">-->
<!--              <img src="./assets/images/contact/Change-Dates.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Help with New Bookings?</h5>-->
<!--                <p class="card-text"> Get assistance to make a new booking.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card h-100">-->
<!--              <img src="./assets/images/contact/New-Booking.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Want to Cancel your trip?</h5>-->
<!--                <p class="card-text">Get assistance to make a new booking.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <a href="">-->
<!--            <div class="card ">-->
<!--              <img src="./assets/images/contact/Boarding-Pass.jpg" class="card-img-top" alt="...">-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">Payment Issues?</h5>-->
<!--                <p class="card-text"> Receive Payment Information and assistance on other billing queries.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; end Popular-card &ndash;&gt;-->


      <!-- start asked -->
      <div class="asked">
        <h3>Frequently asked questions:</h3>
        <p>We've put together some commonly asked questions to give you more information related to the selected
          topic.</p>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button " type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne">
                <h5>1. How do I book a new flight on Flyallover?</h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                 aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>You can make a new booking <a class="us" href="#"> here</a>.</span>
                  </li>
                  <li>
                    <span>All you have to do is choose a one-way, round trip, or multi-city flight. Then, type in your origin and destination airports, your preferred travel dates, and the number of travelers. </span>
                  </li>
                  <li>
                    <span>You can also speak to one of our travel experts. Please <a class="us" href="">chat with us</a> or call 646-738-4820 toll (8AM - 2AM EST). </span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="  fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="  fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo">
                <h5>2. Which cabin classes are available for my flight?</h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">
                <ul>
                  <li><span>Cabin classes are determined by the airline and aircraft type. Some will have one cabin class while others will have multiple.</span>
                  </li>
                  <ul>
                    <li><span> Coach/Economy Class seating is in the main cabin area. These seats are usually the least expensive.</span>
                    </li>
                    <li><span>Business Class seating, when available, tends to feature roomier seats that recline farther, more leg room, and upgraded meal service. Business class tickets cost more than Coach/Economy but less than First Class.</span>
                    </li>
                    <li><span>First class seating is usually in the cabin area nearest the front of the aircraft. It has the fewest and most comfortable seats and offers upgraded meals. Other perks can include shorter lines at check-in and use of the airline’s airport business lounge. First class faresare almost always the most expensive and are not available on all aircrafts.</span>
                    </li>
                  </ul>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="  fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="  fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree">
                <h5>3. What is a super saver fare?</h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body">
                <ul>
                  <li><span>For some of our flight options, we offer super saver fares. These are special deals where important flight details (e.g. identity of airline, exact departure and arrival time) are not disclosed to you until after you pay for your booking. You’ll receive the full itinerary via email right after booking. No refund, changes, or standbys are allowed on this itinerary. Please note you will still be responsible for paying FlyAllOver’s New Booking Service Fee.</span>
                  </li>
                  <li>
                    <span>If your flight route is eligible, you’ll see a super saver fare option listed at the top. </span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingfour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsefour" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsefour">
                <h5>4. Why is my original quoted fare or itinerary no longer available? What are my options? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsefour" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingfour">
              <div class="accordion-body">
                <ul>
                  <li><span>Flights provided by the airlines are only available for a limited time and fare prices can vary due to fluctuating customer demand.</span>
                  </li>
                  <li><span>Don’t worry, we have your back! We will search out database to find you the lowest possible fare price for the original itinerary you chose or a new itinerary that aligns with your travel plans. </span>
                  </li>
                  <li><span>We will send you an email presenting the options and all you have to do is accept the changes. </span>
                  </li>
                  <li><span>If the proposed itinerary does not work for you, just <a class="us"
                                                                                     href="">chat with us </a> we can help you find an alternate option. </span>
                  </li>
                  <li><span>Please note that the new itinerary is not guaranteed until ticketed. If you take no action, your original booking will automatically be canceled. </span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingfive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsefive">
                <h5>5. What are low-cost carriers? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingfive">
              <div class="accordion-body">
                <ul>
                  <li><span>A low-cost carrier (LCC) is an airline that aims to minimize operating costs for customers. It typically does not include any services (i.e., carry-on bags) in the fare and has more restrictive rules compared to Full Service Carriers (FSC).</span>
                  </li>

                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingsix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsesix">
                <h5>6. Which airlines are considered low-cost carriers? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingsix">
              <div class="accordion-body">
                <ul>
                  <li><span>A few examples of low-cost carriers include Frontier Airlines, Spirit Airlines, <span
                    class="ari text-secondary">Allegiant Air</span>  , EasyJet, Ryanair, etc.</span></li>
                  <li>
                    <span>For a more comprehensive list, please visit <a class="us" href="#"> here</a>.</span>
                  </li>

                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingseven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseseven" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseseven">
                <h5>7. If I am flying with a low-cost carrier, how can I make changes to my booking?</h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseseven" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingseven">
              <div class="accordion-body">
                <ul>
                  <li><span>If you need help modifying your booking, such as making a change, cancelation, adding bags or seats, or checking in, please<a
                    class="us" href=""> chat with us </a> here. </span></li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingeight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseeight" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseeight">
                <h5>8. I would like to use my FlyAllOver travel credit to book a new flight. How do I know if I have any
                  credit?</h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseeight" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingeight">
              <div class="accordion-body">
                <ul>
                  <li><span>We can only track your refund or travel credit for flight bookings that were initially made on FlyAllOver. We cannot track any refund or travel credits you may have earned if you booked directly with the airline, hotel, car rental, or other third parties.</span>
                  </li>

                  <li><span>You can track your FlyAllOver refund or travel credit <a class="us"
                                                                                    href=""> here </a> . </span></li>
                  <li><span>To use any travel credit that you have with us from a previously canceled booking, please speak to one of our Travel Experts at 845-848-0211 (8AM - 2AM EST).</span>
                  </li>
                  <li><span>You will need to enter your FlyAllOver Booking ID and booking email address.</span></li>

                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingnine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapsenine" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapsenine">
                <h5>9. What is the maximum number of travelers that I can book online at once? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapsenine" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingnine">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>You may book up to 9 travelers on a single reservation. To book 10+ travelers, please visit our Group Travel page  <a
                      class="us" href="#"> here </a> and fill out the request form.</span>
                  </li>

                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingten">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseten" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseten">
                <h5>10. Why did the fare increase when I tried to book my flight? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseten" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingten">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span> Flight costs can change minute by minute based on demand and availability. Between the time of your flight search and booking, the number of seats in a particular booking class may be quickly filled. Airlines then will offer their available inventory at a higher price.</span>
                  </li>
                  <li>
                    <span> We monitor these changes closely and make every effort to keep inventory and fares as updated as possible.</span>
                  </li>

                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headingeleven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseeleven" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseeleven">
                <h5>11. Which name should I use when booking a flight? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapseeleven" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-headingeleven">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span> For domestic flights, please enter your name as it is written on your government-issued photo <span
                      class=" text-secondary">ID </span> (e.g. driver’s license)</span>
                  </li>
                  <li>
                    <span> For international flights, please enter your name as it is written in your passport.</span>
                  </li>
                  <li><span>It is your sole responsibility to provide your proper name when booking. You will not be entitled to a refund if you are denied boarding due to this issue.</span>
                  </li>
                  <li><span>If you notice there is a typo in your name, please<a class="us" href=""> chat with us </a> here to determine if your airline will allow this type of correction. If your airline permits the correction, applicable supplier and FlyAllOver Service Fees will apply.</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge12">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse12">
                <h5>12. What are my payment options when making a new booking? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse12" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading12">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Most major credit and debit cards are allowed (e.g., Visa, Discover, American Express, MasterCard). We also accept payment through PayPal and Apple Pay. </span>
                  </li>
                  <li>
                    <span>Additionally, U.S. customers (excluding customers from Iowa or West Virginia) can select "Affirm at checkout", which enables them to make monthly payments.</span>
                  </li>

                  <li><span>Learn more<a class="us" href=""> here </a></span></li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge13">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse13">
                <h5>13. What else is included in my booking? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse13" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading13">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>All fares include taxes, airline or airport fees, and FlyAllOver service fees associated with the ticket price.</span>
                  </li>


                  <li><span><a class="Click"
                               href="">Click here </a>for more details on our New Booking Service Fees:</span></li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge14">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse14">
                <h5>14. Can I earn frequent flyer miles when booking? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse14" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading14">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Most of our flight options will allow you to acquire frequent flyer miles.</span>
                  </li>
                  <li>
                    <span>When filling out traveler details while booking, you must enter the frequent flyer number for the miles to be transmitted to the airline.</span>
                  </li>
                  <li>
                    <span>Please note this is an optional field to complete.</span>
                  </li>


                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge15">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse15">
                <h5>15. Do you offer infant/child fares? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse15" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading15">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Infants (children under 24 months) traveling within the United States travel at no charge as long as they’re seated on an adult's lap. Only one infant is allowed to travel as a lap child per adult (over 18 years). Infants traveling internationally will be required to pay a percentage of the adult fare (in most circumstances), plus taxes.</span>
                  </li>
                  <li>
                    <span>When booking a flight online, you can select the number of travelers on our homepage and specify that you’ll be traveling with an infant.</span>
                  </li>
                  <li>
                    <span>If your infant will be traveling in a car seat, special infant fares will apply. Additionally, the car seat must meet airline specifications. Please call the airline you’ll be flying with for more <span
                      class="text-secondary">information</span>.</span>
                  </li>
                  <li>
                    <span>Some airlines provide child discounts for international fares. Most airlines do not offer child discounts for domestic fares and will charge full fare for children over two years old.</span>
                  </li>
                  <li>
                    <span class="please"><a href="">Please note FlyAllOver’s New Booking Service Fee will still apply for infant and child flight tickets.</a></span>
                  </li>
                  <li>
                    <span> <span class="us">Click here </span>for more details on our New Booking Service Fees:</span>
                  </li>


                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge16">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse16" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse16">
                <h5>16. Can I book a flight for my child who is flying alone? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse16" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading16">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Most airlines allow unaccompanied minors (ages 5-16) to travel on non-stop flights but have very specific and strict rules, policies, and procedures.</span>
                  </li>
                  <li>
                    <span>FlyAllOver can help you book a ticket for your minor, but you MUST call the airline directly after your booking is confirmed to arrange this service and check the most updated policies.</span>
                  </li>
                  <li>
                    <span>This service is typically priced around ~$150 USD per minor for a one-way flight. In most circumstances, we cannot arrange this service on your behalf.</span>
                  </li>
                  <li>
                    <span>For more information, please <span class="us">chat with us</span> here.</span>
                  </li>
                  <a href=""><small class="text-secondary please">General Tips: </small></a>
                  <li>
                      <span>Required Information – The airline will require the parent or legal guardian of the child to complete a form prior to the departure date of the unaccompanied minor. Usually, these forms request information centered on:
                        <p class="mb-0">1) Who will be dropping them off at the airport/gate</p>
                         <p class="mb-0">2) Who will pick them up when they reach the destination</p></span>
                  </li>
                  <li>
                    <span> Identification Requirements – Only government-issued photo ID (e.g. driver’s license)will be accepted and will be required for the child and adults dropping off/picking up the child. For more information on accepted forms of identification, please contact the airline your child is traveling on.</span>
                  </li>
                  <li>
                    <span>Passport/Visa Requirements – When traveling internationally, please ensure your child has a valid passport. The passport should be valid for up to 6 months from the date of return. It is the responsibility of the parent or legal guardian to ensure that the child has the required entry and departure documents to enter and depart from the visiting country, including the countries they are transiting between.  </span>
                  </li>
                  <li>
                    <span>Some countries may also have additional travel document requirements. You must check these details with the Embassy or Consulate.</span>
                  </li>


                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge17">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse17" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse17">
                <h5>17. Can I use frequent flyer miles to get a further discount or upgrade? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse17" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading17">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>No, our specially priced contract fares do not allow you to use frequent flyer miles for upgrade or further discount. Additionally, frequent flyer miles must be exchanged directly with the airline you have an account with or with your credit card company. CheapOair is unable to utilize your miles in any way.</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge18">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse18" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse18">
                <h5>18. Do you offer military discounts? How can I apply it toward my trip? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse18" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading18">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>From time to time, we offer coupon codes specifically for our military travelers. All military travelers are eligible for our generic coupons and other promotions.</span>
                  </li>
                  <li>
                    <span>Additionally, most airlines offer discount airfare for active-duty personnel or military-ID cardholders. You can search your airline’s benefits<a
                      class="us"> here</a>.</span>
                  </li>
                  <li>
                    <span>For military travelers, <a class="us"> chat with us </a>. Our agents can assist you in booking military fares, if the same is provided by the airlines. Fares are based on airline availability and prices may not be guaranteed until booked.</span>
                  </li>
                  <li>
                    <span>Agents may find fares that are lower than military fares which may include our discounted, net, and other non-published fares which agents may offer to military travelers.</span>
                  </li>
                  <p class="please mt-3 mb-0">Tips:</p>
                  <li>
                    <span>Before calling us, please have your proof of Active Duty (e.g., .mil Email Address, Active Duty ID Card, Guard/Reserves ID Card, Common Access Card) or Veteran Status (e.g., a Military Retiree ID Card, Veterans ID card, Veteran Health ID Card, Uniformed Services ID Card, State Driver’s License stamped “VETERAN) handy and let our agents know that you’re looking to book military fares.</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge19">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse19" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse19">
                <h5>19. Do you offer any senior citizen discounts? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse19" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading19">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>From time to time, we offer coupon codes specifically for our senior travelers (ages 65+). All senior travelers are eligible for our generic coupons and other promotions.</span>
                  </li>
                  <li>
                    <span>Additionally, some airlines offer attractive discounts for seniors on certain itineraries.</span>
                  </li>
                  <li>
                    <span>For senior travelers, please <a class="us"> chat with us </a>.  and our agents can assist you in booking senior fares if they’re also provided by the airline. Fares are based on airline availability and prices may not be guaranteed until booked.</span>
                  </li>
                  <li>
                    <span>Agents may find fares that are lower than senior fares which may include our discounted, net, and other non-published fares which agents may offer to senior travelers.</span>
                  </li>
                  <li>
                    <span>For more information about senior discounts, please <a class="us">click here</a>.</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge20">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse20" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse20">
                <h5>20. Do you offer discounts for children? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse20" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading20">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Some airlines provide child discounts for international fares. Most airlines do not offer child discounts for domestic fares and will charge full fare for children over two years old.</span>
                  </li>
                  <li>
                    <span>Infants (children under 24 months) traveling within the United States travel at no charge as long as they’re seated on an adult's lap. Only one infant is allowed to travel as a lap child per adult (over 18 years). Infants traveling internationally will be required to pay a percentage of the adult fare (in most circumstances), plus taxes.</span>
                  </li>
                  <li>
                    <span>When booking a flight online, you can select the number of travelers on our homepage and specify that you’ll be traveling with an infant.</span>
                  </li>
                  <li>
                    <span>If your infant will be traveling in a car seat, special infant fares will apply. Additionally, the car seat must meet airline specifications. Please call the airline you’ll be flying with for more information.</span>
                  </li>
                  <li>
                  <li><span class="please fw-bold">Please note FlyAllOver’s New Booking Service Fee will still apply for infant and child flight tickets.</span>
                  </li>
                  <li><span>F <a class="us">click here</a> for more details on our New Booking Service Fees:</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item pb-3 border-0">
            <h2 class="accordion-header" id="panelsStayOpen-headinge21">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapse21" aria-expanded="false"
                      aria-controls="panelsStayOpen-collapse21">
                <h5>21. What are my payment options when booking a new flight? </h5>
              </button>
            </h2>
            <div id="panelsStayOpen-collapse21" class="accordion-collapse collapse"
                 aria-labelledby="panelsStayOpen-heading21">
              <div class="accordion-body">
                <ul>
                  <li>
                    <span>Most major credit and debit cards are allowed (e.g., Visa, Discover, American Express, MasterCard). We also accept payment through PayPal and Apple Pay.</span>
                  </li>
                  <li>
                    <span>Additionally, U.S. customers (excluding Iowa or West Virginia customers) can select Affirm at checkout, which enables them to make monthly payments.</span>
                  </li>
                </ul>
                <div class="information d-flex gap-5">
                  <h6>Was this information helpful?</h6>
                  <div class="button d-flex gap-3 ">
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-down"></i>No</button>
                    <button type="button" class="fs-5"><i class="fa-regular fa-thumbs-up "></i>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- end asked -->

    <!-- stary help -->

    <div class="col-lg-3">
      <div class="help">
        <h4><i class="fa-solid fa-arrow-left"></i>Help with New Bookings</h4>
        <span>Book a New Flight > Help with New Bookings</span>
        <p>For help with your new booking, please call or chat with us and one of our Travel Specialists will
          assist.</p>
        <div class="button-now  d-flex gap-3 px-3">
          <button type="button" class="btn btn-1 px-4">Chat Now</button>
          <button type="button" class="btn btn-2 px-4">Call Us</button>
        </div>
        <div class="information d-flex gap-5 py-5">
          <h6>Was this information helpful?</h6>
          <div class="button d-flex gap-2 ">
            <button type="button"><i class="fa-regular fa-thumbs-down text-secondary "></i>No</button>
            <button type="button"><i class="fa-regular fa-thumbs-up  text-secondary "></i>Yes</button>
          </div>
        </div>
      </div>
    </div>


    <!-- end help -->

  </div>
</div>


<!-- start help-center-page -->
<!--<div class="help-center-page">-->
<!--    <div class="help-center-header">-->
<!--        <div class="container">-->
<!--            <h1 class="text-center Font-Family">Contact Us</h1>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="help-center-body">-->
<!--        &lt;!&ndash; start contact-us &ndash;&gt;-->
<!--        <div class="container">-->
<!--            <div class="contact-us">-->
<!--                <form [formGroup]="contactUsForm" (ngSubmit)="onSubmitContactUsForm(contactUsForm.value)"-->
<!--                    *ngIf="!formSubmitted">-->
<!--                    <div class="inside">-->
<!--                        <h3 class="Font-family text-center">Send Us a Message </h3>-->
<!--                        <p class="Font-family text-center " style="font-size: 15px;"> Enter these details and we will contact you </p>-->
<!--                        &lt;!&ndash; Full name &ndash;&gt;-->
<!--                        <div class="field">-->
<!--                            <label for="name"-->
<!--                            class="Font-family"-->
<!--                                [ngClass]="contactUsForm.get('fullName').invalid && contactUsForm.get('fullName').touched ?'text-danger':''">Full-->
<!--                                Name</label>-->
<!--                            <input id="name" type="text" formControlName="fullName" required-->
<!--                                [ngClass]="contactUsForm.get('fullName').invalid && contactUsForm.get('fullName').touched ? 'has-error':'no-error'"-->
<!--                                (keypress)="navigateOnSamePage()" />-->
<!--                            <span *ngFor=" let validation of validationMessages.fullName">-->
<!--                                <small class="text-danger"-->
<!--                                    *ngIf="contactUsForm.get('fullName').hasError(validation.type) && (contactUsForm.get('fullName').dirty || contactUsForm.get('fullName').touched)">-->
<!--                                    {{validation.message}}</small>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; Email &ndash;&gt;-->
<!--                        <div class="field">-->
<!--                            <label for="email"-->
<!--                            class="Font-family"-->
<!--                                [ngClass]="contactUsForm.get('email').invalid && contactUsForm.get('email').touched ?'text-danger':''">Email-->
<!--                                Address</label>-->
<!--                            <input id="email" type="email" formControlName="email" required-->
<!--                                [ngClass]="contactUsForm.get('email').invalid && contactUsForm.get('email').touched ? 'has-error':'no-error'"-->
<!--                                (keypress)="navigateOnSamePage()" />-->
<!--                            <span *ngFor=" let validation of validationMessages.email">-->
<!--                                <small class="text-danger"-->
<!--                                    *ngIf="contactUsForm.get('email').hasError(validation.type) && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched)">-->
<!--                                    {{validation.message}}</small>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; mobile number &ndash;&gt;-->
<!--                        <div class="field">-->
<!--                            <label for="mobile"-->
<!--                            class="Font-family"-->
<!--                                [ngClass]="contactUsForm.get('mobileNumber').invalid && contactUsForm.get('mobileNumber').touched ?'text-danger':''">Mobile-->
<!--                                Number</label>-->
<!--                            <input id="mobile" type="text" formControlName="mobileNumber" required-->
<!--                                [ngClass]="contactUsForm.get('mobileNumber').invalid && contactUsForm.get('mobileNumber').touched ? 'has-error':'no-error'"-->
<!--                                (keypress)="navigateOnSamePage()" />-->
<!--                            <span *ngFor=" let validation of validationMessages.mobileNumber">-->
<!--                                <small class="text-danger"-->
<!--                                    *ngIf="contactUsForm.get('mobileNumber').hasError(validation.type) && (contactUsForm.get('mobileNumber').dirty || contactUsForm.get('mobileNumber').touched)">-->
<!--                                    {{validation.message}}</small>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        <div class="form-check">-->
<!--                            <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
<!--                            <label class="form-check-label font-weight-bold font-family" for="exampleCheck1">By submitting this form, you agree to receive text messages at anytime, which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). This is so that we may reach you as soon as possible in order to consult on your potential case .</label>-->
<!--                        </div>-->
<!--                        -->
<!--                        &lt;!&ndash; subject &ndash;&gt;-->
<!--                        <div class="field">-->
<!--                            <label for="subject"-->
<!--                                [ngClass]="contactUsForm.get('subject').invalid && contactUsForm.get('subject').touched ?'text-danger':''">*Subject</label>-->
<!--                            <input id="subject" type="text" formControlName="subject" required-->
<!--                                [ngClass]="contactUsForm.get('subject').invalid && contactUsForm.get('subject').touched ? 'has-error':'no-error'"-->
<!--                                (keypress)="navigateOnSamePage()" />-->
<!--                            <span *ngFor=" let validation of validationMessages.subject">-->
<!--                                <small class="text-danger"-->
<!--                                    *ngIf="contactUsForm.get('subject').hasError(validation.type) && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched)">-->
<!--                                    {{validation.message}}</small>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; message &ndash;&gt;-->
<!--                        <div class="field">-->
<!--                            <label for="message"-->
<!--                            class="Font-family"-->
<!--                                [ngClass]="contactUsForm.get('message').invalid && contactUsForm.get('message').touched ?'text-danger':''">Your-->
<!--                                Message</label>-->
<!--                            <textarea id="message" placeholder="Tell us anything you want to.."-->
<!--                                formControlName="message" required-->
<!--                                [ngClass]="contactUsForm.get('message').invalid && contactUsForm.get('message').touched ? 'has-error':'no-error'"-->
<!--                                (keypress)="navigateOnSamePage()"></textarea>-->
<!--                            <span *ngFor=" let validation of validationMessages.message">-->
<!--                                <small class="text-danger"-->
<!--                                    *ngIf="contactUsForm.get('message').hasError(validation.type) && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched)">-->
<!--                                    {{validation.message}}</small>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; <div class="text_blowe mb-3">-->
<!--                            <span>-->
<!--                                By submitting this form, you agree to receive telephone calls and text messages at anytime,-->
<!--                                which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). -->
<!--                                This is so that we may reach you as soon as possible in order to consult on your potential case .-->
<!--                            </span>-->
<!--                        </div> &ndash;&gt;-->
<!--                        -->
<!--                        <div class="form-check">-->
<!--                            <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
<!--                            <label class="form-check-label font-weight-bold font-family" for="exampleCheck1">By submitting this form, you agree to receive telephone calls at anytime, which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). This is so that we may reach you as soon as possible in order to consult on your potential case .</label>-->
<!--                        </div>-->
<!--                        <div class="form-check mb-2">-->
<!--                            <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
<!--                            <label class="form-check-label Font-Family font-weight-bold" for="exampleCheck1">By pressing send button you are authorizing Lead Management team to contact you by SMS/TEXT.</label>-->
<!--                          </div>-->
<!--                          <ul>-->
<!--                            <li class=" Font-Family font-weight-bold font"> We at Fly All Over hold the privacy of your personal information in the highest regard. We want to make it explicitly clear that we do not share or sell any information about our customers to any third parties. The trust you place in us by using our services is something we take seriously, and maintaining the confidentiality of your information is fundamental to our business ethics and practices.</li>-->
<!--                            <li class=" Font-Family font-weight-bold font mt-2"> We collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and any other information that you choose to share with us, but we do not sell, rent, or disclose your personal information to third parties without your consent, except as necessary to provide requested services or as required by law</li>-->
<!--                        </ul>-->
<!--                        <div>-->
<!--                            <re-captcha required (resolved)="resolved($event)" [siteKey]="siteKey">-->
<!--                            </re-captcha>-->
<!--                        </div>-->
<!--                        <div><a class="Font-Family"[routerLink]="['/privacy']" href="https://flyallover.com/privacy">Privacy and Policies</a></div>-->
<!--                        <div class="submit">-->
<!--                            <button [ngClass]="contactUsForm.valid && captchaKey ? 'button active' :'button disabled'"-->
<!--                                type="submit" [disabled]="!contactUsForm.valid && !captchaKey">SEND <i-->
<!--                                    *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> </button>-->
<!--                            &lt;!&ndash; <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> &ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </form>-->
<!--                <div class="inside text-center" *ngIf="formSubmitted">-->
<!--                    <h6 class="font-size">Thank you!</h6>-->
<!--                    <p class="font-size" >Your message has been received and we will be contacting you shortly to follow up</p>-->
<!--                    <div class="img">-->
<!--                        <img title="Contact Us " alt="mail"  src="./assets/images/help-center/mail.svg" width="80" />-->
<!--                    </div>-->
<!--                    <a [routerLink]="['/']">Go back to homepage <i class="fas fa-angle-right"></i></a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; end contact-us &ndash;&gt;-->
<!--      </div>-->
<!--</div>-->
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
