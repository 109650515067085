import {DatePipe, DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {SEOService} from '../../../services/seo.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from '../../../services/apis.service';
import {CouponService} from '../../../services/coupon/coupon.service';

@Component({
  selector: 'app-review-flight-trip-protection',
  templateUrl: './review-flight-trip-protection.component.html',
  styleUrls: ['./review-flight-trip-protection.component.css']
})

export class ReviewFlightTripProtectionComponent implements OnInit, AfterViewInit {
  @Input() form1Component;
  @Input() form2Component;
  @Input() itineraries;
  @Input() passengerDetails;
  @Input() tagId;
  @Input() tripType;
  @Output() setProtectionEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();
  passengerFormData;
  insuranceError = '';
  protectionAccept = 'no';
  insuranceLoading = false;
  insuranceFacilities;
  insuranceUrl;
  insuranceCost;
  insuranceResponse;
  insuranceResponseError = false;
  @Input() InsuranceInfo;
  @Output() InsuranceInfo2 = new EventEmitter();
  @Input() req;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private apis: ApisService,
    private couponApis: CouponService,
    private datePipe: DatePipe,
    private seoService: SEOService
  ) {
  }

  ngOnInit() {
    this.seoUpdate();
    if (this.protectionAccept == 'no') {
      this.getInsuranceInfoApi();
    }
  }

  ngAfterViewInit() {
  }

  seoUpdate() {

    this.seoService.updateTitle('');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  getInsuranceInfoApi() {
    this.apis.getTravelProtection(this.req).subscribe((res: any) => {
      this.InsuranceInfo = res;
      this.getInsuranceInfo();
    });
  }

  // initInsuranceRequest() {
  //   const insuranceData: any = {};
  //   insuranceData.TypeID = 'WTP14';
  //   insuranceData.PlanID = '0';
  //   insuranceData.tagId = this.tagId;
  //   insuranceData.start_date = this.datePipe.transform(this.itineraries[0].flights[0][0].DepartureDateTime, 'yyyy-MM-dd');
  //   insuranceData.end_date = this.datePipe.transform(this.itineraries[0].flights[this.itineraries[0].flights?.length - 1][0].DepartureDateTime, 'yyyy-MM-dd');
  //   insuranceData.trip_type = this.tripType;
  //   const fName = {};
  //   const lName = {};
  //   const dob = {};
  //   const PassengerType = {};
  //   fName[0] = 'p1';
  //   lName[0] = 'p1';
  //   dob[0] = '1995-12-04';
  //   PassengerType[0] = 'ADT';
  //   insuranceData[`f_name`] = fName;
  //   insuranceData[`l_name`] = lName;
  //   insuranceData[`dob`] = dob;
  //   insuranceData[`PassengerType`] = PassengerType;
  //   return insuranceData;
  // }

  getInsuranceInfo() {
    this.insuranceLoading = true;
    if (this.InsuranceInfo) {
      this.insuranceResponse = this.InsuranceInfo;
      this.insuranceUrl = this.insuranceResponse?.QuoteDetail?.QuoteDetailURL;
      this.insuranceFacilities = this.insuranceResponse?.QuoteDetail?.PlanRestrictions;
      this.insuranceCost = this.insuranceResponse?.PlanCost?.Amount;
      this.insuranceResponseError = false;
      this.insuranceLoading = false;
    } else {
      this.insuranceLoading = false;
      this.insuranceResponseError = true;
    }
  }

  setProtection(value) {
    if (value == true) {
      this.setProtectionEvent.emit(this.insuranceResponse);
    } else {
      this.setProtectionEvent.emit(false);
    }
  }
}


