import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ApisService } from "../../../services/apis.service";
import { PnrInDashboardComponent } from "../../review-flight/pnr-in-dashboard/pnr-in-dashboard.component";
import { MatDialog } from "@angular/material/dialog";


@Component({
  selector: "app-orders-tab",
  templateUrl: "./orders-tab.component.html",
  styleUrls: ["./orders-tab.component.scss"],
})
export class OrdersTabComponent implements OnInit {
  ordersData: any[];
  select: boolean;
  disableTicket = false;
  constructor(private Apis: ApisService,private dialog: MatDialog) {
  }

  tab = "pnr-created";
  items: number[] = [1, 2, 3, 4, 5];

  ngOnInit(): void {
    this.openTab('pnr-created')
  }


  isActive(tabId: any): boolean {
    return this.tab === tabId;
  }

  openTab(tabId): void {
    this.tab = tabId;
    console.log('tabId', tabId);
    switch (tabId) {
      case "pnr-created": {
        this.Apis.UserDashoardCreated().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "pnr-canceld": {
        this.Apis.UserDashoardCanceld().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "payment-fail": {
        this.Apis.UserDashoardPaymentFail().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "high-risk-score": {
        this.Apis.UserDashoardHighRiskScore().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "pnr-error": {
        this.Apis.UserDashoardError().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "manual-review": {
        this.Apis.UserDashoardManualReview().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "issue-ticket-error": {
        this.Apis.UserDashoardIssueTticketError().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
      case "success": {
        this.Apis.UserDashoardSuccess().subscribe({
          next: (res: any) => {
            this.ordersData = res.data
          }, error: error => {
            console.error(error);

          }
        });
        break;
      }
    }
  }
  flightDetails(orderId) {
    this.select=!this.select
    if(this.disableTicket== false){
      this.disableTicket = true
      const token = localStorage.getItem('flyallover_token')
      this.Apis.flightDetails(token, orderId).subscribe((data: any) => {

        const orderData = data.data
        this.disableTicket= false

        this.openFlighetDetailsDailog(orderData);
      },
      err => {
        const error = err.error.order_id
        this.viewErrorTicket(error)
        this.disableTicket= false

      });
    }
  }
  openFlighetDetailsDailog(details){
    const dialogRef=this.dialog.open(PnrInDashboardComponent, {
      panelClass: 'pnrTicketDialog',
        autoFocus: false,
        minHeight: '65%',
        width: '440px',
      data: {

        flightData:details
      }})

   }

  viewErrorTicket(error){
    const dialogRef=this.dialog.open(PnrInDashboardComponent, {
      panelClass: 'pnrTicketDialog',
        autoFocus: false,
        width: '440px',
      data: {
        error : error,
      }})
  }
}
