<div class="formContainer hotelsForm">
  <div *ngIf="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{errorMessage}}
  </div>
  <div class="row">
    
    <div class="col-sm-12 col-lg-7 p-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-6 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-hotel-destination-input (setOriginEvent)="setHotelDest($event)"
                                           [inputValue]=hotelDest></app-hotel-destination-input>
            </div>
          </div>
        </div>
        <div class="col-3 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-check-in-input
                (openDesktopCalenderEvent)="openDesktopCalender($event)"
                [departureDate]=departureDate></app-check-in-input>
            </div>
          </div>
        </div>
        <div class="col-3 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-check-out-input
                (openDesktopCalenderEvent)="openDesktopCalender($event)"
                [returnDate]=returnDate></app-check-out-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-5 p-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-8 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-travellers-classes-input
                [totalTravellers]=travellers
                [adult]=adult [child]=child [rooms]="rooms" [hotelStatus]="true"
                (openTravellersDialogEvent)="openTravellersDesktopDialog()">
              </app-travellers-classes-input>
            </div>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="row">
            <div class="inputContainer submitBtn">
              <button (click)="submit() " class="">Search<i *ngIf="loadingSpinner" class="fas fa-spinner fa-spin"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div  class="mt-4">
      <a [routerLink]="['/addhotels/']" style="color: orangered;"> Join 100 Hotels over the world . JOIN US </a>
    </div> -->
  </div>
</div>
