import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
// import * as moment from 'moment';

@Component({
  selector: 'app-my-ticket-dash-board',
  templateUrl: './my-ticket-dash-board.component.html',
  styleUrls: ['./my-ticket-dash-board.component.css']
})
export class MyTicketDashBoardComponent implements OnInit {
  // inputs & outputs
  @Input() viewTicketElement: number;
  @Input() ticketCategory: string;
  @Output() viewBookings = new EventEmitter();
  //  local variables
  chargebaggageAllowance;
  flightNumbersArray = [];
  freeBaggageAllowance;
  operatingAirlines = [];
  ticket;
  trip_type;

  constructor(private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    // getting the upcoming/ completed bookings data form the localStorage
    if (this.ticketCategory === 'upcomingBookings') {

      this.ticket = this.localStorage.getItem('upcomingBookings');

    } else if (this.ticketCategory === 'completedBookings') {

      this.ticket = this.localStorage.getItem('completedBookings');
      
    }
    
    this.ticket = JSON.parse(this.ticket);
    this.ticket = this.ticket[this.viewTicketElement]; // element of the array based on a input from the view button in mybookings comp  
    // initializing some variables to be used in the template
    this.trip_type = this.ticket?.trip_type;
    this.freeBaggageAllowance = this.ticket?.baggage_info[0];
    this.chargebaggageAllowance = this.ticket?.baggage_info[1];

    for (let i = 0; i < this.ticket?.flight_details.length; i++) {// added [0]
      for (let ii = 0; ii < this.ticket?.flight_details[i].length; ii++) {
        this.operatingAirlines.push({ photo: this.ticket?.flight_details[i][ii].OperatingAirline });
        this.flightNumbersArray.push(this.ticket?.flight_details[i][ii].FlightNumber);
      }
    }

  }
  getDateString(date) {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return (`${days[new Date(date).getDay()].substring(0, 3)} , ${months[new Date(date).getMonth()].substring(0, 3)} ${new Date(date).getDate()} , ${new Date(date).getFullYear()}`);
  }
  getDurationString(minutes) {
    return (`Duration ${Math.floor(minutes / 60)}h ${minutes % 60}m`);
  }
  // getTimeString(date) {
  //   var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //   var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  //   return (`
  //   ${
  //     (moment(date).hours()) > 12 ? (moment(date).hours()) - 12 : (moment(date).hours())
  //     }:${
  //     this.zero(moment(date).minutes()) + moment(date).minutes().toString()
  //     } ${
  //     (moment(date).hours()) >= 12 ? 'PM' : 'AM'
  //     }
  //   `);
  // }
  layoverTime(num) {
    if (num > 0) {
      let h = Math.floor(num / 60);
      let m = num % 60;
      return h + 'hrs ' + m + ' mins';
    } else {
      return num;
    }
  }

  zero(num) { return (num < 10 ? '0' : ''); }

}
