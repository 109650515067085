<div class="create-account">
    <div class="signup">
        <h4 *ngIf="errorMessage" class="error text-center">{{errorMessage}}</h4>
        <h1 *ngIf="successMessage" class="success text-center">{{successMessage}}</h1>
        <div class="row">
            <div class="col">
                <div><label for="firstName">First Name <span class="required">*</span></label></div>
                <div><input type="text" name="firstName" id="firstName" [(ngModel)]="firstName" autocomplete="off" /></div>
            </div>
            <div class="col">
                <div><label for="lastName">Last Name <span class="required">*</span></label></div>
                <div><input type="text" name="lastName" id="lastName" [(ngModel)]="lastName" autocomplete="off" /></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div><label for="email">Email <span class="required">*</span></label></div>
                <div><input type="email" name="email" id="email" [(ngModel)]="email" autocomplete="off" /></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div><label for="password">Password <span class="required">*</span></label></div>
                <div class="container-show-hide-password"><input type="{{typePassword}}" name="password" id="password" [(ngModel)]="password" autocomplete="off" /><span (click)="showHidePassword()" class="show-hide-password"><i *ngIf="typePassword == 'password'" class="far fa-eye"></i> <i *ngIf="typePassword == 'text'" class="far fa-eye-slash"></i></span></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div><label for="confirmPassword">Confirm Password <span class="required">*</span></label></div>
                <div class="container-show-hide-password"><input type="{{typePassword}}" name="confirmPassword" id="confirmPassword" [(ngModel)]="confirmPassword" autocomplete="off" /><span (click)="showHidePassword()" class="show-hide-password"><i *ngIf="typePassword == 'password'" class="far fa-eye"></i> <i *ngIf="typePassword == 'text'" class="far fa-eye-slash"></i></span></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div>
                  <label for="country">Country <span class="required">*</span></label>
                </div>
                <div>
                  <select [(ngModel)]="country" class="countries" id="country">
                    <option [value]="country.Code" *ngFor="let country of countries">{{country.Name}}</option>
                  </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="submit" (click)="submitSignUp()">Sign Up <i *ngIf="signupSpinner" class="fas fa-spinner fa-spin"></i></button>
            </div>
        </div>
    </div>
</div>

