import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  Inject,
} from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { BlogService } from "src/app/services/blog/blog.service";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { MetaTagsService } from "src/app/services/meta-tags.service";
import { HotelsApisService } from "src/app/services/hotels-apis.service";
import { HotelSearchComponent } from "src/app/components/hotel-search/hotel-search.component";
import { LocalStorageService } from "src/app/services/webApis/local-storage.service";
import { AppComponent } from "src/app/app.component";
import { SEOService } from "../../services/seo.service";
import { FroalaEditorStylesService } from "../../services/froala-editor-styles/froala-editor-styles.service";

@Component({
  selector: "app-things-todo",
  templateUrl: "./things-todo.component.html",
  styleUrls: ["./things-todo.component.css"],
})
export class ThingsTodoComponent implements OnInit {
  // this component is viewed to update search fields data
  @ViewChild(HotelSearchComponent)
  private hotelSearchComponent: HotelSearchComponent;

  transformedDescription;
  articleSlug: string; // this is what we send to the api to get certain article
  // articleContent;
  data;
  loading: boolean = true;
  typeSearch: string = "round";
  posts = [" "]; // this is an array of length 1, because an array of length 0 will indicate having an error
  // testP = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua'
  carouselOptions: OwlOptions = {
    navText: [
      "<i class='arrow arrowNext fa fa-chevron-left'></i>",
      "<i class='arrow arrowPrev fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 },
    },
    items: 1,
    loop: true,
    dots: false,
    nav: true,
  };
  close_status: boolean = true;
  linkToBeShared: string;
  fragment: string;
  moreHeaders: boolean = false;
  hostUrl: string;
  routerUrl: string;

  constructor(
    private seoService: SEOService,
    private route: ActivatedRoute,
    private blogService: BlogService,
    private metaService: MetaTagsService,
    private title: Title,
    private router: Router,
    private hotelApis: HotelsApisService,
    private sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private froalaStyles: FroalaEditorStylesService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.seoUpdate();
  }

  seoUpdate(title = null, description = null, image = null) {
    // this.seoService.updateTitle(this.router.url);
    // this.seoService.updateDescription(this.router.url);
    // this.seoService.updateUrl();
    // this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');
    // console.log("asaid -> title " + title);
    if (title != null) {
      this.seoService.updateTitle(title);
    }
    // console.log("asaid -> description " + description);
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    // console.log("asaid -> image " + image);
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }

  ngOnInit(): void {
    // console.log("asaid things to do entered ");
    this.froalaStyles.addFroalaStylesFile();
    const hostUrl = AppComponent.hostUrl;
    this.hostUrl = hostUrl;
    this.routerUrl = this.router.url;
    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
    this.route.paramMap.subscribe((params) => {
      this.articleSlug = params.get("slug");
      if (this.articleSlug) {
        this.linkToBeShared = `${hostUrl}${this.routerUrl}`;
        this.getArticle();
      }
    });
  }

  getArticle() {
    this.loading = true;
    this.blogService.getArticle(this.articleSlug).subscribe(
      (res: any) => {
        this.data = res.data;
        this.posts = this.data?.people_also_read;
        this.seoUpdate(
          this.data?.meta_data?.data?.title,
          this.data?.meta_data?.data?.description,
          this.data?.meta_data?.data["og:image"]
        );
        this.loading = false;
      },
      (err) => {
        this.data = undefined;
        this.posts = [];
        this.loading = false;
      }
    );
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // const pageScrollPosition = this.document.documentElement.scrollTop;
    const topOfArticle = this.document
      .getElementById("article")
      .getBoundingClientRect().top;
    const bottomOfArticle = this.document
      .getElementById("article")
      .getBoundingClientRect().bottom;
    const socialListElement = this.document.getElementById("social");
    const headersTop = this.document
      .getElementById("headers")
      .getBoundingClientRect().top;
    const articleContainerBottom = this.document
      .getElementById("article-container-bottom")
      .getBoundingClientRect().bottom;
    const sidebarElement = this.document.getElementById("sidebar");
    const sideBarHeight = this.document.getElementById("sidebar").clientHeight;
    /* if the position of top of the article is smaller -20 , that means that the article is in view,
     * then add sticky class
     * - at the same time make sure that the bottom of the artcile is still in view
     */
    //  adding sticky class to social list
    if (socialListElement) {
      if (topOfArticle < -20 && bottomOfArticle > 300) {
        socialListElement.classList.add("sticky");
      } else {
        socialListElement.classList.remove("sticky");
      }
    }
    // adding sticky class to search component
    if (sidebarElement) {
      // 150 is the height of article footer
      // 21 is the space between the top of the screen and header's top
      if (
        headersTop < 21 &&
        topOfArticle < 0 &&
        articleContainerBottom > 150 &&
        articleContainerBottom > sideBarHeight
      ) {
        sidebarElement.classList.add("sticky");
      } else {
        sidebarElement.classList.remove("sticky");
      }
    }
  }

  // meta data methods
  addMetaScript(script: string) {
    this.metaService.addMetaScript(script);
  }

  modifyMetaTags(metaData) {
    this.metaService.modifyMetaTags(metaData);
  }

  sanitizeHtml(injectedHtml) {
    return this.sanitizer.bypassSecurityTrustHtml(injectedHtml);
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
