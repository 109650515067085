import {Component, OnInit} from '@angular/core';
import {SEOService} from '../../services/seo.service';
import {Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-visa',
    templateUrl: './visa.component.html',
    styleUrls: ['./visa.component.css']
})

export class VisaComponent implements OnInit {
    faqs: any;
    response: any;
    copied: boolean =false;
    close_status: boolean = true;

    constructor(
        private seoService: SEOService, private router: Router, private clipboard: Clipboard,
    ) {
    }

    ngOnInit(): void {
        this.seoUpdate();
    }

    seoUpdate() {
        this.seoService.updateTitle('FlyAllOver | Your One Stop Visa Shop!');
        this.seoService.updateDescription('Need a visa for your next trip? flyallover.com can help! We work with ' +
            'CIBTvisas to provide visas for over 200 countries, and we can help you get the best deal on airfare too.');
        this.seoService.updateUrl();
        this.seoService.updateImage('https://flyallover.com/assets/images/visa/bg.png');
    }

    fb() {
        let url = 'www.google.com';
        var faceboookWindow = window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + 'www.flyallover.com' + this.router.url,
            'facebook-popUp',
            'width=600px,height=350px'
        );
        if (faceboookWindow.focus) {
            faceboookWindow.focus();
        }
        return false;
    }

    tw() {
        let url = 'www.google.com';
        var twitterWindow = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            'twitter-popUp',
            'width=600px,height=350px'
        );
        if (twitterWindow.focus) {
            twitterWindow.focus();
        }
        return false;
    }

    cop() {

        this.clipboard.copy('https://www.flyallover.com' + this.router.url);
        this.copied=!this.copied;

    }

    in() {
        let url = 'www.google,com';
        var linkedWindow = window.open(
            'https://www.linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            'linked-popUp',
            'width=600px,height=350px'
        );
        if (linkedWindow.focus) {
            linkedWindow.focus();
        }
        return false;
    }
}
