import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-sign-up',
  templateUrl: './help-sign-up.component.html',
  styleUrls: ['./help-sign-up.component.css']
})
export class HelpSignUpComponent implements OnInit {

  constructor(private seoService: SEOService) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }

  seoUpdate() {

    this.seoService.updateTitle('Help Sign Up | FlyAllOver');
    this.seoService.updateDescription('Help Sign Up | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
