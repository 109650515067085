import { Component, OnInit } from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-flight-page',
  templateUrl: './flight-page.component.html',
  styleUrls: ['./flight-page.component.css']
})
export class FlightPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  carouselOptions: OwlOptions = {
    navText: ["<i class='arrow arrowNext fa fa-chevron-left'></i>", "<i class='arrow arrowPrev fa fa-chevron-right'></i>"],
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      992: { items: 3 }
    },
    items: 3,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };
}
