import { DatePipe } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-review-flight-fare-rules',
  templateUrl: './review-flight-fare-rules.component.html',
  styleUrls: ['./review-flight-fare-rules.component.css']
})
export class ReviewFlightFareRulesComponent implements OnInit {
  @Input() itineraries: any;
  @Input() title: any =true;
  constructor() { }

  ngOnInit(): void {

  }

}
