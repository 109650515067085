<app-navbar></app-navbar>
<!-- start header-more-offers -->
<div class="header-blog">
  <div class="overlay">
    <div class="container">
      <h1 class=" fao_mobile_title_large text-center" style="color: #fff !important; font-size: 35px !important;">{{ title }}</h1>
    </div>
  </div>
</div>

<!-- <div class="search-box">
  <input type="text" [(ngModel)]="word" placeholder="Search here..."/>
  <button>
    <img
      title="Search about trip"
      alt="search"
      src="./assets/images/blog/search.svg"
      width="30"
    />
  </button>
</div> -->

<div class="container">
    <div class="row icon-box my-5">
      <div class="col-md-7 ">
        <div class="">
          <!-- <div class="day" style="width: 9% !important;">
            <h4> Details </h4>
          </div> -->
          <h1><a >{{datashare.title}}</a></h1>
          <p [innerHTML]="datashare.description"></p>
        </div>
        
        </div>
        <div class="col-md-5">
          <ngb-carousel>
            <ng-template ngbSlide *ngFor="let itemImg of datashare.images ; let i = index">
              <div class="picsum-img-wrapper">
                <img
                  src="{{itemImg.full_url}}"
                  alt="{{itemImg.file_name}}"
                  style="border-radius: 12px ; object-fit: cover;"
                  height="500px"
                  width="100%"
                />
              </div>
            
            </ng-template>
          </ngb-carousel>

          <!-- <img
                      src="{{ datashare.image}}"
                      alt="{{datashare.title}}"
                      class=" rounded w-100 mt-5"
                      style="object-fit: cover;"
                      width="100%"
                      height="90%"
                    /> -->
        </div>
    </div> 
    <div class="row icon-box my-5 " *ngFor="let item of data ; let i = index">
    
      <div class=" col-md-8  mt-4" data-aos="zoom-in" data-aos-delay="100"  >
        <div class=""  >
          <div class="day" *ngIf="item.day">
            <h4 > Day : {{item.day}}</h4>
            <!-- <h5>{{ item.country.name}} - {{item.city.name}}</h5> -->    
          </div>
        
          <h1 style="font-weight: 700;"><a>{{item.title}}</a></h1>
          <p > {{ item.description  }}</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <img
        src="{{ item.image}}"
        alt="{{item.title}}"
        class=" rounded w-100 mt-5"
        style="object-fit: cover;"
        width="100%"
        height="90%"
      />

    </div>
    </div>
    <div class="row icon-box mb-5" *ngIf="data">
      <h2 class="text-center ">Interested ? Want more details? Join our wishlist for exclusive updates! </h2>
      <form [formGroup]="formData" (submit)="onSubmit()">
        <div class="row">
          <div class="mb-3 form-group  col-md-6  col-sm-12">
            <label for="fullname" class="form-label"> name </label>
            <input type="text"  formControlName="name" class="form-control" id="fullname" placeholder="name" required >
          </div>
          <div class="mb-3 form-group  col-md-6  col-sm-12">
            <label for="email" class="form-label"> email   </label>
            <input type="email"  formControlName="email" class="form-control" id="email" placeholder="email" required >
          </div>
        </div>
      
        <div class="mb-3 form-group  col-md-12  col-sm-12">
          <label for="phone" class="form-label"> phone </label>
          <input type="text"  formControlName="phone" class="form-control" id="phone" placeholder="phone" required >
        </div>
        <div class="d-flex justify-content-center  mt-5">
          <button class="btn fao_mobile_btn_meduim " type="submit" [disabled]="formData.invalid"> send </button>
          <!--      <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>-->
        </div>
      </form>

    

    </div>

    <div class="row w-50 mx-auto">
      <div class="item follow Font-Family pl-3 my-5 px-3">
                    
        <ul class=" my-2 font-icon">
          <li><h6 class="font-span_1 ">Share Now : </h6></li>
          <li>
            <a class="social-icon image-item" (click)="cop()" target="_blank">
              <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                src="./assets/newIcon/copy-blue-icon.svg" />
              <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                src="./assets/newIcon/copied.png" />
            </a>
          </li>
          <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                class="fab fa-facebook-f"></i></a></li>
          <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                class="fab fa-twitter"></i></a></li>
          <li><a (click)="linked()" target="_blank" rel="nofollow"
              href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
        </ul>
    </div>
    </div>
  
</div>



<app-footer></app-footer>
