import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SEOService} from '../../services/seo.service';


@Component({
  selector: 'app-more-offers',
  templateUrl: './more-offers.component.html',
  styleUrls: ['./more-offers.component.css']
})
export class MoreOffersComponent implements OnInit {
  word = '';
  showResultSearch: boolean = false;
  searchInput: string = '';
  page: any =0;
  pageSize: number = 9;
  data: any = [];
  about: any;
  collectionSize: number;


  constructor(
    private apis: ApisService,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.getOffers(this.page);
  }
  changePage(page: number) {
    this.data = undefined;
    this.getOffers(page);
  }
  getOffers(pageNumber =1) {
    // console.log(pageNumber)
    if (typeof document !== 'undefined') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.apis.paginationOffers(pageNumber + 1).subscribe((data: any) => {
        this.data = data.data.data.reverse();
        this.collectionSize = data.data.total;
        this.pageSize = data.data.per_page;
        this.page = data.data.current_page;
        if (typeof document !== 'undefined') {
          localStorage.setItem('pageNumber', this.page);
        }
        // console.log(data.data)
      },
      err => {
        console.log(err);
      });
  }

  seoUpdate() {
    this.seoService.updateTitle('FlyAllOver | Best offers For Flights');
    this.seoService.updateDescription('Get the latest FlyAllOver coupons offers and save your money on your vacation.');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/offers/offers.jpg');
  }

}
