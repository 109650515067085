<!-- start share-popup -->
<div class="share-box-container" [ngClass]="{'close': close_status}">
    <div class="container">
        <div class="share-box">

            <div class="share-close text-right">
                <i class="fas fa-times" (click)="close_status = true; close.emit()"></i>
            </div>

            <div *ngIf="viewSocialLinks" class="row share-social">
                <div class="col">
                    <h3>Share On</h3>
                </div>
                <div class="col text-right">
                    <ul>
                        <li><a rel="nofollow" href="https://www.facebook.com/sharer/sharer.php?u={{linkToBeShared}}" target="_blank"><i
                                    class="fab fa-facebook-f"></i></a></li>
                        <li><a rel="nofollow" href="https://twitter.com/intent/tweet?url={{linkToBeShared}}" target="_blank"><i
                                    class="fab fa-twitter"></i></a></li>
                        <!-- <li><a href="#"><i class="fab fa-instagram"></i></a></li> -->
                        <li><a rel="nofollow" href="https://www.linkedin.com/shareArticle?mini=true&url={{linkToBeShared}}&title={{ linkedinTitle }}"
                                target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="share-link">
                <label>Link</label>
                <input type="text" #copy value="{{linkToBeShared}}" readonly />
            </div>

            <div class="share-copy text-right">
                <button (click)="copyMessage(linkToBeShared, copy)">Copy <span class="copy-done"
                        [ngClass]="{'done': copy_status}">Copied!</span></button>
            </div>

        </div>
    </div>
</div>
<!-- end share-popup -->
