import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApisService} from '../../../services/apis.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-add-traveller',
  templateUrl: './review-flight-add-traveller.component.html',
  styleUrls: ['./review-flight-add-traveller.component.css']
})

export class ReviewFlightAddTravellerComponent implements OnInit {

  filteredGender: Observable<string[]>;
  filteredPassportCountries: Observable<string[]>;
  filteredPassportNationalities: Observable<string[]>;
  filteredPassportIssuedCountries: Observable<string[]>;
  filteredPersonalCountries: Observable<string[]>;
  filteredPersonalNationalities: Observable<string[]>;
  form: FormGroup;
  passportCountries: any;
  passportNationalities: any;
  passportIssuedCountries: any;
  personalCountries: any;
  personalNationalities: any;
  relations: any;
  genders: any = ['Female', 'Male'];

  constructor(
    private apis: ApisService,
    private seoService: SEOService,
    private dialogRef: MatDialogRef<ReviewFlightAddTravellerComponent>,
  ) {
    this.seoUpdate();
  }

  ngOnInit() {
    this.form = this.initForm();
    this.getCountries();
    this.getFamilyRelations();
    this.gendersListener();
  }

  seoUpdate() {
    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  gendersListener() {
    this.filteredGender = this.form.controls.gender.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGenders(value)),
      );
  }

  getFamilyRelations() {
    this.apis.familyRelations().subscribe((data: any) => {
        this.relations = data.data;
      },
      err => {
        console.log(err);
      });
  }

  getCountries() {
    this.apis.getCountries().subscribe((data: any) => {
      this.passportCountries = data;
      this.passportNationalities = data;
      this.passportIssuedCountries = data;
      this.personalCountries = data;
      this.personalNationalities = data;
      this.filteredPassportCountries = this.form.controls.passportCountry.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPassportCountries(value)),
        );
      this.filteredPassportNationalities = this.form.controls.passportNationality.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPassportNationalities(value)),
        );
      this.filteredPassportIssuedCountries = this.form.controls.passportIssuedCountry.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPassportIssuedCountries(value)),
        );
      this.filteredPersonalCountries = this.form.controls.travellerCountry.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPersonalCountries(value)),
        );
      this.filteredPersonalNationalities = this.form.controls.travellerNationality.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPersonalNationalities(value)),
        );
    }, err => {
      console.log(err);
    });
  }

  private _filterGenders(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.genders.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterPassportCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.passportCountries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterPassportNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.passportNationalities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterPassportIssuedCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.passportIssuedCountries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterPersonalCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.personalCountries.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterPersonalNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.personalNationalities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  initForm(): FormGroup {
    return new FormGroup({
      GivenName: new FormControl('', [Validators.required]),
      MiddleName: new FormControl('', ),
      Surname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.minLength(8)]),
      travellerDOB: new FormControl('', [Validators.required]),
      passportNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      expiryDate: new FormControl('', [Validators.required]),
      issueDate: new FormControl('', [Validators.required]),
      travellerCountry: new FormControl('', Validators.required),
      travellerNationality: new FormControl('', Validators.required),
      passportCountry: new FormControl('', Validators.required),
      passportNationality: new FormControl('', Validators.required),
      passportIssuedCountry: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      familyRelationId: new FormControl('', []),
    });
  }
  initTravellerObject(): any {
    const traveller: any = {};
    traveller.family_relation_id = this.form.value.familyRelationId;
    traveller.PassengerType = 'ADT';
    traveller.phone_type = 'H';
    traveller.GivenName = this.form.value.GivenName;
    traveller.MiddleName = this.form.value.MiddleName;
    traveller.Surname = this.form.value.Surname;
    traveller.phone = this.form.value.phone;
    traveller.traveller_dob = this.convertDate(String(this.form.value.travellerDOB));
    traveller.passport_number = this.form.value.passportNumber;
    traveller.expiry_date = this.convertDate(String(this.form.value.expiryDate));
    traveller.issue_date = this.convertDate(String(this.form.value.issueDate));
    // custom attributes
    traveller.nationality_id = this.getCountryObject(this.form.value.travellerNationality).id;
    traveller.nationality_code = this.getCountryObject(this.form.value.travellerNationality).code;
    traveller.country_id = this.getCountryObject(this.form.value.travellerCountry).id;
    traveller.country_code = this.getCountryObject(this.form.value.travellerCountry).code;
    traveller.countryPassport = this.getCountryObject(this.form.value.passportCountry).code;
    traveller.nationality = this.getCountryObject(this.form.value.passportNationality).code;
    traveller.issuedCountry = this.getCountryObject(this.form.value.passportIssuedCountry).code;
    traveller['country-code'] = this.getCountryObject(this.form.value.travellerCountry).code;
    if (this.form.value.gender === 'Male') {
      traveller.traveller_gender = 'M';
    }
    if (this.form.value.gender === 'Female') {
      traveller.traveller_gender = 'F';
    }
    return traveller;
  }

  addTraveller(): any {
    this.dialogRef.close(this.initTravellerObject());
  }

  getCountryObject(country): any {
    const index = this.passportCountries.findIndex(obj => obj.name === country);
    if (index > -1) {
      return this.passportCountries[index];
    }
  }

  removeRequiredFormDisplayedErrors(errors): any {
    const index = errors.findIndex(obj => obj === 'required');
    if (index > -1) {
      errors = errors.splice(index, 1);
      return errors;
    } else {
      return errors;
    }
  }

  convertDate(d) {
    const parts = d.split(' ');
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };
    return parts[3] + '-' + months[parts[1]] + '-' + parts[2];
  }

}


