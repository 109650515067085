<app-navbar></app-navbar>

<div class="hotels-page" id="hotelsPage">
  <!-- start breadcrumb -->
  <div class="container">
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/hotel-results']">Search</a>
        </li>
        <li class="breadcrumb-item active">Reserve Your Hotel</li>
      </ol>
    </nav>
  </div>
  <!-- end breadcrumb -->

  <div class="container">
    <h1 class="hotels-page-title">This offer Valid From 30 June To 30 July</h1>
  </div>

  <!-- start content -->
  <div class="container">
    <h1 class="percentage">20% off</h1>
    <div class="row">
      <div class="col-lg results">
        <!-- [RESULTS] -->

        <!-- start hotel -->
        <div class="hotel">
          <div class="hotel-header">
            <div class="row">
              <div class="col">
                <h6>
                  Bali Hotel in Sri Lanka
                  <span class="rate rate3"
                    ><i class="fas fa-star"></i><i class="fas fa-star"></i
                    ><i class="fas fa-star"></i><i class="fas fa-star"></i
                    ><i class="fas fa-star"></i
                  ></span>
                </h6>
                <p>
                  <span
                    ><i class="fas fa-map-marker-alt"></i> Marsa Alam City -
                    Show Map
                  </span>
                  43 km from center - 350 m from subway station
                </p>
              </div>
              <div class="col col-icons text-right">
                <span (click)="close_status = false"
                  ><i class="fas fa-share"></i> Share
                </span>
                <span><i class="far fa-heart"></i> Save </span>
              </div>
            </div>

            <!-- start share-box -->
            <div
              class="share-box-container"
              [ngClass]="{ close: close_status }"
            >
              <div class="container">
                <div class="share-box mx-auto">
                  <div class="share-close text-right">
                    <i class="fas fa-times" (click)="close_status = true"></i>
                  </div>

                  <div class="row share-social">
                    <div class="col">
                      <h3>Share On</h3>
                    </div>
                    <div class="col text-right">
                      <ul>
                        <li>
                          <a href="#"><i class="fab fa-facebook-f"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fab fa-instagram"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fab fa-linkedin-in"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="share-link">
                    <label for="link">Link</label>
                    <input
                      id="link"
                      type="text"
                      #copy
                      value="{{ linkShare }}"
                      readonly
                    />
                  </div>

                  <div class="share-copy text-right">
                    <button (click)="copyMessage(linkShare, copy)">
                      Copy
                      <span class="copy-done" [ngClass]="{ done: copy_status }"
                        >Copied!</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end share-box -->
          </div>

          <div class="hotel-photos">
            <div class="photos" gallerize>
              <div
                class="photo"
                (click)="openGallery($event, i)"
                *ngFor="let img of imageData; let i = index"
                [ngClass]="{ count: i == 4 }"
                data-count="+100"
              >
                <img title="Hotel Image" alt="hotel photo" [src]="img.srcUrl" />
              </div>
            </div>
          </div>

          <div class="hotel-details">
            <div class="description">
              <div class="row">
                <div class="col">
                  <h3>Description</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </p>
                </div>
                <div class="col col-rev text-right">
                  <label class="rev"><span>9.8</span> 1047 Reviews</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end hotel -->
      </div>

      <div class="col-lg sidebar">
        <!-- [SIDEBAR] -->

        <!-- start modify -->
        <div class="benefit row" id="benefit">
          <div class="items">
            <div class="item departure_return">
              <label class="small-title" for="date1">Date</label>
              <div class="row">
                <div class="col">
                  <div class="date">
                    <!-- ADD VALIDATION MIN, MAX AND DISABLED IN CASE THIS DATE PICKER IS USED -->
                    <input
                      matInput
                      (click)="departure.open()"
                      [matDatepicker]="departure"
                      [min]="minDate"
                      readonly
                    />
                    <mat-datepicker-toggle [for]="departure">
                      <mat-icon matDatepickerToggleIcon
                        ><img
                          title="Departure"
                          alt="calendar"
                          src="./assets/images/offers/calendar.svg"
                          class="img-icon"
                          width="18"
                      /></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #departure></mat-datepicker>
                  </div>
                </div>

                <div class="col">
                  <div class="date">
                    <!-- ADD VALIDATION MIN, MAX AND DISABLED IN CASE THIS DATE PICKER IS USED -->
                    <input
                      (click)="return.open()"
                      [matDatepicker]="return"
                      [min]="minDate"
                      readonly
                    />
                    <mat-datepicker-toggle [for]="return">
                      <mat-icon matDatepickerToggleIcon
                        ><img
                          title="Return"
                          alt="calendar"
                          src="./assets/images/offers/calendar.svg"
                          class="img-icon"
                          width="18"
                      /></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #return></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="item">
              <label class="small-title" for="Guets">Guets</label>
              <select id="Guets">
                <option value="1">1 Adults</option>
                <option value="2" selected>2 Adults</option>
                <option value="3">3 Adults</option>
              </select>
            </div>

            <div class="item select-btn">
              <button class="search">SElECT</button>
            </div>

            <div class="item">
              <ul class="list-unstyled benefit-ul">
                <li class="lon">
                  <label>Discount<span>20% off</span></label>
                </li>
                <li>
                  <label>Service fee<span>$ 12.85</span></label>
                </li>
                <li>
                  <label>Service fee<span>$ 12.85</span></label>
                </li>
                <li><label>Additional charges may apply</label></li>
              </ul>
              <ul class="list-unstyled benefit-ul">
                <li>
                  <p>Breakfast included</p>
                </li>
                <li>
                  <p>FREE cancellation</p>
                </li>
                <li>
                  <p>No prepayment needed</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end content -->

  <!-- start offers-posts -->
  <div class="offers-posts">
    <div class="container">
      <h2>Related Offers</h2>
      <div class="row">
        <div class="col">
          <a href="#" class="post">
            <div class="post-photo">
              <h1 class="percentage">20% off</h1>
              <h3>America</h3>
              <img
                title="Related Offers"
                alt="offer"
                src="./assets/images/offers/post1.png"
                class="img-fluid"
              />
            </div>
            <div class="post-description">
              <h4>America</h4>
              <p>Book a flight from bristol (BRS) to Valencia (VLS).</p>
              <label
                ><i class="far fa-clock"></i> From 30 june to 30 july</label
              >
            </div>
          </a>
        </div>

        <div class="col">
          <a href="#" class="post">
            <div class="post-photo">
              <h1 class="percentage">20% off</h1>
              <h3>Bali</h3>
              <img
                title="Related Offers"
                alt="offer"
                src="./assets/images/offers/post2.png"
                class="img-fluid"
              />
            </div>
            <div class="post-description">
              <h4>Bali hotel</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod...
              </p>
              <label
                ><i class="far fa-clock"></i> From 30 june to 30 july</label
              >
            </div>
          </a>
        </div>

        <div class="col">
          <a href="#" class="post">
            <div class="post-photo">
              <h1 class="percentage">20% off</h1>
              <h3>America</h3>
              <img
                title="Related Offers"
                alt="offer"
                src="./assets/images/offers/post1.png"
                class="img-fluid"
              />
            </div>
            <div class="post-description">
              <h4>America</h4>
              <p>Book a flight from bristol (BRS) to Valencia (VLS).</p>
              <label
                ><i class="far fa-clock"></i> From 30 june to 30 july</label
              >
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end offers-posts -->
</div>
<!-- start hotels page -->

<app-footer></app-footer>
