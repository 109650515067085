import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import {map, startWith} from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-edit-passport',
  templateUrl: './edit-passport.component.html',
  styleUrls: ['./edit-passport.component.css']
})
export class EditPassportComponent implements OnInit {
  @Input() id: any;
  @Input() memberName: any;
  @Input() memberRelation: any;
  @Input() familyMembers: any;
  @Output() closeForm = new EventEmitter();
  @Output() reloadFamilyMember = new EventEmitter();

  passportId: any;
  token: any;
  name: any;
  passportForm: any;
  countries: any;
  personalCountries:any;
  personalNationalities:any;
  filteredPersonalCountries: Observable<string[]>;
  filteredPersonalNationalities: Observable<string[]>;
  filteredPersonalIssuedCountries:Observable<string[]>;
  passportDateError = false;
  loadingSpinner: boolean = false;
  deleteSpinner: boolean = false;
  constructor(private apis: ApisService, private cookie: CookieService, private router: Router, public datePipe: DatePipe,
              @Inject(DOCUMENT) private document: Document, private matDialog: MatDialog,
              private _LocalStorageService:LocalStorageService) {
    this.passportForm = this.createComposePassportForm();
  }

  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: false,
    merge: true,
  };

  ngOnInit(): void {
    this.getCountries();
    this.token = this._LocalStorageService.getItem('flyallover_token');
    this.getAccountInfo();
    this.getPassportInfo();
  }
  private _filterPersonalCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.personalCountries.filter(option => option.name.toLowerCase().includes(filterValue)).length == 0) {
      this.passportForm.controls['countryPassport'].setErrors({
        'incorrect': true,
        someProp: 'Country Not Found Please Select Valid Country'
      });
    } else {
      return this.personalCountries.filter(option => option.name.toLowerCase().includes(filterValue));
    }
  }

  private _filterPersonalNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.personalCountries.filter(option => option.name.toLowerCase().includes(filterValue)).length == 0) {
      this.passportForm.controls['nationalityPassport'].setErrors({
        'incorrect': true,
        someProp: 'Nationality Not Found Please Select Valid Country'
      });
    } else {
      return this.personalNationalities.filter(option => option.name.toLowerCase().includes(filterValue));
    }
  }
  private _filteredPersonalIssuedCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.personalCountries.filter(option => option.name.toLowerCase().includes(filterValue)).length == 0) {
      this.passportForm.controls['issuedCountry'].setErrors({
        'incorrect': true,
        someProp: 'Nationality Not Found Please Select Valid Country'
      });
    } else {
      return this.personalNationalities.filter(option => option.name.toLowerCase().includes(filterValue));
    }
  }
  getCountryObject(country): any {
    const index = this.countries.findIndex(obj => obj.name === country);
    if (index > -1) {
      return this.countries[index];
    }
  }
  getCountries() {
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
      this.personalCountries = data;
      this.personalNationalities = data;
      this.filteredPersonalCountries = this.passportForm.controls.countryPassport.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPersonalCountries(value.toString())),
        );
      this.filteredPersonalNationalities  =  this.passportForm.controls.nationalityPassport.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterPersonalNationalities(value.toString())),
        );
        this.filteredPersonalIssuedCountries =  this.passportForm.controls.nationalityPassport.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filteredPersonalIssuedCountries(value.toString())),
        );
    }, err => {
      console.log(err);
    });
  }
  // getCountries(){
  //   this.apis.getCountries().subscribe((data: any) => {
  //     this.countries = data;
  //     console.log(this.countries);
  //     this.countries = this.passportForm.controls.country_id.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this.passportForm(value)),
  //     );
  //   }, err => {
  //   });
  // }
  getAccountInfo(){
    this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.name = data.data.first_name.toUpperCase() + ' ' + data.data.last_name.toUpperCase();
      },
      err => {
        console.log(err);
      });
  }
  createComposePassportForm() :FormGroup {
    return new FormGroup({
      familyMemberId: new FormControl(''),
      passportNumber: new FormControl('', Validators.required),
      issueDate: new FormControl('', Validators.required),
      expireDate: new FormControl('', Validators.required),
      countryPassport: new FormControl('', Validators.required),
      nationalityPassport: new FormControl('', Validators.required),
      issuedCountry: new FormControl('', Validators.required),
    });
  }
  editMemberPassport() {
    if (Date.parse(this.passportForm.value.expireDate) > Date.parse(this.passportForm.value.issueDate) && Date.parse(this.passportForm.value.expireDate) > Date.now()) {
      this.passportForm.value.familyMemberId = this.id;
      this.loadingSpinner = true;
      this.passportForm.value.countryPassport=this.getCountryObject(this.passportForm.value.countryPassport).id
      this.passportForm.value.issuedCountry=this.getCountryObject(this.passportForm.value.issuedCountry).id
      this.passportForm.value.nationalityPassport=this.getCountryObject(this.passportForm.value.nationalityPassport).id
      this.apis.editFamilyMemberPassport(
        this.passportForm.value.familyMemberId,
        this.passportForm.value.passportNumber,
        this.passportForm.value.issueDate,
        this.passportForm.value.expireDate,
        this.passportForm.value.countryPassport,
        this.passportForm.value.nationalityPassport,
        this.passportForm.value.issuedCountry,
        this.passportId,
        this.token
      ).subscribe((data: any) => {
        this.reloadFamilyMember.emit()
        this.loadingSpinner = false;
        },
        err => {
          this.loadingSpinner = false;
          console.log(err);
        });
    } else {
      this.passportDateError = true;
    }
  }
  getPassportInfo() {
    for (let member of this.familyMembers) {
      if (member.id == this.id && member.passport) {
        console.log('member => edit passbort',member)
        this.passportId = member.passport.id;
        this.passportForm.controls.passportNumber.value = member.passport.passport_number;
        this.passportForm.controls.issueDate.value = member.passport.issue_date;
        this.passportForm.controls.expireDate.value = member.passport.expire_date;
        this.passportForm.controls.countryPassport.value = member.passport.country.name;
        this.passportForm.controls.nationalityPassport.value = member.passport.nationality.name;
        this.passportForm.controls.issuedCountry.value = member.passport.issued_country.name;
      }
    }
  }

}
