<div class="formContainer">
  <div *ngIf="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ errorMessage }}
  </div>
  <div *ngIf="errorOffers && !disabeled" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ errorOffersMessage }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-8 col-xxl-9 pl-0 row d-flex align-items-center">
    <div class="col-12 col-lg-4">
      <div class="row">
        <div class="inputContainer">
          <app-from-input (setOriginEvent)="setOrigin($event)"
                          [inputValue]=origin></app-from-input>
        </div>
        <div class="swap-icon cursor-pointer" (click)="swapSearchEngine()">
          <svg id="svg_icon"  viewBox="0 0 200 200" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg"
               class="prSa-icon" role="presentation" style="">
            <path
              d="M56.238 154.801c-25.271-30.326-30.335-33.201-25-39.603l25-30l11.523 9.603L53.013 112.5H120v15H53.013l14.749 17.699l-11.524 9.602zm86.524-40l-11.523-9.603L145.987 87.5H80v-15h65.987l-14.749-17.699l11.523-9.603l25 30c5.335 6.403.272 9.278-24.999 39.603z"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="row">
        <div class="inputContainer">
          <app-to-input (setDestinationEvent)="setDestination($event)"
                        [inputValue]=destination></app-to-input>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="row">
        <div class="inputContainer">
          <!-- <app-depart-input
            (openDesktopCalenderEvent)="openDesktopCalender()"
            [departureDate]=departureDate>
          </app-depart-input> -->
          <app-datePicker [departureDate]="departureDate"  title="departure" [singleDate]="true"
           (setDate)="getDate($event)"></app-datePicker>
        </div>
      </div>
    </div>
    </div>
<div class="col-sm-12  col-lg-4 col-xxl-3 row p-0">
    <div class="col-12 col-lg-5 p-0">
      <div class="row">
        <div class="inputContainer">
          <app-travellers-classes-input
            [className]=className [totalTravellers]=totalTravellers [classTrip]="classTrip"
            [adult]=adult [child]=child [seat]=seat [lap]=lap
            (travellersDetails)="openTravellersDialog($event)">
          </app-travellers-classes-input>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7 p-0 hidden-sm">
      <div class="row">
        <div class="inputContainer submitBtn">
          <button (click)="submit() ;searchClose() ">Search</button>
        </div>
      </div>
    </div></div>
  </div>
  <div class="d-flex justify-content-between ml-5"  [class]="disabeled ? '':'d-none'">
    <div class="w-100">
      <app-direct-flight-input [color]="color"[stops]="stops"
                               (setDirectFlightEvent)="setDirectFlight($event)">
      </app-direct-flight-input>
    </div>
  </div>
  <div class="row">
    <div class="col-1 p-0 show-sm">
      <div class="row">
        <div class="inputContainer submitBtn">
          <button (click)="submit()">Search</button>
        </div>
      </div>
    </div>
  </div>
</div>
