<form [formGroup]="formGroupAddTraveller">
  <ng-container formArrayName="formArrayAddTraveller">
      <ng-container *ngFor="let f of memberTraveller.controls; let i = index ;">
          <ng-container [formGroupName]="i">
              <div class="details-traveler">
                  <div class="formTitle">
                      <div class="d-flex justify-content-between">
                          <span>Personal Information <strong style="color: #FE521A;">({{1 + i}}
                                  {{allTraveller[i]}})</strong></span>
                          <a style="color: red" routerLink="/sign-in" *ngIf="i==0">login if you have an account</a>
                      </div>
                  </div>
                  <div class="col-12">
                      <div class="row">
                          <div class="col-4">
                            <div class="col-11" class="validationMarkContainer" >
                              <mat-form-field appearance="fill">
                                  <mat-label>First Name</mat-label>
                                  <input matInput required formControlName="first_name" type="text" name="firstName" />
                              </mat-form-field>
                              <mat-error *ngIf="formMember[i].get('first_name').dirty || formMember[i].get('first_name').touched">
                                  <mat-error *ngIf="formMember[i].get('first_name').errors?.required">
                                      Please Enter First Name
                                  </mat-error>
                              </mat-error>
                          </div>

                           <div class="col-1" class="validationMark" >
                            <div *ngIf="formMember[i].get('first_name').valid" class="ml-2">
                                <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                            </div>
                           </div>
                        </div>
                          <div class="col-4 remove-astrick">
                            <div class="col-11" class="validationMarkContainer" >
                              <mat-form-field appearance="fill">
                                  <mat-label>Middle Name</mat-label>
                                  <input matInput type="text" formControlName="middle_name" name="MiddleName" />
                              </mat-form-field>
                              <mat-error *ngIf="formMember[i].get('middle_name').dirty || formMember[i].get('middle_name').touched">
                                <mat-error *ngIf="formMember[i].get('middle_name').errors?.required">
                                    Please Enter Middle Name
                                </mat-error>
                            </mat-error>
                        </div>
                            <!-- <div class="col-1" class="validationMark">
                                <div *ngIf="formMember[i].get('middle_name').valid" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div> -->
                          </div>

                          <div class="col-4">
                            <div class="col-11" class="validationMarkContainer" >
                              <mat-form-field appearance="fill">
                                  <mat-label>Last Name</mat-label>
                                  <input matInput required formControlName="last_name" type="text" name="LastName" />
                              </mat-form-field>
                              <mat-error *ngIf="formMember[i].get('last_name').dirty || formMember[i].get('last_name').touched">
                                  <mat-error *ngIf="formMember[i].get('last_name').errors?.required">
                                      Please Enter Last Name
                                  </mat-error>
                              </mat-error>
                            </div>
                              <div class="col-1" class="validationMark">
                                <div *ngIf="formMember[i].get('last_name').valid" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div>
                          </div>

                          <div class="col-4">
                            <div class="col-11" class="validationMarkContainer" >
                              <mat-form-field appearance="fill">
                                  <mat-label>Gender</mat-label>
                                  <input type="text" required matInput formControlName="gender" name="gender"
                                      placeholder="Select your gender" [matAutocomplete]="gender" />
                                  <mat-autocomplete #gender="matAutocomplete">
                                      <mat-option *ngFor="let option of genders" [value]="option.name">
                                          {{ option.name }}
                                      </mat-option>
                                  </mat-autocomplete>
                              </mat-form-field>
                              <mat-error *ngIf="formMember[i].get('gender').dirty || formMember[i].get('gender').touched">
                                  <mat-error *ngIf="formMember[i].get('gender').errors?.required">
                                      Gender is Required
                                  </mat-error>
                              </mat-error>
                          </div>
                              <div class="col-1" class="validationMark">
                                <div [style.visibility]="formMember[i].get('gender').valid ? 'visible' : 'hidden'" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div>
                          </div>

                          <ng-container formGroupName="passport">
                              <div class="col-4 calendar mb-4" formGroupName="travellerDob">
                                <div class="col-11" class="validationMarkContainer" >
                                  <div class="date-form d-flex">
                                      <div class="month col-5">
                                          <mat-form-field appearance="fill" [floatLabel]="'always'">
                                              <mat-label>Date of Birth</mat-label>
                                              <mat-select required formControlName="traveller_dob_month"
                                                  (ngModelChange)="checkDate(i,'travellerDob')">
                                                  <mat-option *ngFor="let month of months"
                                                      [value]="month.num">{{month.text}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                      </div>
                                      <div class="days col-3">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input formControlName="traveller_dob_day"
                                              (focusout)="format($event)" (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                  placeholder="DD" class="example-right-align" maxlength="2"  matInput>
                                          </mat-form-field>
                                      </div>
                                      <div class="years col-4">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input formControlName="traveller_dob_year"
                                                  (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                  placeholder="YYYY" maxlength="4" minlength="4"
                                                   (keyup)="allTraveller[i]=='adult'?calculateDiff(i):''"
                                                  class="example-right-align" matInput>
                                          </mat-form-field>
                                      </div>
                                  </div>
                                  <mat-error
                                  *ngIf="formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').dirty ||formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').touched">
                                  <mat-error
                                      *ngIf="formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').errors?.required">
                                      Date of Birth is Required
                                  </mat-error>

                                  <div class="b-2" *ngIf="errorMessage[i]"> {{errorMessage[i]}}</div>

                              </mat-error>
                              <mat-error
                              *ngIf="formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').dirty || formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').touched">
                              <mat-error
                                  *ngIf="formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').errors?.minlength">
                              </mat-error>
                          </mat-error>
                              </div>
                          <div class="col-1" class="validationMarkDate">
                            <div [style.visibility]="(formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').valid &&
                                        formMember[i].get('passport').get('travellerDob').get('traveller_dob_year').valid)&& !errorMessage[i] ? 'visible' : 'hidden'" class="ml-2">
                                <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                            </div>
                           </div>
                              </div>

                          </ng-container>
                          <div class="col-4" [class]="disabeled ? '':'d-none'">
                            <div class="col-11" class="validationMarkContainer" >
                              <mat-form-field appearance="fill" >
                                  <mat-label>Passenger Type</mat-label>
                                  <input type="text"  matInput  formControlName="PassengerType" name="travellerCountry"
                                      placeholder="Select your Passenger Type" [matAutocomplete]="Relation_id" />
                                  <mat-autocomplete #Relation_id="matAutocomplete">
                                      <mat-option *ngFor="let option of relations" [value]="option.name">
                                          {{ option.name }}
                                      </mat-option>
                                  </mat-autocomplete>
                              </mat-form-field>
                              <mat-error *ngIf="formMember[i].get('PassengerType').dirty || formMember[i].get('PassengerType').touched">
                                  <mat-error *ngIf="formMember[i].get('PassengerType').errors?.required">
                                      Passenger is Required
                                  </mat-error>
                              </mat-error>
                          </div>
                              <div class="col-1" class="validationMark">
                                <div *ngIf="formMember[i].get('PassengerType').valid" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="details-traveler"  >
                  <div class="formTitle">
                      <span>Passport Information <strong style="color: #FE521A;">({{1 + i}}
                              {{allTraveller[i]}})</strong></span>
                  </div>
                  <div class="col-12">
                      <div class="row">
                          <ng-container formGroupName="passport">
                              <div class="col-4">
                                <div class="col-11" class="validationMarkContainer" >
                                  <mat-form-field appearance="fill">
                                      <mat-label>Passport Number</mat-label>
                                      <input matInput required formControlName="passport_number" placeholder="000000000"
                                          type="text" name="passportNumber" />
                                  </mat-form-field>
                                  <mat-error *ngIf="formMember[i].get('passport').get('passport_number').dirty || formMember[i].get('passport').get('passport_number').touched">
                                      <mat-error *ngIf="formMember[i].get('passport').get('passport_number').errors?.required">
                                          Passport Number is Required
                                      </mat-error>
                                  </mat-error>
                              </div>
                                  <div class="col-1" class="validationMark">
                                    <div *ngIf="formMember[i].get('passport').get('passport_number').valid" class="ml-2">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                    </div>
                              </div>

                               </div>
                              <div class="col-4 calendar mb-4" formGroupName="issueDate">
                                <div class="col-11" class="validationMarkContainer" >
                                  <div class="date-form d-flex">
                                      <input matInput>
                                      <div class="month col-5">
                                          <mat-form-field appearance="fill" [floatLabel]="'always'">
                                              <mat-label>Issued Date</mat-label>
                                              <mat-select required (ngModelChange)="checkDate(i,'issueDate')"
                                                  formControlName="issue_date_month">
                                                  <mat-option *ngFor="let month of months" [value]="month.num">
                                                      {{month.text}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                      </div>
                                      <div class="days col-3">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input type="text" (ngModelChange)="checkDate(i,'issueDate')" (focusout)="format($event)"
                                                  formControlName="issue_date_day" placeholder="DD" maxlength="2"
                                                  class="example-right-align"  matInput>
                                          </mat-form-field>
                                      </div>
                                      <div class="years col-4">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input type="text" (ngModelChange)="checkDate(i,'issueDate')"
                                                  formControlName="issue_date_year" placeholder="YYYY" maxlength="4"
                                                  minlength="4" class="example-right-align" matInput>
                                          </mat-form-field>
                                      </div>
                                  </div>
                                  <mat-error
                                  *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').dirty ||formMember[i].get('passport').get('issueDate')?.get('issue_date_year').touched">
                                  <mat-error
                                      *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').errors?.required">
                                      issue Date is Required
                                  </mat-error>
                              </mat-error>
                              <mat-error
                                  *ngIf="formMember[i].get('passport').get('issueDate').get('issue_date_day').dirty || formMember[i].get('passport').get('issueDate').get('issue_date_day').touched">
                                  <mat-error
                                      *ngIf="formMember[i].get('passport').get('issueDate').get('issue_date_day').errors?.minlength">

                                  </mat-error>
                              </mat-error>
                              </div>
                              <div class="col-1" class="validationMarkDate">
                                <div *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').valid" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div>
                              </div>

                              <div class="col-4 calendar mb-4" formGroupName="expireDate">
                                <div class="col-11" class="validationMarkContainer" >
                                  <div class="date-form d-flex">
                                      <input matInput>
                                      <div class="month col-5">
                                          <mat-form-field appearance="fill" [floatLabel]="'always'">
                                              <mat-label>Expire Date</mat-label>
                                              <mat-select required (ngModelChange)="checkDate(i,'expireDate')"
                                                  formControlName="expiry_date_month">
                                                  <mat-option *ngFor="let month of months" [value]="month.num">
                                                      {{month.text}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                      </div>
                                      <div class="days col-3">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input type="text" (ngModelChange)="checkDate(i,'expireDate')" (focusout)="format($event)"
                                                  formControlName="expiry_date_day" placeholder="DD" maxlength="2"
                                                  class="example-right-align"  matInput>
                                          </mat-form-field>
                                      </div>
                                      <div class="years col-4">
                                          <mat-form-field class="example-full-width" appearance="fill">
                                              <input type="text" (ngModelChange)="checkDate(i,'expireDate')"
                                                  formControlName="expiry_date_year" placeholder="YYYY" maxlength="4"
                                                  minlength="4" class="example-right-align" matInput>
                                          </mat-form-field>
                                      </div>
                                  </div>
                                  <mat-error
                                  *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').dirty ||formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').touched">
                                  <mat-error
                                      *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').errors?.required">
                                      issue Date is Required
                                  </mat-error>
                              </mat-error>
                              <mat-error
                                  *ngIf="formMember[i].get('passport').get('expireDate').get('expiry_date_day').dirty || formMember[i].get('passport').get('expireDate').get('expiry_date_day').touched">
                                  <mat-error
                                      *ngIf="formMember[i].get('passport').get('expireDate').get('expiry_date_day').errors?.minlength">
                                  </mat-error>
                              </mat-error>
                              </div>
                              <div class="col-1" class="validationMarkDate">
                                <div *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').valid" class="ml-2">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                               </div>
                              </div>

                              <div class="col-4">
                                <div class="col-11" class="validationMarkContainer" >
                                  <mat-form-field appearance="fill">
                                      <mat-label>Issued Country</mat-label>
                                      <input type="text" required formControlName="issued_country" name="passportIssuedCountry"
                                          placeholder="Select your Issued Country" matInput
                                          [matAutocomplete]="issuedCountry" (focus)="getfilterCountry(i)"
                                          (blur)="countryPatchValue(i)" (change)="countryPatchValue(i)" />
                                      <mat-autocomplete #issuedCountry="matAutocomplete">
                                          <mat-option *ngFor="let option of filteredCountry | async"
                                              [value]="option.name">
                                              {{ option.name }}
                                          </mat-option>
                                      </mat-autocomplete>
                                  </mat-form-field>
                                  <mat-error *ngIf="formMember[i].get('passport').get('issued_country').dirty || formMember[i].get('passport').get('issued_country').touched">
                                    <mat-error *ngIf="formMember[i].get('passport').get('issued_country').errors?.required">
                                          passport Issued Country Is Required
                                      </mat-error>
                                  </mat-error>
                              </div>
                                  <div class="col-1" class="validationMark">
                                    <div *ngIf="formMember[i].get('passport').get('issued_country').valid" class="ml-2">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                    </div>
                                   </div>
                              </div>

                          </ng-container>
                      </div>
                  </div>
              </div>
          </ng-container>
      </ng-container>
  </ng-container>
</form>
