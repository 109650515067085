import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'popular'
})
export class PopularDestSearchService implements PipeTransform {
  transform(popular: any, word: string) {
    return popular.filter(popular => {
      if (word) {
        return popular.city.name.toLowerCase().includes(word?.toLowerCase()) ||
          popular.about.toLowerCase().includes(word?.toLowerCase());
      } else {
        return true;
      }
    });
  }
}
