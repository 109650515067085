import {AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

import {DatePipe} from '@angular/common';


@Component({
    selector: 'app-show-rate',
    templateUrl: './show-rate.component.html',
    styleUrls: ['./show-rate.component.css'],
    styles: [``],
    providers: [DatePipe]
})
export class ShowRateComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input() reviewData: any;
    rateTotal:number;
    rateStart: number;
    showRev: boolean = false;
    percentage_star5: number = 0;
    percentage_star4: number = 0;
    percentage_star3: number = 0;
    percentage_star2: number = 0;
    percentage_star1: number = 0;
    constructor(
    ) {}

    ngOnInit(): void {

    }

    ngOnChanges() {
        this.percentage_stars();
    }

    ngAfterViewChecked() {
        this.percentage_stars();
    }

    percentage_stars() {
        // rateStart= reviewData.rate_average
        if (this.reviewData?.total_count) {
            this.percentage_star5 = ((this.reviewData['5Stars'] / +this.reviewData?.total_count) * 100);
            this.percentage_star4 = ((this.reviewData['4Stars'] / +this.reviewData?.total_count) * 100);
            this.percentage_star3 = ((this.reviewData['3Stars'] / +this.reviewData?.total_count) * 100);
            this.percentage_star2 = ((this.reviewData['2Stars'] / +this.reviewData?.total_count) * 100);
            this.percentage_star1 = ((this.reviewData['1Stars'] / +this.reviewData?.total_count) * 100);
        }
        this.rateStart = this.reviewData?.rate_average * 10 / 2

        this.rateTotal=this.reviewData?.total_count
    }
}
