import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SEOService} from '../../../services/seo.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-review-flight-itinerary-details',
  templateUrl: './review-flight-itinerary-details.component.html',
  styleUrls: ['./review-flight-itinerary-details.component.css']
})

export class ReviewFlightItineraryDetailsComponent implements  OnInit{
  @Input() itineraries;
  @Input() sabre
  @Input() resultArrived;
  @Input() loading;
  @Input() origin;
  @Input() destination;
  @Input() flights;
  @Output() nextStepEvent = new EventEmitter();
  @Output() fareDate = new EventEmitter();
  @Input() airlines;
  @Input() flightResultsPage;
  tagId;
  constructor(private seoService: SEOService , private _ActivatedRoute: ActivatedRoute){
    this.seoUpdate();
  }

  ngOnInit() {
    this.tagId = this._ActivatedRoute.snapshot.paramMap.get('tagId');
    console.log('check tagId', this.tagId);

  }
  foo(e){
    this.fareDate.emit(e);
  }
  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
