import {Component, EventEmitter, Input, Output, OnChanges, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {CouponService} from '../../../services/coupon/coupon.service';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {AlternativeDatesComponent} from '../../alternative-dates/alternative-dates.component';
import {ThanksModalComponent} from '../thanks-modal/thanks-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-review-book',
  templateUrl: './review-flight-review-book.component.html',
  styleUrls: ['./review-flight-review-book.component.css']
})

export class ReviewFlightReviewBookComponent implements OnChanges , OnInit{
  @Output() orderEvent = new EventEmitter();
  @Output() orderEventOffer = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();
  @Input() itineraries;
  @Input() insuranceCost;
  @Input() offerSlug;
  @Input() offerAmount;
  @Input() insurance;
  @Input() offers;
  @Input() ErrorMsg;

  coupon = this.fb.control('', Validators.required);
  validatingCoupon = false;
  validCoupon = false;
  couponError = '';
  couponDiscount: number;
  totalAfterDiscount: number;
  checkboxesCounts: number = 0;
  spinner: any = false;
  dialogRef: any;
  data: any = 55;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apis: ApisService,
    private couponApis: CouponService,
    private datePipe: DatePipe,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private seoService: SEOService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(){
  }

  seoUpdate() {
    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  ngOnChanges(changes) {
  }

  check(e){
    this.checkboxesCounts = e;
  }
  // openThanksModal(): void {
  //   this.dialogRef = this.matDialog.open(ThanksModalComponent, {
  //     panelClass: 'alternativeDateDialog',
  //     autoFocus: false,
  //     maxHeight: '100vh',
  //     width: '70%',
  //     data: {
  //       dataKey: this.data,
  //     }
  //   });
  //   this.dialogRef.afterClosed().subscribe((data) => {
  //     this.router.navigate(['/']);
  //   });
  // }

  spinnerLoader(){
    this.spinner = true;
  }
  validateCoupon() {
    this.couponError = '';
    if (this.coupon.valid) {
      this.validatingCoupon = true;
      let userToken = this.cookie.get('flyallover_token');
      userToken = this.localStorage.getItem('flyallover_token');

      this.couponApis.couponValidation(this.coupon.value.trim(), userToken).subscribe((res: any) => {
        const data = res?.data;
        if (data?.fixed_amount) {
          this.couponDiscount = data?.fixed_amount;
        } else if (data?.percentage_amount) {
          this.couponDiscount = Math.round(this.itineraries[0]?.prices.ItinTotalFare * (data?.percentage_amount / 100));
        }
        this.totalAfterDiscount = this.itineraries[0]?.prices.ItinTotalFare - this.couponDiscount;
        this.validatingCoupon = false;
        this.validCoupon = true;
        this.couponError = '';
      }, err => {
        this.couponError = err.error.error;
        setTimeout(() => {
          this.couponError = '';
        }, 4000);
        this.validatingCoupon = false;
        this.validCoupon = false;
      });
    } else {
      this.coupon.markAsTouched();
    }
  }

  calcTotalPrice(): number {
    let basePrice: number = this.itineraries[0]?.prices?.ItinTotalFare;

    if (this.coupon.value && this.validCoupon) {
      basePrice = this.totalAfterDiscount;
    } else if (this.itineraries[0]?.prices?.AdultPriceWithOffer) {
      basePrice = this.itineraries[0]?.prices?.AdultPriceWithOffer;

    }
    if (this.insurance) {
      basePrice = basePrice + parseFloat(this.insurance?.PlanCost?.Amount);
    }
    basePrice = basePrice + this.itineraries[0]?.Fees;

    return basePrice;
  }
}
