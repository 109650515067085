<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family">Privacy Policy</h1>
      
      <div class="frm">
        <input
          type="text"
          placeholder="How can we help you?"
          [(ngModel)]="searchText"
          (keyup.enter)="onSearch()"
        />
        <button (click)="onSearch()">
          <img
            title="Privacy and Policy"
            alt="search"
            src="./assets/images/help-center/search.svg"
            height="30"
          />
        </button>
      </div>
    </div>
  </div>
  <!-- start skeleton preloader -->
  <div class="help-center-body" *ngIf="!privacyPolicyExist">
    <!-- start search-results -->
    <div class="container">
      <div class="search-results">
        <div class="search-results-header Font-Family">
          <h4 class="Font-Family">
            <ngx-skeleton-loader
              count="1"
              [theme]="{ width: '200px' }"
            ></ngx-skeleton-loader>
          </h4>
        </div>

        <div class="search-results-body">
          <div class="item Font-Family">
            <ngx-skeleton-loader
              count="4"
              [theme]="{
                width: '100%',
                height: '15px',
                'margin-bottom': '0px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <!-- end search-results -->  
  </div>
  <!-- ======= Help Section ======= -->
 <section id="help" class="help section-bg mt-5"> 
  <div class="container " data-aos="fade-up">

    <div class="section-title mt-5">
      <h2 *ngIf="privacyPolicyExist" >Privacy Policy</h2>
    </div>
    <div class="icon-box">
      <span class="mt-5 pt-3" style="font-size: 22px; padding: 10px; font-weight: 500;" *ngIf="privacyPolicyExist"> - If you have any issues or complaints, please contact us at<span class="bolder mx-1" style="font-weight: 700;">+1 212-381-1167</span>  or email <span class="bolder mx-1" style="font-weight: 700;">support@flyallover.com</span> </span>
      <p class="mb-3 " style="font-size: 22px; padding: 10px; font-weight: 500;" *ngIf="privacyPolicyExist"> - We do not share any personal information with third parties</p>
    </div>
    <div class="row mt-5">
      <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100"  >
        <div class="icon-box" routerLink="/details/59" >
          <h4><a>Good tips for our customers:</a></h4>
          <p style="font-weight: 600;"> Airlines are not like buses - if you miss the first flight, you can't catch the following flight. Please buy travel insurance as well.

            Generally, if you miss the first leg of your fligh, the airline may cancel your entire itinerary, including the subsequent . Most airlines operate on a "no-show" policy, which means if you do not board the first segment, they assume you are not traveling and will cancel the rest of your flights. 
            
            However, it’s best to check with the airline directly for their specific policies, as there can be exceptions. In some cases, if you inform the airline in advance, they may allow you to keep your second leg of the journey. </p>
            <h4 class="mt-3"><a>Important Note for Our Users: </a></h4>
            <p> Fly All Over operates as a booking engine system and is not an airline. While we strive to provide comprehensive information, we are not responsible for offering travel advice. Our role concludes once your tickets are booked. For additional information or any issues related to your booking, please contact the airline directly.

              Please note that any extra services provided will incur additional charges. To request these services, please send an email to <span style="font-weight: 700;">support@flyallover.com.</span> Thank you for understanding!
            </p>
        </div>
      </div>
     
    </div>

    <!-- <div>
      <p class="mt-5 pt-3" style="font-size: 25px; padding: 10px; font-weight: 500;" [innerHTML]="privacyPolicy.description"></p>
    </div> -->

    <!-- --------------------------------------------------new --------------------------------------------// -->
    <!-- <div class="row icon-box " >
        <div class=" col-4 mt-4" data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of privacyPolicy.details; let i = index"> 
           <li><a class="linkHandel" href="" (click)="scrollToElement(item.id)" >{{item.title}}</a></li>
        </div>
      </div> -->
    
   

    <div class="row">
      <div class="col-xl-12 col-md-12 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of privacyPolicy.details; let i = index" >
        <div class="icon-box" (click)="privacyDetails(item.id)" >
          <h4><a>{{item.title}}</a></h4>
          <p [innerHTML]="item.description"> </p>
        </div>
      </div>
     
    </div>

  </div>
</section><!-- End Help Section -->
 
</div>
<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->

<!-- <div class="help-center-body" *ngIf="privacyPolicyExist">
  <h1 class="text-center fao_mobile_title_large">Privacy Policy</h1>

  <div class="container">
    <div class="search-results">
      <div class="search-results-header Font-Family">
        <h6 class="fao_mobile_title_large mt-5" [innerHTML]="privacyPolicy.title_content"></h6>
      <div>
        <span class="mt-5 pt-3" style="font-size: 25px; padding: 10px; font-weight: 500;"> - If you have any issues or complaints, please contact us at<span class="bolder mx-1" style="font-weight: 700;">+1 212-381-1167</span>  or email <span class="bolder mx-1" style="font-weight: 700;">support@flyallover.com</span> </span>
        <p class="mb-3 " style="font-size: 25px; padding: 10px; font-weight: 500;"> - We do not share any personal information with third parties</p>
      </div>

      <div class="search-results-body Font-Family">
        <div class="item">
          <p [innerHTML]="privacyPolicy.content" class="Font-Family"></p>
        </div>
       
      </div>
     
    </div>
  </div>
  </div>
</div> -->