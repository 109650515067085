<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page font-family ">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center font-family ">Ask anything you want</h1>
      <div class="frm">
        <input
          #search
          type="text"
          placeholder="How can we help you?"
          (keyup.enter)="onSearch(search.value)"
        />
        <button (click)="onSearch(search.value)">
          <img
            title="Ask anything"
            alt="Search"
            src="./assets/images/help-center/search.svg"
            height="30"
          />
        </button>
      </div>
    </div>
  </div>

  <!-- start skeleton preloader -->
  <div class="help-center-body" *ngIf="loading">
    <div class="container">
      <h2 class="question-header">
        <ngx-skeleton-loader
          count="1"
          [theme]="{ width: '300px' }"
        ></ngx-skeleton-loader>
      </h2>
      <div class="questions">
        <div class="question" *ngFor="let item of createRange(5)">
          <div class="question-header">
            <div class="row">
              <div class="col">
                <h4>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '200px' }"
                  ></ngx-skeleton-loader>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end skeleton preloader -->
  <div class="help-center-body" *ngIf="!loading">
    <!-- start questions -->
    <div class="container" *ngIf="!backendError">
      <h6 class="fao_mobile_title_large">Frequently Asked Questions</h6>
      <p class="font-size ">We've put together some commonly asked questions to give you more information related to the selected topic.</p>
      <!-- <div class="text text-danger" *ngIf="!searchResults">No questions of your Search</div> -->

      <div class="questions">
        <div *ngIf="searchResults">
          <div class="question" *ngFor="let faq of faqs">
            <div
              class="question-header"
              (click)="runAccordion(faq)"
              [ngClass]="{ active: accordion == faq }"
            >
              <div class="row">
                <div class="col">
                  <h3 class="font-family font-weight-bold">{{ faq.question }}</h3>
                </div>
                <div class="col col-icon">
                  <i class="fas fa-angle-down"></i>
                </div>
              </div>
            </div>
            <div class="question-body font-family" [ngClass]="{ open: accordion == faq }">
              <ul>
                <li>
                  <img
                    title="Frequently Asked Questions "
                    alt=" Answer Questions "
                    src="./assets/images/help-center/tick.svg"
                    width="10"
                  />
                  <span class="list-item font-family" [innerHTML]="faq.answer"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end questions -->
    <div *ngIf="backendError">
      <app-no-result-error [homePath]="'/support'"></app-no-result-error>
    </div>
  </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
