<app-itinerary [toggleDetails]="false" (fareData)="foo($event)" [reviewFlight]="true"
               [skeletons]="1" [origin]="origin" [destination]="destination" [flightResultsPage]="flightResultsPage"
               [flights]="flights"[resultsArrived]="resultArrived" [loading]="loading" [tagId]="tagId"
               [itineraries]="itineraries" [sabre]="sabre" [page]="null" [airLines]="airlines" [review]="true">
</app-itinerary>
<div class="navigationButtons">
  <div class="col-12">
    <div class="row justify-content-center" *ngIf="itineraries[0]" >
      <button class="btn navBtn mb-2 Font-Family" (click)="nextStepEvent.emit()">Continue to Next Step</button>
    </div>
  </div>
</div>
