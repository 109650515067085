import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { LocalStorageService } from './services/webApis/local-storage.service';
import { WindowService } from 'src/app/services/webApis/window.service';

@NgModule({
  imports: [
    AppModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    LocalStorageService,
    WindowService,
  ]
})
export class AppBrowserModule {}
