<app-navbar></app-navbar>

<div class="offer-flight-page">
  <!-- start offer-flight-header -->
  <div class="offer-flight-header" *ngIf="loading">
    <ngx-skeleton-loader
      count="1"
      [theme]="{ width: '100%', height: '350px', margin: '0px' }"
    >
    </ngx-skeleton-loader>
  </div>
  <div class="offer-flight-header" *ngIf="!loading">
    <img
      [src]="offerData?.image"
      alt="{{ offerData?.image_alt || 'flyallover' }}"
      title="offer date"
    />
  </div>
  <!-- start offer-flight-body -->
  <div class="offer-flight-body">
    <div class="container">
      <div *ngIf="!errorExists" class="row">
        <!-- start offer-flight-sidebar -->
        <div class="offer-flight-sidebar col">
          <div class="sidebar">
            <div class="row">
              <div class="col">
                <h1>Plan A Trip</h1>
                <div class="search mb-4">
                  <app-search
                    (searchDataReady)="checkDates(content, $event)"
                  ></app-search>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- start offer-flight-content -->
        <div class="offer-flight-content col">
          <div class="offer">
            <div class="row">
              <div *ngIf="!loading" class="col">
                <div class="offer-header">
                  <h1>{{ offerData.offer_name }}</h1>
                </div>
                <div
                  class="offer-body fr-view"
                  [innerHtml]="transformedDescription"
                ></div>

                <div class="offer-footer">
                  <div class="details mb-3">
                    <p>
                      Origin City:
                      <span
                        >{{ offerData.origin_city }},
                        {{ offerData.origin }}</span
                      >
                    </p>
                    <p>
                      Destination City:
                      <span
                        >{{ offerData.destination_city }},
                        {{ offerData.destination }}</span
                      >
                    </p>
                    <p *ngIf="offerData.airline">
                      airline:
                      <span>{{ offerData.airline?.data?.name }}</span>
                      <img
                        class="airline-image"
                        src="https://c.fareportal.com/n/common/air/3x/{{
                          offerData.airline?.data?.iata
                        }}.png"
                        alt="{{ offerData.airline?.data?.name }}"
                        title="Airline"
                      />
                    </p>
                    <p>
                      Trip Type:
                      <span>{{ offerData.type.offertTypeName }}</span>
                    </p>
                    <p>
                      Class:
                      <span>{{ offerData.class }}</span>
                    </p>
                    <p class="my-3">
                      Offer's discount:
                      <span class="text-success">{{
                        offerData.offer_amount | currency
                      }}</span>
                    </p>
                  </div>

                  <div class="expiration-dates">
                    <p>
                      * This Offer can be used from
                      <span class="date">{{
                        offerData.start_date | date: "MMM, dd yyyy"
                      }}</span>
                      to
                      <span class="date">{{
                        offerData.expire_date | date: "MMM, dd yyyy"
                      }}</span>
                    </p>
                    <p>
                      * Valid for travelling between
                      <span class="date">{{
                        offerData.valid_from | date: "MMM, dd yyyy"
                      }}</span>
                      &
                      <span class="date">{{
                        offerData.valid_for | date: "MMM, dd yyyy"
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <!-- start preloader for offer details section -->
              <div *ngIf="loading" class="col">
                <div class="offer-header">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '150px', height: '15px' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div class="offer-body">
                  <ngx-skeleton-loader
                    count="5"
                    [theme]="{ width: '80%', height: '15px', margin: '5px 0' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div class="offer-footer">
                  <div class="details mb-3">
                    <ngx-skeleton-loader
                      count="3"
                      [theme]="{
                        width: '30%',
                        height: '15px',
                        margin: '10px 0',
                        display: 'block'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>

                  <div class="expiration-dates">
                    <ngx-skeleton-loader
                      count="2"
                      [theme]="{
                        width: '50%',
                        height: '15px',
                        margin: '10px 0',
                        display: 'block'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <!-- end preloader for offer details section -->

              <!-- social share links section -->
              <div class="col social text-right">
                <ul>
                  <li>
                    <a (click)="close_status = false">
                      <img
                        title="flyallover"
                        alt="share"
                        src="./assets/images/things-todo/share.svg"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.facebook.com/sharer/sharer.php?u={{
                        hostUrl
                      }}{{ routerUrl }}"
                      target="_blank"
                    >
                      <img
                        title="facebook image"
                        alt="facebook"
                        src="./assets/images/things-todo/facebook.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://twitter.com/intent/tweet?url={{ hostUrl }}{{
                        routerUrl
                      }}"
                      target="_blank"
                    >
                      <img
                        title="twitter image"
                        alt="twitter"
                        src="./assets/images/things-todo/twitter.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.linkedin.com/shareArticle?mini=true&url={{
                        hostUrl
                      }}{{ routerUrl }}&title=!!offerTitle"
                      target="_blank"
                    >
                      <img
                        title="linkedin image"
                        alt="linkedin"
                        src="./assets/images/things-todo/linkedin.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <app-share-popup
                [close_status]="close_status"
                [viewSocialLinks]="false"
                [linkToBeShared]="linkToBeShared"
                (close)="close_status = true"
              ></app-share-popup>
            </div>
          </div>
        </div>
        <!-- end offer-flight-content -->
      </div>

      <!-- appears when error occurs -->
      <div *ngIf="errorExists && !loading" class="row">
        <div class="col-12 error-box">
          <h4 class="text-center text-danger">Offer Not Found</h4>
        </div>
      </div>
    </div>
  </div>
  <!-- end offer-flight-body -->

  <!-- date warning modal -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>The offer will not be applied to the dates you have chosen</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.dismiss('dismiss click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('Close click')"
      >
        Continue anyway
      </button>
    </div>
  </ng-template>
</div>

<app-footer></app-footer>
