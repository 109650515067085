<!-- end header -->
<section class="find-adventure">
        <div class="placeHolder element pointer input-search"   >
          <input type="text" class="datepicker__input " placeholder="Choose Date">
          <div *ngIf=" !viewDate">
            <span class="star">*</span>
            <span>{{title}}</span>
          </div>

          <div *ngIf="viewDate">
            <div class="text-holder-port row">
              <span class="text-holder-up col-12 p-0" style="
              font-weight: 900;
              font-size: 18px;"
              >{{viewDate|date:'MMM d'}}</span>
              <span class="text-holder-down col-12 p-0" style="
              font-size: 13px;"
              >{{viewDate|date:'yyy'}}</span>
            </div>
          </div>
          <div>
            <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
          </div>
        </div>

</section>
