import {DatePipe, DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";

declare var $: any;


@Component({
  selector: 'app-datePicker-return',
  templateUrl: './calendar-return.component.html',
  styleUrls: ['./calendar-return.component.scss']
})
export class CalunderReturnComponent implements OnInit {
  @ViewChild('datepicker') datepicker: any;

  startDate: any;
  endDate: any;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  @Output() setDate = new EventEmitter();
  @Input() departureDate: any;
  @Input() returnDate: any;
  @Input() singleDate = false;
  @Input() title = 'Chose Date';
  viewDate: any;
  tomorrow: any;
  @Input() prices: { [key: string]: number } = {
    '2024-05-20': 100,
    '05/21/2024': 120,
    '2024-05-25': 150,
  }; // Add prices input
  constructor(@Inject(DOCUMENT) private document: Document, private datePipe: DatePipe, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.tomorrow = new Date();
    this.tomorrow.setDate({picker: this.tomorrow.getDate() + 1, type: 'departure'});
  }

  ngOnInit() {

    // $('.departure_date').daterangepicker({
    //   autoUpdateInput: false,
    //   autoApply: true,
    //   singleDatePicker: true,
    //   startDate: this.startDate ? this.startDate : this.departureDate ? this.datePipe.transform(this.departureDate, 'MM/dd/YYYY') : this.tomorrow,
    //   locale: {
    //     cancelLabel: 'close',
    //     format: 'MM/DD/YYYY'
    //   },
    //   minDate: this.tomorrow,
    //   isInvalidDate: (date) => {
    //     // Mark dates as invalid based on some condition
    //     return false;
    //   },
    //   isCustomDate: (date) => {
    //     const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    //     if (this.prices[formattedDate]) {
    //       return 'has-price';
    //     }
    //     return '';
    //   }
    // })
    // $('.return_date').daterangepicker({
    //   autoUpdateInput: false,
    //   autoApply: true,
    //   singleDatePicker: true,
    //   endDate: this.endDate ? this.endDate : this.returnDate ? this.datePipe.transform(this.returnDate, 'MM/dd/YYYY') : this.tomorrow,
    //   locale: {
    //     cancelLabel: 'close',
    //     format: 'MM/DD/YYYY'
    //   },
    //   minDate: this.tomorrow,
    //   isInvalidDate: (date) => {
    //     // Mark dates as invalid based on some condition
    //     return false;
    //   },
    //   isCustomDate: (date) => {
    //     const formattedDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    //     if (this.prices[formattedDate]) {
    //       return 'has-price';
    //     }
    //     return '';
    //   }
    // });
    // $('.datepicker__input').on('show.daterangepicker', (ev, picker) => {
    //   const calendarContainer = picker.container;
    //   calendarContainer.find('.has-price').each((index, element) => {
    //     const dateText = $(element).text().trim();
    //     const formattedDate = dateText ? this.datePipe.transform(new Date(dateText), 'MM/dd/yyyy') : null;
    //     if (formattedDate && this.prices[formattedDate]) {
    //       $(element).append(`<span class="price-badge">${this.prices[formattedDate]}</span>`);
    //     }
    //   });
    // });
    // $('.departure_date').on('apply.daterangepicker', (ev, picker) => {
    //   this.startDate = picker.startDate;
    //   this.viewDate = this.startDate;
    //   this.setDate.emit({picker, type: 'departure'});
    //   $('.return_date').click();
    // });
    // $('.return_date').on('apply.daterangepicker', (ev, picker) => {
    //   this.endDate = picker.endDate;
    //   this.setDate.emit({picker, type: 'return'});
    // });
    // $('.datepicker__return').on('cancel.daterangepicker', function (ev, picker) {
    //   $(this).val('');
    // });

  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.departureDate = this.formatter.format(date);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.returnDate = this.formatter.format(date);
      this.setDate.emit({departureDate: this.departureDate, returnDate: this.returnDate});
      this.datepicker.toggle();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.departureDate = this.formatter.format(date);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}

