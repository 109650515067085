<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center">Help Center - {{ postTitle }}</h1>
      <div class="frm">
        <input
          type="text"
          placeholder="How can we help you?"
          [(ngModel)]="searchText"
          (keyup.enter)="onSearch()"
        />
        <button (click)="onSearch()">
          <img
            title="help Center - {{ postTitle }}"
            alt="Search"
            src="./assets/images/help-center/search.svg"
            height="30"
          />
        </button>
      </div>
    </div>
  </div>

  <div class="help-center-body">
    <!-- start breadcrumb -->
    <div class="container">
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/support']">Help Center</a>
          </li>
          <li class="breadcrumb-item">
            <a>{{ topicTitle }}</a>
          </li>
          <li class="breadcrumb-item active">{{ postTitle }}</li>
        </ol>
      </nav>
    </div>

    <!-- start search-results -->
    <div class="container">
      <!-- start skeleton preloader -->

      <div class="search-results" *ngIf="!postDataExist">
        <div class="search-results-body">
          <div class="item">
            <h4>
              <ngx-skeleton-loader
                count="1"
                [theme]="{ width: '300px' }"
              ></ngx-skeleton-loader>
            </h4>
            <p>
              <ngx-skeleton-loader
                count="4"
                [theme]="{
                  width: '100%',
                  height: '15px',
                  'margin-bottom': '0px'
                }"
              >
              </ngx-skeleton-loader>
            </p>
          </div>
        </div>
      </div>

      <!-- end skeleton preloader -->
      <div class="search-results" *ngIf="postDataExist">
        <div class="search-results-body">
          <div class="item">
            <h6 [innerHTML]="postData['title']"></h6>
            <p [innerHTML]="postData['description']"></p>

            <p>
              Want to know more?
              <a [routerLink]="['/contact-us']">Contact Us</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- start pagination -->
    <div class="container">
      <div class="row ngb-pagination"></div>
    </div>
    <!-- end pagination -->
  </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
