<app-navbar></app-navbar>
<div class="help-center-page">
  <div class="help-center-header">
    <div class="cover">
      <div class="title">
        <h1>pcr test for travel near me</h1>

        <div class="help-center-body container">
          <h4
            *ngIf="statesOrCitiesNotFound && !loading"
            class="help-center-body container error-note text-danger text-center"
          >
            We could not find any covid-19 testing locations in {{ country }}
          </h4>
          <div class="topics" *ngIf="!loading">
            <div class="row">
              <div
                class="col-md-4 col-s"
                *ngFor="let state of states; let i = index"
              >
                <div class="topic">
                  <a>
                    <h6>{{ state?.name }}</h6>
                    <div class="list" *ngIf="state.pcr_locations?.length === 0">
                      <ul>
                        <li class="text-danger">No locations yet</li>
                      </ul>
                    </div>
                    <div class="list" *ngIf="state.pcr_locations?.length > 0">
                      <ul>
                        <li
                          (click)="navigateToState(state.name, location.name)"
                          *ngFor="let location of state.pcr_locations"
                        >
                          {{ location?.name.slice(0, 35) }}
                          <span *ngIf="location?.name?.length > 35">...</span>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="topics" *ngIf="loading">
            <div class="row">
              <div class="col-md-4 col-s" *ngFor="let item of createRange(6)">
                <div class="topic">
                  <a>
                    <h6>
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '90%' }"
                      ></ngx-skeleton-loader>
                    </h6>
                    <p>
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '10px', width: '70%' }"
                      >
                      </ngx-skeleton-loader>
                    </p>
                    <div class="list">
                      <ul>
                        <ngx-skeleton-loader
                          count="3"
                          [theme]="{ height: '15px', width: '90%px' }"
                        >
                        </ngx-skeleton-loader>
                      </ul>
                    </div>

                    <div class="submit">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ height: '50px', width: '70%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- end preloader -->
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
