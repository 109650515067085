<div class="col-12">
<div class="custom-container">
  
  <div class="warnings" *ngIf="error">
    <span role="alert" class="alert alert-dismissible alert-warning">{{error}}</span>
  </div>
  <div class="row">
    <div class="col-12 col-sm-9 col-lg-10">
      <div class="row" style="justify-content: space-between;padding-right: 30px;">
        <ng-container *ngFor="let traveller of travellers | keyvalue">
          <div class="col-4 col-lg-3">
            <div class="header d-flex flex-column">
              <span class="name">{{traveller.value.name}}</span>
              <span class="ages">({{traveller.value.ageFrom}} - {{traveller.value.ageTo}})</span>
            </div>
            <div class="value d-flex align-items-center">
              <button class="btn valueBtn" [disabled]="traveller.value.currentValue < 1 || totalTravellers < 2"
                      (click)="dec(traveller)">-
              </button>
              <div class="number">
                <span>{{traveller.value.currentValue}}</span>
              </div>
              <button class="btn valueBtn" [disabled]="totalTravellers > 8" (click)="inc(traveller)">+</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>


    <div class="col-12 col-sm-3 col-lg-2">
      <div class="row flex-column justify-content-between select">
        <div class="classes">
          <mat-select [(ngModel)]="className" class="matSelect">
            <mat-option [value]="'Economy'">Economy</mat-option>
            <mat-option [value]="'Premium economy'">Premium Economy</mat-option>
            <mat-option [value]="'First'">First</mat-option>
            <mat-option [value]="'Business'">Business</mat-option>
          </mat-select>
        </div>
        <button class="btn selectClassBtn"
                [mat-dialog-close]="{className:className,totalTravellers:totalTravellers
                  ,adult:travellers.adult.currentValue ,child:travellers.child.currentValue
                  ,seat:travellers.seat.currentValue,lap:travellers.lap.currentValue}"
                [disabled]="errorInfants">
          Select
        </button>
      </div>
    </div>

  </div>
</div>
<div class="error" *ngIf="errorInfants" >{{seatExceedError}}</div>
</div>
