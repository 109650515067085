<!-- start navbar -->
<app-navbar></app-navbar>
<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center">Help Center</h1>
      <div class="frm">
        <input
          #search
          type="text"
          placeholder="How can we help you?"
          (keyup.enter)="onSearch(search.value)"
        />
        <button (click)="onSearch(search.value)">
          <img
            title="Help Center
"
            alt="search"
            src="./assets/images/help-center/search.svg"
            height="30"
          />
        </button>
      </div>
    </div>
  </div>

  <div class="help-center-body">
    <!-- start skeleton preloader for topic  -->
    <div class="container" *ngIf="!topicsExist">
      <h2>
        <ngx-skeleton-loader
          count="1"
          [theme]="{ width: '200px' }"
        ></ngx-skeleton-loader>
      </h2>
      <div class="topics text-center">
        <div class="row">
          <div class="col" *ngFor="let item of createRange(4)">
            <ngx-skeleton-loader
              count="1"
              [theme]="{ width: '263px', height: '190px' }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <!-- end skeleton preloader for topics -->
    <div class="container" *ngIf="topicsExist && faqsExist">
      <h2>Search By Topics</h2>
      <div class="topics text-center">
        <div class="row">
          <div
            class="col"
            *ngFor="let topic of topics"
            (click)="searchTopic(topic.title_content, topic.id)"
          >
            <div class="topic">
              <a>
                <img
                  title="flyallover"
                  [title]="topic.title_cotent"
                  alt="topic image
"
                  [src]="topic.image"
                  height="60"
                />
                <p [innerHTML]="topic.title_content"></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- start skeleton loader for faq -->
    <div class="container" *ngIf="!faqsExist">
      <div class="container">
        <h2 class="question-header">
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '300px' }"
          ></ngx-skeleton-loader>
        </h2>
        <div class="questions">
          <div class="question" *ngFor="let item of createRange(2)">
            <div class="question-header">
              <div class="row">
                <div class="col">
                  <h3>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '200px' }"
                    ></ngx-skeleton-loader>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="faqsExist">
      <div class="container">
        <h2 class="question-header">Frequently Asked Questions</h2>
        <div class="questions">
          <div
            class="question"
            *ngFor="let faq of faqs.slice(0, 10); let i = index"
          >
            <div
              class="question-header"
              (click)="runAccordion(faq.answer)"
              [ngClass]="{ active: accordion == faq.answer }"
            >
              <div class="row">
                <div class="col">
                  <h3>{{ faq.question }}</h3>
                </div>
                <div
                  [ngClass]="{ 'd-none': accordion == faq.answer }"
                  class="col col-icon"
                >
                  <i class="fas fa-angle-down"></i>
                </div>
                <div
                  [ngClass]="{ 'd-none': accordion != faq.answer }"
                  class="col col-icon"
                >
                  <i class="fas fa-angle-up"></i>
                </div>
              </div>
            </div>
            <div
              class="question-body"
              [ngClass]="{ open: accordion == faq.answer }"
            >
              <ul>
                <li>
                  <img
                    title="Frequently answer QQuestion"
                    alt="Frequently answer image"
                    src="./assets/images/help-center/tick.svg"
                    width="10"
                  />
                  <div [innerHTML]="faq.answer"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- end questions -->
    </div>
  </div>
  <!-- end help-center-page -->
</div>
<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
