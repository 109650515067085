import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, switchMap, tap} from 'rxjs/operators';
import {AppComponent} from 'src/app/app.component';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {SEOService} from 'src/app/services/seo.service';
import {Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-covid-country-search',
    templateUrl: './covid-country-search.component.html',
    styleUrls: ['./covid-country-search.component.css']
})
export class CovidCountrySearchComponent implements OnInit {

    countrySearchField: string;
    country: string;
    modifiedCountryName: string;
    copied= false;

    constructor(
        private centerApis: HelpCenterServices,
        private seoService: SEOService,
        private router : Router,
        private clipboard : Clipboard,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.seoUpdate();
    }

    ngOnInit(): void {
        this.seoUpdate();
    }

    seoUpdate() {
        this.seoService.updateTitle('Locations to perform COVID-19 PCR TESTS | flyallover');
        this.seoService.updateDescription('Find a testing center near you, learn how to get tested, and find key information about Locations to perform COVID-19 PCR TESTS.');
        this.seoService.updateUrl();
        this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/pcr/pcr_meta.jpg');
    }

    countrySearch = (text$: Observable<string>) => {
        return text$.pipe(
            // emits a value only after 100ms without any change in text$
            // https://rxjs-dev.firebaseapp.com/api/operators/debounceTime
            debounceTime(100),
            // Only emits when the current value is different than the last.
            // distinctUntilChanged(),
            switchMap(term => {
                return this.centerApis.countriesAutoComplete(term).pipe(
                    tap(term => {
                        if (term[0] === ' ') {
                            setTimeout(() => {
                                const button = this.document.querySelector('[id^=ngb-typeahead] .dropdown-item:first-of-type') as HTMLButtonElement
                                button.innerHTML = `<span class="text-danger"> <i class="fas fa-exclamation-circle mr-1"></i> No results Found<span>`
                                button.classList.add('no-results');
                                button.disabled = true
                            }, 0);
                        }
                    }),
                    catchError(() => {
                        return of([]);
                    })
                )
            })
        )
    }

    chooseCountry(event) {
        this.country = event.item;
        this.modifiedCountryName = this.country.replace(/\s+/g, '-').toLowerCase();
    }

    cop() {
        this.clipboard.copy('https://www.flyallover.com' + this.router.url)
        this.copied=!this.copied;
        setTimeout(()=>{
            this.copied = false;
        },1500)
    }

    fb() {
        let url = "www.google.com";

        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "flyallover.com" +
            this.router.url,
            "facebook-popup",
            "height=350,width=600"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }
}
