<div class="container-fluid mt-3 ">
  <div class="row">
    <div class="col-3">
      <div class="image">
        <ng-container *ngIf="autoPlay">
          <video style="width: 100%"  muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                 class="img-fluid" title="NEW YORK"
                 controls
                 src="https://player.vimeo.com/external/578837684.sd.mp4?s=a81260666754d730e43f437c7b137192990c55c2&profile_id=165">
          </video>
        </ng-container>
        <ng-container *ngIf="!autoPlay">
          <video style="width: 100%" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                 class="img-fluid" title="NEW YORK"
                 controls
                 src="https://player.vimeo.com/external/578837684.sd.mp4?s=a81260666754d730e43f437c7b137192990c55c2&profile_id=165">
          </video>
        </ng-container>
        <div class="placeholder text-uppercase d-flex justify-content-between">
          <span>NEW YORK</span>
          <button (click)="displayEngine(1)">Book Now</button>
        </div>
        <span class="engine" *ngIf="showEngine == 1">
    <app-engine-static [destination]="destination"
                       [origin]="origin"
                       [stops]="stops"
                       [tripType]="tripType"
                       [shortOrigin]="shortOrigin"
                       [shortDestination]="shortDestination"
                       [fullOrigin]="fullOrigin"
                       [fullDestination]="fullDestination">
    </app-engine-static>
      </span>
      </div>
    </div>
    <div class="col-3">
      <div class="image">
        <ng-container *ngIf="autoPlay">
          <video style="width: 100%"  muted="" playsinline="" loop=""
                 onloadedmetadata="this.muted = true"
                 class="img-fluid" title="LONDON"
                 controls
                 src="https://player.vimeo.com/external/578831397.sd.mp4?s=076622ca93918f02a39d1447b4e0696d80d0c4b0&profile_id=165">
          </video>
        </ng-container>
        <ng-container *ngIf="!autoPlay">
          <video style="width: 100%" muted="" playsinline="" loop=""
                 onloadedmetadata="this.muted = true"
                 class="img-fluid" title="LONDON"
                 src="https://player.vimeo.com/external/578831397.sd.mp4?s=076622ca93918f02a39d1447b4e0696d80d0c4b0&profile_id=165">
          </video>
        </ng-container>
        <div class="placeholder text-uppercase d-flex justify-content-between">
          <span>London</span>
          <button (click)="displayEngine(2)">Book Now</button>
        </div>
       
      </div>
    </div>
    <div class="col-3">
      <div class="image">
        <ng-container *ngIf="autoPlay">
          <video style="width: 100%"  muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                 class="img-fluid" title="UKRAINE"
                 controls
                 src="https://player.vimeo.com/external/578836808.sd.mp4?s=cd300dc545f0dce10b5f9a7561ba5fa0648667a3&profile_id=165">
          </video>
        </ng-container>
        <ng-container *ngIf="!autoPlay">
          <video style="width: 100%" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
                 class="img-fluid" title="UKRAINE"
                 src="https://player.vimeo.com/external/578836808.sd.mp4?s=cd300dc545f0dce10b5f9a7561ba5fa0648667a3&profile_id=165">
          </video>
        </ng-container>
        <div class="placeholder text-uppercase d-flex justify-content-between">
          <span>MAUI</span>
          <button (click)="displayEngine(3)">Book Now</button>
        </div>
        <span class="engine" *ngIf="showEngine == 3">
    <app-engine-static [destination]="destination"
                       [origin]="origin"
                       [stops]="stops"
                       [tripType]="tripType"
                       [shortOrigin]="shortOrigin"
                       [shortDestination]="shortDestination"
                       [fullOrigin]="fullOrigin"
                       [fullDestination]="fullDestination">
    </app-engine-static>
    </span>
      </div>
    </div>
    <div class="col-3">
      <div class="image">
        <ng-container *ngIf="autoPlay">
          <video (click)="displayEngine(4)" style="width: 100%" muted="" playsinline="" loop=""
                 onloadedmetadata="this.muted = true"
                 class="img-fluid" title="BALI"
                 controls
                 src="https://player.vimeo.com/external/578843714.sd.mp4?s=31874e1c9d3242ac18b4ea6d2705048d855b426c&profile_id=165">
          </video>
        </ng-container>
        <ng-container *ngIf="!autoPlay">
          <video (click)="displayEngine(4)" style="width: 100%" muted="" playsinline="" loop=""
                 onloadedmetadata="this.muted = true"
                 class="img-fluid" title="BALI"
                 src="https://player.vimeo.com/external/578843714.sd.mp4?s=31874e1c9d3242ac18b4ea6d2705048d855b426c&profile_id=165">
          </video>
        </ng-container>
        <div class="placeholder text-uppercase d-flex justify-content-between">
          <span>Ukraine</span>
          <button (click)="displayEngine(4)">Book Now</button>
        </div>
        <span class="engine" *ngIf="showEngine == 4">
    <app-engine-static [destination]="destination"
                       [origin]="origin"
                       [stops]="stops"
                       [tripType]="tripType"
                       [shortOrigin]="shortOrigin"
                       [shortDestination]="shortDestination"
                       [fullOrigin]="fullOrigin"
                       [fullDestination]="fullDestination">
    </app-engine-static>
      </span>
      </div>
    </div>
  </div>
</div>
