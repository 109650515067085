import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FroalaEditorStylesService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  addFroalaStylesFile() {
    const froalaLink = this.document.getElementById('froala');
    if (!froalaLink) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('id', 'froala');
      this.document.head.appendChild(link);
      //  WARNING: for the stylesheet to be applied in a page a container in the page must have class fr-view
      link.setAttribute('href', '../../../assets/css/froala_styles.min.css');
    }
  }
}
