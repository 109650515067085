
  <ng-template #t let-fill="fill">
    <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill"><i class="fa fa-star"></i></span><i class="fa fa-star"></i>
    </span>
  </ng-template>

  <ngb-rating [(rate)]="rateStart" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>

  <hr>
  <!-- <pre>Rate: <b>{{currentRate}}</b></pre> -->
  <p class="Font-family">{{ rateStart | number:'1.1'  }} average based on  <span>{{rateTotal}}</span> reviews.</p>

  <!-- <button class="btn btn-sm btn-outline-primary me-2" (click)="currentRate = 1.35">1.35</button>
  <button class="btn btn-sm btn-outline-primary me-2" (click)="currentRate = 4.72">4.72</button> -->
<hr style="border:3px solid #f1f1f1">

<!-- progress-color-width -->
<div class="row" *ngIf="reviewData">
  <div class="side ">
    <div class="Font-family">5 star</div>
  </div>
  <div class="middle">
    <div class="bar-container">
      <div class="bar-5" [style.width]="percentage_star5+'%'"></div>
    </div>
  </div>
  <div class="side right">
    <div>{{reviewData["5Stars"]}}</div>
  </div>
  <div class="side">
    <div class="Font-family">4 star</div>
  </div>
  <div class="middle">
    <div class="bar-container">
      <div class="bar-4" [style.width]="percentage_star4+'%'"></div>
    </div>
  </div>
  <div class="side right">
    <div>{{reviewData["4Stars"]}}</div>
  </div>
  <div class="side">
    <div class="Font-family">3 star</div>
  </div>
  <div class="middle">
    <div class="bar-container" >
      <div  class="bar-3" [style.width]="percentage_star3+'%'"></div>
    </div>
  </div>
  <div class="side right">
    <div>{{reviewData["3Stars"]}}</div>
  </div>
  <div class="side">
    <div class="Font-family">2 star</div>
  </div>
  <div class="middle">
    <div class="bar-container" >
      <div  class="bar-2" [style.width]="percentage_star2+'%'"></div>
    </div>
  </div>
  <div class="side right">
    <div>{{reviewData["2Stars"]}}</div>
  </div>
  <div class="side">
    <div class="Font-family">1 star</div>
  </div>
  <div class="middle">
    <div class="bar-container" >
      <div  class="bar-1" [style.width]="percentage_star1+'%'"></div>
    </div>
  </div>
  <div class="side right">
    <div>{{reviewData["1Stars"]}}</div>
  </div>
</div>

<!-- End Total Rating -->


