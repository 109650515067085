import {Component, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import { HotelSearchComponent } from '../hotel-search/hotel-search.component';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-multiple-search-engines',
  templateUrl: './multiple-search-engines.component.html',
  styleUrls: ['./multiple-search-engines.component.css']
})
export class MultipleSearchEnginesComponent implements OnInit, AfterViewInit {

  @Input() hotelSearch;
  // this component is viewed to update search fields data
  // @ViewChild(SearchComponent)
  // private searchComponentFLightResults: SearchComponent;
  // this component is viewed to update search fields data
  @ViewChild(HotelSearchComponent)
  private hotelSearchComponent: HotelSearchComponent;

  searchEngine = 'flights';

  constructor(
    private apis: ApisService,
    private hotelApis: HotelsApisService,
    private router: Router,
    private localStorage: LocalStorageService,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  // this method is a lifecycle hook in angular and it is performed when the components and all its child components are rendered
  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
    this.fillSearchFields();
  }

  callSearchAPi(formData) {
    this.apis.search(formData).subscribe((data: any) => {
      this.localStorage.setItem('oldSearch', JSON.stringify(formData))
      this.router.navigate(['/flight-results'], { state: data.data })

    }, err => {
      this.router.navigate(['/no-result'])
    })
  }
  hotelSearchAPI(searchData) {

    if (searchData.longitude === undefined || searchData.latitude === undefined) {
      searchData.longitude = this.hotelSearch.longitude;
      searchData.latitude = this.hotelSearch.latitude;
    }
    this.hotelApis.searchHotels(searchData).subscribe((data: any) => {
      this.localStorage.setItem('hotelSearchData', JSON.stringify(searchData));
      this.router.navigate(['/hotel-results'], { state: data.data })
    }, err => {
      this.router.navigate(['/no-result']);

    })

  }
  selectSearchEngine(engine) {
    this.searchEngine = engine;
  }

  // this method is used to persist data in the search fields
  // data might be the one the user added in home page or what user added to do anther search in hotel-results page
  fillSearchFields() {
    // this.searchComponentFLightResults.updateSearchData(this.search);
    if (this.hotelSearch) {
      this.hotelSearchComponent.updateSearchData(this.hotelSearch);
    }
  }
}
