import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {ApisService} from '../../services/apis.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-flight-details-drop-down',
  templateUrl: './flight-details-drop-down.component.html',
  styleUrls: ['./flight-details-drop-down.component.css','./details.component.scss']
})

export class FlightDetailsDropDownComponent implements OnInit {

  @Input() itinerary;
  @Input() sabre;
  @Input() origin;
  @Input() destination;
  @Input() reviewFlight = true;
  BF:any;
  LG:any;
  bagging: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private apis: ApisService,
    private router: Router,
    private localStorage: LocalStorageService,


  ) {
  }

  ngOnInit(): void {
    if (!this.itinerary.flights){
      this.itinerary.flights = this.itinerary.flight_details;
    }
  }

  closeCalenderDialog(): void {
    this.matDialog.closeAll();
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}h`;
    }
    convertedMinutes = `${minutes % 60}m`;
    return convertedHours + ' ' + convertedMinutes;
  }

  select(TagID) {
    let tripType = 'Return';
    if (this.itinerary.length === 1) {
      tripType = 'OneWay';
    }
    const oneResult = {
      tagId: TagID,
      class: this.itinerary.prices.FareClassName,
      trip_type: tripType,
      passengerDetails: this.itinerary.flights.passengerDetails
    };
    this.localStorage.setItem('selectedFlight', JSON.stringify(oneResult));
    this.router.navigate(['review-flight'], {state: oneResult});
  }

}
