<div class="home-offers">
  <div class="row">
    <owl-carousel-o *ngIf="data" [options]="customOptions" class="d-flex justify-content-center">
      <ng-container *ngFor="let item of data | reverse;  let i = index">
        <ng-container *ngIf="i<= 5">
          <ng-template carouselSlide>
            <div class="col-12">
              <div class="offer ">
                <div class="offer-img">
                  <a [routerLink]="['offers/', item.slug]">
                    <img
                      src="{{ item.images[0].full_url }}"
                      alt="{{ item.images[0].file_name }}"
                      class="rounded w-100"
                      />
                  </a>
                  <div class="name">
                    <h5><a [routerLink]="['offers/', item.slug]">
                      {{ item.name }}
                    </a>
                    </h5>
                    <span class="d-flex justify-content-center px-1 my-1"  >
                          <span class="text-dark DataFont dateColor To-color ">{{ item.departure_date | date: "MM/dd/yyyy" }} </span>
                      <span class="px-2 DataFont bold text-dark"> To </span>
                          <span class="text-dark DataFont dateColor To-color " >{{ item.return_date | date: "MM/dd/yyyy" }}    </span>
                        </span>
                  </div>

                  <div
                  style="background-color: #ddd4d4;                                           
                  height: 1px;
                  width: 95%;
                  margin: auto;
                  "

          ></div>
                  <p class="fao_mobile_prgraph">
                    {{ item?.offer_description |slice:0:100}}
                 </p>
                   
                    <div class="line-height px-3">
                      <h4 class="price">$ {{item.price}}</h4>
                      <span class="d-flex justify-content-end text-muted">Round Trip</span>
                  </div>
                  <div class="center-class my-3">
                    <button class="fao_des_btn_med" style="padding: 4px !important; font-size: 14px !important;" [routerLink]="['/offers/' ,item.slug]"
                            (click)="refresh(item.slug)">
                      MORE DETAILS
                    </button>
                  </div>
<!--                    <div class="center-class">-->
<!--                      <a href="/offers"><h5> More Offers</h5>-->
<!--                      </a>-->
<!--                    </div>-->
                  

                </div>
                <div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
