<input class="form-control d-flex pointer" readonly [value]="returnDate | date: 'MMM d'" (click)="openDesktopCalender()"
  (focus)="onFocus()" (blur)="onFocus()" style="color: #fd502100 ;opacity: 0;">
<ng-container>
  <div class="placeHolder element pointer input-search" (click)="openDesktopCalender()">
    <div *ngIf="!isFocused && !returnDate">
      <span class="star">*</span>
      <span>Return</span>
    </div>
    
    <div *ngIf="returnDate">
      <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{returnDate|date:'MMM d'}}</span>
        <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
        >{{returnDate|date:'yyy'}}</span>
      </div>
    </div>
    <div>
      <img alt="calendar" style="width: 24px;height: 24px;" title="Return Date" src="/assets/images/icons/calendar.svg">
    </div>
  </div>
</ng-container>
