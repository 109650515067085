<div class="post-container">
  <a href="#" class="post">
    <div class="overlay text-left">
      <a class="label" target="_blank">
        Dubai Hotels
      </a>
    </div>
    <div class="post-photo">
      <img title="Dubai Hotels" alt="tourism" src="../../../assets/images/hotels/4.jpg"
           class="img-fluid">
    </div>
    <div class="post-description">
      <div class="offer_name">
        <h4 title="Hotel America">New York City, NY</h4>
      </div>
      <div class="offer_description">
      <p>Avg. price per night for a 3-star hotel: EGP 676
        Popular with travellers in Egypt
      </p>
      </div>
    </div>
  </a>
</div>
