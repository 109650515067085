<!-- <ng-container *ngIf="token" class="d-none">
  <app-review-flight-family-members *ngIf="offers != 'false' && payLater!='true'" [selectable]="true" [searchRequest]="searchRequest"
    (selectTravellerEvent)="selectTraveller($event)" (unSelectTravellerEvent)="unSelectTraveller($event)"
    (countriesOut)="getcountry()" (accountInfo2)="accountinfo()" (ownerinfo)="ownerInfo()"
    (familyMember2)="getMembers()" (familyRelation)="getFamilyRelation()" [getCountries1]="countries"
    [accountInfo]="accountInfo" [OwnerInfo]="OwnerInfo" [familyMembers]="familyMembers"
    [familyRelation1]="FamilyRelation1">
  </app-review-flight-family-members>
  <app-review-flight-offers-family-members [payLater]="payLater" *ngIf="offers == 'false'|| payLater=='true'" [selectable]="true" [searchRequest]="searchRequest"
  (lenthFormAddTraveller)="lenthFormAddTraveller($event)"(selectTravellerEvent)="selectTraveller($event)" (unSelectTravellerEvent)="unSelectTraveller($event)"
  (countriesOut)="getcountry()" (accountInfo2)="accountinfo()" (ownerinfo)="ownerInfo()"
  (familyMember2)="getMembers()" (familyRelation)="getFamilyRelation()" [getCountries1]="countries"
  [accountInfo]="accountInfo" [OwnerInfo]="OwnerInfo" [familyMembers]="familyMembers" (setPayLater)="getPayLater($event)"
  [familyRelation1]="FamilyRelation1" (informationContact)="getInformationContact($event)" (reloadFamilyMember)="reloadMember()">
</app-review-flight-offers-family-members>
</ng-container> -->
<!-- <ng-container *ngIf="!token"> -->
  <ng-container *ngIf="offers != 'false' && payLater!='true'">
    <app-review-flight-add-traveller-form (selectTravellerEvent)="selectTraveller($event)" [searchRequest]="searchRequest" (setOwnerEvent)="setOwner($event)">
    </app-review-flight-add-traveller-form>
  </ng-container>
  <ng-container *ngIf="offers == 'false'|| payLater=='true'">
    <app-app-review-flight-offer-add-traveller-form [payLater]="payLater" (setPayLater)="getPayLater($event)" (selectTravellerEvent)="selectTraveller($event)" [searchRequest]="searchRequest" (setOwnerEvent)="setOwner($event)" [familyMembers]="familyMembers">
    </app-app-review-flight-offer-add-traveller-form>
  </ng-container>
<!-- </ng-container> -->
<!-- <ng-container *ngIf="!token">
  <ng-container *ngIf="!anotherTraveller">
    <app-review-flight-add-traveller-form [searchRequest]="searchRequest" (setOwnerEvent)="setOwner($event)">
    </app-review-flight-add-traveller-form>
  </ng-container>
  <ng-container *ngIf="anotherTraveller">
    <app-review-flight-select-travellers (selectTravellerEvent)="selectTraveller($event)"
      (unSelectTravellerEvent)="unSelectTraveller($event)" (deleteTravellerEvent)="deleteTraveller($event)"
      [travellers]="noAuthTravellers">
    </app-review-flight-select-travellers>
    <ng-container *ngIf="searchRequest.travellers > 1 && searchRequest.travellers > noAuthTravellers.length">
      <button class="btn  navBtn" (click)="openAddTravellerDialog()">
        Add another Traveller
      </button>
    </ng-container>
  </ng-container>
</ng-container> -->
<div class="navigationButtons">
  <!-- <div class="col-12 mb-4" *ngIf="token">
    <div class="row justify-content-center">
      <ng-container *ngIf="selectedTravellers.length != searchRequest.travellers && noAuthTravellers.length > 0">
        <div class="alert alert-danger" role="alert">
          <span class="Font-Family">you should select ({{searchRequest.travellers}}) Traveller(s) to proceed</span>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTravellers.length == searchRequest.travellers && noAuthTravellers.length > 0">
        <div class="alert alert-success" role="alert">
          <span class="Font-Family">Thanks, you are now able to complete booking</span>
        </div>
      </ng-container>
    </div>
  </div> -->
  <div class="col-12">
    <div class="row align-items-center justify-content-center flex-row">
      <button class="btn previousStepBtn navBtn Font-Family" (click)="previousStepEvent.emit()">Back To <br> Review Flight</button>
      <button class="btn nextStepBtn navBtn Font-Family" [disabled]="!validTravellers" *ngIf="payLater!='true'"
        (click)="nextStepEvent.emit()">
        Continue to Payment
      </button>
      <button class="btn nextStepBtn navBtn Font-Family "  *ngIf="payLater=='true'"
      (click)="createPnr()">
      Contiue Booking<i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
    </button>
    <!-- &<br><p style="font-size: 35px;text-transform: uppercase;">pay within 24 hours</p> -->
    <!-- *ngIf="loading" -->
    </div>
  </div>
</div>
