<div class="details-traveler" *ngIf="!token">
    <div class="formTitle">
        <div class="d-flex justify-content-between">
            <span class="Font-Family">Contact Information</span>
        </div>
    </div>
    <div class="col-12" [formGroup]="form">
        <div class="row">
            <div class="col-4 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Full Name</mat-label>
                        <input matInput formControlName="cardHolderName" pattern="^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$"
                            type="text" placeholder="Full Name" />
                    </mat-form-field>
                    <div *ngIf="forminfo['cardHolderName'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                    <mat-error *ngIf="forminfo['cardHolderName'].touched || forminfo['cardHolderName'].dirty">
                        <mat-error *ngIf="forminfo['cardHolderName'].errors?.required" class="Font-Family">
                            Full Name Is Required
                        </mat-error>
                        <mat-error *ngIf="forminfo['cardHolderName'].errors?.pattern" class="Font-Family">
                            Enter your name correctly

                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <!-- <div class="col-4 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone"  type="phone" placeholder="phone Number" />
                    </mat-form-field>
                    <div *ngIf="forminfo['phone'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                    <mat-error *ngIf="forminfo['phone'].touched || forminfo['phone'].dirty">
                        <mat-error *ngIf="forminfo['phone'].errors?.required">
                            Phone Number Is Required
                        </mat-error>
                        <mat-error *ngIf="forminfo['phone'].errors?.pattern">
                            Phone Number Is Type Only Numbers
                        </mat-error>
                        <mat-error *ngIf="forminfo['phone'].errors?.minlength">
                            Phone Number Is At least 8 numbers
                        </mat-error>
                    </mat-error>
                </div>
            </div> -->
            <div class="col-4 position-relative" >
                <div class="d-flex justify-content-between align-items-baseline">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Email</mat-label>
                        <input matInput formControlName="email" placeholder="name@email.com" type="email"
                            name="email" />
                    </mat-form-field>
                    <div *ngIf="spinner" class="airLineSpinner ml-2">
                        <mat-spinner></mat-spinner>
                    </div>
                    <div *ngIf="right" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                </div>
                <mat-error class="mat-error" *ngIf="errorCheckEmail">
                    <span>{{message}}</span>
                </mat-error>
                <mat-error *ngIf="forminfo['email'].touched || forminfo['email'].dirty">
                    <mat-error *ngIf="forminfo['email'].errors?.required" class="Font-Family">
                        Email Is Required
                    </mat-error>
                    <mat-error *ngIf="forminfo['email'].errors?.pattern " class="Font-Family">
                        Email Is Invalid
                    </mat-error>
                </mat-error>
            </div>

            <div class="col-4 position-relative" >
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Phone Number</mat-label>
                        <input matInput required formControlName="phone" placeholder="phone number" type="text"
                            name="phone" />
                    </mat-form-field>
                </div>
                <mat-error *ngIf="forminfo['phone'].touched || forminfo['phone'].dirty">
                    <mat-error *ngIf="forminfo['phone'].errors?.required" class="Font-Family">
                        Phone Number Is Required
                    </mat-error>
                    <mat-error *ngIf="forminfo['phone'].errors?.pattern" class="Font-Family">
                        Phone Number Is Type Only Numbers
                    </mat-error>
                </mat-error>
                <div *ngIf="forminfo['phone'].valid" class="ml-2 validateMark">
                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                </div>
            </div>

            <!-- ------------------------------------------------- -->
            <!-- <div class="col-4  position-relative">
                <div class="d-flex justify-content-between ">
                    <mat-form-field appearance="fill">
                        <mat-label>Phone Number</mat-label>
                        <input matInput required   placeholder="Phone Number" type="text"
                        name="phone" formControlName="phone" />
                    </mat-form-field>

                        <div *ngIf="forminfo['phone'].valid" class="ml-2 validateMark">
                            <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                        </div>
                </div>
                <mat-error *ngIf="forminfo['phone'].touched || forminfo['phone'].dirty">
                    <mat-error *ngIf="forminfo['phone'].errors?.required">
                        Phone Number Is Required
                    </mat-error>
                    <mat-error *ngIf="forminfo['phone'].errors?.pattern">
                        Phone Number Is Type Only Numbers
                    </mat-error>
                    <mat-error *ngIf="forminfo['phone'].errors?.minlength">
                        Phone Number Is At least 8 numbers
                    </mat-error>
                </mat-error>
            </div> -->
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Country</mat-label>
                        <input matInput formControlName="CreditCardCountry" type="text"
                            placeholder="Select your Country" (focus)="getfilterCountryInfo()"
                            [matAutocomplete]="country_id" required />
                    </mat-form-field>
                    <mat-autocomplete autoActiveFirstOption #country_id="matAutocomplete">
                        <mat-option *ngFor="let option of filteredCountryInfo | async" [value]="option.name">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <div *ngIf="forminfo['CreditCardCountry'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                    <mat-error *ngIf="forminfo['CreditCardCountry'].touched || forminfo['CreditCardCountry'].dirty">
                        <mat-error *ngIf="forminfo['CreditCardCountry'].errors?.someProp">
                            {{forminfo['CreditCardCountry'].errors?.someProp}}
                        </mat-error>
                        <mat-error *ngIf="forminfo['CreditCardCountry'].errors?.required" class="Font-Family">
                            Country Is Required
                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item letters">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">City</mat-label>
                        <input matInput formControlName="CreditCardCity" type="text" placeholder="City" required />
                    </mat-form-field>
                    <div *ngIf="forminfo['CreditCardCity'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                    <mat-error *ngIf="forminfo['CreditCardCity'].touched || forminfo['CreditCardCity'].dirty">
                        <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern" class="Font-Family"> Please Typr
                            Only
                            Letters Only</mat-error>
                        <mat-error *ngIf="forminfo['CreditCardCity'].errors?.required " class="Font-Family">
                            City Is Required
                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Address</mat-label>
                        <input matInput formControlName="CreditCardstreetAddress" type="text" placeholder="Address"
                            required />
                    </mat-form-field>
                    <div *ngIf="forminfo['CreditCardstreetAddress'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                    <mat-error
                        *ngIf="forminfo['CreditCardstreetAddress'].touched || forminfo['CreditCardstreetAddress'].dirty">
                        <mat-error *ngIf="forminfo['CreditCardstreetAddress'].errors?.required " class="Font-Family">
                            Address Is Required
                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label class="Font-Family">Zip</mat-label>
                        <input matInput formControlName="CreditCardZip" type="text" placeholder="Zip" required />
                    </mat-form-field>
                    <div *ngIf="forminfo['CreditCardZip'].valid" class="ml-2 validateMark">
                        <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                    </div>
                </div>
                <mat-error *ngIf="forminfo['CreditCardZip'].touched || forminfo['CreditCardZip'].dirty">
                    <mat-error *ngIf="forminfo['CreditCardZip'].errors?.pattern" class="Font-Family"> Please Type Only
                        Numbers
                    </mat-error>
                    <mat-error *ngIf="forminfo['CreditCardZip'].errors?.required " class="Font-Family"> Zip
                        Is Required
                    </mat-error>
                </mat-error>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="formGroupAddTraveller">
    <ng-container formArrayName="formArrayAddTraveller">
        <ng-container *ngFor="let f of memberTraveller.controls; let i = index ;">
            <ng-container [formGroupName]="i">
                <div class="details-traveler">
                    <div class="formTitle">
                        <div class="d-flex justify-content-between">
                            <span class="Font-Family">Traveller<strong style="color: #FE521A;"> {{' '+(1 + i)+'
                                    '}}</strong> Information <strong
                                    style="color: #FE521A;">({{allTraveller[i]}})</strong></span>
                            <a style="color: red" routerLink="/sign-in" *ngIf="i==0&& !token" class="Font-Family">login
                                if you have an account</a>
                            <a style="cursor: pointer; color: red" (click)="selectFamily(familyContent, i)" *ngIf=" token"
                            class="Font-Family">Add traveler <span class="plus-family">+</span></a>
                          </div>
                        </div>
                        <div class="col-12">
                          <input formControlName="PassengerType"  [(ngModel)]="allTraveller[i]" hidden>
                          <div class="row">
                            <div class="col-4">
                              <mat-form-field appearance="fill">
                                    <mat-label class="Font-Family">First Name</mat-label>
                                    <input matInput required formControlName="first_name" type="text" [(ngModel)]="getFamliyMember[i].firstName"
                                        name="firstName" />
                                </mat-form-field>
                                <div *ngIf="formMember[i].get('first_name').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                                <mat-error
                                    *ngIf="formMember[i].get('first_name').dirty || formMember[i].get('first_name').touched">
                                    <mat-error *ngIf="formMember[i].get('first_name').errors?.required"
                                        class="Font-Family">
                                        First Name Is Required
                                    </mat-error>
                                    <mat-error *ngIf="formMember[i].get('first_name').errors?.pattern"
                                        class="Font-Family">
                                        Space Not Allowed
                                    </mat-error>
                                </mat-error>
                            </div>
                            <div class="col-4 remove-astrick">
                                <mat-form-field appearance="fill">
                                    <mat-label class="Font-Family">Middle Name</mat-label>
                                    <input matInput type="text" formControlName="middle_name" name="MiddleName" [(ngModel)]="getFamliyMember[i].middleName" />
                                </mat-form-field>
                                <!-- <div *ngIf="formMember[i].get('middle_name').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div> -->
                                <!-- <mat-error *ngIf="formMember[i].get('middle_name').errors?.pattern">
                                    Space Not Allowed
                                </mat-error> -->
                            </div>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label class="Font-Family">Last Name</mat-label>
                                    <input matInput required formControlName="last_name" type="text" name="LastName" [(ngModel)]="getFamliyMember[i].lastName"/>
                                </mat-form-field>
                                <div *ngIf="formMember[i].get('last_name').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                                <mat-error
                                    *ngIf="formMember[i].get('last_name').dirty || formMember[i].get('last_name').touched">
                                    <mat-error *ngIf="formMember[i].get('last_name').errors?.required"
                                        class="Font-Family">
                                        Please Enter Last Name
                                    </mat-error>
                                    <mat-error *ngIf="formMember[i].get('last_name').errors?.pattern"
                                        class="Font-Family">
                                        Space Not Allowed
                                    </mat-error>
                                </mat-error>
                            </div>
                            <div class="col-4">
                                <mat-form-field appearance="fill">
                                    <mat-label class="Font-Family">Gender</mat-label>
                                    <input type="text" required matInput formControlName="gender" name="gender" [(ngModel)]="getFamliyMember[i].gender"
                                        placeholder="Select your gender" [matAutocomplete]="gender" />
                                    <mat-autocomplete #gender="matAutocomplete">
                                        <mat-option *ngFor="let option of genders" [value]="option.name">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div *ngIf="formMember[i].get('gender').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                                <mat-error
                                    *ngIf="formMember[i].get('gender').dirty || formMember[i].get('gender').touched">
                                    <mat-error *ngIf="formMember[i].get('gender').errors?.required" class="Font-Family">
                                        Gender is Required
                                    </mat-error>
                                </mat-error>
                            </div>
                            <ng-container formGroupName="passport">
                                <div class="col-4 calendar mb-4" formGroupName="travellerDob">
                                    <div class="date-form d-flex">
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label class="Font-Family">Date of Birth</mat-label>
                                                <mat-select required formControlName="traveller_dob_month" [(ngModel)]="getFamliyMember[i].birthOfMonth"
                                                    (ngModelChange)="checkDate(i,'travellerDob')">
                                                    <mat-option *ngFor="let month of months"
                                                        [value]="month.num">{{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input formControlName="traveller_dob_day" [(ngModel)]="getFamliyMember[i].birthOfDay"
                                                    (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                    (focusout)="format($event)" placeholder="DD"
                                                    class="example-right-align" maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill" >
                                                <input formControlName="traveller_dob_year" [(ngModel)]="getFamliyMember[i].birthOfYear"
                                                    (ngModelChange)="checkDate(i,'travellerDob')" type="text"
                                                    placeholder="YYYY" maxlength="4" minlength="4"
                                                    (keyup)="allTraveller[i]=='adult'?calculateDiff(i):''"
                                                    class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').dirty ||formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').touched">
                                        <mat-error class="Font-Family"
                                            *ngIf="formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').errors?.required">
                                            Date of Birth is Required
                                        </mat-error>
                                        <div class="b-2" *ngIf="errorMessage[i]"> {{errorMessage[i]}}</div>
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').dirty || formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').touched">

                                    </mat-error>
                                    <div *ngIf="formMember[i].get('passport').get('travellerDob')?.get('traveller_dob_year').valid
                                 && formMember[i].get('passport').get('travellerDob').get('traveller_dob_day').valid &&!errorMessage[i]"
                                        class="ml-2 validateMarkDate">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check"
                                            title="check" />
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-4" *ngIf="!payLater">
                                <mat-form-field appearance="fill">
                                    <mat-label class="Font-Family">Passenger Type</mat-label>
                                    <input type="text" matInput formControlName="" name="travellerCountry"
                                        placeholder="Select your Passenger Type" [matAutocomplete]="Relation_id" />
                                    <mat-autocomplete #Relation_id="matAutocomplete">
                                        <mat-option *ngFor="let option of relations" [value]="option.name">
                                            {{ option.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <!-- <div *ngIf="formMember[i].get('PassengerType').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div> -->
                                <mat-error
                                    *ngIf="formMember[i].get('PassengerType').dirty || formMember[i].get('PassengerType').touched">
                                    <mat-error *ngIf="formMember[i].get('PassengerType').errors?.required"
                                        class="Font-Family">
                                        Passenger is Required
                                    </mat-error>
                                </mat-error>
                                <div *ngIf="formMember[i].get('PassengerType').valid" class="ml-2 validateMark">
                                    <img src="assets/icons/check.png" class="check-icon" alt="check" title="check" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-traveler">
                    <div class="formTitle">
                        <span class="Font-Family">Passport Information <strong style="color: #FE521A;">({{1 + i}}
                                {{allTraveller[i]}})</strong></span>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <ng-container formGroupName="passport">
                                <div class="col-4">
                                    <mat-form-field appearance="fill">
                                        <mat-label class="Font-Family">Passport Number</mat-label>
                                        <input matInput required formControlName="passport_number" [(ngModel)]="getFamliyMember[i].passport_number"
                                            placeholder="000000000" type="text" name="passportNumber" />
                                    </mat-form-field>
                                    <div *ngIf="formMember[i].get('passport').get('passport_number').valid"
                                        class="ml-2 validateMark">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check"
                                            title="check" />
                                    </div>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('passport_number').dirty || formMember[i].get('passport').get('passport_number').touched">
                                        <mat-error
                                            *ngIf="formMember[i].get('passport').get('passport_number').errors?.required"
                                            class="Font-Family">
                                            Passport Number is Required
                                        </mat-error>
                                    </mat-error>
                                </div>
                                <div class="col-4 calendar mb-4" formGroupName="issueDate">
                                    <div class="date-form d-flex">
                                        <input matInput>
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label class="Font-Family">Issued Date</mat-label>
                                                <mat-select required (ngModelChange)="checkDate(i,'issueDate')"  [(ngModel)]="getFamliyMember[i].issueOfMonth"
                                                    formControlName="issue_date_month">
                                                    <mat-option *ngFor="let month of months" [value]="month.num">
                                                        {{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'issueDate')" [(ngModel)]="getFamliyMember[i].issueOfDay"
                                                    formControlName="issue_date_day" placeholder="DD"
                                                    (focusout)="format($event)" class="example-right-align"
                                                    maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'issueDate')" [(ngModel)]="getFamliyMember[i].issueOfYear"
                                                    formControlName="issue_date_year" placeholder="YYYY" maxlength="4"
                                                    minlength="4" class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').valid
                                    &&formMember[i].get('passport').get('issueDate').get('issue_date_day').valid "
                                        class="ml-2 validateMarkDate">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check"
                                            title="check" />
                                    </div>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').dirty ||formMember[i].get('passport').get('issueDate')?.get('issue_date_year').touched">
                                        <mat-error class="Font-Family"
                                            *ngIf="formMember[i].get('passport').get('issueDate')?.get('issue_date_year').errors?.required">
                                            issue Date is Required
                                        </mat-error>
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('issueDate').get('issue_date_day').dirty || formMember[i].get('passport').get('issueDate').get('issue_date_day').touched">

                                    </mat-error>
                                </div>
                                <div class="col-4 calendar mb-4" formGroupName="expireDate">
                                    <div class="date-form d-flex">
                                        <input matInput>
                                        <div class="month col-5">
                                            <mat-form-field appearance="fill" [floatLabel]="'always'">
                                                <mat-label class="Font-Family">Expire Date</mat-label>
                                                <mat-select required (ngModelChange)="checkDate(i,'expireDate')" [(ngModel)]="getFamliyMember[i].expiryOfMonth"
                                                    formControlName="expiry_date_month">
                                                    <mat-option *ngFor="let month of months" [value]="month.num">
                                                        {{month.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="days col-3">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'expireDate')" [(ngModel)]="getFamliyMember[i].expiryOfDay"
                                                    formControlName="expiry_date_day" placeholder="DD"
                                                    (focusout)="format($event)" class="example-right-align"
                                                    maxlength="2" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="years col-4">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input type="text" (ngModelChange)="checkDate(i,'expireDate')" [(ngModel)]="getFamliyMember[i].expiryOfYear"
                                                    formControlName="expiry_date_year" placeholder="YYYY" maxlength="4"
                                                    minlength="4" class="example-right-align" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').valid
                                    &&formMember[i].get('passport').get('expireDate').get('expiry_date_day').valid "
                                        class="ml-2 validateMarkDate">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check"
                                            title="check" />
                                    </div>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').dirty ||formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').touched">
                                        <mat-error class="Font-Family"
                                            *ngIf="formMember[i].get('passport').get('expireDate')?.get('expiry_date_year').errors?.required">
                                            issue Date is Required
                                        </mat-error>
                                    </mat-error>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('expireDate').get('expiry_date_day').dirty || formMember[i].get('passport').get('expireDate').get('expiry_date_day').touched">

                                    </mat-error>
                                </div>
                                <div class="col-4">
                                    <mat-form-field appearance="fill">
                                        <mat-label class="Font-Family">Issued Country</mat-label>
                                        <input type="text" required formControlName="issued_country" [(ngModel)]="getFamliyMember[i].issuedCountry"
                                            name="passportIssuedCountry" placeholder="Select your Issued Country"
                                            matInput [matAutocomplete]="issuedCountry" (focus)="getfilterCountry(i)"
                                            (blur)="countryPatchValue(i)" (ngModelChange)="countryPatchValue(i); getCountryObject($event); setPassengerType(i)" />
                                        <mat-autocomplete #issuedCountry="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredCountry | async"
                                                [value]="option.name">
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div *ngIf="formMember[i].get('passport').get('issued_country').valid"
                                        class="ml-2 validateMark">
                                        <img src="assets/icons/check.png" class="check-icon" alt="check"
                                            title="check" />
                                    </div>
                                    <mat-error
                                        *ngIf="formMember[i].get('passport').get('issued_country')?.dirty || formMember[i].get('passport').get('issued_country')?.touched">
                                        <mat-error
                                            *ngIf="formMember[i].get('passport').get('issued_country')?.errors?.someProp">
                                            {{formMember[i].get('passport').get('issued_country')?.errors?.someProp}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="formMember[i].get('passport').get('issued_country')?.errors?.required"
                                            class="Font-Family">
                                            passport Issued Country Is Required
                                        </mat-error>
                                    </mat-error>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</form>
<ng-template #familyContent let-modal>
    <div class="family-member-card" *ngIf="familyMembers">
        <div class="family-member-card-header">
            Add traveler
        </div>
        <ng-container *ngFor=" let member of familyMembers">
            <div class="family-member-card-body" (click)="setFamilyMember(member)" [ngClass]="{'selectedMember': checkFamilySelected(member.id)== true}" >
                <div class="family-member-name">
                    <span class="family-name">{{member.last_name}}</span>
                    <span class="family-name">{{member.first_name}}</span>
                    <span class="family-name">{{member.middle_name}}</span>
                </div>
                <div class="family-member-details" *ngIf="!checkFamilySelected(member.id)"><img  src="assets/images/icons_flights/arrow-point-to-right.svg"  alt="check"
                    title="check"
                        width="35px"></div>
                <div class="family-member-details" *ngIf="checkFamilySelected(member.id)" ><span class="check-text">Already selected</span><span (click)="removeFamilyMember()" *ngIf="member.id===getFamliyMember[formTravellerIndex].familyMemberId" class="checked-text d-none">UnSelected</span></div>
            </div>
        </ng-container>
        <div class="family-member-card-footer">
            <button mat-raised-button color="worn" (click)="modal.close()">Cancel</button>
        </div>
    </div>
</ng-template>
