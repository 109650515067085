<input class="form-control d-flex pointer" readonly [value]="departureDate | date: 'MMM d'"
       (click)="openDesktopCalender()" (focus)="onFocus()" (blur)="onFocus()" style="color: #fd502100; opacity: 0;">
<ng-container>
  <div class="placeHolder element pointer input-search" (click)="openDesktopCalender()">
    <div *ngIf="!isFocused && !departureDate">
      <span class="star">*</span>
      <span>Depart</span>
    </div>
    
    <div *ngIf="departureDate">
      <div class="text-holder-port row">
        <span class="text-holder-up col-12 p-0" style="
        font-weight: 900;
        font-size: 18px;"
        >{{departureDate|date:'MMM d'}}</span>
        <span class="text-holder-down col-12 p-0" style="
        font-size: 13px;"
        >{{departureDate|date:'yyy'}}</span>
      </div>
    </div>
    <div>
      <img style="width: 24px;height: 24px;" title="Depart" alt="location" src="/assets/images/icons/calendar.svg">
    </div>
  </div>
</ng-container>
