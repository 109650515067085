<div class="formContainer hotelsForm">
  <div *ngIf="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{errorMessage}}
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-7 p-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-6 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-hotel-destination-input (setOriginEvent)="setHotelDest($event)"
                [inputValue]=hotelDest></app-hotel-destination-input>
            </div>
          </div>
        </div>
        <div class="col-6 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-check-hotel-date [departureDate]="departureDate"  [returnDate]="returnDate" (setDate)="getDate($event)"></app-check-hotel-date>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-5 p-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-8 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-travellers-classes-input [totalTravellers]=travellers [adult]=adult [child]=child [rooms]="rooms"
                [hotelStatus]="true" (openTravellersDialogEvent)="openTravellersDesktopDialog()">
              </app-travellers-classes-input>
            </div>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="row">
            <div class="inputContainer submitBtn">
              <button (click)="submit()" class="">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
