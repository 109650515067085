<div class="details-traveler">
  <app-navbar></app-navbar>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-4 col-xxl-3 col-lg-5">
        <app-dash-sidebar>

        </app-dash-sidebar>
      </div>
      <div class="col-xl-8 col-xxl-9 col-lg-7">
        <!-- <div class="header-image">
          <h1 class="text-center Font-Family">Welcome Back {{name}}</h1>
          <div class="slider">
            <div class="box" (click)="opedAddMemberDialog()">
              <div class="right-section">
                <div class="header">
                  <img alt=" add travellers" title="Add Family member"
                       src="../../../../assets/images/dashboard/ADD%20-Travellers.svg">
                </div>
                <div class="content">
                  <h4 class=" Font-Family">Add Family member</h4>
                  <h6 class=" Font-Family">When You book your trip you can Automatically select your co-traveller And book faster</h6>
                </div>
              </div>
              <div class="image-add">
                <img alt="Group" title="add image" src="../../../../assets/images/dashboard/Group%2015504.svg">
              </div>
            </div>
          </div>
        </div> -->
        <app-family-members></app-family-members>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
