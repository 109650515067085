import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, EventEmitter, OnInit, Output, Input } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-datePicker',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalunderComponent implements OnInit {

  startDate: any;
  endDate: any;
  @Output() setDate = new EventEmitter()
  @Input() departureDate: Date;
  @Input() returnDate: Date;
  @Input() singleDate = false;
  @Input() title = 'Chose Date';
  viewDate: any;
  tomorrow: any;
  constructor(@Inject(DOCUMENT) private document: Document, private datePipe: DatePipe) {
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  ngOnInit() {
    console.log('23121213');

    if (this.title == 'return') {

      this.viewDate = this.returnDate ? this.returnDate : '';
    } else {
      this.viewDate = this.departureDate ? this.departureDate : '';
    }

    $(".datepicker__input").daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: this.singleDate,
      autoApply: true,
      startDate: this.departureDate ? this.datePipe.transform(this.departureDate, "MM/dd/YYYY") : this.tomorrow,
      endDate: this.returnDate ? this.datePipe.transform(this.returnDate, "MM/dd/YYYY") : this.tomorrow,
      locale: {
        cancelLabel: 'close',
        format: 'MM/DD/YYYY'

      },
      minDate: this.tomorrow,
      onSelect(e){
        $(this).trigger('change');
      }
    });

    $('.datepicker__input').on('apply.daterangepicker', (ev, picker) => {
      this.startDate = picker.startDate;
      this.viewDate = this.startDate
      this.setDate.emit(picker)
      console.log('23121213');
    });

    $('.datepicker__input').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });

  }

}

