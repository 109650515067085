import {Component, OnInit} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-end-timer-reminder-dialog',
  templateUrl: './review-flight-end-timer-reminder-dialog.component.html',
  styleUrls: ['./review-flight-end-timer-reminder-dialog.component.css']
})

export class ReviewFlightEndTimerReminderDialogComponent implements  OnInit{

  constructor(private seoService: SEOService) {
    this.seoUpdate();
  }
  ngOnInit() {
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}


