import {AfterViewChecked, AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {SEOService} from '../../services/seo.service';
import {BlogService} from '../../services/blog/blog.service';
import {Router} from '@angular/router';
import {WindowService} from '../../services/webApis/window.service';
import {MetaTagsService} from '../../services/meta-tags.service';
import {ApisService} from '../../services/apis.service';
import {ThanksModalComponent} from '../../components/review-flight/thanks-modal/thanks-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit, AfterViewChecked {
  showEngine: any = false;
  articles: any;
  isSticky: any;
  autoPlay: any = false;
  title = 'chat-float';
  response = '';
  hasLoaded = false;
  hasLoaded2 = false;
  hasLoadedMain = false;
  hasLoadedMainSec = false;
  elementHeight: any;
  data: any;
  config = {
    title: 'ChatBot',
    subTitle: 'New Way of learning'
  };
  images:any[]=[
    './assets/images/home/carousel/flyallover_flight.webp',
    './assets/images/home/carousel/flyallover_flight_airplane.webp',
    './assets/images/home/carousel/flyallover_flight_cheap_flight.webp',
    './assets/images/home/carousel/flyallover_flight_cheapest_flights.webp'
  ];

  dialogRef: any;
  erorr: any;

  paylater=true;
  constructor(
    private seoService: SEOService,
    private blogService: BlogService,
    private router: Router,
    private windowService: WindowService,
    private metaTagsService: MetaTagsService,
    private apis: ApisService,
  ) {
    this.seoUpdate();
    this.changeOgMeta();
  }
  

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 700;
  }

  ngOnInit(): void {
    this.getAirLines();
  }

  getHeight() {
    const elmnt = document.getElementById('side');
    this.elementHeight = elmnt.offsetHeight;
  }

  ngAfterViewInit() {
    const params = {
      page: 1,
      paginate: 6
    };
    this.blogService.getAllArticles(params).subscribe((res: any) => {
      this.articles = res.data;
    }, err => {
    });
  }

  seoUpdate(): void {
    this.seoService.updateTitle('FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips');
    this.seoService.updateDescription(
      'FlyAllOver providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available,' +
      ' don’t miss the chance and book online now');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/home/metaDataImageHome.png');
  }

  getAirLines() {
    this.apis.getHomeAirLines().subscribe((data: any) => {
        this.data = data.data;
      },
      err => {
        console.log(err);
      });
  }

  changeOgMeta() {
    this.metaTagsService.updateOGMetaTag('og:description', 'FlyAllOver providing is you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available,' +
      ' don’t miss the chance and book online now');
    this.metaTagsService.updateOGMetaTag('og:title', 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips');
    this.metaTagsService.updateTwitterMetaTags('title', 'FlyAllOver - Cheap Flight Tickets, Cheap Airlines, Cheap Tours and Cheap Trips');
    this.metaTagsService.updateTwitterMetaTags('description', 'FlyAllOver providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available,' +
      ' don’t miss the chance and book online now');
  }

  navigateToUrl(param1, param2) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/blog/${param1}/${param2}`])
    );
    this.windowService.nativeWindow.open(url, '_blank');
  }

  setData(message) {
    this.response = message;
  }

  getMessage($event) {
  }

  ngAfterViewChecked() {
    this.hasLoadedMain = true;
  }
}
