<div class="index-page">

<!--    <ng-container *ngIf="loadingOffers">-->
<!--        <div class="offers">-->
<!--            <div class="container">-->
<!--                <div style="display: flex; justify-content: center;">-->
<!--                    <ngx-skeleton-loader count="1" [theme]="{width:'300px'}"></ngx-skeleton-loader>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                    <div class="col" *ngFor="let offer of createRange(3)">-->
<!--                        <a class="post">-->
<!--                            <div class="post-photo" style="background-color: transparent;">-->
<!--                                <ngx-skeleton-loader count="1" [theme]="{width:'100%', height: '100%'}">-->
<!--                                </ngx-skeleton-loader>-->
<!--                            </div>-->
<!--                            <div class="post-description">-->
<!--                                <h4>-->
<!--                                    <ngx-skeleton-loader count="1" [theme]="{width:'150px'}"></ngx-skeleton-loader>-->
<!--                                </h4>-->
<!--                                <p>-->
<!--                                    <ngx-skeleton-loader count="1" [theme]="{width:'100%'}"></ngx-skeleton-loader>-->
<!--                                </p>-->
<!--                                <label>-->
<!--                                    <ngx-skeleton-loader appearance="circle" count="1"-->
<!--                                        [theme]="{height:'15px', width:'15px', 'vertical-align':'super'}">-->
<!--                                    </ngx-skeleton-loader>-->
<!--                                    <ngx-skeleton-loader count="1" [theme]="{width:'100px'}"></ngx-skeleton-loader>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->
<!--    <div class="offers also-read" *ngIf="!loadingOffers && offersExist">-->
<!--        <div class="container">-->
<!--            <h2 class="title text-center mb-5">Check Out Our Incredible Offers</h2>-->
<!--            <div class="row">-->
<!--                <div class="col-md-4" *ngFor="let offer of offers">-->

<!--                    <app-offer-card [offer]="offer"></app-offer-card>-->

<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="subscribe">-->
<!--        <div class="container">-->
<!--            <div class="row align-items-center">-->
<!--                <div class="col-md">-->
<!--                    <p>Get emails from FlyAllOver for exclusive access to benefits & discounts on flight, hotels, tours and more!</p>-->
<!--                </div>-->
<!--                <div class="col-md">-->
<!--                    <form (submit)="submitSubscription()" class="border">-->
<!--                      <input [formControl]="emailSubscription" type="email"-->
<!--                             placeholder="Enter your email address here" />-->
<!--                      <button *ngIf="!spinner" type="submit" [disabled]="message === 'Successfully Subscribed!'">-->
<!--                        <img title="Email Imade" alt="mail" src="./assets/images/index/mail.svg" width="30">-->
<!--                      </button>-->
<!--                        <i *ngIf="spinner" class="fas fa-spinner fa-spin"></i>-->
<!--                    </form>-->

<!--                    <span id="subscriptionMsg" class="d-none">{{message}}</span>-->
<!--                    <app-print-error [control]="emailSubscription"></app-print-error>-->

<!--                </div>-->

<!--            </div>-->
<!--            <div class="captcha text-right" *ngIf="showCaptchaComponent">-->
<!--                <re-captcha required (resolved)="resolved($event)" [siteKey]="siteKey">-->
<!--                </re-captcha>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <div class="blog also-read" *ngIf="!loading && articles.length >0">
        <div class="container">
            <owl-carousel-o [options]="carouselOptions">
                <ng-container *ngFor="let article of articles; let i = index">
                    <ng-template carouselSlide>
                        <app-article-card [post]="article" [posts]="articles"></app-article-card>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
    <div class="blog" *ngIf="loading">
        <div class="container">
            <div class="row">
                <div class="col-md-4 post-carousel" *ngFor="let post of createRange(3)">
                    <div class="post">
                        <div class="photo">
                            <div class="overlay p-0">
                                <ngx-skeleton-loader count="1" [theme]="{width: '100%', height: '100%'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div class="description">
                            <ngx-skeleton-loader count="1" [theme]="{width: '200px', 'max-width': '100%'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{width: '150px'}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader count="2" [theme]="{width: '100%'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
