import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-loading-flight-results-dialog',
  templateUrl: './loading-flight-results-dialog.component.html',
  styleUrls: ['./loading-flight-results-dialog.component.css']
})
export class LoadingFlightResultsDialogComponent implements OnInit {

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  
  }

}
