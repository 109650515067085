import {Component, Inject, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {DeleteMemberComponent} from '../../../components/dashboard/delete-member/delete-member.component';
import {AddMemberComponent} from '../../../components/dashboard/add-family-member/add-member.component';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';

@Component({
  selector: 'app-travel-details',
  templateUrl: './travel-details.component.html',
  styleUrls: ['./travel-details.component.css']
})
export class TravelDetailsComponent implements OnInit {
  token: any;
  ImagePath: any;
  name: any;
  dialogRef: any;

  constructor(private apis: ApisService, private cookie: CookieService, private router: Router, public datePipe: DatePipe, private localStorage: LocalStorageService,
              @Inject(DOCUMENT) private document: Document, private matDialog: MatDialog) {
  }

  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: false,
    merge: true,
  };

  ngOnInit(): void {
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
    this.getAccountInfo();
  }

  getAccountInfo() {
    this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.name = data.data.first_name.toUpperCase() + ' ' + data.data.last_name.toUpperCase();
      },
      err => {
        console.log(err);
      });
  }

  opedAddMemberDialog() {
    this.dialogRef = this.matDialog.open(AddMemberComponent, {
      panelClass: 'ReviewFlightAddMemberDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '60%',
    });
  }
}
