import {AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ApisService} from '../../services/apis.service';
import {AddMemberComponent} from '../dashboard/add-family-member/add-member.component';
import {DeleteMemberComponent} from '../dashboard/delete-member/delete-member.component';
import {LocalStorageService} from '../../services/webApis/local-storage.service';


@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.css']
})

export class OwnerComponent implements OnInit, OnChanges, AfterViewInit {
  selectable = true;
  @Input() searchRequest: any;
  @Output() selectTravellerEvent = new EventEmitter();
  @Output() unSelectTravellerEvent = new EventEmitter();
  ownerFlag = true;
  addTraveler = false;
  showTraveler = false;
  passportInfo = false;
  addPassport = false;
  memberId: any;
  memberName: any;
  memberRelation: any;
  token: any;
  name: any;
  countries: any;
  relations: any;
  loading = true;
  dialogRef: any;
  owner: any;
  noPassport: any = [];
  localFlight: any;
  @Input() getCountries1;
  @Input() accountInfo;
  @Input() OwnerInfo;
  @Input() familyMembers;
  @Input() familyRelation1;
  @Output() countriesOut = new EventEmitter();
  @Output() accountinfo = new EventEmitter();
  @Output() ownerinfo = new EventEmitter();
  @Output() familyMember2 = new EventEmitter();
  @Output() familyRelation = new EventEmitter();

  constructor(private apis: ApisService, private cookie: CookieService, private router: Router, public datePipe: DatePipe,
              @Inject(DOCUMENT) private document: Document, private matDialog: MatDialog, private localStorage: LocalStorageService,) {
  }

  ngOnInit(): void {
    this.countriesOut.emit();
    this.accountinfo.emit();
    this.ownerinfo.emit();
    this.familyMember2.emit();
    this.token = this.localStorage.getItem('flyallover_token');
  }

  ngAfterViewInit() {
    this.getCountries();

    this.getAccountInfo();
    this.owner = this.OwnerInfo;
  }

  getCountries() {
    this.countries = this.getCountries1;
  }

  getAccountInfo() {
    if (this.accountInfo) {
      this.name = this.accountInfo.data.first_name.toUpperCase() + ' ' + this.accountInfo.data.last_name.toUpperCase();
    }
  }

  ngOnChanges(changes) {
    if (changes.currentValue) {
      const countryFrom = changes.currentValue.fullDestination.split(',')[2].trim().toLowerCase();
      const CountryTo = changes.currentValue.fullOrigin.split(',')[2].trim().toLowerCase();
      if (countryFrom === CountryTo) {
        this.localFlight = true;
      }
    }
    this.owner = this.OwnerInfo;
    this.loading = false;
  }

  opedAddMemberDialog() {
    this.dialogRef = this.matDialog.open(AddMemberComponent, {
      panelClass: 'ReviewFlightAddMemberDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '60%',
    });
  }

  info(id) {
    this.addPassport = false;
    this.passportInfo = false;
    this.memberId = id;
  }

  getPassportInfo(id, name, relation = '') {
    this.memberId = id;
    this.memberName = name;
    this.memberRelation = relation;
  }

  newPassport(id) {
    this.memberId = id;
  }

  openDialog(id): void {
    this.dialogRef = this.matDialog.open(DeleteMemberComponent, {
      panelClass: 'delete-member',
      width: '50%',
      data: id
    });
  }

  closePassport() {
    this.passportInfo = false;
  }

  closeMember() {
    this.showTraveler = false;
  }

  closeAddPassport() {
    this.addPassport = false;
  }

  toggleCheck(e, member) {
    
    if (e.checked == true) {
      this.selectTravellerEvent.emit(member);
    } else {
      this.unSelectTravellerEvent.emit(member);
    }

    if (!this.localFlight && !member.passport) {
      this.noPassport[this.owner.id] = true;
    }
  }

}
