<!-- ............................................. IN HOME PAGE .......................................................... -->
<div class="container">
  <div class="row">
    <div class="col-12 pt-5 ">
      <h3 class="fao_mobile_title_large text-center">Things To Do</h3>
      <div
        style="
          background-color: #000000;
          height: 3px;
          width: 90px;
          margin: auto;
        "
      ></div>
    </div>
    <ng-container *ngFor="let item of data; let i = index">
      <div *ngIf="i <3" class="col col-12 col-md-12 col-lg-4 p-16px">

          <div class="things card-body">
            <div class="things-img">
              <a [routerLink]="['../things-to-do/', item.city?.name,item.slug]">
              <img
                src="{{ item.images[0].full_url }}"
                alt="{{ item.images[0].file_name }}"
                class="rounded w-100"
                height="250px"
              />
              <div >
                <h3 class="fao_mobile_title_meduim">
                  {{ item.name }}
                </h3>
              </div>
              </a>
            </div>
            <div
              style="
                background-color: #ddd4d4;
                height: 1px;
                width: 95%;
                margin: auto;
              "
            ></div>
            <div>
              <p class="fao_mobile_prgraph">
                {{ item.about | slice:0:120 }} ....
              </p>
              <div class="center-class">
                <button class="fao_des_btn_med  mb-4" [routerLink]="['../things-to-do/', item.city?.name,item.slug]">
                  MORE DETAILS
                </button>
              </div>
            </div>
          </div>
      </div>
    </ng-container>

    <div class="booking d-flex justify-content-center mb-64px mb-5">
      <button class="fao_des_btn_large  my-4" [routerLink]="['/things-to-do']" >
        View More
      </button>
    </div>

<!--    <div class="col-12 d-flex justify-content-center mb-64px btn-viewMore">-->
<!--      <a href="/things-to-do" class="show-more"><h4>View More</h4>-->
<!--        <i aria-hidden="true" class="fa-fw fas fa-arrow-right hndel-icon"></i-->
<!--      ></a>-->
<!--    </div>-->
  </div>
</div>
