<div class="home-offers">
  <div class="container handel">
    <div class="section-header text-center">
      <h5 class="fao_mobile_title_large  mt-3"> Offers</h5>
      <div
        style="
          background-color: #000000;
          height: 5px;
          width: 70px;
          margin: auto;
          border-radius: 2px;
        "
        class="mb-5"
      ></div>
    </div>
    <div class="row ml-5">
      <owl-carousel-o *ngIf="data" [options]="customOptions" class="d-flex justify-content-center" >
        <ng-container *ngFor="let item of data | reverse;  let i = index" class="">
          <ng-container *ngIf="i<= 10">
            <ng-template carouselSlide >
              <div class="col col-md-12 col-lg-12" >
                <div class="offer card-body ">
                  <div class="offer-img">
                    <a [routerLink]="['offers/', item.slug]">
                    <img
                      src="{{ item.images[0].full_url }}"
                      alt="{{ item.images[0].file_name }}"
                      class="w-100"
                      height="200px"
                    />
                  </a>
                    <div >
                      <a style="text-decoration: none;" [routerLink]="['/offers/', item.slug]">
                      <h4 class="fao_mobile_title_meduim text-center mt-2">
                        {{ item.name }}
                      </h4>
                      </a>
                      <h4 class="d-flex justify-content-center fw-bold pt-1" >
                        <span class=" pt-1 Datahandel Font-family">{{ item.departure_date | date: "MM/dd/yyyy" }} </span>
                        <span class=" text-dark px-2 fs-3 Font-family"> To </span>
                        <span class=" pt-1 Datahandel Font-family">{{ item.return_date | date: "MM/dd/yyyy" }}    </span>
                      </h4>
                    </div>
                  </div>
                  <div
                    style="
                      background-color: #ddd4d4;
                      height: 1px;
                      width: 95%;
                      margin: auto;
                    "
                  ></div>
                  <div>
                    <h3 class="fao_mobile_prgraph">
                      {{ item.offer_description |slice:0:100}}...
                    </h3>
                    <div class="line-height px-4">
                      <h4 class="price end_div Font-family">$ {{item.price}}</h4>
                       <span class="d-flex justify-content-end text-muted Font-family">Round Trip</span>
                    </div>
                    <div class="center-class">
                      <button class="fao_des_btn_med mt-4" [routerLink]="['/offers/', item.slug]"  >
                        MORE DETAILS
                      </button>
                    </div>
                  </div>
                  <!-- box-side -->
                  <!-- <div class="offerPrice text-center">
                    <small class="nightNum"
                      >{{ item.airport_origin.city_name }}
                      <span class="fw-bold text-danger"> To </span
                      >{{ item.airport_destination.city_name }}
                    </small>
                    <p class="text-center flightClass">{{ item.class }}</p>
                  </div> -->
                </div>
              </div>
            </ng-template>
          </ng-container>


        </ng-container>
      </owl-carousel-o>
    </div>
    <!-- <div class="col-12 d-flex justify-content-center">
      <a href="/offers" class="show-more">   <h4> More Offers</h4>
        <i aria-hidden="true" class="fa-fw fas fa-arrow-right mt-7"></i>
      </a>
    </div> -->
  </div>
</div>
