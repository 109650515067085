import {AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddMemberComponent} from '../../dashboard/add-family-member/add-member.component';
import {DeleteMemberComponent} from '../../dashboard/delete-member/delete-member.component';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {SEOService} from '../../../services/seo.service';
import {log} from "util";

@Component({
  selector: 'app-review-flight-family-members',
  templateUrl: './review-flight-family-members.component.html',
  styleUrls: ['./review-flight-family-members.component.css']
})
export class ReviewFlightFamilyMembersComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectable;
  @Input() searchRequest: any;
  @Output() selectTravellerEvent = new EventEmitter();
  @Output() unSelectTravellerEvent = new EventEmitter();
  addTraveler = false;
  showTraveler = false;
  passportInfo = false;
  addPassport = false;
  memberId: any;
  memberName: any;
  memberRelation: any;
  token: any;
  name: any;
  countries: any;
  relations: any;
  dialogRef: any;
  owner: any;
  noPassport: any = [];
  localFlight: any;
  newTempMembers: any = [];
  selected:boolean = false;
  @Input() getCountries1;
  @Input() accountInfo;
  @Input() OwnerInfo;
  @Input() familyMembers;
  @Input() familyRelation1;
  @Output() countriesOut = new EventEmitter();
  @Output() accountInfo2 = new EventEmitter();
  @Output() ownerinfo = new EventEmitter();
  @Output() familyMember2 = new EventEmitter();
  @Output() familyRelation = new EventEmitter();

  constructor(
    private apis: ApisService, private cookie: CookieService,
    private router: Router, public datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog, private localStorage: LocalStorageService,
    private seoService: SEOService) {
  }

  ngOnInit(): void {
    this.token = this.localStorage.getItem('flyallover_token');
    this.countriesOut.emit();
    this.accountInfo2.emit();
    this.ownerinfo.emit();
    this.familyMember2.emit();
    this.seoUpdate();
  }

  ngAfterViewInit() {
    this.getAccountInfo();
    this.getCountries();
    this.owner = this.OwnerInfo;
  }

  getCountries() {
    this.countries = this.getCountries1;
  }

  getAccountInfo() {
    if (this.accountInfo) {
      this.name = this.accountInfo.data.first_name.toUpperCase() + ' ' + this.accountInfo.data.last_name.toUpperCase();
    }
  }

  seoUpdate() {

    this.seoService.updateTitle('Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnChanges(changes) {
    if (changes.searchRequest && changes.searchRequest.currentValue) {
      const countryFrom = changes.searchRequest.currentValue.fullDestination.split(',')[2].trim().toLowerCase();
      const CountryTo = changes.searchRequest.currentValue.fullOrigin.split(',')[2].trim().toLowerCase();
      if (countryFrom === CountryTo) {
        this.localFlight = true;
      }
    }
  }

  opedAddMemberDialog() {
    this.dialogRef = this.matDialog.open(AddMemberComponent, {
      panelClass: 'ReviewFlightAddMemberDialog',
      autoFocus: false,
      width: '80%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.newTempMembers.push(data);
        this.familyMember2.emit();
      }
    });
  }


  info(id) {
    this.addPassport = false;
    this.passportInfo = false;
    this.memberId = id;
  }

  getPassportInfo(id, name, relation = '') {
    this.memberId = id;
    this.memberName = name;
    this.memberRelation = relation;
  }

  newPassport(id) {
    this.memberId = id;
  }

  openDialog(id): void {
    this.dialogRef = this.matDialog.open(DeleteMemberComponent, {
      panelClass: 'delete-member',
      width: '50%',
      data: id
    });
  }

  closePassport() {
    this.passportInfo = false;
  }

  closeMember() {
    this.showTraveler = false;
  }

  closeAddPassport() {
    this.addPassport = false;
  }

  toggleCheck(e, member) {
    if(e.checked == true && member.passport == null){
      this.selected = false;
      alert('please add passport number')
      return
    }
    if (e.checked == true) {
      // console.log('islam ===> selectTravellerEvent' ,member);
      this.selectTravellerEvent.emit(member);
    } else {
      this.unSelectTravellerEvent.emit(member);
    }
    if (!this.localFlight && !member.passport) {
      this.noPassport[this.owner.id] = true;
    }
  }

}
