<div>
  <div class="col-12">
    <img src="assets/images/hotels/3.jpg" alt="Room Image">
  </div>
  <div class="col-12">
    <div class="d-flex justify-content-between title">
    <h1>{{hotelDetails.title}} | vacation package</h1>
    <span>Book by:{{hotelDetails.book_by|date:'M/d/yy'}}</span>
  </div>
  <p class="description">{{hotelDetails.vacation_description_1}}</p>
   <div class="row p-0 col-12">
    <div class="col-4 p-0">
      <mat-form-field appearance="fill">
        <mat-label>Number of travellers</mat-label>
        <mat-select #numberValue (selectionChange)="changeNumber($event.value)">
          <mat-option *ngFor="let number of NOTravellers" [value]="number.value">
            {{number.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <!-- item.value*hotelDetails.price -->
     <p class="price">* ${{hotelDetails.price}}= ${{numberOfTravellers*hotelDetails.price }}</p>
    </div>

    <div class="col-4 d-flex justify-content-end">
      <p class="pricePerNight">4 nights from ${{hotelDetails.price}}</p>
      <button (click)="routeToPayment()"  class="btn--book">book</button>
    </div>
   </div>
</div>
</div>
