import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {ApisService} from 'src/app/services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})

export class AccountInfoComponent implements OnInit {
  oldPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
  countries: any;
  token: any;
  firstName: any;
  middleName: any;
  lastName: any;
  countryName: any;
  nationality: any;
  address: any;
  phone: any;
  gender: any;
  email: any;
  birthDate: any;
  passwordForm: any;
  loginSpinner: boolean = false;
  passwordSpinner: boolean = false;
  passwordChange :boolean=false;
  loading: boolean = false;
  passwordError: boolean = false;
  passwordLengthError: boolean = false;
  matchError: boolean = false;
  accountForm = this.fb.group({
    // fb is the formBuilder class
    // all form arrays contain initially one element only
    first_name: this.fb.control(
      [this.fb.control('',) /* array of validators*/
      ],
    ),
    middle_name: this.fb.control(
      [this.fb.control('',)
      ],
    ),
    last_name: this.fb.control(
      [this.fb.control('',)
      ],
    ),
    country: this.fb.control(
      [this.fb.control('',)],
    ),
    nationality: this.fb.control(
      [this.fb.control('',)],
    ),
    address: this.fb.control(
      [this.fb.control('',
        Validators.required
      )],
    ),
    phone: this.fb.control(
      [this.fb.control('',)
      ],
    ),
    gender: this.fb.control(
      [this.fb.control('',)],
    ),
    EM: this.fb.control(
      [this.fb.control('',)],
    ),
    birth_date: this.fb.control(
      [this.fb.control('',)],
    ),
  });

  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    @Inject(DOCUMENT) private document: Document,
    private cookie: CookieService,
    public datepipe: DatePipe,
    private router: Router,
    private localStorage: LocalStorageService,
    private ref: ChangeDetectorRef
  ) {
    this.passwordForm = this.createComposeForm();

  }

  ngOnInit(): void {
    this.ref.detectChanges();
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    }, err => {
    });
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
    if (this.token) {
      this.loading = true;
      this.apis.getAccountInfo(this.token).subscribe((data: any) => {
          localStorage.setItem('user_id', data.data.id);
          this.firstName = data.data.first_name;
          this.middleName = data.data.middle_name;
          this.lastName = data.data.last_name;
          this.countryName = data.data.country_id;
          this.nationality = data.data.nationality_id;
          this.address = data.data.address;
          this.phone = data.data.phone;
          if (data.data.gender == 'M') {
            this.gender = 'Male';
          }
          if (data.data.gender == 'F') {
            this.gender = 'Female';
          }
          this.email = data.data.email;
          this.birthDate = this.datepipe.transform(data.data.date_of_birth, 'yyyy-MM-dd');
          this.loading = false;
        console.log(data)
        },
        err => {
          console.log(err);
        });
    }
  }

  submit(): void {
    this.loginSpinner = true;
    if (this.accountForm.value.gender == 'Male') {
      this.accountForm.value.gender = 'M';
    }
    if (this.accountForm.value.gender == 'Female') {
      this.accountForm.value.gender = 'F';
    }
    this.apis.editProfile(this.token, this.accountForm.value.first_name, this.accountForm.value.last_name, this.accountForm.value.middle_name,
      this.accountForm.value.country, this.accountForm.value.gender, this.accountForm.value.phone
      , this.accountForm.value.birth_date, this.accountForm.value.EM, this.accountForm.value.nationality, this.accountForm.value.address).subscribe((data: any) => {
        if (data.status == true) {
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }
      }, (err: any) => {
        console.log(err);
      }
    );
  }

  submitPassword(): void {
    if (this.passwordForm.controls.newPassword.value == this.passwordForm.controls.confirmPassword.value) {
      this.passwordError = false;
      if (this.passwordForm.value.newPassword.length >= 6) {
        this.passwordSpinner = true;
        this.apis.changePassword(this.token, this.passwordForm.controls.currentPassword.value, this.passwordForm.controls.newPassword.value
          , this.passwordForm.controls.confirmPassword.value).subscribe((data: any) => {
            this.passwordChange = true;
            this.localStorage.setItem('flyallover_token', undefined);
           this.router.navigate(['/sign-in']);
          },
          (err: any) => {
            if (err.error.error.error) {
              this.matchError = true;
              this.passwordLengthError = false;
              this.passwordForm.status = 'INVALID';
            }
            if (err.error.error.new_password[0]) {
              this.passwordLengthError = true;
              this.matchError = false;
              this.passwordForm.status = 'INVALID';
            }
          });
        this.passwordSpinner = false;
        this.passwordError = false;
      } else {
        this.passwordForm.status = 'INVALID';
        this.passwordLengthError = true;
      }
    } else {
      this.passwordForm.status = 'INVALID';
      this.passwordError = true;
    }
  }

  showOld() {
    this.oldPassword = !this.oldPassword;
  }

  showNew() {
    this.newPassword = !this.newPassword;
  }

  showConfirm() {
    this.confirmPassword = !this.confirmPassword;
  }

  createComposeForm(): FormGroup {
    return new FormGroup({
      currentPassword: new FormControl(''),
      newPassword: new FormControl(''),
      confirmPassword: new FormControl(''),
    });
  }
}
