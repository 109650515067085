<h2>My Ticket</h2>
<!-- <div class="confirmation-body" *ngIf="trip_type && trip_type != undefined"
  [ngStyle]="{'display':trip_type ? 'block' : 'none' }"> -->
<!-- <div class="container"> -->

<!-- start confirmation -->
<!-- REMOVED THE CONDITION *ngIf="result_now && !supportMessage" -->
<div class="confirmation">
  <div class="row">
    <div class="col">
      <div class="confirm">
        <div class="success">
          <span>Code</span> <span class="pl-3">{{ ticket.pnr }}</span>
        </div>
        <div>
          <p>Your reservation with Fly All Over is confirmed!</p>
        </div>
        <!-- <div><button class="download-button">Download ticket</button></div> -->
        <div>
          <p>
            If you have any questions please contact us at:
            <a href="mailto:info@flyallover.com">info@flyallover.com</a>
            <a href="tel:888-666-8545">888-666-8545</a>
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="confirm">
        <div class="calculator">
          <div>
            <p>E- ticket Number : {{ ticket.ticket_serial }}</p>
          </div>
          <ul>
            <li *ngIf="ticket.cost">
              <span>Total</span> <span>${{ ticket.cost.toFixed(2) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<!-- end confirmation -->
<!-- </div> -->

<!-- start of "results" class & baggage info  -->
<div class="window">
  <div class="boxHolder">
    <div class="boxHolder-body">
      <!-- start bodybox -->
      <div class="bodybox">
        <div class="results" id="results" *ngIf="ticket">
          <!-- ------------------- start Return ------------------ -->
          <div *ngIf="trip_type.toString().trim() == 'Return'">
            <div>
              <div class="result">
                <div class="result-header first">
                  <div class="items">
                    <div class="group group1" *ngIf="ticket.cost">
                      <!-- ignored offer option -->
                      <div class="item text-right">
                        <p class="price">
                          <span>${{ ticket.cost.toFixed(2) }}</span
                          ><br />(incl. Taxes &amp; Fees)
                        </p>
                      </div>
                      <!-- <div *ngIf="offerParam" class="item text-right">
                      <p class="price offer"><span class="old-price">${{ticket.cost.toFixed(2)}}</span>
                        <span>${{ticket.cost.toFixed(2)}}</span><br />(incl.
                        Taxes &amp; Fees)</p>
                    </div> -->
                    </div>

                    <div class="group group2">
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[0][0]?.DepartureAirport.split(
                              " "
                            )[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[0][0]?.DepartureAirportName
                          }}
                        </p>
                      </div>
                      <div class="item text-center">
                        <img
                          title="flyallover"
                          alt="flyallover"
                          src="../../../../assets/images/airplane_depart.svg"
                          width="30"
                        />
                        <p class="stop">
                          {{ ticket.flight_details[0]?.length - 1 }} stop
                        </p>
                      </div>
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[0][
                              ticket.flight_details[0]?.length - 1
                            ]?.ArrivalAirport.split(" ")[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[0][
                              ticket.flight_details[0]?.length - 1
                            ]?.ArrivalAirportName
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>

                <div class="result-body">
                  <div
                    class="items"
                    *ngFor="
                      let flight of ticket.flight_details[0];
                      let i = index
                    "
                  >
                    <div class="item">
                      <img
                        title="flyallover"
                        alt="flyallover"
                        src="https://c.fareportal.com/n/common/air/3x/{{
                          flight.OperatingAirline
                        }}.png"
                      />
                      <p class="country-air">
                        {{ flight.OperatingAirlineName }}
                      </p>
                    </div>
                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.DepartureDateTime) }}
                      </p>
                      <!-- Mon , Nov 18 , 2019 -->
                      <p class="date">
                        {{ flight.DepartureDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 6:30 PM -->
                    </div>
                    <div class="item text-center">
                      <div class="flight-new-style">
                        <!-- <span class="fare">{{itinerary.prices.FareClassName}}</span> -->
                        <span class="num"
                          >Flight {{ flight.FlightNumber }}</span
                        >
                        <div class="border-line"></div>
                        <span
                          class="layover"
                          *ngIf="layoverTime(ticket.flight_details[0][i + 1])"
                          >Layover:
                          {{
                            layoverTime(
                              ticket.flight_details[0][i + 1]?.FlightLayoverTime
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.ArrivalDateTime) }}
                      </p>
                      <!-- Tue , Nov 19 , 2019 -->
                      <p class="date">
                        {{ flight.ArrivalDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 12:5 PM -->
                    </div>
                    <div class="item text-center">
                      <p class="duration">
                        {{ getDurationString(flight.ElapsedTime) }}
                      </p>
                      <!-- Duration 10h 35m -->
                      <p class="country-shortcut">
                        {{ flight.DepartureAirportName.split(" ")[0] }} (
                        {{ flight.DepartureAirport }} ) <br />
                        {{ flight.ArrivalAirportName.split(" ")[0] }} (
                        {{ flight.ArrivalAirport }} )
                      </p>
                      <!-- Cairo ( JFK ) Frankfurt ( CAI ) -->
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="result-header last">
                  <div class="items">
                    <div class="group group2">
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[1][0]?.DepartureAirport.split(
                              " "
                            )[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[1][0]?.DepartureAirportName
                          }}
                        </p>
                      </div>
                      <div class="item text-center">
                        <img
                          title="flyallover"
                          alt="flyallover"
                          src="../../../../assets/images/airplane_return.svg"
                          width="30"
                        />
                        <p class="stop">
                          {{ ticket.flight_details[1]?.length - 1 }} stop
                        </p>
                      </div>
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[1][
                              ticket.flight_details[1]?.length - 1
                            ]?.ArrivalAirport.split(" ")[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[1][
                              ticket.flight_details[1]?.length - 1
                            ]?.ArrivalAirportName
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>

                <div class="result-body">
                  <div
                    class="items"
                    *ngFor="
                      let flight of ticket.flight_details[1];
                      let i = index
                    "
                  >
                    <div class="item">
                      <img
                        title="flyallover"
                        alt="flyallover"
                        src="https://c.fareportal.com/n/common/air/3x/{{
                          flight.OperatingAirline
                        }}.png"
                      />
                      <p class="country-air">
                        {{ flight.OperatingAirlineName }}
                      </p>
                    </div>
                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.DepartureDateTime) }}
                      </p>
                      <!-- Mon , Nov 18 , 2019 -->
                      <p class="date">
                        {{ flight.DepartureDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 6:30 PM -->
                    </div>
                    <div class="item text-center">
                      <div class="flight-new-style">
                        <!-- <span class="fare">{{itinerary.prices.FareClassName}}</span> -->
                        <span class="num"
                          >Flight {{ flight.FlightNumber }}</span
                        >
                        <div class="border-line"></div>
                        <span
                          class="layover"
                          *ngIf="layoverTime(ticket.flight_details[1][i + 1])"
                          >Layover:
                          {{
                            layoverTime(
                              ticket.flight_details[1][i + 1]?.FlightLayoverTime
                            )
                          }}</span
                        >
                      </div>
                    </div>

                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.ArrivalDateTime) }}
                      </p>
                      <!-- Tue , Nov 19 , 2019 -->
                      <p class="date">
                        {{ flight.ArrivalDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 12:5 PM -->
                    </div>
                    <div class="item text-center">
                      <p class="duration">
                        {{ getDurationString(flight.ElapsedTime) }}
                      </p>
                      <!-- Duration 10h 35m -->
                      <p class="country-shortcut">
                        {{ flight.DepartureAirportName.split(" ")[0] }} (
                        {{ flight.DepartureAirport }} ) <br />
                        {{ flight.ArrivalAirportName.split(" ")[0] }} (
                        {{ flight.ArrivalAirport }} )
                      </p>
                      <!-- Cairo ( JFK ) Frankfurt ( CAI ) -->
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <!-- ------------------- end Return ------------------- -->
          <!-- ------------------- start OneWay ------------------- -->
          <div *ngIf="trip_type.toString().trim() == 'OneWay'">
            <div>
              <div class="result">
                <div class="result-header first">
                  <div class="items">
                    <div class="group group1" *ngIf="ticket.cost">
                      <!-- ignored offer option -->
                      <!-- <div *ngIf="!offerParam" class="item text-right"> -->
                      <div class="item text-right">
                        <p class="price">
                          <span>${{ ticket.cost.toFixed(2) }}</span
                          ><br />(incl. Taxes &amp; Fees)
                        </p>
                      </div>
                      <!-- <div *ngIf="offerParam" class="item text-right">
                                  <p class="price offer"><span
                                          class="old-price">${{itinerary.prices.AdultPrice.toFixed(2)}}</span>
                                      <span>${{itinerary.prices.AdultPriceWithOffer.toFixed(2)}}</span><br />(incl.
                                      Taxes &amp; Fees)</p>
                              </div> -->
                    </div>
                    <div class="group group2">
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[0][0]?.DepartureAirport.split(
                              " "
                            )[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[0][0]?.DepartureAirportName
                          }}
                        </p>
                      </div>
                      <div class="item text-center">
                        <img
                          title="flyallover"
                          alt="flyallover"
                          src="../../../../assets/images/airplane_depart.svg"
                          width="30"
                        />
                        <p class="stop">
                          {{ ticket.flight_details[0]?.length - 1 }} stop
                        </p>
                      </div>
                      <div class="item">
                        <p class="country">
                          {{
                            ticket.flight_details[0][
                              ticket.flight_details[0]?.length - 1
                            ]?.ArrivalAirport.split(" ")[0]
                          }}
                        </p>
                        <p class="airport">
                          {{
                            ticket.flight_details[0][
                              ticket.flight_details[0]?.length - 1
                            ]?.ArrivalAirportName
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>

                <div class="result-body">
                  <div
                    class="items"
                    *ngFor="
                      let flight of ticket.flight_details[0];
                      let i = index
                    "
                  >
                    <div class="item">
                      <img
                        title="flyallover"
                        alt="flyallover"
                        src="https://c.fareportal.com/n/common/air/3x/{{
                          flight.OperatingAirline
                        }}.png"
                      />
                      <p class="country-air">
                        {{ flight.OperatingAirlineName }}
                      </p>
                    </div>
                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.DepartureDateTime) }}
                      </p>
                      <!-- Mon , Nov 18 , 2019 -->
                      <p class="date">
                        {{ flight.DepartureDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 6:30 PM -->
                    </div>
                    <div class="item text-center">
                      <div class="flight-new-style">
                        <!-- <span class="fare">{{itinerary.prices.FareClassName}}</span> -->
                        <span class="num"
                          >Flight {{ flight.FlightNumber }}</span
                        >
                        <div class="border-line"></div>
                        <span
                          class="layover"
                          *ngIf="layoverTime(ticket.flight_details[0][i + 1])"
                          >Layover:
                          {{
                            layoverTime(
                              ticket.flight_details[0][i + 1]?.FlightLayoverTime
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="item text-center">
                      <p class="date">
                        {{ getDateString(flight.ArrivalDateTime) }}
                      </p>
                      <!-- Tue , Nov 19 , 2019 -->
                      <p class="date">
                        {{ flight.ArrivalDateTime | date: "h:mm a" }}
                      </p>
                      <!-- 12:5 PM -->
                    </div>
                    <div class="item text-center">
                      <p class="duration">
                        {{ getDurationString(flight.ElapsedTime) }}
                      </p>
                      <!-- Duration 10h 35m -->
                      <p class="country-shortcut">
                        {{ flight.DepartureAirportName.split(" ")[0] }} (
                        {{ flight.DepartureAirport }} ) <br />
                        {{ flight.ArrivalAirportName.split(" ")[0] }} (
                        {{ flight.ArrivalAirport }} )
                      </p>
                      <!-- Cairo ( JFK ) Frankfurt ( CAI ) -->
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <!-- ------------------- end Oneway ------------------- -->
        </div>
        <button (click)="viewBookings.emit()">Back</button>
      </div>
    </div>

    <div class="boxHolder-sidebar">
      <!-- start sidebarbox -->
      <div class="sidebarbox">
        <div class="clearfix"></div>
        <!-- start details -->
        <details open="open">
          <summary>
            <img
              title="flyallover"
              alt="flyallover"
              src="../../../../assets/images/summary2.svg"
            />
            BAGGAGE INFORMATION
          </summary>

          <div class="contain">
            <div
              class="photos"
              *ngFor="
                let operatingAirline of operatingAirlines;
                let iii = index
              "
            >
              <!-- Index: {{ iii }}
                    {{freeBaggageAllowance[iii][0].Pieces}} -->
              <!-- this row for free baggage -->
              <div class="row">
                <div class="col small-col">
                  <img
                    title="flyallover"
                    alt="flyallover"
                    src="https://c.fareportal.com/n/common/air/3x/{{
                      operatingAirline.photo
                    }}.png"
                  />
                  <p class="flight-number">
                    Flight {{ flightNumbersArray[iii] }}
                  </p>
                </div>
                <!-- if the array of free baggage is not empty -->

                <div
                  class="col"
                  *ngIf="
                    freeBaggageAllowance?.length > 0 &&
                    freeBaggageAllowance[iii][0]?.Pieces
                  "
                >
                  <p class="small-paragraph">
                    Free Baggage Allowance
                    {{ freeBaggageAllowance[iii][0]?.Pieces }} Piece(s)
                    {{ freeBaggageAllowance[iii][0]?.Weight
                    }}{{ freeBaggageAllowance[iii][0]?.Unit }} <br />{{
                      freeBaggageAllowance[iii][0]?.Description1
                    }}
                    <br />
                    {{ freeBaggageAllowance[iii][0]?.Description2 }}
                  </p>
                </div>
                <!-- the array of free baggage is not empty & if number of pieces is 0 -->
                <!-- OR if the array of free baggage is empty (length = 0) -->
                <div
                  class="col"
                  *ngIf="
                    (freeBaggageAllowance?.length > 0 &&
                      !freeBaggageAllowance[iii][0]?.Pieces) ||
                    freeBaggageAllowance?.length === 0
                  "
                >
                  <p class="small-paragraph">
                    Free Baggage Allowance 0 pieces for this flight.
                  </p>
                </div>
              </div>
              <!-- this row for charged baggage -->
              <div class="row" *ngIf="chargebaggageAllowance?.length > 0">
                <div class="col-12 extra-info">
                  <h6 class="extra-baggage">Extra Baggage</h6>
                </div>
                <div
                  class="row mx-0"
                  *ngFor="
                    let ChargedItemIndex of chargebaggageAllowance[iii];
                    let i = index
                  "
                >
                  <div class="col-12 extra-info mt-3">
                    <h6>
                      {{ chargebaggageAllowance[iii][i][0]?.FirstPiece }} Piece
                      <span class="price-extra-baggage">
                        {{
                          chargebaggageAllowance[iii][i][0]?.EquivalentAmount
                            | currency
                        }}
                      </span>
                    </h6>
                    <p class="small-paragraph">
                      {{ chargebaggageAllowance[iii][i][0]?.Description1 }}
                      <br />
                      {{ chargebaggageAllowance[iii][i][0]?.Description2 }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </details>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
