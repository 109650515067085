<section>
  <div class="row">
    <h2>bagging information</h2>
    <div class="priceDesc">
      <div class="travellerDesc custombox" *ngFor="let item of itineraries ;let i = index"  style="height: 3.3rem;box-shadow: 1px 2px #e4d7d7;">
          <span class="fao_label my-auto">{{item.CommercialName}} </span>
          <p> </p>
      </div>
    </div>
  </div>
</section>
