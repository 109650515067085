import { SEOService } from 'src/app/services/seo.service';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';
import { ApisService } from 'src/app/services/apis.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { validators } from "sitemap";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ThanksModalComponent } from 'src/app/components/review-flight/thanks-modal/thanks-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FareRulesComponent } from 'src/app/components/fare-rules/fare-rules.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PaymentDialogComponent } from '../review-flight/payment-dialog/payment-dialog.component';
import { element } from 'protractor';
import { emit } from 'process';
import { LoadingModalComponent } from 'src/app/components/loading-modal/loading-modal.component';

@Component({
  selector: "app-review-flight-payment-without-search",
  templateUrl: "./review-flight-payment-without-search.component.html",
  styleUrls: ["./review-flight-payment-without-search.component.css"],
})
export class ReviewFlightPaymentWithOutSearchComponent implements OnInit {

  dataPnr_input: any;
  card: any;
  paymentStep = 1;
  vendorCode;
  error = false;
  submitError = false;
  filteredCountries: Observable<string[]>;
  countries;
  form: FormGroup;
  vendorSrc: any;
  checkEmail: boolean;
  messageCheckEmail: string;
  errorMessageYear;
  errorMessageMonth;
  token: any;
  selectedTravellers: any;
  dataPnr: any;
  loading: boolean = false;
  messageError: string;
  tagId: string;
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''


  autocompleteInput: string;
  queryWait: boolean;
  ZipCode: void;
  city: any;
  addresscomponents: any[] = [];
  state: any;
  country: any;
  countryCode: any;
  itinerary: any;
  orderId: any;
  errorMessage;
  pnrTicketError;
  checkPoliceyBox = 0;
  checkbox1 = new FormControl('');
  checkbox2 = new FormControl('');
  checkbox3 = new FormControl('');
  checkbox4 = new FormControl('');
  checkbox5 = new FormControl('');
  dialogRef: any;
  myIpAddress: any;
  checkName = true;
  loadingModal: any;
  paymentType = 'Credit';
  changePaymentKay: any;
  constructor(private apis: ApisService, private seoService: SEOService, @Inject(DOCUMENT) private document: Document, private router: Router, private matDialog: MatDialog,
    private localStorage: LocalStorageService, private _ActivatedRoute: ActivatedRoute, private cookie: CookieService,
    private _Router: Router
  ) {
    this.orderId = this._ActivatedRoute.snapshot.paramMap.get('orderId');
    // this.seoUpdate();
    if (this.localStorage.getItem("flyallover_token") !== "undefined") {
      this.token = this.localStorage.getItem('flyallover_token');
    }
    this.flightDetails()
    this.form = this.initForm();
    this.selectedTravellers = JSON.parse(this.localStorage.getItem("selectedTravellers"));
  }


  ngOnInit() {
    this.getIpAddress()
  }
  validationCountry(FormControlName: any) {
    let element = this.document.getElementById(FormControlName);
    if (!this.f[FormControlName]?.errors?.required) {
      element.classList.remove('is-invalid')
      element.classList.add('is-valid')
      if (!this.f[FormControlName]?.errors?.someProp) {
        element.classList.remove('is-invalid')
        element.classList.add('is-valid')
      } else {
        element.classList.remove('is-valid')
        element.classList.add('is-invalid')
      }
    } else {
      element.classList.remove('is-valid')
      element.classList.add('is-invalid')
    }
  }
  validationForm(FormControlName: any) {
    this.form.get(FormControlName).valueChanges.subscribe((value) => {
      if (this.f[FormControlName].dirty) {
        let element = this.document.getElementById(FormControlName);
        if (FormControlName == 'email') {
          return
        } else {
          if (!this.f[FormControlName]?.errors?.required) {
            element.classList.remove('is-invalid')
            element.classList.add('is-valid')
            if (!this.f[FormControlName]?.errors?.pattern) {
              element.classList.remove('is-invalid')
              element.classList.add('is-valid')
            } else {
              element.classList.remove('is-valid')
              element.classList.add('is-invalid')
            }
          } else {
            element.classList.remove('is-valid')
            element.classList.add('is-invalid')
          }
        }
      }
    })
  }
  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.addresscomponents = address.address_components;

    //   address.address_components.length.forEach(function (value) {
    //     console.log(value);
    // });
    for (let i = 0; i < address.address_components.length; i++) {

      for (let ii = 0; ii < address.address_components[i].types.length; ii++) {
        console.log('address.address_components[i].types[ii]', address.address_components[i].types[ii])
        if (address.address_components[i].types[ii] == "postal_code") {
          this.ZipCode = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "administrative_area_level_1") {
          this.state = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "locality") {
          this.city = address.address_components[i].short_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.country = address.address_components[i].long_name;
        }
        if (address.address_components[i].types[ii] == "country") {
          this.countryCode = address.address_components[i].short_name;
        }
      }

    }
  }
  onChangeZip(e) {
    this.ZipCode = e;
  }
  onChangeCity(e) {
    this.city = e;
  }
  onChangeCountry(e) {
    this.country = e;
  }
  onChangeState(e) {
    this.state = e;
  }


  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }


  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  setPaymentCardEvent(e) {
    this.form.patchValue({
      CreditCardNumber: e.card,
      CreditCardVendorCode: e.vendorCode,
    });
  }

  updateHolderName() {
    this.form.patchValue({
      cardHolderName: this.form.value.cardHolderFullName,
    });
  }
  setCreditCardExpireDate: any
  updateExpireDate() {
    const date =
      this.form.value.CreditCardExpireDateYear +
      "-" +
      this.form.value.CreditCardExpireDateMonth.padStart(2, '0');
    this.form.patchValue({
      CreditCardExpireDate: date,
    });
    this.setCreditCardExpireDate = date
  }

  checkDate() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const valueMonth = this.form.value.CreditCardExpireDateMonth;
    const valueYear = this.form.value.CreditCardExpireDateYear;
    if (valueYear == null) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Is Required!";
    }
    if (valueYear > year) {
      this.errorMessageYear = null;
      this.errorMessageMonth = null;
    }
    if (valueYear < year) {
      this.errorMessageYear = null;
      this.errorMessageYear = "Year Should Be More Than " + year;
    }
    if (valueYear == year) {
      this.errorMessageYear = null;
      if (month > valueMonth) {
        this.errorMessageYear = null;
        this.errorMessageMonth = "Month should Be More Than " + month;
      }
      if (month <= valueMonth) {
        this.errorMessageMonth = null;
      }
    }
  }

  initForm(): FormGroup {
    return new FormGroup({
      CreditCardNumber: new FormControl("", []),
      CreditCardVendorCode: new FormControl("", []),
      CreditCardExpireDate: new FormControl("", []),
      cardHolderFullName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$"),
      ]),
      card: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{16}"),
      ]),
      cvc: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{3}"),
      ]),
      CreditCardExpireDateMonth: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,}"),
      ]),
      CreditCardExpireDateYear: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
      // addressLine2: new FormControl(""),
      CreditCardCountry: new FormControl("", [Validators.required]),
      CreditCardstreetAddress: new FormControl("", [Validators.required]),
      CreditCardCity: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,}$"),
      ]),
      CreditCardZip: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{1,100}"),
      ]),
      State: new FormControl("", [Validators.required]),
      creditCardRegion: new FormControl("", [Validators.required]),
      // stateOrProvince: new FormControl("", [Validators.pattern("[a-zA-Z ]+")]),
      // email: new FormControl("", [Validators.required, Validators.email]),
      // phone: new FormControl("", [Validators.required, Validators.pattern("[0-9]{1,}"),])
    });
  }
  get f() {
    return this.form.controls;
  }


  getCountryCode(country): any {
    const index = this.countries.findIndex((obj) => obj.name === country);
    if (index > -1) {
      return this.countries[index];
    }
  }

  testCreditCard() {
    this.card = this.form.value.card;
    const vendorCode = {
      AX: "American Express",
      CB: "Carte Blanche",
      DC: "Diners Club",
      DS: "Discover",
      CA: "MasterCard",
      VI: "Visa",
      JC: "JCB",
    };
    if (this.checkCreditCard(this.form.value.card, vendorCode["AX"])) {
      this.vendorCode = "AX";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CB"])) {
      this.vendorCode = "CB";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DC"])) {
      this.vendorCode = "DC";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["DS"])) {
      this.vendorCode = "DS";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["CA"])) {
      this.vendorCode = "CA";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["VI"])) {
      this.vendorCode = "VI";
      this.error = false;
    } else if (this.checkCreditCard(this.form.value.card, vendorCode["JC"])) {
      this.vendorCode = "JC";
      this.error = false;
    } else {
      this.vendorCode = 'VI'
      this.error = true;
    }
    const object = {
      card: this.form.value.card,
      error: this.error,
      vendorCode: this.vendorCode,
    };
    this.setPaymentCardEvent(object);
  }

  validateCheckboxes(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.checkPoliceyBox++;
    } else {
      this.checkPoliceyBox--;
    }
  }
  checkCreditCard(cardnumber, cardname) {
    let ccErrorNo = 0;
    const ccErrors = [];
    const cards = [];
    ccErrors[0] = "Unknown card type";
    ccErrors[1] = "No card number provided";
    ccErrors[2] = "Credit card number is in invalid format";
    ccErrors[3] = "Credit card number is invalid";
    ccErrors[4] = "Credit card number has an inappropriate number of digits";
    ccErrors[5] =
      "Warning! This credit card number is associated with a scam attempt";

    // Define the cards we support. You may add addtional card types as follows.
    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit

    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      checkdigit: true,
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      checkdigit: true,
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      checkdigit: true,
    };
    cards[3] = {
      name: "CarteBlanche",
      length: "14",
      prefixes: "300,301,302,303,304,305",
      checkdigit: true,
    };
    cards[4] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      checkdigit: true,
    };
    cards[5] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      checkdigit: true,
    };
    cards[6] = {
      name: "JCB",
      length: "16",
      prefixes: "35",
      checkdigit: true,
    };
    cards[7] = {
      name: "enRoute",
      length: "15",
      prefixes: "2014,2149",
      checkdigit: true,
    };
    cards[8] = {
      name: "Solo",
      length: "16,18,19",
      prefixes: "6334,6767",
      checkdigit: true,
    };
    cards[9] = {
      name: "Switch",
      length: "16,18,19",
      prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
      checkdigit: true,
    };
    cards[10] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      checkdigit: true,
    };
    cards[11] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      checkdigit: true,
    };
    cards[12] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      checkdigit: true,
    };

    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {
      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() == cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType == -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length == 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, "");

    // Check that the number is numeric
    let cardNo = cardnumber;
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 != 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo == "5490997771092064") {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(",");

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp("^" + prefix[i]);
      if (exp.test(cardNo)) {
        PrefixValid = true;
      }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(",");
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length == lengths[j]) {
        LengthValid = true;
      }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    }

    // The credit card is in the required format.
    return true;
  }
  format(e) {

    e.target.value = this.padLeft(e.target.value, "0", 2);
    console.log(e.target.value)
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);

  }
  goToNextStep() {
    if (this.paymentStep >= 1 && this.paymentStep < 2) {
    }
    else if (this.paymentStep == 2) {
      this.initRequestOffers()
    }
    if (this.checkPoliceyBox == 5) {
      this.submit()
    }
  }

  goToPreviousStep() {
    if (this.paymentStep > 1 && this.paymentStep <= 2) {

      this.paymentStep--
    }

  }
  flightDetails() {

    this.apis.flightDetails(this.token, this.orderId).subscribe((data: any) => {
      this.dataPnr_input = data.data;
      this.itinerary = JSON.parse(this.dataPnr_input?.itinerary_details)
    },
      err => {
        console.log(err);
      });
  }
  setIssueTicket() {
    this.apis.issueTicketWithPnr(this.initRequestOffers()).subscribe({
      next: (res: any) => {
        this.loadingModal.close();
        if (res.data) {
          this.loadingModal.close()
          const order = res.data?.order
          this.router.navigate(['/payment-details/' + order.order_status + '/' + order.order_id]);
        }
      },
      error: (err: any) => {
        this.loadingModal.close()
        this.errorMessage = err.error.error;
        this.openErorPaymentModal();
      }
    });
  }
  openErorThanksModal(): void {
    this.pnrTicketError = this.matDialog.open(ThanksModalComponent, {
      panelClass: "alternativeDateDialog",
      autoFocus: false,
      maxHeight: "95vh",
      width: "max-content",
      maxWidth: "98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: null,
        error: this.errorMessage,
      },
    });
    this.pnrTicketError.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }
  getIpAddress() {
    this.apis.getIpAddress().subscribe((data: any) => {
      this.myIpAddress = data.ip;
    });
  }
  submit() {
    this.openLoadingModal()
    this.setIssueTicket()
  }
  openErorPaymentModal(): void {
    this.dialogRef = this.matDialog.open(PaymentDialogComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      disableClose: true,
      width: '840px',
      maxWidth: "98%",
      backdropClass: "background-transparent",
      data: {
        dataKey: null,
        error: this.errorMessage,
        order_id: this.orderId
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //this.router.navigate(["/"]);
    });
  }
  initRequestOffers() {
    if (this.form.status != "VALID") {
      this.submitError = true;
    } else {
      const orderData = this.form.value;
      // data.CreditCardCountry = this.getCountryCode(data.CreditCardCountry);
      orderData.CreditCardCountry = this.countryCode;
      const object: any = {};
      object.order_id = this.orderId;
      object.CreditCardCity = orderData.CreditCardCity;
      object.CreditCardstreetAddress = orderData.CreditCardstreetAddress;
      object.cardHolderName = orderData.cardHolderFullName;
      object.CreditCardCountry = orderData.CreditCardCountry;
      object.CreditCardZip = orderData.CreditCardZip;
      object.CreditCardVendorCode = orderData.CreditCardVendorCode;
      object.CreditCardExpireDate = orderData.CreditCardExpireDate;
      object.CreditCardNumber = orderData.CreditCardNumber;
      object.state = orderData.State;
      object.creditCardRegion = orderData.creditCardRegion;
      object.contact_email = orderData.contact_email;
      object.contact_phone = orderData.contact_phone;
      object.cvc = orderData.cvc;
      object.payment_intent = "pi_1Iv1ssEzVRHi1jXem8lfgH7k",
        object.customer_ip = this.myIpAddress;
      return object;
    }
  }

  openFareRulesDialog(itinerary) {
    this.dialogRef = this.matDialog.open(FareRulesComponent, {
      panelClass: 'FareRules',
      maxHeight: '90vh',
      width: '50%',
    });
    this.dialogRef.componentInstance.itinerary = itinerary;
  }
  checkCardName() {
    this.checkName = false
    const formName = this.form.value.cardHolderFullName;
    var cardName = formName?.split(' ')[0] + ' ' + formName?.split(' ')[formName.split(' ').length - 1]
    let travelerName = this.dataPnr_input.family_members.find(element => this.checkTravelerName(element.family_member) === cardName.toLocaleLowerCase())
    if (travelerName) {
      this.checkName = true
      return true
    } else {
      this.checkName = false
      return false
    }

  }
  checkTravelerName(travelerName) {
    let setName = travelerName?.first_name + ' ' + travelerName?.last_name;
    return setName.toLocaleLowerCase()
  }
  openLoadingModal(): void {
    this.loadingModal = this.matDialog.open(LoadingModalComponent, {
      panelClass: "alternativeDateDialog",
      autoFocus: false,
      maxHeight: "95vh",
      maxWidth: "95vw",
      backdropClass: "background-transparent",

    })

  }
  paymentTypeAction(typePayment: string) {
    this.paymentType = typePayment
  }
  checkedValue(checked) {
    this.changePaymentKay = checked.value
  }
}
