import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-one-way-calender-desktop-dialog',
  templateUrl: './one-way-calender-desktop-dialog.component.html',
  styleUrls: ['./one-way-calender-desktop-dialog.component.css']
})

export class OneWayCalenderDesktopDialogComponent implements OnInit, AfterViewInit {

  @Output() setDepartDateEvent = new EventEmitter();
  @Output() setReturnDateEvent = new EventEmitter();
  public origin: any;
  public destination: any;
  date = new Date();
  departDateText: any;
  priceAPiDateFormat: any;
  departDate: any;
  departDates :any []=[];
  returnDateText: any;
  returnDate: any;
  confirmedDepartDate: any;
  confirmedReturnDate: any;
  displayedDaysCounter = 1;
  datesPrices: any;
  daysText = 'Days';
  diff: any;
  activeInput = 'departInput';
  displayedMonth2 = 0;
  days = [
    {
      shortName: 'Mon',
      longName: 'Mon',
      number: '1',
    },
    {
      shortName: 'Tue',
      longName: 'Tue',
      number: '2',
    },
    {
      shortName: 'Wed',
      longName: 'Wed',
      number: '3',
    },
    {
      shortName: 'Thu',
      longName: 'Thu',
      number: '4',
    },
    {
      shortName: 'Fri',
      longName: 'Fri',
      number: '5',
    },
    {
      shortName: 'Sat',
      longName: 'Sat',
      number: '6',
    },
    {
      shortName: 'Sun',
      longName: 'Sun',
      number: '7',
    },
  ];
  months = [
    {
      shortName: 'January',
      longName: 'January',
      number: '0',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'February',
      longName: 'February',
      number: '1',
      firstDayWeekPosition: '1',
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'March',
      longName: 'March',
      number: '2',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'April',
      longName: 'April',
      number: '3',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'May',
      longName: 'May',
      number: '4',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'June',
      longName: 'June',
      number: '5',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'July',
      longName: 'July',
      number: '6',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'August',
      longName: 'August',
      number: '7',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'September',
      longName: 'September',
      number: '8',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'October',
      longName: 'October',
      number: '9',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'November',
      longName: 'November',
      number: '10',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
    {
      shortName: 'December',
      longName: 'December',
      number: '11',
      firstDayWeekPosition: '1',
      numberOfDays: Array(30),
      year: this.date.getFullYear(),
      displayedMonth: false,
    },
  ];
  counter = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: ApisService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<OneWayCalenderDesktopDialogComponent>
  ) {
    this.initMonths();
  }

  ngOnInit(): void {
    // this.initMonths();
    this.departDates=this.data.departDates
    if(this.departDates==undefined){
      this.departDate=this.data.departDate
    }else if(this.departDates !=undefined){
    if (this.departDates[0]) {
      this.departDate = this.departDates[0];
    }
    if (this.departDates[1]) {
      this.departDate = this.departDates[1];
    }
    if (this.departDates[2]) {
      this.departDate = this.departDates[2];
    }
    if (this.departDates[3]) {
      this.departDate = this.departDates[3];
    }
  }
  }

  ngAfterViewInit(): void {
    this.pricesApi();
    this.handleSelectedDates();
  }

  handleSelectedDates(): void {
    if (this.departDate) {

      const date = new Date(this.departDate);
      const className = '.day-' + date.getTime().toString();
      setTimeout(() => {
        this.displayedMonth2 = date.getMonth() - this.date.getUTCMonth() ;
      }, 1);
      document.querySelectorAll(className).forEach((item) => {
        item.classList.add('selectedDay');
        item.classList.add('selectedDatesDepartureDate');
      });
    }
  }

  initMonths(): void {
    for (let i = 0; i < this.date.getUTCMonth(); i++) {
      const poppedItem = this.months.shift();
      poppedItem.year = this.date.getFullYear() + 1;
      this.months.push(poppedItem);
    }
    for (const item of this.months) {
      
      item.firstDayWeekPosition = this.firstDayWeekPosition(item);
      
      item.numberOfDays = Array(new Date(item.year, parseFloat(item.number) + 1, 0).getDate());

      
      if ((this.date.getUTCMonth()).toString() === item.number) {
        item.displayedMonth = true;
      }
    }
    
  }


  timeStamp(year, month, day) {
    return new Date(year, month, day).getTime();
  }

  daysInMonth(month): any {
    const days = new Date(month.year, month.number, 0).getDate();
    return Array(days);
  }

  firstDayWeekPosition(month): any {
    return new Date(month.year, month.number, 1).getDay();
  }

  toArray(numberToConvert): any {
    if (numberToConvert === 0) {
      return Array(6);
    }
    return Array(numberToConvert - 1);
  }

  selectDay(month, day): void {
    if ((this.date.getDate() ) > day && this.date.getMonth() == month.number) {

    } else if (this.departDate) {
      this.clearDates();
      this.setDepartDate(month, day);
    } else {
      this.setDepartDate(month, day);
    }
    this.dialogRef.close({departureDate: this.departDate});
  }

  setDepartDate(month, day): void {
    if (!this.departDate && !this.returnDate) {
      const className = '.year-' + month.year + '.month-' + month.number + '.day-' + day;
      document.querySelectorAll(className).forEach((item) => {
        item.classList.toggle('selectedDay');
        item.classList.add('selectedDatesDepartureDate');
        this.departDate = new Date(month.year, month.number, day);
        this.departDateText = day + ' ' + month.shortName + ' ' + month.year;
      });
    }

  }
  clearDates(): void {
    this.confirmedDepartDate = null;
    this.confirmedReturnDate = null;
    this.departDate = null;
    this.returnDate = null;
    this.departDateText = '';
    this.returnDateText = '';
    const dates = this.document.querySelectorAll('.monthDay');
    Array.from(dates).forEach(date => {
      date.classList.remove('selectedDay');
      date.classList.remove('selectedDatesDepartureDate');
      date.classList.remove('selectedDatesReturnDate');
    });
    this.activeInput = 'departInput';
  }

  nextMonth(): void {
    this.displayedMonth2++;
  }

  previousMonth(): void {
    this.displayedMonth2--;
  }

  pricesApi(tripType = 'OneWay') {
    if (this.origin && this.destination && this.origin.length >= 3 && this.destination.length >= 3) {
      if (this.departDate) {
        this.priceAPiDateFormat = this.datePipe.transform(this.departDate, 'yyyy-MM-dd');
      }
      this.apis.leadPriceCalendar(this.origin, this.destination, 'OneWay', this.priceAPiDateFormat).subscribe((data: any) => {
        this.datesPrices = data.data.calendar;
        this.viewPrices();
      }, err => {
        this.datesPrices = undefined;
      });
    }
  }

  viewPrices() {
    document.querySelectorAll('.price').forEach((item) => {
      item.innerHTML = '';
    });
    this.datesPrices.forEach((el: any, i) => {
      const date = new Date(el.date);
      const className = '.year-' + date.getFullYear() + '.month-' + date.getMonth() + '.day-' + date.getDate() + ' .price';
      document.querySelectorAll(className).forEach((item) => {
        item.innerHTML = '$' + el.price;
        if (el.price > 400 && el.price < 500) {
          item.classList.add('green');
        }
      });
    });
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

}
