import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApisService } from 'src/app/services/apis.service';
import { SEOService } from 'src/app/services/seo.service';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';

@Component({
  selector: 'app-review-flight-add-member-traveller',
  templateUrl: './review-flight-add-member-traveller.component.html',
  styleUrls: ['./review-flight-add-member-traveller.component.css']
})
export class ReviewFlightAddMemberTravellerComponent implements OnInit {
  @Input() searchRequest;
  @Input() traveller;
  @Output() MemberTraveller: EventEmitter<any> = new EventEmitter();
  @Output() lenthFormMember: EventEmitter<any> = new EventEmitter();
  @Output() removeMember: EventEmitter<any> = new EventEmitter();
  @Output() reloadFamilyMember: EventEmitter<any> = new EventEmitter();

  formGroupAddTraveller: FormGroup;
  genders: any = [{ name: "Female", value: 'F' }, { name: "Male", value: 'M' }];
  months = [
    { text: 'January', num: '01' },
    { text: 'February', num: '02' },
    { text: 'March', num: '03' },
    { text: 'April', num: '04' },
    { text: 'May', num: '05' },
    { text: 'June', num: '06' },
    { text: 'July', num: '07' },
    { text: 'August', num: '08' },
    { text: 'September', num: '09' },
    { text: 'October', num: '10' },
    { text: 'November', num: '11' },
    { text: 'December', num: '12' }
  ];
  issuedDate = {
    day: undefined,
    month: undefined,
    year: undefined
  }
  expireDate = {
    day: undefined,
    month: undefined,
    year: undefined
  }
  birthDate = {
    day: undefined,
    month: undefined,
    year: undefined
  }
  relations: any;

  // filter
  filteredCountry: Observable<any>;
  countries: any[] = [];
  passportIssuedCountries: any;
  initForm() {
    return this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      first_name: ['', Validators.required],
      middle_name: ['', Validators.required],
      last_name: ['', Validators.required],
      family_member_id: ['0', Validators.required],
      date_of_birth: ['', Validators.required],
      nationality_id: ['', Validators.required],
      nationality_code: ['', Validators.required],
      country_id: ['', Validators.required],
      country_code: ['', Validators.required],
      'country-code': ['', Validators.required],
      country: [null, Validators.required],
      nationality: ['', Validators.required],
      gender: ['', Validators.required],
      // phone_type: ['H', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]],
      PassengerType: ['', Validators.required],
      color: '#e67e22',
      passport: this.fb.group({
        passport_number: ['', Validators.required],
        expire_date: ['', Validators.required],
        issue_date: ['', Validators.required],
        issued_country: [null, Validators.required],
        country: [null, Validators.required],
        issueDate: this.fb.group({
          issue_date_day: ['', Validators.required],
          issue_date_month: ['', Validators.required],
          issue_date_year: ['', Validators.required]
        }),
        expireDate: this.fb.group({
          expiry_date_day: ['', Validators.required],
          expiry_date_month: ['', Validators.required],
          expiry_date_year: ['', Validators.required],
        }),
        travellerDob: this.fb.group({
          traveller_dob_day: ['', Validators.required],
          traveller_dob_month: ['', Validators.required],
          traveller_dob_year: ['', Validators.required]
        }),
      }),
    });
  }
  token: any;
  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private seoService: SEOService,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute,
    private _ToastrService: ToastrService
  ) {
    this.token = this.localStorage.getItem('flyallover_token');
  }

  ngOnInit(): void {
    this.formGroupAddTraveller = this.fb.group({
      formArrayAddTraveller: this.fb.array([])
    });
    this.getFamilyRelations();
    this.getCountries();
  }

  get memberTraveller() {
    return <FormArray>this.formGroupAddTraveller.controls["formArrayAddTraveller"];
  }
  addTraveller() {
    const lenthFormArray = (<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).length
    if (lenthFormArray == 0) {
      this.memberTraveller.push(this.initForm())
      this.lenthFormMember.emit((<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')))
    }
  }
  deleteTraveller(index: number) {
    const itemDeleted = this.memberTraveller.controls[index].value
    this.memberTraveller.removeAt(index)
    this.removeMember.emit(itemDeleted)
    this.lenthFormMember.emit((<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')))
  }
  getFamilyRelations() {
    this.apis.familyRelations().subscribe(
      (data: any) => {
        this.relations = data.data;
        // console.log(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => { this.countries = data },
      (err) => { console.log(err); }
    );
  }
  // auto compelete filter Country
  getfilterCountry(i) {
    if (this.countries) {
      this.filteredCountry = ((<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).controls[i].get('passport').get('issued_country')).valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountries(value)),
      );
    }
  }
  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((option) => option.name.toLowerCase().includes(filterValue))
  }
  // patch value in date
  checkDate(index, formGroupName) {
    this.memberTraveller.controls[index].get('passport').get(`${formGroupName}`).valueChanges.subscribe(res => {
      switch (formGroupName != undefined) {
        case formGroupName == 'travellerDob': {
          this.memberTraveller.controls[index].patchValue({
            date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month}-${res.traveller_dob_day}`
          })
          break;
        }
        case formGroupName == 'issueDate': {
          // console.log('issueDate');
          this.memberTraveller.controls[index].get('passport').patchValue({
            issue_date: `${res.issue_date_year}-${res.issue_date_month}-${res.issue_date_day}`
          })
          break;
        }
        case formGroupName == 'expireDate': {
          this.memberTraveller.controls[index].get('passport').patchValue({
            expire_date: `${res.expiry_date_year}-${res.expiry_date_month}-${res.expiry_date_day}`
          })
          break;
        }
        default: {
          // console.log("No date exists!");
          break;
        }
      }
    })
  }
  countryPatchValue(index) {
    this.memberTraveller.controls[index].get('passport').get('issued_country').valueChanges.subscribe(res => {
      this.memberTraveller.controls[index].patchValue({
        country_id: this.getCountryObject(res).id,
        country_code: this.getCountryObject(res).code,
        'country-code': this.getCountryObject(res).code,
        country: this.getCountryObject(res),
        nationality_id: this.getCountryObject(res).id,
        nationality_code: this.getCountryObject(res).code,
      })
      this.memberTraveller.controls[index].get('passport').patchValue({
        country: this.getCountryObject(res)
      })
      this.memberTraveller.controls[index].patchValue({
        nationality: this.getCountryObject(res),
        country: this.getCountryObject(res),
      })
    })
  }
  // get object country
  getCountryObject(country): any {
    let objCountry = this.countries.find((obj) => obj.name === country);
    return objCountry
  }
  getFamilyObject(relation): any {
    const objRelation = this.relations.find((obj) => obj.name === relation);
    return objRelation
  }
  get f() {
    return this.memberTraveller.controls[0].get
  }
  xx() {
    // console.log(this.memberTraveller.controls);
  }
  // submitMember() {
  //   if(this.formGroupAddTraveller.invalid){
  //     return
  //   }
  //   const formMember = (<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).value;
  //   this.lenthFormMember.emit((<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')))
  //   this.MemberTraveller.emit(formMember);
  // }


  initRequest(): any {
    const formData: FormData = new FormData();
    // formData.append('image', null);
    let form = this.memberTraveller.controls[0]
    // formData.append('image', '');
    formData.append('first_name', form.get('first_name').value);
    formData.append('middle_name', form.get('middle_name').value);
    formData.append('last_name', form.get('last_name').value);
    formData.append('phone', form.get('phone').value);
    formData.append('country_id', this.getCountryObject(form.get('passport').get('issued_country').value).id);
    formData.append('nationality_id', this.getCountryObject(form.get('passport').get('issued_country').value).id);
    // formData.append('phone', form.get('phone').value);
    formData.append('gender', form.get('gender').value);
    formData.append('family_relation_id', this.getFamilyObject(form.get('PassengerType').value).id.toString());
    formData.append('date_of_birth', form.get('date_of_birth').value);
    formData.append('email', form.get('email').value);
    return formData;
  }

  createMember() {
    if (this.formGroupAddTraveller.invalid) {
      return
    }
    let form = this.memberTraveller.controls[0]
    const request = this.initRequest();
    this.apis.addNewMember(this.token, request).subscribe((res: any) => {
      this._ToastrService.success(res.data)
      form.patchValue({
        family_member_id : res.data.id
      })
      this.apis.createFamilyMemberPassport(
        form.get('family_member_id').value,
        form.get('passport').get('passport_number').value,
        form.get('passport').get('issue_date').value,
        form.get('passport').get('expire_date').value,
        this.getCountryObject(form.get('passport').get('issued_country').value).id,
        this.getCountryObject(form.get('passport').get('issued_country').value).id,
        this.getCountryObject(form.get('passport').get('issued_country').value).id,
        this.token
      ).subscribe((res: any) => {
        this.reloadFamilyMember.emit();
        this._ToastrService.success(res.data);
        this.memberTraveller.clear();
      },
        err => {
          console.log(err);
        });
    },
      err => {
      });

  }

}
