import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { ApisService } from "../../../services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { Router } from "@angular/router";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-add-member",
  templateUrl: "./add-member.component.html",
  styleUrls: ["./add-member.component.css"],
})
export class AddMemberComponent implements OnInit {
  @Output() reloadFamilyMember = new EventEmitter();
  form: FormGroup;
  countries: any;
  relations: any;
  image: any;
  token: any;
  ImagePath: any;
  fileToUpload: File = null;
  fileValidation: any;
  errors: any;
  personalCountries: any;
  errorKeys: any;
  loading: any = false;
  email: any;
  message: any;
  genders: any = [{ name: "Female" }, { name: "Male" }];
  spinner: any = false;
  filteredPersonalCountries: Observable<string[]>;
  filteredPersonalNationalities: Observable<string[]>;
  filteredGender: Observable<string[]>;
  filteredFamilyRelation: Observable<string[]>;
  familyRelations: any;
  personalNationalities: any;
  right: boolean = false;
  imageAlt: string;
  maleImage: any = "";
  constructor(
    public datePipe: DatePipe,
    private apis: ApisService,
    private cookie: CookieService,
    private matDialog: MatDialog,
    private localStorage: LocalStorageService,
    private dialogRef: MatDialogRef<AddMemberComponent>
  ) {
    this.form = this.initForm();
    this.imageAlt = "image";
    this.image = "/assets/newIcon/male.png";
  }

  ngOnInit() {
    this.token = this.localStorage.getItem("flyallover_token");
    this.getCountries();
    this.getFamilyRelation();
    this.gendersListener();
  }

  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => {
        this.countries = data;
        this.personalCountries = data;
        this.personalNationalities = data;
        this.filteredPersonalCountries =
          this.form.controls.country_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPersonalCountries(value))
          );
        this.filteredPersonalNationalities =
          this.form.controls.nationality_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPersonalNationalities(value))
          );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getFamilyRelation() {
    this.apis.familyRelations(this.token).subscribe(
      (data: any) => {
        this.relations = data.data;
        this.familyRelations = data;

        this.filteredFamilyRelation =
          this.form.controls.family_relation_id.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterFamilyRelation(value))
          );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  gendersListener() {
    this.filteredGender = this.form.controls.gender.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterGenders(value))
    );
  }

  initForm(): FormGroup {
    return new FormGroup({
      first_name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern("[A-Za-z\\s]{3,100}"),
      ]),
      middle_name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern("[A-Za-z\\s]{3,100}"),
      ]),
      last_name: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern("[A-Za-z\\s]{3,100}"),
      ]),
      country_id: new FormControl("", [Validators.required]),
      nationality_id: new FormControl("", [Validators.required]),
      phone: new FormControl("", [
        Validators.minLength(8),
        Validators.pattern("[0-9]{8,}"),
        Validators.required,
      ]),
      gender: new FormControl("", [Validators.required]),
      family_relation_id: new FormControl("", [Validators.required]),
      image: new FormControl(""),
      date_of_birth: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.minLength(3)]),
    });
  }

  getCountryObject(country): any {
    const index = this.countries.findIndex((obj) => obj.name === country);
    if (index > -1) {
      return this.countries[index];
    }
  }

  getFamilyObject(relation): any {
    const index = this.relations.findIndex((obj) => obj.name === relation);
    if (index > -1) {
      return this.relations[index];
    }
  }

  convertDate(d) {
    const parts = d.split(" ");
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
  }

  onFileSelected(files: FileList): void {
    this.fileToUpload = files.item(0);
    if (files && files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {
        this.image = event.target.result;
      };
      this.fileValidation = true;
    }
  }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  initRequest(): any {
    const formData: FormData = new FormData();
    formData.append("image", this.fileToUpload);
    formData.append("first_name", this.form.value["first_name"]);
    formData.append("middle_name", this.form.value["middle_name"]);
    formData.append("last_name", this.form.value["last_name"]);
    formData.append(
      "country_id",
      this.getCountryObject(this.form.value.country_id).id
    );
    formData.append(
      "nationality_id",
      this.getCountryObject(this.form.value.nationality_id).id
    );
    formData.append("phone", this.form.value["phone"]);
    formData.append("gender", this.form.value["gender"]);
    formData.append(
      "family_relation_id",
      this.getFamilyObject(this.form.value.family_relation_id).id
    );
    formData.append(
      "date_of_birth",
      this.convertDate(String(this.form.value.date_of_birth))
    );
    formData.append("email", this.form.value.email);
    return formData;
  }

  createMember() {
    if (this.form.status === "VALID") {
      this.loading = true;
      const request = this.initRequest();
      this.apis.addNewMember(this.token, request).subscribe(
        (data: any) => {
          this.matDialog.closeAll();
          this.loading = false;
          this.dialogRef.close(this.form.value);
          this.reloadFamilyMember.emit();
        },
        (err) => {
          this.errors = err.error.error;
          this.errorKeys = Object.keys(this.errors);
          this.loading = false;
        }
      );
    }
  }

  checkMail() {
    this.message = "";
    const mailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (this.form.value.email.match(mailFormat)) {
      this.message = "";
      this.right = true;
    } else {
      this.message = "You have entered an invalid email address!";
      this.form.controls["email"].setErrors({
        incorrect: true,
        someProp: "You have entered an invalid email address",
      });
    }
  }

  checkAge(e) {
    const currentDate = new Date().getTime();
    const age = (currentDate - e.value.getTime()) / (1000 * 60 * 60 * 24) / 365;
    if (age < 18 && this.form.controls["family_relation_id"].value == "adult") {
      this.form.controls["date_of_birth"].setErrors({
        incorrect: true,
        someProp: "Age Should Be More Than 18 year",
      });
    }
  }

  private _filterGenders(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.genders.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["gender"].setErrors({
        incorrect: true,
        someProp: "Gender Not Found Please Select Valid Gender",
      });
    } else {
      return this.genders.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private _filterPersonalCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["country_id"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private _filterPersonalNationalities(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.personalCountries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["nationality_id"].setErrors({
        incorrect: true,
        someProp: "Nationality Not Found Please Select Valid Country",
      });
    } else {
      return this.personalNationalities.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private _filterFamilyRelation(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.familyRelations.data.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["family_relation_id"].setErrors({
        incorrect: true,
        someProp: "Relation Not Found Please Select Valid Relation",
      });
    } else {
      return this.familyRelations.data.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
}
