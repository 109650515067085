import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-home-video-main-section',
  templateUrl: './home-video-main-section.component.html',
  styleUrls: ['./home-video-main-section.component.css']
})

export class HomeVideoMainSectionComponent implements OnInit, AfterViewChecked {
  @Input() offsetHeight: any;
  @Input() images: any;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    
  }

  ngAfterViewChecked() {
    
  }
}
