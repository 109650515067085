import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  token: any;
  menuView = false;

  constructor(
    private localStorage: LocalStorageService,
  ) {
  }
  openOffersMenu(){
    this.menuView = !this.menuView;
  }
  ngOnInit(): void {
    if (this.localStorage.getItem('flyallover_token') != null) {
      this.token = this.localStorage.getItem('flyallover_token');
      if(this.token == 'undefined'){
        this.token = false;
      }else{
        this.token = true
      }
    }

    if (typeof document === 'undefined') {
      // during server evaluation
    } else {
      document.querySelector('.toggle-btn').addEventListener('click', () => {
        document.querySelector('.nav-show').classList.toggle('nav-hidden');
      });
    }
  }

  ngAfterViewInit() {

  }
}
