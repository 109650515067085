import {Component, OnInit, ViewChildren, AfterViewInit, Inject} from '@angular/core';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {map, switchMap, catchError} from 'rxjs/operators';
import {BlogService} from 'src/app/services/blog/blog.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AppComponent} from 'src/app/app.component';
import {WindowService} from 'src/app/services/webApis/window.service';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit, AfterViewInit {

  @ViewChildren('categoriesList') categoriesList;
  @ViewChildren('categoriesListMobile') categoriesListMobile;
  isMenuCollapsed: boolean = true;
  showResultSearch: boolean = false;
  searchInput: string = '';
  articleSuggestions = {};
  posts = [];
  categoriesBreadCrumb = [];
  categories;
  page: number = 1;
  pageSize: number = 11;
  collectionSize: number;
  currentApi: string = 'all Articles';
  categorySlug: string;
  loading: boolean = true;
  mobile: boolean;
  $categoriesListSubscription;
  $categoriesListMobileSubscription;
  hostUrl: string;

  constructor(
    private seoService: SEOService,
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private viewportScroller: ViewportScroller,
    private windowService: WindowService,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.hostUrl = AppComponent.hostUrl;
    this.getCategories();
    this.route.paramMap.subscribe((paramMap: any) => {
      const params = paramMap.params;
      if (params.category) {
        this.categorySlug = paramMap.get('category');
        this.searchInput = '';
        this.getArticlesForCategory(this.categorySlug);
      } else if (params.query) {
        this.searchInput = paramMap.get('query');
        this.categorySlug = undefined;
        this.searchBlog();
      } else {
        this.getAllArticles();
      }
    });

    if (this.windowService.nativeWindow.screen?.width < 992) {
      this.mobile = true;
    }
  }

  seoUpdate() {
    this.seoService.updateTitle('Blog | Fllyallover');
    this.seoService.updateDescription('Flyallover\'s Blog | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/blog/header.png');
  }

  ngAfterViewInit() {
    // detecting the end of ngFor loops that loop on categories array
    this.$categoriesListSubscription = this.categoriesList.changes.subscribe(t => {
      // this method is called in case there is a categorySlug, it will update the view of the category tab
      this.categoriesUI();
    });
    this.$categoriesListMobileSubscription = this.categoriesListMobile.changes.subscribe(t => {
      // this method is called in case there is a categorySlug, it will update the view of the category tab
      this.categoriesUI();
    });
  }

  // this method is used to get all the categories that all articles belong to
  getCategories() {
    this.blogService.getCategories().subscribe((res: any) => {
      const allcats = res.data;
      this.categories = allcats.filter(cat => cat.parent_id == null);
    }, err => {
      this.categories = undefined;
    });
  }

  getAllArticles(page: number = 1) {
    this.currentApi = 'all Articles';
    this.showResultSearch = false;
    this.categoriesBreadCrumb = [];
    this.loading = true;
    const navItems = this.document.querySelectorAll('.navbar-blog .active');
    // navItems.forEach(navItem => navItem.classList.remove('active'))
    for (let i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('active');
    }
    const firstNavItem = this.document.getElementById('first-nav-item');
    if (firstNavItem) {
      firstNavItem.classList.add('active');
    }
    const params = {
      page: page,
      paginate: this.pageSize
    };
    this.blogService.getAllArticles(params).subscribe((res: any) => {
      this.posts = res.data;
      this.assignPaginationVars(res?.meta.current_page, res?.meta.total, res?.meta.per_page);
      this.loading = false;
    }, err => {
      this.posts = [];
      this.loading = false;
    });
  }

  triggerSearch() {
    // persist search query in url,such that in case the page reloads get the query from url
    // when this method is called it changes parameters, and in  params subscription search blog will be called
    if (this.searchInput) {
      this.router.navigate(['/blog/search', this.searchInput]);
    } else {
      this.router.navigate(['/blog']);
    }

  }

  searchBlog(page: number = 1) {
    this.loading = true;
    this.currentApi = 'search';
    this.categoriesBreadCrumb = [];
    const navItems = this.document.querySelectorAll('.active');
    // navItems.forEach(navItem => navItem.classList.remove('active'))
    for (let i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('active');
    }
    // if (this.searchInput.length > 0) {
    const params = {
      text: this.searchInput,
      page: page,
      paginate: this.pageSize
    };
    this.blogService.articlesSearch(params).subscribe((res: any) => {
      this.showResultSearch = true;
      this.posts = res.data;
      this.assignPaginationVars(res?.meta.current_page, res?.meta.total, res?.meta.per_page);
      this.loading = false;
      // these lines are repeated to remove active class from all nav-item
      const navItems = this.document.querySelectorAll('.active');
      // navItems.forEach(navItem => navItem.classList.remove('active'))
      for (let i = 0; i < navItems.length; i++) {
        navItems[i].classList.remove('active');
      }
    }, err => {
      this.showResultSearch = true;
      this.posts = [];
      this.loading = false;
    });

    // }
  }

  categoriesUI(event = undefined) {
    // this condition is triggered by changing categorySlug in URl, when triggered active class is added to active tab
    if (this.categorySlug && !event) {
      const activeCategory = this.document.getElementById(`${this.categorySlug}`);
      if (activeCategory) {
        const navItems = this.document.querySelectorAll('.active');
        // navItems.forEach(navItem => navItem.classList.remove('active'))
        for (let i = 0; i < navItems.length; i++) {
          navItems[i].classList.remove('active');
        }
        activeCategory.classList.add('active');
        this.$categoriesListSubscription.unsubscribe();
        this.$categoriesListMobileSubscription.unsubscribe();
      }
    } else if (event) {
      if (event.target.id !== 'restOfCats' && event.target.id) {
        this.categorySlug = event.target.id;
        // control active tab styling
        const navItems = this.document.querySelectorAll('.active');
        navItems.forEach(navItem => navItem.classList.remove('active'));
        event.target.classList.add('active');
        // this.getArticlesForCategory(this.categorySlug);
        this.router.navigate(['/blog', this.categorySlug]);
      }
    }
  }

  getArticlesForCategory(slug: string, page: number = 1) {
    const params = {
      page: page,
      paginate: this.pageSize
    };
    //  control pagination (changePage method will call which api)
    this.currentApi = 'categories';
    this.showResultSearch = false;
    this.loading = true;
    this.blogService.getArticlesOfCategory(slug, params).subscribe((res: any) => {
      this.posts = res.data;
      this.categoriesBreadCrumb = this.posts[0].bread_crumb;
      this.assignPaginationVars(res?.meta?.current_page, res?.meta?.total, res?.meta?.per_page);
      this.loading = false;
    }, err => {
      this.posts = [];
      this.categoriesBreadCrumb = [];
      this.loading = false;
    });
  }

  searchSuggestions = (text$: Observable<string>) => {
    return text$.pipe(
      // distinctUntilChanged(),
      switchMap(term => {
        const params = {
          text: term
        };
        return this.blogService.articlesSearch(params).pipe(
          map((res: any) => {
            return res.data.map(el => {
              this.articleSuggestions[`${el.title_content}_slug`] = el.article_slug;
              this.articleSuggestions[`${el.title_content}_category`] = el.category_slug;
              return el.title_content;
            });
          }),
          catchError(() => {
            return of([]);
          })
        );
      })
    );
  };

  goToArticle(event) {
    // get the title of the article form the event (the articles titles are keys in this.articleSuggestions object)
    const articleSelected = event.item;
    // get the value (the slug) of the key articleSelected
    const aticleSlug = this.articleSuggestions[`${articleSelected}_slug`];
    const aticleCategory = this.articleSuggestions[`${articleSelected}_category`];
    this.router.navigate(['/blog', aticleCategory, aticleSlug]);
  }

  changePage(page) {
    // this.windowService.nativeWindow.scrollTo(0, 0);
    this.viewportScroller.scrollToPosition([0, 0]);
    switch (true) {
      case this.currentApi === 'all Articles':
        this.getAllArticles(page);
        break;
      case this.currentApi === 'search':
        this.searchBlog(page);
        break;
      case this.currentApi === 'categories':
        this.getArticlesForCategory(this.categorySlug, page);
        break;
      default:
        this.getAllArticles(page);
        break;
    }
  }

  assignPaginationVars(currentPage, total, pageSize) {
    // pagination variable assignment
    this.page = currentPage;
    this.collectionSize = total;
    this.pageSize = pageSize;
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
