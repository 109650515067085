<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<h1 class="no-dataRouter" *ngIf="intent == undefined">
  This page can't be opened directly
</h1>

<!-- start confirmation page -->
<div class="confirmation-body" *ngIf="intent != undefined">
  <div class="container">
    <div
      class="supportMessage no-dataRouter"
      *ngIf="!loading && supportMessage && !invalidCard"
    >
      <p>
        We cannot complete your reservation request now, but you can call our
        customer service to support you any time.
      </p>
      <p><a href="mailto:info@flyallover.com">info@flyallover.com</a></p>
      <p><a href="tel:888-666-8545">888-666-8545</a></p>
    </div>

    <!-- start skeleton loader -->
    <div
      class="confirmation"
      *ngIf="loading && !supportMessage && !invalidCard"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="confirm">
            <div class="success">
              <span>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '30px', width: '20%' }"
                >
                </ngx-skeleton-loader>
              </span>
              <span class="pl-3">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '30px', width: '20%' }"
                >
                </ngx-skeleton-loader>
              </span>
            </div>
            <div>
              <p>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '15px', width: '80%' }"
                >
                </ngx-skeleton-loader>
              </p>
            </div>
            <div>
              <div>
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{ height: '15px', width: '80%' }"
                >
                </ngx-skeleton-loader>
                <div>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '15px', width: '50%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '15px', width: '30%' }"
                  >
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="confirm">
            <div class="calculator">
              <div>
                <p>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '15px', width: '60%' }"
                  >
                  </ngx-skeleton-loader>
                </p>
                <p>
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ height: '15px', width: '20%' }"
                  >
                  </ngx-skeleton-loader>
                </p>
              </div>
              <ul>
                <li>
                  <span>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ height: '15px', width: '10%' }"
                    >
                    </ngx-skeleton-loader>
                  </span>
                  <span>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ height: '15px', width: '10%' }"
                    >
                    </ngx-skeleton-loader>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <!-- end skeleton loader -->
    <!-- start confirmation -->
    <div
      class="confirmation"
      *ngIf="!loading && !supportMessage && !invalidCard"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="confirm">
            <div class="success">
              <span>Code</span> <span class="pl-3">{{ code }}</span>
            </div>
            <div>
              <h3>Your reservation with Fly All Over is confirmed!</h3>
            </div>
            <div>
              <p>
                If you have any questions please contact us at:
                <a href="mailto:info@flyallover.com">info@flyallover.com</a>
                <a href="tel:888-666-8545">888-666-8545</a>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="confirm">
            <div class="calculator">
              <div>
                <h4>E- ticket Number : {{ DocumentNumber }}</h4>
                <h4>{{ name }}</h4>
              </div>
              <ul>
                <li>
                  <span>Total</span>
                  <span
                    [ngClass]="{
                      'line-through':
                        createPnr.createPnr.coupon_code ||
                        itinerary.prices.AdultPriceWithOffer
                    }"
                  >
                    {{
                      createPnr.createPnr.coupon_code ||
                      itinerary.prices.AdultPriceWithOffer
                        ? itinerary.prices.AdultPrice + itinerary?.Fees
                        : (totalPrices | currency)
                    }}
                  </span>
                </li>
                <li
                  *ngIf="
                    createPnr?.createPnr?.coupon_code ||
                    itinerary.prices.AdultPriceWithOffer
                  "
                >
                  <span>Total after discount</span>
                  <span>{{
                    createPnr?.createPnr?.coupon_code
                      ? totalPrices
                      : (itinerary?.prices?.AdultPriceWithOffer +
                          itinerary?.Fees | currency)
                  }}</span>
                </li>
              </ul>
            </div>
            <!-- <div><button class="download-button">Download ticket</button></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <!-- end confirmation -->

    <div class="row">
      <div class="col-lg-9">
        <!-- start results -->
        <div class="mt-3">
          <app-itinerary
            [skeletons]="1"
            [resultsArrived]="code"
            [loading]="loading"
            [itineraries]="[itinerary]"
            [page]="null"
          ></app-itinerary>
        </div>
        <!-- end results -->
      </div>

      <!-- start skeleton for baggage -->

      <div
        class="col-lg-3 pl-md-0"
        *ngIf="loading && !supportMessage && !invalidCard"
      >
        <!-- baggage information section -->
        <div class="mt-3">
          <div class="bg-white py-3 baggage-container">
            <div
              class="flight-baggage baggage-info"
              *ngFor="let item of createRange(2); let i = index"
            >
              <div class="d-flex mb-3">
                <div>
                  <ngx-skeleton-loader
                    count="1"
                    appearance="circle"
                    [theme]="{
                      height: '40px',
                      width: '40px',
                      'margin-right': '20px'
                    }"
                  >
                  </ngx-skeleton-loader>
                </div>
                <div>
                  <small>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '140px',
                        'vertical-align': 'bottom',
                        'margin-bottom': '2px'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </small>
                  <div class="mb-2">
                    <ngx-skeleton-loader
                      count="1"
                      appearance="circle"
                      [theme]="{
                        height: '10px',
                        width: '10px',
                        'margin-right': '10px',
                        'margin-left': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                    <span>
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{
                          height: '10px',
                          width: '100px',
                          'margin-bottom': '5px'
                        }"
                      >
                      </ngx-skeleton-loader>
                      <br />
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <p class="extra-baggage">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      height: '15px',
                      width: '100px',
                      'margin-bottom': '0px',
                      'vertical-align': 'bottom'
                    }"
                  >
                  </ngx-skeleton-loader>
                </p>
                <div class="mb-2">
                  <small>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '70px',
                        'margin-bottom': '0px',
                        'vertical-align': 'sub'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </small>
                  <small>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{
                        height: '10px',
                        width: '200px',
                        'margin-bottom': '0px'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end skeleton for baggage -->
    </div>
    <!-- </ng-container> -->

    <!-- appears in case of invalid card (stripe responded with 400) -->
    <div
      *ngIf="!loading && supportMessage && invalidCard"
      class="confirmation text-center"
    >
      <h3 *ngIf="invalidCard" class="text-danger">Card is invalid</h3>
      <h6 class="h6">We could not proceed with your reservation</h6>
    </div>

    <!-- </ng-container> -->
  </div>
</div>
<!-- end confirmation page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
