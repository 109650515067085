import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-pay-later',
  templateUrl: './pay-later.component.html',
  styleUrls: ['./pay-later.component.css']
})
export class PayLaterComponent implements OnInit {
  elementHeight: any;
  isSticky:any;
  hasLoadedMainSec = false;
  paylater=true;
  images:any[]=[
    './assets/images/pay-later/book now 1.jpg',
    './assets/images/pay-later/book now, pay later2.jpg',
    './assets/images/pay-later/book now, pay later4.jpg',
    './assets/images/pay-later/traveler in airport 2.jpg',
    './assets/images/pay-later/traveler in airport 5.jpg',
 ]
  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private _plateform: object,
              private seoService: SEOService,
              private router: Router,)
              {
                this.seoUpdate();
              }
              seoUpdate(title = 'FlyAllOver | Book Flights Now, Pay Later',
                 description = 'FlyAllOver makes your journey easier by helping you to book now and pay later. With FlyAllOver, you can enjoy stress-free travel and take off with complete peace of mind knowing that each flight purchase is secure and budget-friendly. We provide an easy way to book',
                 image = 'https://flyallover.com/assets/images/book-now.jpg', url = 'https://api.flyallover.com/storage/') {
                this.seoService.updateTitle(this.router.url);
                this.seoService.updateDescription(this.router.url);
                this.seoService.updateUrl();
                this.seoService.updateImage('https://flyallover.com/assets/images/book-now.jpg');

                if (title != null) {
                  this.seoService.updateTitle(title);
                }
                if (description != null) {
                  this.seoService.updateDescription(description);
                }
                if (image != null) {
                  this.seoService.updateImage(image);
                }
              }
  ngOnInit(): void {
  }
  getHeight() {
    const elmnt = this.document.getElementById('side');
    this.elementHeight = elmnt.offsetHeight;
  }
  checkScroll() {
    if(isPlatformBrowser(this._plateform)){
    this.isSticky = window.pageYOffset >= 700;
    }
  }
}
