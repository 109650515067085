<ngb-carousel class="text-lg-center" [interval]="2000" [showNavigationArrows]="true"
              [showNavigationIndicators]="true"
              [keyboard]="true"
              [pauseOnHover]="true"
              [wrap]="true"
              [activeId]="'secondSlide'">
  <ng-template ngbSlide *ngFor="let image of images"  >
    <div id="overLay1">
      <div class="picsum-img-wrapper">
        <img
          class=" section__img-box"
          [src]="image.full_url"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">

      </div>
    </div>
  </ng-template>
  <!-- <ng-template ngbSlide >
    <div id="overLay2">
      <div class="picsum-img-wrapper">
        <img
          class="section__img-box"
          src="./assets/images/hotels/img/conference.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">

      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide >
    <div id="overLay">
      <div class="picsum-img-wrapper">
        <img
          class="section__img-box"
          src="./assets/images/hotels/img/gallery1.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">
      </div>
    </div>

  </ng-template>
  <ng-template ngbSlide >
    <div id="overLay9">
      <div class="picsum-img-wrapper">
        <img
          class="section__img-box"
          src="./assets/images/hotels/img/gallery2.jpg"
          alt="Random first slide"
          title="first slide"
        />
        <div class="filter"></div>
      </div>
      <div class="carousel-caption">

      </div>
    </div>

  </ng-template> -->
</ngb-carousel>
