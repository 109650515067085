<div class="back-btn" (click)="selectRound.emit()" >
  <img src="assets/icons/left-arrow-back-svgrepo-com.svg" alt="Back-Arrow">
  <b>back</b>
</div>
<div class="selected-ticket" >

  <div *ngFor="let itinerary of selectedItinerary; let i = index" class="itinerary position-relative" [ngClass]="{ 'first-result': i == 0 }">
    <div *ngIf="searchRequest?.offers == 'false'" class="page-offer">
      <img src="assets/icons/icons8-favorites.svg" alt="page offers">
    </div>
    <div class="row">
      <div *ngFor="let trip of itinerary.flights; let ii = index" class="list">

        <div class="content col-12">
          <div class="content-img col-3">
            <div class="text-center">
              <div class="selected-ticket-heading">
                <p *ngIf="selectedIndex==0" >Your selcted departure : {{trip[val].DepartureDateTime | date:'EE, MMM d, y'}}</p>
                <p *ngIf="selectedIndex==1">your selcted return : {{trip[returnIndex-1].DepartureDateTime | date:'EEEE, MMMM d, y'}}</p>

              </div>
             <div class="select-flight-checkbox" >
              <input type="checkbox" id="selectFight" name="selectFight" (click)="selectRound.emit()"  checked >
             </div>
            <div class="text-center" (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')">
              <img class="img"  src="https://c.fareportal.com/n/common/air/3x/{{trip[val]?.OperatingAirline}}.png" alt="">
              <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true" style="display: block;">{{flights[i]?.legs[ii]?.schedules[0]?.airline?.name|slice:0:22}}</small>
              <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" style="display: block;">{{trip[val]?.OperatingAirlineName|slice:0:22}}</small>

            </div>
            <!-- (mouseenter)="dialog.classList.add('hidden-dialog')" (mouseleave)="dialog.classList.remove('hidden-dialog')" -->
            <div class="d-flex justify-content-center" >
            <p *ngFor="let item of trip; let k=index" (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')"  (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog') ">{{item.AircraftCode+","}}</p>
            </div>
            <!-- *ngIf="showAirCraftPop" -->
            <div  style="color: white; position: absolute;" class=".dialog-airCraft"  #dialogAirCraft (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')" (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog')" >
              <div  class="arrow-up"></div>
              <span class="border-stop">
                <small *ngFor="let item of trip" >
                  {{item.AircraftName}}
          </small>
          <!-- <small >
            {{trip[val+1]?.AircraftName}}
    </small> -->
              </span>
            </div >
            <div class="additionalinfo">
              <p *ngFor="let item of trip" >{{item.OperatingAirline+' '}}{{item.MarketingFlightNumber+'-'}}{{'Operated by'+' '+item.OperatingAirlineName}}</p>
            </div>
          </div>

            <div  class="card-img" #popCard (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')">
              <div class="card-img__image-container">
                <img class="card-img__image" src="{{flights[i]?.legs[ii]?.schedules[0]?.airline?.main_image}}" alt="">
              </div>
              <div class="card-img__content">
                <h1 *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true"   class="card-img__title">{{flights[i]?.legs[ii]?.schedules[0]?.airline?.name|slice:0:22}}</h1>
                <h1 *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" class="card-img__title">{{trip[val]?.OperatingAirlineName|slice:0:22}}</h1>
                <p>{{flights[i]?.legs[ii]?.schedules[0]?.airline?.about|slice:0:500}}</p>
                <button *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true" type="button" [routerLink]="['/airline-description/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">More Details</button>
                <button *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" type="button" [routerLink]="['/airline-description/',trip[0]?.OperatingAirlineName]">More Details</button>
             </div>
            </div>
          </div>

          <div class="content-center col-6" >  <!---[class]="review && searchRequest?.offers == 'false' ? 'col-9':'col-6'"-->
            <div>
              <strong>{{trip[0]?.DepartureDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[0]?.DepartureDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[0]?.DepartureAirport}}</p>
            </div>
            <div>
              <strong style="font-weight: bold;" *ngIf="trip[1]?.FlightLayoverTime">{{convertTime(trip[1]?.FlightLayoverTime)}}<span *ngIf="trip?.length-1==2"  class="mx-1">/</span> <span *ngIf="trip?.length-1==2">{{convertTime(trip[2]?.FlightLayoverTime) }}</span></strong>
              <small style="display: block; margin: 6px 0;" *ngIf="trip[1]?.FlightLayoverTime">layover time</small>
              <div class="text-stop">
                <p *ngIf="trip?.length - 1 == 0">Non stop</p>
                <p *ngIf="trip?.length - 1 > 0" (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')">{{trip?.length - 1}} stop</p>
                  <div class="dialog-stop" #dialog (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')">
                    <div class="arrow-up"></div>
                    <span class="border-stop" *ngIf="trip?.length - 1 > 0">
                      <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span> {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
                      <div style="padding-left: 14px;">
                        <div class="d-flex">
                          <p class="stops-title white-space-no-wrap mr-1 font">Stop 1 : </p>
                          <span class="font white-space-no-wrap"> <span class="mx-1"style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[1].FlightLayoverTime) }}</span>
                            Layover, {{ trip[1].DepartureAirportName }} </span>
                        </div>
                        <div class="d-flex mt-2 position-relative"*ngIf="trip?.length-1>1">
                        <span class="line-dot"></span>
                          <p class="stops-title white-space-no-wrap mr-1 font">Stop 2 : </p>
                          <span class="font white-space-no-wrap"> <span class="mx-1"style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[2].FlightLayoverTime) }}</span>
                            Layover, {{ trip[2].DepartureAirportName }} </span>
                        </div>
                      </div>
                    </span>
                  </div>
              </div>
              <small class="total-trip"><span style="color: #f00;font-weight: bold;">Total Duration : </span> {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
            </div>
            <div>
              <strong>{{ trip[trip?.length - 1]?.ArrivalDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[trip?.length - 1]?.ArrivalDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[trip?.length - 1]?.ArrivalAirport }}</p>
            </div>

          </div>

            <!-- <div class="d-flex col-12" >
              <div class="col-3"  *ngFor="let brand of itinerary.flights[0][0].BrandFeatures | keyvalue">
                <div *ngIf="brand.key != 'BG'"
                (mouseover)="arrow_brands.classList.add('shoow');arrow_brands.classList.remove('hidden');brands.classList.add('shoow');brands.classList.remove('hidden')"
                (mouseout)="arrow_brands.classList.remove('shoow');arrow_brands.classList.add('hidden');brands.classList.remove('shoow');brands.classList.add('hidden')">
                <ng-container *ngIf="brand.key != 'BG'">
                  <img width="30"  [src]="'assets/icons/brand-futures/'+brand.key+'.png'">
                </ng-container>
                <span #arrow_brands class="arrow-top hidden"></span>
              </div>
              <div class="brands-popup hidden" #brands >
                <span class="d-flex justify-content-between my-2 align-items-center" *ngFor="let item of brand.value ;let i=index">
                  <span class="d-flex align-items-center">
                    <img width="20" style="margin-right: 4px;" [src]="'assets/icons/brand-futures/'+item.commercialName+'.png'">
                    <small class="mr-auto">{{item.commercialName}}</small>
                  </span>
                  <small style="color: blue;" *ngIf="item.application=='C'">CHARGABLE</small>
                  <small style="color: green;" *ngIf="item.application=='F'">FREE</small>
                  <small style="color: red; text-transform: uppercase;" *ngIf="item.application=='D'">not offered</small>
                </span>
              </div>
            </div>
            </div> -->
          <div class="content-button col-3" >

            <div [class]="'top-50' ">
              <p [ngClass]="(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount==cheapestFlights)?'cheapest-price':'not-cheapest-price'" *ngIf="searchRequest?.offers != 'false'">{{(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount)| currency}}</p>
              <!-- <button class="btn-book" *ngIf="toggleDetails" type="button" (click)="select.emit(itinerary.TagID)">Book Now</button> -->
            </div>
          </div>

          <div class="content-button col-3">
            <p class="text-content-button" *ngIf="ii==0 && searchRequest?.offers != 'false'">price per person (incl. taxes & fees)</p>
            <p class="text-content-button" *ngIf="ii==1 && searchRequest?.offers == 'false'">follow these easy steps to get a special deal</p>
          </div>

        </div>

      </div>

      <div class="btn-flight-details" (click)="toggle.classList.contains('hide') ? toggle.classList.toggle('show') : toggle.classList.toggle('hide');iconToggle.classList.toggle('rotate')" style="cursor: pointer;">
        <button type="button">
          <div class="d-flex justify-content-center">
            <span style="color: #152B79;"> flight details </span>
            <div class="marketing">
          <div
           class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[0];">
          <span >{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{' '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
        </div>
        <div
        class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[1];">
       <span >{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{' '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
     </div>
    </div>
      </div>
        </button>
        <img #iconToggle src="../../../assets/images/flights/arrow-down.svg" alt="icon">
      </div>
      <div #toggle class="p-0 mx-auto" style="width: 98.5%;" [class]="toggleDetails ?  'hide': ''">
        <app-flight-details-drop-down [reviewFlight]="reviewFlight" [sabre]="sabre" [itinerary]="itinerary" [origin]="origin"
        [destination]="destination">
        </app-flight-details-drop-down>
      </div>
    </div>
  </div>
</div>
<div class="select-departure-return py-2">
<p *ngIf="selectedIndex==1" >Now select your departure flight from : {{searchRequest.fullOrigin}}</p>
<p *ngIf="selectedIndex==0" >Now select your return flight to : {{searchRequest.fullDestination}}</p>
</div>
<div >
  <div *ngFor="let itinerary of itineraries; let i = index" class="itinerary position-relative" [ngClass]="{ 'first-result': i == 0 }">
    <div *ngIf="searchRequest?.offers == 'false'" class="page-offer">
      <img src="assets/icons/icons8-favorites.svg" alt="page offers">
    </div>
    <div class="row">
      <div *ngFor="let trip of itinerary.flights; let ii = index" class="list">

        <div class="content col-12">
          <div class="content-img col-3">
            <div class="text-center">
             <div  class="select-flight-checkbox" *ngIf="searchRequest.trip_type=='Return'" (mouseover)="checkboxDialog.classList.add('checkboxDialogContentShow')"
             (mouseout)="checkboxDialog.classList.remove('checkboxDialogContentShow')" >
              <input type="checkbox" id="selectFight" name="selectFight" (click)="getItineraries(itinerary)" >
             </div>
             <div #checkboxDialog class="checkboxDialogContent">
              <p *ngIf="selectedIndex==0" >choose your return</p>
              <p *ngIf="selectedIndex==1" >choose your depart</p>
           </div>
            <div class="text-center" (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')">
              <img class="img"  src="https://c.fareportal.com/n/common/air/3x/{{trip[val]?.OperatingAirline}}.png" alt="">
              <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true" style="display: block;">{{flights[i]?.legs[ii]?.schedules[0]?.airline?.name|slice:0:22}}</small>
              <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" style="display: block;">{{trip[val]?.OperatingAirlineName|slice:0:22}}</small>

            </div>
            <!-- (mouseenter)="dialog.classList.add('hidden-dialog')" (mouseleave)="dialog.classList.remove('hidden-dialog')" -->
            <div class="d-flex justify-content-center" >
            <p *ngFor="let item of trip; let k=index" (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')"  (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog') ">{{item.AircraftCode+","}}</p>
            </div>
            <!-- *ngIf="showAirCraftPop" -->
            <div  style="color: white; position: absolute;" class=".dialog-airCraft"  #dialogAirCraft (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')" (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog')" >
              <div  class="arrow-up"></div>
              <span class="border-stop">
                <small *ngFor="let item of trip" >
                  {{item.AircraftName}}
          </small>
          <!-- <small >
            {{trip[val+1]?.AircraftName}}
    </small> -->
              </span>
            </div >
            <div class="additionalinfo">
              <p *ngFor="let item of trip" >{{item.OperatingAirline+' '}}{{item.MarketingFlightNumber+'-'}}{{'Operated by'+' '+item.OperatingAirlineName}}</p>
            </div>
          </div>

            <div  class="card-img" #popCard (mouseover)="popCard.classList.add('card-img-show')" (mouseout)="popCard.classList.remove('card-img-show')">
              <div class="card-img__image-container">
                <img class="card-img__image" src="{{flights[i]?.legs[ii]?.schedules[0]?.airline?.main_image}}" alt="">
              </div>
              <div class="card-img__content">
                <h1 *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true"   class="card-img__title">{{flights[i]?.legs[ii]?.schedules[0]?.airline?.name|slice:0:22}}</h1>
                <h1 *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" class="card-img__title">{{trip[val]?.OperatingAirlineName|slice:0:22}}</h1>
                <p>{{flights[i]?.legs[ii]?.schedules[0]?.airline?.about|slice:0:500}}</p>
                <button *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true" type="button" [routerLink]="['/airline-description/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">More Details</button>
                <button *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" type="button" [routerLink]="['/airline-description/',trip[0]?.OperatingAirlineName]">More Details</button>
             </div>
            </div>
          </div>

          <div class="content-center col-6" >  <!---[class]="review && searchRequest?.offers == 'false' ? 'col-9':'col-6'"-->
            <div>
              <strong>{{trip[0]?.DepartureDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[0]?.DepartureDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[0]?.DepartureAirport}}</p>
            </div>
            <div>
              <strong style="font-weight: bold;" *ngIf="trip[1]?.FlightLayoverTime">{{convertTime(trip[1]?.FlightLayoverTime)}}<span *ngIf="trip?.length-1==2"  class="mx-1">/</span> <span *ngIf="trip?.length-1==2">{{convertTime(trip[2]?.FlightLayoverTime) }}</span></strong>
              <small style="display: block; margin: 6px 0;" *ngIf="trip[1]?.FlightLayoverTime">layover time</small>
              <div class="text-stop">
                <p *ngIf="trip?.length - 1 == 0">Non stop</p>
                <p *ngIf="trip?.length - 1 > 0" (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')">{{trip?.length - 1}} stop</p>
                  <div class="dialog-stop" #dialog (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')">
                    <div class="arrow-up"></div>
                    <span class="border-stop" *ngIf="trip?.length - 1 > 0">
                      <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span> {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
                      <div style="padding-left: 14px;">
                        <div class="d-flex">
                          <p class="stops-title white-space-no-wrap mr-1 font">Stop 1 : </p>
                          <span class="font white-space-no-wrap"> <span class="mx-1"style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[1].FlightLayoverTime) }}</span>
                            Layover, {{ trip[1].DepartureAirportName }} </span>
                        </div>
                        <div class="d-flex mt-2 position-relative"*ngIf="trip?.length-1>1">
                        <span class="line-dot"></span>
                          <p class="stops-title white-space-no-wrap mr-1 font">Stop 2 : </p>
                          <span class="font white-space-no-wrap"> <span class="mx-1"style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[2].FlightLayoverTime) }}</span>
                            Layover, {{ trip[2].DepartureAirportName }} </span>
                        </div>
                      </div>
                    </span>
                  </div>
              </div>
              <small class="total-trip"><span style="color: #f00;font-weight: bold;">Total Duration : </span> {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
            </div>
            <div>
              <strong>{{ trip[trip?.length - 1]?.ArrivalDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[trip?.length - 1]?.ArrivalDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[trip?.length - 1]?.ArrivalAirport }}</p>
            </div>

          </div>

            <!-- <div class="d-flex col-12" >
              <div class="col-3"  *ngFor="let brand of itinerary.flights[0][0].BrandFeatures | keyvalue">
                <div *ngIf="brand.key != 'BG'"
                (mouseover)="arrow_brands.classList.add('shoow');arrow_brands.classList.remove('hidden');brands.classList.add('shoow');brands.classList.remove('hidden')"
                (mouseout)="arrow_brands.classList.remove('shoow');arrow_brands.classList.add('hidden');brands.classList.remove('shoow');brands.classList.add('hidden')">
                <ng-container *ngIf="brand.key != 'BG'">
                  <img width="30"  [src]="'assets/icons/brand-futures/'+brand.key+'.png'">
                </ng-container>
                <span #arrow_brands class="arrow-top hidden"></span>
              </div>
              <div class="brands-popup hidden" #brands >
                <span class="d-flex justify-content-between my-2 align-items-center" *ngFor="let item of brand.value ;let i=index">
                  <span class="d-flex align-items-center">
                    <img width="20" style="margin-right: 4px;" [src]="'assets/icons/brand-futures/'+item.commercialName+'.png'">
                    <small class="mr-auto">{{item.commercialName}}</small>
                  </span>
                  <small style="color: blue;" *ngIf="item.application=='C'">CHARGABLE</small>
                  <small style="color: green;" *ngIf="item.application=='F'">FREE</small>
                  <small style="color: red; text-transform: uppercase;" *ngIf="item.application=='D'">not offered</small>
                </span>
              </div>
            </div>
            </div> -->
          <div class="content-button col-3" >

            <div [class]="'top-50' ">
              <p [ngClass]="(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount==cheapestFlights)?'cheapest-price':'not-cheapest-price'" *ngIf="searchRequest?.offers != 'false'">
                <span *ngIf="(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount)-(selectedItinerary[0]?.BaseFare?.Amount + selectedItinerary[0]?.taxfees?.Amount)>=0" >+</span>{{(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount)-(selectedItinerary[0]?.BaseFare?.Amount + selectedItinerary[0]?.taxfees?.Amount)| currency}}</p>
              <button class="btn-book" *ngIf="toggleDetails" type="button"  (click)="select.emit(itinerary.TagID)" >Book Now</button>
            </div>
          </div>
          <!-- (click)="getItineraries(itinerary)" -->
          <!-- <div class="content-button col-3">
            <p class="text-content-button" *ngIf="ii==0 && searchRequest?.offers != 'false'">price per person (incl. taxes & fees)</p>
            <p class="text-content-button" *ngIf="ii==1 && searchRequest?.offers == 'false'">follow these easy steps to get a special deal</p>
          </div> -->

        </div>

      </div>

      <div class="btn-flight-details" (click)="toggle.classList.contains('hide') ? toggle.classList.toggle('show') : toggle.classList.toggle('hide');iconToggle.classList.toggle('rotate')" style="cursor: pointer;">
        <button type="button">
          <div class="d-flex justify-content-center">
            <span style="color: #152B79;"> flight details </span>
            <div class="marketing">
          <div
           class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[0];">
          <span >{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{' '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
        </div>
        <div
        class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[1];">
       <span >{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{' '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
     </div>
    </div>
      </div>
        </button>
        <img #iconToggle src="../../../assets/images/flights/arrow-down.svg" alt="icon">
      </div>
      <div #toggle class="p-0 mx-auto" style="width: 98.5%;" [class]="toggleDetails ?  'hide': ''">
        <app-flight-details-drop-down [reviewFlight]="reviewFlight" [sabre]="sabre" [itinerary]="itinerary" [origin]="origin"
        [destination]="destination">
        </app-flight-details-drop-down>
      </div>
    </div>
  </div>
</div>
