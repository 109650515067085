import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BlogService {

  // domain
  private domain = environment.domain;

  constructor(private http: HttpClient) { }

  getCategories() {
    return this.http.get(`${this.domain}/api/blog/categories`);
  }
  getAllArticles(params) {
    return this.http.get(`${this.domain}/api/blog/articles`, { params: params });
  }
  getArticlesOfCategory(id: string, params) {
    return this.http.get(`${this.domain}/api/blog/articles/category/${id}`, { params: params })
  }
  getArticle(slug: string) {
    return this.http.post(`${this.domain}/api/blog/articles/${slug}`, {})
  }
  articlesSearch(params) {
    // params includes search term, page and paginate fields
    return this.http.get(`${this.domain}/api/blog/articles/search`, { params: params });
  }

}
