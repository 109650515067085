<app-navbar></app-navbar>
<div class="page">
  <div class="container">
    <ng-container *ngIf="errorSearchData">
      <div class="no-data d-none">
        <h1 class="error Font-Family " >Error occurred while trying to open this page</h1>
        <h3>
          Go back to <a [routerLink]="['/flight-results']" class="Font-Family">search results</a>
        </h3>
      </div>
      <app-no-result [novbar]="false"></app-no-result>
    </ng-container>
    <div class="row" *ngIf="!errorSearchData">
      <section class="col-12 navigation p-0">
        <app-review-flight-navigation
          [currentStep]="step"
        ></app-review-flight-navigation>
      </section>
      <section class="col-12 tripInfo p-0">
        <app-review-flight-trip-info [itineraries]="itineraries" [offers]="offers" [searchRequest]="searchRequest"></app-review-flight-trip-info>
      </section>
      <div class="col-9 ps-0" [ngClass]="{'col-12 p-0': step==3}">
        <div class="row">
            <ng-container *ngIf="((!itineraries && !destination) || (tripType == 'OpenJaw' && !itineraries)||!itineraries[0]) && !error">
                <div class="w-100 filters mb-3" >
                  <div class="w-100 d-flex overflow-hidden">
                    <ng-container *ngFor="let item of airlineImg; let i = index">
                      <div class="animated-css bounceInRight" *ngIf="randomMin<i<airlineImg.length">
                        <img class="" src="{{item['url']}}">

                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row" >
                  <ng-container >
                    <section class="w-100">
                      <div class="skeleton-container">
                        <ngx-skeleton-loader
                          [theme]="{ height: '300px' }"
                        ></ngx-skeleton-loader>
                      </div>
                    </section>
                  </ng-container>
                </div>
            </ng-container>
            <!-- *ngIf="(itineraries && flights && destination) || (tripType == 'OpenJaw' && itineraries)" -->
            <ng-container >
              <section class="col-12 p-0">
                <ng-container *ngIf="step == 1">
                  <app-review-flight-itinerary-details
                    (fareDate)="getData($event)"
                    (nextStepEvent)="nextStep()"
                    [itineraries]="itineraries"
                    [flightResultsPage]="flightResultsPage"
                    [airlines]="res"
                    [origin]="origin"
                    [destination]="destination"
                    [flights]="flights"
                    [loading]="loadingFlight || (!itineraries && !error && !destination) || (tripType == 'OpenJaw' && !itineraries)||!itineraries[0]"
                  >
                  <!-- [sabre]="sabre" -->
                  </app-review-flight-itinerary-details>
                </ng-container>
                <ng-container *ngIf="step == 2">
                  <app-review-trip-travellers-data
                    [searchRequest]="searchRequest"
                    [offers]="offers"
                    [selectedTravellers]="selectedTravellers"
                    (updateSelectedTravellersEvent)="setSelectedTravellers($event)"
                    (dataPnr_Output)="dataPnr_Output($event)"
                    (nextStepEvent)="nextStep()"
                    (previousStepEvent)="previousStep()"
                    (countriesOut)="getCountries()"
                    (accountOut)="getAccountInfo()"
                    (ownerinfo)="getProfile()"
                    (familyMember2)="getFamilyMembers()"
                    (familyRelation)="getFamilyRelations()"
                    [countries]="countries"
                    [accountInfo]="accountInfo"
                    [OwnerInfo]="OwnerInfo"
                    [familyMembers]="familyMembers"
                    [FamilyRelation1]="familyrelation"
                    (setSelectedTravellers)="noAuthSelectedTraveller($event)"
                    (reloadFamilyMember)="getFamilyMembers()"
                  >
                  </app-review-trip-travellers-data>
                </ng-container>
                <!--          <ng-container *ngIf="step == 3">-->
                <!--            <app-review-flight-trip-protection-->
                <!--              (nextStepEvent)="nextStep()"-->
                <!--              (previousStepEvent)="previousStep()"-->
                <!--              (setProtectionEvent)="setProtection($event)"-->
                <!--              (InsuranceInfo2)="getInsuranceInfo()"-->
                <!--              [req]="initInsuranceRequest()"-->
                <!--              [itineraries]="itineraries"-->
                <!--              [tripType]="tripType"-->
                <!--              [tagId]="tagId"-->
                <!--              [InsuranceInfo]="InsuranceInfo"-->
                <!--            >-->
                <!--            </app-review-flight-trip-protection>-->
                <!--          </ng-container>-->
                <!--          <ng-container *ngIf="step == 4">-->
                <!--            <app-review-flight-seats-->
                <!--              (nextStepEvent)="nextStep()"-->
                <!--              (previousStepEvent)="previousStep()"-->
                <!--              [travellers]="selectedTravellers"-->
                <!--              [tagId]="selectedTrip['tagId']"-->
                <!--              [itineraries]="itineraries[0].flights"-->
                <!--              [seats1]="seats"-->

                <!--              (seats2)="getSeats()"-->
                <!--            >-->
                <!--            </app-review-flight-seats>-->
                <!--          </ng-container>-->
                <ng-container *ngIf="step == 3">
                  <app-checkout [fareRulesData]="fareRulesData"></app-checkout>
                  <!-- <app-review-flight-payment
                    (nextStepEvent)="nextStep()"
                    [offers]="offers"
                    [payLater]="payLater"
                    (previousStepEvent)="previousStep()"
                    (updatePaymentDataEvent)="updatePaymentData($event)"
                    [paymentData]="updatePaymentDataVar"
                    [Countries2]="countries"
                    (countries2)="getCountries()"
                    [familyMembers]="data"
                  >
                  </app-review-flight-payment> -->
                </ng-container>
                <ng-container *ngIf="step == 4">
                  <app-review-flight-review-book
                    (previousStepEvent)="previousStep()"
                    (orderEvent)="order()"
                    (orderEventOffer)="orderOffers()"
                    [insurance]="insurance"
                    [itineraries]="itineraries"
                    [offers]="offers"
                  >
                  </app-review-flight-review-book>
                </ng-container>
                <!-- <ng-container *ngIf="step == 6">
                  <app-review-flight-thanks></app-review-flight-thanks>
                </ng-container> -->
              </section>
            </ng-container>
          </div>
      </div>
      <div class="col-3 border-1px "  [ngClass]="{'d-none': step==3}">
        <div class="row">
          <ng-container *ngIf="!itineraries && !error">
            <div class="row mt-2">
              <section class="col-12">
                <div class="skeleton-container">
                  <ngx-skeleton-loader
                    [theme]="{ height: '300px' }"
                  ></ngx-skeleton-loader>
                </div>
              </section>
            </div>
          </ng-container>
          <section class="col-12 p-0 " *ngIf="step!= 3">
            <app-review-flight-flight-price *ngIf="itineraries && offers != 'false'"  [itineraries]="itineraries"></app-review-flight-flight-price>
          </section>
          <section class="col-12 p-0 ">
            <app-review-flight-fare-rules   [itineraries]="fareRulesData"></app-review-flight-fare-rules>
          </section>
          <section class="col-12 p-0 ">
            <app-review-flight-bagging-information *ngIf="baggingFree && offers != 'false' && itineraries[0]"  [itineraries]="baggingFree"></app-review-flight-bagging-information>
          </section>
          <!-- <section class="col-12 p-0">
            <app-review-flight-right-section
              [itineraries]="itineraries"
            ></app-review-flight-right-section>
          </section> -->
          <section class="col-12">
                    <app-review-flight-secure-section  [offers]="offers" ></app-review-flight-secure-section>
          </section>
        </div>
      </div>

      <!-- <section class="col-12 tripInfo p-0" *ngIf="step == 3">
        <app-payment-itinerary [itineraries]="itineraries" [offers]="offers" [searchRequest]="searchRequest" [itineraryNumber]="1"></app-payment-itinerary>
      </section> -->
    </div>

  </div>
</div>
<!-- <app-important-links></app-important-links> -->
<app-footer></app-footer>
