<div class="dialog-custom-container" style="border-radius: 20px">
  <div class="d-flex justify-content-center mt-4">
  <img alt="information" title="information" style="height: 50px" src="./assets/images/dashboard/information.svg">
  </div>
  <h2 style="width: 100%; padding: 10px 0px; font-weight: bold; text-align: center">SIGN OUT</h2>
 <h4 class="text-center">Would you like to sign out from your account ?</h4>
  <hr class="hr">
  <div style="display: flex;justify-content: space-around; margin: 15px">
    <button (click)="close(); goSignOut() " class="submit-btn btn">SIGN-OUT</button>
    <button (click)="close()" class="button-light btn">CANCEL</button>
  </div>
</div>
