import {DOCUMENT} from '@angular/common';
import {Component, EventEmitter, Inject, Input, Output, OnChanges, OnInit} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-one-flight-seats',
  templateUrl: './review-flight-one-flight-seats.component.html',
  styleUrls: ['./review-flight-one-flight-seats.component.css']
})

export class ReviewFlightOneFlightSeatsComponent implements OnChanges , OnInit{
  @Output() selectSeatEvent = new EventEmitter();
  @Input() currentFlightIndex: any;
  @Input() currentTravellerIndex: any;
  @Input() currentFlight: any;
  @Input() currentTraveller: any;
  @Input() seats: any;
  columnsNames: any;
  rowsNames: any;
  selectedSeat: any;
  loading = true;

  characteristicObj = {
    PetInCabin: 'Pet In Cabin',
    SeatSuitableForAdultWithInfant: 'Seat Suitable For Adult With Infant',
    LeftSideOfAircraft: 'Left Side Of Aircraft',
    NotAllowedForInfants: 'Not Allowed For Infants',
    AisleSeat: 'Aisle Seat',
    ChargeableSeat: 'Chargeable Seat',
    SeatNotSuitableForChild: 'Seat Not Suitable For Child',
    ExitRowSeat: 'Exit Row Seat',
    CenterSeat: 'Center Seat',
    BulkheadSeat: 'Bulk head Seat',
    'PreferredSeat/PreferentialSeat': 'Preferred Seat',
    'SeatWithFacilitiesForHandicapped/IncapacitatedPassenger': 'With Facilities For Handicapped',
  };

  constructor(
    @Inject(DOCUMENT) private document: Document, private seoService: SEOService
  ) {
    this.seoUpdate();
  }
  ngOnInit() {
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnChanges(changes) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  getDurationString(minutes) {
    return (`${Math.floor(minutes / 60)}h ${minutes % 60}m`);
  }

  showSeatDetails(event, seat) {
    const seatElement = this.document.querySelector('.' + seat.column + '' + seat.row);
    const seatElementPosition = Math.round(event.clientY / window.innerHeight * 100);
    if (seatElementPosition > 50) {
      seatElement.classList.toggle('absoluteTop');
    } else {
      seatElement.classList.toggle('absoluteBottom');
    }
    seatElement.classList.toggle('hide');
  }

  hideSeatDetails() {
    this.document.querySelectorAll('.details').forEach((item) => {
      item.classList.add('hide');
    });
  }

  findSeat() {
    this.seats.forEach(s1 => {
      s1.forEach(s2 => {
        s2.seatRow.forEach(s3 => {
          if (s3.row.toString() === '25') {
          }
        });
      });
    });
  }

  unSelectOldSeat(e, i, j, k) {
    if (this.currentTraveller.seatIndex && this.currentTraveller.seatIndex[this.currentFlightIndex]) {
      const si = this.currentTraveller.seatIndex[this.currentFlightIndex].i;
      const sj = this.currentTraveller.seatIndex[this.currentFlightIndex].j;
      const sk = this.currentTraveller.seatIndex[this.currentFlightIndex].k;
      const oldSeat = this.seats[si][sj].seatRow[sk];
      oldSeat.occupationStatusCode = 'SeatIsFree';
    }
  }

  selectSeat(e, i, j, k) {
    this.unSelectOldSeat(e, i, j, k);
    e.occupationStatusCode = 'seatIsSelected';
    e.color = this.currentTraveller.color;
    e.i = i;
    e.j = j;
    e.k = k;
    this.selectSeatEvent.emit(e);
  }

  unSelectSeat(e) {
    e.occupationStatusCode = 'SeatIsFree';
    e.color = undefined;
  }

  // selectSeat(seat) {
  //   seat.occupationStatusCode = 'seatIsSelected';
  //   if (this.selectedSeat) {
  //     this.selectedSeat.occupationStatusCode = 'SeatIsFree';
  //   }
  //   this.selectedSeat = seat;
  //   const setSelectedSeatEventVar = {
  //     seat: this.selectedSeat.row + '' + this.selectedSeat.column,
  //     flightIndex: this.index,
  //     travellerIndex: this.travellerIndex
  //   };
  //   this.setSelectedSeatEvent.emit(setSelectedSeatEventVar);
  //   const nextTravellerIndex = this.travellerIndex + 1;
  //   if (nextTravellerIndex < this.travellers.length) {
  //     this.setTravellerIndexEvent.emit(nextTravellerIndex);
  //   } else {
  //     const nextFlightIndex = this.index + 1;
  //     if (nextFlightIndex < this.flights.length) {
  //       this.setIndexEvent.emit(nextFlightIndex);
  //     }
  //   }
  // }
  //
  // unSelectSeat(seat) {
  //   seat.occupationStatusCode = 'SeatIsFree';
  //   this.selectedSeat = undefined;
  // }
}


