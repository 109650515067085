<div class="signin">
    <!-- <div class="social-network text-center">
        <p>with your social network</p>
        <button class="google" (click)="useGoogle()"><i class="fab fa-google"></i> Google</button>
        <button class="facebook" (click)="useFacebook()"><i class="fab fa-facebook-f"></i> Facebook</button>
    </div> -->
    <div class="login">
        <div *ngIf="errorMessage" class="error text-center">{{errorMessage}}</div>
        <div class="row">
            <div class="col">
                <div><label for="firstName">Name or Email<span class="required">*</span></label></div>
                <div><input type="text" placeholder="Name or Email *" name="nameOrEmail" id="nameOrEmail"
                        [(ngModel)]="nameOrEmail" autocomplete="off" /></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div><label for="password">Password <span class="required">*</span></label></div>
                <div class="container-show-hide-password"><input type="{{typePassword}}" placeholder="Password"
                        name="password" id="password" [(ngModel)]="password" autocomplete="off" /><span
                        (click)="showHidePassword()" class="show-hide-password"><i *ngIf="typePassword == 'password'"
                            class="far fa-eye"></i> <i *ngIf="typePassword == 'text'"
                            class="far fa-eye-slash"></i></span></div>
            </div>
        </div>
        <div class="row last-remember">
            <div class="col">
                <input id="remember" type="checkbox" (change)="rememberMe()" />
                <label for="remember">Remember Me</label>
            </div>
            <!-- <div class="col text-right">
            <p (click)="passwordRecovery()">Lost your password?</p>
          </div> -->
        </div>
        <div class="row">
            <div class="col">
                <button class="submit" (click)="submitSignIn()">Login <i *ngIf="loginSpinner"
                        class="fas fa-spinner fa-spin"></i></button>
            </div>
        </div>
    </div>
</div>