import {Component, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {ApisService} from '../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {metaData} from '../home-meta-tag.component';
import {SEOService} from '../../services/seo.service';
import {LocalStorageService} from '../../services/webApis/local-storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @Output() viewTicketElement: number;
  category: string;
  selectedItem = 'tab1';
  ticketComponent: boolean = false; // if ture booking component is hidden and ticket component is viewed
  token: string;

  constructor(
    private seoService: SEOService,
    private router: Router,
    private apis: ApisService,
    private cookie: CookieService,
    private title: Title,
    private metaService: MetaTagsService,
    private localStorage: LocalStorageService
  ) {
    this.seoUpdate();
    this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
  }

  ngOnInit() {
    this.goSignOut();
  }

  seoUpdate() {

    this.seoService.updateTitle('Flyallover Profile ');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  listClick(event, newValue) {
    this.selectedItem = newValue;
  }

  goSignOut() {
    
    this.apis.logout(this.token).subscribe((data: any) => {
      if (data.status == true) {
        this.cookie.set('flyallover_token', '')

        if (this.cookie.get('flyallover_token') == '') {
          this.router.navigate(['/']);
        }
      }
    }, (err: any) => {
      console.log(err);
    });
  }

  toggleComponent(toggle: boolean, event?) {
    toggle ? this.ticketComponent = true : this.ticketComponent = false;
    if (event) {
      this.viewTicketElement = event.ticketIndex;
      this.category = event.ticketCategory;
    }
  }

  setMetaTags() {
    this.title.setTitle(metaData.title);
    this.metaService.modifyMetaTags(metaData.meta_data);
  }
}
