import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-timer-reminder-dialog',
  templateUrl: './review-flight-timer-reminder-dialog.component.html',
  styleUrls: ['./review-flight-timer-reminder-dialog.component.css']
})

export class ReviewFlightTimerReminderDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ReviewFlightTimerReminderDialogComponent>,
    private seoService: SEOService
  ) {
  }

  ngOnInit() {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}


