import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-flight-engine',
  templateUrl: './flight-engine.component.html',
  styleUrls: ['./flight-engine.component.scss']
})
export class FlightEngineComponent implements OnInit {
  @Output() modifiedSearchRequestEvent = new EventEmitter();
  @Output() offsetHeight = new EventEmitter();
  @Input() searchRequest;
  @Input() airLine;
  codeAirLine = '';
  private dialogRef = null;
  selectedIndex: number;
  classTrip = true;

  // public dialogRef: MatDialogRef<EngineComponent>

  constructor(
    // private injector: Injector,

    // public dialogRef: MatDialogRef<EngineComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dialogRef = this.injector.get(MatDialogRef, null);
  }

  close(): void {
    // this.dialogRef?.close();

  }
  ngOnInit(): void {
    switch (this.searchRequest.trip_type) {
      case 'Return':
        this.selectedIndex = 0;
        break;
      case 'OneWay':
        this.selectedIndex = 1;
        break;
      case 'OpenJaw':
        this.selectedIndex = 2;
        break;
    }
  }

  setOffsetHeight() {
    this.offsetHeight.emit();
  }

  applyModificationEvent(searchRequest): void {

    this.searchRequest = searchRequest;
    this.modifiedSearchRequestEvent.emit(this.searchRequest);
  }

  searchDone(searchDone){
    this.searchDone = searchDone;
    this.modifiedSearchRequestEvent.emit(this.searchDone);
  }
}
