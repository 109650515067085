<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start profile page -->
<div class="profile-body">
  <div class="containerme">

      <!-- start holderbox -->
      <div class="holderbox">
          <div class="holderbox-sidebar">
              <!-- start sidebarbox -->
              <div class="sidebarbox tabs-windows">
                  <ul class="list-unstyled tabs">
                      <li class="tab tab1" [ngClass]="{'active': selectedItem == 'tab1'}" (click)="listClick($event, 'tab1')">
                        <img alt="profile-icon-sidebar-man"  src="./assets/images/profile/profile-icon-sidebar-man.svg" title="My Profile" /> My Profile</li>
                      <li class="tab tab2" [ngClass]="{'active': (selectedItem == 'tab2' && !ticketComponent)}" (click)="listClick($event, 'tab2'); toggleComponent(false)">
                        <img alt="profile-icon-sidebar-label"  src="./assets/images/profile/profile-icon-sidebar-label.svg" title="My Bookings" /> My Bookings</li>
                      <li class="tab tab3" [ngClass]="{'active': selectedItem == 'tab3'}" (click)="listClick($event, 'tab3')"><i class="far fa-file mr-2"></i> My Documents</li>
                      <li class="tab" (click)="goSignOut()"><img alt="profile-icon-sidebar-login"  title="Sign Up" src="./assets/images/profile/profile-icon-sidebar-login.svg" /> Sign out</li>
                  </ul>
              </div>
              <!-- end sidebarbox -->
          </div>

      </div>
      <!-- end holderbox -->

  </div>
</div>
<router-outlet></router-outlet>
<div class="clearfix"></div>
<!-- end profile page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
