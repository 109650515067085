import {Component, EventEmitter, Inject, Input, Output, OnChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ApisService} from 'src/app/services/apis.service';
import {LocalStorageService} from '../../services/webApis/local-storage.service';
import {MatDialog} from '@angular/material/dialog';
import {AlternativeDatesComponent} from '../alternative-dates/alternative-dates.component';
import {ActivatedRoute, Router} from '@angular/router';
// import {trigger, transition, animate, style} from '@angular/animations';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-flight-results-sorts',
  templateUrl: './flight-results-sorts.component.html',
  styleUrls: ['./flight-results-sorts.component.css'],
  // animations: [
  //   trigger('fadeInOut', [
  //     transition(':enter', [   // :enter is alias to 'void => *'
  //       style({opacity: 0}),
  //       animate(700, style({opacity: 1}))
  //     ]),
  //     transition(':leave', [   // :leave is alias to '* => void'
  //       animate(700, style({opacity: 0}))
  //     ])
  //   ])
  // ]

})

export class FlightResultsSortsComponent implements OnChanges {
  @Input() requestId;
  @Input() response;
  @Input() tripType;
  @Output() filterAlternativeDatesEvent = new EventEmitter();
  @Output() setOpenDetailsEvent = new EventEmitter();
  @Output() selectTagId = new EventEmitter();
  @Output() selectedObject = new EventEmitter();
  @Output() emitData = new EventEmitter();
  @Input() openDetails: any = false;
  data: any;
  dialogRef: any;
  airLines: any = [];
  formData: any;
  // randomMin: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private dataFake: DataService,
  ) {
    // this.randomMin = Math.floor(Math.random() * this.dataFake.airlinesLog.length);
    // this.getArrayValues(0);

  }

  ngOnChanges(changes) {
    if (changes.requestId && changes.requestId.currentValue) {


    }
    
  }


  openAlternativeDates(): void {
    
  }

  timeFormatter(minutes) {
    return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
  }

}
