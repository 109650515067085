<app-navbar></app-navbar>

<div class="offers-page">

    <!-- start offers-header -->
    <div class="offers-header">
        <div class="overlay">
            <div class="container">
                <h1 class="text-center">Get all the offers you need in all the world in one place</h1>
            </div>
        </div>
    </div>
    <!-- end offers-header -->

    <!-- start offers-body -->
    <div class="offers-body">

        <!-- start search-box -->
        <div class="search-box">
            <div class="container">

                <div class="form" [formGroup]="searchForm">
                    <div class="row">

                        <div class="col">
                            <div class="field">
                                <label for="search">Search</label>
                                <input formControlName="destination" id="search" type="text"
                                    placeholder="Tours, Destinations.." [ngbTypeahead]="airportSearch"
                                    placement="bottom-left" autofocus (click)="$event.target.select()" />
                            </div>
                        </div>

                        <!-- <div class="col">
                            <div class="field">
                                <label for="type">Type</label>
                                <select formControlName="category_id" id="type">
                                    <option value="1">Flights</option>
                                    <option value="2">Hotels</option>
                                </select>
                            </div>
                        </div> -->

                        <div class="col">
                            <div class="field">
                                <label for="depart">Depart</label>
                                <div class="date">
                                    <input formControlName="departure_date" (focus)="departure.open()"
                                        [matDatepicker]="departure" readonly
                                        [placeholder]="initialDate | date:'dd/MM/yyyy'" [min]="initialDate"
                                        (dateChange)=" checkValue($event); return.open();"
                                        [max]="searchForm.value.return_date">
                                    <mat-datepicker-toggle [for]="departure">
                                        <mat-icon matDatepickerToggleIcon><img title="Departure" alt="calendar"  src="./assets/images/offers/calendar.svg"
                                                class="img-icon" width="20" /> </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #departure></mat-datepicker>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="field">
                                <label for="return">Return</label>
                                <div class="date">
                                    <input formControlName="return_date" readonly #returnInput (focus)="return.open()"
                                        [matDatepicker]="return" [min]="searchForm.value.departure_date || initialDate"
                                        [placeholder]="initialDate | date :'dd/MM/yyyy'" class="return-date">
                                    <mat-datepicker-toggle [for]="return">
                                        <mat-icon matDatepickerToggleIcon><img title="Return" alt="calendar"  src="./assets/images/offers/calendar.svg"
                                                class="img-icon" width="20" /> </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #return></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col text-right">
                            <button class="submit" type="submit" (click)="activateSearch()">
                                SEARCH
                                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- end search-box -->

        <!-- this part of the page appears initially when the page first gets rendered -->
        <ng-container *ngIf="!searchResults && !loading">
            <!-- start offers-posts -->
            <div *ngIf="page === 1" class="offers-posts">
                <div class="container">
                  <div class="col-12">
                    <h2>Latest Offers</h2>
                  </div>
                    <div class="row">
                        <div class="col" *ngFor="let offer of offers?.slice(0,3)">
                            <app-offer-card [offer]="offer"></app-offer-card>
                        </div>
                        <p *ngIf="offers?.length == 0" class="text-danger px-3">No Offers available</p>
                    </div>
                </div>
            </div>
            <!-- end offers-posts -->

            <!-- start offers-posts -->
            <div class="offers-posts" *ngIf="offers?.length > 0">
                <div class="container">
                    <h2>Most Popular Offers</h2>
                    <div class="row">
                        <ng-container *ngIf="page === 1">
                            <div class="col" *ngFor="let offer of offers?.slice(3,offers?.length)">
                                <app-offer-card [offer]="offer"></app-offer-card>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="page !== 1">
                            <div class="col" *ngFor="let offer of offers">
                                <app-offer-card [offer]="offer"></app-offer-card>
                            </div>
                        </ng-container>

                    </div>
                    <div class="ngb-pagination">
                        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize"
                            [collectionSize]="totalSize" (pageChange)="changePage(page)">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <!-- end offers-posts -->
        </ng-container>

        <!-- this part of the page appears when the user search for an offer -->
        <ng-container *ngIf="searchResults  && !loading">
            <div class="offers-posts">
                <div class="container">
                    <h2>
                        Search Results
                    </h2>
                    <p *ngIf="offers?.length == 0" class="text-danger p-3">No Offers were found</p>

                    <div class="row">
                        <div class="col" *ngFor="let offer of offers">
                            <app-offer-card [offer]="offer"></app-offer-card>
                        </div>
                    </div>
                    <div class="ngb-pagination">
                        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize"
                            [collectionSize]="totalSize" (pageChange)="changePage($event)">
                            <ng-template ngbPaginationPrevious>Prev</ng-template>
                            <ng-template ngbPaginationNext>Next</ng-template>
                        </ngb-pagination>
                    </div>
                    <div class="text-right">
                        <button class="submit" (click)="activateSearch(false); scrollToTop()">
                            BACK TO ALL OFFERS
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- this part of the page appears during loading of the page -->
        <ng-container *ngIf="loading">
            <div class="offers-posts">
                <div class="container">
                    <ngx-skeleton-loader count="1" [theme]="{width:'200px'}"></ngx-skeleton-loader>
                    <div class="row">
                        <div class="col" *ngFor="let offer of createRange(3)">
                            <a class="post">
                                <div class="post-photo">
                                    <ngx-skeleton-loader count="1" [theme]="{width:'100%', height: '100%'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="post-description">
                                    <h4>
                                        <ngx-skeleton-loader count="1" [theme]="{width:'150px'}"></ngx-skeleton-loader>
                                    </h4>
                                    <p>
                                        <ngx-skeleton-loader count="1" [theme]="{width:'100%'}"></ngx-skeleton-loader>
                                    </p>
                                    <label>
                                        <ngx-skeleton-loader appearance="circle" count="1"
                                            [theme]="{height:'15px', width:'15px', 'vertical-align':'super'}">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader count="1" [theme]="{width:'100px'}"></ngx-skeleton-loader>
                                    </label>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- end offers-body -->

</div>

<app-footer></app-footer>
