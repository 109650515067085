
<div class="conatainer">
    <div class="row">
<!--<button class="close mat-button"  (click)="close()">X</button>-->
        <div class="col-12">
          <div class="close-container close" (click)="close()">
            <div class="h">
              <div class="leftright"></div>
              <div class="rightleft"></div>
            </div>

<!--            <label class="close">close</label>-->
          </div>
         <h1 *ngIf="airline" class="text-center handel-tit"> You are going to search flights with <span>{{airline.name}}</span></h1>
        </div>
    </div>
    <div class="row">
            <div class="col-12">
              <app-engine [airLine]="airline.code">
              </app-engine>
            </div>
    </div>
</div>



