<app-navbar></app-navbar>
<div class="container" *ngIf="data">
    <!-- search-Engine  -->
    <div class="row afterNav">
        <div class="col-12 pt-5">
            <!--            <app-engine></app-engine>-->
            <div class="searchPanel">
                <app-flight-engine></app-flight-engine>
            </div>
        </div>
    </div>

    <!-- offers -->
    <div class="row g-0 mainRow headline add">

    </div>
    <!-- slider -->
    <!-- ======= offers Details Section ======= -->
    <div class="row g-0">
        <div class="col-8">
            <div class="row">
                <div class="col-12 ml-4">
                    <div class="flexDiv" style="justify-content: space-between">
                        <div class="flexDiv   " *ngIf="dataObj">
                            <h1 class="fao_mobile_title_meduim">{{ dataObj.name }}</h1>
                        </div>
                    </div>
                    <h1 class="cheapTicket Font-family">Cheap Flight Tickets</h1>
                </div>
                <div class="col-12">
                    <section id="portfolio-details" class="portfolio-details">
                        <div class="container">
                            <div class="row gy-4">
                                <div class="col-lg-12">
                                    <div class="portfolio-details-slider swiper">
                                        <div class="row g-0">
                                            <div class="col-12 g-0 mainRow" *ngIf="dataObj">
                                                <ngb-carousel>
                                                    <ng-template
                                                            ngbSlide
                                                            *ngFor="let item of dataObj.images; let i = index"
                                                    >
                                                        <div class="picsum-img-wrapper">
                                                            <img
                                                                    src="{{ item.full_url }}"
                                                                    alt="{{ dataObj.name}}"
                                                                    width="100%"
                                                                    style="border-radius: 12px"
                                                            />
                                                        </div>
                                                        <div class="carousel-caption"></div>
                                                        <div class="p-3 ">
                                                            <h6 class="handelText">Fare prices above were last found On
                                                                <span
                                                                        class="textDataColor">{{dataObj.created_at | date: "MM/dd/yyyy"}}
                                                                    <span class="text-dark p-1"> At </span>
                                                                    {{dataObj.created_at | date: "shortTime"}}</span>
                                                                Travel Dates Range
                                                                <span class="textDataColor">{{ dataObj.departure_date | date: "MM/dd/yyyy" }} </span>
                                                                <span class=" text-dark p-1"> To </span>
                                                                <span class="textDataColor">{{ dataObj.return_date | date: "MM/dd/yyyy" }}</span>
                                                                Fares are round trip and include all fuel surcharges -
                                                                Tickets are limited
                                                            </h6>
                                                        </div>
                                                    </ng-template>
                                                </ngb-carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- ///////////////////////offer By/////////////////////////////// -->

                            </div>
                            <div class="row">
                                <h2 class="info">Offer Info</h2>
                                <h3 class="fw-bold text-left">
                                    <p class=" To-color DataFont"><span class=" DataFontDate ">Date : </span><span
                                            class="text-dark DataFont px-2">From</span> {{ dataObj?.departure_date | date: "MM/dd/yyyy" }}
                                        <span class="text-dark DataFont px-2">To</span>
                                        <span class="To-color DataFont">{{dataObj?.return_date | date: "MM/dd/yyyy"}}</span>
                                    </p>

                                </h3>
                                <h3>
                            <span class="dropdown"><a class="size-links"
                                                      [routerLink]="['/airport-description',dataObj?.airport_origin.code]">{{ dataObj?.airport_origin.name }}</a>
                            <span class="dropdown-content-airport">
                               <ng-container>
                                  <img src="{{dataObj?.airport_origin.main_image}}" width="45" height="40">
                                  <span class="airlineDesc"> {{dataObj?.airport_origin.description|slice:0:120}}</span>
                                  <a class="size-links"
                                     [routerLink]="['/airport-description',dataObj?.airport_origin.code]">For More Details</a>
                               </ng-container>
                            </span>
                            </span>
                                    <span class="text-dark DataFont px-2">To</span>
                                    <span class="dropdown">
                              <a class="size-links"
                                 [routerLink]="['/airport-description',dataObj?.airport_destination.code]">{{dataObj?.airport_destination.name}}</a><span
                                            class="dropdown-content-airport">
                    <ng-container>
                      <img src="{{dataObj?.airport_destination.main_image}}" width="45" height="40">
                      <span class="airlineDesc"> {{dataObj?.airport_destination.description|slice:0:120}}</span>
                      <a [routerLink]="['/airport-description',dataObj?.airport_destination.code]">For More Details</a>
                    </ng-container>
                  </span>
                  </span>
                                </h3>
                                <h3 style="text-align: left;font-size: 22px;"
                                    *ngIf="dataObj">{{ dataObj?.offer_description }}</h3>
                            </div>
                            <div class="row mt-3">
                                <div class="d-flex justify-content-between align-items-baseline">
                                    <section>
                                        <div class="col-lg-1 order-lg-first " style="margin-top: -2.5rem;">
                                            <div class="sticky-content">
                                                <div class="social-icons social-icons-colored social-icons-vertical">
                                                    <div class="icon-share">
                                                        <span class="social-label ">SHARE:</span>
                                                        <p class="social-icon image-item" (click)="cop()"
                                                           target="_blank">
                                                            <img *ngIf="!copied" title="copy" class="copy"
                                                                 alt="copy"
                                                                 src="./assets/newIcon/copy-blue-icon.svg"/>
                                                            <img *ngIf="copied" title="copied" class="copied"
                                                                 alt="copied"
                                                                 src="./assets/newIcon/copied.png"/>
                                                        </p>
                                                        <!-- <p (click)="fb($event)" class="social-icon social-facebook"
                                                           title="Facebook"><i
                                                                class="icon-facebook-f"></i></p>
                                                        <p (click)="tw()" class="social-icon social-twitter"
                                                           title="Twitter"><i
                                                                class="icon-twitter"></i></p>
                                                        <p (click)="in()" class="social-icon social-linkedin"
                                                           title="Linkedin"><i
                                                                class="icon-linkedin"></i></p> -->
                                                    <a (click)="fb($event)" class="social-icon social-facebook" title="Facebook">
                                                        <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                                                        <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                                                        <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                                                        <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                                                        <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                                                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <!--                                        <div class="booking  py-2" *ngIf="dataObj">-->
                                    <!--                                            <button (click)="submit()">Book Now</button>-->
                                    <!--                                        </div>-->
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="portfolio-info" *ngIf="dataObj">
                <h2 class="text-center handelOffered mb-3"> Offered By {{ dataObj.airline.name }}</h2>
                <ul *ngIf="dataObj">
                    <div style="text-align: center; margin-right: 30px">
                        <a class="dropdown" [routerLink]="['/airline-description/',dataObj.airline.callsign]">
                            <img
                                    src="{{dataObj.airline.image }}"                 
                                    [alt]="dataObj.airline.name"
                                    width="200"
                            /><span class="dropdown-content">
              <ng-container>
                <img src="{{dataObj?.airline.main_image}}" [alt]="dataObj.airline.name" width="45" height="40"><br> <span
                      class="airlineDesc"> {{dataObj.airline.name}}</span><br>
                <small class="airlineDesc">{{dataObj.airline.about|slice:0:80}}</small><br>
                     <a [routerLink]="['/airline-description/',dataObj.airline.callsign]">For More Details</a>
              </ng-container>
            </span></a>

                        <div class="text-center">
                            <a class="size-links"
                               [routerLink]="['/Bagging-Information/',dataObj.airline.callsign]">
                                <br/>
                                Bagging Information
                            </a>
                            <br/>
                            <a class=" size-links Font-family" [routerLink]="['/cancellation-policy/',dataObj.airline.callsign]">
                                Cancellation Policy
                              </a>
                              <br/>
                            <a class="size-links" [routerLink]="['/Online-Check/',dataObj.airline.callsign]">
                                Online Check
                            </a>
                            <br/>
                            <a class="size-links" [routerLink]="['/FAQ/',dataObj.airline.callsign]">
                                FAQ
                            </a>
                        </div>

                    </div>
                </ul>
            </div>
            <!-- ///////////////////////offer info/////////////////////////////// -->
            <div class="portfolio-description" *ngIf="dataObj">
                <div class="offer-price-sec1 mb-5">
                    <h2 class="text-center fw-bold ">${{ dataObj.price }}</h2>
                    <small class="center-class fw-bold fs-5">{{ dataObj.class | uppercase }}</small>
                </div>
                <div class="  py-2 d-flex justify-content-center " *ngIf="dataObj">
                    <button class="fao_des_btn_med" (click)="submit()">Book Now</button>
                </div>

            </div>
        </div>
    </div>
    <div class="row g-0">
        <div class="col-7 pading">
            <app-show-rate [reviewData]="reviewData" ></app-show-rate>
            <app-add-reviews [keyId]="'flight_offer_id'" [cityId]="flightOfferId" (addReview)="setReview($event)"></app-add-reviews>

        </div>
        <div class="col-5 offerSlide">
            <app-point-slider-offers></app-point-slider-offers>
        </div>
    </div>
    <!-- =======  show reviews ======= -->
    <div class="row">
        <div class=" handel-pos col-7">
            <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="!showRev">Show All Reviews</button>
            <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="showRev">Hide All Reviews</button>
        </div>
        <div class="all-reviews">
            <div class="row">
                <owl-carousel-o *ngIf="showRev" [options]="customOptions" class="d-flex justify-content-center mt-5">
                    <ng-container *ngFor='let item of reviewData.reviews; let i = index'>
                        <ng-container>
                            <ng-template carouselSlide>
                                <app-share-reviews [data]="item"
                                                   [image]="item?.flight_offer.main_image"></app-share-reviews>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
  <div class="my-5"></div>
  <section class="my-5">
    <div class="mt-y mx-auto">
      <app-show-video></app-show-video>
    </div>
  </section>

    <div class="container  p-3 mt-2">
        <!-- End Details Section -->

        <!-- =======  offers Section ======= -->
        <section id="offers" class="section-with-bg">
            <div class="container">
                <div class="row mainRow headline">
                    <div class="container" data-aos="fade-up">
                        <div class="section-header text-center">
                            <h3 class="fao_mobile_title_large">Other Offers</h3>
                            <div
                                    style="
                  background-color: #000000;
                  height: 5px;
                  width: 100px;
                  margin: auto;
                  border-radius: 2px;
                " class="mb-5"></div>
                        </div>
                    </div>
                    <div
                            class="col-lg-4 col-md-4 mt-3"
                            *ngFor="let item of data|reverse; let i = index"
                    >
                        <div class="offer" *ngIf="i < 3">
                            <div class="offer-img">
                                <img
                                        src="{{ item.images[0].full_url }}"
                                        alt="{{ item.name }}"
                                        class=" rounded w-100 p-3"
                                        height="250px"
                                        width="100%"
                                />
                                <div class="text-center">
                                    <h4 class="fao_mobile_title_meduim mt-2">
                                        {{ item.name }}
                                    </h4>
                                    <p class="text-center fw-bold pt-2">
                                        <span class="pt-1 To-color DataFont">{{ item?.departure_date | date: "MM/dd/yyyy" }} </span>
                                        <span class=" px-2 bold text-dark DataFont">To</span>
                                        <span class="pt-1 To-color DataFont">{{ item?.return_date | date: "MM/dd/yyyy" }}</span>
                                    </p>
                                </div>
                            </div>
                            <div
                                    style="
                  background-color: #ddd4d4;
                  height: 1px;
                  width: 95%;
                  margin: auto;
                "
                            ></div>
                            <div>
                                <p class="fao_mobile_prgraph">
                                    {{ item?.offer_description |slice:0:100}}
                                </p>
                                <div class="line-height px-4">
                                    <h4 class="price">$ {{item.price}}</h4>
                                    <span class="d-flex justify-content-end text-muted">Round Trip</span>
                                </div>
                            </div>
                            <div class="center-class">
                                <button 
                                        class="fao_des_btn_med  my-4"
                                        
                                        [routerLink]="['/offers/',item.slug]"
                                        (click)="refresh(item.slug)"
                                >
                                    MORE DETAILS
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- End More offers Section -->
    </div>
</div>
<app-footer></app-footer>
