import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {SEOService} from '../../services/seo.service';
import {AppComponent} from "../../app.component";


@Component({
    selector: 'app-things-to-do-page',
    templateUrl: './things-to-do-page.component.html',
    styleUrls: ['./things-to-do-page.component.css']
})
export class ThingsToDoPageComponent implements OnInit {
    word = '';
    data: any = [];
    page: any = 1;
    pageSize = 10;
    collectionSize: number;
    name: string;
    countries:string;
    country_id: string;
    constructor(private apis: ApisService, private seoService: SEOService,) {
        this.seoUpdate();
    }

    ngOnInit(): void {
        this.getThingsToDo(0);
        this.seoUpdate();
        this.selectCountries()
    }

    prevent(e): void {
        e.preventDefault();
    }

    seoUpdate() {
        this.seoService.updateTitle('Thing To Do | Flyallover');
        this.seoService.updateDescription('Thing To Do With Your Flight | FlyAllOver');
        this.seoService.updateUrl();
        this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');
    }

    getThingsToDo(pageNumber: any) {
        if (typeof document !== 'undefined') {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
        this.apis.paginationThingsToDo(pageNumber + 1).subscribe((data: any) => {
                this.data = data.data.data;
                this.collectionSize = data.data.total;
                this.pageSize = data.data.per_page;
                this.page = +pageNumber;
                if (typeof document !== 'undefined') {
                    localStorage.setItem('pageNumber', this.page);
                  }
                
            },
            err => {
                console.log(err);
            });
    }

    search_thinks() {
        this.apis.searchThings(this.name ,this.country_id).subscribe((data: any) => {

                this.data = data.data.data;
            },
            err => {
                console.log(err);
            });
    }
/*    selectCountries() {
        this.apis.searchThings(this.name,this.country_id ).subscribe((data: any) => {
                this.data = data.data.data;
                this.countries = data.data.country;
                this.country_id=data.data.data.country.id
            },
            err => {
                console.log(err);
            });
    }*/

  selectCountries() {
        this.apis.getCountries().subscribe(
            (data: any) => {
                this.countries = data;
                for (let i = 0; i < this.data.length; i++) {
                    if (this.name == this.data[i].name) {
                        this.country_id = this.data[i].id;
                    }
                    // console.log('hghjgj', this.country_id)
                }
                this.search_thinks();
                console.log(this.data)
            },
            (err) => {
                console.log(err);
            }
        );
    }


}


