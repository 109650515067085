import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {ApisService} from '../../../services/apis.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { FlightDetailsDashboardComponent } from '../../flight-details-dashboard/flight-details-dashboard.component';
import {NotePayLaterComponent} from '../note-pay-later/note-pay-later.component';
@Component({
  selector: 'app-thanks-modal',
  templateUrl: './thanks-modal.component.html',
  styleUrls: ['./thanks-modal.component.css']
})
export class ThanksModalComponent implements OnInit {
  itinerary: any;
  token: any;
  flights: any;
  noteLog: any;
  class: any;
  allStep = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,]
  flightDetails: any;
  personalData: any;
  departureDate: any;
  errorMessages;
  paymentErrorMessages;
  flightData: any;
  travellersNum: number;
  continueToPayment = true;
  errorData: any[];
  ticketStep = 0;
  moveStep = 0;
  ticketMaxStep = 2;
  nextName = 'NEXT';
  backName = 'BACK';


  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ThanksModalComponent>,
    private router: Router,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private _HttpClient: HttpClient,
    public dialog: MatDialog,
  ) {

    this.token = this.localStorage.getItem('flyallover_token');
    this.itinerary = this.data?.dataKey;
    this.errorMessages = this.data?.error
    this.errorData = this.errorMessages?.error?.data;
    console.log('error messages ', this.errorMessages)
    console.log('error messages ', this.data)
  }

  ngOnInit(): void {

    console.log("Page name ", "ThanksModalComponent");
    if (this.itinerary) {
      this.localStorage.setItem('order_pnr',this.itinerary.order.pnr)
      this.localStorage.setItem('order_id',this.itinerary.order.id)
      console.log('this.drar', this.itinerary)
      this.flightDetails = JSON.parse(this.itinerary?.order.itinerary_details)
      console.log('this.flightDetails', this.flightDetails)
      this.flightNum()
      this.flightName()
    }
  }

  goToHome() {
    this.router.navigate(['/']);
  }
  goToDashboard() {
    this.router.navigate(['/profile/dashboard']);
  }

  flightName() {
    if (this.ticketStep == this.ticketMaxStep) {
      this.nextName = 'Payment';
      this.backName = 'Cancel';
    } else {
      this.nextName = 'Next';
      this.backName = 'Back';
    }

  }

  flightNum() {
    if (this.flightDetails.flights.length) {
      var flightNumFirst = this.flightDetails.flights.length;
      if (flightNumFirst == 2) {
        // var flightnum2 =
      }
    } else {
      var flightNumFirst = this.flightDetails.flights[0].length;

    }
    const flightNumChild = [];
    let totalLength = 0;
    for (let i = 0; i < flightNumFirst; i++) {
      const flightDetails = this.flightDetails.flights[i];

      if (flightDetails) {
        flightNumChild[i] = flightDetails.length;
        totalLength += flightDetails.length;
      }
    }
    this.ticketMaxStep = totalLength;
  }

  payment() {
    this.apis.continueToPaymentStep.next(3)

  }
  handelName(name) {
    var nameUpdata= name != null ? name : ` `;
    return nameUpdata;
  }

  routToDashboard(orderId) {
    this.token = this.localStorage.getItem('flyallover_token');

    this.apis.flightDetails(this.token, orderId).subscribe((data: any) => {
        this.flightData = data.data;

        this.travellersNum = +this.flightData.traveller_num;

        this.itinerary = JSON.parse(this.flightData.itinerary_details);
        this.router.navigate(['/profile'])
        this.openFlighetDetailsDailog(this.itinerary);
      },
      err => {
        console.log(err);
      });
  }

  openFlighetDetailsDailog(details) {
    const dialogRef = this.dialog.open(FlightDetailsDashboardComponent, {
      minHeight: '100vh',
      minWidth: '100%',
      // maxWidth:'59vw',
      data: {
        details: details,
        flights: this.flights,
        flightData: this.flightData
      }, panelClass: 'custom-container'
    })

  }

  f() {
  }

  onClick(): void {
    this.dialogRef.close();
  }

  // calcTotalFlightTime(durationTime) {
  //   return (`${Math.floor(durationTime / 60)}h ${durationTime % 60}m`);
  // }
  goToNextStep() {
    if (this.ticketStep >= 0 && this.ticketStep < this.ticketMaxStep) {
      // if(this.ticketStep>1 && this.ticketStep<3 && this.ticketStep !=2.2){
      //   this.ticketStep = this.ticketStep +0.1

      //else  {
      this.ticketStep++

      // }
      this.flightName()
      console.log('step', this.ticketStep)
    } else if (this.ticketStep == this.ticketMaxStep) {
      this.payment()
      this.dialogRef.close();
    }
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    let space = ' ';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}hr(s)`;
    }
    convertedMinutes = `${minutes % 60}min(s)`;
    return convertedHours + space + convertedMinutes;
  }

  goToPreviousStep() {

    if (this.ticketStep > 0 && this.ticketStep <= this.ticketMaxStep) {

      this.flightName()
      this.ticketStep--
    } else if (this.ticketStep == this.ticketMaxStep) {
      this.openNote()
      this.dialogRef.close();
    }
  }

  openNote(): void {
    this.noteLog = this.dialog.open(NotePayLaterComponent, {
      panelClass: 'alternativeDateDialog',
      autoFocus: false,
      minHeight: '97vh',
      width: "max-content",

    });
    this.noteLog.afterClosed().subscribe((data) => {
      this.router.navigate(['/']);
    });
  }

  protected readonly length = length;
}
