import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
// import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {SEOService} from '../../services/seo.service';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-help-privacy',
  templateUrl: './help-privacy.component.html',
  styleUrls: ['./help-privacy.component.css']
})
export class HelpPrivacyComponent implements OnInit {
[x: string]: any;

  privacyPolicy: any = {};
  privacyPolicyExist: boolean = false;
  searchText = '';
  formSubmitted: boolean = false;

  
  url: string;
  roundSpinner: boolean = false;
  id: any;
  @ViewChild('target') target: ElementRef;
  toScrollInto: string;

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
    private title: Title,
    private metaService: MetaTagsService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.apis.getPrivacyPolicy().subscribe(
      (res) => {
        this.privacyPolicy = res['data'];
        console.log(this.privacyPolicy ,'this.privacyPolicy')
        this.privacyPolicyExist = true;
      },
      (err) => {
        this.router.navigate(['/no-result']);
      }
      
    );
   
  }
  privacyDetails(privacyId) {
    console.log(privacyId);
    this.router.navigate([`/details/${privacyId}`]);
  }
  // scrollToElement($element): void {
  //   console.log($element);
  //   this.id = $element ;
  // //   $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
 
  // }

  seoUpdate() {

    this.seoService.updateTitle('Privacy | flyallover ');
    this.seoService.updateDescription('Data protection regulations: Flyallover data protection regulations regulate our handling of personal user data and other information.');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  onSearch() {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: this.searchText
      }
    })
  }

  addMetaScript(script: string) {
    this.metaService.addMetaScript(script);
  }

  modifyMetaTags(metaData) {
    this.metaService.modifyMetaTags(metaData);
  }

 
 
}
