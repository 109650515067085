import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-qatar-offer',
  templateUrl: './qatar-offer.component.html',
  styleUrls: ['./qatar-offer.component.css']
})
export class QatarOfferComponent implements AfterViewChecked  {
  @Input() autoPlay;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'QA';
  tripType: any = 'Return';

  ngAfterViewChecked() {
    // you could also do this after a service call of some sort
    this.finishedLoading.emit(true);
  }
}
