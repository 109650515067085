import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {SEOService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-airline-online-check',
    templateUrl: './airline-online-check.component.html',
    styleUrls: ['./airline-online-check.component.css']
})
export class AirlineOnlineCheckComponent implements OnInit {
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoplay: true,
        autoplayTimeout: 7000,
        dots: false,
        navSpeed: 700,
        nav: true,
        navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 2
            },
            940: {
                items: 3
            }
        },

    };
    display = true;
    airlineId: number;
    reviewData: any = [];
    showRev = false;
    total_review: number;
    star5: number;
    star4: number;
    star3: number;
    star2: number;
    star1: number;
    averageReview: number;
    metaTitle: any;
    metaDescription: string;
    metaImage: any;
    data: any = [];
    airlineName: any;
    airlineInfo: any;
    about: any;
    images: any = [];
    copied = false;


    constructor(private apis: ApisService, public activatedRoute: ActivatedRoute, location: Location, private router: Router, private seoService: SEOService, private clipboard: Clipboard) {

            this.airlineName = this.activatedRoute.snapshot.paramMap.get('name');
            this.getAirlineInfo();

        this.seoUpdate();
        this.getAirlineInfo();

    }

    ngOnInit(): void {
        this.airlineName = this.activatedRoute.snapshot.paramMap.get('name');
        this.getAirLines();
    }

    getAirLines() {
        this.apis.getHomeAirLines().subscribe((data: any) => {
                this.data = data.data;
                // this.metaTitle = this.data[0].name;
                // this.metaDescription = this.data[0].about;
                // this.metaImage = this.data[0].main_image;
                // this.getAirlineInfo();


            },
            err => {
                console.log(err);
            });
    }

    // getAirlineInfo() {
    //     this.images = [];
    //     for (let i = 0; i < this.data.length; i++) {
    //         if (this.airlineName == this.data[i].name) {
    //             this.airlineInfo = this.data[i];
    //             this.airlineId = this.data[i].id;
    //             this.about = this.airlineInfo.online_check_descrption;
    //             for (let i = 0; i < this.airlineInfo.images.length; i++) {
    //                 this.images.push(this.airlineInfo.images[i].full_url);
    //             }
    //             window.scrollTo(0, 0);
    //         }
    //     }
    //     this.metaTitle = this.airlineInfo.name;
    //     this.metaDescription = this.airlineInfo.meta_description;
    //     this.metaImage = this.airlineInfo.main_image;
    //     console.log('id', this.airlineId);
    //     this.getReviews();
    //     this.seoUpdate(
    //         this.metaTitle,
    //         this.metaDescription,
    //         this.metaImage
    //     );

    // }
    getAirlineInfo() {
      this.activatedRoute.params
          .pipe(map((prams) => prams.name))
          .subscribe((name) => {
            //   console.log(name, "xxxxxxxxxxxxxxxxxxxxxx");
              this.apis.getAirLineByCallsign(name).subscribe(
                  (data: any) => {
                      this.about = data.data.online_check_descrption;;
                      this.airlineInfo = data.data;
                      this.airlineId = this.airlineInfo.id;
                      for (let i = 0; i < this.airlineInfo.images.length; i++) {
                          this.images.push(this.airlineInfo.images[i].full_url);
                      }

                      this.metaTitle = this.airlineInfo.online_check_tag;
                      this.metaDescription = this.airlineInfo.meta_online_check_description;
                      this.metaImage = this.airlineInfo.main_image;
                      this.getReviews();
                      this.seoUpdate(
                          this.metaTitle,
                          this.metaDescription,
                          this.metaImage
                      );
                    //   console.log(this.airlineId);
                  },
                  (err) => {
                      console.log(err);
                  }
              );
          });
  }
    seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
        this.seoService.updateTitle(this.router.url);
        this.seoService.updateDescription(this.router.url);
        this.seoService.updateUrl();
        this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

        if (title != null) {
            this.seoService.updateTitle(title);
        }
        if (description != null) {
            this.seoService.updateDescription(description);
        }
        if (image != null) {
            this.seoService.updateImage(image);
        }
    }


    getReviews() {
        this.apis.getAirlineReviews(this.airlineId).subscribe(
            (res: any) => {
                this.reviewData = res.data;

            },
            (err) => {
                console.log(err);
            }
        );
    }

    setReview(event: any) {
        this.apis.addAirlineReviews(event).subscribe((data: any) => {
            this.data = data.data;
            this.getReviews();
        })
    }

    showReviews() {
        this.showRev = !this.showRev;
    }

    //
    cop() {
        this.clipboard.copy('https://www.flyallover.com' + this.router.url);
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500);
    }

    fb() {
        const url = 'www.google.com';

        const facebookWindow = window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' +
            'flyallover.com' +
            this.router.url,
            'facebook-popup',
            'height=350,width=600'
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        const url = 'www.google.com';
        const Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        const url = 'www.google.com';
        const Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

}
