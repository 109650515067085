import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from 'src/app/app.component';
import {ApisService} from 'src/app/services/apis.service';
import {SEOService} from '../../services/seo.service';
import {MatDialog} from '@angular/material/dialog';
import {EnginePointComponent} from '../../components/engine-point/engine-point.component';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-explore-details',
    templateUrl: './explore-details.component.html',
    styleUrls: ['./explore-details.component.css']
})
export class ExploreDetailsComponent implements OnInit {

    page: number = 1;
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        navSpeed: 700,
        nav: true,
        navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },

    };
    slug: any;
    thingData: any;
    data: any = [];
    cityName: string;
    countryName: string;
    latitude: any;
    longitude: any;
    about: string;
    isSticky: any;
    related: any = [];
    mainImageUrl: any;
    images: any = [];
    location: any;
    thingName: any;
    count: string;
    street_name: string;
    formatted_address: string;
    street_number: any;
    display = true;
    metaTitle: any;
    metaDescription: string;
    metaImage: any;
    pageNumber: any;
    reviewData: any = [];
    showRev = false;
    revId: any;
    firstName: any;
    token: any;
    relatedThingsToDo: any;
    countryId: any = [];
    cityId: number;
    copied: boolean =false;

    constructor(
        private router: Router,
        private apis: ApisService,
        public activatedRoute: ActivatedRoute,
        private seoService: SEOService,
        @Inject(DOCUMENT) private document: Document,
        public dialog: MatDialog,
        private clipboard:Clipboard
    ) {
        this.seoUpdate();
    }

    seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {

        if (title != null) {
            this.seoService.updateTitle(title);
        }

        if (description != null) {
            this.seoService.updateDescription(description);
        }

        this.seoService.updateUrl();

        if (image != null) {
            this.seoService.updateImage(image);
        }
    }

    ngOnInit(): void {
        this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
        if (typeof document !== 'undefined') {
            this.pageNumber = localStorage.getItem('pageNumber');
        }
        this.getThingToDo();
        this.getThingToDetails();
        if (typeof document === 'undefined') {
            this.display = false;
        } else {
            window.scrollTo(0, 0);
            this.display = true;
        }
    }

    refresh(id) {
        this.slug = id;
        this.getReviews();
        this.getThingToDetails();
        window.scrollTo(0, 0);
    }

    getrelatedThingsToDo(countryId) {
        this.apis.relatedThingsToDo(countryId).subscribe((data: any) => {
            // console.log(data);
            this.relatedThingsToDo = data.data;
            // console.log(this.relatedThingsToDo);
        });
    }


    getThingToDetails() {
        this.apis.thingsToDoDetails(this.slug).subscribe((data: any) => {
                this.data = data.data;
                // console.log(this.data);
                if (this.data) {
                    this.latitude = this.data.city?.lat;
                    this.longitude = this.data.city?.lng;
                    this.about = this.data.about;
                    this.cityId = this.data.id;
                    this.getReviews();
                    if (this.data.country) {
                        this.countryName = this.data.country?.name;
                        this.countryId = this.data.country.id;
                    }
                    this.mainImageUrl = this.data.featured_image;
                    this.images = this.data.images;
                    this.cityName = this.data.city?.name;
                    this.thingName = this.data.name;
                    this.street_name = this.data.address.street_name;
                    this.formatted_address = this.data.address.formatted_address;
                    this.street_number = this.data.address.street_number;
                    this.metaTitle = this.data.title_tag;
                    this.metaDescription = this.data.meta_description;
                    this.getrelatedThingsToDo(this.countryId);
                    this.metaImage = this.data.main_image;
                }
                this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);
                // console.log('seo', this.seoUpdate)
                // console.log('seos', this.seoService)
                // console.log('seo()', this.seoUpdate())

            },
            err => {
                console.log(err);
            });

    }

    getThingToDo() {
        this.apis.paginationThingsToDo(+this.pageNumber).subscribe((data: any) => {
                this.data = data.data.data;
                this.thingData = this.data.find(obj => {
                    return obj.slug === this.slug;
                });
                this.related = [];
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].city?.name == this.cityName && this.data[i].name != this.thingName) {
                        this.related.push(this.data[i]);

                    }

                }
                if (this.related.length < 20) {
                    for (let i = 0; i < 20; i++) {
                        if (this.data[i]?.country) {
                            this.count = this.data[i].country?.name;
                        }
                        if (this.count == this.countryName && this.data[i]?.name != this.thingName) {
                            if (this.related.includes(this.data[i]) === false) {
                                this.related.push(this.data[i]);
                            }
                        }
                    }
                    this.data = data.data.data;
                    this.thingData = this.data?.find(obj => {
                        return obj.slug === this.slug;
                    });

                    this.related = [];
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].city?.name == this.cityName && this.data[i].name != this.thingName) {
                            this.related.push(this.data[i]);

                        }

                    }
                    if (this.related.length < 20) {
                        for (let i = 0; i < 20; i++) {
                            if (this.data[i]?.country) {
                                this.count = this.data[i].country?.name;
                            }
                            if (this.count == this.countryName && this.data[i]?.name != this.thingName) {

                                if (this.related.includes(this.data[i]) === false) {
                                    this.related.push(this.data[i]);
                                }
                            }
                        }
                    }
                    if (this.related.length < 20) {
                        for (let i = 0; i < 20; i++) {
                            if (this.data[i]?.name != this.thingName) {
                                if (this.related.includes(this.data[i]) === false) {
                                    this.related.push(this.data[i]);
                                }
                            }
                        }
                    }
                }
            },
            err => {
                console.log(err);
            });
    }

    openDialog() {
        this.dialog.open(EnginePointComponent, {
            panelClass: 'custom-dialog',
        });
    }

    getReviews() {
        this.apis.getThingsReviews(this.cityId).subscribe((data: any) => {
                this.reviewData = data.data;
            },
            err => {
                console.log(err);
            });
    }

    setReview(event:any){
        this.apis.addThingsRviews(event).subscribe((data:any)=>{
            this.data=data.date;
            this.getReviews();
        })
    }
    showReviews() {
        this.showRev = !this.showRev;
    }

    cop() {
        this.clipboard.copy('https://www.flyallover.com' + this.router.url)
        this.copied=!this.copied;
        setTimeout(()=>{
            this.copied = false;
        },1500)
    }

    fb() {
        let url = "www.google.com";

        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "flyallover.com" +
            this.router.url,
            "facebook-popup",
            "height=350,width=600"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }
}


