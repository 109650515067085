<!-- <div class="w-100 filters mb-3 d-none" *ngIf="loading&&!selectRound && itineraries.length == 0">
  <div class="w-100 d-flex overflow-hidden">
    <ng-container *ngFor="let item of airlineImg; let i = index">
      <div class="animated-css bounceInRight" *ngIf="randomMin<i<airlineImg.length">
        <img class="" src="{{item['url']}}">

      </div>
    </ng-container>
  </div>
</div> -->
<!-- <div class="row" *ngIf="loading&&!selectRound &&loadCount != 1 || !itineraries[0]">
<ng-container *ngIf="(!itineraries && !destination) || (tripType == 'OpenJaw' && !itineraries)||!itineraries[0]">
  <section class="w-100">
    <div class="skeleton-container">
      <ngx-skeleton-loader
        [theme]="{ height: '300px' }"
      ></ngx-skeleton-loader>
    </div>
  </section>
</ng-container>
</div> -->
<!-- <ng-container *ngIf="loading&&!selectRound && itineraries.length == 0">
  <div *ngFor="let itinerary of itinerariesFackData; let i = index" class="itinerary"

    [ngClass]="{ 'first-result': i == 0 }">
    <div class="row">
      <div *ngFor="let trip of itinerary.flights; let ii = index" class="list">
        <div class="content col-12">
          <div class="content-img col-3">
            <div class="text-center">
              <img class="img" src="https://c.fareportal.com/n/common/air/3x/{{trip[val]?.OperatingAirline}}.png"
                alt="">
              <small style="display: block;">{{trip[val]?.OperatingAirlineName}}</small>
            </div>
          </div>
          <div class="content-center col-6">
            <div>
              <strong>{{trip[0]?.DepartureDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[0]?.DepartureDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[0]?.DepartureAirport}}</p>
            </div>
            <div>
              <strong style="font-weight: bold;"
                *ngIf="trip[1]?.FlightLayoverTime">{{convertTime(trip[1]?.FlightLayoverTime)}}<span
                  *ngIf="trip?.length-1==2" class="mx-1">/</span> <span
                  *ngIf="trip?.length-1==2">{{convertTime(trip[2]?.FlightLayoverTime) }}</span></strong>
              <small style="display: block; margin: 6px 0;" *ngIf="trip[1]?.FlightLayoverTime">layover time</small>
              <div class="text-stop">
                <p *ngIf="trip?.length - 1 == 0">Non stop</p>
                <p *ngIf="trip?.length - 1 > 0" (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')">{{trip?.length - 1}} stop</p>
                <div class="dialog-stop" #dialog (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')">
                  <div class="arrow-up"></div>
                  <span class="border-stop" *ngIf="trip?.length - 1 > 0">
                    <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span>
                      {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
                    <div style="padding-left: 14px;">
                      <div class="d-flex">
                        <p class="stops-title white-space-no-wrap mr-1 font">Stop 1 : </p>
                        <span class="font white-space-no-wrap"> <span class="mx-1"
                            style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[1].FlightLayoverTime) }}</span>
                          Layover, {{ trip[1].DepartureAirportName }} </span>
                      </div>
                      <div class="d-flex mt-2 position-relative" *ngIf="trip?.length-1>1">
                        <span class="line-dot"></span>
                        <p class="stops-title white-space-no-wrap mr-1 font">Stop 2 : </p>
                        <span class="font white-space-no-wrap"> <span class="mx-1"
                            style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[2].FlightLayoverTime) }}</span>
                          Layover, {{ trip[2].DepartureAirportName }} </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <small class="total-trip"><span style="color: #f00;font-weight: bold;">Total Duration : </span>
                {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
            </div>
            <div>
              <strong>{{ trip[trip?.length - 1]?.ArrivalDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[trip?.length - 1]?.ArrivalDateTime| date: 'EEE , MMM d' }}</small>
              <p>{{trip[trip?.length - 1]?.ArrivalAirport }}</p>
            </div>
          </div>
          <div class="content-button col-3" *ngIf="ii==0">
            <div class="w-100 border-bottom d-flex justify-content-between position-relative">
              <ul class="d-flex align-items-center" style="margin: 10px 4px;">
                <ng-container *ngFor="let brand of itinerary.flights[0][0].BrandFeatures | keyvalue">
                  <li *ngIf="brand.key != 'BG'" class="position-relative"
                    (mouseover)="arrow_brands.classList.add('shoow');arrow_brands.classList.remove('hidden');brands.classList.add('shoow');brands.classList.remove('hidden')"
                    (mouseout)="arrow_brands.classList.remove('shoow');arrow_brands.classList.add('hidden');brands.classList.remove('shoow');brands.classList.add('hidden')">
                    <span *ngIf="brand.key != 'BG'">
                      <img width="20" style="margin-right: 4px;" [src]="'assets/icons/brand-futures/'+brand.key+'.png'">
                    </span>
                    <span #arrow_brands class="arrow-top hidden"></span>
                  </li>
                  <div class="brands-popup hidden" #brands>
                    <span class="d-flex justify-content-between my-2 align-items-center"
                      *ngFor="let item of brand.value ;let i=index">
                      <span class="d-flex align-items-center">
                        <img width="20" style="margin-right: 4px;"
                          [src]="'assets/icons/brand-futures/'+item.commercialName+'.png'">
                        <small class="mr-auto">{{item.commercialName}}</small>
                      </span>
                      <small style="color: blue;" *ngIf="item.application=='C'">CHARGABLE</small>
                      <small style="color: green;" *ngIf="item.application=='F'">FREE</small>
                      <small style="color: red; text-transform: uppercase;" *ngIf="item.application=='D'">not
                        offered</small>
                    </span>
                  </div>
                </ng-container>
              </ul>
            </div>
            <div [class]="itinerariesFackData[0].flights.length == 1 ?  'top-50':'top-100' ">
              <p>{{(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount)| currency}}</p>
              <button class="btn-book" style="background-color: #777 !important;" type="button" disabled="true">Book
                Now</button>

            </div>
          </div>
          <div class="content-button col-3" *ngIf="ii==1">
            <p class="text-content-button">price per person (incl. taxes & fees)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->


<div *ngIf=" itineraries.length > 0">
  <div *ngFor="let itinerary of itineraries; let i = index" class="itinerary position-relative"
    [ngClass]="{ 'first-result': i == 0 }">
    <div *ngIf="searchRequest?.offers == 'false'" class="page-offer">
      <img src="assets/icons/icons8-favorites.svg" alt="page offers">
    </div>
    <div class="row mt-4  cardHandel" >
      <div *ngFor="let trip of itinerary.flights; let ii = index" class="list">

        <div class="content col-12">
          <div class="content-img col-4">
            <div class="text-center row">
              <div class="col-6 d-flex align-items-center justify-content-center"  *ngIf="searchRequest.trip_type=='Return'&&flightResultsPage!=true">
                <div
                  (mouseover)="checkboxDialog.classList.add('checkboxDialogContentShow')"
                  (mouseout)="checkboxDialog.classList.remove('checkboxDialogContentShow')"
                  class="select-flight-checkbox" (click)="selectRoundIti(ii,itinerary)">
                  <!-- <input type="checkbox" id="selectFight" name="selectFight">
                  <label for="selectFight"></label> -->
                  <button class="btn go-to-select" type="checkbox" id="selectFight" name="selectFight"> Select <span
                    *ngIf="ii==0">Departure</span> <span *ngIf="ii==1">Return</span> </button>
                </div>
              </div>
              <div #checkboxDialog class="checkboxDialogContent">
                <p *ngIf="ii==0">choose your depart</p>
                <p *ngIf="ii==1">choose your return</p>
              </div>
              <div class="col">
                <div class="text-center" (mouseover)="popCard.classList.add('card-img-show')"
                  (mouseout)="popCard.classList.remove('card-img-show')">
                  <img class="img" src="https://c.fareportal.com/n/common/air/3x/{{trip[val]?.OperatingAirline}}.png"
                    alt="">
                  <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true"
                    style="display: block;">{{flights[i]?.legs[ii]?.schedules[0]?.airline?.name|slice:0:22}}</small>
                  <small *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false"
                    style="display: block;">{{trip[val]?.OperatingAirlineName|slice:0:22}}</small>

                </div>
                <!-- (mouseenter)="dialog.classList.add('hidden-dialog')" (mouseleave)="dialog.classList.remove('hidden-dialog')" -->
                <!-- AircraftCode -->
                <!-- <div class="d-flex justify-content-center">
                  <p *ngFor="let item of trip; let k=index"
                    (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')"
                    (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog') ">{{item.AircraftCode+","}}</p>
                </div> -->
                <!-- *ngIf="showAirCraftPop" -->
                <div style="color: white; position: absolute;" class=".dialog-airCraft" #dialogAirCraft
                  (mouseover)="dialogAirCraft.classList.add('airCraft-dialog')"
                  (mouseout)="dialogAirCraft.classList.remove('airCraft-dialog')">
                  <div class="arrow-up"></div>
                  <span class="border-stop">
                    <small *ngFor="let item of trip">
                      {{item.AircraftName}}
                    </small>
                    <!-- <small >
            {{trip[val+1]?.AircraftName}}
    </small> -->
                  </span>
                </div>
                <div class="additionalinfo">
                  <p *ngFor="let item of trip">
                    {{item.OperatingAirline+' '}}{{item.MarketingFlightNumber+'-'}}{{'Operated by'+' '+item.OperatingAirlineName}}
                  </p>
                </div>
              </div>
            </div>

            <div class="card-img" #popCard (mouseover)="popCard.classList.add('card-img-show'); getAirlineForFilght()"
              (mouseout)="popCard.classList.remove('card-img-show')">
              <div class="card-img__image-container">
                <img class="card-img__image" *ngIf="flights" src="{{flights[i]?.legs[ii]?.schedules[0]?.airline?.main_image}}" alt="">
                <img class="card-img__image" *ngIf="!flights" src="/favicon.ico" alt="">
              </div>
              <div class="card-img__content">
                  <div class="link text-center mb-2" *ngIf="flights">
                    <a
                      [routerLink]="['/Bagging-Information/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]"
                    >
                      <br/>
                      Bagging Information
                    </a>
                    <br/>
                    <a class=" Font-family" [routerLink]="['/cancellation-policy/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">
                      Cancellation Policy
                    </a>
                    <br/>

                    <a [routerLink]="['/Online-Check/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">
                      Online Check
                    </a>
                    <br/>

                    <a [routerLink]="['/FAQ/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">
                      FAQ
                    </a>
                  </div>
                  <button  class=" fao_des_btn_small" *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==true&&flights" type="button"
                  [routerLink]="['/airline-description/',flights[i]?.legs[ii]?.schedules[0]?.airline?.callsign]">More
                  Details</button>
                <button class=" fao_des_btn_small" *ngIf="get(trip[val]?.OperatingAirlineName|slice:0:20)==false" type="button"
                  [routerLink]="['/airline-description/',trip[0]?.OperatingAirlineName]">More Details</button>
              </div>
            </div>
          </div>

          <div class="content-center col-6">
            <!---[class]="review && searchRequest?.offers == 'false' ? 'col-9':'col-6'"-->
            <div>
              <strong>{{trip[0]?.DepartureDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[0]?.DepartureDateTime| date: 'EEE , MMM d' }}</small>
              <p class="PriceCusst">{{trip[0]?.DepartureAirport}}</p>
            </div>
            <div>
              <strong style="font-weight: bold;"
                *ngIf="trip[1]?.FlightLayoverTime">{{convertTime(trip[1]?.FlightLayoverTime)}}<span
                  *ngIf="trip?.length-1==2" class="mx-1">/</span> <span
                  *ngIf="trip?.length-1==2">{{convertTime(trip[2]?.FlightLayoverTime) }}</span></strong>
              <small style="display: block; margin: 6px 0;" *ngIf="trip[1]?.FlightLayoverTime">layover time</small>
              <div class="text-stop">
                <p *ngIf="trip?.length - 1 == 0">Non stop</p>
                <p *ngIf="trip?.length - 1 > 0" (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')">{{trip?.length - 1}} stop</p>
                <div class="dialog-stop" #dialog (mouseover)="dialog.classList.add('hidden-dialog')"
                  (mouseout)="dialog.classList.remove('hidden-dialog')">
                  <div class="arrow-up"></div>
                  <span class="border-stop" *ngIf="trip?.length - 1 > 0">
                    <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span>
                      {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
                    <div style="padding-left: 14px;">
                      <div class="d-flex">
                        <p class="stops-title white-space-no-wrap mr-1 font">Stop 1 : </p>
                        <span class="font white-space-no-wrap"> <span class="mx-1"
                            style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[1].FlightLayoverTime) }}</span>
                          Layover, {{ trip[1].DepartureAirportName }} </span>
                      </div>
                      <div class="d-flex mt-2 position-relative" *ngIf="trip?.length-1>1">
                        <span class="line-dot"></span>
                        <p class="stops-title white-space-no-wrap mr-1 font">Stop 2 : </p>
                        <span class="font white-space-no-wrap"> <span class="mx-1"
                            style="color:#161d4c; font-weight: 600;">{{ convertTime(trip[2].FlightLayoverTime) }}</span>
                          Layover, {{ trip[2].DepartureAirportName }} </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <small class="total-trip"><span style="color: #f00;font-weight: bold;">Total Duration : </span>
                {{convertTime(itinerary.flights[ii][1]?.FlightLayoverTime+itinerary.flights[ii][1]?.ElapsedTime+itinerary.flights[ii][0]?.ElapsedTime)}}</small>
            </div>
            <div>
              <strong>{{ trip[trip?.length - 1]?.ArrivalDateTime| date: "h:mm a" }}</strong>
              <small style="display: block;">{{trip[trip?.length - 1]?.ArrivalDateTime| date: 'EEE , MMM d' }}</small>
              <p class="PriceCusst">{{trip[trip?.length - 1]?.ArrivalAirport }}</p>
            </div>

          </div>

          <!-- <div class="d-flex col-12" >
              <div class="col-3"  *ngFor="let brand of itinerary.flights[0][0].BrandFeatures | keyvalue">
                <div *ngIf="brand.key != 'BG'"
                (mouseover)="arrow_brands.classList.add('shoow');arrow_brands.classList.remove('hidden');brands.classList.add('shoow');brands.classList.remove('hidden')"
                (mouseout)="arrow_brands.classList.remove('shoow');arrow_brands.classList.add('hidden');brands.classList.remove('shoow');brands.classList.add('hidden')">
                <ng-container *ngIf="brand.key != 'BG'">
                  <img width="30"  [src]="'assets/icons/brand-futures/'+brand.key+'.png'">
                </ng-container>
                <span #arrow_brands class="arrow-top hidden"></span>
              </div>
              <div class="brands-popup hidden" #brands >
                <span class="d-flex justify-content-between my-2 align-items-center" *ngFor="let item of brand.value ;let i=index">
                  <span class="d-flex align-items-center">
                    <img width="20" style="margin-right: 4px;" [src]="'assets/icons/brand-futures/'+item.commercialName+'.png'">
                    <small class="mr-auto">{{item.commercialName}}</small>
                  </span>
                  <small style="color: blue;" *ngIf="item.application=='C'">CHARGABLE</small>
                  <small style="color: green;" *ngIf="item.application=='F'">FREE</small>
                  <small style="color: red; text-transform: uppercase;" *ngIf="item.application=='D'">not offered</small>
                </span>
              </div>
            </div>
            </div> -->
          <div class="content-button col-2 p-0" *ngIf="ii==0">

            <div [class]="itineraries[0].flights.length == 1 ?  'top-520':'top-100' ">
              <p [ngClass]="(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount==cheapestFlights)?'cheapest-price':'not-cheapest-price'"
                *ngIf="searchRequest?.offers != 'false'">
                {{(itinerary?.BaseFare?.Amount + itinerary?.taxfees?.Amount)| currency}}</p>

            </div>
              <p class="text-content-button Font-Family" *ngIf=" searchRequest?.offers != 'false'">price per person (incl.
                taxes & fees)</p>
              <p class="text-content-button Font-Family" *ngIf=" searchRequest?.offers == 'false'">follow these easy steps to
                get a special deal</p>
                <!-- *ngIf="ii==1" -->
                <div class="content-button  p-0 " >
                  <button class="fao_mobile_btn_Sq_small"  *ngIf="toggleDetails|| itineraries && itinerary.TagID" type="button" (click)="goToReviewFlight(itinerary.TagID)">Book
                    Now</button><br>
                    <!-- (click)="ShareFlightDetails(itinerary.TagID);" [routerLink]="['/flight-details/',itinerary.TagID,tripType]" -->
                    <div class="d-flex justify-content-center w-100 my-2" *ngIf="shareValue!=1 &&itinerary.TagID"   (click)="ShareFlightDetails(itinerary.TagID );"  ><img  class="mb-1 mx-1" src="../../../assets/images/arrow/sharing-bg.png" width="30px"> <span class="title_meduim "> Share</span> </div>
    
              </div>
          </div>
          

         

        </div>

      </div>

      <div class="btn-flight-details"
        (click)="toggle.classList.contains('hide') ? toggle.classList.toggle('show') : toggle.classList.toggle('hide');iconToggle.classList.toggle('rotate')"
        style="cursor: pointer;">
        <button type="button">
          <div class="d-flex justify-content-center">
            <span style="color: #152B79;font-weight: 600;"> flight details </span>
            <div class="marketing">
              <div class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[0];">
                <span>{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{'
                  '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
              </div>
              <div class="d-flex justify-content-between" *ngFor="let trip of itinerary.flights[1];">
                <span>{{' '+' '+trip.DepartureAirport}}-{{trip.ArrivalAirport}}{{'
                  '+trip.MarketingAirline}}/{{trip.OperatingAirline}}{{' '+trip.MarketingFlightNumber}}</span>
              </div>
            </div>
          </div>
        </button>
        <img #iconToggle src="../../../assets/images/flights/arrow-down.svg" alt="icon">
      </div>
      <div #toggle class="p-0 mx-auto" style="width: 98.5%;" [class]="toggleDetails ?  'hide': ''">
        <app-flight-details-drop-down [reviewFlight]="reviewFlight" [sabre]="sabre" [itinerary]="itinerary"
          [origin]="origin" [destination]="destination">
        </app-flight-details-drop-down>
      </div>
    </div>
  </div>
</div>
<!-- <app-itinerary-select-round *ngIf="selectRound" [selectedIndex]="selectedIndex"
 (selectedTicket)="getSelectedTicket($event)"
 (selectRound)="BactToIti()" [selectedItineraryTagId]="selectedItineraryTagId"
[toggleDetails]="true" [skeletons]="3" [alternativeDatesResponse]="alternativeDatesResponse"
                         [resultsArrived]="resultsArrived" [loading]="loading" [itineraries]="itineraries"
                         [flights]="flights"
                         [origin]="origin" [destination]="destination" [page]="page" [cheapestFlights]="lowestPrice"
                          ></app-itinerary-select-round> -->
<!-- (select)="select($event)" -->
