import { Component, Inject, Input, OnInit } from "@angular/core";
@Component({
  selector: "app-pnr-header-in-dashboard",
  templateUrl: "./pnr-header-in-dashboard.component.html",
  styleUrls: ["./pnr-header-in-dashboard.component.css"],
})
export class PnrHeaderInDashboardComponent implements OnInit {
  @Input() item: any;
  user: any;
  constructor() { }
  ngOnInit(): void {
    this.user = this.item.family_members[0].family_member
  }
  handelName(name) {
    var nameUpdata= name != null ? name : ` `;
    return nameUpdata;
  }

}
