import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baggage-fees',
  templateUrl: './baggage-fees.component.html',
  styleUrls: ['./baggage-fees.component.css']
})
export class BaggageFeesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
