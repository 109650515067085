import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-flight-results-search',
  templateUrl: './flight-results-search.component.html',
  styleUrls: ['./flight-results-search.component.scss']
})

export class FlightResultsSearchComponent implements OnInit ,OnChanges{
 searchRequest: any;
  hideSearch = false;
  @Output() applyModifiedSearchEvent = new EventEmitter();
  @Output() modifiedSearchRequestEvent = new EventEmitter();
  constructor(
    private route: ActivatedRoute,
  ) {
    this.dataHandler();
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    
  }

  applyModificationEvent(searchRequest): void {
    this.searchRequest = searchRequest;
    
    this.modifiedSearchRequestEvent.emit(this.searchRequest);
  }
  dataHandler(): void {
    this.searchRequest = this.route.snapshot.queryParams;
  }

}
