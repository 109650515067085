<!-- <div class="shadow-box row align-items-center justify-content-around">
  <i class="fas fa-chevron-down openDetailsBtn" *ngIf="!openDetails" ></i>
  <i class="fas fa-chevron-up openDetailsBtn" *ngIf="openDetails" ></i>
  <div class="col-md-8 flex-column mb-4 line-height-2m">
    <ng-container>
      <div class="d-flex">
        <div>
          <span class="trip-info">{{searchRequest.fullOrigin}}</span>
        </div>
        <div>
          <i  class="fas fa-arrow-right orang d-md-inline d-block"></i>
        </div>
        <div>
          <span class="trip-info">{{searchRequest.fullDestination}}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="searchRequest.trip_type =='Return'">
      <div class="d-flex">
        <div>
          <span class="trip-info">{{searchRequest.fullDestination}}</span>
        </div>
        <div>
          <i  class="fas fa-arrow-left d-md-inline d-block orang"></i>
        </div>
        <div>
          <span class="trip-info">{{searchRequest.fullOrigin}}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-4 padding-left-80 align-items-center line-height-2m  justify-content-around date-details">
    <div class=" align-items-center mr-3 d-flex">
      <span class="orang">Departure : &nbsp;</span>
      <div class="item">
        <img alt="calendar" src="assets/images/icons/calendar.svg" title="Departure Date">
        <span class="trip-info">{{searchRequest.departure_date | date: 'EEE, d MMM yyyy'}}</span>
      </div>
    </div>
    <div class=" align-items-center  d-flex">
      <span class="orang">Return : &nbsp;</span>
      <div class="item">
        <img alt="calendar" title="Return Date" src="assets/images/icons/calendar.svg">
        <span class="trip-info">{{searchRequest.return_date | date: 'EEE, d MMM yyyy'}}</span>
      </div>
    </div>
    <div class=" align-items-center d-flex">
      <span class="orang">Class : &nbsp;</span>
      <span class="trip-info">{{searchRequest.class}}</span>
    </div>
    <button (click)="toggleShow()" type="checkbox"  class="btn btn-danger m-2">More Details</button>
  </div> -->

              <!-- <span (click)="xx()">{{searchRequest.return_date | date: 'EEE, d MMM yyyy'}}</span> -->
            <!-- <span>{{searchRequest.departure_date | date: 'EEE, d MMM yyyy'}}</span> -->
             <!-- <div class="d-flex col-12 my-2" *ngIf="flight.length == 1">
            <div class="col-5 text-end">
              <span class="trip-info">{{flight[0].DepartureAirport}}</span>
            </div>
            <div class="col-2 text-center">
              <span class="icon-arrow">
                <img src="assets/icons/icon8-right.png" alt="icons">
              </span>
            </div>
            <div class="col-5 text-start">
              <span class="trip-info">{{flight[0].ArrivalAirport}}</span>
            </div>
          </div> -->

<div class="shadow-box py-4">
  <div class="row">
    <div class="col-8" >
      <!-- <app-itinerary  [skeletons]="3"   
      [flights]="select_Flights" [itineraries]="itineraries"  ></app-itinerary> -->
    </div>
    <div class="col-4 " *ngIf="!itineraryNumber">
      <div class="row align-items-center w-100 d-flex">
        <div class="col-8 p-0">
          <div id="countDown">
            <h1 class="m-0">{{transform(counter)}}</h1>
          </div>
        </div>
        <div class="col-4">
          <img alt="timer" title="clock" src="./assets/images/timer.svg"/>
        </div>
      </div>
    </div>
  
    <!-- <div class="col-3 d-flex align-items-center">
      <div class="col-2 calendar">
        <img  alt="calendar" title="Return Date" src="assets/images/icons/calendar.svg">
      </div>
      <div class="col-10">
        <div class="d-flex">
          <span class="title-date">Departure  &nbsp;</span>
          <div class="item">
            <span *ngIf="itineraries" style="display: block;">{{itineraries[0].flights[0][0].DepartureDateTime | date: 'EEE, d MMM yyyy' }}</span>
            <ngx-skeleton-loader *ngIf="!itineraries" count="1" [theme]="{height:'15'}"></ngx-skeleton-loader>

          </div>
        </div>
        <div class="d-flex">
          <span class="title-date">Return  &nbsp;</span>
          <div class="item">
            <ngx-skeleton-loader *ngIf="!itineraries" count="1" [theme]="{height:'15'}"></ngx-skeleton-loader>
            <span *ngIf="itineraries" style="display: block;">{{itineraries[0].flights[itineraries[0].flights.length - 1][0].ArrivalDateTime | date: 'EEE, d MMM yyyy' }}</span>
          </div>
        </div>
      </div>
    </div> -->

    <div class="content-center col-7 row justify-content-center border-left-right d-flex align-items-center">
      <!-- <ng-container *ngIf="itineraries">
        <ng-container *ngFor="let flight of itineraries[0].flights;index as i">
         
          <ng-container *ngFor="let item of flight;index as i">
            <div class="d-flex col-5 my-2">
              <div class="col-5 text-end">
                <span class="trip-info">{{flight[i].DepartureAirport}}</span>
              </div>
              <div class="col-2 text-center">
                <span class="icon-arrow">
                  <img src="assets/icons/icon8-right.png" alt="icons">
                </span>
              </div>
              <div class="col-5 text-start">
                <span class="trip-info">{{flight[i].ArrivalAirport}}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container> -->
    </div>
    
  </div>
</div>
  <div *ngIf="isShown" class="row container-fluid" id="divshow">
    <app-itinerary></app-itinerary>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate quisquam obcaecati, quas dicta nobis animi
      distinctio voluptatem autem aut incidunt iste numquam aperiam laudantium molestiae alias fugiat reiciendis.
      Veniam, sequi!
    </div>
  </div>
