<div class="container-fluid position-relative">
  <div class="col-12">
    <div class="row justify-content-between">
      <div class="video-section">
        <ng-container *ngIf="autoPlay">
          <video style="height: 555px" autoplay="" muted="" playsinline="" loop="" onloadedmetadata="this.muted = true"
            class="img-fluid" title="EGYPT" controls
            src="https://player.vimeo.com/external/581824874.hd.mp4?s=3c7460ba72156ac4d818a2e496458d8551901406&profile_id=175">
          </video>
        </ng-container>
        <ng-container *ngIf="!autoPlay">
          <video style="height: 555px" muted="" playsinline="" loop="" poster="./assets/webp/QatarBanner-1.webp"
            onloadedmetadata="this.muted = true" class="img-fluid" title="EGYPT"
            src="https://player.vimeo.com/external/581824874.hd.mp4?s=3c7460ba72156ac4d818a2e496458d8551901406&profile_id=175">
          </video>
        </ng-container>

        <ng-container *ngIf="showEngine">
          <app-engine-static [origin]="origin" [destination]="destination" [tripType]="tripType" [stops]="stops"
            [airline]="airline">
          </app-engine-static>
        </ng-container>
        <button class="btn" *ngIf="!showEngine" (click)="showEngine = !showEngine">BOOK NOW</button>
      </div>
      <div class="details-section">
        <h4>SPECIAL OFFER</h4>
        <p>
          coming soon
          <br>
          <img alt="image" style="width: 300px;height: 300px" title="SPECIAL OFFER" src="./assets/webp/qatar.webp">
        </p>
      </div>
    </div>
  </div>
</div>
