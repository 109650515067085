import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, EventEmitter, OnInit, Output, Input, AfterViewInit, Renderer2 } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-check-hotel-date',
  templateUrl: './ceck-hotel-date.component.html',
  styleUrls: ['./ceck-hotel-date.component.scss']
})
export class CheckHotelDateComponent implements OnInit {
  startDate: any;
  endDate: any;
  @Output() setDate = new EventEmitter()
  @Input() departureDate: Date;
  @Input() returnDate: Date;
  @Input() singleDate = false;
  @Input() title = 'Chose Date';
  viewDate: any;
  tomorrow: any;
  @Input() prices: { [key: string]: number } = {
    '2024-05-20': 100,
    '05/21/2024': 120,
    '2024-05-25': 150,
  }; // Add prices input
  constructor(@Inject(DOCUMENT) private document: Document, private datePipe: DatePipe,private renderer: Renderer2) {
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }


  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve();
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      this.renderer.appendChild(document.body, script);
    });
  }

  initializeDateRangePicker() {
    // Use jQuery to initialize the Date Range Picker
    const input = document.querySelector('input[name="daterange"]');
    $(input).daterangepicker({
      opens: 'left'
    }, function(start, end, label) {
      console.log('A new date selection was made: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    });
  }
  ngOnInit() {
setTimeout(()=>{ this.loadScript('https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js').then(() => {
  this.initializeDateRangePicker();
});},3000)

    $(".checkin-hotel").daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: false,
      startDate:this.startDate?this.startDate: this.departureDate ? this.datePipe.transform(this.departureDate, "MM/dd/YYYY") : this.tomorrow,
      endDate:this.endDate?this.endDate: this.returnDate ? this.datePipe.transform(this.returnDate, "MM/dd/YYYY") : this.tomorrow,
      locale: {
        cancelLabel: 'close',
        format: 'MM/DD/YYYY'
      },
      minDate: this.tomorrow,
      isInvalidDate: (date) => {
        // Mark dates as invalid based on some condition
        return false;
      },
      isCustomDate: (date) => {
        const formattedDate = this.datePipe.transform(date, "MM/dd/yyyy");
        if (this.prices[formattedDate]) {
          return "has-price";
        }
        return "";
      }
    });
    $('.checkin-hotel').on('show.daterangepicker', (ev, picker) => {
      const calendarContainer = picker.container;
      calendarContainer.find('.has-price').each((index, element) => {
        const dateText = $(element).text().trim();
        const formattedDate = dateText ? this.datePipe.transform(new Date(dateText), "MM/dd/yyyy") : null;
        if (formattedDate && this.prices[formattedDate]) {
          $(element).append(`<span class="price-badge">${this.prices[formattedDate]}</span>`);
        }
      });
    });

    $('.checkin-hotel').on('apply.daterangepicker', (ev, picker) => {
      this.startDate = picker.startDate;
      this.endDate = picker.endDate;
      this.viewDate = this.startDate
      this.setDate.emit(picker)
    });

    $('.checkin-hotel').on('cancel.daterangepicker', function (ev, picker) {
      $(this).val('');
    });

  }
}

