<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center">Help Center - {{topicTitle}}</h1>
            <div class="frm">
                <input type="text" placeholder="How can we help you?" [(ngModel)]="searchText"
                    (keyup.enter)="onSearch(search.value)" #search />
                <button (click)="onSearch(search.value)"><img title="Help Center" alt="Search"  src="./assets/images/help-center/search.svg"
                        height="30" /></button>
            </div>
        </div>
    </div>

    <div class="help-center-body">

        <!-- start breadcrumb -->
        <div class="container">
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/support']">Help Center</a></li>
                    <li class="breadcrumb-item active">{{topicTitle}}</li>
                </ol>
            </nav>
        </div>
        <!-- end breadcrumb -->

        <!-- start skeleton preloader -->
        <div class="container" *ngIf="loading">
            <div class=" search-results">
                <div class="search-results-header">
                    <h4>
                        <ngx-skeleton-loader count="1" [theme]="{width:'200px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </h4>
                    <p>
                        <ngx-skeleton-loader count="1" [theme]="{width:'150px', height:'10px'}"></ngx-skeleton-loader>
                    </p>
                </div>

                <div class="search-results-body">
                    <div class="item" *ngFor="let result of createRange(2)">
                        <div>
                            <ngx-skeleton-loader count="1"
                                [theme]="{width:'200px', 'margin-bottom':'0px', height:'15px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <ngx-skeleton-loader count="1" [theme]="{width:'300px', 'margin-bottom':'0px', height:'15px'}">
                        </ngx-skeleton-loader>
                        <!-- <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end skeleton preloader -->

        <div class="row" *ngIf="!searchResultsFound && !loading && !backEndErr">
            <div class="container text text-danger no-topics">
                No Topics of your Search
                <span class="ml-sm-3 d-block d-sm-inline">
                    <a [routerLink]="['/support']">Back to Help center</a>
                </span>

            </div>
        </div>
        <div *ngIf="backEndErr">
            <app-no-result-error [homePath]="'/support'"></app-no-result-error>
        </div>

        <!-- start search-results -->
        <div class="container" *ngIf="searchResultsFound && !loading && !backEndErr">
            <div class=" search-results">
                <div class="search-results-header">
                    <h2>Search Results</h2>
                    <p>Found {{searchResults?.length}} results for {{searchText || 'this topic'}}</p>
                </div>

                <div class="search-results-body">
                    <div class="item" *ngFor="let result of searchResults"
                        (click)="getPostDetails(topicTitle,result.title,result.id)">
                        <h3 [innerHTML]="result.title_content"></h3>
                        <div [innerHTML]="result.description"></div>
                        <!-- <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul> -->
                    </div>

                    <!-- <div class="item">
                        <h3>How do I change or cancel my booking?</h3>
                        <ul>
                            <li>For all questions about changes, cancellations, and refunds – as well as all other
                                questions about bookings – you'll need to contact the company you bought travel from.
                                They'll have all the info about your booking and can </li>
                            <li>advise you. There are two ways to buy travel through FlyAllOver: </li>
                            <li>1: Buy from a partner (these are all the travel agencies, airlines, hotels and car hire
                                companies you'll see in your search results) <a href="#">see more</a></li>
                        </ul>
                    </div>

                    <div class="item">
                        <h3>How do I make a booking?</h3>
                        <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul>
                    </div>

                    <div class="item">
                        <h3>How do I change or cancel my booking?</h3>
                        <ul>
                            <li>For all questions about changes, cancellations, and refunds – as well as all other
                                questions about bookings – you'll need to contact the company you bought travel from.
                                They'll have all the info about your booking and can </li>
                            <li>advise you. There are two ways to buy travel through FlyAllOver: </li>
                            <li>1: Buy from a partner (these are all the travel agencies, airlines, hotels and car hire
                                companies you'll see in your search results) <a href="#">see more</a></li>
                        </ul>
                    </div>

                    <div class="item">
                        <h3>How do I make a booking?</h3>
                        <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul>
                    </div>

                    <div class="item">
                        <h3>How do I change or cancel my booking?</h3>
                        <ul>
                            <li>For all questions about changes, cancellations, and refunds – as well as all other
                                questions about bookings – you'll need to contact the company you bought travel from.
                                They'll have all the info about your booking and can </li>
                            <li>advise you. There are two ways to buy travel through FlyAllOver: </li>
                            <li>1: Buy from a partner (these are all the travel agencies, airlines, hotels and car hire
                                companies you'll see in your search results) <a href="#">see more</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end search-results -->

        <!-- start pagination -->
        <!-- <div class="container" *ngIf="searchResultsFound">
            <div class="ngb-pagination">

                <ngb-pagination class="d-flex justify-content-center mt-0" [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="collectionSize" (pageChange)="changePage(page)">

                </ngb-pagination>
            </div>
        </div> -->
        <!-- end pagination -->

    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<div>

    <app-footer></app-footer>
</div>
<!-- end footer -->
