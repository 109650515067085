import {Component,Input, OnInit} from '@angular/core';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.css']
})
export class NoResultComponent implements OnInit {
  @Input() novbar = true;

  constructor(
    private seoService: SEOService
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }

  seoUpdate() {

    this.seoService.updateTitle('search no results ');
    this.seoService.updateDescription('search no results  now in our website , you can nevigate another offer which this one is ended');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
