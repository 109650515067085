<div class="d-flex flex-column justify-content-center my-10" style="margin-bottom: -14px;">
  <h4 class="fao_mobile_title_large text-center ">Recommended Airlines</h4>
  <!-- RECOMMENDED AIRLINES -->
  <div style="background-color: #000000; height: 3px; width: 200px; margin: auto;"></div>
</div>
<div class="container space">
  <div class="panel active first"
       style="background-image: url('./assets/images/slider-home/New folder/neo.jpg'); margin-left: 12px;">
    <div class="item">
      <div class="overlay"></div>
      <div class="content">
        <span class="engine Neos Airlines alitalia" *ngIf="NOS">
          <!-- <app-engine-static>
          </app-engine-static> -->
        </span>
        <h3 class="mt-2 Font-Family">Neos</h3>
        <p class="Font-Family">Neos is an excellent choice for travelers looking for an upscale experience from Italy and beyond.</p>
        <p class="Font-Family">FlyAllOver - Cheap Flight Tickets</p>
        <button class="btn Font-Family" mat-button (click)="openDialog({name:'Neos' , code:'NOS'}); ">BOOK NOW</button>
      </div>
    </div>
  </div>
  <div class="panel" style="background-image: url('./assets/images/slider-home/New folder/jetblue.png')">
    <div class="item">
      <div class="overlay"></div>
      <div class="content">
        <span class="engine  jetBlue" *ngIf="getBlue">
          <!-- <app-engine-static>
          </app-engine-static> -->
        </span>
        <h3 class="mt-2 Font-Family">JETBLUE</h3>
        <p class="Font-Family"> OUR FLOCK FLIES nonstop. Get Away without going out of your way</p>
        <p class="Font-Family">FlyAllOver - Cheap Flight Tickets</p>
        <button class="btn Font-Family" mat-button (click)="openDialog({name:'jetBlue' , code:'B6'});">BOOK NOW</button>
      </div>
    </div>
  </div>
  <div class="panel"
       style="background-image: url('./assets/images/slider-home/New folder/egyptair.png')">
    <div class="item">
      <div class="overlay"></div>
      <div class="content">
                <span *ngIf="egyptAir" class="engine  egyptair">
          <!-- <app-engine-static>
          </app-engine-static> -->
        </span>
        <h3 class="mt-2 Font-Family">EGYPTAIR</h3>
        <p class="Font-Family"> Fly to Cairo, your gateway to Africa and Far east</p>
        <p class="Font-Family">FlyAllOver - Cheap Flight Tickets</p>
        <button class="btn Font-Family" mat-button (click)="openDialog({name:'EgyptAir' , code:'MS'}) ;" >BOOK NOW</button>
      </div>
    </div>
  </div>
  <div class="panel last"
       style="background-image: url('./assets/images/slider-home/New folder/Emirates.png'); margin-right: 12px">
    <div class="item">
      <div class="overlay"></div>
      <div class="content">
                <span *ngIf="emirates" class="engine  emirates">
          <!-- <app-engine-static>
          </app-engine-static> -->
        </span>
        <h3 class="mt-2 Font-Family">FLY EMIRATES</h3>
        <p class="Font-Family"> Hello tomorrow
          Visit new world with our flight entertainment</p>
        <p class="Font-Family">FlyAllOver - Cheap Flight Tickets</p>
        <button class="btn Font-Family" mat-button (click)="openDialog({name:'Emirates' , code:'EK'}) ;" >BOOK NOW</button>
      </div>
    </div>
  </div>
</div>
