<div class="my-bookings-body">
    <h2>My Bookings</h2>

    <div class="tabs-windows">
        <div class="tabs">
            <div class="tab tab1" [ngClass]="{'active': selectedItem == 'tab1'}" (click)="listClick($event, 'tab1')">
                UPCOMING</div>
            <div class="tab tab2" [ngClass]="{'active': selectedItem == 'tab2'}" (click)="listClick($event, 'tab2')">
                COMPLETED</div>
        </div>
        <div class="windows">
            <div class="window" *ngIf="selectedItem == 'tab1'">
                <div class="tabel-section">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Ticket Serial</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <!-- <th scope="col">Date</th> -->
                                <th scope="col">Departure</th>
                                <th scope="col">Arrival</th>
                                <th scope="col">Flight Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let book of upcoming; let i = index">
                                <td>{{book.ticket_serial}}</td>
                                <td>{{book.origin}}</td>
                                <td>{{book.destination}}</td>
                                <!-- <td>{{book.created_at}}</td> -->
                                <td>{{book.departure_date | date: 'MMM d, y, h:mm a'}}</td>
                                <td>{{book.return_date| date: 'MMM d, y, h:mm a'}}</td>
                                <td><button (click)="ticketView.emit({ticketIndex:i, ticketCategory: 'upcomingBookings'})">View</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="window" *ngIf="selectedItem == 'tab2'">
                <div class="tabel-section">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Ticket Serial</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <!-- <th scope="col">Date</th> -->
                                <th scope="col">Departure</th>
                                <th scope="col">Arrival</th>
                                <th scope="col">Flight Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let book of complete; let i = index">
                                <td>{{book.ticket_serial}}</td>
                                <td>{{book.origin}}</td>
                                <td>{{book.destination}}</td>
                                <!-- <td>{{book.created_at}}</td> -->
                                <td>{{book.departure_date | date: 'MMM d, y, h:mm a'}}</td>
                                <td>{{book.return_date | date: 'MMM d, y, h:mm a'}}</td>
                                <td><button (click)="ticketView.emit({ticketIndex: i, ticketCategory: 'completedBookings'})">View</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>