import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { ApisService } from "../../services/apis.service";
import { EnginePointComponent } from "../../components/engine-point/engine-point.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SEOService } from "../../services/seo.service";
import { DatePipe, isPlatformBrowser } from "@angular/common";
import { AppComponent } from "../../app.component";
import { HelpCenterServices } from "../../services/help-center.service";
import { LoadingFlightResultsDialogComponent } from "../../components/loading-flight-results-dialog/loading-flight-results-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { OwlOptions } from "ngx-owl-carousel-o";
import {Clipboard} from "@angular/cdk/clipboard";

import * as url from "url";
import { LocalStorageService } from "../../services/webApis/local-storage.service";

@Component({
  selector: "app-blog-description",
  templateUrl: "./blog-description.component.html",
  styleUrls: ["./blog-description.component.css"],
})
export class BlogDescriptionComponent implements OnInit {
  data: any = [];
  popItemData: any;
  cityName: any;
  blogName: any;
  airportName: any;
  imageUrl: any;
  about: any;
  latitude: any;
  longitude: any;
  metaTitle: any;
  metaImage: any;
  metaDescription: string;
  address: string;
  pageNumber: any;
  slug: any;
  count: any;
  related: any = [];
  thingsallData: any = [];
  relatedThingsData: any = [];
  dialog: any;
  images: any = [];
  dialogLoadingRef: any;
  display: any = true;
  blogslug: any;
  blogId: number;
  airlineId: number;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: [
      '<i class="fas fa-arrow-left"></i>',
      '<i class="fas fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
  };

  reviewData: any = [];
  showRev = false;

copied: boolean= false;
  myUrl: string = this.router.url;

  constructor(
    private apis: ApisService,
    public dialogComp: MatDialog,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private router: Router,
    private clipboard: Clipboard,
    private helpCenterApis: HelpCenterServices,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    this.blogslug = this.activatedRoute.snapshot.paramMap.get("slug");
    this.getBlogDetails();
    this.getblogs();
    this.getthings();
  }

  seoUpdate(
    title = null,
    description = null,
    image = null,
    url = "https://api.flyallover.com/storage/"
  ) {
    
    this.seoService.updateUrl();
    this.seoService.updateImage(
      AppComponent.hostUrl + "/assets/images/blog/header.png"
    );

    if (title != null) {
      // console.log('title',title);
      
      this.seoService.updateTitle(title);
      // console.log('meta title',title)
    }
    if (description != null) {
      this.seoService.updateDescription(description);
      // console.log('title',description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
      // console.log('title',image);
    }
  }

  getblogs() {
    if (typeof document !== "undefined") {
      this.pageNumber = localStorage.getItem("pageNumber");
    }
    this.apis.paginationblogs(this.pageNumber).subscribe(
      (data: any) => {
        this.data = data.data.data;
        for (let i = 0; i < this.data.length; i++) {
          this.count = this.data[i].slug;
          if (this.count == this.blogslug) {
            this.related.push(this.data[i]);
            this.blogName = this.related.title;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBlogDetails() {
    this.apis.getBlogDetails(this.blogslug).subscribe(
      (data: any) => {
        this.popItemData = data.data;
        this.images.length = 0;
        this.blogId = this.popItemData.id;
        if (this.data) {
          // console.log(this.popItemData);
          
          this.metaTitle = this.popItemData.meta_title;
          this.metaDescription = this.popItemData.meta_description;
          this.metaImage = this.popItemData.image;
          this.images.push(this.popItemData.image);
        }
        this.getReviews();
        this.dialogLoadingRef?.close();
        this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // openLoadingDialog() {
  //   this.dialogLoadingRef = this.dialogComp.open(LoadingFlightResultsDialogComponent, {
  //     maxHeight: '70vh',
  //     width: '50vw',
  //     maxWidth: '100vw',
  //     data: {message: 'Blog'}
  //   });
  //   this.dialogLoadingRef.afterClosed().subscribe(result => {
  //     if (typeof document !== 'undefined') {
  //       window.scrollTo({top: 0, behavior: 'smooth'});
  //     }
  //   });
  // }
  deleteSpace(text) {
    return text.trim();
  }

  refresh(slug) {
    // @ts-ignore
    if (typeof document !== "undefined") {
      window.scrollTo(0, 0);
    }
    this.slug = slug;
    this.blogslug = slug;
    this.getBlogDetails();
    this.getblogs();
    this.getBlogDetails();
    this.getthings();
    // this.getOffers();
  }

  getthings() {
    this.apis.getThingsToDo().subscribe((data: any) => {
      this.thingsallData = data.data.data;
      this.relatedThingsData = this.thingsallData;
      for (let i = 0; i < this.thingsallData.length; i++) {
        if (this.popItemData?.country?.name) {
          this.count = this.thingsallData[i].country.name;
          // tslint:disable-next-line:triple-equals
          if (this.count == this.popItemData?.country.name) {
            this.relatedThingsData[i] = this.thingsallData[i];
          }
        }
      }
    });
  }

  openDialog() {
    this.dialog.open(EnginePointComponent, {
      panelClass: "custom-dialog",
    });
  }

  getReviews() {
    this.apis.getBlogReviews(this.blogId).subscribe(
      (data: any) => {
        this.reviewData = data.data;

      },
      (err) => {
        console.log(err);
      });
  }

  setReview(event :any){
    this.apis.addBlogRviews(event).subscribe((data:any)=>{
      this.data=data.data;
      this.getReviews();
    })
  }
  showReviews() {
    this.showRev = !this.showRev;
  }

  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied=!this.copied;
    setTimeout(()=>{
      this.copied = false;
    },1500)
  }

  fb() {
    let url = "www.google.com";

    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "flyallover.com" +
        this.router.url,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }
}
