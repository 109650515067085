import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import { SEOService } from '../../services/seo.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { Request } from 'express';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(REQUEST) private request: Request
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      if (this.request.res) {
        this.request.res.status(404);
      }
    }
  }

  seoUpdate() {

    this.seoService.updateTitle('page not found');
    this.seoService.updateDescription('page not found ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
