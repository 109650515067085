import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApisService } from 'src/app/services/apis.service';
import { PassengersNumberComponent } from '../passengers-number/passengers-number.component';
import { DatePipe, DOCUMENT } from '@angular/common';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @Output() searchDataReady = new EventEmitter();
  @Input() roundSpinner: boolean = false;

  minFLights: number = 2; // min flight number for openJaw (used in template)
  hideTripType: boolean = false;

  // dates
  today: Date = new Date();
  initialDate: Date = new Date();
  openDate: Date = new Date();
  tripType: string = 'Return';
  activeClass: any = 'Economy';
  passengerData: any;
  activeTravellers: any = 1;
  travellersText = 'Travellers';
  bindClass: any;
  searchForm = this.fb.group({
    /*
      * this form has 3 views in the template one for every trip type
      * (the 3 can't be present together since we choose the trip type using radio buttons)
    */
    // some controls hold default values if the user didn't change them
    /*
    * flightsData array is used mainly in openJaw to be able to add flights
    * there is no need to dynamically create return_date field since it is not used in openJaw trip type
    * but it is added to the array to keep the template view consistent
    * (since departure_date and return_date are in the same col)
    */

    flightData: this.fb.array([
      this.fb.group({
        origin: [''],
        destination: [''],
        departure_date: [''],
        return_date: [''],
      })
    ]),
    travellers: [1],
    adult: [1],
    youth: [0],
    senior: [0],
    child: [0],
    lap: [0],
    seat: [0],
    class: ['Economy'],
    trip_type: [this.tripType],
  });

  // search data
  roundData: any;
  oneWayData: any;
  openJawData: any;


  // error variables
  submitErrorReturn: boolean = false;
  submitErrorOneWay: boolean = false;
  submitErrorOpenJaw: boolean = false;
  errorReturn: string;
  errorOneWay: string;
  errorOpenJaw: string;

  // this component is viewed to fetch passenger data
  @ViewChild(PassengersNumberComponent)
  private passengerComponent: PassengersNumberComponent;

  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    private changeDetection: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    // search dates starts from the day after tomorrow (two days after today)
    this.initialDate.setDate(this.today.getDate() + 2);
    this.setTravellersAndClassesInput();
    this.addFlight();
  }

  search = (text$: Observable<string>) => {


    return text$.pipe(
      // emits a value only after 300ms without any change in text$
      // https://rxjs-dev.firebaseapp.com/api/operators/debounceTime
      // debounceTime(100),
      // Only emits when the current value is different than the last.
      // distinctUntilChanged(),
      switchMap(term => {
        if (term.length >= 1) {
          return this.apis.geoAutocomplete_v2(term, 'AIR', null, null).pipe(
            tap(term => {
              if (term[0] === ' ') {
                setTimeout(() => {
                  const button = this.document.querySelector('[id^=ngb-typeahead] .dropdown-item:first-of-type') as HTMLButtonElement;
                  button.innerHTML = `<span class="text-danger"> <i class="fas fa-exclamation-circle mr-1"></i> No results Found<span>`;
                  button.classList.add('no-results');
                  button.disabled = true;
                }, 0);
              }
            }),
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  };

  // this to update passenger data when any change to the number
  // of passenger happens in passenger-number component
  updatePassengerData() {
    this.passengerData = this.passengerComponent.fetchData();
    this.searchForm.patchValue({
      travellers: this.passengerData.travellers,
      adult: this.passengerData.adult,
      youth: this.passengerData.youth,
      senior: this.passengerData.senior,
      child: this.passengerData.child,
      lap: this.passengerData.lap,
      seat: this.passengerData.seat,
    });
  }

  // this to update searchForm when moving from home page to flight-results
  // newSearchData comes most of the time from this.localStorage
  updateSearchData(newSearchData) {
    if (this.flightData.length < newSearchData.flightData.length) {
      // loop until we reach the result of newSearchData.flightData.length - this.minFLights
      for (let i = 0; i < (newSearchData.flightData.length - this.minFLights); i++) {
        this.addFlight();
      }
    }
    for (let i = 0; i < (this.searchForm.controls.flightData as FormArray).length; i++) {
      if (newSearchData.flightData[i]) {
        (this.searchForm.controls.flightData as FormArray).controls[i].patchValue({
          origin: newSearchData.flightData[i].origin,
          destination: newSearchData.flightData[i].destination,
          departure_date: newSearchData.flightData[i].departure_date,
          return_date: newSearchData.flightData[i].return_date
        });
      }
    }
    // update trip type in form and in component
    this.searchForm.controls.trip_type.setValue(newSearchData.trip_type);
    this.tripType = newSearchData.trip_type;
    // to update travellers data that are coming from the new search
    // passenger data has its own component soo it must be updated manually from the parent
    this.passengerData = {
      travellers: newSearchData.travellers,
      adult: newSearchData.adult,
      youth: newSearchData.youth,
      senior: newSearchData.senior,
      child: newSearchData.child,
      lap: newSearchData.lap,
      seat: newSearchData.seat,
    };
    this.searchForm.patchValue({
      travellers: newSearchData.travellers,
      adult: newSearchData.adult,
      youth: newSearchData.youth,
      senior: newSearchData.senior,
      child: newSearchData.child,
      lap: newSearchData.lap,
      seat: newSearchData.seat,
    });
    this.changeDetection.detectChanges();
  }

  /*
  * submitting is done in this component through emitting an event to the parent as an output (searchDataReady)
  * to notify it that the data is ready, and from the parent we call callSearchAPi
  */
  submitRoundTrip() {
    this.enableCitiesControls();
    this.roundData = this.searchForm.value;
    // need only the first 3 chars in origin and destination
    this.roundData.origin = this.roundData.flightData[0].origin?.substring(0, 3);
    this.roundData.destination = this.roundData.flightData[0].destination?.substring(0, 3);
    // formate dates to be 05-30-2020
    this.roundData.departure_date = this.datePipe.transform(this.roundData.flightData[0].departure_date, 'yyyy-MM-dd');
    this.roundData.return_date = this.datePipe.transform(this.roundData.flightData[0].return_date, 'yyyy-MM-dd');
    this.roundData.trip_type = 'Return';

    // checking the input using if statement not using formGroup validation
    // (since required fields are different for every trip type)
    if (
      this.roundData.flightData[0].origin &&
      this.roundData.flightData[0].destination &&
      this.roundData.flightData[0].departure_date &&
      this.roundData.flightData[0].return_date &&
      this.roundData.flightData[0].travellers !== 0
    ) {
      this.roundSpinner = true;
      this.submitErrorReturn = false;
      this.localStorage.setItem('oldSearch', JSON.stringify(this.roundData));
      this.searchDataReady.emit(this.roundData);

    } else {
      this.roundSpinner = false;
      this.submitErrorReturn = true;
      this.errorReturn = 'City or Airport, Date of Departing, and Returning are required';
    }
  }

  submitOneWay() {
    this.enableCitiesControls();
    this.oneWayData = this.searchForm.value;
    this.oneWayData.trip_type = 'OneWay';
    // need only the first 3 chars in origin and destination
    this.oneWayData.origin = this.oneWayData.flightData[0].origin?.substring(0, 3);
    this.oneWayData.destination = this.oneWayData.flightData[0].destination?.substring(0, 3);
    // formate dates to be 05-30-2020
    this.oneWayData.departure_date = this.datePipe.transform(this.oneWayData.flightData[0].departure_date, 'yyyy-MM-dd');

    if (
      this.oneWayData.flightData[0].origin &&
      this.oneWayData.flightData[0].destination &&
      this.oneWayData.flightData[0].departure_date &&
      this.oneWayData.flightData[0].travellers !== 0
    ) {
      this.roundSpinner = true;
      this.submitErrorOneWay = false;
      this.localStorage.setItem('oldSearch', JSON.stringify(this.oneWayData));
      this.searchDataReady.emit(this.oneWayData);
    } else {
      this.roundSpinner = false;
      this.submitErrorOneWay = true;
      this.errorOneWay = 'City or Airport, Date of Departing are required';
    }
  }

  submitOpenJaw() {
    this.enableCitiesControls();
    // getting the data that is repeated for each flight (flightData formArray)
    let origins: string | string[] = [];
    let destinations: string | string[] = [];
    let departure_dates: string | string[] = [];

    for (let i = 0; i < this.flightData.length; i++) {
      // check that the user didn't leave any field empty
      if (this.flightData.value[i].origin !== '') {
        origins.push(this.flightData.value[i].origin.substring(0, 3));
      }
      if (this.flightData.value[i].destination !== '') {
        destinations.push(this.flightData.value[i].destination.substring(0, 3));
      }
      if (this.flightData.value[i].departure_date !== '') {
        departure_dates.push(this.datePipe.transform(this.flightData.value[i].departure_date, 'yyyy-MM-dd'));
      }
    }
    if (
      origins.length === this.flightData.value.length &&
      destinations.length === this.flightData.value.length &&
      departure_dates.length === this.flightData.value.length
    ) {
      // the user filled all fields of flight data
      origins = origins.join(',');
      destinations = destinations.join(',');
      departure_dates = departure_dates.join(',');

      // building the data that is going to be sent to the API
      this.openJawData = {
        flightData: this.searchForm.value.flightData,
        trip_type: 'OpenJaw',
        origin: origins,
        destination: destinations,
        departure_date: departure_dates,
        travellers: this.searchForm.value.travellers,
        adult: this.searchForm.value.adult,
        youth: this.searchForm.value.youth,
        senior: this.searchForm.value.senior,
        child: this.searchForm.value.child,
        lap: this.searchForm.value.lap,
        seat: this.searchForm.value.seat,
        class: this.searchForm.value.class,
      };
      this.roundSpinner = true;
      this.submitErrorOpenJaw = false;
      this.localStorage.setItem('oldSearch', JSON.stringify(this.openJawData));
      this.searchDataReady.emit(this.openJawData);

    } else {
      // the user didn't fill some fields
      this.roundSpinner = false;
      this.submitErrorOpenJaw = true;
      this.errorOpenJaw = 'City or Airport, Date of Departing are required for all flights';
    }

  }

  // methods for openJaw
  get flightData() {
    return this.searchForm.get('flightData') as FormArray;
  }

  addFlight() {
    const flightGroup = this.fb.group({
      origin: [''],
      destination: [''],
      departure_date: [''],
      return_date: [''],
    });
    this.flightData.push(flightGroup);
  }

  removeFlight(i: number) {
    this.flightData.removeAt(i);
  }

  // closing passenger list
  closePassengerList(event) {
    const parentElement = event.target.closest('.passenger-list');
    if (!parentElement) {
      if (this.passengerComponent && this.passengerComponent.showList) {
        this.passengerComponent.closeList();
      }
    }
  }

  disableCitiesControls(disableTravellersComponent: boolean) {
    // disable controls of origin and destination in case of return, one way and first trip of openJaw
    (this.searchForm.get('flightData') as FormArray).controls[0].get('origin').disable();
    (this.searchForm.get('flightData') as FormArray).controls[0].get('destination').disable();
    // this.passengerComponent.disableList = disableTravellersComponent;
  }

  enableCitiesControls() {
    // enable controls of origin and destination in case of return, one way and first trip of openJaw
    (this.searchForm.get('flightData') as FormArray).controls[0].get('origin').enable();
    (this.searchForm.get('flightData') as FormArray).controls[0].get('destination').enable();
  }

  setClassType(type) {
    this.searchForm.value.class = type;
    this.activeClass = type;
    this.setTravellersAndClassesInput();
  }

  updateFlightPassengerData() {
    this.passengerData = this.passengerComponent.fetchData();
    this.searchForm.patchValue({
      travellers: this.passengerData.travellers,
      adult: this.passengerData.adult,
      youth: this.passengerData.youth,
      senior: this.passengerData.senior,
      child: this.passengerData.child,
      lap: this.passengerData.lap,
      seat: this.passengerData.seat,
    });
    this.activeTravellers = this.passengerData.travellers;
    this.setTravellersAndClassesInput();
  }

  setTravellersAndClassesInput() {
    if (this.activeTravellers === 1) {
      this.travellersText = 'Traveller';
    } else {
      this.travellersText = 'Travellers';
    }
    this.bindClass = this.activeTravellers + ' ' + this.travellersText + ' , ' + this.activeClass;
  }
}
