<app-navbar></app-navbar>
<!-- start support-article -->
<div class="support-article">
  <!-- start support-article-header -->
  <div class="support-article-header" *ngIf="loading">
    <ngx-skeleton-loader
      count="1"
      [theme]="{ width: '100%', height: '350px', margin: '0px' }"
    >
    </ngx-skeleton-loader>
  </div>
  <div class="support-article-header" *ngIf="!loading && data?.image">
    <img
      alt="{{ data?.image_alt || 'flyallover' }}"
      title="support-artical-image"
      [src]="data?.image"
    />
  </div>
  <!-- end support-article-header -->
  <!-- start breadcrumb -->
  <div class="container">
    <nav>
      <ol *ngIf="!loading" class="breadcrumb" [ngClass]="{ 'm-0': !data }">
        <li
          *ngFor="let breadcrumb of data?.bread_crumb"
          class="breadcrumb-item"
        >
          <a href="{{ breadcrumb?.url }}">{{ breadcrumb?.title }}</a>
        </li>
      </ol>
      <ol *ngIf="loading" class="breadcrumb">
        <li *ngFor="let breadcrumb of createRange(2)" class="breadcrumb-item">
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '90px', margin: '0px' }"
          ></ngx-skeleton-loader>
        </li>
      </ol>
    </nav>
  </div>
  <!-- end breadcrumb -->
  <!-- start support-article-body -->
  <div class="support-article-body">
    <div class="container">
      <div class="row" id="article-container-bottom">
        <!-- start support-article-sidebar -->
        <div class="support-article-sidebar col">
          <div class="sidebar" id="sidebar">
            <div class="row">
              <div
                [ngClass]="{
                  'd-none': !loading && data?.description?.headers?.length == 0
                }"
                class="col"
                id="headers"
              >
                <h6 *ngIf="data?.description?.headers?.length > 0">Content</h6>
                <div class="links">
                  <ul *ngIf="!loading">
                    <ng-container
                      *ngFor="
                        let header of data?.description?.headers | slice: 0:3
                      "
                    >
                      <li
                        class="link-item"
                        [ngClass]="{
                          'active-header': header.includes(fragment)
                        }"
                        [innerHTML]="sanitizeHtml(header)"
                      ></li>
                    </ng-container>
                    <ng-container *ngIf="moreHeaders">
                      <ng-container
                        *ngFor="
                          let header of data?.description?.headers | slice: 3
                        "
                      >
                        <li
                          class="link-item"
                          [ngClass]="{
                            'active-header': header.includes(fragment)
                          }"
                          [innerHTML]="sanitizeHtml(header)"
                        ></li>
                      </ng-container>
                    </ng-container>
                    <!-- buttons to toggle more or less headers -->
                    <ng-container
                      *ngIf="data?.description?.headers?.length > 3"
                    >
                      <h6 class="more-less" *ngIf="!moreHeaders">
                        <span (click)="moreHeaders = true">
                          See More
                          <i class="fas fa-chevron-down ml-2"></i>
                        </span>
                      </h6>
                      <h6 class="more-less" *ngIf="moreHeaders">
                        <span (click)="moreHeaders = false">
                          See Less
                          <i class="fas fa-chevron-up ml-2"></i>
                        </span>
                      </h6>
                    </ng-container>
                  </ul>
                  <!-- skeleton when loading headers of content -->
                  <ul *ngIf="loading">
                    <li *ngFor="let item of createRange(3)">
                      <ngx-skeleton-loader
                        count="1"
                        class="mt-2"
                        [theme]="{ width: '100%', 'margin-bottom': '0' }"
                      ></ngx-skeleton-loader>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col">
                <h1 class="tripPlan">Plan A Trip</h1>
                <app-multiple-search-engines
                  [hotelSearch]="undefined"
                ></app-multiple-search-engines>
              </div>
            </div>
          </div>
        </div>

        <!-- start support-article-content -->
        <div class="support-article-content col">
          <div class="post" #article id="article">
            <div class="row">
              <div
                *ngIf="!loading && data?.description?.description"
                class="col"
              >
                <div class="post-header">
                  <h1>{{ data?.title_content }}</h1>
                </div>
                <!-- WARNING:  fr-view class is added for tha transformedDescription to be styled by froala_styles (editor's css file)  -->
                <div
                  class="post-body fr-view"
                  [innerHTML]="sanitizeHtml(data?.description?.description)"
                ></div>
                <div class="post-footer">
                  <div class="logo">
                    <img
                      title="article
"
                      alt="logo"
                      src="./assets/images/things-todo/logo.png"
                      width="77"
                    />
                  </div>
                  <div class="labels">
                    <label>By. FlyAllOver</label>
                    <label>{{ data?.created_at | date: "dd MMM yyyy" }}</label>
                  </div>
                </div>
              </div>
              <!-- appears when error occurs -->
              <div
                *ngIf="!loading && !data?.description?.description"
                class="col"
              >
                <h5 class="text-center text-danger">
                  An error Occurred while trying to get the article
                </h5>
              </div>

              <!-- skeleton article content for loading  -->
              <div *ngIf="loading" class="col">
                <div class="post-header">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '50%' }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="post-body">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{ width: '30%' }"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="3"
                    [theme]="{ width: '100%' }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="post-footer">
                  <div class="logo">
                    <ngx-skeleton-loader
                      count="1"
                      appearance="circle"
                      [theme]="{
                        height: '77px',
                        width: '77px',
                        'vertical-align': 'super'
                      }"
                    >
                    </ngx-skeleton-loader>
                  </div>
                  <div class="labels">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '160px' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '160px' }"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>

              <div
                *ngIf="!loading && data?.description?.description"
                class="col social text-right"
              >
                <ul id="social">
                  <li>
                    <a (click)="close_status = false">
                      <img
                        title="share artical"
                        alt="share"
                        src="./assets/images/things-todo/share.svg"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.facebook.com/sharer/sharer.php?u={{
                        hostUrl
                      }}{{ url }}"
                      target="_blank"
                    >
                      <img
                        title="facebook image "
                        alt="facebook"
                        src="./assets/images/things-todo/facebook.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://twitter.com/intent/tweet?url={{ hostUrl }}{{
                        url
                      }}"
                      target="_blank"
                    >
                      <img
                        title="twitter image"
                        alt="twitter"
                        src="./assets/images/things-todo/twitter.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      href="https://www.linkedin.com/shareArticle?mini=true&url={{
                        hostUrl
                      }}{{ url }}&title={{ data?.title }}"
                      target="_blank"
                    >
                      <img
                        title="linkedin image"
                        alt="linkedin"
                        src="./assets/images/things-todo/linkedin.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <app-share-popup
                [close_status]="close_status"
                [viewSocialLinks]="false"
                [linkToBeShared]="linkToBeShared"
                (close)="close_status = true"
              ></app-share-popup>
            </div>
          </div>
        </div>
      </div>
      <!-- end support-article-body -->

      <!-- start also-read -->
      <div *ngIf="posts.length > 0" class="also-read">
        <div class="container">
          <h6>People Also Read</h6>

          <div class="carousel" id="carousel">
            <owl-carousel-o *ngIf="!loading" [options]="carouselOptions">
              <ng-container *ngFor="let post of posts; let i = index">
                <ng-template carouselSlide>
                  <div class="post-carousel">
                    <div
                      class="post"
                      [routerLink]="[
                        '/support',
                        post?.category_slug,
                        post?.post_slug
                      ]"
                      [ngClass]="{ 'mx-auto': posts.length == 2 }"
                    >
                      <div
                        class="photo"
                        style="background-image:url({{ post.image }})"
                      >
                        <img
                          class="sr-only"
                          src="{{ post?.image }}"
                          title="People Also Read"
                          alt="{{ post?.image_alt || 'flyallover' }}"
                        />
                        <!-- add link to category -->
                        <div class="overlay">
                          <a
                            [routerLink]="['/support', post?.category_slug]"
                            class="label"
                          >
                            {{ post?.category_title }}
                          </a>
                        </div>
                      </div>
                      <div class="description">
                        <h4 title="{{ post?.title_content }}">
                          {{ post?.title_content.slice(0, 50) }}
                          <span *ngIf="post?.title_content?.length > 50"
                            >...</span
                          >
                        </h4>
                        <div class="labels">
                          <label>By. FlyAllOver</label>
                          <label *ngIf="post?.created_at"
                            >On
                            {{ post?.created_at | date: "dd MMM yyyy" }}</label
                          >
                        </div>
                        <p class="d-none d-sm-block">
                          {{ post?.short_description?.slice(0, 120) }}
                          <a
                            *ngIf="post?.short_description?.length > 120"
                            [routerLink]="[
                              '/support',
                              post?.category_slug,
                              post?.post_slug
                            ]"
                          >
                            ...</a
                          >
                        </p>
                        <p class="d-block d-sm-none">
                          {{ post?.short_description?.slice(0, 90) }}
                          <a
                            *ngIf="post?.short_description?.length > 90"
                            [routerLink]="[
                              '/support',
                              post?.category_slug,
                              post?.post_slug
                            ]"
                          >
                            ...</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>

            <!-- skeleton cards -->
            <div *ngIf="loading" class="row">
              <div
                class="col-md-4 post-carousel"
                *ngFor="let post of createRange(2)"
              >
                <div class="post">
                  <div class="photo">
                    <div class="overlay p-0">
                      <ngx-skeleton-loader
                        count="1"
                        [theme]="{ width: '100%', height: '100%' }"
                      >
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="description">
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '200px', 'max-width': '100%' }"
                    >
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="1"
                      [theme]="{ width: '150px' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      count="2"
                      [theme]="{ width: '100%' }"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- start support-article -->
  <app-footer></app-footer>
</div>
