<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center">Help Center - Search results</h1>
            <div class="frm">
                <input type="text" placeholder="How can we help you?" [(ngModel)]="searchText"
                    (keyup.enter)="onSearch()" />
                <button (click)="onSearch()"><img alt="Help Center - Search results" title="Search" src="./assets/images/help-center/search.svg" height="30" /></button>
            </div>
        </div>
    </div>

    <div class="help-center-body">

        <!-- start breadcrumb -->
        <div class="container">
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/support']">Help Center</a></li>
                    <li class="breadcrumb-item active">Search Results</li>
                </ol>
            </nav>
        </div>
        <!-- end breadcrumb -->
        <!-- no topics of your search message -->
        <!-- <div class="row" *ngIf="!loading">
            <div class="container text text-danger no-topics">
                No Topics of your Search
                <div class="ml-sm-3 d-block d-sm-inline">
                    <a [routerLink]="['/support']">Back to Help center</a>
                </div>

            </div>
        </div> -->

        <!-- start skeleton preloader -->
        <div class="container" *ngIf="loading">
            <div class=" search-results">
                <div class="search-results-header">
                    <h4>
                        <ngx-skeleton-loader count="1" [theme]="{width:'200px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </h4>
                    <p>
                        <ngx-skeleton-loader count="1" [theme]="{width:'150px', height:'10px'}"></ngx-skeleton-loader>
                    </p>
                </div>

                <div class="search-results-body">
                    <div class="item" *ngFor="let result of createRange(2)">
                        <div>
                            <ngx-skeleton-loader count="1"
                                [theme]="{width:'200px', 'margin-bottom':'0px', height:'15px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <ngx-skeleton-loader count="1" [theme]="{width:'300px', 'margin-bottom':'0px', height:'15px'}">
                        </ngx-skeleton-loader>
                        <!-- <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end skeleton preloader -->

        <!-- start search-results -->
        <div class="container" *ngIf="!loading &&!backendError">
            <div class=" search-results">
                <div class="search-results-header">
                    <h6>Search Results</h6>
                    <p>Found {{searchResults?.length}} results for {{searchText || 'this topic'}}</p>
                </div>

                <div class="search-results-body">
                    <div class="item" *ngFor="let result of searchResults">
                        <h3 [innerHTML]="result.title"></h3>
                        <div [innerHTML]="result.description"></div>
                        <!-- <ul>
                            <li>Select a travel destination, dates and search. Its that easy.</li>
                            <li>Filter for your preference and and select the best flight for you. </li>
                            <li>Add travel products for example insurances or flexible travel dates. <a href="#">see
                                    more</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end search-results -->

        <!-- start pagination -->
        <div class="container" *ngIf="!loading && !backendError">
            <div class="ngb-pagination">
                <!-- pagination -->
                <ngb-pagination class="d-flex justify-content-center mt-0" [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="collectionSize" (pageChange)="changePage(page)">

                </ngb-pagination>
            </div>
        </div>
        <!-- end pagination -->

        <div *ngIf="!loading && backendError">
            <app-no-result-error [homePath]="'/support'"></app-no-result-error>
        </div>

    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<div>

    <app-footer></app-footer>
</div>
<!-- end footer -->
