import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApisService} from 'src/app/services/apis.service';

@Component({
  selector: 'app-popular-dest',
  templateUrl: './popular-dest.component.html',
  styleUrls: ['./popular-dest.component.css']
})
export class PopularDestComponent implements OnInit {
  data: any = [];
  @Input() autoPlay;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';
  isvalid: boolean = true;
  val: number = 0;
  page: number = 1;
  constructor(private apis: ApisService,) {
    this.getPopularDestination();
  }
  ngOnInit(): void {

  }
  prevent(e): void {
    e.preventDefault();
  }
  getPopularDestination() {
    if (typeof document !== 'undefined') {
      localStorage.setItem('popularPageNumber', '1');
    }
    this.apis.popularDestPagination(1).subscribe((data: any) => {
        this.data = data.data.data;
      },
      err => {
        console.log(err);
      });
  }
}
