import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {timer} from 'rxjs';
import {ReviewFlightAddTravellerComponent} from '../review-flight-add-traveller/review-flight-add-traveller.component';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {ReviewFlightTimerReminderDialogComponent} from '../review-flight-timer-reminder-dialog/review-flight-timer-reminder-dialog.component';
import {ReviewFlightEndTimerReminderDialogComponent} from '../review-flight-end-timer-reminder-dialog/review-flight-end-timer-reminder-dialog.component';
import {Router} from '@angular/router';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-right-section',
  templateUrl: './review-flight-right-section.component.html',
  styleUrls: ['./review-flight-right-section.component.css']
})

export class ReviewFlightRightSectionComponent implements OnInit {
  @Input() itineraries;
  countDown: any;
  counter = (30 * 60);
  tick = 1000;
  restTimer = false;
  dialogRef: any;
  searchRequest: any;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private localStorage: LocalStorageService,
    private seoService: SEOService
  ) {
    this.seoUpdate();
  }

  ngOnInit() {
      // this.countDown = timer(0, this.tick)
      //   .subscribe(() => this.countrHandller());

  }

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  countrHandller() {
    if (this.counter == 0 ) {
      this.countDown.unsubscribe();
      this.openEndTimerReminderDialog();
    } else {
      --this.counter;
      if (this.counter == 600 ) {
        this.openTimerReminderDialog();
      }
    }
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
  }

  transform(value: number): string {
    const minutes: number = Math.floor((value % 3600) / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }

  openTimerReminderDialog(): void {
    this.dialogRef = this.matDialog.open(ReviewFlightTimerReminderDialogComponent, {
      panelClass: 'TimerReminderDialog',
      maxHeight: '100vh',
      width: '380px',
    });
  }

  openEndTimerReminderDialog(): void {
    this.dialogRef = this.matDialog.open(ReviewFlightEndTimerReminderDialogComponent, {
      panelClass: 'EndTimerReminderDialog',
      maxHeight: '100vh',
      width: '50%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequest'));
      this.router.navigate(['flights'], {queryParams: this.searchRequest});
    });
  }
}
