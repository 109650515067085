import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {ApisService} from '../../../services/apis.service';
import {Router} from '@angular/router';
import {LocalStorageService} from "../../../services/webApis/local-storage.service";

@Component({
  selector: 'app-travel-details',
  templateUrl: './feedback-dashboard.component.html',
  styleUrls: ['./feedback-dashboard.component.css']
})
export class FeedbackDashboardComponent implements OnInit {
  feedbackForm: any;
  token: any;
  feedbackSubmit: boolean = false;
  name: any;
  email: any;
  phoneNumber: any;
  feedback: any;
  constructor(private cookie: CookieService, private apis: ApisService,  private router: Router ,
              private localStorage: LocalStorageService,) {
    this.feedbackForm = this.createComposeForm();
  }

  ngOnInit(): void {
    // this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token')
    this.getAccountInfo();
  }
  getAccountInfo(){
    this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.name = data.data.first_name.toUpperCase() + ' ' + data.data.last_name.toUpperCase();
      },
      err => {
        console.log(err);
      });
  }
  createComposeForm(): FormGroup {
    return new FormGroup({
      email: new FormControl(''),
      phoneNumber: new FormControl(''),
      feedback: new FormControl(''),
    });
  }
  submitFeedback() {
    this.apis.addFeedback(this.feedbackForm.value, this.token).subscribe((data: any) => {
      this.feedbackSubmit = true;
      this.email = '';
      this.phoneNumber = '';
      this.feedback = '';
    }, err => {
      console.log(err);
    });
    this.feedbackSubmit = false;
  }
}
