import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-travellers-classes-desktop-dialog',
  templateUrl: './travellers-classes-desktop-dialog.component.html',
  styleUrls: ['./travellers-classes-desktop-dialog.component.css']
})

export class TravellersClassesDesktopDialogComponent implements OnInit {
  className: any = 'Economy';
  totalTravellers = 1;
  seatExceedError=" Airlines allow a maximum of 2 children under 2 years old per Adult traveler "
  error = false;
  errorMax = false;
  errorMin = false;
  errorInfants = false;
  travellers: any = {
    adult: {
      id: 'adult',
      name: 'Adults',
      ageFrom: 16,
      ageTo: 64,
      initialValue: 1,
      currentValue: 1,
      minValue: 0,
      maxValue: 12,
    },
    child: {
      id: 'child',
      name: 'Children',
      ageFrom: 2,
      ageTo: 12,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
    seat: {
      id: 'seat',
      name: 'Seat infants',
      ageFrom: 0,
      ageTo: 2,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
    lap: {
      id: 'lap',
      name: 'Lap infants',
      ageFrom: 0,
      ageTo: 2,
      initialValue: 0,
      currentValue: 0,
      minValue: 0,
      maxValue: 12,
    },
  };

  constructor(private route:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   
  }

  ngOnInit(): void {

    if (this.data.className) {
      this.className = this.data.className;
    }
    if (this.data.totalTravellers) {
      this.totalTravellers = this.data.totalTravellers;
    }
    if (this.data.adult) {
      this.travellers.adult.currentValue = this.data.adult;
    }
    if (this.data.child) {
      this.travellers.child.currentValue = this.data.child;
    }
    if (this.data.seat) {
      this.travellers.seat.currentValue = this.data.seat;
    }
    if (this.data.lap) {
      this.travellers.lap.currentValue = this.data.lap;
    }
  }

  inc(traveller): void {
    if (this.totalTravellers < 9) {
      traveller.value.currentValue++;
      this.totalTravellers++;
      if (this.totalTravellers >= 1) {
        this.errorMin = false;
      }
      if (this.travellers.seat.currentValue +Number(this.travellers.lap.currentValue) > this.travellers.adult.currentValue * 2) {
        this.errorInfants = true;
      }

      this.errorMax = false;
    }
    if (this.totalTravellers >= 9) {
      this.errorMax = true;
    }
  }

  dec(traveller): void {
    if (this.totalTravellers >= 1) {
      traveller.value.currentValue--;
      this.totalTravellers--;
      if (this.totalTravellers <= 9) {
        this.errorMax = false;
      }
      if (this.travellers.seat.currentValue + Number(this.travellers.lap.currentValue) <= this.travellers.adult.currentValue * 2) {
        this.errorInfants = false;
      }
      this.errorMin = false;
    }
    if (this.totalTravellers < 1) {
      this.errorMin = true;
    }
  }

}
