<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start page body -->
<div class="help-center-page">

  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family ">Resetting Password</h1>

    </div>
  </div>
  <div class="help-center-body">
    <!-- start contact-us -->
    <div class="container">
      <div class="contact-us"*ngIf="!formSubmitted">
        <div class="logo text-center" *ngIf="!formSubmitted">
          <img title="Sign Up" alt="Sign Up"  src="/assets/images/home/coparess/logo-sign.png">
        </div>
        <form [formGroup]="emailForm" (ngSubmit)="onSubmitEmailForm(emailForm.value)">

          <div class="inside" *ngIf="!formSubmitted">

            <h2 class="text-center Font-Family">Forgot your password?</h2>
            <h4 class="text-center Font-Family">You will receive an email shortly to reset your account info</h4>
            <div class="field" *ngIf="backendError">
              <div class="text-danger text-center Font-Family">Invalid email!</div>
            </div>
            <!-- Email -->
            <div class="field">
              <label for="email"
              class="Font-Family"
                     [ngClass]="emailForm.get('email').invalid && emailForm.get('email').touched && !backendError ?'text-danger':''">Email
                Address</label>
              <input id="email" type="email" formControlName="email" required
                     [ngClass]="emailForm.get('email').invalid && emailForm.get('email').touched && !backendError ? 'has-error':'no-error'"/>
              <span *ngFor=" let validation of validationMessages.email">
                                <small class="text-danger"
                                       *ngIf="emailForm.get('email').hasError(validation.type) && (emailForm.get('email').dirty || emailForm.get('email').touched)">
                                    {{validation.message}}</small>
                            </span>
            </div>
            <div class="captcha">
              <re-captcha required (resolved)="resolved($event)" [siteKey]="siteKey">
              </re-captcha>
            </div>

            <div class="submit">
              <button [ngClass]=" emailForm.valid && captchaKey? 'button active' : 'button disabled' "
                      [disabled]="!emailForm.valid && !captchaKey" type="submit">CONFIRM
                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>
              </button>                                                          
            </div>

          </div>

        </form>
      </div>
        <div class="inside text-center form-submitted" *ngIf="formSubmitted">
      <div class="send-mail">
          <h6 class="head-text Font-Family">Thank you!</h6>
          <p class=" Font-Family">An email is already sent to your account ({{emailForm.get('email').value}}) to reset your
            account details</p>
          <div class="img">
            <img title="Resetting Email" alt="mail" src="./assets/images/help-center/email.svg" width="80"/>
          </div>
          <a [routerLink]="['/']">
            <button class="btn btn-back-home Font-Family ">Go To Home</button>
          </a>
        </div>
      </div>
    </div>
    <!-- end contact-us -->
  </div>
</div>
<!-- end page body -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
