import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { SearchComponent } from 'src/app/components/search/search.component';
import { ApisService } from 'src/app/services/apis.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FormArray } from '@angular/forms';
import { SEOService } from "../../services/seo.service";
import { DomSanitizer } from '@angular/platform-browser';
import { FroalaEditorStylesService } from 'src/app/services/froala-editor-styles/froala-editor-styles.service';

@Component({
  selector: 'app-offer-flight',
  templateUrl: './offer-flight.component.html',
  styleUrls: ['./offer-flight.component.css'],
})
export class OfferFlightComponent implements OnInit, OnDestroy, AfterViewInit {

  // this component is viewed to update search fields data
  @ViewChild(SearchComponent)
  private searchComponentFLightResults: SearchComponent;

  offerSlug: string;
  paramSubscription: Subscription;

  transformedDescription;
  offerData;
  loading: boolean = true;
  errorExists: boolean = false;
  close_status: boolean = true;
  offerSearchData;
  // share links vars
  routerUrl: string;
  hostUrl: string;
  linkToBeShared: string;

  constructor(
    private seoService: SEOService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private metaService: MetaTagsService,
    private title: Title,
    private apis: ApisService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private froalaStyles: FroalaEditorStylesService,
    private sanitizer: DomSanitizer
  ) {
    this.seoUpdate();
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  /* start lifecycle hooks */
  ngOnInit(): void {
    this.froalaStyles.addFroalaStylesFile();
    this.paramSubscription = this.route.params.subscribe(params => {
      this.offerSlug = params.slug;
      this.getOfferData();
    });
    this.routerUrl = this.router.url;
    this.hostUrl = AppComponent.hostUrl;
    this.linkToBeShared = `${this.hostUrl}${this.routerUrl}`;
  }

  seoUpdate(title = null, description = null, image = null) {

    this.seoService.updateTitle(this.router.url);
    this.seoService.updateDescription(this.router.url);
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');
    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }
  ngAfterViewInit(): void {
    if (this.offerSearchData) {
      this.fillSearchFields(this.offerSearchData);
      this.disableSearchFields();
    }
  }
  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }

  /* start lifecycle hooks */
  getOfferData() {
    this.apis.getOfferDetails(this.offerSlug).subscribe((res: any) => {
      this.offerData = res.data;
      this.seoUpdate(this.offerData?.meta_data?.data?.title, this.offerData?.meta_data?.data?.description, this.offerData?.meta_data?.data['og:image']);
      this.transformedDescription = this.transformYourHtml(this.offerData.description);
      this.offerSearchData = {
        "flightData": [
          {
            "origin": `${this.offerData.origin} - ${this.offerData.origin_city}`,
            "destination": `${this.offerData.destination} - ${this.offerData.destination_city}`,
            "departure_date": "",
            "return_date": ""
          },
          { "origin": "", "destination": "", "departure_date": "", "return_date": "" }
        ],
        "travellers": 1,
        "adult": 1,
        "youth": 0,
        "senior": 0,
        "child": 0,
        "lap": 0,
        "seat": 0,
        "class": this.offerData.class,
        "trip_type": this.getTripType(this.offerData.type),
      }
      this.offerData.valid_from = this.datePipe.transform(this.offerData.valid_from, 'yyyy-MM-dd');
      this.offerData.valid_for = this.datePipe.transform(this.offerData.valid_for, 'yyyy-MM-dd');

      this.fillSearchFields(this.offerSearchData);
      this.disableSearchFields();
      this.errorExists = false;
      this.loading = false;
    }, err => {
      this.errorExists = true;
      this.loading = false;
    })
  }

  modifyMetaTags(metaData) {
    this.metaService.modifyMetaTags(metaData)
  }

  getTripType(triTypeObj) {
    if (triTypeObj.offerType === 1) {
      return 'Return';
    } else if (triTypeObj.offerType === 2) {
      return 'OneWay';
    }
  }
  // this method is used to persist data of the offer in the search fields
  fillSearchFields(offerSearchData) {
    if (this.searchComponentFLightResults) {
      this.searchComponentFLightResults.updateSearchData(offerSearchData);
      // make the min date for date picker is the today's date
      let today: Date | string = new Date();
      this.searchComponentFLightResults.initialDate = today;

      // set the date of the datePicker to valid_from date or if valid_from date is an old date then set it to today's date
      today = this.datePipe.transform(today, 'yyyy-MM-dd');
      let openDate;
      if (today < this.offerData.valid_from) {
        openDate = new Date(this.offerData.valid_from);
      } else {
        openDate = new Date(today)
      }
      this.searchComponentFLightResults.openDate = openDate;
      // setting the value of departure_date in search form to openDate
      (this.searchComponentFLightResults.searchForm.get('flightData') as FormArray).controls[0]
        .get('departure_date')
        .setValue(this.searchComponentFLightResults.openDate);
    }
  }

  disableSearchFields() {
    // const controlNames = ['flightData-0-origin', 'flightData-0-destination'];
    if (this.searchComponentFLightResults) {
      this.searchComponentFLightResults?.disableCitiesControls(true);
      // disable radio buttons group
      this.searchComponentFLightResults.hideTripType = true;
      this.changeDetection.detectChanges();
    }

  }

  checkDates(modalContent, searchData) {
    // check that the dates chosen by the user are in the range of valid dates
    // these checks are based on lexographic (i.e., alphanumeric "dictionary order") string comparison
    if (
      searchData.departure_date < this.offerData.valid_from ||
      searchData.departure_date > this.offerData.valid_for
    ) {
      this.modalService.open(modalContent, { centered: true }).result.then((result) => {
        // if the user clicked continue discarding that he chose invalid dates redirect him to review flight page
        this.reviewOfferFlight(searchData);
      }, (reason) => {
        this.searchComponentFLightResults.roundSpinner = false;
      });
    } else {
      this.reviewOfferFlight(searchData);
    }
  }

  reviewOfferFlight(searchData) {
    this.searchComponentFLightResults.disableCitiesControls(true);
    this.router.navigate(['/review-flight'],
      {
        queryParams: {
          offer: this.offerSlug,
          departure_date: searchData.departure_date,
          return_date: searchData.return_date,
          travellers: searchData.travellers,
          adult: searchData.adult,
          youth: searchData.youth,
          senior: searchData.senior,
          child: searchData.child,
          lap: searchData.lap,
          seat: searchData.seat,
        }
      }
    )
  }
}
