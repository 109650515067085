import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { SEOService } from "../../../services/seo.service";

@Component({
  selector: "app-review-flight-select-travellers",
  templateUrl: "./review-flight-select-travellers.component.html",
  styleUrls: ["./review-flight-select-travellers.component.css"],
})
export class ReviewFlightSelectTravellersComponent
  implements OnChanges, OnInit
{
  @Input() travellers;
  @Output() selectTravellerEvent = new EventEmitter();
  @Output() unSelectTravellerEvent = new EventEmitter();
  @Output() deleteTravellerEvent = new EventEmitter();

  constructor(private seoService: SEOService) {}

  ngOnInit() {
    this.seoUpdate();
    // console.log(this.travellers);
  }

  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  ngOnChanges(changes) {
    if (changes.travellers.currentValue) {
    }
  }

  toggleCheck(e, traveller) {
    if (e.checked == true) {
      this.selectTravellerEvent.emit(traveller);
    } else {
      this.unSelectTravellerEvent.emit(traveller);
    }
  }

  delete(traveller) {
    this.unSelectTravellerEvent.emit(traveller);
    this.deleteTravellerEvent.emit(traveller);
  }
}
