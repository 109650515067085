import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-note-pay-later',
  templateUrl: './note-pay-later.component.html',
  styleUrls: ['./note-pay-later.component.css']
})
export class NotePayLaterComponent implements OnInit {

  constructor(    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    console.log("Page name ","NotePayLaterComponent");
  }
  goToHome(){
    this.dialog.closeAll }
}
