import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-villas',
  templateUrl: './all-villas.component.html',
  styleUrls: ['./all-villas.component.css']
})
export class AllVillasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getRating(rateValue: number, e){


    let classList = '';

    if (rateValue >= e){
      classList = 'checked';
    }

    return classList;
  }
}
