import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-villas-details',
  templateUrl: './villas-details.component.html',
  styleUrls: ['./villas-details.component.css']
})
export class VillasDetailsComponent implements OnInit {
   id: any[];

  constructor( private activatedRoute: ActivatedRoute,
               private router: Router,
               ) { }

  ngOnInit(): void {
  }
  // routToHotelPage(){
  //   this.router.navigate(['vacation-hotel-details/', this.id]);
  // }

  openDialog() {
  }
}
