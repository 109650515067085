<header id="side" class="header-home">
    <app-navbar class="fixed" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}"></app-navbar>
    <ng-container *ngIf="!isSticky">
        <div class="container center-box mt-5">
            <div class="img">
                <img src="assets\images\egyptair\discount 3.png" alt="discount">
            </div>
            <app-engine  [disabeled]="false" [color]="'#fff'" (offsetHeight)="getHeight()"></app-engine>
        </div>
    </ng-container>
</header>
<section class="video-header">
    <ng-container>
        <app-home-video-main-section [images]="images" (finishedLoading)="hasLoadedMainSec = $event" [offsetHeight]="elementHeight">
        </app-home-video-main-section>
    </ng-container>
</section>
<div style="margin-top: 300px;">
    <!-- <app-home-airlines-logos *ngIf="data" [airLines]="data" ></app-home-airlines-logos> -->

</div>
<!-- <app-important-links></app-important-links> -->
<div class="mt-5">
    <app-footer ></app-footer>
</div>

