<div class="covid-container">
  <div class="container">
    <div class="row">
      <div class="headr_covid">
        <div class="header_img">
        <img src="../../../assets/images/help-center/no-result.png" title="no-result-image" alt="no-result">
        </div>
        <hr class="d-block ">
        <h5>page result has expired and you will be redirected to the flight search results again to refresh the data.</h5>
        <span><a routerLink="/flight-results"  class="btn btn_continue">Continue</a></span>
      </div>
    </div>
  </div>
</div>
