<app-navbar></app-navbar>
<div class="payment-page">
    <div class="container">
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/hotel-results']">Search</a></li>
                <!-- <li class="breadcrumb-item"><a [routerLink]="['/hotel', hotelCode]">Your hotel</a></li> -->
                <li class="breadcrumb-item"><a>Your hotel</a></li>
                <li class="breadcrumb-item active">Payment</li>
            </ol>
        </nav>
    </div>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg contain">
<!--                    <div class="box box-guest">-->
<!--                        <app-review-trip-form1 [resultsArrived]="hotelInfoArrived" [loading]="loading"-->
<!--                            [passengerDetailsInput]="passengerDetails" [submit]="submitted">-->
<!--                        </app-review-trip-form1>-->
<!--                    </div>-->
                    <div id="billierMissmatch" *ngIf="billerMissmatch === false" class="text-danger" #match>
                        <h3>Biller name must match one of the passengers! </h3>
                    </div>
                    <div class="box box-billing" *ngIf="!loading && hotelInfoArrived">
                        <h2>Billing Information</h2>
<!--                        <app-review-trip-form2 [submit]="submitted"></app-review-trip-form2>-->
                    </div>
                    <div class="box box-price" *ngIf="!loading && hotelInfoArrived">
                        <h2>Price Details</h2>
                        <div class="box-inside">
                            <div class="box-header p-3">
                                <div class="row">
                                    <div class="col">
                                        <h1>Total Price Details:</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-3">
                                <div class="row text-black-50 mb-2">
                                    <div class="col">Guests</div>
                                    <div class="col text-right">{{adults}}</div>
                                </div>
                                <div class="row">
                                    <div class="col">Subtotal:</div>
                                    <div class="col text-right">{{pricesInfo?.AmountBeforeTax | currency}}</div>
                                </div>
                            </div>
                            <div class="box-body p-3">
                                <div class="row">
                                    <div class="col">Taxes & Fees:</div>
                                    <div class="col text-right">
                                        {{pricesInfo?.AmountAfterTax - pricesInfo?.AmountBeforeTax | currency}}</div>
                                </div>
                            </div>
                            <div class="total">
                                <div class="row">
                                    <div class="col">Total</div>
                                    <div class="col text-right">{{pricesInfo?.AmountAfterTax | currency}}</div>
                                </div>
                            </div>
                            <h4 class="tip">
                                Please note: Your credit/debit card may be billed in multiple charges totaling the final
                                total price.
                            </h4>
                        </div>
                    </div>
                    <div class="box box-accept" *ngIf="!loading && hotelInfoArrived">
                        <div class="row">
                            <div class="col"><input [formControl]="checkbox1" type="checkbox" id="checkbox1"></div>
                            <div class="col">
                                <label for="checkbox1">
                                    I Agree that I have read and accepted the FlyAllOver's
                                    <a href="https://flyallover.com/support/terms-conditions/" target="_blank">
                                        Terms & Conditions.
                                    </a>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col"><input [formControl]="checkbox2" type="checkbox" id="checkbox2"></div>
                            <div class="col"><label for="checkbox2">I agree on the Property Rules of this trip.</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col"><input [formControl]="checkbox3" type="checkbox" id="checkbox3"></div>
                            <div class="col">
                                <label for="checkbox3">
                                    Please acknowledge our <a href="https://flyAllOver.com/cancellation-policy/"
                                        target="_blank">Cancellation Policy</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- end box-accept -->

                    <!-- start confirm -->
                    <div class="confirm text-right" *ngIf="!loading && hotelInfoArrived">
                        <button (click)="submitBook()"
                            [disabled]="!checkbox1.value || !checkbox2.value || !checkbox3.value">
                            <i *ngIf="spinner" class="fas fa-spinner fa-spin"></i>
                            CONFIRM AND BOOK
                        </button>
                    </div>
                    <!-- end confirm -->

                </div>
                <!-- begin skeleton loader -->
                <div class="col-lg sidebar" *ngIf="loading && !hotelInfoArrived">
                    <!-- SIDEBAR -->
                    <!-- start post -->
                    <div class="post">
                        <div class="photo">
                            <ngx-skeleton-loader count=" 1" [theme]="{height:'200px', width:'200px'}">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="title pt-2">

                            <ngx-skeleton-loader count="1"
                                [theme]="{height:'20px', width:'70%', 'margin-right':'30px', 'display':'block'}">
                            </ngx-skeleton-loader>

                            <ngx-skeleton-loader appearance="circle" count="5"
                                [theme]="{height:'10px', width:'10px', 'vertical-align':'top', 'margin-right':'0px'}">
                            </ngx-skeleton-loader>
                            <p>
                                <ngx-skeleton-loader count="1"
                                    [theme]="{height:'10px', width:'60%', 'margin-right':'30px', 'display':'block'}">
                                </ngx-skeleton-loader>
                            </p>
                        </div>
                        <div class="details">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of createRange(4)">
                                    <div class="row">
                                        <div class="col">
                                            <ngx-skeleton-loader count="1"
                                                [theme]="{height:'5px', width:'60%', 'margin-right':'30px', 'display':'block'}">
                                            </ngx-skeleton-loader>
                                        </div>
                                        <div class="col" style="text-align: right;">
                                            <ngx-skeleton-loader class="text-right" count="1"
                                                [theme]="{height:'5px', width:'80%', 'margin-left':'auto', 'display':'block'}">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                            <div class="row total">
                                <div class="col">
                                    <ngx-skeleton-loader count="1"
                                        [theme]="{height:'25px', width:'60%', 'display':'block', 'margin-bottom':'0px'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="col text-right">
                                    <ngx-skeleton-loader count="1"
                                        [theme]="{height:'25px', width:'80%', 'margin-left':'auto' , 'margin-bottom':'0px'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <!-- end skeleton loader -->
                <div class="col-lg sidebar" *ngIf="!loading && hotelInfoArrived">
                    <!-- SIDEBAR -->
                    <!-- start post -->
                    <div class="post">
                        <div class="photo">
                            <img title="Hotel Image" alt="Hotel"  class="img-fluid" src="{{hotelImg}}" />
                        </div>
                        <div class="title pt-2">
                            <h2>{{hotelInfo.HotelName}}</h2>
                            <span class=" d-block rate rate{{rate}}">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </span>
                            <p>{{roomName}}</p>
                        </div>
                        <div class="details">
                            <ul class="list-unstyled">
                                <li>
                                    <div class="row">
                                        <div class="col">Check In</div>
                                        <div class="col text-right">{{pricesInfo.StartDate | date: 'EEE , d MMM yyyy'}}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col">Check Out</div>
                                        <div class="col text-right">{{pricesInfo.EndDate | date: 'EEE , d MMM yyyy'}}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-7">{{ pricesInfo.AverageNightlyRate | currency }} x
                                            {{ pricesInfo.Nights }} night(s)</div>
                                        <div class="col text-right">
                                            {{ pricesInfo.AverageNightlyRate * pricesInfo.Nights | currency }}</div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <!-- <div class="col">!!!Discount</div> -->
                                        <!-- <div class="col text-right">$208</div> -->
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col">Service fee</div>
                                        <div class="col text-right">$0</div>
                                    </div>
                                </li>
                            </ul>
                            <div class="row total">
                                <div class="col">Total</div>
                                <div class="col text-right">{{pricesInfo.AmountAfterTax | currency}}</div>
                            </div>
                        </div>
                        <div class="text-center coupon">
                            <!-- <a href="#">Have a Coupon or Promo Code?</a> -->
                        </div>
                    </div>
                    <!-- end post -->
                </div>
            </div>
        </div>
    </div>
    <!-- end content -->

</div>
<!-- end payment-page -->

<app-footer></app-footer>
