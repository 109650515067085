<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family ">Newsletter</h1>
        </div>
    </div>

    <div class="help-center-body">
        <!-- start contact-us -->
        <div class="container">
            <div class="contact-us">
                <div class="inside" *ngIf="!submit">
                    <h4 class="text-center Font-Family">Enter your Email get hot deals!</h4>
                    <p class="text-center Font-Family">Get our newsletter and be the first one to know hear from us.</p>
                    <form [formGroup]="newsLetterForm" (ngSubmit)="onSubmitEmail(newsLetterForm.value)">
                        <div class="field">
                            <label for="email"
                            class="Font-Family"
                                [ngClass]="newsLetterForm.get('email').invalid && newsLetterForm.get('email').touched ?'text-danger':''">Email
                                Address</label>
                            <input id="email" type="email" required formControlName="email"
                                (keyup)="navigateOnSamePage()"
                                [ngClass]="newsLetterForm.get('email').invalid && newsLetterForm.get('email').touched ? 'has-error':'no-error'" />
                            <span *ngFor=" let validation of validationMessages.email">
                                <small class="text-danger"
                                    *ngIf="newsLetterForm.get('email').hasError(validation.type) && (newsLetterForm.get('email').dirty || newsLetterForm.get('email').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- <mat-error *ngIf="true">hellos</mat-error> -->
                        <div>
                            <re-captcha required (resolved)="resolved($event)" [siteKey]="siteKey">
                            </re-captcha>
                        </div>
                        <div class="submit d-flex justify-content-center">
                            <button [ngClass]="newsLetterForm.valid && captchaKey? 'active' :' disabled'"
                                [disabled]="!newsLetterForm.valid && !captchaKey">SEND
                                <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i></button>
                        </div>
                    </form>

                </div>
                <div class="inside text-center" *ngIf="submit">
                    <h6>Thank you!</h6>
                    <p>Check your email address {{newsLetterForm.value.email}} to find our newsletter</p>
                    <div class="img">
                        <img title="Check your email " alt="mail"  src="./assets/images/help-center/mail.svg" width="80" />
                    </div>
                    <a [routerLink]="['/']">Go back to homepage <i class="fas fa-angle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end contact-us -->
    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
