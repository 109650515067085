
<div class="">
  <div class="gdlr-core-price-table-item  ">
      <div class=" ">
          <div class="gdlr-core-price-table">
              <div class="gdlr-core-price-table-head">
                  <h3 class="gdlr-core-price-table-title">Price Details</h3>
                  <div class="gdlr-core-price-table-caption">Suitable for corporate</div>
              </div>
              <div class="gdlr-core-price-table-price gdlr-core-title-font">
                  <span class="gdlr-core-price-prefix">$</span><span
                      class="gdlr-core-price-table-price-number">50</span><span class="gdlr-core-price-suffix">/
                      Total</span>
              </div>
              <div class="gdlr-core-price-table-content-wrap gdlr-core-center-align">
                  <div class="gdlr-core-price-table-content">
                      <div class="gdlr-core-price-list-shortcode gdlr-core-with-divider">
                          <ul>
                              <li><i class="fa fa-check"></i>List item here</li>
                              <li><i class="fa fa-check"></i>List item here</li>
                              <li><i class="fa fa-check"></i>List item here</li>
                              <li><i class="fa fa-check"></i>List item here</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
