<form [formGroup]="form">
  <div class="details-traveler">
    <div class="formTitle">
      <span>Personal Information</span>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">First Name</mat-label>
            <input matInput placeholder="John" type="text" formControlName="GivenName">
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Middle Name</mat-label>
            <input matInput placeholder="doe" type="text" formControlName="MiddleName">
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Last Name</mat-label>
            <input matInput placeholder="roblox" type="text" formControlName="Surname">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Email</mat-label>
            <input C placeholder="name@email.com" type="email" formControlName="email">
          </mat-form-field>
        </div>
        <div class="col-6 phone">
          <mat-form-field appearance="fill">
            <mat-label class="star">Phone Number</mat-label>
            <input matInput class="phone"  ng2TelInput placeholder="Phone Number" type="text" formControlName="phone">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Gender</mat-label>
            <input type="text" matInput
                   formControlName="gender"
                   placeholder="Select your gender"
                   (click)="$event.target.select()"
                   [matAutocomplete]="gender">
            <mat-autocomplete #gender="matAutocomplete">
              <mat-option *ngFor="let option of filteredGender | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              Gender is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" (click)="travellerDOBCalender.open()">
            <mat-label>Date Of Birth</mat-label>
            <input matInput [matDatepicker]="travellerDOBCalender" formControlName="travellerDOB">
            <mat-datepicker-toggle matSuffix [for]="travellerDOBCalender"></mat-datepicker-toggle>
            <mat-datepicker #travellerDOBCalender></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Country</mat-label>
            <input type="text" matInput
                   formControlName="travellerCountry"
                   placeholder="Select your Country"
                   (click)="$event.target.select()"
                   [matAutocomplete]="country_id">
            <mat-autocomplete #country_id="matAutocomplete">
              <mat-option *ngFor="let option of filteredPersonalCountries | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label class="star">Nationality</mat-label>
            <input
              type="text"
              formControlName="travellerNationality"
              placeholder="Select your Nationality"
              matInput
              (click)="$event.target.select()"
              [matAutocomplete]="nationality_id">
            <mat-autocomplete #nationality_id="matAutocomplete">
              <mat-option *ngFor="let option of filteredPersonalNationalities | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="details-traveler">
    <div class="formTitle">
      <span>Passport Information</span>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Passport Number</mat-label>
            <input matInput placeholder="000000000" type="text" formControlName="passportNumber">
            <mat-error *ngIf="form.controls.passportNumber.errors && form.controls.passportNumber.errors.pattern">
              Passport Number should be numbers
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill" (click)="issueDateCalender.open()">
            <mat-label>Issue Date</mat-label>
            <input matInput [matDatepicker]="issueDateCalender" formControlName="issueDate">
            <mat-datepicker-toggle matSuffix [for]="issueDateCalender"></mat-datepicker-toggle>
            <mat-datepicker #issueDateCalender></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill" (click)="expiryDateCalender.open()">
            <mat-label>Expire Date</mat-label>
            <input matInput [matDatepicker]="expiryDateCalender" formControlName="expiryDate">
            <mat-datepicker-toggle matSuffix [for]="expiryDateCalender"></mat-datepicker-toggle>
            <mat-datepicker #expiryDateCalender></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Country</mat-label>
            <input
              type="text" matInput
              formControlName="passportCountry"
              placeholder="Select your Country"
              (click)="$event.target.select()"
              [matAutocomplete]="countryPassport">
            <mat-autocomplete #countryPassport="matAutocomplete">
              <mat-option *ngFor="let option of filteredPassportCountries | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Nationality</mat-label>
            <input
              type="text"
              formControlName="passportNationality"
              placeholder="Select your Nationality"
              matInput
              (click)="$event.target.select()"
              [matAutocomplete]="nationalityPassport">
            <mat-autocomplete #nationalityPassport="matAutocomplete">
              <mat-option *ngFor="let option of filteredPassportNationalities | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label class="star">Issued Country</mat-label>
            <input
              type="text"
              formControlName="passportIssuedCountry"
              placeholder="Select your Issued Country"
              matInput
              (click)="$event.target.select()"
              [matAutocomplete]="issuedCountry">
            <mat-autocomplete #issuedCountry="matAutocomplete">
              <mat-option *ngFor="let option of filteredPassportIssuedCountries| async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
<hr class="hr">
<div class="bottom">
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-end">
        <div class="col-2">
          <button class="btn submit-btn"
                  [disabled]='form.status != "VALID"'
                  [ngClass]="{'notValid': form.status != 'VALID'}"
                  (click)="addTraveller()">
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
