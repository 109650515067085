import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-hotel-page',
  templateUrl: './hotel-page.component.html',
  styleUrls: ['./hotel-page.component.css']
})
export class HotelPageComponent implements OnInit {

  constructor(private seoService: SEOService,) {
    this.seoUpdate();
  }

  ngOnInit(): void {
  }

  carouselOptions: OwlOptions = {
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    responsive: {
      0: {items: 1},
      768: {items: 2},
      992: {items: 3}
    },
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true
  };

  seoUpdate(): void {
    this.seoService.updateTitle('FlyAllOver - Deals & Discounts for Hotel Reservations from Luxury Hotels to Budget Accommodations');
    this.seoService.updateDescription(
      'FlyAllOver -Find cheap hotels and discounts when you book on Hotels.com. Compare hotel deals, offers and read unbiased reviews on hotels.');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/home/par.jpg');
  }

}
