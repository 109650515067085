<div style="position: relative">
  <input type="text" class="form-control airlineCarrier"
         [formControl]="myControl"
         [matAutocomplete]="auto"
         (focus)="onFocus()"
         (blur)="onFocus()"/>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
  <label *ngIf="!isFocused" class="placeHolder">
    <span>Choose An Airline (Optional)</span>
    <img alt="location" title="location" src="/assets/images/icons/searching.svg">
  </label>
</div>

