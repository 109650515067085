import {Component, OnInit, Inject, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {DOCUMENT} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CookieService} from 'ngx-cookie-service';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';

@Component({
  selector: 'app-delete-member',
  templateUrl: './delete-member.component.html',
  styleUrls: ['./delete-member.component.css']
})
export class DeleteMemberComponent implements OnInit {
  token: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private apis: ApisService,
    private matDialog: MatDialog,
    private cookie: CookieService,
    private localStorage: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteMemberComponent>,
  ) {
  }

  ngOnInit(): void {
    this.token =  this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token');
  }
  close(){
    this.matDialog.closeAll();
  }
  deleteMember(){
     this.apis.deleteFamilyMember(this.data, this.token).subscribe((data: any) => {
         this.matDialog.closeAll();
         this.dialogRef.close(data);
        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);
      },
      err => {
        console.log(err);
    });
  }
}
