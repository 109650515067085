import {Component, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-bookings',
  templateUrl: './help-bookings.component.html',
  styleUrls: ['./help-bookings.component.css']
})
export class HelpBookingsComponent implements OnInit {
  // pagination variables
  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 50;
  topicTitle;
  postId;
  postTitle;
  postData = {};

  postDataExist: boolean = false;
  textFormSearchResults;
  searchText;

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.topicTitle = this.activatedRoute.snapshot.params.topic;
    this.postId = this.activatedRoute.snapshot.params.id;
    this.postTitle = this.activatedRoute.snapshot.params.title;
    this.textFormSearchResults = this.activatedRoute.snapshot.queryParams.text;
    this.apis.getPostDetails(this.postId).subscribe(
      (res: any) => {
        this.postData = res.data;
        this.postDataExist = true;
      },
      (err) => {
        this.router.navigate(['/no-result']);
      }
    );
  }

  goToSearchResults() {
    this.router.navigate(['/search-results'], {
      queryParams: {
        text: this.textFormSearchResults
      }
    });
  }

  onSearch() {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: this.searchText
      }
    });
  }

  seoUpdate() {

    this.seoService.updateTitle('Help Bookings | FlyAllOver');
    this.seoService.updateDescription('Help Bookings | FlyAllOver');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

}
