import { DataService } from './../../../services/data.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/webApis/local-storage.service';
import { ApisService } from '../../../services/apis.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent implements OnInit {
  token: any;
  flights: any;
  noteLog: any;
  errorMessages;
  flightData: any;
  errorData: any[];
  private plaidInstance: any;
  plaidReady = false
  plaidToken: any;
  changePaymentKay: any;
  orderId: any;
  plaidProducts: any;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    private router: Router,
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    public apis: ApisService,
  ) {


    this.token = this.localStorage.getItem('flyallover_token');
    this.errorMessages = this.data?.error
    this.orderId = this.data?.order_id
    this.errorData = this.errorMessages?.error?.data;
    this.close()
  }

  close(){
    this.router.navigate(['/update-payment/zelle/'+this.orderId]);
    this.dialogRef.close()
  }
  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.loadPlaidScript().then(() => {
        this.plaidReady = true; // Enable the button
      }).catch(error => {
        console.error('Failed to load the Plaid script', error);
      });
    }
  }
  goToHome() {
    this.router.navigate(['/']);
  }

  goToDashboard() {
    this.router.navigate(['/profile/dashboard']);
  }
  checkedValue(checked){
    this.changePaymentKay =checked.value
  }
  onClick(): void {
    this.dialogRef.close();
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    let space = ' ';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}hr(s)`;
    }
    convertedMinutes = `${minutes % 60}min(s)`;
    return convertedHours + space + convertedMinutes;
  }
  openPlaidLink() {
    if (this.plaidReady) {
      this.plaidInstance.open();
    }
  }

  loadPlaidScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).Plaid) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.onload = () => resolve();
      script.onerror = () => reject('Failed to load the Plaid script');
      document.body.appendChild(script);
    });
  }
  getPlaidToken() {
    this.apis.plaidCreateToken().subscribe({
      next: (res:any)=>{
        this.plaidToken = res.data;
        this.initPlaid();
      }
    })
  }

  initPlaid() {
    this.plaidInstance = (window as any).Plaid.create({
      clientName: 'Fly All Over',
      env: 'production',
      token: this.plaidToken,
      product: this.plaidProducts,
      onSuccess: (public_token: string, metadata: any) => {
        this.plaidTransferChangeStatus()
      },
      onExit: (err: any, metadata: any) => {
        if (err != null) {
          console.error('Error:', err);
        }
      }
    });
    if (this.plaidReady) {
      this.plaidInstance.open();
    }
  }
changepayment(){
  this.apis.changePayment(this.orderId,this.changePaymentKay).subscribe({
    next: (res:any)=>{
      if(this.changePaymentKay=='plaid'){
        this.plaidToken =res.data?.link_token
        this.plaidProducts =res.data?.products
        this.initPlaid()
      }else{
        this.dialogRef.close()
      this.router.navigate(['/update-payment/zelle/'+this.orderId]);
      }
    }
  })
}
  protected readonly length = length;
  plaidTransferChangeStatus(){
this.apis.plaidTransferChangeStatus(this.plaidToken).subscribe({
  next: (res:any)=>{
    this.router.navigate(['/payment-details/success/' + this.orderId]);
    this.dialogRef.close()
  }
})
  }
}

