<app-navbar></app-navbar>
<h1 class="no-dataRouter" *ngIf="intent == undefined && !loading">
    This page can't be opened directly
</h1>
<div class="page" *ngIf="intent != undefined">
    <div class="container">
        <div class="confirmation shadow">
            <div *ngIf="loading">
                <h3 class="text-success">
                    <ngx-skeleton-loader count="1" [theme]="{height:'30px', width:'60%'}">
                    </ngx-skeleton-loader>
                </h3>
                <div class="row">
                    <div class="col-md-6">
                        <p *ngFor="let item of createRange(4)">
                            <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'20%'}">
                            </ngx-skeleton-loader> <span>
                                <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'45%'}">
                                </ngx-skeleton-loader>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p *ngFor="let item of createRange(4)">
                            <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'20%'}">
                            </ngx-skeleton-loader> <span>
                                <ngx-skeleton-loader count="1" [theme]="{height:'15px', width:'45%'}">
                                </ngx-skeleton-loader>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <!-- end skeleton -->
            <div *ngIf="!loading && !supportMessage">
                <h3 class="text-success">You have successfully booked a room in {{pnrResponse.hotel_name}}</h3>
                <div class="row">
                    <div class="col-md-6">
                        <p>Address: <span>{{pnrResponse.address}}</span></p>
                        <p>Phone: <span> {{pnrResponse.hotel_phone}}</span></p>
                        <p>Confirmation number is: <span>{{pnrResponse.confirmation_number}}</span></p>
                        <p>PNR: <span>{{pnrResponse.pnr}}</span></p>
                    </div>
                    <div class="col-md-6">
                        <p>
                            From <span>{{pnrResponse.start_date | date}}</span>
                            To <span>{{pnrResponse.end_date | date}}</span>
                        </p>
                        <p>Cost per night: <span>{{pnrResponse.cost_night |  currency}}</span></p>
                        <p>Total Cost : <span>{{pnrResponse.cost}}</span></p>
                    </div>
                </div>
            </div>

            <div *ngIf="!loading && supportMessage" class="text-center p-4">
                <!-- card is invalid stripe responded with 400 -->
                <ng-container *ngIf="invalidCard">
                    <h3 *ngIf="invalidCard" class="">Card is invalid</h3>
                    <h4>We could not proceed with your reservation</h4>
                </ng-container>
                <!-- stripe responded with another error code  -->
                <ng-container *ngIf="!invalidCard">
                    <p>We cannot complete your reservation request now, but you can call our customer service to support
                        you
                        any
                      text-danger                     time.</p>
                    <p><a href="mailto:info@flyallover.com">info@flyallover.com</a></p>
                    <p><a href="tel:888-666-8545">888-666-8545</a></p>
                </ng-container>

            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
