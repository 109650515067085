import {AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-add-reviews',
    templateUrl: './add-reviews.component.html',
    styleUrls: ['./add-reviews.component.css'],
    styles: [`

    `],
    providers: [DatePipe]
})
export class AddReviewsComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() cityId: number | undefined;
    @Output() addReview:EventEmitter<any> = new EventEmitter();
    @Input()keyId :string;
    rating: any;
    rate: number;
    data: any = [];
    user_id: any;
    token: any;
    myDate: any;
    tit: any;
    rev: any;
    name: any;
    firstName: any;
    middle_name: any;
    lastName: any;
    email: any;
    email_log: any;
    first_name_dash: any;
    toggle = true;
    status = 'Enable';


    constructor(
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
    ) {
        this.myDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    }

    ngOnInit(): void {
        this.getUser_info();
        this.getToken();
    }

    ngOnChanges() {

        this.getUser_info();
    }

    getUser_info() {
        // this.user_id=this.cookie.get('');
        this.user_id = localStorage.getItem('user_id');
        this.email_log = localStorage.getItem('email_log');
        this.first_name_dash = localStorage.getItem('firstName-dash');


    }

    getToken() {
        this.token = localStorage.getItem('flyallover_token');

    }

    ngAfterViewChecked() {
        this.getUser_info();
    }

    checkoutForm = this.formBuilder.group({
        name: new FormControl('', Validators.compose([
            Validators.required,])),
        email: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
        ])),
        titleReview: '',
        review: ''
    });
    spiltName() {
        // this.name = event.target.value.split(' ', 3);
        this.name = this.checkoutForm.value.name.split(' ', 3);
    }

    onSubmit(): void {
        this.name = this.checkoutForm.value.name?.split(' ', 3);
        this.email = this.checkoutForm.value.email;
        this.tit = this.checkoutForm.value.titleReview;
        this.rev = this.checkoutForm.value.review;

        this.checkoutForm.reset();
        this.addReviews();
        this.enableDisableRule();
    }

    stars: number[] = [1, 2, 3, 4, 5];
    selectedValue: number = 0;


    countStar(star) {
        this.selectedValue = star;
    }

    gitRating() {
        this.rating = this.selectedValue;
        this.rate = this.rating;
        if (this.rating == 1) {
            this.rating = ' Terrible';
        }
        if (this.rating == 2) {
            this.rating = ' Poor';
        }
        if (this.rating == 3) {
            this.rating = ' Average';
        }
        if (this.rating == 4) {
            this.rating = 'Very Good';
        }
        if (this.rating == 5) {
            this.rating = 'Excellent';
        }
    }

    addClass(star) {
        let ab = '';
        for (let i = 0; i < star; i++) {
            ab = 'starId' + i;
            document.getElementById(ab).classList.add('selected');
        }
    }

    removeClass(star) {
        let ab = '';
        for (let i = star - 1; i >= this.selectedValue; i--) {
            ab = 'starId' + i;
            document.getElementById(ab).classList.remove('selected');
        }
    }

    addReviews() {
        const obj = {};
        obj['user_id'] = this.user_id;
        if (obj['first_name'] == null) {
            obj['first_name'] = this.first_name_dash;
        } else {
            obj['first_name'] = this.name[0];
        }

        if (this.name[1]) {
            obj['middle_name'] = this.name[1];
        }

        if (this.name[2]) {
            obj['last_name'] = this.name[2];
        }
        if (obj['email'] == null) {
            obj['email'] = this.email_log;
        } else {
            obj['email'] = this.email;
        }

        obj['rate'] = this.selectedValue;
        obj['title'] = this.tit;
        obj['review'] = this.rev;
        obj['date_of_visit'] = this.myDate;
        obj[this.keyId] = this.cityId;
        this.addReview.emit(obj)
    }

    validationMessages = {
        'email': [
            {type: 'required', message: 'Email is required'},
            {type: 'pattern', message: 'Enter a valid email'}
        ],
        'name': [
            {type: 'required', message: 'Name is required'},],
    }

    enableDisableRule() {
        this.toggle = !this.toggle;
        this.status = this.toggle ? 'Enable' : 'Disable';
    }
}
