import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../services/webApis/local-storage.service';
import { ApisService } from '../../../services/apis.service';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { PaymentDialogComponent } from 'src/app/pages/review-flight/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.css']
})
export class UpdatePaymentComponent implements OnInit {
  ticketData;
  ticketArray;
  paymentErrorMessages;
  pageParams
  errorDialog: any;
  openDocument: boolean;
  orderData: any;
  filghtData: any;
  user: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apis: ApisService,

  ) {
    this.pageParams = this.route.snapshot.params
  }
  ngOnInit(): void {
    this.getOrder()
  }


  verifyDocument() {
    this.openDocument = true
    this.openDocument = false
    this.openDocument = false
  }
  getOrder() {
    var token = localStorage.getItem('flyallover_token')
    this.apis.flightDetails(token, this.pageParams.id).subscribe({
      next: (res: any) => {
        this.orderData = res.data
        this.filghtData = JSON.parse(this.orderData.itinerary_details)
        console.log('filght ', this.filghtData);
        this.user = this.orderData?.family_members[0]?.family_member

      }
    })
  } handelName(name) {
    var nameUpdata = name != null ? name : ` `;
    return nameUpdata;
  }
  goToHome() {
    this.router.navigate(['/'])
  }
  goToDashbord() {
    this.router.navigate(['/profile/dashboard'])
  }
}
