<app-navbar></app-navbar>
<div class="covid-page">
    <div class="covid-header">
        <div class="cover">
            <div class="title">
                <h1>pcr test for travel near me</h1>
            </div>
            <div class="image">
              <img title="pcr test" alt="covid19"  src="../../../assets/images/covid19.png">
            </div>
        </div>
    </div>
    <div class="covid-body container">
        <h4>Find Testing Location In Your Country</h4>
        <h6 class="text-center info">
            <p>Find a testing center near you, learn how to get tested, and find key information about Locations to perform COVID-19 PCR TESTS.</p>
        </h6>
        <div class="country-search">
            <div class="row text-center">
                <div class="col-sm-8">
                    <input type="text" class="form-control" [ngbTypeahead]="countrySearch"
                           [(ngModel)]="countrySearchField" placement="bottom-left"
                        (click)="$event.target.select()" placeholder="Select Country" (selectItem)="chooseCountry($event)"/>
                </div>
                <div class="col-sm-4">
                    <button [routerLink]="['/pcr-time-slider-for-travel', modifiedCountryName]" [disabled]="(country !== countrySearchField) || !country" class="btn search-btn" >
                        Search
                    </button>
                </div>
                    <section>
                        <div class="col-lg-1 order-lg-first " style="margin-top: -2rem;">
                            <div class="sticky-content">
                                <div class="social-icons social-icons-colored social-icons-vertical">
                                    <div class="icon-share">
                                        <span class="social-label ">SHARE:</span>
                                        <a class="social-icon image-item" (click)="cop()"
                                           target="_blank">
                                            <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                                                 src="./assets/newIcon/copy-blue-icon.svg"/>
                                            <img *ngIf="copied" title="copied" class="copied" alt="copied"
                                                 src="./assets/newIcon/copied.png"/>
                                        </a>
                                        <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                                            <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                                          <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                                            <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                                          <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                                            <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
