import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-covid-popup',
  templateUrl: './covid-popup.component.html',
  styleUrls: ['./covid-popup.component.css']
})
export class CovidPopupComponent implements OnInit {
  @Input() closedStatus: any;
  @Input() search: any;
  @Output() closeCovidEvent = new EventEmitter();
  flights: any;

  constructor(public dialogRef: MatDialogRef<CovidPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.flights = this.data.search.flights;
  }

  close(): void {
    this.dialogRef.close();
  }

}
