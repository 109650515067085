<h1 class="header mt-5" translate> Featured Villas </h1>
<!-- ======= Events Section ======= -->
<div class="event">
  <ngb-carousel class="py-5" [interval]="9000">
    <ng-template ngbSlide  >
      <section id="events" class="events" >
        <div class="container" data-aos="fade-up">
          <div class="section-title mb-4">
            <h2 translate> Villas</h2>
            <!--          <p>{{item.name}}</p>-->
          </div>
          <div class="events-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div class="swiper-wrapper">

              <div class="swiper-slide">
                <div class="row event-item">
                  <div class="col-lg-6">
                    <img   src="../../../assets/images/hotels/villas/villa-1.jpg" class="handel-img" alt="">
                  </div>
                  <div class="col-lg-6 pt-4 pt-lg-0 content">
                    <div class="section-title">
                      <p> Villa Queen Nariman </p>
                    </div>
                    <div class="price mb-2 mt-4">
                      <p> 680 m2 </p>
                      <p> <i class="bi bi-people-fill mx-1" ></i> 8 (Max. 10) </p>
                      <p> <img src="assets/images/hotels/icon/double-bed.png" class="hndel-bed-img"> 4 </p>
                      <span></span>
                    </div>
                    <p class="fst-italic handel-des mt-2">
                      The elaborate and grand Villa Cleopatra is designed in the manner of the Temple Luxor.
                      The elaborate and grand Villa Cleopatra is designed in the manner of the Temple Luxor.
                      The elaborate and grand Villa Cleopatra is designed in the manner of the Temple Luxor.
                      The elaborate and grand Villa Cleopatra is designed in the manner of the Temple Luxor.

                    </p>

                    <div class="mt-4">
                      <a  class="book-a-table-btn scrollto "> More Details </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End testimonial item -->

            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div>
      </section>
    </ng-template>
  </ngb-carousel>
</div>
<!-- End Events Section -->
<div class="d-flex justify-content-center mt-4">
  <a style="text-decoration: none" routerLink="activities">
    <button  [routerLink]="['/villas-details']"class="projects-btn" translate> All Villas </button>
  </a>
</div>
