<form (submit)="formatFormData()" [formGroup]="searchForm" class="items" (click)="closePassengerList($event)">
  <!-- if any required field is missing -->
  <div *ngIf="submitErrorHotels" class="alert alert-danger fade show" role="alert">
    All fields are required, and Check In date should be larger than Check Out date
  </div>
  <div class="item">
    <label for="location">Location</label>
    <div class="field">
      <input formControlName="location" type="text" id="location" (selectItem)="selectItem($event)"
             [ngbTypeahead]="hotelsSearch" placement="bottom-left" (click)="$event.target.select()"/>
      <i class="fas fa-map-marker-alt"></i>
    </div>
  </div>

  <div class="item departure_return">
    <label for="date1">Date</label>
    <div class="">
      <div class=" mb-3">
        <div class="date">
          <input style="padding: 8px" formControlName="checkInDate" placeholder="Check-In" matInput (click)="departure.open()" readonly
                 [matDatepicker]="departure"
                 [min]="initialDate" [max]="searchForm.value.checkOutDate || null">
          <mat-datepicker #departure></mat-datepicker>
        </div>
      </div>

      <div class=" mb-3">
        <div class="date">
          <input style="padding: 8px" formControlName="checkOutDate" placeholder="Check-Out" (click)="return.open()" readonly class="check-out"
                 [matDatepicker]="return"
                 [min]="searchForm.value.checkInDate || initialDate" [attr.disabled]="!searchForm.value.checkInDate">
          <mat-datepicker #return></mat-datepicker>
        </div>
      </div>
    </div>
  </div>

  <div class="item">
    <label for="travellers">Travellers</label>
    <app-hotel-passengers-number class="passenger-list" (notify)="updatePassengerData()"
                                 [passengerData]="passengerData">
    </app-hotel-passengers-number>
  </div>

  <!-- <div class="item">
      <label for="property">Property</label>
      <select formControlName="property" id="property">
          <option value="1">1 Room</option>
          <option value="2">2 Room</option>
          <option value="3">3 Room</option>
      </select>
  </div> -->

  <!-- <div class="item">
      <input formControlName="businessTrip" type="checkbox" checked id="business_trip">
      <label for="business_trip">Business Trip</label>
  </div> -->

  <div class="item">
    <button type="submit" class="search ">SEARCH
      <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i>
    </button>

  </div>

</form>
