
import { AfterViewInit, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { Options } from 'ng5-slider';
@Component({
  selector: 'app-root',styles: [`
  agm-map {
    height: 300px;
  }
`],
template: `
<agm-map [latitude]="lat" [longitude]="lng"></agm-map>
`,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit{
  value: number = 100;
  lat = 51.678418;
  lng = 7.809007;
  title = 'NEW-Flyallover';
  static isBrowser = new BehaviorSubject<boolean>(null);
  static hostUrl: string = 'https://flyallover.com';
  loading:boolean = true;
  isBrowser: boolean;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    AppComponent.hostUrl =  this.getPath();
  }

  getPath() {
    return this.document.location.origin
  }
  ngAfterViewInit() {
    if (this.isBrowser) {
      setTimeout(()=>{
        let loader = this.renderer.selectRootElement('#loader')
        const body = document.getElementsByTagName("html");
        this.renderer.setStyle(loader, 'display', 'none')
        body[0].style.overflowY = 'scroll'
      },1500)
    }
  }
}

