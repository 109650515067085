import {Component, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {Router} from '@angular/router';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-help-disclaimer',
  templateUrl: './help-disclaimer.component.html',
  styleUrls: ['./help-disclaimer.component.css']
})
export class HelpDisclaimerComponent implements OnInit {

  disclaimers: any = {};
  loading = true;
  searchText = '';
  backendError = false;

  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.getDisclaimer();
  }

  seoUpdate() {

    this.seoService.updateTitle('Disclaimer | flyallover ');
    this.seoService.updateDescription('General Disclaimer from flyallover you must read it before you book your flights or hotels to know your rights and flyallover rights');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  getDisclaimer() {
    this.apis.getDisclaimer().subscribe(
      (res: any) => {
        this.disclaimers = res.data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.backendError = true;
      }
    );
  }

  onSearch() {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: this.searchText
      }
    });
  }

}
