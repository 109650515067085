import {Component, inject, Inject, OnChanges, OnInit, PLATFORM_ID, SimpleChanges} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ApisService} from "src/app/services/apis.service";
import {Router} from "@angular/router";
import {isPlatformBrowser, Location} from "@angular/common";
import {SEOService} from "../../services/seo.service";
import {AppComponent} from "../../app.component";
import {DOCUMENT} from "@angular/common";
import {map} from "rxjs/operators";
import {OwlOptions} from "ngx-owl-carousel-o";
import {Clipboard} from "@angular/cdk/clipboard";
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
    selector: "app-airline-description",
    templateUrl: "./airline-description.component.html",
    styleUrls: ["./airline-description.component.css"],
})
export class AirlineDescriptionComponent implements OnInit , OnChanges{
    data: any[] = [];
    airlineName: any;
    airlineInfo: any;
    about: any;
    images: any = [];
    aboutAirline: any;
    showAll: boolean = true;

    linkToBeShared: string;
    fragment: string;
    moreHeaders: boolean = false;
    hostUrl: string;
    routerUrl: string;
    copied = false;

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        navSpeed: 700,
        nav: true,
        navText: [
            '<i class="fas fa-arrow-left"></i>',
            '<i class="fas fa-arrow-right"></i>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            740: {
                items: 3,
            },
            940: {
                items: 3,
            },
        },
    };
    display = true;
    airlineId: number;
    reviewData: any = [];
    showRev = false;
    total_review: number;
    star5: number;
    star4: number;
    star3: number;
    star2: number;
    star1: number;
    averageReview: number;
    metaTitle: any;
    metaDescription: string;
    metaImage: any;

    constructor(
        private apis: ApisService,
        public activatedRoute: ActivatedRoute,
        location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private seoService: SEOService,
        private formBuilder: FormBuilder,
        private clipboard: Clipboard,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private _plateform: object
    ) {
        this.seoUpdate();
        // this.airlineName = this.activatedRoute.snapshot.paramMap.get("name");
        // if(isPlatformBrowser(this._plateform)){
        // }
        this.getAirlineInfo();
        this.getAirLines();
        // router.events.subscribe((val) => {
        //   this.airlineInfo = null;
        // });
    }

    ngOnInit(): void {
        // const hostUrl = AppComponent.hostUrl;
        // this.hostUrl = hostUrl;
        // this.routerUrl = this.router.url;
        // this.route.fragment.subscribe((fragment: string) => {
        //   this.fragment = fragment;
        // });
        // this.route.paramMap.subscribe((params) => {
        //   this.linkToBeShared = `${hostUrl}${this.routerUrl}`;
        // });
        // this.airlineInfo = null;
        // this.aboutAirline = null;
        // this.about = null;
        // this.airlineName = this.activatedRoute.snapshot.paramMap.get("name");
    }

    getAirLines() {
        this.apis.getHomeAirLines().subscribe(
            (data: any) => {
                this.data = data.data;
                // this.airlineId = data.data[0].id;
                // this.metaTitle = this.data[0].name;
                // this.metaDescription = this.data[0].meta_description;
                // this.metaImage = this.data[0].main_image;
                // this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);

            },
            (err) => {
                console.log(err);
            }
        );
    }

    getAirlineInfo() {
        this.activatedRoute.params
            .pipe(map((prams) => prams.name))
            .subscribe((name) => {
                // console.log(name, "xxxxxxxxxxxxxxxxxxxxxx");
                this.apis.getAirLineByCallsign(name).subscribe(
                    (data: any) => {
                        this.about = data.data.about;
                        this.airlineInfo = data.data;
                        this.airlineId = this.airlineInfo.id;
                        for (let i = 0; i < this.airlineInfo.images.length; i++) {
                            this.images.push(this.airlineInfo.images[i].full_url);
                        }
                        console.log(this.airlineInfo , ' airlineInfo ');

                        this.metaTitle = this.airlineInfo.name;
                        this.metaDescription = this.airlineInfo.meta_description;
                        this.metaImage = this.airlineInfo.main_image;
                        this.getReviews();
                        this.seoUpdate(
                            this.metaTitle,
                            this.metaDescription,
                            this.metaImage
                        );
                        // console.log(this.airlineId);
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            });
    }

    changeFlag() {
        this.showAll = !this.showAll;
    }

    checkoutForm = this.formBuilder.group({
        name: '',
        email: new FormControl('', Validators.compose([
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
        ])),
        titleReview: '',
        review: ''
    });


    getReviews() {
        this.apis.getAirlineReviews(this.airlineId).subscribe(
            (res: any) => {
                this.reviewData = res.data;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    showReviews() {
        this.showRev = !this.showRev;
    }

    //
    seoUpdate(
        title = null,
        description = null,
        image = null,
        url = "https://api.flyallover.com/storage/"
    ) {
        // this.seoService.updateTitle(this.metaTitle);
        // this.seoService.updateDescription(this.metaDescription);
        // this.seoService.updateUrl();
        // this.seoService.updateImage(this.metaImage);

        if (title != null) {
            this.seoService.updateTitle(title);
        }
        if (description != null) {
            this.seoService.updateDescription(description);
        }
        this.seoService.updateUrl();
        if (image != null) {
            this.seoService.updateImage(image);
        }
    }

    cop() {
        this.clipboard.copy('https://www.flyallover.com' + this.router.url)
        this.copied = !this.copied;
        setTimeout(() => {
            this.copied = false;
        }, 1500)
    }

    fb() {
        let url = "www.google.com";

        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "flyallover.com" +
            this.router.url,
            "facebook-popup",
            "height=350,width=600"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            '-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    setReview(event:any){
        this.apis.addAirlineReviews(event).subscribe((data: any) => {
                this.data = data.data;
                this.getReviews();
            },
            err => {
                console.log(err);
            });

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getReviews() ;
        // console.log(this.getReviews())
    }
}
