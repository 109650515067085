import {Component, EventEmitter, Input, Output, OnChanges, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ApisService} from '../../../services/apis.service';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-airline-preference-input',
  templateUrl: './airline-preference-input.component.html',
  styleUrls: ['./airline-preference-input.component.css']
})

export class AirlinePreferenceInputComponent implements OnInit, OnChanges {
  @Output() setAirLineEvent = new EventEmitter();
  @Input() airline;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  carriers: any;
  isFocused = false;
  newValue: any;

  constructor(
    private apis: ApisService,
  ) {
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const filteredValue = this.carriers.filter(option => option.name.toLowerCase().includes(filterValue));
    if (filteredValue && filteredValue.length == 1) {
      this.setAirLineEvent.emit(filteredValue[0].iata);
    } else {
      this.setAirLineEvent.emit(undefined);
    }
    return filteredValue;
  }

  ngOnInit(): void {
    this.apis.carriers().subscribe((data: any) => {
      this.carriers = data.data;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      this.oldData();
    }, err => {
      console.log(err);
    });
  }

  ngOnChanges(changes) {
    if (changes.airline.currentValue) {
      this.newValue = changes.airline.currentValue;
      this.oldData();
    }
  }

  oldData() {
    if (this.carriers && this.newValue) {
      const result = this.carriers.filter(obj => {
        return obj.iata == this.newValue;
      });
      if (result && result.length > 0) {
        setTimeout(() => {
          this.isFocused = true;
          this.myControl.setValue(result[0].name);
        }, 1);
      }
    }
  }

  onFocus(): void {
    if (this.myControl.value && this.myControl.value.length > 0) {
      this.isFocused = true;
    } else {
      this.isFocused = !this.isFocused;
    }
  }
}
