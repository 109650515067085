<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 col-xxl-3 col-lg-5">
      <app-dash-sidebar></app-dash-sidebar>
    </div>
    <div class="col-xl-8 col-xxl-9 col-lg-7">
      <div class="cards-ticket" *ngIf="pagedItems">
        <div style="padding-bottom: 17px" class="" *ngFor="let item of pagedItems" (click)="click(item.id)"
          (click)="details = !details; flightDetails(item.id)">
          <div class="ticket-body-view">
            <div class="d-flex">
              <div class="p-0">
                <img src="../../../../assets/images/arrow/logo_sign_shadow.png" alt="logo" width="25px"
                  class="logo-ticket-pnr">
              </div>

              <div class=" ticket-header-view ">

                <p class="Font-Family fontHandel "><span class="mr-1"
                    *ngIf="item?.departure_date">{{item?.departure_date| date:"dd MMM yyy"}}</span> <img
                    *ngIf="item?.return_date" src="assets/images/arrow/Polygon.png" class="mx-2"> <span class="mx-1"
                    *ngIf="item?.return_date">{{item?.return_date| date:"dd MMM yyy"}}</span>
                </p>
                <p class=" Font-Family trip-part " *ngIf="item?.origin_city"><small
                    class=" font-ticket-lv-3 col-4 p-0">TRIP FROM</small> <span
                    class="text2Handel col-8 p-0">{{item?.origin_city.name}}
                    , {{item?.origin_city.country.code}} </span>
                </p>
                <p class=" Font-Family trip-part" *ngIf="item?.destination_city"><small
                    class=" font-ticket-lv-3 col-4 p-0">TRIP TO</small> <span
                    class="text2Handel col-8 p-0">{{item?.destination_city.name}}
                    , {{item?.destination_city.country.code}}</span>
                </p>
                <div class="hr-part"></div>
                <div class="">
                  <p class="font-ticket-lv-3 Font-Family"><small> RESERVATION CODE</small></p>
                  <span class="font-ticket-lv-1 Font-Family-bold" *ngIf="item.pnr">{{item.pnr}}</span>
                  <div class="">
                    <p class="font-ticket-lv-3 " *ngIf="item.trip_class"> Cabin <small
                        class="text2Handel">{{item.trip_class}} </small></p>
                    <p class="font-ticket-lv-3  d-none" *ngIf="item?.status"> Status<small class="text2Handel">{{
                        item?.status}} </small></p>

                  </div>
                  <div class="min-part">

                    <p class=" Font-Family trip-part" *ngIf="item?.id"><small
                        class=" font-ticket-lv-3 col-4 p-0 pe-2">FLIGHT NO</small> <span
                        class="text2Handel  p-0">{{item?.id}} </span>
                    </p>
                    <p class=" Font-Family trip-part" *ngIf="item?.fare_class"><small
                        class=" font-ticket-lv-3 col-4 p-0 pe-2">CLASS</small> <span
                        class="text2Handel  p-0">{{item?.fare_class}} </span>
                    </p>
                  </div>
                  <div class="min-part">

                    <p class=" Font-Family trip-part" *ngIf="item?.trip_type"><small
                        class=" font-ticket-lv-3 col-4 p-0 pe-2">TRIP TYPE </small> <span
                        class="text2Handel  p-0">{{item?.trip_type}}</span>
                    </p>
                    <p class=" Font-Family trip-part" *ngIf="item?.departure_date"><small
                        class=" font-ticket-lv-3 col-4 p-0 pe-2">TIME</small> <span
                        class="text2Handel  p-0">{{item?.departure_date|date :'HH:mm a'}} </span>
                    </p>
                  </div>
                  <div class="hr-part"></div>

                </div>
              </div>
            </div>
            <div class="up-title">PREPARED FOR</div>
            <div class="down-title">

              <span *ngIf="item.user?.gender=='M'">MR</span>
              <span *ngIf="item.user.gender=='F'">MRS</span>
              {{handelName(item.user?.last_name) + ' / ' + handelName(item.user?.first_name) + ' ' + handelName(item.user?.middle_name) + ' '}}
            </div>
            <div class="btn-ticket" *ngIf="item.order_status=='requier_documents'">
              <span class="btn-name">Verify Document</span>
            </div>
          </div>
        </div>
      </div>
      <ngb-pagination *ngIf="pagedItems.length>1" class="d-flex justify-content-center"
        [collectionSize]="flights?.length" [pageSize]="pageSize" (pageChange)="pageChanged($event)"
        [page]="currentPage">
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>

      <!-- <div class=" itinerary" *ngIf="details">
        <div class="row flights-summary mx-0 flightDataSection " >
          <div class="col-md-12">
            <div class="col-md-12 custom-mobile-view">
              <div class="row">
                <div class="covid_policy" (click)="closedStatus= false;">
                  <span><img src="/assets/images/icons_flights/protection%20(1).svg" alt="protection"
                             title="COVID-19 policies"></span>
                  <span>COVID-19 policies  </span>
                  <span><img src="/assets/images/icons_flights/arrow-point-to-right.svg" title="COVID-19 policies"
                             width="20px"
                             height="15px"
                             alt="arrow-point-to-right"></span>
                </div>
              </div>
            </div>
            <div class="flight_headers mb-3" style="display: flex;justify-content: space-between">
              <div style="display: flex; align-items: center">
                <div style="margin-right: 25px" class="covid_policy" (click)="closedStatus= false;">
                  <span><img src="/assets/images/icons_flights/protection%20(1).svg" title="COVID-19 policies"
                             alt="protection"></span>
                  <span>COVID-19 policies  </span>
                  <span><img src="/assets/images/icons_flights/arrow-point-to-right.svg" title="COVID-19 policies"
                             width="20px"
                             height="15px"
                             alt="arrow-point-to-right"></span>
                </div>
                <div class="icons_services">
                  <div class="inner_icons">
                    <span><img src="/assets/images/icons_flights/Path_1262.svg" alt="path"
                               title="icons_services"></span>
                    <span><img src="/assets/images/icons_flights/Group_13924.svg" title="icons_services"
                               alt="Group"></span>
                    <span><img src="/assets/images/icons_flights/Group_13936.svg" title="icons_services"
                               alt="Group"></span>
                    <span><img src="/assets/images/icons_flights/Icon_material-live-tv.svg" title="icons_services"
                               alt="Icon_material-live-tv"></span>
                    <span><img src="/assets/images/icons_flights/Path_42007.svg" title="icons_services"
                               alt="path"></span>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center">
                  <h3 class="flightPrice"
                      style="margin: 10px;color: #fd5021">{{itinerary.BaseFare.Amount + itinerary.taxfees.Amount |
                    currency}}</h3>
                  <span class="color-148181" style="font-size: 19px">{{itinerary.prices.FareClassName}}</span>
                </div>
                <div class="d-flex justify-content-end taxesSection">
                  <span>{{itinerary.BaseFare.Amount | currency}}</span>
                  <span>+</span>
                  <span>{{itinerary.taxfees.Amount| currency}}</span>
                  <span>(Taxes)</span>
                </div>
              </div>
            </div>
            <div style="padding: 8px;display: flex;justify-content: space-between; flex-direction: row"
                 class="position-relative">
              <div class="items-container" [ngClass]="{'full-width': flightData.trip_type == 'OneWay'}"
                   *ngFor="let flight of flightData.flight_details; let i = index">
                <div style="width: 100%;display: flex; justify-content: space-between">
                  <div>
                    <span class="color-148181 font-weight-600" *ngIf="i == 0">Depart Flight</span>
                    <span class="color-148181 font-weight-600" *ngIf="i == 1">Return Flight</span>
                  </div>
                  <div>
                    <span class="color-007bff" style="margin-right: 5px">{{flightData.baggage_info[0].length}}</span>
                    <span class="color-007bff" style="font-size: 14px">Free Bags</span>
                  </div>
                </div>
                <div class="items" style="display: flex;flex-direction: column">
                  <div style="display: flex;align-items: center;flex-direction: column">
                    <img class="airCompanyLogo" alt="airCompanyLogo" title="Operating Airline Image"
                         src="https://c.fareportal.com/n/common/air/3x/{{flight[0].OperatingAirline}}.png">
                    <p style="margin-top: 8px;margin-bottom: 0"
                       class="airline-name">{{flight[0].OperatingAirlineName}}</p>
                    <div class="handelAirCraft">
                                  <span
                                    style="font-size: 10px;text-align: left;">Flight: {{flight[0].FlightNumber}} | Aircraft: {{flight[0].AircraftName}}</span>
                    </div>
                  </div>
                  <div class="handleRes">
                    <div class="item item-date-time first-date">
                            <span class="airport"
                                  style="text-align: right;font-weight: bold">{{flightData.origin}}</span>
                      <p class="item-date">{{flight[0].DepartureDateTime | date: 'dd-MMM-yyyy'}}</p>
                      <p class="time">{{flight[0].DepartureDateTime| date: 'HH:mm'}}</p>
                    </div>
                    <div class="item stops" (click)="hideShowStops($event)">
                      <span style="font-size: 12px">{{calcTotalFlightTime(flight)}}</span>
                      <span class="stops-line">
                                <i *ngIf="flight?.length - 1>0" class="fas fa-circle"></i>
                            </span>
                      <p class="time-stop color_red" *ngIf="flight?.length - 1>0">{{flight?.length - 1}} Stop</p>
                    </div>
                    <div class="item item-date-time second-date">
                            <span class="airport second-airport"
                                  style="text-align: left;display: flex;font-weight: bold">{{flight[flight.length - 1].ArrivalAirport}}</span>
                      <p class="item-date">{{flight[flight.length-1].ArrivalDateTime | date: 'dd-MMM-yyyy'}}</p>
                      <p class="time">{{flight[flight.length-1].ArrivalDateTime| date: 'HH:mm'}}</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="flight?.length - 1>0" class="stops-tooltip hide p-2">
                  <ng-container *ngFor="let trip of flight;  let flightI = index; last as isLast">
                    <div *ngIf="!isLast" class="handleStopsTooltip">
                      <ng-container *ngIf="flight[0] && flight[1] && !flight[2]">
                        <span class="stops-title">Arriving in</span>
                        <span>{{flight[0].ArrivalAirportName}} At {{trip.ArrivalDateTime | date: 'h:mm a'}}</span>
                        <span class="layover">({{convertTime(flight[1].FlightLayoverTime)}}) Layover Time</span>
                        <span class="stops-title">Departing from</span>
                        <span> {{flight[1].DepartureAirportName}} At {{flight[1].DepartureDateTime | date: 'h:mm a'}}</span>
                      </ng-container>
                      <ng-container *ngIf="flight[1] && flight[2]">
                        <span class="stops-title">Arriving in</span>
                        <span> {{flight[1].ArrivalAirportName}} At {{trip.ArrivalDateTime | date: 'h:mm a'}}</span>
                        <span class="layover">({{convertTime(flight[2].FlightLayoverTime)}}) Layover Time</span>
                        <span class="stops-title">Departing from</span>
                        <span>{{flight[2].DepartureAirportName}} At {{flight[2].DepartureDateTime | date: 'h:mm a'}}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="itinerary.flights.length > 1">
                  <div class="directions d-flex flex-column">
                    <i class="fas fa-arrow-right"></i>
                    <i class="fas fa-arrow-left"></i>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 p-3">
            <div class="bottom d-flex justify-content-end">
              <button (click)="toggleFlightDetails()" class="btn flightDetailsBtn">
                Flight Details
              </button>
            </div>
          </div>
          <div class="flightDetails flightNumber hide">
            <div class="container">
              <app-flight-details-drop-down [itinerary]=itinerary></app-flight-details-drop-down>
            </div>
          </div>
          <div class="col-12">
            <div class="travellers-sec">
              <div class="d-flex color-181F22 font-size-28-regular justify-content-between mb-16">
                <div>Flight PNR :</div>
                <div class="pnr">{{flightData.pnr}}</div>
              </div>
            </div>
            <div class="travellers-sec" *ngIf="flightData.family_members != 0">
              <div class="d-flex justify-content-center font-size-38-500 color-464646 mb-30">
                {{flightData.family_members.length}} Travellers
              </div>
              <div *ngFor="let traveller of flightData.family_members; let i = index">
                <div class="d-flex color-181F22 font-size-20 justify-content-between align-items-center mb-16 mt-2">
                  <div>{{i+1}}</div>
                  <img *ngIf="traveller?.family_member?.image" class="member-img" alt="member" title="family member"
                       src="{{traveller?.family_member?.image}}">
                  <img *ngIf="!traveller?.family_member?.image" class="member-img" alt="member" title="family member"
                       src="../../../../assets/newIcon/user-profile.svg">
                  <div class="col-6">{{traveller.family_member?.first_name + ' ' + traveller.family_member?.middle_name +
                    ' ' + traveller.family_member?.last_name }}
                  </div>
                  <div class="col-2">{{traveller.family_member?.family_relation.name}}</div>
                  <!--<div class="col-2">{{traveller.family_member.passport.passport_number}}</div>-->
      <!--    </div>
                <div class="hr border-bottom"></div>
              </div>
            </div>
            <div class="total-price text-center font-size-38-500 mb-38 mt-39">
              <span class="font-size-38-500 ">Total Charge(usd)</span>
              <span class="color-FE5448">{{itinerary.BaseFare.Amount + itinerary.taxfees.Amount | currency}}</span>
            </div>
            <div class="col-w-100">
              <hr>
            </div>
            <div class="font-size-24-500 mb-39 mt-39 text-center">
              <h4>You will pay in the local currency of your country 4536 eg
              </h4>
            </div>
            <div class="col-w-100">
              <hr>
            </div>
            <div class="d-flex flex-column price-details">
              <div class="d-flex justify-content-between mb-40">
                <span class="font-size-28-medium color-000000">Total Price Details:</span>
                <span class="color-29ABE0 font-size-28-medium">{{ itinerary.BaseFare.Amount + itinerary.taxfees.Amount  | currency}}</span>
              </div>
              <div class="d-flex justify-content-between mb-40">
                <span class="font-size-20 font-weight-500">Fare Price :</span>
                <span class="font-size-20 font-weight-500">{{itinerary.BaseFare.Amount | currency}}</span>
              </div>
              <div class="d-flex justify-content-between mb-40">
                <span class="font-size-20 font-weight-500">Tax (14%) is not included</span>
                <span class="font-size-20 font-weight-500">{{itinerary.taxfees.Amount| currency}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>  -->
      <div class="recommended" *ngIf="flights == 0">
        <h2 class="text-center">We Recommend you try these places</h2>
        <div class="recommended-section">
          <div class="recommended-box" *ngFor="let place of recommendedPlaces;">
            <div class="head-img">{{place.origin_city}}</div>
            <img src="{{place.image}}" [alt]="place.title" title="recommnded place">
            <p>{{place.title}}</p>
            <span>{{place.offer_name}}</span>
            <span> Popular with travellers in {{place.origin}}, {{place.origin_city}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
