<input class="form-control d-flex pointer" readonly [value]="returnDate | date: 'MMM d'" (click)="openDesktopCalender()"
  (focus)="onFocus()" (blur)="onFocus()">
<ng-container>
  <div class="placeHolder element pointer" (click)="openDesktopCalender()">
    <div *ngIf="!isFocused && !returnDate">
      <span class="star">*</span>
      <span>Check-Out</span>
    </div>
    <div>
      <img title="Check-Out" style="width: 24px;height: 24px;" alt="location" src="/assets/images/icons/calendar.svg">
    </div>
  </div>
</ng-container>
