<div class="container">
  <div class="row g-0" style="margin-bottom: 32px;">
    <!-- /////////////////////// -->
    <div class="col-12 pt-5 hotels">
      <h1>Hotels</h1>
      <div
        style="
          background-color: #000000;
          height: 3px;
          width: 90px;
          margin: auto;
          margin-bottom: 8px;
        "
      ></div>
    </div>
    <!-- /////////////////////// -->
    <div class="col-sm-12 col-md-6 col-lg-3  hotelDiv">
     <div class="internal">
      <div class="hotelImg">
        <a href="/hotel/100158860">
          <img
            src="../assets/images/hotels/hotels/7.jpg"
            width="100%"
            height="250"
            alt="Hilton Cairo Zamalek Residences"
            title="Hilton Cairo Zamalek Residences"
          />
        </a>

        <div class="hotelPrice">
          <small class="nightNum">1 night, 2 adults</small>
          <h2>$ 95.00</h2>
        </div>
      </div>
      <div class="hotelInfo">
        <span class="hotelName">Hilton Cairo Zamalek Residences </span>
        <div _ngcontent-serverapp-c348="" class="rate rate4">
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>

          <i _ngcontent-serverapp-c348="" class="fas fa-star blacStar"></i>
        </div>
        <a
          href="https://www.google.com/maps/place/30%C2%B004'03.4%22N+31%C2%B013'20.5%22E/@30.0676,31.2245487,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x140d139fbd32173d!8m2!3d30.0676!4d31.22236"
          ><p class="hotelLocation">Cairo City - Show Map</p></a
        >
        <div class="flexDiv">
          <svg
            aria-hidden="true"
            class="bk-icon -iconset-checkmark_bold"
            fill="#006607"
            height="40"
            width="40"
            viewBox="0 0 128 128"
            role="presentation"
            focusable="false"
          >
            <path
              d="M52 102a8 8 0 0 1-5.7-2.3l-28-28a8 8 0 0 1 11.4-11.4L52 82.7l46.3-46.4a8 8 0 0 1 11.4 11.4l-52 52A8 8 0 0 1 52 102z"
            ></path>
          </svg>
          <p class="hotelDesc">
            Lounges/bars | Non-smoking | Smoking rooms available | Family Room |
            Smoke-free property
          </p>
        </div>
        <div class="flexDiv spac">
          <p class="hotelRate">8.7 Excellent .</p>
          <span class="hotelReview"> 121 reviews </span>
        </div>
      </div>
     </div>
    </div>
    <!-- /////////////////////// -->
    <div class="col-sm-12 col-md-6 col-lg-3  hotelDiv">
     <div class="internal">
      <div class="hotelImg">
        <a href="/hotel/100055096">
          <img
            src="../assets/images/hotels/hotels/8.jpg"
            width="100%"
            height="250"
            alt="Le Meridien Cairo Airport"
            title="Le Meridien Cairo Airport"
          />
        </a>

        <div class="hotelPrice">
          <small class="nightNum">1 night, 2 adults</small>
          <h2>$108.00</h2>
        </div>
      </div>
      <div class="hotelInfo">
        <span class="hotelName">Le Meridien Cairo Airport </span>
        <div _ngcontent-serverapp-c348="" class="rate rate4">
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>

          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
        </div>
        <a
          href="https://www.google.com/maps/place/30%C2%B006'40.1%22N+31%C2%B023'48.4%22E/@30.111127,31.3989687,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x245a25bf06149c68!8m2!3d30.111127!4d31.39678"
          ><p class="hotelLocation">Cairo City - Show Map</p></a
        >
        <div class="flexDiv">
          <svg
            aria-hidden="true"
            class="bk-icon -iconset-checkmark_bold"
            fill="#006607"
            height="40"
            width="40"
            viewBox="0 0 128 128"
            role="presentation"
            focusable="false"
          >
            <path
              d="M52 102a8 8 0 0 1-5.7-2.3l-28-28a8 8 0 0 1 11.4-11.4L52 82.7l46.3-46.4a8 8 0 0 1 11.4 11.4l-52 52A8 8 0 0 1 52 102z"
            ></path>
          </svg>
          <p class="hotelDesc">
            Non-smoking rooms (generic) | Rollaway adult | Crib charge | Extra
            person | Conference facilities
          </p>
        </div>
        <div class="flexDiv spac">
          <p class="hotelRate">9.2 Excellent .</p>
          <span class="hotelReview"> 143 reviews </span>
        </div>
      </div>
     </div>
    </div>
    <!-- /////////////////////// -->
    <div class="col-sm-12 col-md-6 col-lg-3  hotelDiv">
     <div class="internal">
      <div class="hotelImg">
        <a href="/hotel/100430948">
          <img
            src="../assets/images/hotels/hotels/9.jpg"
            width="100%"
            height="250"
            alt="Holiday Inn Cairo City Stars"
            title="Holiday Inn Cairo City Stars"
          />
        </a>

        <div class="hotelPrice">
          <small class="nightNum">1 night, 2 adults</small>
          <h2>$111.00</h2>
        </div>
      </div>
      <div class="hotelInfo">
        <span class="hotelName">Holiday Inn Cairo City Stars</span>
        <div _ngcontent-serverapp-c348="" class="rate rate4">
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
        </div>
        <a
          href="https://www.google.com/maps/place/30%C2%B004'28.2%22N+31%C2%B020'37.6%22E/@30.07449,31.3459787,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x3bf8391b572e923!8m2!3d30.07449!4d31.34379"
          ><p class="hotelLocation">Cairo City - Show Map</p></a
        >
        <div class="flexDiv">
          <svg
            aria-hidden="true"
            class="bk-icon -iconset-checkmark_bold"
            fill="#006607"
            height="38"
            width="38"
            viewBox="0 0 128 128"
            role="presentation"
            focusable="false"
          >
            <path
              d="M52 102a8 8 0 0 1-5.7-2.3l-28-28a8 8 0 0 1 11.4-11.4L52 82.7l46.3-46.4a8 8 0 0 1 11.4 11.4l-52 52A8 8 0 0 1 52 102z"
            ></path>
          </svg>
          <p class="hotelDesc">
            Car rental desk | Extra person | Tennis court | Conference
            facilities| Smoking rooms available
          </p>
        </div>
        <div class="flexDiv spac">
          <p class="hotelRate">9.1 Excellent .</p>
          <span class="hotelReview"> 131 reviews </span>
        </div>
      </div>
     </div>
    </div>
    <!-- /////////////////////// -->
    <div class="col-sm-12 col-md-6 col-lg-3   hotelDiv">
      <div class="internal">
        <div class="hotelImg">
          <a href="/hotel/100141305">
            <img
              src="../assets/images/hotels/hotels/10.jpg"
              width="100%"
              height="250"
              alt="The St. Regis Almasa Hotel, Cairo"
              title="The St. Regis Almasa Hotel, Cairo"
            />
          </a>

          <div class="hotelPrice">
            <small class="nightNum">1 night, 2 adults</small>
            <h2>$135.00</h2>
          </div>
        </div>
        <div class="hotelInfo">
          <span class="hotelName">The St. Regis Almasa Hotel, Cairo </span>
          <div _ngcontent-serverapp-c348="" class="rate rate4">
            <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
            <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
            <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
            <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>

            <i _ngcontent-serverapp-c348="" class="fas fa-star yelStar"></i>
          </div>
          <a
            href="https://www.google.com/maps/place/30%C2%B004'46.2%22N+31%C2%B026'07.2%22E/@30.07951,31.4375187,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x476cf1287fee2f3c!8m2!3d30.07951!4d31.43533"
            ><p class="hotelLocation">Cairo City - Show Map</p></a
          >
          <div class="flexDiv">
            <svg
              aria-hidden="true"
              class="bk-icon -iconset-checkmark_bold"
              fill="#006607"
              height="40"
              width="40"
              viewBox="0 0 128 128"
              role="presentation"
              focusable="false"
            >
              <path
                d="M52 102a8 8 0 0 1-5.7-2.3l-28-28a8 8 0 0 1 11.4-11.4L52 82.7l46.3-46.4a8 8 0 0 1 11.4 11.4l-52 52A8 8 0 0 1 52 102z"
              ></path>
            </svg>
            <p class="hotelDesc">
              Non-smoking rooms (generic) | Crib charge | Extra person | Tennis
              court | Conference facilities | Spa
            </p>
          </div>
          <div class="flexDiv spac">
            <p class="hotelRate">9.5 Excellent .</p>
            <span class="hotelReview"> 148 reviews </span>
          </div>
        </div>
      </div>
  </div>
</div>
<div class="col-12 d-flex justify-content-center ">
  <a href="/hotels" class="show-more"
    ><h2>Search On Hotels</h2>
    <i
      style="margin-top: 15px"
      aria-hidden="true"
      class="fa-fw fas fa-arrow-right"
    ></i
  ></a>
</div>
