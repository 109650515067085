import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {Router, ActivatedRoute} from '@angular/router';
import {AppComponent} from '../../app.component';
import {SEOService} from '../../services/seo.service';
import {DatePipe, DOCUMENT} from '@angular/common';
import {OwlOptions} from "ngx-owl-carousel-o";
import {LocalStorageService} from "../../services/webApis/local-storage.service";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
    selector: 'app-page-offers',
    templateUrl: './page-offers.component.html',
    styleUrls: ['./page-offers.component.css']
})
export class PageOffersComponent implements OnInit {
    data: any = [];
    image_slider = [];
    about: any;
    slug: any;
    dataObj: any;
    date = '2018-07-16 18:00:00';
    time = '18:00:00';
    justHour: any;
    pageNumber: number;
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        navSpeed: 700,
        nav: true,
        navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },

    };
    display = true;
    flightOfferId: number;
    reviewData: any;
    showRev = false;
    copied = false;
    metaTitle: string;

    constructor(
        private apis: ApisService,
        public activatedRoute: ActivatedRoute,
        private seoService: SEOService,
        private router: Router, private route: ActivatedRoute,
        private datePipe: DatePipe,
        private _LocalStorageService: LocalStorageService,
        private clipboard: Clipboard,
    ) {
        this.getOfferDetails();
    }


    ngOnInit(): void {
        this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
        this.pageNumber = +this.activatedRoute.snapshot.paramMap.get('pageNumber');
        this.getOffers();
        this.getOfferDetails();
        this.initRequest();
       /* this.appendToRoute();*/

    }

    getOffers() {
        this.apis.getOffersPagination(this.pageNumber - 1).subscribe((data: any) => {
                this.data = data.data.data;
                // this.seoUpdate(this.metaTitle, this.dataObj.meta_description, this.dataObj.images[0].full_url);
            },
            err => {
                console.log(err);
            });
    }

    getOfferDetails() {
        this.apis.offersDetails(this.slug).subscribe((data: any) => {
                this.dataObj = data.data;
                this.flightOfferId = data.data.id
                this.metaTitle = 'Deal of the day From '+this.dataObj?.airport_origin.name+ ' To ' +this.dataObj?.airport_destination?.name + ' At ' + this.datePipe.transform(this.dataObj.departure_date, 'MM/dd/yyyy') + ' To ' + this.datePipe.transform(this.dataObj?.return_date, 'MM/dd/yyyy') + ' Price $' + this.dataObj.price;
                this.seoUpdate(this.metaTitle, this.dataObj?.offer_description, this.dataObj?.images[0].full_url );
                this.getReviews(data.data.id)
                // console.log(this.dataObj?.offer_description,'sesdes')
                // console.log(this.dataObj?.images[0].full_url,'image')
            },
            err => {
                console.log(err);
            });
    }

    seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
        this.seoService.updateUrl();
        this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

        if (title != null) {
            this.seoService.updateTitle(title);
        }
        if (description != null) {
            this.seoService.updateDescription(description);
        }
        if (image != null) {
            this.seoService.updateImage(image);
        }
    }

    getReviews(id) {
        this.apis.getOfferReviews(id).subscribe((data: any) => {
                this.reviewData = data.data;
            },
            err => {
                console.log(err);

            });
    }

    setReview(event: any){
        this.apis.addOfferRviews(event).subscribe((data:any)=>{
           this.date=data.data;
           this.getReviews(this.flightOfferId)

        })
    }
    refresh(slug) {
        this.slug = slug;
        this.getOffers();
        if (typeof document !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }


    initRequest()  {
        if (this.dataObj) {
            this.dataObj.airport_destination.name.toString();
            return {
                adult: 1,
                child: 0,
                class: this.dataObj.class.charAt(0).toUpperCase() + this.dataObj.class.slice(1),
                departure_date: this.datePipe.transform(this.dataObj.departure_date, 'yyyy-MM-dd'),
                fullDestination: this.dataObj.airport_destination.code + ' - ' + this.dataObj.airport_destination.name + ',' + this.dataObj.airport_destination.city_name + ', ' + this.dataObj.airport_destination.country_code,
                fullOrigin: this.dataObj.airport_origin.code + ' - ' + this.dataObj.airport_origin.name + ',' + this.dataObj.airport_origin.city_name + ', ' + this.dataObj.airport_origin.country_code,
                trip_type: 'Return',
                return_date: this.datePipe.transform(this.dataObj.return_date, 'yyyy-MM-dd'),
                origin: this.dataObj.airport_origin.code,
                destination: this.dataObj.airport_destination.code,
                travellers: this.dataObj.travellers,
                stops: 1,
                lap: 0,
                seat: 0,
                offer_id: this.dataObj.id,
            };
        }


    }

 /*   appendToRoute() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: this.initRequest(),
            queryParamsHandling: 'merge',
            skipLocationChange: true
        }).then(() => {
            this.applyModificationEvent.emit(this.route.snapshot.queryParams);
        });
    }*/

    submit() {
        // this.appendToRoute();
        if (typeof document !== 'undefined') {
            localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
        }
        this.router.navigate(['flights'], {queryParams: this.initRequest()});
    }


    showReviews() {
        this.showRev = !this.showRev;
    }

    cop() {
        this.clipboard.copy('https://www.flyallover.com' + this.router.url)
        this.copied = !this.copied;
        setTimeout(()=>{
            this.copied = false;
          },1500)
    }

    fb(e) {
        let url = "www.google.com";
        e.preventDefault();
        var facebookWindow = window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
            "flyallover.com" +
            this.router.url,
            "facebook-popup",
            "height=350,width=600"
        );
        if (facebookWindow.focus) {
            facebookWindow.focus();
        }

        return false;
    }

    tw() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://twitter.com/intent/tweet?url=' + 'www.flyallover.com' + this.router.url,
            'twitter-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }

    in() {
        let url = 'www.google.com';
        var Window = window.open(
            'https://linkedin.com/shareArticle?mini=true&url=' + 'www.flyallover.com' + this.router.url,
            'linkedin-popUp',
            'width= 600px, height= 350px'
        );
        if (Window.focus) {
            Window.focus();
        }
    }


}
