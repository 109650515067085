import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thingsToDoSearch'
})
export class ThingsToDoSearchPipe implements PipeTransform {

  transform(Things: any, word: string) {
    return Things.filter(Things => {
      return Things.name.toLowerCase().includes(word.toLowerCase()) ||
        Things.about.toLowerCase().includes(word.toLowerCase());
    });
  }

}
