<div  class="covid-container" [ngClass]="{'hide': closedStatus}">
  <div class="container">
    <div class="row">
      <div class="headr_covid">
      <h2>Airline Reassurance Policies</h2>
        <span (click)="close();"><img title="close covid pop up" alt="close covid pop up" src="./assets/images/icons/covid-icon/cancel.svg"></span>
      </div>
    </div>
    <div class="row d-flex flex-column">
      <div class="col-md-12">
      <hr class="d-block ">
      <div class="header_text">
        <h5>
          <p class="green_color">{{search?.flightData[0]?.origin}} :</p>
          <span class="grren_text">AZ895</span>
        </h5>
        <h5>
          <p class="gray_color">{{search?.flightData[0]?.destination}} :</p>
          <span class="green_color">AF9742</span>
        </h5>
      </div>
      <hr class="d-block mt-1">
      </div>
    </div>
      <div class="row covid_items">
        <div class="col-md-6">
          <div class="covid_img">
            <span><img title="Enhanced aircraft" alt="Enhanced aircraft" src="./assets/images/icons/covid-icon/covid.svg"></span>
            <h5>
              <p>Enhanced aircraft cleaning nightly</p>
              <h6>This flight features that will be thoroughly cleaned <br> using disinfectants on a nightly basis.</h6>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div class="covid_img">
            <span><img title="Face covering" alt="Face covering" src="./assets/images/icons/covid-icon/mask.svg"></span>
            <h5>
              <p>Face covering required</p>
              <h6>All passengers are required to wear a face covering throughout <br> their journey</h6>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div class="covid_img">
            <span><img title="No pre-flight temperature check" alt="No pre-flight temperature check" src="./assets/images/icons/covid-icon/temp.svg"></span>
            <h5>
              <p>No pre-flight temperature check</p>
              <h6>A temperature check is not required before boarding</h6>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div class="covid_img">
            <span><img title="spacing covid" alt="" src="./assets/images/icons/covid-icon/spacing.svg"></span>
            <h5>
              <p>No blocked adjacent seat</p>
              <h6> Adjacent seats are not blocked on this flight </h6>
            </h5>

          </div>
        </div>
        <div class="col-md-6">
          <div class="covid_img">
            <span><img title="Standard passenger capacity" alt="Standard passenger capacity" src="./assets/images/icons/covid-icon/menu.svg"></span>
            <h5>
              <p>Standard passenger capacity</p>
              <span>Ticket sales are not limited for this flight</span>
            </h5>
          </div>
        </div>
      </div>
  </div>
</div>
