import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-contact-us',
  templateUrl: './help-contact-us.component.html',
  styleUrls: ['./help-contact-us.component.css']
})
export class HelpContactUsComponent implements OnInit {

  formSubmitted: boolean = false;

  // form
  contactUsForm: FormGroup;
  validationMessages = {
    'fullName': [
      {type: 'required', message: 'Full name is required'},
      {type: 'minlength', message: 'Full name must be at least 6 characters long'},
      {type: 'maxlength', message: 'Full name cannot be more than 40 characters long'},
      {type: 'pattern', message: 'Full name must not contains numbers'}
      // { type: 'pattern', message: 'Your firstname must contain only numbers and letters' },
      // { type: 'validUsername', message: 'Your firstname has already been taken' }
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}
    ],
    'mobileNumber': [
      {type: 'required', message: 'Mobile number is required'},
      // { type: 'validCountryPhone', message: 'Phone incorrect for the country selected' }
    ],
    'subject': [
      {type: 'required', message: 'Subject is required'}
    ],
    'message': [
      {type: 'required', message: 'Message is required'}
    ]
  }
  url: string;
  queryParams = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  }
  siteKey: string = '';
  captchaKey: string = '';
  roundSpinner: boolean = false;

  constructor(
    private seoService: SEOService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apis: HelpCenterServices,
    private title: Title,
    private metaService: MetaTagsService
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.siteKey = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
    this.activatedRoute.url.subscribe(
      (activeUrl) => {
        this.url = this.router.url;
      }
    );

    this.queryParams.name = this.activatedRoute.snapshot.queryParams.name;
    this.queryParams.email = this.activatedRoute.snapshot.queryParams.email;
    this.queryParams.phone = this.activatedRoute.snapshot.queryParams.phone;
    this.queryParams.subject = this.activatedRoute.snapshot.queryParams.subject;
    this.queryParams.message = this.activatedRoute.snapshot.queryParams.message;
    this.createForm();
  }

  seoUpdate() {

    this.seoService.updateTitle('Contact Us | flyallover ');
    this.seoService.updateDescription('Contact flyallover in anytime for your questions about flights, hotels,trips when you booking or you have any problem with our website');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createForm() {
    this.contactUsForm = this.fb.group({
      fullName: new FormControl(this.queryParams.name, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*$')
      ])),
      email: new FormControl(this.queryParams.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ])),
      mobileNumber: new FormControl(this.queryParams.phone, Validators.compose([
        Validators.required,
        /// Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')
        //^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$
      ])),
      subject: new FormControl(this.queryParams.subject, Validators.required),
      message: new FormControl(this.queryParams.message, Validators.required)
    })
  }

  navigateOnSamePage() {
    this.queryParams.name = this.contactUsForm.value.fullName
    this.queryParams.email = this.contactUsForm.value.email
    this.queryParams.phone = this.contactUsForm.value.mobileNumber
    this.queryParams.subject = this.contactUsForm.value.subject
    this.queryParams.message = this.contactUsForm.value.message
    this.router.navigate([`${this.url}`], {
      queryParams: {
        name: this.contactUsForm.value.name,
        email: this.contactUsForm.value.email,
        phone: this.contactUsForm.value.mobileNumber,
        subject: this.contactUsForm.value.subject,
        message: this.contactUsForm.value.message
      }
    })
  }

  resolved(captchaResponse: string) {
    this.captchaKey = captchaResponse;
  }
  onSubmitContactUsForm(formValue) {
    this.roundSpinner = true
    if (this.captchaKey) {
      this.apis.postToContactUs(formValue.fullName, formValue.email,
        formValue.mobileNumber, formValue.subject, formValue.message, this.captchaKey).subscribe(
        (res) => {
          this.roundSpinner = false;
          this.formSubmitted = true;
        },
        (err) => {
        }
      )
    }
  }

  setMetaTags() {
    const hostUrl = AppComponent.hostUrl;
    const metaData = {
      title: "Contact Us | Flyallover",
      meta_data: {
        "og:title": "Contact Us | Flyallover",
        "og:url": `${hostUrl}${this.url}`,
        "og:type": "website",
        "og:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "og:description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
        "twitter:card": "summary_large_image",
        "twitter:title": "Contact Us | Flyallover",
        "twitter:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "twitter:description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
        "twitter:url": `${hostUrl}${this.url}`,
        "title": "Contact Us | Flyallover",
        "description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
      }
    }
    this.title.setTitle(metaData.title);
    this.metaService.modifyMetaTags(metaData.meta_data);
  }
}
