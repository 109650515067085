import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SEOService } from "../../services/seo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Clipboard } from "@angular/cdk/clipboard";
import { AppComponent } from '../../app.component';
import { WindowService } from 'src/app/services/webApis/window.service';

@Component({
  selector: 'app-articles-links-details',
  templateUrl: './articles-links-details.component.html',
  styleUrls: ['./articles-links-details.component.css']
})
export class ArticlesLinksDetailsComponent implements OnInit {
  articlLinksId: string;
  copied = false;
  metaD: any;
  title: any;
  imgShareDes: any;
  data: any;

  loadings = [

    { width: '300px' },
    { width: '300px' },
    { width: '300px' },   
    { width: '300px' },

  ];
  loadingsShare = [
    { width: '40px' },
    { width: '40px' },
    { width: '40px' },
    { width: '50px' },
  ];
  policyId: string;
  constructor(
    private localStorage: LocalStorageService,
    private seoService: SEOService,
    private _ActivatedRoute: ActivatedRoute,
    private apis: ApisService,
    private cookie: CookieService,
    private _Router: Router,
    private modalService: NgbModal,
    private router: Router,
    private clipboard: Clipboard,
    private _Window: WindowService
  ) { 
    this.articlLinksId = this._ActivatedRoute.snapshot.paramMap.get('id')
    this.CallFlightDataShare();
  }

  ngOnInit(): void {
    
  }
  CallFlightDataShare() {
    this.apis.getArticlesDetails(this.articlLinksId).subscribe(
      (data: any) => {
        this.data = data.data;       
        console.log("shared data " ,this.data);
        // this.imgShareDes = this.data.image ;
        // this.metaD = this.data.meta_description;
        // this.title =  this.data.title;
        // this.seoUpdate( this.title , this.metaD , this.imgShareDes);
      }
    );
  }
  
 
  seoUpdate(title, description , image , url = 'https://api.flyallover.com/storage/') {
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }

  

  // seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
  //   this.seoService.updateUrl();
  //   this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

  //   if (title != null) {
  //     this.seoService.updateTitle(title);
  //   }
  //   if (description != null) {
  //     this.seoService.updateDescription(description);
  //   }
  //   if (image != null) {
  //     this.seoService.updateImage(image);
  //   }
  // }

  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied = !this.copied
    setTimeout(() => {
      this.copied = false;
    }, 1500)
  }

  fb() {
    let url = 'www.google.com';
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + 'https://www.flyallover.com' + this.router.url,
      'facebook-popUp',
      'width=600px,height=350px,'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  linked() {
    let url = 'www.google.com';
    var Window = window.open(
      'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
      '-popUp',
      'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

}
