<div class="details-traveler">
  <div class="container section-deatails">
    <div class="row">
      <div class="form-details">
        <div class="header-content">
          <div class="d-flex">
            <img
              alt="Additional-Info"
              title="Additional-Info"
              src="../../../../assets/images/dashboard/Additional-Info.svg"
            />
            <h4 style="margin-top: 1vh">
              Family Member Passport for ({{ memberName }}) relation: ({{
                memberRelation
              }})
            </h4>
          </div>
          <div class="item-header">
            <p>
              When you book your trip you can Automatically select your
              co-traveller And book faster.
            </p>
          </div>
        </div>
        <hr class="hr" />
        <div
          class="alert-danger alert"
          *ngIf="passportForm.status == 'INVALID'"
        >
          Please make sure that all the (*) fields are filled as they are
          required to complete the process.
        </div>
        <div *ngIf="passportDateError" class="alert-danger alert" role="alert">
          Expiration date must be after the issue date or today's date
        </div>

        <form
          class="row passport-form form-selection"
          [formGroup]="passportForm"
          (submit)="editMemberPassport()"
        >
          <div class="col-4">
            <mat-form-field appearance="fill">
              <mat-label class="star">Passport Number</mat-label>
              <input
                matInput
                required
                formControlName="passportNumber"
                type="text"
                name="passportNumber"
              />
            </mat-form-field>
            <div
              *ngIf="passportForm.get('passportNumber').valid"
              class="ml-2 validateMark"
            >
              <img
                style="height: 2vh"
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
            <mat-error
              *ngIf="
                passportForm.get('passportNumber').dirty ||
                passportForm.get('passportNumber').touched
              "
            >
              <mat-error
                *ngIf="passportForm.get('passportNumber').errors?.required"
              >
              Passport Number Is Required
              </mat-error>
            </mat-error>
          </div>
          <div class="col-4" (click)="issuedDateCalender.open()">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label class="star">Issue Date</mat-label>
              <input
                placeholder="Select Issue date"
                matInput
                [matDatepicker]="issuedDateCalender"
                formControlName="issueDate"
                matInput
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="issuedDateCalender"
              ></mat-datepicker-toggle>
              <mat-datepicker #issuedDateCalender></mat-datepicker>
            </mat-form-field>

            <div
              *ngIf="passportForm.get('issueDate').valid"
              class="ml-2 validateMark"
            >
              <img
                style="height: 2vh"
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
          </div>
          <div class="col-4" (click)="expireDateCalender.open()">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label class="star">Expire Date</mat-label>
              <input
                placeholder="Select Issue Date"
                matInput
                [matDatepicker]="expireDateCalender"
                formControlName="expireDate"
                matInput
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="expireDateCalender"
              ></mat-datepicker-toggle>
              <mat-datepicker #expireDateCalender></mat-datepicker>
            </mat-form-field>

            <div
              *ngIf="passportForm.get('expireDate').valid"
              class="ml-2 validateMark"
            >
              <img
                style="height: 2vh"
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
          </div>
          <div class="col-4">
            <mat-form-field appearance="fill">
              <mat-label>Country</mat-label>
              <input
                type="text"
                matInput
                formControlName="countryPassport"
                placeholder="Select your Country"
                (click)="$event.target.select()"
                [matAutocomplete]="countryPassport"
              />
              <mat-autocomplete #countryPassport="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredPersonalCountries | async"
                  [value]="option.name"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="passportForm.get('countryPassport').valid"
              class="ml-2 validateMark"
            >
              <img
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
          </div>
          <div class="col-4">
            <mat-form-field appearance="fill">
              <mat-label>Nationality</mat-label>
              <input
                type="text"
                formControlName="nationalityPassport"
                placeholder="Select your Nationality"
                matInput
                (click)="$event.target.select()"
                [matAutocomplete]="nationalityPassport"
              />
              <mat-autocomplete #nationalityPassport="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredPersonalNationalities | async"
                  [value]="option.name"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="passportForm.get('nationalityPassport').valid"
              class="ml-2 validateMark"
            >
              <img
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
          </div>
          <div class="col-4">
            <mat-form-field appearance="fill">
              <mat-label>Issued Country</mat-label>
              <input
                type="text"
                formControlName="issuedCountry"
                placeholder="Select your Issued Country"
                matInput
                (click)="$event.target.select()"
                [matAutocomplete]="issuedCountry"
              />
              <mat-autocomplete #issuedCountry="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredPersonalNationalities | async"
                  [value]="option.name"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="passportForm.get('issuedCountry').valid"
              class="ml-2 validateMark"
            >
              <img
                src="assets/icons/check.png"
                class="check-icon"
                alt="check"
                title="check"
              />
            </div>
          </div>

          <hr class="hr" />
          <div class="col-12 btns-group">
            <div class="btn-groups">
              <button class="btn submit-btn">
                DELETE
                <i *ngIf="deleteSpinner" class="fas fa-spinner fa-spin"></i>
              </button>
              <button class="btn button-light" (click)="closeForm.emit()">
                CANCEL
              </button>
              <button
                class="btn submit-btn"
                type="submit"
                [disabled]="passportForm.status == 'INVALID'"
              >
                EDIT
                <i *ngIf="loadingSpinner" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
