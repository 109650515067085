import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {WindowService} from 'src/app/services/webApis/window.service';
import {SEOService} from '../../services/seo.service';
import {DialogDiscountComponent} from './dialog-discount/dialog-discount.component';
import {ApisService} from '../../services/apis.service';

@Component({
    selector: 'app-egyptair-search',
    templateUrl: './egyptair-search.component.html',
    styleUrls: ['./egyptair-search.component.css']
})
export class EgyptairSearchComponent implements OnInit {
  isSticky:any;
  elementHeight: any;
  hasLoadedMainSec = false;
  images:any[]=[
    './assets/images/egyptair/6.jpg',
    // './assets/images/egyptair/7.jpg',
    './assets/images/egyptair/1.jpg',
    './assets/images/egyptair/2.jpg',
    './assets/images/egyptair/3.jpg',
    './assets/images/egyptair/4.jpg',
    // './assets/images/egyptair/5.jpg',
  ]
  data: any;
  constructor(
    private seoService: SEOService,
    public dialog: MatDialog,
    private apis: ApisService,
    @Inject(DOCUMENT) private document: Document,
    // private window: WindowService,
    @Inject(PLATFORM_ID) private _plateform: object

  ) {
    this.seoUpdate();
    this.getAirLines();
  }
  openDialog() {
    const dialogRef = this.dialog.open(DialogDiscountComponent);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }
  checkScroll() {
    if(isPlatformBrowser(this._plateform)){
    this.isSticky = window.pageYOffset >= 700;
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
    this.openDialog()
    }, 1000);
  }
  getHeight() {
    const elmnt = this.document.getElementById('side');
    this.elementHeight = elmnt.offsetHeight;
  }
  seoUpdate(): void {
    this.seoService.updateTitle('EGYPTAIR family offer 5% off up to 33% off Discount');
    this.seoService.updateDescription('EGYPTAIR is offering family fares between Egypt and (Middle East, Europe, USA,' +
        ' Canada),5% off up to 33% off Discount Travel: from 01OCT 2022 ' +
        'till 30SEP 2023 .');
    this.seoService.updateUrl();
    this.seoService.updateImage('https://flyallover.com/assets/images/egyptair/6.jpg');
  }

  getAirLines() {
    this.apis.getHomeAirLines().subscribe((data: any) => {
        this.data = data.data;
      },
      err => {
        console.log(err);
      });
  }
}
