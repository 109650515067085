import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {SignOutComponent} from '../sign-out/sign-out.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dash-sidebar',
  templateUrl: './dash-sidebar.component.html',
  styleUrls: ['./dash-sidebar.component.css']
})
export class DashSidebarComponent implements OnInit {
  dialogRef: any;
  constructor(private apis: ApisService, private cookie: CookieService, private router: Router, private localStorage: LocalStorageService,
              private matDialog: MatDialog) {
  }

  token: any;
  firstName: any;
  firstLetter: any;
  email: any;
  lastName:any;

  ngOnInit(): void {
    this.token = this.localStorage.getItem('flyallover_token');
    this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.firstName = data.data.first_name.toUpperCase();
        this.lastName=data.data.last_name.toUpperCase();
        this.firstLetter = this.firstName[0];
        this.email = data.data.email;
        localStorage.setItem( 'firstName-dash', this.firstName);
        localStorage.setItem( 'lastName-dash', data.data.last_name);
        localStorage.setItem( 'email-dash', this.email);
      },
      (err: any) => {
        console.log(err);
      });
  }

  goSignOut() {
   
    this.localStorage.removeItem('flyallover_token');
    localStorage.removeItem( 'firstName-dash');
    localStorage.removeItem( 'lastName-dash');
    localStorage.removeItem( 'email-dash');
    localStorage.removeItem( 'user_id');
    localStorage.removeItem( 'email_log');
    localStorage.removeItem( 'user_id');
    
    this.router.navigate(['/']);
  }

}
