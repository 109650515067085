import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-package-room',
  templateUrl: './package-room.component.html',
  styleUrls: ['./package-room.component.css']
})
export class PackageRoomComponent implements OnInit {

  hotelDetails: any;
  copied: any;
  @Input() data;
  viewDetails= false;
  info: any;
  constructor() { }
  changeRoomName() {
    // if()
    const newName = this.data.RatePlanName.toString().replace(' - Booking.com rate', '');
    const roomArrayName = newName?.split('- ')
    console.log('roomArrayName', roomArrayName);

    return roomArrayName;
  }
  ngOnInit(): void {
    this.info =this.data.RateInfo ;
    console.log(this.data.RateInfo      ,' data privet')
  }
  viewPriceDetails(){
    this.viewDetails=!this.viewDetails
  }

}
